<script setup lang="ts">
import InputNumber from 'primevue/inputnumber';
import { useActuarialStore } from '@/stores/actuarial';

const actuarialStore = useActuarialStore();

defineEmits([
  'update:updateAverageType',
  'update:updatePeriodType',
  'update:updateLastN',
  'update:updateFromX',
  'update:updateToY',
  'update:updateNumberOfPeriods',
]);

defineProps({
  averageType: {
    type: String,
    default: 'Weighted',
  },
  periodType: {
    type: String,
    default: 'LastN',
  },
  lastN: {
    type: Number,
    default: 1,
  },
  fromX: {
    type: String,
    default: '',
  },
  toY: {
    type: String,
    default: '',
  },
  numberOfPeriods: {
    type: Number,
    required: true,
  },
});
</script>

<template>
  <div>
    <!-- Your template code here -->
    <div>
      <div>Average Method</div>
      <div class="mb-2 flex items-center text-sm">
        <el-radio-group
          :model-value="averageType"
          data-testid="average-method"
          class="ml-4"
          @change="(e) => $emit('update:updateAverageType', e)"
        >
          <el-radio label="Weighted" size="large">Volume Weighted</el-radio>
          <el-radio label="Simple" size="large">Simple</el-radio>
        </el-radio-group>
      </div>
      <div>Period Selection</div>
      <div class="mb-2 flex items-center text-sm">
        <el-radio-group
          :model-value="periodType"
          data-testid="period-selection"
          class="ml-4"
          @change="(e) => $emit('update:updatePeriodType', e)"
        >
          <el-radio label="LastN" size="large">Last N Periods</el-radio>
          <el-radio label="FromTo" size="large">From Period X to Period Y</el-radio>
        </el-radio-group>
      </div>
      <div v-if="periodType == 'LastN'">
        <div>
          Number of Periods (Should Range From <i>0 to {{ numberOfPeriods }} </i> , <i>0 </i>means all)
        </div>
        <div class="mb-2 w-full">
          <InputNumber
            :model-value="lastN"
            data-testid="number-of-periods-input"
            class="h-10 w-full"
            mode="decimal"
            :min="1"
            :max="numberOfPeriods"
            @input="
              (e) => {
                const parsedValue = parseFloat(String(e.value ?? '0'));
                $emit('update:updateLastN', parsedValue);
              }
            "
          />
        </div>
      </div>
      <div v-else>
        <div>Periods from</div>
        <div class="w-full mb-2">
          <el-select
            :model-value="fromX"
            placeholder=""
            data-testid="from-period-select"
            class="text-center w-full shadow-sm"
            @change="(e) => $emit('update:updateFromX', e)"
          >
            <el-option
              v-for="amount in actuarialStore.triangles_dates['month']"
              :key="amount"
              data-testid="from-period-option"
              :label="amount"
              :value="amount"
            />
          </el-select>
        </div>
        <div>Periods to</div>
        <div class="w-full mb-2">
          <el-select
            :model-value="toY"
            placeholder=""
            data-testid="to-period-select"
            class="text-center w-full shadow-sm"
            @change="(e) => $emit('update:updateToY', e)"
          >
            <el-option
              v-for="amount in actuarialStore.triangles_dates['month'].slice(
                actuarialStore.triangles_dates['month'].indexOf(fromX),
                -1
              )"
              :key="amount"
              data-testid="to-period-option"
              :label="amount"
              :value="amount"
            />
          </el-select>
        </div>
      </div>
    </div>
  </div>
</template>

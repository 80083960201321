<script setup lang="ts">
import monthColors from '@/constants/monthColors.js';
import { InfoFilled, More } from '@element-plus/icons-vue';
import moment from 'moment';

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
  treeSelected: {
    type: String,
    required: true,
  },
  selectedDraftList: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(['onClickTree', 'getInfo']);

function onClickTree(val, isDrafts, parentId) {
  emit('onClickTree', { val, isDrafts, parent_id: parentId });
}

function isFormal(node) {
  try {
    if (node.name == 'root') {
      return false;
    }

    const splitName = node.name.split('-');
    if (splitName[0].length != 6) {
      if (splitName[0].slice(-1) == 'F') {
        return true;
      }
      return false;
    }

    if (splitName[1].slice(-1) == 'F') {
      return true;
    }

    return false;
  } catch {
    return false;
  }
}

function getName(node) {
  try {
    if (node.name == 'root') {
      return node.name;
    }

    const splitName = node.name.split('-');
    const stringlength = splitName[0].length + 1 + splitName[1].length + 1;

    return node.name.slice(stringlength, node.name.length);
  } catch {
    return node.name;
  }
}

function treeNameFormatter(node) {
  try {
    if (node.name == 'root') {
      return node.name;
    }
    const splitName = node.name.split('-');
    let el = '';
    if (splitName[0] == 'DRAFT') {
      el += 'DRAFT' + '\n';
      el += splitName[1] + '';

      return el;
    } else {
      if (splitName[0].length != 6) {
        el += 'User: ' + splitName[1] + '\n';
        if (splitName[0].slice(-1) == 'F') {
          el += 'Timestamp:\n' + splitName[0].slice(0, -1) + '\n';
        } else {
          el += 'Timestamp:\n' + splitName[0] + '\n';
        }
        return el;
      }

      el += 'As at Date: \n' + moment(splitName[0], 'YYYYMM').format('MMM-YYYY') + '\n';
      el += 'User: ' + splitName[2] + '\n';
      if (splitName[1].slice(-1) == 'F') {
        el += 'Timestamp:\n' + splitName[1].slice(0, -1) + '\n';
      } else {
        el += 'Timestamp:\n' + splitName[1] + '\n';
      }
    }

    return el;
  } catch {
    return node.name;
  }
}

function checkColor(node) {
  try {
    const splitName = node.name.split('-');
    if (splitName[0].length != 6) {
      return '#55b691';
    }

    return monthColors[moment(splitName[0], 'YYYYMM').format('MM')];
  } catch {
    return '#55b691';
  }
}

function getInfo(data) {
  emit('getInfo', data);
}

function modifyDraftName(name) {
  try {
    return name.split('-')[3];
  } catch {
    return 'Undefined';
  }
}
</script>

<template>
  <el-tooltip class="box-item" :show-after="1000" effect="dark" :content="getName(data)" placement="top">
    <div
      class="tree-node"
      data-testid="bounce-node"
      :class="{ 'border-tree': data.nodeID == treeSelected, 'shadow-formal': isFormal(data) }"
      :style="{ 'background-color': checkColor(data) }"
      @click="onClickTree(data, false, null)"
    >
      <div class="absolute" style="right: 10px; top: 10px">
        <el-icon v-if="data.name.split('-')[0] != 'DRAFT'" :size="25" @click.stop="getInfo(data)">
          <InfoFilled />
        </el-icon>
      </div>
      <div v-if="props.selectedDraftList[data.nodeID]" class="absolute" style="right: 10px; bottom: 2px">
        <el-dropdown :max-height="200" size="large" placement="bottom-start">
          <el-icon>
            <More class="text-white mt-2" :size="20" />
          </el-icon>

          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item
                v-for="(i, idx) of props.selectedDraftList[data.nodeID]"
                :key="idx + ' dropdown'"
                @click.stop="onClickTree(i, true, data.nodeID)"
              >
                <div class="w-32">
                  <p :class="{ 'text-sybil-teal': i.nodeID == treeSelected }">{{ modifyDraftName(i['name']) }}</p>
                </div>
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
      <p style="white-space: pre-line">{{ treeNameFormatter(data) }}</p>
    </div>
  </el-tooltip>
</template>

<style scoped>
.tree-node {
  width: 150px;
  height: 150px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  color: white;
  border-radius: 4px;
}

.shadow-formal {
  box-shadow: 2px 5px 5px black;
}

.border-tree {
  border: solid;
  border-color: black;
  border-width: 3px;
}
</style>

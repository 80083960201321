import errorHandler from '@/utilities/errorhandler';
import { api } from './api';

export function fetchMirroredPortfolioDictionary(selectedBounceID: string, mirroredPortfolioId: string) {
  return api
    .post(import.meta.env.VITE_API_URL + 'portfolio/mirrored-dictionary', {
      bounce_id: selectedBounceID,
      mirror_portfolio_id: mirroredPortfolioId,
    })
    .then((res) => {
      return { mirroredDictionary: res.data.data.data };
    })
    .catch((err) => {
      errorHandler(err);
    });
}

export function fetchPortfolioInformation(selectedBounceID: string) {
  return api
    .get(import.meta.env.VITE_API_URL + 'portfolio/information', {
      params: {
        bounce_id: selectedBounceID,
      },
    })
    .then((res) => {
      return {
        portfolioID: res.data.data.portfolio_id,
        portfolioName: res.data.data.portfolio_name,
        bounceName: res.data.data.bounce_name,
      };
    })
    .catch((err) => {
      errorHandler(err);
    });
}

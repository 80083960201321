import { ref, computed } from 'vue';
import { defineStore } from 'pinia';
import { api } from '@/services/api';
import { usePortfolioStore } from '@/stores/portfolio';
import { useGlobalStore } from '@/stores/global';
import { useActuarialStore } from '@/stores/actuarial';
import { ElMessage } from 'element-plus';
import reverseSearch from '@/utilities/reverseSearch';

/**
 * CONSTANTS
 */
export const REPORTING_PERIOD_CONST = ['Last 12 Months', 'Last 6 Months', 'Last 3 Months', 'Last Month'];
export const REPORTING_PERIOD_VALUE = [12, 6, 3, 1];
export const INCURRED_PAID_NUMBER = [
  'Incurred Claims Triangles',
  'Paid Claims Triangles',
  'Number of Claims Triangles',
];
export const INCURRED_PAID_NUMBER_VALUE = ['INCURRED', 'PAID', 'COUNT'];
export const SELECTION_FROM_PAGE = ['Triangulations', 'Results_Summary'];
export const COHORT_CONSTANT = ['Months', 'Quarters', 'Years'];

export const useAvEActuarialStore = defineStore('AvEActuarial', () => {
  const global_store = useGlobalStore();
  const portfolio_store = usePortfolioStore();
  const actuarial_store = useActuarialStore();
  const numberOfDev = ref([]);
  const triangulations: any = ref([]);
  const isExclusionCols = ref([]);
  const actuarial_claims_nature: any = ref([]);
  const linkRatio = ref([]);
  const ratios: any = ref({});
  const actuarial_accident_underwriting = ref(0);
  const actuarial_claims_selection: any = ref(0);
  const actuarialHierarchy: any = ref({});
  const cohort = ref(0);
  const development = ref(0);
  const hierarchySelection = ref({});
  const ult_table_data: any = ref({ gwp_gep: {}, incurred_paid_amount: {} });
  const triangles_dates: any = ref({ month: [], quarter: [], year: [] });
  const isActuarialAveEligible = ref(false);

  const customUltTableDesc = ref<string[]>([]);
  const customUltTableTitle = ref([]);

  const trianglesJSON = ref(null);
  const devs = ref([]);
  const origins = ref([]);

  const ibnr_table: any = ref(null);

  const graphComponentID = ref(0);

  async function getActuarialChunkGrouping() {
    global_store.setLoading(true);
    await api
      .get(import.meta.env.VITE_API_URL + 'actuarial/get-actuarial-chunk-grouping', {
        params: {
          bounce_id: portfolio_store.expectedBounceID,
        },
      })
      .then((res) => {
        if (res.data.data['variable'].actuarial_hierarchy) {
          actuarialHierarchy.value = res.data.data['variable'].actuarial_hierarchy;
        }

        if (res.data.data['variable'].actuarial_claims_nature) {
          actuarial_claims_nature.value = res.data.data['variable'].actuarial_claims_nature;
        } else {
          actuarial_claims_nature.value = [];
          for (const i of portfolio_store.parameters['claims_nature']) {
            actuarial_claims_nature.value.push([i]);
          }
        }
        actuarial_claims_selection.value = 0;

        if (portfolio_store.isMirrored) {
          actuarial_store.selectedBusinessActuarialHierarchyAddLinkRatio =
            actuarial_store.configChunkGroupNamesIfMirrored(
              actuarial_store.selectedBusinessActuarialHierarchyAddLinkRatio
            );
        }

        hierarchySelection.value = {};
        for (const i of [
          ...portfolio_store.parameters['hierarchies'],
          ...portfolio_store.parameters['actuarial_grouping'],
        ]) {
          hierarchySelection.value[i] = 0;
        }
      })
      .catch((err) => {
        console.log(err);
        openErrorMsg('Error! Please try again');
      });
  }

  async function fetchCohortDevelopment() {
    await api
      .get(import.meta.env.VITE_API_URL + 'actuarial/cohort-development', {
        params: {
          bounce_id: portfolio_store.expectedBounceID,
        },
      })
      .then((res) => {
        cohort.value = res.data.data.cohort;
        development.value = res.data.data.development;
      })
      .catch(() => {
        global_store.setLoading(false);
        openErrorMsg('Error! Please try again');
      });
  }

  async function checkEligible() {
    await getActuarialChunkGrouping();
    await fetchCohortDevelopment();

    const isSameHierarchy =
      JSON.stringify(actuarialHierarchy.value) == JSON.stringify(actuarial_store.actuarialHierarchy);
    const isSameClaimsNature =
      JSON.stringify(actuarial_claims_nature.value) == JSON.stringify(actuarial_store.actuarial_claims_nature);

    const isSameCohort = cohort.value == actuarial_store.cohort;
    const isSameDevelopment = development.value == actuarial_store.development;
    console.log(JSON.stringify(actuarialHierarchy.value));
    console.log(JSON.stringify(actuarial_store.actuarialHierarchy));
    console.log(isSameHierarchy, isSameClaimsNature, isSameCohort, isSameDevelopment);
    if (isSameHierarchy && isSameClaimsNature && isSameCohort && isSameDevelopment) {
      isActuarialAveEligible.value = true;
    } else {
      isActuarialAveEligible.value = false;
    }
  }

  async function fetchTriangulationsData() {
    const filter = actuarial_store.createFilters();
    global_store.setLoading(true);

    let selected_group: number[] = [];
    for (const i of [
      ...portfolio_store.parameters['hierarchies'],
      ...portfolio_store.parameters['actuarial_grouping'],
    ]) {
      selected_group.push(actuarial_store.hierarchySelection[i]);
    }

    await api
      .post(import.meta.env.VITE_API_URL + 'actuarial/triangles', {
        bounce_id: portfolio_store.expectedBounceID,
        claims_nature_selection: actuarial_store.actuarial_claims_nature[actuarial_store.actuarial_claims_selection],
        filters_hierarchy: filter,
        selected_group: selected_group,
        report_date: portfolio_store.selectedExpectedReportDate,
        triangles_type: actuarial_store.triangleType,
      })
      .then((res) => {
        trianglesJSON.value = JSON.parse(res.data.data.triangles_json);
        origins.value = res.data.data.origins;
        devs.value = res.data.data.devs;

        numberOfDev.value = res.data.data.devs;
        ratios.value = res.data.data.ratios;
        actuarial_accident_underwriting.value = res.data.data.actuarial_accident_underwriting;
        triangles_dates.value = res.data.data.dates;
        ult_table_data.value = JSON.parse(res.data.data.ult_table_data.ult_table);
        ibnr_table.value = JSON.parse(res.data.data.ult_table_data.monthly_ult_table);
        customUltTableTitle.value = res.data.data.ult_table_data.custom_ult_table;
        customUltTableDesc.value = res.data.data.ult_table_data.custom_ult_description;
        global_store.setLoading(false);
        graphComponentID.value += 1;

        console.log('AVEAVEAVESUMMARY');
      })
      .catch((err) => {
        global_store.setLoading(false);
        console.log(err);
        openErrorMsg('Error! Please try again');
      });
  }

  function openErrorMsg(msg: string) {
    ElMessage({
      message: msg,
      type: 'error',
    });
  }

  return {
    fetchTriangulationsData,
    triangulations,
    numberOfDev,
    isExclusionCols,
    linkRatio,
    ratios,
    ult_table_data,
    actuarial_accident_underwriting,
    trianglesJSON,
    origins,
    devs,
    triangles_dates,
    ibnr_table,
    checkEligible,
    isActuarialAveEligible,
    customUltTableDesc,
    customUltTableTitle,
  };
});

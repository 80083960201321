<script setup lang="ts">
import { usePortfolioStore } from '@/stores/portfolio';
import { useDashboardStore } from '@/stores/dashboard';
import { CaretRight, CaretBottom } from '@element-plus/icons-vue';
import { ref, onMounted, computed, watch } from 'vue';
import {
  SELECTION_FROM_PAGE,
  INCURRED_PAID_NUMBER,
  INCURRED_PAID_NUMBER_VALUE,
  REPORTING_PERIOD_CONST,
  useActuarialStore,
} from '@/stores/actuarial';

const actuarial_store = useActuarialStore();

const emit = defineEmits(['chunkSetup', 'tierSetup', 'valuesSetup', 'resizeFilter']);

function setTriangleType(e: any) {
  console.log('adsfsefsef');
  actuarial_store.onSetTriangleType(e, SELECTION_FROM_PAGE[1]);
}

function setReportingPeriodSelection(e: any) {
  actuarial_store.onChangeReportingPeriod(e);
}

const reportingPeriodExpand = ref(false);
const reportingPeriodSelection = computed(() => actuarial_store.reportingPeriod);

function updateActuarialClaimsSelection(e: any) {
  actuarial_store.setActuarialClaimsSelection(e, SELECTION_FROM_PAGE[1]);
}

function onSelectHierarchyChunk(e, hierarchy) {
  actuarial_store.setHierarchySelection(hierarchy, e, SELECTION_FROM_PAGE[1]);
}
function toTitleCase(str: string) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}
const props = defineProps(['filtersWidth']);

const tierGroupExpand = ref(false);

function resizeFilter() {
  emit('resizeFilter');
}

const beforeEnter = (el) => {
  el.style.height = '0';
};

const enter = (el) => {
  el.style.height = el.scrollHeight + 'px';
};

const beforeLeave = (el) => {
  el.style.height = el.scrollHeight + 'px';
};

const leave = (el) => {
  el.style.height = '0';
};

const portfolio_store = usePortfolioStore();
const dashboard_store = useDashboardStore();

// watch( () => portfolio_store.isAve,  () => {
//   // loadDashboard()
//   reportingPeriodExpand.value = false
// })

const chunkRadio = ref([]);
const graphSelectionRadio = ref(dashboard_store.graphSelectionRadio);

const hierarchyTierGroupExpand = ref({});
const allHierarchies: any = ref([]);
const incurredPaidNumberExpand = ref(true);

onMounted(async () => {
  let hierarchyTierGroupExpandTemp = {};
  let allHierarchiesTemp: any = [];
  for (const i of [...portfolio_store.parameters['hierarchies'], ...portfolio_store.parameters['actuarial_grouping']]) {
    hierarchyTierGroupExpandTemp[i] = false;
    allHierarchiesTemp.push(i);
  }
  allHierarchies.value = allHierarchiesTemp;
  hierarchyTierGroupExpand.value = hierarchyTierGroupExpandTemp;
  dashboard_store.setGraphSelectionRadio(graphSelectionRadio.value);
  chunkRadio.value = [];
});
</script>

<template>
  <div class="sidenav main-sidebars shadow">
    <!-- <h1>{{sideBarData}}</h1> -->
    <div class="flex flex-row place-items-center sticky top-0 bg-white py-2 z-20">
      <div v-if="props.filtersWidth === '300px'" class="h-8 mr-10 ml-2"></div>
      <i
        @click="resizeFilter"
        class="pi pi-arrows-h ml-2 cursor-pointer absolute top-4 right-2"
        style="font-size: 20px"
      ></i>
    </div>
    <template v-if="props.filtersWidth === '300px'">
      <div>
        <div class="side-panel-title">
          <el-icon class="pt-3 cursor-pointer ml-1" @click="incurredPaidNumberExpand = !incurredPaidNumberExpand">
            <CaretRight v-if="incurredPaidNumberExpand" class="text-gray-400 text-sm" />
            <CaretBottom v-if="!incurredPaidNumberExpand" class="text-gray-400 text-sm" />
          </el-icon>
          <span
            class="text-sybil-charcoal -mt-2 ml-1 cursor-pointer"
            @click="incurredPaidNumberExpand = !incurredPaidNumberExpand"
            >{{ 'Triangles Type' }}</span
          >
        </div>
        <transition
          v-if="!incurredPaidNumberExpand"
          @before-enter="beforeEnter"
          @enter="enter"
          @before-leave="beforeLeave"
          @leave="leave"
        >
          <el-radio-group
            @change="setTriangleType"
            class="chunk-group-elements"
            :model-value="actuarial_store.triangleType"
          >
            <el-radio
              v-for="(val, idx) in INCURRED_PAID_NUMBER"
              :label="INCURRED_PAID_NUMBER_VALUE[idx]"
              size="large"
              class="hover:bg-gray-200 px-3 w-full"
            >
              {{ val }}
            </el-radio>
          </el-radio-group>
          <!-- </div> -->
        </transition>
      </div>
      <div>
        <div class="side-panel-title">
          <el-icon class="pt-3 cursor-pointer ml-1" @click="tierGroupExpand = !tierGroupExpand">
            <CaretRight v-if="!tierGroupExpand" class="text-gray-400 text-sm" />
            <CaretBottom v-if="tierGroupExpand" class="text-gray-400 text-sm" />
          </el-icon>
          <span class="text-sybil-charcoal -mt-2 ml-1 cursor-pointer" @click="tierGroupExpand = !tierGroupExpand"
            >Claims Type</span
          >
        </div>
        <transition @before-enter="beforeEnter" @enter="enter" @before-leave="beforeLeave" @leave="leave">
          <div v-if="tierGroupExpand" class="chunk-group-elements">
            <el-radio-group
              @change="updateActuarialClaimsSelection"
              :model-value="actuarial_store.actuarial_claims_selection"
              class="chunk-group-elements"
            >
              <div>
                <el-radio
                  v-for="(item, idx) in actuarial_store.actuarial_claims_nature"
                  :label="idx"
                  :key="item.join(', ')"
                  size="large"
                  class="hover:bg-gray-200 px-3 w-full"
                >
                  {{ toTitleCase(item.join(', ')) }}
                </el-radio>
              </div>
            </el-radio-group>
          </div>
        </transition>
      </div>
      <div v-for="hierarchy of allHierarchies">
        <div class="side-panel-title">
          <el-icon
            class="pt-3 cursor-pointer ml-1"
            @click="hierarchyTierGroupExpand[hierarchy] = !hierarchyTierGroupExpand[hierarchy]"
          >
            <CaretRight v-if="!hierarchyTierGroupExpand[hierarchy]" class="text-gray-400 text-sm" />
            <CaretBottom v-if="hierarchyTierGroupExpand[hierarchy]" class="text-gray-400 text-sm" />
          </el-icon>
          <span
            class="text-sybil-charcoal -mt-2 ml-1 cursor-pointer"
            @click="hierarchyTierGroupExpand[hierarchy] = !hierarchyTierGroupExpand[hierarchy]"
            >{{ hierarchy }}</span
          >
        </div>
        <transition @before-enter="beforeEnter" @enter="enter" @before-leave="beforeLeave" @leave="leave">
          <!-- <div v-if="hierarchyTierGroupExpand[hierarchy]" class="chunk-group-elements ">
                        <el-select :model-value="actuarial_store.actuarial_claims_selection" class="m-2"
                            placeholder="Select" size="large" @change="updateActuarialClaimsSelection">
                            <el-option v-for="item, idx in actuarial_store.actuarial_claims_nature" :key="item.join(', ')"
                                :label="item.join(', ')" :value="idx" />
                        </el-select>
                    </div> -->
          <el-radio-group
            @change="(e) => onSelectHierarchyChunk(e, hierarchy)"
            v-if="hierarchyTierGroupExpand[hierarchy]"
            :model-value="actuarial_store.hierarchySelection[hierarchy]"
            class="chunk-group-elements"
          >
            <div
              v-if="
                actuarial_store.actuarialHierarchy[hierarchy] &&
                actuarial_store.actuarialHierarchy[hierarchy]['groupNames']
              "
            >
              <el-radio
                v-for="(val, idx) in actuarial_store.actuarialHierarchy[hierarchy]['groupNames']"
                :label="idx"
                size="large"
                class="hover:bg-gray-200 px-3 w-full"
              >
                {{ val }}
              </el-radio>
            </div>
          </el-radio-group>
          <!-- </div> -->
        </transition>
      </div>
      <div v-if="!portfolio_store.isAve">
        <div class="side-panel-title">
          <el-icon class="pt-3 cursor-pointer ml-1" @click="reportingPeriodExpand = !reportingPeriodExpand">
            <CaretRight v-if="!reportingPeriodExpand" class="text-gray-400 text-sm" />
            <CaretBottom v-if="reportingPeriodExpand" class="text-gray-400 text-sm" />
          </el-icon>
          <span
            class="text-sybil-charcoal -mt-2 ml-1 cursor-pointer"
            @click="reportingPeriodExpand = !reportingPeriodExpand"
            >Reporting Period</span
          >
        </div>
        <transition @before-enter="beforeEnter" @enter="enter" @before-leave="beforeLeave" @leave="leave">
          <div v-if="reportingPeriodExpand" class="chunk-group-elements">
            <el-radio-group
              @change="setReportingPeriodSelection"
              :model-value="reportingPeriodSelection"
              class="chunk-group-elements"
            >
              <div>
                <el-radio
                  v-for="(item, idx) in REPORTING_PERIOD_CONST"
                  :label="idx"
                  :key="idx"
                  size="large"
                  class="hover:bg-gray-200 px-3 w-full"
                >
                  {{ item }}
                </el-radio>
              </div>
            </el-radio-group>
          </div>
        </transition>
      </div>
    </template>
  </div>
</template>

<style setup>
.chunk-group-elements {
  overflow: hidden;
  transition: height 0.2s ease-in-out;
}

.sidenav {
  height: 100%;
  position: fixed;
  right: 0;
  border-left: 1px solid #eeeeee;
  background-color: white;
  overflow-x: hidden;
  overflow-y: scroll;

  padding-bottom: 6rem;
}

.sidenav #filter {
  text-align: center;
  padding: 2px 0px 2px 16px;
  font-size: 18px;
  display: block;
  text-align: center;
}

.sidenav #filter-choice:hover {
  padding: 0px 0px 0px 22px;
  font-size: 13px;
  display: block;
  background-color: #e2e8f0;
}

.sidenav #filter-choice {
  padding: 0px 0px 0px 22px;
  font-size: 11px;
  display: block;
}

.side-panel-title {
  height: 30px;

  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));

  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;

  position: sticky;
  top: 3rem;
  font-size: 18px;
  z-index: 10;
}

.is-item {
  font-size: 12px;
  display: block;
  overflow: hidden;
  white-space: normal;
}

.is-item:hover {
  font-size: 14px;
}

@keyframes scroll-right {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-100%);
    /* Adjust to match content width */
  }
}

.tracker {
  font-size: 11px;
}

.panel-teal {
  filter: invert(63%) sepia(42%) saturate(423%) hue-rotate(105deg) brightness(92%) contrast(93%);
}

.panel-teal:hover {
  filter: none;
}

.chunk-group-elements {
  overflow: hidden;
  transition: height 0.2s ease-in-out;
}
</style>

<script setup lang="ts">
import { ref, onMounted, nextTick, onUnmounted } from 'vue';
import { useActuarialStore } from '@/stores/actuarial';
import * as am5 from '@amcharts/amcharts5/index';
import * as am5xy from '@amcharts/amcharts5/xy';

onUnmounted(() => {
  if (root) {
    root.dispose();
  }

  root = null;
  seriesSmoothed = null;
});

const actuarial_store = useActuarialStore();

function onCloseFittingModal() {
  actuarial_store.onChangeCurveFittingModal(false);
}
const fitChange = ref(actuarial_store.fitChange);

let root;
let seriesSmoothed;

onMounted(() => {
  nextTick(() => {
    createChart();
  });
});

function createChart() {
  if (root) {
    root.dispose();
  }

  root = am5.Root.new('chartdiv');

  let selectedData = structuredClone(actuarial_store.fitDevFactors);

  // Create chart
  // https://www.amcharts.com/docs/v5/charts/xy-chart/
  let chart = root.container.children.push(
    am5xy.XYChart.new(root, {
      panX: false,
      panY: false,
      wheelX: 'panX',
      wheelY: 'zoomX',
      paddingLeft: 0,
    })
  );

  // Add cursor
  // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
  chart.set(
    'cursor',
    am5xy.XYCursor.new(root, {
      // behavior: "zoomX"
    })
  );

  let xAxis = chart.xAxes.push(
    am5xy.ValueAxis.new(root, {
      renderer: am5xy.AxisRendererX.new(root, {}),
      tooltip: am5.Tooltip.new(root, {}),
    })
  );

  let yAxis = chart.yAxes.push(
    am5xy.ValueAxis.new(root, {
      renderer: am5xy.AxisRendererY.new(root, {}),
      numberFormat: "#'%'",
      tooltip: am5.Tooltip.new(root, {}),
    })
  );

  xAxis.set('minorDateFormats', {
    day: 'dd',
    month: 'MM',
  });

  xAxis.children.push(
    am5.Label.new(root, {
      text: 'Development',
      x: am5.p50,
      centerX: am5.p50,
    })
  );

  yAxis.children.unshift(
    am5.Label.new(root, {
      rotation: -90,
      text: 'Percent(%)',
      y: am5.p50,
      centerX: am5.p50,
    })
  );

  let seriesSelected = chart.series.push(
    am5xy.LineSeries.new(root, {
      name: 'Selected',
      xAxis: xAxis,
      yAxis: yAxis,
      valueYField: 'value',
      valueXField: 'dev',
      stroke: am5.color(0xff0000),
    })
  );

  seriesSmoothed = chart.series.push(
    am5xy.LineSeries.new(root, {
      name: 'Selected',
      xAxis: xAxis,
      yAxis: yAxis,
      valueYField: 'smooth',
      valueXField: 'dev',
      stroke: am5.color(0x009900),
    })
  );

  seriesSelected.data.setAll(selectedData);
  seriesSmoothed.data.setAll(selectedData);

  seriesSelected.strokes.template.setAll({
    strokeWidth: 0,
    strokeDasharray: [0, 300],
  });

  seriesSmoothed.strokes.template.setAll({
    strokeWidth: 4,
  });

  // Make stuff animate on load
  // https://www.amcharts.com/docs/v5/concepts/animations/
  seriesSelected.appear();
  seriesSmoothed.appear();

  // Actual bullet
  seriesSelected.bullets.push(function () {
    let bulletCircle = am5.Circle.new(root, {
      radius: 5,
      fill: seriesSelected.get('fill'),
    });
    return am5.Bullet.new(root, {
      sprite: bulletCircle,
    });
  });

  let isDrawing = false;

  chart.plotContainer.get('background').events.on('pointerdown', function (e) {
    isDrawing = true;
    addPoint(e);
  });

  chart.plotContainer.get('background').events.on('globalpointermove', function (e) {
    let point = chart.plotContainer.toLocal(e.point);
    let date = Math.round(xAxis.positionToValue(xAxis.coordinateToPosition(point.x)));
    let value = yAxis.positionToValue(yAxis.coordinateToPosition(point.y));
    if (isDrawing && actuarial_store.curveFittingModal && date > 0 && date <= actuarial_store.fitDevFactors.length) {
      addPoint(e);
    }
  });

  chart.plotContainer.get('background').events.on('pointerup', function (e) {
    isDrawing = false;
  });

  function addPoint(e) {
    let point = chart.plotContainer.toLocal(e.point);
    let date = Math.round(xAxis.positionToValue(xAxis.coordinateToPosition(point.x)));
    let value = yAxis.positionToValue(yAxis.coordinateToPosition(point.y));

    if (date - 1 < actuarial_store.fitDevFactors.length) {
      seriesSmoothed.data.removeIndex(date - 1);
      seriesSmoothed.data.push({
        dev: date,
        smooth: value,
      });

      seriesSmoothed.setPrivate('endIndex', seriesSmoothed.data.length);
      sortData();
    }
  }

  function sortData() {
    seriesSmoothed.dataItems.sort(function (a, b) {
      let atime = a.get('valueX');
      let btime = b.get('valueX');

      if (atime < btime) {
        return -1;
      } else if (atime == btime) {
        return 0;
      } else {
        return 1;
      }
    });
  }

  chart.set(
    'scrollbarY',
    am5.Scrollbar.new(root, {
      orientation: 'vertical',
    })
  );
  chart.set(
    'scrollbarX',
    am5.Scrollbar.new(root, {
      orientation: 'horizontal',
    })
  );
  chart.appear(1000, 100);
}

async function fit(method: string) {
  await actuarial_store.fit(method);
  createChart();
}

async function addIDFmethod() {
  let ratioType: any = [];
  let constantArray: any = [];

  ratioType.push('Smooth');
  ratioType.push('Fit');
  ratioType.push(actuarial_store.fitDevFactorsName);
  let devCopy = seriesSmoothed.dataItems.map((x) => 100 / x.dataContext.smooth);
  devCopy.push(1);
  for (let i = 0; i < devCopy.length - 1; i++) {
    if (devCopy[i] <= 0) {
      constantArray.push(1);
    } else {
      constantArray.push(devCopy[i] / devCopy[i + 1]);
    }
  }

  actuarial_store.addLinkRatios(ratioType, constantArray);
  onCloseFittingModal();
}
</script>
<template>
  <el-dialog
    :model-value="actuarial_store.curveFittingModal"
    :before-close="onCloseFittingModal"
    width="90%"
    title="Smooth Development Factors"
  >
    <div class="w-full">
      <div class="flex flex-row content-center pl-12 align-center">
        <el-button class="w-44 ml-10" type="primary" plain @click="fit('spl')">Spline Smoothing</el-button>
        <el-button class="w-44" type="primary" plain @click="fit('exp')">Exponential Decay Fit</el-button>
        <div class="h-4 w-4 bg-blue-300 rounded-full mt-2 ml-10"></div>
        <div class="mt-2 ml-2">Original Pattern</div>
        <div class="h-1 w-5 bg-green-600 mt-4 ml-10"></div>
        <div class="mt-2 ml-2">Smoothed/Fitted Line</div>
      </div>
      <div id="chartdiv" class="chart bg-white w-full h-96"></div>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button type="info" @click="onCloseFittingModal()">Cancel</el-button>
        <el-button type="primary" @click="addIDFmethod()">Add</el-button>
      </span>
    </template>
  </el-dialog>
</template>

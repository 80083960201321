import { ref, computed } from 'vue';
import { defineStore } from 'pinia';
import { usePortfolioStore } from '@/stores/portfolio';
import { useGlobalStore } from '@/stores/global';
import { useActuarialStore } from '@/stores/actuarial';
import { useDashboardStore } from '@/stores/dashboard';
import { api } from '@/services/api';
import { ElMessage } from 'element-plus';
import moment from 'moment';
import { openSuccessMsg } from '@/utilities/notificationMessage';

export const useUnderwritingStore = defineStore('underwriting', () => {
  const actuarial_store = useActuarialStore();
  const underwriting_store = useUnderwritingStore();
  const convertedFilter: any = ref();
  const filtersExpandedKeys: any = ref([]);
  const dataFilters: any = ref();
  const portfolio_store = usePortfolioStore();
  const global_store = useGlobalStore();
  const dashboard_store = useDashboardStore();
  const isNewBusinessModal = ref(false);
  const hierarchyOrder = ref<number[]>([]);
  const currentLevel = ref<number>(0);
  const tableData = ref(null);
  const tableDataColumns = ref(null);
  const valueArrayColumns: any = ref(null);
  const totalTableData = ref([]);
  const accident_underwriting = ref(1);
  const currentAmountType = ref('GWP');
  const creditsData: any = ref([]);
  const isChangeHierarchyOrderModal = ref(false);
  const unDataFilters: any = ref(null);
  const unDateFilters: any = ref(null);
  const underwritingTableRenderKey = ref(1);
  const chart_claims_selection: any = ref(0);
  const filtersKeys = ref(0);
  const underwritingControllerDate = ref({
    filters: {},
    underwriting_month: '',
    index: 0,
  });
  const isUnderwritingControllerModal = ref(false);
  const isUnderwritingMultipleChangeModal = ref(false);
  const underwriting_metadata = ref({
    page: 1,
    per_page: 50,
    total_items: 0,
    total_page: 1,
  });
  const checkedFilterTreeKeys = ref(null);
  const exitsTable = ref([]);
  const selectedData = ref([]);
  const treeSelectionKeys = ref(null);
  const valueFilters = ref(null);
  const isFiltered = ref(0);
  const underwritingMultipleChangeDesc = ref(null);
  const underwritingMultipleChangeGrid = ref(null);

  function resetUnderwriting() {
    tableData.value = null;
  }

  function updateTreeSelectionKeys(o: any) {
    treeSelectionKeys.value = o;
  }

  function setIsFiltered(val: number) {
    isFiltered.value = val;
  }

  async function updateHierarchyOrder(newHierarchyOrder: any) {
    hierarchyOrder.value = newHierarchyOrder;
    currentLevel.value = newHierarchyOrder.length;
    underwriting_metadata.value.page = 1;
    await fetchUnderwritingTable();
  }

  function setUnderwritingControllerDate(val: any) {
    underwritingControllerDate.value = val;
  }

  function resetFilters() {
    let a = JSON.parse(JSON.stringify(dashboard_store.filters));

    selectedData.value = JSON.parse(JSON.stringify(a));
    dataFilters.value = JSON.parse(JSON.stringify(a));
    convertedFilter.value = set_filter();
  }

  function getKeyByValue(object: any, value: any): string {
    return Object.keys(object).find((key) => object[key] === value) || '';
  }

  function setTreeFilters(s: any, uws: any, accs: any) {
    console.log('hello');
    selectedData.value = s;

    filtersKeys.value += 1;
    convertedFilter.value = set_filter();
  }

  function set_filter() {
    let temp_hierarchy: any = {};

    for (const i_key of Object.keys(dashboard_store.filters)) {
      let temp_arr: any = [];
      for (const value of selectedData.value[i_key]) {
        temp_arr.push(parseInt(getKeyByValue(portfolio_store.dictionary[i_key], value)));
      }

      temp_hierarchy[i_key] = temp_arr;
    }

    return temp_hierarchy;
  }

  async function onAddNewBusiness(
    newBusinessModalData: any,
    additionalInfomationNeededSelection: any,
    earningRatio: any
  ) {
    global_store.setLoading(true);
    newBusinessModalData = JSON.parse(JSON.stringify(newBusinessModalData));
    newBusinessModalData['start_date'] = moment(newBusinessModalData['start_date']).format('YYYY-MM-DD');
    newBusinessModalData['end_date'] = moment(newBusinessModalData['end_date']).format('YYYY-MM-DD');

    newBusinessModalData['GWP'] = parseFloat(newBusinessModalData['GWP']);

    for (const i of portfolio_store.parameters.claims_nature) {
      newBusinessModalData[i] = parseFloat((parseFloat(newBusinessModalData[i]) / 100).toFixed(2));
    }

    newBusinessModalData['ELASTICITY'] = parseFloat((parseFloat(newBusinessModalData['ELASTICITY']) / 100).toFixed(2));

    for (const i of portfolio_store.parameters.commission) {
      newBusinessModalData[i] = parseFloat((parseFloat(newBusinessModalData[i]) / 100).toFixed(2));
    }

    await api
      .post(import.meta.env.VITE_API_URL + 'underwriting/add-new-business', {
        bounce_id: portfolio_store.selectedBounceID,
        parameters_json: portfolio_store.parameters,
        report_date: dashboard_store.report_date,
        new_data: newBusinessModalData,
        new_chunk_group: additionalInfomationNeededSelection,
        earning_ratio: earningRatio,
      })
      .then(async (res: any) => {
        actuarial_store.createDraft(res);
        await portfolio_store.onFetchPortfolio();
        actuarial_store.setActuarialCacheResetRequired(true);
        isNewBusinessModal.value = false;
        openSuccessMsg('New Business Added Successfully');
        return Promise.all([
          underwriting_store.fetchUnderwritingTable(),
          portfolio_store.onResetForwardLooking(true),
          portfolio_store.getSignedOff(),
        ]);
      })
      .then(() => {
        global_store.setLoading(false);
      })
      .catch(() => {
        openErrorMsg('Error!');
        global_store.setLoading(false);
      });
  }

  async function changeAccidentUnderwriting() {
    accident_underwriting.value = 1 - accident_underwriting.value;
    for (let m in valueFilters.value) {
      if (valueFilters.value[m].length > 0) {
        for (let i in valueFilters.value[m]) {
          if (valueFilters.value[m][i][0] == 'GWP') {
            valueFilters.value[m][i][0] = 'GEP';
          } else if (valueFilters.value[m][i][0] == 'GEP') {
            valueFilters.value[m][i][0] = 'GWP';
          }
        }
      }
    }
    await fetchUnderwritingTable();
    if (currentAmountType.value == 'GWP') {
      currentAmountType.value = 'GEP';
    } else if (currentAmountType.value == 'GEP') {
      currentAmountType.value = 'GWP';
    }
  }

  function initialisedFilters() {
    checkedFilterTreeKeys.value = hierarchyOrder.value
      .slice(0, currentLevel.value)
      .map((value) => Object.keys(portfolio_store.dictionary[portfolio_store.parameters.hierarchies[value]]));
  }

  async function fetchAPIUnderwritingTable() {
    global_store.setLoading(true);
    let convertedData = [];
    let convertedDataColumns = [];
    let convertedValueArrayColumns = [];
    let total: any = [];

    let hierarchies_order: any = [];
    for (const i of hierarchyOrder.value) {
      hierarchies_order.push(portfolio_store.parameters['hierarchies'][i]);
    }
    await api
      .post(import.meta.env.VITE_API_URL + 'underwriting/table-view', {
        bounce_id: portfolio_store.selectedBounceID,
        hierarchies_order: hierarchies_order.slice(0, currentLevel.value),
        start_date: portfolio_store.all_uw_dates['month'][0],
        end_date: portfolio_store.all_uw_dates['month'][portfolio_store.all_uw_dates['month'].length - 1],
        all_months: portfolio_store.all_uw_dates['month'],
        per_page: underwriting_metadata.value.per_page,
        page: underwriting_metadata.value.page,
        accident_underwriting: accident_underwriting.value,
        checkedFilterTreeKeys:
          checkedFilterTreeKeys.value ||
          hierarchyOrder.value
            .slice(0, currentLevel.value)
            .map((value) => Object.keys(portfolio_store.dictionary[portfolio_store.parameters.hierarchies[value]])),
        valueFilters: valueFilters.value || portfolio_store.all_uw_dates['month'].map((x) => []),
        selectedLineSize: portfolio_store.selectedLineSize,
      })
      .then((res) => {
        console.log(res.data.data.metadata);
        underwriting_metadata.value = res.data.data.metadata;
        convertedData = res.data.data.table.combined_data;
        convertedDataColumns = res.data.data.table.combined_data_cols;
        convertedValueArrayColumns = res.data.data.table.value_array_cols;
        total = res.data.data.total.data;
        global_store.setLoading(false);
      })
      .catch((error) => {
        openErrorMsg('Error!');
        console.log(error);
        global_store.setLoading(false);
        throw 'Error!';
      });

    return [convertedData, convertedDataColumns, convertedValueArrayColumns, total];
  }

  async function fetchUnderwritingTable() {
    await fetchAPIUnderwritingTable()
      .then((res) => {
        let [convertedData, convertedDataColumns, convertedValueArrayColumns, total] = res;
        valueArrayColumns.value = convertedValueArrayColumns;
        tableDataColumns.value = convertedDataColumns;
        tableData.value = convertedData;
        totalTableData.value = total;
      })
      .catch(() => {
        return;
      });
  }

  function openErrorMsg(msg: string) {
    ElMessage.warning(msg);
  }

  function setHierarchyOrder() {
    if (hierarchyOrder.value.length == 0) {
      if (portfolio_store.parameters['default_hierarchies'].length == 0) {
        let temp: number[] = [];

        for (const i in portfolio_store.parameters['hierarchies']) {
          temp.push(parseInt(i));
        }

        hierarchyOrder.value = temp;
        currentLevel.value = temp.length;
      } else {
        let convertedHierarchiesKey: number[] = [];

        for (const i of portfolio_store.parameters['default_hierarchies']) {
          convertedHierarchiesKey.push(portfolio_store.parameters['hierarchies'].findIndex((e: string) => e == i));
        }

        hierarchyOrder.value = convertedHierarchiesKey;
        currentLevel.value = convertedHierarchiesKey.length;
      }
    }
  }

  function setCurrentAmountType(amount: string) {
    currentAmountType.value = amount;
  }

  function setCurrentLevel(isLevelUp: boolean) {
    if (isLevelUp && currentLevel.value > 1) {
      currentLevel.value -= 1;
    }

    if (!isLevelUp && portfolio_store.parameters.hierarchies.length - 1 >= currentLevel.value) {
      currentLevel.value += 1;
    }
    fetchUnderwritingTable();
  }

  function updateChangeHierarchyOrderModal(x: boolean) {
    isChangeHierarchyOrderModal.value = x;
  }

  function updatePageUnderwritingTable(page: number) {
    underwriting_metadata.value.page = page;
    fetchUnderwritingTable();
  }

  function updateIsUnderwritingControllerModal(val: boolean) {
    isUnderwritingControllerModal.value = val;
  }

  function updateIsUnderwritingMultipleChangeModal(val: boolean) {
    isUnderwritingMultipleChangeModal.value = val;
  }

  function onChangeIsNewBusinessModal(val: boolean) {
    isNewBusinessModal.value = val;
  }

  async function setCheckedFilterTreeKeys(val: array) {
    checkedFilterTreeKeys.value = val;
    underwriting_metadata.value.page = 1;
    underwritingTableRenderKey.value += 1;
    console.log('hello 2');
    await fetchUnderwritingTable();
  }

  async function setValueFilters(val: array) {
    valueFilters.value = val;
    underwriting_metadata.value.page = 1;
    underwritingTableRenderKey.value += 1;
    console.log('hello');
    await fetchUnderwritingTable();
  }

  async function setChartClaimsSelection(e: any, selection_from: string) {
    chart_claims_selection.value = e;
  }

  function updateUnderwritingMultipleChangeDesc(value: string) {
    underwritingMultipleChangeDesc.value = value;
  }

  async function updateUnderwritingMultipleChangeGrid(value: any) {
    underwritingMultipleChangeGrid.value = value;
  }

  return {
    onChangeIsNewBusinessModal,
    updatePageUnderwritingTable,
    fetchUnderwritingTable,
    setHierarchyOrder,
    hierarchyOrder,
    currentLevel,
    tableData,
    tableDataColumns,
    valueArrayColumns,
    currentAmountType,
    setCurrentAmountType,
    creditsData,
    accident_underwriting,
    changeAccidentUnderwriting,
    setCurrentLevel,
    checkedFilterTreeKeys,
    setCheckedFilterTreeKeys,
    isChangeHierarchyOrderModal,
    updateChangeHierarchyOrderModal,
    updateHierarchyOrder,
    underwriting_metadata,
    isUnderwritingControllerModal,
    updateIsUnderwritingControllerModal,
    isUnderwritingMultipleChangeModal,
    updateIsUnderwritingMultipleChangeModal,
    setUnderwritingControllerDate,
    underwritingControllerDate,
    exitsTable,
    totalTableData,
    isNewBusinessModal,
    onAddNewBusiness,
    setTreeFilters,
    treeSelectionKeys,
    updateTreeSelectionKeys,
    selectedData,
    dataFilters,
    unDataFilters,
    unDateFilters,
    filtersExpandedKeys,
    resetFilters,
    convertedFilter,
    valueFilters,
    setValueFilters,
    isFiltered,
    setIsFiltered,
    resetUnderwriting,
    chart_claims_selection,
    setChartClaimsSelection,
    underwritingTableRenderKey,
    filtersKeys,
    updateUnderwritingMultipleChangeDesc,
    underwritingMultipleChangeDesc,
    updateUnderwritingMultipleChangeGrid,
    underwritingMultipleChangeGrid,
  };
});

<script setup lang="ts">
import { onMounted, computed } from 'vue';
const props = defineProps(['forwardlooking', 'ccr_nlr', 'tootltipDisabled']);

const tootltipDisabled = computed(() => props.tootltipDisabled);
const forwardlooking = computed(() => props.forwardlooking);

onMounted(() => console.log(forwardlooking.value));
</script>

<template>
  <div
    v-if="forwardlooking"
    class="h-14 -mt-2 relative bg-sybil-charcoal text-white p-1 rounded-lg w-24 hover:scale-150 hover:z-80 hover:mt-2 hover:mr-5 ease-in-out duration-1000"
  >
    <div class="absolute left-0">
      <h1 class="text-xs -rotate-90 -ml-4 mt-4 scale-x-90">FWD {{ props.ccr_nlr }}</h1>
    </div>
    <div class="grid grid-rows-3 gap-1 z-20 -mt-1 watermark absolute pl-2 w-full">
      <el-tooltip :disabled="tootltipDisabled" placement="left" :offset="30" content="Current month forward looking">
        <p class="mr-1 text-sm cursor-help text-center text-sybil-teal brightness-200 hover:text-red-500">
          {{ (forwardlooking[0] * 100).toFixed(1) }}%
        </p>
      </el-tooltip>
      <el-tooltip
        :disabled="tootltipDisabled"
        placement="left"
        :offset="30"
        content="Average next 12 months forward looking"
      >
        <b class="font-bold mr-1 -mt-2 cursor-help text-center text-sybil-teal brightness-125 hover:text-red-500"
          >{{ (forwardlooking[1] * 100).toFixed(1) }}%
        </b>
      </el-tooltip>
      <el-tooltip :disabled="tootltipDisabled" placement="left" :offset="30" content="After 12 months forward looking">
        <p class="mr-1 text-sm -mt-3 cursor-help text-center text-sybil-teal brightness-200 hover:text-red-500">
          {{ (forwardlooking[2] * 100).toFixed(1) }}%
        </p>
      </el-tooltip>
    </div>
  </div>
</template>

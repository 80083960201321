export enum DateUnitEnum {
  MONTH = 'Month',
  QUARTER = 'Quarter',
  YEAR = 'Year',
}

export enum MomentFormatUnitEnum {
  MONTH = 'MMM-YYYY',
  QUARTER = '[Q]Q-YYYY',
  YEAR = 'YYYY',
}

<script setup lang="ts">
import { ref, computed, watch } from 'vue';
import { ElMessage } from 'element-plus';
import router from '@/router/index';
import { useRouter } from 'vue-router';
import { useAuthenticationStore } from '@/stores/auth';
import { usePortfolioStore } from '@/stores/portfolio';
import { useDashboardStore } from '@/stores/dashboard';
import ForwardLooking from '@/components/ForwardLooking.vue';
import moment from 'moment';
import { useGlobalStore } from '@/stores/global';
import { SetUp, Right } from '@element-plus/icons-vue';

const userRouter = useRouter();
const portfolioStore = usePortfolioStore();
const dashboardStore = useDashboardStore();
const globalStore = useGlobalStore();

const projectionsOptions = [
  'No Projections',
  '1-Year Projections',
  '2-Year Projections',
  '3-Year Projections',
  '4-Year Projections',
  '5-Year Projections',
];
const selectedLineSize = computed(() => portfolioStore.selectedLineSize);
const isMainMenu = computed(() => portfolioStore.selectedPortfolioName == '');
const forwardlooking = computed(() =>
  dashboardStore.dashboards.ccr_nlr == 'CCR'
    ? portfolioStore.forwardLookings?.map((x: [number, number]) => x[0] + x[1])
    : portfolioStore.forwardLookings?.map((x: [number, number]) => x[0] / (1 - x[1]))
);

const oldForwardlooking = computed(() =>
  dashboardStore.dashboards.ccr_nlr == 'CCR'
    ? portfolioStore.oldForwardLookings?.map((x: [number, number]) => x[0] + x[1])
    : portfolioStore.oldForwardLookings?.map((x: [number, number]) => x[0] / (1 - x[1]))
);

const fwdCalss = ref('-mr-32');
const forwardLookingChange = ref(false);

watch(
  () => portfolioStore.oldForwardLookings,
  () => {
    console.log(portfolioStore.oldForwardLookings);
    if (portfolioStore.oldForwardLookings) {
      forwardLookingChange.value = true;
      // fwdCalss.value = 'mr-5';

      setTimeout(() => {
        forwardLookingChange.value = false;
        // fwdCalss.value = '-mr-28';
        portfolioStore.resetOldForwardLookings();
      }, 10000);
    } else {
      forwardLookingChange.value = false;
      // fwdCalss.value = '-mr-28';
    }
  },
  { deep: true }
);

function removeOldForwardLookings() {
  forwardLookingChange.value = false;
  // fwdCalss.value = '-mr-28';
  portfolioStore.resetOldForwardLookings();
}

defineProps<{
  isAdmin: boolean;
  isBronze: boolean;
}>();

function openIsTargetModal() {
  globalStore.isTargetModal = true;
}

function onOpenAuditLogModal() {
  globalStore.setAuditLogModal(true);
}

defineEmits(['selectPage', 'logout']);
const authStore = useAuthenticationStore();

function openErrorMsg(msg: string) {
  ElMessage.error(msg);
}

function userProfile() {
  router.push('/profile');
}

function logout() {
  authStore
    .logout()
    .then(() => router.push('/login'))
    .catch((err) => {
      if (err.response && err.response.data) {
        openErrorMsg(err.response.data.data);
      } else {
        openErrorMsg('Error! Please try again');
      }
    });
}

function nameInit() {
  if (authStore.user) {
    return (authStore.user.firstName.charAt(0) + authStore.user.lastName.charAt(0)).toUpperCase();
  } else {
    return '';
  }
}

function nameFormatter(name: string) {
  return name.length > 17 ? name.substring(0, 17) + '...' : name;
}
</script>

<template>
  <!-- top-menu -->
  <div class="topmenu relative top-0 z-50 h-16 bg-white w-full drop-shadow-lg font-graphie" data-testid="menu-bar">
    <div class="flex flex-row content-center ml-3 cursor-pointer" @click="userRouter.go(0)">
      <img v-if="!isBronze" class="sybil-logo h-12" alt="Sybil" src="@/assets/images/sybil.png" />
    </div>

    <!-- open module -->
    <div v-if="!isMainMenu" class="h-12 text-3xl grid grid-rows-2 brightness-75 items-center ml-3">
      <a class="text-sybil-teal items-center" data-testid="selected-portfolio-name">{{
        nameFormatter(portfolioStore.selectedPortfolioName)
      }}</a>
      <el-dropdown trigger="click">
        <span class="el-dropdown-link text-sybil-teal text-sm">
          {{ selectedLineSize }}
        </span>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item
              v-for="value in portfolioStore.parameters.line_size"
              :key="value"
              @click="portfolioStore.setSelectedLineSize(value)"
              >{{ value }}</el-dropdown-item
            >
            <el-dropdown-item disabled divided>Add new...</el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>

    <div v-if="!isMainMenu" class="h-12 text-xl brightness-75 flex items-center ml-3 -mt-3">
      <el-tooltip :content="portfolioStore.selectedBounceTimeStamp" placement="bottom" :show-after="1000">
        <div class="grid grid-rows-3 gap-2 -mt-0">
          <el-dropdown trigger="click">
            <span class="">
              <h1
                class="ml-5 h-2 scale-150 relative"
                :class="portfolioStore.selectedBounceIsFormal ? 'text-sybil-teal' : ''"
              >
                <a class="items-center" data-testid="bounce-date">{{
                  moment(dashboardStore.report_date, 'YYYY-MM-DD').format('MMM-YYYY')
                }}</a>
                <h1 v-if="dashboardStore.yearsOfProjections" class="absolute top-0 left-14 w-32 scale-75 text-xs">
                  +
                  {{ projectionsOptions[dashboardStore.yearsOfProjections] }}
                </h1>
              </h1>
            </span>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item
                  v-for="(value, index) in projectionsOptions"
                  :key="value"
                  @click="dashboardStore.setYearsOfProjections(index)"
                  >{{ value }}</el-dropdown-item
                >
              </el-dropdown-menu>
            </template>
          </el-dropdown>
          <h1
            v-if="portfolioStore.selectedBounceName"
            class="mt-0 h-1 ml-1"
            :class="portfolioStore.selectedBounceIsFormal ? 'text-sybil-teal' : ''"
          >
            <a class="text-sm items-center" data-testid="selected-bounce-name"
              >{{ nameFormatter(portfolioStore.selectedBounceName) }}
            </a>
          </h1>
          <h1
            v-if="portfolioStore.selectedBounceName"
            class="mt-0 h-1 ml-1"
            :class="portfolioStore.selectedBounceIsFormal ? 'text-sybil-teal' : ''"
          >
            <a class="text-sm items-center" data-testid="selected-bounce-user"
              >by {{ portfolioStore.selectedBounceUser }}</a
            >
          </h1>
        </div>
      </el-tooltip>
      <el-tooltip content="Update bounce" placement="bottom" :show-after="1000">
        <img
          src="@/assets/images/add.svg"
          width="20"
          class="png-teal cursor-pointer ml-8"
          alt="update bounce"
          @click="globalStore.isDataUploadModal = true"
        />
      </el-tooltip>

      <div v-if="portfolioStore.isAve" class="grid grid-cols-2 mt-3 ml-2"><a class="items-center ml-1"> vs </a></div>

      <el-tooltip
        v-if="portfolioStore.isAve"
        :content="portfolioStore.selectedExpectedBounceTimeStamp"
        placement="bottom"
        :show-after="1000"
      >
        <div class="grid grid-rows-3 gap-2 -mt-2">
          <div class="mb-0 h-2" :class="portfolioStore.selectedExpectedReportisFormal ? 'text-sybil-teal' : ''">
            <a class="items-center">{{
              moment(portfolioStore.selectedExpectedReportDateHeader, 'YYYYMM').format('MMM-YYYY')
            }}</a>
          </div>
          <div class="mt-0 h-1 ml-1" :class="portfolioStore.selectedExpectedReportisFormal ? 'text-sybil-teal' : ''">
            <a class="text-sm items-center">{{ portfolioStore.selectedExpectedReportName }} </a>
          </div>
          <div class="mt-0 h-1 ml-1" :class="portfolioStore.selectedExpectedReportisFormal ? 'text-sybil-teal' : ''">
            <a class="text-sm items-center">by {{ portfolioStore.selectedExpectedselectedBounceUser }}</a>
          </div>
        </div>
      </el-tooltip>

      <el-tooltip v-if="portfolioStore.isAve" content="Update bounce" placement="bottom" :show-after="1000">
        <img
          src="@/assets/images/remove.svg"
          width="20"
          class="png-teal cursor-pointer ml-2"
          alt="remove"
          @click="portfolioStore.toggleIsAve"
        />
      </el-tooltip>
    </div>

    <!-- user avatar container -->
    <div
      v-if="
        userRouter.currentRoute.value.name != 'OTP Verification Page' &&
        userRouter.currentRoute.value.name != 'Onboarding Page' &&
        userRouter.currentRoute.value.name != 'login'
      "
      class="absolute right-6 top-3 flex flex-row"
    >
      <div>
        <transition
          enter-active-class="transition transform ease-in-out duration-1000"
          enter-from-class="translate-x-32"
          enter-to-class="translate-x-0 hover:scale-150 hover:z-80 hover:mt-2 hover:mr-5"
          leave-active-class="transition transform ease-in-out duration-1000"
          leave-from-class="translate-x-0"
          leave-to-class="translate-x-32"
        >
          <ForwardLooking
            v-if="forwardLookingChange"
            :forwardlooking="oldForwardlooking"
            :ccr_nlr="dashboardStore.dashboards.ccr_nlr"
            :tootltipDisabled="false"
            class="fixed right-4"
            @click="removeOldForwardLookings()"
          />
        </transition>
      </div>

      <div>
        <div>
          <el-icon>
            <transition
              enter-active-class="transition-opacity ease-in-out duration-1000"
              enter-from-class="opacity-0"
              enter-to-class="opacity-100"
              leave-active-class="transition-opacity ease-in-out duration-100"
              leave-from-class="opacity-100"
              leave-to-class="opacity-0"
            >
              <span v-if="forwardLookingChange" class="scale-150 mr-4">
                <Right />
              </span>
            </transition>
          </el-icon>
        </div>
        <div>
          <el-icon
            ><transition
              enter-active-class="transition-opacity ease-in-out duration-1000"
              enter-from-class="opacity-0"
              enter-to-class="opacity-100"
              leave-active-class="transition-opacity ease-in-out duration-100"
              leave-from-class="opacity-100"
              leave-to-class="opacity-0"
            >
              <span v-if="forwardLookingChange" class="scale-150 mr-4">
                <Right />
              </span> </transition
          ></el-icon>
        </div>
      </div>

      <ForwardLooking
        :forwardlooking="forwardlooking"
        :ccr_nlr="dashboardStore.dashboards.ccr_nlr"
        :tootltipDisabled="false"
      />

      <div v-if="!isMainMenu && !portfolioStore.isAve" @click="portfolioStore.toggleIsAve">
        <el-tooltip placement="bottom" :show-after="100" content="Actual vs Expected">
          <img src="@/assets/images/vs.svg" width="25" class="png-teal cursor-pointer ml-3 h-10" alt="vs" />
        </el-tooltip>
      </div>

      <el-dropdown v-if="!isMainMenu" class="ml-3 mt-1">
        <SetUp style="width: 1.8em; height: 1.8em" class="text-black mt-1 hover:text-sybil-teal" />
        <el-button type="primary"> Parameters </el-button>

        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item @click="globalStore.setSeasonalityModal(true)">Seasonality</el-dropdown-item>
            <el-dropdown-item @click="globalStore.setLargeThresholdMethodModal(true)"
              >Large Threshold & Method</el-dropdown-item
            >
            <el-dropdown-item @click="openIsTargetModal()">Target</el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
      <el-tooltip v-if="!isMainMenu && !isBronze" content="Changes timeline" placement="bottom" :show-after="100">
        <img
          src="@/assets/images/timeline.svg"
          width="25"
          class="png-teal cursor-pointer mr-2 ml-3 h-10"
          alt="timeline"
          @click="onOpenAuditLogModal"
        />
      </el-tooltip>

      <img
        v-if="!isMainMenu && !isBronze && !portfolioStore.isBounce"
        src="@/assets/images/letter-b-128.png"
        class="png-teal cursor-pointer mr-2 w-12 h-10"
        alt="bounce"
        @click="globalStore.setBounceModal(true)"
      />
      <img
        v-if="!isMainMenu && (isBronze || portfolioStore.isBounce)"
        src="@/assets/images/letter-b-128.png"
        class="png-gray cursor-not-allowed mr-2 w-12 h-10"
        alt="bounce"
      />

      <!-- user dropdown menu container -->
      <el-dropdown>
        <!-- user avatar button -->
        <div class="py-1 h-10 bg-sybil-charcoal" data-testid="user-avatar-dropdown">
          <div class="-skew-y-12 h-8 w-10 bg-sybil-teal text-white text-center text-xl">
            <div class="skew-y-12">{{ nameInit() }}</div>
          </div>
        </div>
        <!-- user dropdown menue -->
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item data-testid="user-profile-dropdown-selection" @click="userProfile"
              ><i class="pi pi-user" style="font-size: 1.5rem"></i><a class="text-xl ml-5">User profile</a>
            </el-dropdown-item>
            <el-dropdown-item data-testid="log-out-dropdown-selection" @click.prevent="logout"
              ><i class="pi pi-sign-out text-red-700" style="font-size: 1.5rem"></i
              ><a class="text-red-700 text-xl ml-5">Log out</a>
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
  </div>
</template>

<style scoped>
.savedText {
  color: grey;
  text-decoration: underline;
}

.savedTextError {
  color: red;
  text-decoration: underline;
}

.p-avatar-circle {
  background: #55b691;
  color: white;
}

.topmenu {
  left: 0;
  top: 0;
  width: 100%;
  position: fixed;
  z-index: 60;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.sybil-logo {
  z-index: 2500;
}

.png-teal:hover {
  filter: invert(63%) sepia(42%) saturate(423%) hue-rotate(105deg) brightness(92%) contrast(93%);
}

.png-gray {
  filter: invert(69%) sepia(0%) saturate(0%) hue-rotate(220deg) brightness(89%) contrast(89%);
}
</style>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import InputText from 'primevue/inputtext';
import { useActuarialStore } from '@/stores/actuarial';
import { usePortfolioStore } from '@/stores/portfolio';
import openErrorMsg from '@/components/openErrorMsg';
import reverseSearch from '@/utilities/reverseSearch';

/**
 * Sample value: actuarialHierarchy.value['combination']
 * [[1], [2], [3]]
 * where it will be the same index as groupNames
 *
 * Sample values:  groupCombination.value
 * "hierarchy_name": {
 *  "group"
 * }
 *
 * So to save it the database it needed to be convert
 */
function updategv(val, idx, oldVal) {
  // console.log(oldv, newv);
  // console.log(this.gname);
  // console.log(groupNames.value[idx]);
  console.log(val.target.value);
  console.log(oldVal);

  groupNames.value[idx] = val.target.value;
  if (groupNames.value.filter((name) => name === val.target.value).length > 1) {
    groupNames.value[idx] = oldVal;
    openErrorMsg('Duplicated name');
  }
  // if (!this.gname.includes(newv)) {
  //   if (newv == '') {
  //     const idx = Object.values(this.gn).length;
  //     let a = idx + 1;
  //     while (this.gname.includes('Group-' + a)) {
  //       a += 1;
  //     }
  //     newv = 'Group-' + a;
  //   }
  //   this.gname[idx] = newv;
  //   for (let k in this.gnselect) {
  //     if (this.gnselect[k] == oldv) {
  //       this.gnselect[k] = newv;
  //     }
  //   }
  //   this.gn[idx] = this.gname[idx];
  // } else {
  //   if (newv != this.gname[idx]) {
  //     this.gname[idx] = oldv;
  //     this.gname = structuredClone(this.gname);
  //     errorMessage('Duplicated name');
  //   }
  // }
}
function toTitleCase(str: string) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

function onChangeGroup(e: any, val: string) {
  groupCombination.value[val] = e;
}

function addNewGroup() {
  if (groupNames.value[0] == 'All') {
    groupNames.value[0] = 'Group-1';
  }
  let num = 2;
  while (groupNames.value.includes('Group-' + num)) {
    num += 1;
  }

  groupNames.value.push('Group-' + num);
}

function groupAll() {
  groupNames.value = ['All'];
  for (const i of <any>Object.values(portfolio_store.dictionary[currHierarchy.value])) {
    groupCombination.value[i] = 0;
  }
}

function splitAll() {
  groupNames.value = [];
  groupCombination.value = {};
  let idx = 0;
  for (const i of <any>Object.values(portfolio_store.dictionary[currHierarchy.value])) {
    groupNames.value.push(i);
    groupCombination.value[i] = idx;
    idx += 1;
  }
}

function confirm() {
  let converted: string[][] | [][] = Array.from({ length: groupNames.value.length }, () => []);
  for (const i of Object.keys(groupCombination.value)) {
    const val: string = groupCombination.value[i];
    converted[val].push(i);
  }

  // Remove unused names

  let newGroupNames: any = [];
  let newIndex: string[][] = [];
  console.log(converted);
  for (const idx in groupNames.value) {
    const gName: string = groupNames.value[idx];
    const c = converted[idx];

    if (c.length != 0) {
      newGroupNames.push(gName);

      let newIdxTemp: string[] = [];
      for (const a of c) {
        newIdxTemp.push(reverseSearch(portfolio_store.dictionary[currHierarchy.value], a));
      }

      newIndex.push(newIdxTemp);
    }
  }
  actuarial_store.setActuarialHierarchy(currHierarchy.value, newGroupNames, newIndex);
}

function convertCombinationToGroupCombination() {
  let tempCombination = {};
  for (const idx in actuarialHierarchy.value['combinationKey']) {
    const selectedCombination = actuarialHierarchy.value['combinationKey'][idx];
    const groupNames = actuarialHierarchy.value['groupNames'][idx];
    for (const i of selectedCombination) {
      tempCombination[portfolio_store.dictionary[currHierarchy.value][i]] = idx;
    }
  }

  return tempCombination;
}

const portfolio_store = usePortfolioStore();
const actuarial_store = useActuarialStore();

const currHierarchy = computed(() => actuarial_store.currHierarchyOnHierarchyGroupingListModal);

const actuarialHierarchy: any = computed(() =>
  actuarial_store.actuarialHierarchy[currHierarchy.value] ? actuarial_store.actuarialHierarchy[currHierarchy.value] : {}
);

const inputVal = computed({
  // getter
  get() {
    return actuarial_store.isHierarchyGroupingListModal;
  },
  // setter
  set() {
    actuarial_store.changeActuarialGroupingListModal(false);
  },
});

const groupNames: any = ref([]);
const groupCombination: any = ref({});

onMounted(() => {
  groupNames.value = JSON.parse(JSON.stringify(actuarialHierarchy.value['groupNames']));
  groupCombination.value = convertCombinationToGroupCombination();
  createOthersGroup();
  // createOthersGroup()
});

function createOthersGroup() {
  const checkIsInThere = new Set();
  for (const i of <any>Object.keys(groupCombination.value)) {
    checkIsInThere.add(i);
  }

  for (const i of <any>Object.values(portfolio_store.dictionary[currHierarchy.value])) {
    if (!checkIsInThere.has(i)) {
      groupNames.value.push(i);
      groupCombination.value[i] = i;
    }
  }
}
</script>

<template>
  <el-dialog v-model="inputVal" :show-close="false" width="750px">
    <template #header>
      <div class="my-header text-xl">
        <h1>
          <span class="text-sybil-teal font-bold">{{
            toTitleCase(actuarial_store.actuarial_claims_nature[actuarial_store.actuarial_claims_selection].join(', '))
          }}</span>
          claims,
          <span class="text-sybil-teal font-bold">{{ currHierarchy }}</span>
          grouping
        </h1>
      </div>
    </template>

    <div class="flex flex-row h-full w-full">
      <div>
        <el-space wrap>
          <el-card class="box-card" style="height: 450px; width: 300px">
            <template #header>
              <div class="card-header text-xl" style="height: 30px">
                <span>Groups</span>
                <el-button class="button" text @click="addNewGroup">Add new group</el-button>
              </div>
            </template>
            <el-scrollbar height="400px" class="text-lg">
              <div v-for="(val, idx) in groupNames" :key="idx" class="mt-3">
                <InputText
                  :key="val"
                  :value="groupNames[idx]"
                  class="radius-0 w-full h-10"
                  @focusout="(e) => updategv(e, idx, val)"
                />
              </div>
              <div class="h-16"></div>
            </el-scrollbar>
          </el-card>
        </el-space>
      </div>
      <div>
        <el-space wrap>
          <el-card class="box-card" style="height: 450px; width: 400px">
            <template #header>
              <div class="card-header text-xl">
                <span>Group allocation</span>
              </div>
            </template>
            <el-scrollbar height="400px">
              <div
                v-for="(val, idx) in <any>Object.values(portfolio_store.dictionary[currHierarchy])"
                :key="idx"
                class="scrollbar-demo-item"
              >
                <div v-if="val != ''">
                  {{ val }}
                  <el-select
                    :model-value="groupNames[groupCombination[val]]"
                    class="text-center w-full shadow-sm"
                    @change="(e) => onChangeGroup(e, val)"
                  >
                    <el-option v-for="(v, i) in groupNames" :key="i" :label="v" :value="i" />
                  </el-select>
                </div>
              </div>
              <div class="h-12"></div>
            </el-scrollbar>
          </el-card>
        </el-space>
      </div>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" data-testid="grouping-modal-group-all" plain @click="groupAll()">Group all</el-button>
        <el-button type="primary" data-testid="grouping-modal-split-all" plain @click="splitAll()">Split all</el-button>
        <el-button @click="inputVal = false">Cancel</el-button>
        <el-button
          type="primary"
          data-testid="grouping-modal-confirm"
          @click="
            confirm();
            inputVal = false;
          "
          >Confirm</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>

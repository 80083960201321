<script setup lang="ts">
import { useActionsStore } from '@/stores/actions';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import { onMounted, ref, computed } from 'vue';
import { useGlobalStore } from '@/stores/global';
import type { StatusObject } from '@/types/actions';
import { openErrorMsg } from '@/utilities/errorhandler';

const globalStore = useGlobalStore();
const actionsStore = useActionsStore();

onMounted(() => {
  fetchStatusList();
});

async function fetchStatusList() {
  globalStore.isLoading = true;
  await actionsStore
    .fetchStatusList()
    .then(() => {
      globalStore.isLoading = false;
    })
    .catch(() => {
      globalStore.isLoading = false;
    });
}

function handleDeleteStatus(i: number) {
  console.log(i);
  actionsStore.deleteStatus(actionsStore.statusList[i].id);
}

function addStatus() {
  if (!statusName.value || !statusColor.value) {
    openErrorMsg('Please fill in all fields');
    return;
  }
  actionsStore.addStatus({
    name: statusName.value,
    color: statusColor.value,
  });
}

const statusName = ref('');
const statusColor = ref('');
const currentStatus = computed<StatusObject[]>(() => actionsStore.statusList);
</script>
<template>
  <el-dialog v-model="actionsStore.isEditStatusModal" :width="650" style="height: 500px" title="Edit Status">
    <TabView>
      <TabPanel header="Add new status">
        <div class="grid grid-cols-2 gap-2">
          <div>
            <h1>Status Name:</h1>
            <el-input
              v-model="statusName"
              type="text"
              class="w-full"
              style="height: 30px"
              maxlength="100"
              show-word-limit
            />
          </div>
          <div class="grid place-content-center">
            <h1 class="place-self-center">Select colour:</h1>
            <el-color-picker v-model="statusColor" class="place-self-center" color-format="hex" />
          </div>
        </div>
        <div class="flex justify-end mt-2">
          <el-button @click="addStatus()">Add Status</el-button>
        </div>
      </TabPanel>
      <TabPanel header="Edit Status">
        <el-table :data="currentStatus" style="width: 100%">
          <el-table-column prop="name" label="Name" width="120" />
          <el-table-column prop="name" label="Name" width="120">
            <template #default="scope">
              <el-color-picker disabled :model-value="currentStatus[scope.$index].color" />
            </template>
          </el-table-column>
          <el-table-column align="right">
            <template #default="scope">
              <el-button size="small" type="danger" @click="handleDeleteStatus(scope.$index)">Delete</el-button>
            </template>
          </el-table-column>
        </el-table>
      </TabPanel>
    </TabView>
  </el-dialog>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { useAuthenticationStore } from '@/stores/auth';
import { openSuccessMsg, openErrorMsg } from '@/utilities/notificationMessage';
import LoaderComponent from '@/components/LoaderComponent.vue';
import { api } from '../../services/api';
import QrcodeVue from 'qrcode.vue';
import errorHandler from '@/utilities/errorhandler';

// const store = useStore();

const authStore = useAuthenticationStore();
const loading = ref(false);

const isOTPRequired = ref(false);

const otp = ref('');
const key = ref('');

const currNameData = ref({
  firstName: '',
  lastName: '',
  email: '',
  domain: '',
  emailPrefix: '',
});

async function removeMultiFactor() {
  loading.value = true;
  console.log('removed');
  await api
    .post(import.meta.env.VITE_API_URL + 'users/delete_OTP', {
      otp: otp.value,
    })
    .then((res) => {
      otp.value = '';
      isOTPRequired.value = false;
      authStore.loginSuccess(res.data.data.user);
      isOTP.value = authStore.user.is_OTP;
      check();
      openSuccessMsg('Success!');
      loading.value = false;
    })
    .catch((err) => {
      if (err.response && err.response.data) {
        openErrorMsg(err.response.data.data);
      } else {
        openErrorMsg('Error! Please try again');
      }
      loading.value = false;
    });
}

async function confirmMultiFactor() {
  loading.value = true;
  await api
    .post(import.meta.env.VITE_API_URL + 'users/' + 'confirm_OTP', {
      otp: otp.value,
      key: key.value,
    })
    .then((res) => {
      otp.value = '';
      key.value = '';
      authStore.loginSuccess(res.data.data.user);
      check();
      qrCode.value = null;
      isOTP.value = authStore.user.is_OTP;
      openSuccessMsg('Success!');
      loading.value = false;
    })
    .catch((err) => {
      if (err.response && err.response.data) {
        openErrorMsg(err.response.data.data);
      } else {
        openErrorMsg('Error! Please try again');
      }
      loading.value = false;
    });
}

const errors = ref({
  wrongConfirmPassword: false,
  invalidPassword: false,
});

const newPassword = ref({
  currPassword: '',
  newPassword: '',
  confirmPassword: '',
});

const isLoaded = ref(false);
const isOTP = ref(false);
const qrCode = ref(null);

async function enableMultiFactor() {
  loading.value = true;
  await api
    .post(import.meta.env.VITE_API_URL + 'users/' + 'create_OTP')
    .then((res) => {
      qrCode.value = res.data.data.totp_link;
      key.value = res.data.data.key;
      loading.value = false;
    })
    .catch(() => {
      openErrorMsg('Error! Please try again');
      loading.value = false;
    });
}

const portfolioInsightsNewsletters = ref(false);

onMounted(async () => {
  check();
  getNewsletterStatus();
  isLoaded.value = true;
});

function check() {
  const email = authStore.user.email;
  const emailPrefix = email.substring(0, email.lastIndexOf('@'));
  const domain = email.substring(email.lastIndexOf('@'));
  isOTP.value = authStore.user.is_OTP;
  if (authStore.user) {
    currNameData.value.firstName = authStore.user.firstName;
    currNameData.value.lastName = authStore.user.lastName;
    currNameData.value.emailPrefix = emailPrefix;
    currNameData.value.domain = domain;
  }
}

async function getNewsletterStatus() {
  loading.value = true;
  await api
    .get(import.meta.env.VITE_API_URL + 'users/newsletter-options')
    .then((res) => {
      loading.value = false;
      portfolioInsightsNewsletters.value = res.data.data.portfolio_insights_newsletters;
    })
    .catch((err) => {
      loading.value = false;
      errorHandler(err);
    });
}

async function submitNewsletter() {
  loading.value = true;
  await api
    .post(import.meta.env.VITE_API_URL + 'users/newsletter-options', {
      portfolio_insights_newsletters: portfolioInsightsNewsletters.value,
    })
    .then(() => {
      openSuccessMsg('Success!');
      loading.value = false;
    })
    .catch((err) => {
      loading.value = false;
      errorHandler(err);
    });
}

async function submitNameAndEmail() {
  const email = currNameData.value.emailPrefix.trim() + currNameData.value.domain;
  const data = {
    first_name: currNameData.value.firstName.trim(),
    last_name: currNameData.value.lastName.trim(),
    email: email.trim(),
  };
  loading.value = true;
  await api
    .patch(import.meta.env.VITE_API_URL + 'users/edit-self-information', data)
    .then((response) => {
      if (response.status == 200) {
        authStore.updateUser(response.data.data);
      }
      openSuccessMsg(response.data.data.message);
    })
    .catch((err) => {
      loading.value = false;
      if (err.response && err.response.data) {
        openErrorMsg(err.response.data.data);
      } else {
        openErrorMsg('Failed');
      }
    });
  loading.value = false;
}

async function submitPassword() {
  newPassword.value.newPassword = newPassword.value.newPassword.trim();
  newPassword.value.confirmPassword = newPassword.value.confirmPassword.trim();
  newPassword.value.currPassword = newPassword.value.currPassword.trim();
  if (newPassword.value.newPassword == '') {
    errors.value.invalidPassword = true;
    return;
  }
  errors.value.invalidPassword = false;

  if (newPassword.value.newPassword != newPassword.value.confirmPassword) {
    errors.value.wrongConfirmPassword = true;
    return;
  }
  errors.value.wrongConfirmPassword = false;
  loading.value = true;
  await api
    .patch(import.meta.env.VITE_API_URL + 'users/' + 'change_password', {
      password_check: newPassword.value.currPassword,
      password_new: newPassword.value.newPassword,
    })
    .then(() => {
      openSuccessMsg('Success!');
      loading.value = false;
    })
    .catch((e) => {
      errorHandler(e);
      loading.value = false;
    });
}
</script>
<template>
  <div>
    <LoaderComponent v-if="loading" />
    <div class="absolute w-screen top-0 left-0 bg-gray-200 font-graphie">
      <div class="ml-10 pt-24">
        <!-- PROFILE INFORMATION -->
        <div class="max-w-6xl mx-auto py-10 sm:px-6 lg:px-8">
          <div class="md:grid md:grid-cols-3 md:gap-6">
            <div class="md:col-span-1 flex justify-between">
              <div class="px-4 sm:px-0">
                <h3 class="text-lg font-medium text-gray-900">Profile Information</h3>

                <p class="mt-1 text-sm text-gray-600">Update your account's profile information and email address.</p>
              </div>
            </div>
            <div class="mt-5 md:mt-0 md:col-span-2">
              <form>
                <div class="px-4 py-5 bg-white sm:p-6 shadow sm:rounded-tl-md sm:rounded-tr-md">
                  <div class="grid grid-cols-6 gap-6">
                    <!-- Name -->
                    <div class="col-span-6 sm:col-span-4">
                      <label class="block font-medium text-sm text-gray-700"> First Name </label>
                      <input
                        v-model="currNameData.firstName"
                        data-testid="first-name-input"
                        class="border-gray-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 rounded-md shadow-sm mt-1 block w-full"
                        type="text"
                      />
                    </div>
                    <div class="col-span-6 sm:col-span-4">
                      <label class="block font-medium text-sm text-gray-700"> Last Name </label>
                      <input
                        v-model="currNameData.lastName"
                        data-testid="last-name-input"
                        class="border-gray-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 rounded-md shadow-sm mt-1 block w-full"
                        type="text"
                      />
                    </div>

                    <!-- Email -->
                    <div class="col-span-6 sm:col-span-4">
                      <label class="block font-medium text-sm text-gray-700"> Email </label>
                      <div class="flex flex-row items-center">
                        <input
                          v-model="currNameData.emailPrefix"
                          data-testid="email-input"
                          class="border-gray-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 rounded-md shadow-sm mt-1 block w-full"
                          type="text"
                        />
                        <p class="ml-1">{{ currNameData.domain }}</p>
                      </div>
                    </div>
                    <div class="col-span-6 sm:col-span-4">
                      <h3 v-if="authStore.client.is_sso">This account is managed by Azure</h3>
                    </div>
                    <div
                      data-testid="save-button-for-email-and-name"
                      class="col-span-6 sm:col-span-4"
                      @click="submitNameAndEmail"
                    >
                      <el-button class="justify-end">Save</el-button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <!-- UPDATE PASSWORD -->
        <div v-if="!authStore.client.is_sso" class="max-w-6xl mx-auto py-10 sm:px-6 lg:px-8">
          <div class="md:grid md:grid-cols-3 md:gap-6">
            <div class="md:col-span-1 flex justify-between">
              <div class="px-4 sm:px-0">
                <h3 class="text-lg font-medium text-gray-900">Update Password</h3>

                <p class="mt-1 text-sm text-gray-600">
                  Ensure your account is using a long, random password to stay secure.
                </p>
              </div>
            </div>
            <div class="mt-5 md:mt-0 md:col-span-2">
              <form>
                <div class="px-4 py-5 bg-white sm:p-6 shadow sm:rounded-tl-md sm:rounded-tr-md">
                  <div class="grid grid-cols-6 gap-6">
                    <!-- Name -->
                    <div class="col-span-6 sm:col-span-4">
                      <label class="block font-medium text-sm text-gray-700"> Current Password </label>
                      <input
                        v-model="newPassword.currPassword"
                        class="border-gray-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 rounded-md shadow-sm mt-1 block w-full"
                        type="password"
                        autocomplete="name"
                      />
                    </div>

                    <!-- Email -->
                    <div class="col-span-6 sm:col-span-4">
                      <label class="block font-medium text-sm text-gray-700"> New Password </label>
                      <input
                        v-model="newPassword.newPassword"
                        class="border-gray-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 rounded-md shadow-sm mt-1 block w-full"
                        :class="{ 'border-red-500': errors.invalidPassword }"
                        type="password"
                      />
                      <p v-if="errors.invalidPassword" class="text-red-600">Please enter a valid password.</p>
                    </div>
                    <div v-if="newPassword.newPassword" class="col-span-6 sm:col-span-4">
                      <label class="block font-medium text-sm text-gray-700"> Confirm Password </label>
                      <input
                        v-model="newPassword.confirmPassword"
                        class="border-gray-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 rounded-md shadow-sm mt-1 block w-full"
                        type="password"
                        :class="{
                          'border-red-500': errors.wrongConfirmPassword,
                        }"
                      />
                      <p v-if="errors.wrongConfirmPassword" class="text-red-600">
                        The password confirmation does not match.
                      </p>
                    </div>
                    <div class="col-span-6 sm:col-span-4">
                      <el-button
                        :disabled="
                          newPassword.confirmPassword == '' ||
                          newPassword.newPassword == '' ||
                          newPassword.currPassword == ''
                        "
                        class="justify-end"
                        @click="submitPassword"
                        >Save</el-button
                      >
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="max-w-6xl mx-auto py-10 sm:px-6 lg:px-8">
          <div class="md:grid md:grid-cols-3 md:gap-6">
            <div class="md:col-span-1 flex justify-between">
              <div class="px-4 sm:px-0">
                <h3 class="text-lg font-medium text-gray-900">Email Preferences</h3>
                <p class="mt-1 text-sm text-gray-600">
                  Manage your email notification settings to receive updates that matter to you.
                </p>
              </div>
            </div>
            <div class="mt-5 md:mt-0 md:col-span-2">
              <form>
                <div class="px-4 py-5 bg-white sm:p-6 shadow sm:rounded-tl-md sm:rounded-tr-md">
                  <el-checkbox v-model="portfolioInsightsNewsletters">Portfolio Insights</el-checkbox>
                </div>
                <div class="px-4 py-5 bg-white sm:p-6 shadow sm:rounded-tl-md sm:rounded-tr-md">
                  <el-button @click="submitNewsletter">Save</el-button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <!-- Create OTP -->
        <div v-if="isLoaded && !authStore.client.is_sso" class="max-w-6xl mx-auto py-10 sm:px-6 lg:px-8">
          <div class="md:grid md:grid-cols-3 md:gap-6">
            <div class="md:col-span-1 flex justify-between">
              <div class="px-4 sm:px-0">
                <h3 class="text-lg font-medium text-gray-900">Multi-factor authentication</h3>
                <p class="mt-1 text-sm text-gray-600">Enable multi-factor authentication to protect your account.</p>
              </div>
            </div>
            <div class="mt-5 md:mt-0 md:col-span-2">
              <form>
                <div class="px-4 py-5 bg-white sm:p-6 shadow sm:rounded-tl-md sm:rounded-tr-md">
                  <div v-if="!isOTP">
                    <!-- <div> -->
                    <div v-if="!qrCode" class="col-span-6 sm:col-span-4">
                      <el-button class="justify-end" @click="enableMultiFactor"
                        >Enable multi-factor authentication</el-button
                      >
                    </div>
                    <div v-else>
                      <div class="grid grid-cols-2">
                        <div>
                          <qrcode-vue :value="qrCode" :size="150" level="H" />
                        </div>
                        <div>
                          <h1>1. Download Google Authenticator on Play Store/App Store</h1>
                          <h1 class="mt-2">2. Click add (plus icon bottom right) and scan a QR code</h1>
                          <h1 class="mt-2">3. Insert the authenticator code below and click confirm</h1>
                          <el-input v-model="otp" placeholder="Insert the verification code shown" class="mt-3 mb-2" />
                          <el-button class="mt-2 flex flex-end" @click="confirmMultiFactor">Confirm</el-button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else class="grid grid-cols-6 gap-6">
                    <div class="col-span-6 sm:col-span-4">
                      <div v-if="!isOTPRequired" class="flex flex-row">
                        <el-button class="justify-end" @click="isOTPRequired = true"
                          >Remove multi-factor authentication</el-button
                        >
                      </div>
                      <div v-else>
                        <el-input v-model="otp" placeholder="Insert the verification code shown" class="mt-3 mb-2" />
                        <el-button class="mt-2 flex flex-end" @click="removeMultiFactor">Confirm</el-button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

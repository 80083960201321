const monthColors:any = {
    "01": "#85acbe",
    "02": "#859bbe",
    "03": "#858abe",
    "04": "#9185be",
    "05": "#a285be",
    "06": "#b385be",
    "07": "#be85b7",
    "08": "#be85a6",
    "09": "#be8595",
    "10": "#be8685",
    "11": "#be9785",
    "12": "#bea885",
}

export default monthColors
<script setup lang="ts">
import { usePortfolioStore } from '@/stores/portfolio';
import { useDashboardStore } from '@/stores/dashboard';
import { CaretRight, CaretBottom, Edit, PieChart, Download, EditPen } from '@element-plus/icons-vue';
import { watch, ref, onMounted, computed } from 'vue';
import { SELECTION_FROM_PAGE, COHORT_CONSTANT, useActuarialStore } from '@/stores/actuarial';

const actuarial_store = useActuarialStore();

const emit = defineEmits(['chunkSetup', 'tierSetup', 'valuesSetup', 'resizeFilter', 'onDownloadExcel']);

const cohort = computed(() => actuarial_store.cohort);

function isSavedButtonRequired() {
  // console.log(actuarial_store.exclusionStack[0])
  // if ((actuarial_store.exclusionStack[0].length != 0 && actuarial_store.exclusionStack[1].length != 0)) {
  if (
    actuarial_store.isLinkRatioChanged ||
    actuarial_store.isTriangleChanged ||
    actuarial_store.isUltimateSelectionChanged
  ) {
    return true;
  }

  // } else {
  // return false
  // }
}

function setTriangleType(e: any) {
  actuarial_store.onSetTriangleType(e, SELECTION_FROM_PAGE[0]);
  // console.log(e)
}

const development = computed(() => actuarial_store.development);
const DEVELOPMENT_CONSTANT = ['Monthly', 'Quarterly', 'Yearly'];

const ACCIDENT_UNDERWRITING = ['Accident', 'Underwriting'];
const INCURRED_PAID_NUMBER = ['Incurred Claims Triangles', 'Paid Claims Triangles', 'Count of Claims Triangles'];
const INCURRED_PAID_NUMBER_VALUE = ['INCURRED', 'PAID', 'COUNT'];
const actuarialAccidentUnderwriting = computed(() => actuarial_store.actuarial_accident_underwriting);

function getSignedOff() {
  const currentGroup = actuarial_store.getSelectedGroup();
  return (
    portfolio_store.signedOff['actuarial_module'] &&
    portfolio_store.signedOff['actuarial_module'][currentGroup.join(',')]
  );
}

function updateAccidentUnderwriting(e: any) {
  actuarial_store.updateAccidentUnderwriting(e);
}

function updateActuarialClaimsSelection(e: any) {
  actuarial_store.setActuarialClaimsSelection(e, SELECTION_FROM_PAGE[0]);
}

function setCohort(e: number) {
  actuarial_store.setCohort(e);
}

function setDevelopment(e: number) {
  actuarial_store.setDevelopment(e);
}

function triggerHierarchyGroupingListModal(hierarchy: string) {
  actuarial_store.changeCurrHierarchyOnHierarchyGroupingListModal(hierarchy);
  actuarial_store.changeActuarialGroupingListModal(true);
}

function triggerHierarchyPercentageModal(hierarchy: string) {
  actuarial_store.changeCurrHierarchyOnHierarchyGroupingListModal(hierarchy);
  actuarial_store.changeActuarialPercentageModal(true);
}

function onSelectHierarchyChunk(e, hierarchy) {
  actuarial_store.setHierarchySelection(hierarchy, e, SELECTION_FROM_PAGE[0]);
}

const props = defineProps(['filtersWidth']);

const tierGroupExpand = ref(false);

function toTitleCase(str: string) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

function resizeFilter() {
  emit('resizeFilter');
}

function onDownloadExcel() {
  emit('onDownloadExcel');
}

function chunkSetup() {
  actuarial_store.change_tiering_group_modal(true);
}

const beforeEnter = (el) => {
  el.style.height = '0';
};

const enter = (el) => {
  el.style.height = el.scrollHeight + 'px';
};

const beforeLeave = (el) => {
  el.style.height = el.scrollHeight + 'px';
};

const leave = (el) => {
  el.style.height = '0';
};

const portfolio_store = usePortfolioStore();
const dashboard_store = useDashboardStore();

const chunkRadio = ref([]);
const graphSelectionRadio = ref(dashboard_store.graphSelectionRadio);

const hierarchyTierGroupExpand = ref({});
const cohortExpand = ref(true);
const devExpand = ref(true);
const allHierarchies: any = ref([]);
const incurredPaidNumberExpand = ref(true);
const accidentUnderwritingExpand = ref(true);

onMounted(async () => {
  let hierarchyTierGroupExpandTemp = {};
  let allHierarchiesTemp: any = [];
  for (const i of [...portfolio_store.parameters['hierarchies'], ...portfolio_store.parameters['actuarial_grouping']]) {
    hierarchyTierGroupExpandTemp[i] = false;
    allHierarchiesTemp.push(i);
  }
  allHierarchies.value = allHierarchiesTemp;
  hierarchyTierGroupExpand.value = hierarchyTierGroupExpandTemp;
  dashboard_store.setGraphSelectionRadio(graphSelectionRadio.value);
  chunkRadio.value = [];
});
</script>

<template>
  <div class="sidenav main-sidebars shadow">
    <!-- <h1>{{sideBarData}}</h1> -->
    <div class="flex relative flex-row place-items-center sticky top-0 bg-white py-2 z-20">
      <div v-if="actuarial_store.trianglesLoading && props.filtersWidth === '300px'" class="relative w-3/4 ml-5 h-8">
        Calculation in Progress
        <el-progress :percentage="100" status="warning" :indeterminate="true" :duration="1" class="w-full" />
      </div>
      <div v-else>
        <el-button
          v-if="props.filtersWidth === '300px'"
          key="primary"
          type="primary"
          class="text-center w-5 absolute left-3 top-3"
          @click="onDownloadExcel()"
        >
          <el-icon><Download /></el-icon>
        </el-button>

        <el-tooltip v-if="props.filtersWidth === '300px'" content="Signed Off" placement="bottom" :show-after="1000">
          <el-button
            key="primary"
            :type="getSignedOff() ? 'primary' : 'danger'"
            class="text-center w-5 absolute left-10 top-3"
            @click="portfolio_store.signedOffActuarial"
          >
            <el-icon><EditPen /></el-icon>
          </el-button>
        </el-tooltip>

        <el-tooltip
          v-if="props.filtersWidth === '300px' && isSavedButtonRequired()"
          content="Signed Off"
          placement="bottom"
          :show-after="1000"
        >
          <el-button
            key="primary"
            type="primary"
            class="text-center w-20 absolute left-20 top-3"
            data-testid="save-triangulations-data"
            @click="actuarial_store.saveTriangulationsData"
          >
            Save
          </el-button>
        </el-tooltip>
      </div>

      <div v-if="props.filtersWidth === '300px'" class="h-8 mr-10 ml-2"></div>
      <i
        class="pi pi-arrows-h ml-2 cursor-pointer absolute top-4 right-2"
        style="font-size: 20px"
        @click="resizeFilter"
      ></i>
    </div>
    <template v-if="props.filtersWidth === '300px'">
      <div>
        <div class="side-panel-title">
          <el-icon class="pt-3 cursor-pointer ml-1" @click="accidentUnderwritingExpand = !accidentUnderwritingExpand">
            <CaretRight v-if="accidentUnderwritingExpand" class="text-gray-400 text-sm" />
            <CaretBottom v-if="!accidentUnderwritingExpand" class="text-gray-400 text-sm" />
          </el-icon>
          <span
            class="text-sybil-charcoal -mt-2 ml-1 cursor-pointer"
            data-testid="accident-underwriting-expand"
            @click="accidentUnderwritingExpand = !accidentUnderwritingExpand"
            >{{ 'Accident / Underwriting' }}</span
          >
        </div>
        <transition
          v-if="!accidentUnderwritingExpand"
          :key="actuarial_store.renderKeySidePanel"
          data-testid="accident-underwriting-div"
          @before-enter="beforeEnter"
          @enter="enter"
          @before-leave="beforeLeave"
          @leave="leave"
        >
          <el-radio-group
            data-testid="accident-underwriting"
            :model-value="actuarialAccidentUnderwriting"
            @change="updateAccidentUnderwriting"
          >
            <el-radio
              v-for="(val, idx) in ACCIDENT_UNDERWRITING"
              :key="idx + '-accident-underwriting'"
              :label="idx"
              size="large"
              class="hover:bg-gray-200 px-3 w-full"
            >
              {{ val }}
            </el-radio>
          </el-radio-group>
          <!-- </div> -->
        </transition>
      </div>
      <div>
        <div class="side-panel-title">
          <el-icon class="pt-3 cursor-pointer ml-1" @click="incurredPaidNumberExpand = !incurredPaidNumberExpand">
            <CaretRight v-if="incurredPaidNumberExpand" class="text-gray-400 text-sm" />
            <CaretBottom v-if="!incurredPaidNumberExpand" class="text-gray-400 text-sm" />
          </el-icon>
          <span
            class="text-sybil-charcoal -mt-2 ml-1 cursor-pointer"
            data-testid="incurred-paid-expand"
            @click="incurredPaidNumberExpand = !incurredPaidNumberExpand"
            >{{ 'Triangles Type' }}</span
          >
        </div>
        <transition
          v-if="!incurredPaidNumberExpand"
          @before-enter="beforeEnter"
          @enter="enter"
          @before-leave="beforeLeave"
          @leave="leave"
        >
          <el-radio-group
            class="chunk-group-elements"
            data-testid="incurred-paid-number"
            :model-value="actuarial_store.triangleType"
            @change="setTriangleType"
          >
            <el-radio
              v-for="(val, idx) in INCURRED_PAID_NUMBER"
              :key="idx + '-incurred-paid-number'"
              :label="INCURRED_PAID_NUMBER_VALUE[idx]"
              size="large"
              class="hover:bg-gray-200 px-3 w-full"
            >
              {{ val }}
            </el-radio>
          </el-radio-group>
          <!-- </div> -->
        </transition>
      </div>
      <div>
        <div class="side-panel-title">
          <el-icon class="pt-3 cursor-pointer ml-1" @click="tierGroupExpand = !tierGroupExpand">
            <CaretRight v-if="!tierGroupExpand" class="text-gray-400 text-sm" />
            <CaretBottom v-if="tierGroupExpand" class="text-gray-400 text-sm" />
          </el-icon>
          <span class="text-sybil-charcoal -mt-2 ml-1 cursor-pointer" @click="tierGroupExpand = !tierGroupExpand"
            >Claims Type</span
          >
          <Edit
            style="width: 1.2em; height: 1.2em"
            data-testid="edit-claims-type"
            class="absolute right-2 top-1 cursor-pointer ml-2 hover:text-sybil-teal"
            @click="chunkSetup()"
          />
        </div>
        <transition
          :key="actuarial_store.renderKeySidePanel"
          @before-enter="beforeEnter"
          @enter="enter"
          @before-leave="beforeLeave"
          @leave="leave"
        >
          <div v-if="tierGroupExpand" class="chunk-group-elements">
            <el-radio-group
              class="chunk-group-elements"
              :model-value="actuarial_store.actuarial_claims_selection"
              @change="updateActuarialClaimsSelection"
            >
              <div>
                <el-radio
                  v-for="(item, idx) in actuarial_store.actuarial_claims_nature"
                  :key="item.join(', ')"
                  :label="idx"
                  size="large"
                  class="hover:bg-gray-200 px-3 w-full"
                >
                  {{ toTitleCase(item.join(', ')) }}
                </el-radio>
              </div>
            </el-radio-group>
          </div>
        </transition>
      </div>
      <div
        v-for="(hierarchy, indexHierarchies) of allHierarchies"
        :key="indexHierarchies + '-hierarchies'"
        data-testid="hierarchies-dropdown"
      >
        <div class="side-panel-title">
          <el-icon
            class="pt-3 cursor-pointer ml-1"
            data-testid="hierarchy-icon"
            @click="hierarchyTierGroupExpand[hierarchy] = !hierarchyTierGroupExpand[hierarchy]"
          >
            <CaretRight v-if="!hierarchyTierGroupExpand[hierarchy]" class="text-gray-400 text-sm" />
            <CaretBottom v-if="hierarchyTierGroupExpand[hierarchy]" class="text-gray-400 text-sm" />
          </el-icon>
          <span
            class="text-sybil-charcoal -mt-2 ml-1 cursor-pointer"
            data-testid="hierarchy-span"
            @click="hierarchyTierGroupExpand[hierarchy] = !hierarchyTierGroupExpand[hierarchy]"
            >{{ hierarchy }}</span
          >
          <PieChart
            v-if="portfolio_store.parameters['actuarial_grouping'].indexOf(hierarchy) > -1"
            style="width: 1.2em; height: 1.2em"
            class="absolute right-8 top-1 cursor-pointer ml-2 hover:text-sybil-teal"
            @click="triggerHierarchyPercentageModal(hierarchy)"
          />
          <Edit
            data-testid="edit-grouping-hierarchy"
            style="width: 1.2em; height: 1.2em"
            class="absolute right-2 top-1 cursor-pointer ml-2 hover:text-sybil-teal"
            @click="triggerHierarchyGroupingListModal(hierarchy)"
          />
        </div>
        <transition
          :key="actuarial_store.renderKeySidePanel"
          @before-enter="beforeEnter"
          @enter="enter"
          @before-leave="beforeLeave"
          @leave="leave"
        >
          <el-radio-group
            v-if="hierarchyTierGroupExpand[hierarchy]"
            :model-value="actuarial_store.hierarchySelection[hierarchy]"
            class="chunk-group-elements"
            @change="(e) => onSelectHierarchyChunk(e, hierarchy)"
          >
            <div
              v-if="
                actuarial_store.actuarialHierarchy[hierarchy] &&
                actuarial_store.actuarialHierarchy[hierarchy]['groupNames']
              "
            >
              <el-radio
                v-for="(val, idx) in actuarial_store.actuarialHierarchy[hierarchy]['groupNames']"
                :key="idx + '-groupnames'"
                :label="idx"
                size="large"
                class="hover:bg-gray-200 px-3 w-full"
              >
                {{ val }}
              </el-radio>
            </div>
          </el-radio-group>
          <!-- </div> -->
        </transition>
      </div>
      <div>
        <div class="side-panel-title">
          <el-icon class="pt-3 cursor-pointer ml-1" @click="cohortExpand = !cohortExpand">
            <CaretRight v-if="cohortExpand" class="text-gray-400 text-sm" />
            <CaretBottom v-if="!cohortExpand" class="text-gray-400 text-sm" />
          </el-icon>
          <span
            data-testid="cohort-expand"
            class="text-sybil-charcoal -mt-2 ml-1 cursor-pointer"
            @click="cohortExpand = !cohortExpand"
            >{{ 'Cohort' }}</span
          >
        </div>
        <transition
          v-if="!cohortExpand"
          data-testid="cohort-div"
          @before-enter="beforeEnter"
          @enter="enter"
          @before-leave="beforeLeave"
          @leave="leave"
        >
          <el-radio-group :model-value="cohort" class="chunk-group-elements" @change="setCohort">
            <el-radio
              v-for="(val, idx) in COHORT_CONSTANT"
              :key="idx + '-cohort'"
              :label="idx"
              size="large"
              class="hover:bg-gray-200 px-3 w-full"
            >
              {{ val }}
            </el-radio>
          </el-radio-group>
          <!-- </div> -->
        </transition>
      </div>
      <div>
        <div class="side-panel-title">
          <el-icon class="pt-3 cursor-pointer ml-1" @click="devExpand = !devExpand">
            <CaretRight v-if="devExpand" class="text-gray-400 text-sm" />
            <CaretBottom v-if="!devExpand" class="text-gray-400 text-sm" />
          </el-icon>
          <span class="text-sybil-charcoal -mt-2 ml-1 cursor-pointer" @click="devExpand = !devExpand">{{
            'Development'
          }}</span>
        </div>
        <transition
          v-if="!devExpand"
          @before-enter="beforeEnter"
          @enter="enter"
          @before-leave="beforeLeave"
          @leave="leave"
        >
          <el-radio-group class="chunk-group-elements" :model-value="development" @change="setDevelopment">
            <el-radio
              v-for="(val, idx) in DEVELOPMENT_CONSTANT"
              :key="idx + '-development'"
              :label="idx"
              size="large"
              class="hover:bg-gray-200 px-3 w-full"
              :disabled="idx > actuarial_store.cohort"
            >
              {{ val }}
            </el-radio>
          </el-radio-group>
          <!-- </div> -->
        </transition>
      </div>
    </template>
  </div>
</template>

<style setup>
.chunk-group-elements {
  overflow: hidden;
  transition: height 0.2s ease-in-out;
}

.sidenav {
  height: 100%;
  position: fixed;
  right: 0;
  border-left: 1px solid #eeeeee;
  background-color: white;
  overflow-x: hidden;
  overflow-y: scroll;

  padding-bottom: 6rem;
}

.sidenav #filter {
  text-align: center;
  padding: 2px 0px 2px 16px;
  font-size: 18px;
  display: block;
  text-align: center;
}

.sidenav #filter-choice:hover {
  padding: 0px 0px 0px 22px;
  font-size: 13px;
  display: block;
  background-color: #e2e8f0;
}

.sidenav #filter-choice {
  padding: 0px 0px 0px 22px;
  font-size: 11px;
  display: block;
}

.side-panel-title {
  height: 30px;

  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));

  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;

  position: sticky;
  top: 3rem;
  font-size: 18px;
  z-index: 10;
}

.is-item {
  font-size: 12px;
  display: block;
  overflow: hidden;
  white-space: normal;
}

.is-item:hover {
  font-size: 14px;
}

@keyframes scroll-right {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-100%);
    /* Adjust to match content width */
  }
}

.tracker {
  font-size: 11px;
}

.panel-teal {
  filter: invert(63%) sepia(42%) saturate(423%) hue-rotate(105deg) brightness(92%) contrast(93%);
}

.panel-teal:hover {
  filter: none;
}

.chunk-group-elements {
  overflow: hidden;
  transition: height 0.2s ease-in-out;
}
</style>

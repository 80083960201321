<script setup lang="ts">
import { useUnderwritingStore } from '@/stores/underwriting';
import { computed, ref, onMounted, nextTick } from 'vue';
import { usePortfolioStore } from '@/stores/portfolio';
import { useGlobalStore } from '@/stores/global';
import { useActuarialStore } from '@/stores/actuarial';
import { ElTree } from 'element-plus';
import { api } from '@/services/api';
import { ElMessage } from 'element-plus';
import openErrorMsg from '@/components/openErrorMsg';

const htree = ref<InstanceType<typeof ElTree>>();
const underwritingStore = useUnderwritingStore();
const portfolioStore = usePortfolioStore();
const globalStore = useGlobalStore();
const hierarchyOrderData = ref(underwritingStore.hierarchyOrder);
const parameters = computed(() => portfolioStore.parameters);
// const defaultSelection = ref(getDefaultSelection())
const defaultSelection = ref(false);
const isDisabled = ref(false);

onMounted(() => {
  nextTick(() => {
    defaultSelection.value = getDefaultSelection();
    isDisabled.value = getDefaultSelection();
  });
});

function openSuccessMsg(msg: string) {
  ElMessage({
    message: msg,
    type: 'success',
  });
}

const arraysAreEqual = (array1, array2) =>
  array1.length === array2.length && array1.every((value, index) => value === array2[index]);

const actuarialStore = useActuarialStore();

async function setDefault() {
  let parametersCopy = structuredClone(portfolioStore.parameters);
  const checkedKeys = htree.value!.getCheckedKeys(true);

  let convertedHierarchies: string[] = [];

  if (htree.value!.getCheckedKeys(true).length != parameters.value['hierarchies'].length) {
    for (const i of checkedKeys) {
      convertedHierarchies.push(parameters.value['hierarchies'][i]);
    }
  }

  if (!arraysAreEqual(portfolioStore.parameters['default_hierarchies'], convertedHierarchies)) {
    parametersCopy['default_hierarchies'] = convertedHierarchies;
    console.log('changed hierarchies');
    // parameters['default_hierarchies'] =
    globalStore.setLoading(true);
    await api
      .post(import.meta.env.VITE_API_URL + 'portfolio/save-parameters', {
        bounce_id: portfolioStore.selectedBounceID,
        new_parameters: parametersCopy,
      })
      .then((res) => {
        actuarialStore.createDraft(res);
        globalStore.setLoading(false);
        openSuccessMsg('Successfully set new default hierarchies.');
      })
      .catch((e) => {
        console.log(e);
        globalStore.setLoading(false);
        openErrorMsg('Error!');
      });
  }
}

function onResetDefaultSelection() {
  console.log(getDefaultSelection());
  defaultSelection.value = getDefaultSelection();
  isDisabled.value = getDefaultSelection();
}

function onCheckDefaltSelection(e: any) {
  defaultSelection.value = e;
  isDisabled.value = false;
}

function getDefaultSelection() {
  if (!htree.value) {
    console.log('1');
    return false;
  }

  if (
    parameters.value['default_hierarchies'].length == 0 &&
    htree.value!.getCheckedKeys(true).length == parameters.value['hierarchies'].length
  ) {
    console.log('2');
    return true;
  }

  if (parameters.value['default_hierarchies'].length != 0) {
    const checkedKeys = htree.value!.getCheckedKeys(true);

    let convertedHierarchiesKey: number[] = [];

    for (const i of parameters.value['default_hierarchies']) {
      convertedHierarchiesKey.push(parameters.value['hierarchies'].findIndex((e: string) => e == i));
    }
    console.log('3');
    return arraysAreEqual(convertedHierarchiesKey, checkedKeys);
  }
  console.log('4');
  return false;
}

const defaultProps = {
  id: 'id',
  label: 'label',
};
const hierarchies = ref(portfolioStore.parameters['hierarchies'].map((x, i) => ({ label: x, id: i })));

function onCloseHierarchyOrderModal() {
  underwritingStore.updateChangeHierarchyOrderModal(false);
}

function onClickCancelHierarchy() {
  underwritingStore.updateChangeHierarchyOrderModal(false);
}

async function onClickSubmitHierarchy() {
  if (htree.value!.getCheckedKeys(false).length == 0) {
    openErrorMsg('Please select at least one hierarchy');
    return;
  }
  if (defaultSelection.value) {
    await setDefault();
  }
  underwritingStore.updateHierarchyOrder(htree.value!.getCheckedKeys(false)).then(() => openSuccessMsg('Updated'));
  await underwritingStore.updateChangeHierarchyOrderModal(false);
}
</script>

<template>
  <el-dialog
    title="Hierarchy Selection"
    :model-value="underwritingStore.isChangeHierarchyOrderModal"
    :before-close="onCloseHierarchyOrderModal"
  >
    <div
      class="custom-scroll h-full border-2 mt-4 border-gray-100 rounded-sm overflow-scroll bg-white"
      data-testid="hierarchy-order"
      style="height: 265px"
    >
      <el-tree
        ref="htree"
        :data="hierarchies"
        :props="defaultProps"
        :default-checked-keys="hierarchyOrderData"
        show-checkbox
        style="min-height: 261px"
        class="p-5"
        node-key="id"
        @check="onResetDefaultSelection"
      />
    </div>
    <el-checkbox
      data-testid="default-selection"
      label="Set as default"
      :disabled="isDisabled"
      :model-value="defaultSelection"
      size="large"
      @change="onCheckDefaltSelection"
    />
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="onClickCancelHierarchy()">Cancel</el-button>
        <el-button type="primary" data-testid="submit-hierarchy" @click="onClickSubmitHierarchy()">Confirm</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<style scoped>
.shadow-internal {
  box-shadow: inset 0 0 10px #dddddd;
  background-color: rgb(250, 255, 255);
}

.el-tree {
  /* box-shadow: inset 0 0 10px #dddddd ; */
  /* background-color: rgb(250, 255, 255);  */
}

.custom-scroll::-webkit-scrollbar {
  width: 7px;
  height: 0px;
}

.custom-scroll::-webkit-scrollbar-track {
  background: #eeeeee;
}

.custom-scroll::-webkit-scrollbar-thumb {
  background: #888;
}

.custom-scroll::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>

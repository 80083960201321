<script setup lang="ts">
import { onMounted, ref } from 'vue';

defineProps(["modelValue"])

const emit = defineEmits(['update:modelValue']);

function handleInput(event: Event) {
  const target = event.target as HTMLButtonElement;
  if (target) emit('update:modelValue', target.value);
}

const input = ref();

onMounted(() => {
    if (input.value.hasAttribute('autofocus')) {
        input.value.focus();
    }
});

defineExpose({ focus: () => input.value.focus() });
</script>

<template>
    <input
        ref="input"
        class="border-gray-300 focus:border-sybil-teal focus:ring focus:ring-sybil-teal focus:ring-opacity-50 shadow-sm"
        :value="modelValue"
        @input="handleInput"
    >
</template>

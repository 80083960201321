<script setup lang="ts">
import { useDashboardStore } from '@/stores/dashboard';
import { usePortfolioStore } from '@/stores/portfolio';
import { useGlobalStore } from '@/stores/global';
import { ref, computed } from 'vue';
import formatDateWithDateUnit from '@/views/Dashboard/utils/formatDateWithDateUnit';

const dashboard_store = useDashboardStore();
const portfolio_store = usePortfolioStore();

const global_store = useGlobalStore();

function onCloseClaimsAttritionalInformationModal() {
  dashboard_store.onSetClaimsAttritionalInformationModal(false);
}

function onCloseClaimsOthersInformationModal() {
  dashboard_store.onSetClaimsOthersInformationModal(false);
}

const selectedGWPUnit = computed<string>(() => global_store.currency.currencyFormat.selectedGWPUnit);
const GWPUnits: any = computed(() => global_store.currency.GWPUnits);
const currency: any = computed(() => global_store.currency.currencyFormat.currency);

const claimsOthersModalData: any = computed(() => dashboard_store.claimsOthersModalData);
const claimType: any = computed(() => dashboard_store.claimsSelectedType);

function numberWithCommas(x: number, isValue: boolean) {
  if (!isValue) {
    return (
      global_store.currency.currencyFormat.currency +
      (x / parseFloat(GWPUnits.value[selectedGWPUnit.value][0]))
        .toFixed(global_store.currency.currencyFormat.precision)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    );
  } else {
    return parseFloat(
      (x / parseFloat(GWPUnits.value[selectedGWPUnit.value][0])).toFixed(global_store.currency.currencyFormat.precision)
    );
  }
}

function toTitleCase(str: string) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

function onChangePage(e: number) {
  dashboard_store.updateClaimsOthersModalPage(e);
}

function sortData(e: any) {
  const temp = {
    column: e.prop,
    order: e.order == 'descending' ? 'DESC' : 'ASC',
  };

  dashboard_store.modifyOtherInformationSort(temp);
}

const all_hierarchy = computed(() => [
  ...portfolio_store.parameters['actuarial_grouping'],
  ...portfolio_store.parameters['hierarchies'],
]);

function getFormattedUnderwritingOrAccident() {
  return dashboard_store.dashboards.uw_acc == 'uw' ? 'Underwriting' : 'Accident';
}

function getFormattedSelectedDate() {
  const dateUnit = dashboard_store.dateUnit;
  const selectedDateClaimsOthersInformation = dashboard_store.selectedDateClaimsOthersInformation;
  if (selectedDateClaimsOthersInformation == '') {
    return '';
  } else {
    return formatDateWithDateUnit(dateUnit, selectedDateClaimsOthersInformation);
  }
}

function getSelectedDateUnit() {
  const dateUnit = dashboard_store.dateUnit;
  return dateUnit.charAt(0).toUpperCase() + dateUnit.slice(1).toLowerCase();
}
</script>

<template>
  <el-dialog
    :model-value="dashboard_store.isClaimsAttritionalInformationModal"
    :before-close="onCloseClaimsAttritionalInformationModal"
    width="75%"
    :style="{ background: '#efefef' }"
  >
    <template #header="{ close, titleId, titleClass }">
      <div class="my-header text-2xl">
        <h4 :id="titleId" class="titleClass">
          {{ toTitleCase(claimType) }} Claims Additional Information -
          <span class="text-sybil-teal"
            >{{ getFormattedUnderwritingOrAccident() }} {{ getSelectedDateUnit() }}
            {{ getFormattedSelectedDate() }}</span
          >
        </h4>
      </div>
    </template>

    <div class="text-xl font-bold">Number of Claims</div>
    <el-row>
      <el-col :span="8">
        <el-statistic
          decimal-separator
          :value="
            dashboard_store.claimsAttritionalModalData['rows'][0][
              dashboard_store.claimsAttritionalModalData['cols']['NUMBER_OF_CLAIMS']
            ]
          "
          class="text-3xl border-2 p-4 bg-white m-2 shadow-lg text-red-500"
        >
          <template #title>
            <div style="display: inline-flex; align-items: center" class="text-sm font-bold mb-4 text-black">
              Incurred
            </div>
          </template>
        </el-statistic>
      </el-col>
      <el-col :span="8">
        <el-statistic
          decimal-separator
          :value="
            dashboard_store.claimsAttritionalModalData['rows'][0][
              dashboard_store.claimsAttritionalModalData['cols']['NUMBER_OF_CLAIMS']
            ] -
            dashboard_store.claimsAttritionalModalData['rows'][0][
              dashboard_store.claimsAttritionalModalData['cols']['NUMBER_OF_NIL_CLAIMS']
            ]
          "
          class="text-3xl border-2 p-4 bg-white m-2 shadow-lg text-red-500"
        >
          <template #title>
            <div style="display: inline-flex; align-items: center" class="text-sm font-bold mb-4 text-black">
              Incurred Exc. Nil
            </div>
          </template>
        </el-statistic>
      </el-col>
      <el-col :span="8">
        <el-statistic
          decimal-separator
          :value="
            dashboard_store.claimsAttritionalModalData['rows'][0][
              dashboard_store.claimsAttritionalModalData['cols']['NUMBER_OF_PAID_CLAIMS']
            ]
          "
          class="text-3xl border-2 p-4 bg-white m-2 shadow-lg text-red-500"
        >
          <template #title>
            <div style="display: inline-flex; align-items: center" class="text-sm font-bold mb-4 text-black">Paid</div>
          </template>
        </el-statistic>
      </el-col>
      <el-col v-if="false" :span="6">
        <el-statistic
          decimal-separator
          :value="
            dashboard_store.claimsAttritionalModalData['rows'][0][
              dashboard_store.claimsAttritionalModalData['cols']['NUMBER_OF_CLAIMS']
            ] -
            dashboard_store.claimsAttritionalModalData['rows'][0][
              dashboard_store.claimsAttritionalModalData['cols']['NUMBER_OF_NIL_CLAIMS']
            ] -
            dashboard_store.claimsAttritionalModalData['rows'][0][
              dashboard_store.claimsAttritionalModalData['cols']['NUMBER_OF_PAID_CLAIMS']
            ]
          "
          class="text-3xl border-2 p-4 bg-white m-2 shadow-lg text-red-500"
        >
          <template #title>
            <div style="display: inline-flex; align-items: center" class="text-sm font-bold mb-4 text-black">
              OS Exc. Nil
            </div>
          </template>
        </el-statistic>
      </el-col>
    </el-row>
    <div class="text-xl font-bold mt-10">Average Claims Amount in {{ currency + GWPUnits[selectedGWPUnit][1] }}</div>
    <el-row>
      <el-col :span="8">
        <el-statistic
          decimal-separator
          :value="
            numberWithCommas(
              dashboard_store.claimsAttritionalModalData['rows'][0][
                dashboard_store.claimsAttritionalModalData['cols']['NUMBER_OF_CLAIMS']
              ] === 0
                ? 0
                : dashboard_store.claimsAttritionalModalData['rows'][0][
                    dashboard_store.claimsAttritionalModalData['cols']['inc']
                  ] /
                    dashboard_store.claimsAttritionalModalData['rows'][0][
                      dashboard_store.claimsAttritionalModalData['cols']['NUMBER_OF_CLAIMS']
                    ],
              false
            )
          "
          class="text-3xl border-2 p-4 bg-white m-2 shadow-lg text-red-500"
        >
          <template #title>
            <div style="display: inline-flex; align-items: center" class="text-sm font-bold mb-4 text-black">
              Incurred
            </div>
          </template>
        </el-statistic>
      </el-col>
      <el-col :span="8">
        <el-statistic
          decimal-separator
          :value="
            numberWithCommas(
              dashboard_store.claimsAttritionalModalData['rows'][0][
                dashboard_store.claimsAttritionalModalData['cols']['NUMBER_OF_CLAIMS']
              ] -
                dashboard_store.claimsAttritionalModalData['rows'][0][
                  dashboard_store.claimsAttritionalModalData['cols']['NUMBER_OF_NIL_CLAIMS']
                ] ===
                0
                ? 0
                : dashboard_store.claimsAttritionalModalData['rows'][0][
                    dashboard_store.claimsAttritionalModalData['cols']['inc']
                  ] /
                    (dashboard_store.claimsAttritionalModalData['rows'][0][
                      dashboard_store.claimsAttritionalModalData['cols']['NUMBER_OF_CLAIMS']
                    ] -
                      dashboard_store.claimsAttritionalModalData['rows'][0][
                        dashboard_store.claimsAttritionalModalData['cols']['NUMBER_OF_NIL_CLAIMS']
                      ]),
              false
            )
          "
          class="text-3xl border-2 p-4 bg-white m-2 shadow-lg text-red-500"
        >
          <template #title>
            <div style="display: inline-flex; align-items: center" class="text-sm font-bold mb-4 text-black">
              Incurred Exc. Nil
            </div>
          </template>
        </el-statistic>
      </el-col>
      <el-col :span="8">
        <el-statistic
          decimal-separator
          :value="
            numberWithCommas(
              dashboard_store.claimsAttritionalModalData['rows'][0][
                dashboard_store.claimsAttritionalModalData['cols']['NUMBER_OF_PAID_CLAIMS']
              ] === 0
                ? 0
                : dashboard_store.claimsAttritionalModalData['rows'][0][
                    dashboard_store.claimsAttritionalModalData['cols']['paid']
                  ] /
                    dashboard_store.claimsAttritionalModalData['rows'][0][
                      dashboard_store.claimsAttritionalModalData['cols']['NUMBER_OF_PAID_CLAIMS']
                    ],
              false
            )
          "
          class="text-3xl border-2 p-4 bg-white m-2 shadow-lg text-red-500"
        >
          <template #title>
            <div style="display: inline-flex; align-items: center" class="text-sm font-bold mb-4 text-black">Paid</div>
          </template>
        </el-statistic>
      </el-col>
      <el-col v-if="falss" :span="6">
        <el-statistic
          decimal-separator
          :value="
            numberWithCommas(
              dashboard_store.claimsAttritionalModalData['rows'][0][
                dashboard_store.claimsAttritionalModalData['cols']['NUMBER_OF_CLAIMS']
              ] -
                dashboard_store.claimsAttritionalModalData['rows'][0][
                  dashboard_store.claimsAttritionalModalData['cols']['NUMBER_OF_NIL_CLAIMS']
                ] -
                dashboard_store.claimsAttritionalModalData['rows'][0][
                  dashboard_store.claimsAttritionalModalData['cols']['NUMBER_OF_PAID_CLAIMS']
                ] ===
                0
                ? 0
                : (dashboard_store.claimsAttritionalModalData['rows'][0][
                    dashboard_store.claimsAttritionalModalData['cols']['inc']
                  ] -
                    dashboard_store.claimsAttritionalModalData['rows'][0][
                      dashboard_store.claimsAttritionalModalData['cols']['paid']
                    ]) /
                    (dashboard_store.claimsAttritionalModalData['rows'][0][
                      dashboard_store.claimsAttritionalModalData['cols']['NUMBER_OF_CLAIMS']
                    ] -
                      dashboard_store.claimsAttritionalModalData['rows'][0][
                        dashboard_store.claimsAttritionalModalData['cols']['NUMBER_OF_NIL_CLAIMS']
                      ] -
                      dashboard_store.claimsAttritionalModalData['rows'][0][
                        dashboard_store.claimsAttritionalModalData['cols']['NUMBER_OF_PAID_CLAIMS']
                      ]),
              false
            )
          "
          class="text-3xl border-2 p-4 bg-white m-2 shadow-lg text-red-500"
        >
          <template #title>
            <div style="display: inline-flex; align-items: center" class="text-sm font-bold mb-4 text-black">
              OS Exc. Nil
            </div>
          </template>
        </el-statistic>
      </el-col>
    </el-row>
  </el-dialog>
  <el-dialog
    :model-value="dashboard_store.isClaimsOthersInformationModal"
    :before-close="onCloseClaimsOthersInformationModal"
    width="75%"
    top="5vh"
    :style="{ background: '#efefef' }"
  >
    <template #header="{ close, titleId, titleClass }">
      <div class="my-header text-2xl">
        <h4 :id="titleId" class="titleClass">
          {{ toTitleCase(claimType) }} Claims Additional Information -
          <span class="text-sybil-teal"
            >{{ getFormattedUnderwritingOrAccident() }} {{ getSelectedDateUnit() }}
            {{ getFormattedSelectedDate() }}</span
          >
        </h4>
      </div>
    </template>

    <el-table
      v-if="dashboard_store.isClaimsOthersInformationModal && claimsOthersModalData['data']"
      @sort-change="sortData"
      :default-sort="{ prop: 'POLICY_NO', order: 'ascending' }"
      :data="claimsOthersModalData['data']"
      style="width: 100%"
      border
      :header-row-style="{ 'background-color': 'black' }"
      class="shadow-lg"
    >
      <el-table-column sortable resizable prop="POLICY_NO" label="Policy Number" minWidth="180">
        <template #default="scope">
          <div class="custom-scrollbar whitespace-nowrap overflow-hidden overflow-x-auto">
            {{ scope.row[claimsOthersModalData['cols']['POLICY_NO']] }}
          </div>
        </template>
      </el-table-column>
      <el-table-column sortable resizable prop="CLAIM_NUMBER" label="Claim Number" minWidth="180">
        <template #default="scope">
          <div class="custom-scrollbar whitespace-nowrap overflow-hidden overflow-x-auto">
            {{ scope.row[claimsOthersModalData['cols']['CLAIM_NUMBER']] }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="Loss Date" minWidth="180" prop="LOSS_DATE" sortable resizable>
        <template #default="scope">
          <div class="custom-scrollbar whitespace-nowrap overflow-hidden overflow-x-auto">
            {{ scope.row[claimsOthersModalData['cols']['LOSS_DATE']] }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="Inception Date" minWidth="180" prop="INCEPTION_DATE" sortable resizable>
        <template #default="scope">
          <div class="custom-scrollbar whitespace-nowrap overflow-hidden overflow-x-auto">
            {{ scope.row[claimsOthersModalData['cols']['INCEPTION_DATE']] }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="Incurred" minWidth="180" prop="INCURRED" sortable resizable>
        <template #default="scope">
          <div class="custom-scrollbar whitespace-nowrap overflow-hidden overflow-x-auto">
            {{ numberWithCommas(scope.row[claimsOthersModalData['cols']['INCURRED']], false) }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="Paid" minWidth="180" prop="PAID" sortable resizable>
        <template #default="scope">
          <div class="custom-scrollbar whitespace-nowrap overflow-hidden overflow-x-auto">
            {{ numberWithCommas(scope.row[claimsOthersModalData['cols']['PAID']], false) }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        v-for="hierarchy of all_hierarchy"
        sortable
        resizable
        :label="hierarchy"
        minWidth="180"
        v-bind:key="hierarchy"
        :prop="hierarchy"
      >
        <template #default="scope">
          <div class="custom-scrollbar whitespace-nowrap overflow-hidden overflow-x-auto">
            {{ portfolio_store.dictionary[hierarchy][scope.row[claimsOthersModalData['cols'][hierarchy]]] }}
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="shadow-lg w-fit h-fit mt-5 p-2 bg-white">
      <el-pagination
        background
        layout="prev, pager, next, jumper, ->,  total"
        :total="claimsOthersModalData['metadata']['total_items']"
        :page-size:current-page="dashboard_store.claimsOthersModalPage"
        @current-change="onChangePage"
        :page-size="10"
      />
    </div>
  </el-dialog>
</template>

<style scoped>
.el-col {
  text-align: center;
}

.custom-scrollbar::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome, Safari and Opera */
}

.custom-scrollbar {
  -ms-overflow-style: none; /* Hide scrollbar for IE and Edge */
  scrollbar-width: none; /* Hide scrollbar for Firefox */
}
</style>

<script setup lang="ts">
import { onUpdated, ref, onBeforeMount, computed, watch } from 'vue';
import TriangulationsSidePanel from './components/TriangulationsSidePanel.vue';
import TriangulationsTable from './components/TriangulationsTable.vue';
import HierarchyGroupingList from './components/HierarchyGroupingList.vue';
import ClaimsTypePercentageModal from './components/ClaimsTypePercentageModal.vue';
import TieringGroup from './components/TieringGroup.vue';
import { useActuarialStore, COHORT_CONSTANT } from '@/stores/actuarial';
import TriangulationsLinkRatios from './components/TriangulationsLinkRatios.vue';
import TriangulationsDevFactors from './components/TriangulationsDevFactors.vue';
import UltimateSelectionTable from './components/UltimateSelectionTable.vue';
import DiagnosticChartsVue from './components/DiagnosticCharts.vue';
import ImportIBNRModal from './components/ImportIBNRModal.vue';
import CurveFittingModal from './components/CurveFittingModal.vue';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import * as logo from '@/assets/images/sybil';
import { usePortfolioStore } from '@/stores/portfolio';
import { useGlobalStore } from '@/stores/global';
import {
  DEV_FACTORS_TABLE_ID,
  LINK_RATIOS_TABLE_ID,
  TRIANGULATIONS_RATIOS_TABLE_ID,
  TRIANGULATIONS_TABLE_ID,
  ULT_TABLE_ID,
} from './constants';
import AddNewLinkRatiosModal from '@/views/Actuarial/AddNewLinkRatios/AddNewLinkRatiosModal.vue';
import { useElementVisibility } from '@vueuse/core';

function resizeFilter() {
  const numericValue = parseInt(filtersWidth.value, 10); // Extract numeric value from the string
  filtersWidth.value = `${350 - numericValue}px`; // Calculate and append 'px' back
}
const portfolio_store = usePortfolioStore();
const actuarial_store = useActuarialStore();
const global_store = useGlobalStore();

const triangulationsLinkRatiosElement = ref();
const triangulationsDevFactorsElement = ref();

const triangulationsLinkRatiosVisibilityWatch = useElementVisibility(triangulationsLinkRatiosElement);
const triangulationsDevFactorsVisibilityWatch = useElementVisibility(triangulationsDevFactorsElement);

watch(triangulationsLinkRatiosVisibilityWatch, () => {
  actuarial_store.saveTriangulationsData();
});

watch(triangulationsDevFactorsVisibilityWatch, () => {
  actuarial_store.saveTriangulationsData();
});

onBeforeMount(async () => {
  actuarial_store.is_parameters_fetch = false;
  await actuarial_store.getActuarialChunkGrouping().then(() => {
    actuarial_store.is_parameters_fetch = true;
  });
});

const filtersWidth = ref('300px');

const selectedGWPUnit = computed<string>(() => global_store.currency.currencyFormat.selectedGWPUnit);
const GWPUnits: any = computed(() => global_store.currency.GWPUnits);
const currency: any = computed(() => global_store.currency.currencyFormat.currency);

function getType(data, columnIndex) {
  if (columnIndex === 0) {
    return 'string'; // Treat the first column as text
  }
  // Remove commas and check if it's a valid number
  const unformattedNumber = data.replace(/,/g, '');
  if (!isNaN(unformattedNumber) && unformattedNumber.trim() !== '') {
    return 'number';
  }
  if (data.endsWith('%')) {
    return 'percentage';
  }
  return 'string';
}

function parseData(data, type) {
  switch (type) {
    case 'number':
      return parseFloat(data.replace(/,/g, ''));
    case 'percentage':
      return parseFloat(data.replace('%', '')) / 100;
    default:
      return data;
  }
}

function getNumberFormat(precision) {
  let format = '#,##0'; // Base format for zero precision

  if (precision > 0) {
    // Add decimal places based on precision
    format += '.' + '0'.repeat(precision);
  }

  return format;
}

function tableToArray(table) {
  console.log(table);
  const rows = table.querySelectorAll('tr');
  return Array.from(rows).map((tr) => {
    return Array.from(tr.querySelectorAll('td, th')).map((td, columnIndex) => {
      const type = getType(td.innerText, columnIndex);
      const value = parseData(td.innerText, type);
      const classes = td.classList;
      return { value, type, classes };
    });
  });
}

function toTitleCase(str: string) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

function cleanString(str) {
  return str.replace(/\n+/g, ' ').trim();
}

function toExcelCellRef(row, col) {
  // Convert column index to letter(s)
  let colRef = '';
  let dividend = col;
  let modulo;

  while (dividend > 0) {
    modulo = (dividend - 1) % 26;
    colRef = String.fromCharCode(65 + modulo) + colRef;
    dividend = Math.floor((dividend - modulo) / 26);
  }

  // Combine with row index
  return colRef + row;
}

function onDownloadExcel() {
  let workbook = new Workbook();

  let worksheet = workbook.addWorksheet('Triangles and Ratios');
  worksheet.mergeCells('A1:C5');
  let myLogoImage = workbook.addImage({
    base64: logo.imgBase64,
    extension: 'png',
  });
  worksheet.addImage(myLogoImage, 'A1:C5');
  worksheet.addRow([]);

  worksheet.getCell('B7').value = portfolio_store.selectedPortfolioName;
  worksheet.getCell('B7').alignment = {
    horizontal: 'left',
    vertical: 'middle',
  };

  worksheet.getCell('B8').value = portfolio_store.selectedBounceDate;
  worksheet.getCell('B8').alignment = {
    horizontal: 'left',
    vertical: 'middle',
  };

  worksheet.getCell('B9').value = portfolio_store.selectedBounceFullName;
  worksheet.getCell('B9').alignment = {
    horizontal: 'left',
    vertical: 'middle',
  };

  let rowIndex = 10;

  let tableData = tableToArray(document.getElementById(TRIANGULATIONS_TABLE_ID));

  let row = worksheet.getRow(rowIndex++);

  worksheet.mergeCells(toExcelCellRef(rowIndex, 2) + ':' + toExcelCellRef(rowIndex, tableData[0].length + 1));
  let r = worksheet.getRow(rowIndex++);
  let c = r.getCell(2);
  c.value =
    toTitleCase(actuarial_store.triangleType) +
    (actuarial_store.triangleType == 'COUNT' ? ' of' : '') +
    ' Claims Triangles / ' +
    'Development ' +
    COHORT_CONSTANT[actuarial_store.development] +
    '    Amounts are in ' +
    currency.value +
    GWPUnits.value[selectedGWPUnit.value][1];
  c.fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: '30353c' },
  };
  c.font = { color: { argb: 'd1d5db' } };
  let colAddition = 2;
  for (let cIndex in tableData[0]) {
    let colIndex = parseInt(cIndex);
    const row = worksheet.getRow(rowIndex);
    const cell = row.getCell(colIndex + colAddition);
    cell.value = tableData[0][colIndex].value;

    cell.border = {
      top: { style: 'thin', color: { argb: 'FFEEEEEE' } }, // Grey top border
      left: { style: 'thin', color: { argb: 'FFEEEEEE' } }, // Grey left border
      bottom: { style: 'thin', color: { argb: 'FFEEEEEE' } }, // Grey bottom border
      right: { style: 'thin', color: { argb: 'FFEEEEEE' } }, // Grey right border
    };

    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'bbe2d3' },
    };
    cell.alignment = {
      wrapText: true,
      horizontal: 'center',
      vertical: 'middle',
    };
  }

  let rowAddition = rowIndex;

  tableData.forEach((rowData, rowIndex) => {
    if (rowIndex > 0) {
      const row = worksheet.getRow(rowIndex + rowAddition);
      rowData.forEach((cellData, colIndex) => {
        const cell = row.getCell(colIndex + 2);
        cell.value = cellData.value;

        cell.border = {
          top: { style: 'thin', color: { argb: 'FFEEEEEE' } }, // Grey top border
          left: { style: 'thin', color: { argb: 'FFEEEEEE' } }, // Grey left border
          bottom: { style: 'thin', color: { argb: 'FFEEEEEE' } }, // Grey bottom border
          right: { style: 'thin', color: { argb: 'FFEEEEEE' } }, // Grey right border
        };

        cell.alignment = {
          wrapText: true,
          horizontal: 'center',
          vertical: 'middle',
        };

        if (cellData.classes.contains('excluded')) {
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'ecafc2' },
          };
        }

        // Apply number formatting only to other columns
        if (colIndex !== 0) {
          const precision = global_store.currency.currencyFormat.precision;
          cell.numFmt = getNumberFormat(precision);
        } else {
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'eef8f4' },
          };
        }

        // Set text color and cell color as before
      });
    }

    row.commit();
  });

  rowIndex = 12 + tableData.length;
  tableData = tableToArray(document.getElementById(TRIANGULATIONS_RATIOS_TABLE_ID));

  row = worksheet.getRow(rowIndex++);

  worksheet.mergeCells(toExcelCellRef(rowIndex, 2) + ':' + toExcelCellRef(rowIndex, tableData[0].length + 1));
  r = worksheet.getRow(rowIndex++);
  c = r.getCell(2);
  c.value = 'Link Ratios';
  c.fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: '30353c' },
  };
  c.font = { color: { argb: 'd1d5db' } };
  colAddition = 2;
  for (let cIndex in tableData[0]) {
    let colIndex = parseInt(cIndex);
    const row = worksheet.getRow(rowIndex);
    const cell = row.getCell(colIndex + colAddition);
    cell.value = tableData[0][colIndex].value;

    cell.border = {
      top: { style: 'thin', color: { argb: 'FFEEEEEE' } }, // Grey top border
      left: { style: 'thin', color: { argb: 'FFEEEEEE' } }, // Grey left border
      bottom: { style: 'thin', color: { argb: 'FFEEEEEE' } }, // Grey bottom border
      right: { style: 'thin', color: { argb: 'FFEEEEEE' } }, // Grey right border
    };

    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'bbe2d3' },
    };
    cell.alignment = {
      wrapText: true,
      horizontal: 'center',
      vertical: 'middle',
    };
  }

  rowAddition = rowIndex;

  tableData.forEach((rowData, rowIndex) => {
    if (rowIndex > 0) {
      const row = worksheet.getRow(rowIndex + rowAddition);
      rowData.forEach((cellData, colIndex) => {
        const cell = row.getCell(colIndex + 2);
        cell.value = cellData.value;

        cell.border = {
          top: { style: 'thin', color: { argb: 'FFEEEEEE' } }, // Grey top border
          left: { style: 'thin', color: { argb: 'FFEEEEEE' } }, // Grey left border
          bottom: { style: 'thin', color: { argb: 'FFEEEEEE' } }, // Grey bottom border
          right: { style: 'thin', color: { argb: 'FFEEEEEE' } }, // Grey right border
        };

        cell.alignment = {
          wrapText: true,
          horizontal: 'center',
          vertical: 'middle',
        };

        if (cellData.classes.contains('excluded')) {
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'ecafc2' },
          };
        }

        // Apply number formatting only to other columns
        if (colIndex !== 0) {
          const precision = global_store.currency.currencyFormat.precision;
          cell.numFmt = getNumberFormat(3);
        } else {
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'eef8f4' },
          };
        }

        // Set text color and cell color as before
      });
    }

    row.commit();
  });

  rowIndex = 14 + tableData.length * 2;
  tableData = tableToArray(document.getElementById(LINK_RATIOS_TABLE_ID));

  row = worksheet.getRow(rowIndex++);

  worksheet.mergeCells(toExcelCellRef(rowIndex, 2) + ':' + toExcelCellRef(rowIndex, tableData[0].length + 1));
  r = worksheet.getRow(rowIndex++);
  c = r.getCell(2);
  c.value = 'Derived Link Ratios';
  c.fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: '30353c' },
  };
  c.font = { color: { argb: 'd1d5db' } };
  colAddition = 2;
  for (let cIndex in tableData[0]) {
    let colIndex = parseInt(cIndex);
    const row = worksheet.getRow(rowIndex);
    const cell = row.getCell(colIndex + colAddition);
    cell.value = tableData[0][colIndex].value;

    cell.border = {
      top: { style: 'thin', color: { argb: 'FFEEEEEE' } }, // Grey top border
      left: { style: 'thin', color: { argb: 'FFEEEEEE' } }, // Grey left border
      bottom: { style: 'thin', color: { argb: 'FFEEEEEE' } }, // Grey bottom border
      right: { style: 'thin', color: { argb: 'FFEEEEEE' } }, // Grey right border
    };

    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'bbe2d3' },
    };
    cell.alignment = {
      wrapText: true,
      horizontal: 'center',
      vertical: 'middle',
    };
  }

  rowAddition = rowIndex;

  tableData.forEach((rowData, rowIndex) => {
    if (rowIndex > 0) {
      const row = worksheet.getRow(rowIndex + rowAddition);
      rowData.forEach((cellData, colIndex) => {
        const cell = row.getCell(colIndex + 2);
        cell.value = cellData.value;

        cell.border = {
          top: { style: 'thin', color: { argb: 'FFEEEEEE' } }, // Grey top border
          left: { style: 'thin', color: { argb: 'FFEEEEEE' } }, // Grey left border
          bottom: { style: 'thin', color: { argb: 'FFEEEEEE' } }, // Grey bottom border
          right: { style: 'thin', color: { argb: 'FFEEEEEE' } }, // Grey right border
        };

        cell.alignment = {
          wrapText: true,
          horizontal: 'center',
          vertical: 'middle',
        };

        if (cellData.classes.contains('selected')) {
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'e6c193' },
          };
        }

        if (cellData.classes.contains('text-blue-500')) {
          cell.font = {
            color: { argb: '3b82f6' },
          };
        }
        if (cellData.classes.contains('text-green-500')) {
          cell.font = {
            color: { argb: '22c55e' },
          };
        }
        if (cellData.classes.contains('text-red-500')) {
          cell.font = {
            color: { argb: 'ef4444' },
          };
        }

        // Apply number formatting only to other columns
        if (colIndex !== 0) {
          const precision = global_store.currency.currencyFormat.precision;
          cell.numFmt = getNumberFormat(3);
        } else {
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'eef8f4' },
          };
        }

        // Set text color and cell color as before
      });
    }

    row.commit();
  });

  let refRowIndex = structuredClone(rowIndex);

  rowIndex = refRowIndex + tableData.length;
  tableData = tableToArray(document.getElementById(DEV_FACTORS_TABLE_ID));

  row = worksheet.getRow(rowIndex++);

  worksheet.mergeCells(toExcelCellRef(rowIndex, 2) + ':' + toExcelCellRef(rowIndex, tableData[0].length + 1));
  r = worksheet.getRow(rowIndex++);
  c = r.getCell(2);
  c.value = 'Development Factors';
  c.fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: '30353c' },
  };
  c.font = { color: { argb: 'd1d5db' } };
  colAddition = 2;
  for (let cIndex in tableData[0]) {
    let colIndex = parseInt(cIndex);
    const row = worksheet.getRow(rowIndex);
    const cell = row.getCell(colIndex + colAddition);
    cell.value = tableData[0][colIndex].value;

    cell.border = {
      top: { style: 'thin', color: { argb: 'FFEEEEEE' } }, // Grey top border
      left: { style: 'thin', color: { argb: 'FFEEEEEE' } }, // Grey left border
      bottom: { style: 'thin', color: { argb: 'FFEEEEEE' } }, // Grey bottom border
      right: { style: 'thin', color: { argb: 'FFEEEEEE' } }, // Grey right border
    };

    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'bbe2d3' },
    };
    cell.alignment = {
      wrapText: true,
      horizontal: 'center',
      vertical: 'middle',
    };
  }

  rowAddition = rowIndex;

  tableData.forEach((rowData, rowIndex) => {
    if (rowIndex > 0) {
      const row = worksheet.getRow(rowIndex + rowAddition);
      rowData.forEach((cellData, colIndex) => {
        const cell = row.getCell(colIndex + 2);
        cell.value = cellData.value;

        cell.border = {
          top: { style: 'thin', color: { argb: 'FFEEEEEE' } }, // Grey top border
          left: { style: 'thin', color: { argb: 'FFEEEEEE' } }, // Grey left border
          bottom: { style: 'thin', color: { argb: 'FFEEEEEE' } }, // Grey bottom border
          right: { style: 'thin', color: { argb: 'FFEEEEEE' } }, // Grey right border
        };

        cell.alignment = {
          wrapText: true,
          horizontal: 'center',
          vertical: 'middle',
        };

        if (cellData.classes.contains('selected')) {
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'e6c193' },
          };
        }

        if (cellData.classes.contains('text-blue-500')) {
          cell.font = {
            color: { argb: '3b82f6' },
          };
        }
        if (cellData.classes.contains('text-green-500')) {
          cell.font = {
            color: { argb: '22c55e' },
          };
        }
        if (cellData.classes.contains('text-red-500')) {
          cell.font = {
            color: { argb: 'ef4444' },
          };
        }

        // Apply number formatting only to other columns
        if (colIndex !== 0) {
          const precision = global_store.currency.currencyFormat.precision;
          cell.numFmt = getNumberFormat(3);
        } else {
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'eef8f4' },
          };
        }

        // Set text color and cell color as before
      });
    }

    row.commit();
  });

  refRowIndex = structuredClone(rowIndex);

  rowIndex = refRowIndex + tableData.length;
  tableData = tableToArray(document.getElementById(ULT_TABLE_ID));

  row = worksheet.getRow(rowIndex++);

  worksheet.mergeCells(
    toExcelCellRef(rowIndex, 2) + ':' + toExcelCellRef(rowIndex, tableData[0].length + tableData[1].length - 1)
  );
  r = worksheet.getRow(rowIndex++);
  c = r.getCell(2);
  c.value = 'Ultimate Selection    Amounts are in ' + currency.value + GWPUnits.value[selectedGWPUnit.value][1];
  c.fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: '30353c' },
  };
  c.font = { color: { argb: 'd1d5db' } };
  colAddition = 2;
  for (let cIndex in tableData[0]) {
    let colIndex = parseInt(cIndex);
    const row = worksheet.getRow(rowIndex);
    const cell = row.getCell(colIndex + colAddition);
    if (colIndex == 5) {
      worksheet.mergeCells(
        toExcelCellRef(rowIndex, colIndex + colAddition) + ':' + toExcelCellRef(rowIndex, colIndex + colAddition + 1)
      );
      colAddition++;
    } else if (colIndex == 6) {
      worksheet.mergeCells(
        toExcelCellRef(rowIndex, colIndex + colAddition) +
          ':' +
          toExcelCellRef(rowIndex, colIndex + colAddition + tableData[1].length - 3)
      );
      colAddition = colAddition + tableData[1].length - 3;
    } else {
      worksheet.mergeCells(
        toExcelCellRef(rowIndex, colIndex + colAddition) + ':' + toExcelCellRef(rowIndex + 1, colIndex + colAddition)
      );
    }
    cell.value = tableData[0][colIndex].value;

    cell.border = {
      top: { style: 'thin', color: { argb: 'FFEEEEEE' } }, // Grey top border
      left: { style: 'thin', color: { argb: 'FFEEEEEE' } }, // Grey left border
      bottom: { style: 'thin', color: { argb: 'FFEEEEEE' } }, // Grey bottom border
      right: { style: 'thin', color: { argb: 'FFEEEEEE' } }, // Grey right border
    };

    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'bbe2d3' },
    };
    cell.alignment = {
      wrapText: true,
      horizontal: 'center',
      vertical: 'middle',
    };
  }

  colAddition = 7;
  rowIndex++;
  for (let cIndex in tableData[1]) {
    let colIndex = parseInt(cIndex);
    const row = worksheet.getRow(rowIndex);
    const cell = row.getCell(colIndex + colAddition);

    cell.value = tableData[1][colIndex].value;

    cell.border = {
      top: { style: 'thin', color: { argb: 'FFEEEEEE' } }, // Grey top border
      left: { style: 'thin', color: { argb: 'FFEEEEEE' } }, // Grey left border
      bottom: { style: 'thin', color: { argb: 'FFEEEEEE' } }, // Grey bottom border
      right: { style: 'thin', color: { argb: 'FFEEEEEE' } }, // Grey right border
    };

    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'bbe2d3' },
    };
    cell.alignment = {
      wrapText: true,
      horizontal: 'center',
      vertical: 'middle',
    };
  }

  rowAddition = rowIndex - 1;

  tableData.forEach((rowData, rowIndex) => {
    if (rowIndex > 1) {
      const row = worksheet.getRow(rowIndex + rowAddition);
      rowData.forEach((cellData, colIndex) => {
        const cell = row.getCell(colIndex + 2);
        cell.value = cellData.value;

        cell.border = {
          top: { style: 'thin', color: { argb: 'FFEEEEEE' } }, // Grey top border
          left: { style: 'thin', color: { argb: 'FFEEEEEE' } }, // Grey left border
          bottom: { style: 'thin', color: { argb: 'FFEEEEEE' } }, // Grey bottom border
          right: { style: 'thin', color: { argb: 'FFEEEEEE' } }, // Grey right border
        };

        cell.alignment = {
          wrapText: true,
          horizontal: 'center',
          vertical: 'middle',
        };

        if (cellData.classes.contains('selected')) {
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'e6c193' },
          };
        }

        if (cellData.classes.contains('text-blue-500')) {
          cell.font = {
            color: { argb: '3b82f6' },
          };
        }
        if (cellData.classes.contains('text-green-500')) {
          cell.font = {
            color: { argb: '22c55e' },
          };
        }
        if (cellData.classes.contains('text-red-500')) {
          cell.font = {
            color: { argb: 'ef4444' },
          };
        }

        // Apply number formatting only to other columns
        if (colIndex !== 0) {
          const precision = global_store.currency.currencyFormat.precision;
          cell.numFmt = getNumberFormat(3);
        } else {
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'eef8f4' },
          };
        }

        // Set text color and cell color as before
      });
    }

    row.commit();
  });

  workbook.xlsx.writeBuffer().then((data) => {
    let blob = new Blob([data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });

    fs.saveAs(blob, portfolio_store.selectedPortfolioName + '-' + portfolio_store.selectedBounceFullName + '.xlsx');
  });
}

function scrollTable(t) {
  const tableElement = document.getElementById(t);
  if (tableElement) {
    const ctl = tableElement.scrollLeft;
    const ctt = tableElement.scrollTop;

    const tables = [
      TRIANGULATIONS_TABLE_ID,
      TRIANGULATIONS_RATIOS_TABLE_ID,
      LINK_RATIOS_TABLE_ID,
      DEV_FACTORS_TABLE_ID,
    ];
    for (let i in tables) {
      const table = document.getElementById(tables[i]);
      if (table) {
        table.scrollLeft = ctl;
      }
    }

    if (t == TRIANGULATIONS_TABLE_ID) {
      const el = document.getElementById(TRIANGULATIONS_RATIOS_TABLE_ID);
      if (el) {
        el.scrollTop = ctt;
      }
    } else if (t == TRIANGULATIONS_RATIOS_TABLE_ID) {
      const el = document.getElementById(TRIANGULATIONS_TABLE_ID);
      if (el) {
        el.scrollTop = ctt;
      }
    } else if (t == LINK_RATIOS_TABLE_ID) {
      const el = document.getElementById(DEV_FACTORS_TABLE_ID);
      if (el) {
        el.scrollTop = ctt;
      }
    } else if (t == DEV_FACTORS_TABLE_ID) {
      const el = document.getElementById(LINK_RATIOS_TABLE_ID);
      if (el) {
        el.scrollTop = ctt;
      }
    }
  }
}
</script>
<template>
  <div>
    <CurveFittingModal v-if="actuarial_store.curveFittingModal" />
    <AddNewLinkRatiosModal v-if="actuarial_store.averageLinkRatioModal" />
    <ImportIBNRModal v-if="actuarial_store.IBNRModal" />
    <div class="absolute bg-white top-20 right-0 z-50" style="margin-top: -15px">
      <div
        :style="{
          width: filtersWidth,
          transition: '0.5s ease-out all',
        }"
      >
        <TriangulationsSidePanel
          style="width: inherit"
          :filters-width="filtersWidth"
          @resizeFilter="resizeFilter"
          @onDownloadExcel="onDownloadExcel"
        />
      </div>
    </div>
    <TieringGroup />
    <HierarchyGroupingList v-if="actuarial_store.isHierarchyGroupingListModal" curr-hierarchy="Business Unit" />
    <ClaimsTypePercentageModal v-if="actuarial_store.isHierarchyPercentageModal" curr-hierarchy="Business Unit" />

    <div class="relative" :style="{ width: 'calc(100% - ' + filtersWidth + ')', transition: '0.5s ease-out all' }">
      <TriangulationsTable v-if="actuarial_store.is_parameters_fetch" @on-scroll="scrollTable" />
      <TriangulationsLinkRatios ref="triangulationsLinkRatiosElement" @on-scroll="scrollTable" />
      <TriangulationsDevFactors ref="triangulationsDevFactorsElement" @on-scroll="scrollTable" />
      <DiagnosticChartsVue v-if="actuarial_store.trianglesJSON" :key="actuarial_store.graphComponentID" />
      <UltimateSelectionTable />
    </div>
  </div>
</template>

function reverseSearch(obj, searchValue) {
  for (let key in obj) {
    if (obj.hasOwnProperty(key) && obj[key] === searchValue) {
      return key;
    }
  }
  return -1; // Return null if no matching key is found
}

export default reverseSearch;

<script setup lang="ts">
import { onMounted, onBeforeMount, ref, computed } from 'vue';
import { useUnderwritingStore } from '@/stores/underwriting';
import { usePortfolioStore } from '@/stores/portfolio';
import { useGlobalStore } from '@/stores/global';
import { useActuarialStore } from '@/stores/actuarial';
import { useDashboardStore } from '@/stores/dashboard';
import { api } from '@/services/api';
import moment from 'moment';
import { openSuccessMsg } from '@/utilities/notificationMessage';
const underwriting_store = useUnderwritingStore();
const portfolio_store = usePortfolioStore();
const global_store = useGlobalStore();
const actuarial_store = useActuarialStore();

const originalControllerData: any = ref({});
const controllerData: any = ref({});

const PREVIOUS = 0;
const CURRENT = 1;
const NEXT = 2;
function percentageFormat(val: number) {
  return (val * 100).toFixed(1) + '%';
}

const exitsTable = computed(() => underwriting_store.exitsTable);

const applyRate = ref(portfolio_store.parameters['claims_nature'].slice(1).map((x) => false));
const applyRateOriginalValue = ref(portfolio_store.parameters['claims_nature'].slice(1).map((x) => null));

function onClickApplyRate(cd: any, idx: number) {
  if (!applyRate.value[idx]) {
    onClickValueDynamicDialog(cd, idx);
  }
}

function applyRateChangeImpact(cd: string, isApplyRate: boolean) {
  console.log(isApplyRate);
  if (!isApplyRate) {
    dynamicModalEnter(applyRateOriginalValue.value[cd] * 100, cd);
  } else {
    if (!applyRateOriginalValue.value[cd]) {
      applyRateOriginalValue.value[cd] =
        controllerData.value['value_array'][CURRENT][underwriting_store.valueArrayColumns[toTitleCase(cd) + ' Load']];
    }

    let newLoads =
      controllerData.value['value_array'][PREVIOUS][underwriting_store.valueArrayColumns[toTitleCase(cd) + ' Load']] /
      (1 +
        controllerData.value['value_array'][CURRENT][underwriting_store.valueArrayColumns['Rate Change']] *
          controllerData.value['value_array'][CURRENT][underwriting_store.valueArrayColumns['Credit']]);

    if (!newLoads) {
      newLoads = 0;
    }

    console.log(newLoads);
    dynamicModalEnter(newLoads * 100, cd);
  }
}

async function ceaseUncease() {
  changes.value = Object.keys(underwriting_store.valueArrayColumns).reduce((acc, key) => {
    acc[key] = null;
    return acc;
  }, {});

  if (controllerData.value['value_array'][NEXT][underwriting_store.valueArrayColumns['Exits']]) {
    changes.value['Exits'] = 0;
  } else {
    changes.value['Exits'] = 1;
  }
  await submitUnderwritingController();
  changes.value = Object.keys(underwriting_store.valueArrayColumns).reduce((acc, key) => {
    acc[key] = null;
    return acc;
  }, {});
  // global_store.setLoading(true);
  // await api
  //   .post(import.meta.env.VITE_API_URL + "underwriting/cease-underwriting", {
  //     bounce_id: portfolio_store.selectedBounceID,
  //     filters: underwriting_store.underwritingControllerDate["filters"],
  //     underwriting_month: underwriting_store.underwritingControllerDate["underwriting_month"],
  //     claims_nature: portfolio_store.parameters["claims_nature"],
  //     commission: portfolio_store.parameters["Commission"],
  //     changes: changes.value,
  //     exit_value: isNextMonth.value ? 0 : 1
  //   })
  //   .then((res) => {
  //     global_store.setLoading(false);
  //     if (res.data.data.data.is_new_bounce) {
  //       portfolio_store.changeBounceID(res.data.data.data.bounce_id)
  //     }
  //     underwriting_store.fetchType()
  //   })
  //   .catch(() => {
  //     // openErrorMsg("Error!")
  //     global_store.setLoading(false);
  //     global_store.onConnectionError()
  //   })
}

async function submitUnderwritingController() {
  // console.log("jwij")
  underwriting_store.updateIsUnderwritingControllerModal(false);
  global_store.setLoading(true);
  // console.log(Object.keys(underwriting_store.valueArrayColumns).reduce((acc, key) => {
  //   acc[key] = null;
  //   return acc;
  // }, {}))

  let api_changes_body = {};
  const to_be_parsed = [
    'GWP',
    'Attritional_LR',
    'HIST_ACTIONS',
    'HIST_ACTIONS_CREDITS',
    'INDEXES',
    'EXITS',
    'ELASTICITY',
    'MANUAL_PERTURBATION',
    'ALGO_PERTURBATION',
  ];
  const changes_original_name = [
    'GWP',
    'Attritional A-priori',
    'Rate Change',
    'Credit',
    'Indexation',
    'Exits',
    'Elasticity',
    'Manual Perturbation',
    'Algorithmic Perturbation',
  ];
  // Convert changes to api body

  for (let i = 0; i < to_be_parsed.length; i++) {
    if (changes.value[changes_original_name[i]] != null) {
      api_changes_body[to_be_parsed[i]] = changes.value[changes_original_name[i]];
    }
  }

  for (const i of portfolio_store.parameters['line_size']) {
    if (changes.value[i] != null) {
      api_changes_body[i] = changes.value[i];
    }
  }

  for (const i of Object.keys(additionalChanges.value['Commission'])) {
    api_changes_body[i] = additionalChanges.value['Commission'][i];
  }

  for (const i of Object.keys(additionalChanges.value['Loads'])) {
    api_changes_body[i] = additionalChanges.value['Loads'][i];
  }

  const a = {
    bounce_id: portfolio_store.selectedBounceID,
    filters: underwriting_store.underwritingControllerDate['filters'],
    underwriting_month: underwriting_store.underwritingControllerDate['underwriting_month'],
    claims_nature: portfolio_store.parameters['claims_nature'],
    commission: portfolio_store.parameters['Commission'],
    changes: changes.value,
  };
  console.log(a);
  await api
    .post(import.meta.env.VITE_API_URL + 'underwriting/submit-table-controller', {
      bounce_id: portfolio_store.selectedBounceID,
      filters: underwriting_store.underwritingControllerDate['filters'],
      underwriting_month: underwriting_store.underwritingControllerDate['underwriting_month'],
      changes: api_changes_body,
      selectedLineSize: portfolio_store.selectedLineSize,
    })
    .then(async (res) => {
      global_store.setLoading(false);
      openSuccessMsg('Changes saved successfully!');
      actuarial_store.createDraft(res);
      actuarial_store.setActuarialCacheResetRequired(true);
      return Promise.all([
        underwriting_store.fetchUnderwritingTable(),
        portfolio_store.onResetForwardLooking(true),
        portfolio_store.getSignedOff(),
      ]);
    })
    .then(() => {
      global_store.setLoading(false);
    })
    .catch(() => {
      // openErrorMsg("Error!")
      portfolio_store.getSignedOff();
      global_store.setLoading(false);
      global_store.onConnectionError();
    });
}

function toTitleCase(str: string) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

function mouseLeaveController(type: string) {
  isActive.value[type] = false;
  // isChanged.value[type] ? (changedClass.value[type] = true) : '';
}

function mouseOverCurrMonth(type: string) {
  isActive.value[type] = true;
  // changedClass.value[type] = false
}

function CCRSum(month: string) {
  return (
    sumLoads(month) +
    sumCommission(month, false) +
    controllerData.value[month]['rows'][controllerData.value[month]['cols']['SUM_Attritional_LR']]
  );
}

function LRAprioriSum(month: string) {
  return (
    sumLoads(month) + controllerData.value[month]['rows'][controllerData.value[month]['cols']['SUM_Attritional_LR']]
  );
}

function sumLoads(month: string) {
  let sum = 0;
  for (const i of portfolio_store.parameters['claims_nature'].slice(1)) {
    sum += controllerData.value[month]['rows'][controllerData.value[month]['cols']['SUM_' + i]];
  }

  return sum;
}

function sumCommission(month: string, isOriginal: boolean) {
  const data = isOriginal ? originalControllerData.value : controllerData.value;

  let sum = 0;
  for (const i of portfolio_store.parameters['Commission']) {
    // sum += data[month]["rows"][data[month]["cols"]["SUM_" + i]]
  }

  return sum;
}

const dashboard_store = useDashboardStore();

function checkIsProj(val: string) {
  if (moment(val, 'YYYY-MM-DD').isSameOrBefore(moment(dashboard_store.report_date))) {
    return false;
  } else {
    return true;
  }
}

const is_proj_prev = computed(() =>
  checkIsProj(
    moment(underwriting_store.underwritingControllerDate['underwriting_month'], 'YYYY-MM-DD')
      .subtract(1, 'months')
      .format('YYYY-MM-DD')
  )
);
const is_proj_curr = computed(() => checkIsProj(underwriting_store.underwritingControllerDate['underwriting_month']));
const is_proj_next = computed(() =>
  checkIsProj(
    moment(underwriting_store.underwritingControllerDate['underwriting_month'], 'YYYY-MM-DD')
      .add(1, 'months')
      .format('YYYY-MM-DD')
  )
);

const changes = ref(
  Object.keys(underwriting_store.valueArrayColumns).reduce((acc, key) => {
    acc[key] = null;
    return acc;
  }, {})
);

const additionalChanges = ref({ Commission: {}, Loads: {}, CP: {} });

const anyChange = computed(() => {
  return !Object.values(changes.value).every((value) => value === null);
});

async function fetchTableController() {
  global_store.setLoading(true);
  let data = null;

  let post = {
    bounce_id: portfolio_store.selectedBounceID,
    filters: underwriting_store.underwritingControllerDate['filters'],
    underwriting_month: underwriting_store.underwritingControllerDate['underwriting_month'],
    changes: changes.value,
    selectedLineSize: portfolio_store.selectedLineSize,
    value_array_cols: underwriting_store.valueArrayColumns,
    anyChange: anyChange.value,
  };

  await api
    .post(import.meta.env.VITE_API_URL + 'underwriting/fetch-table-controller', post)
    .then((res) => {
      data = res.data.data;
      global_store.setLoading(false);
    })
    .catch(() => {
      // openErrorMsg("Error!")
      global_store.setLoading(false);
      global_store.onConnectionError();
    });

  return data;
}

async function fetchDynamicController(variable: string) {
  global_store.setLoading(true);
  let data = null;

  let post = {
    bounce_id: portfolio_store.selectedBounceID,
    filters: underwriting_store.underwritingControllerDate['filters'],
    underwriting_month: underwriting_store.underwritingControllerDate['underwriting_month'],
    changes: changes.value,
    selectedLineSize: portfolio_store.selectedLineSize,
    anyChange: anyChange.value,
    variable: variable,
  };

  await api
    .post(import.meta.env.VITE_API_URL + 'underwriting/fetch-dynamic-controller', post)
    .then((res) => {
      data = res.data.data;
      global_store.setLoading(false);
    })
    .catch(() => {
      // openErrorMsg("Error!")
      global_store.setLoading(false);
      global_store.onConnectionError();
    });

  return data;
}

onBeforeMount(async () => {
  const data = await fetchTableController();
  console.log(data);
  if (data != undefined) {
    originalControllerData.value = JSON.parse(JSON.stringify(data));
    controllerData.value = JSON.parse(JSON.stringify(data));
  }
  applyRate.value = portfolio_store.parameters['claims_nature'].slice(1).map((x) => false);
});

const dynamicDialogDataCache = ref({});

async function dynamicColumnValues(e: any) {
  dynamicDialogData.value = [];
  dynamicDialogEdit.value = {};

  let data = null;
  data = await fetchDynamicController(e);
  console.log(data);
  dynamicDialogType.value = e;

  if (e == 'Commission' || e == 'Loads') {
    for (const i in data['variable']) {
      dynamicDialogData.value.push(data['variable'][i]);
      dynamicDialogEdit.value[data['variable'][i]] = {
        isEditing: false,
        value: data['value'][i],
      };
    }
  }

  console.log(dynamicDialogEdit.value);
}

function onDynamicModal(e: any) {
  dynamicColumnValues(e);
  dynamicDialogVisible.value = true;
}

function calculateTotalDynamicModal() {
  let sum = 0;
  console.log(additionalChanges.value);
  for (const i of dynamicDialogData.value) {
    console.log(
      additionalChanges.value[dynamicDialogType.value][i]
        ? additionalChanges.value[dynamicDialogType.value][i]
        : dynamicDialogEdit.value[i].value
    );
    sum +=
      additionalChanges.value[dynamicDialogType.value][i] != null
        ? additionalChanges.value[dynamicDialogType.value][i]
        : dynamicDialogEdit.value[i].value;
  }
  return sum;
}

const FormattingIndex = ref({ GWP: 0 });
const totalModal = ref(true);

function keyupEnter(e: any) {
  e.target.blur();
}

function keyUpInput(e: any) {
  tempInput.value = e.target.value;
}

function onFocusController(e: any) {
  e.target.select();
}

function numberFormat(type, x) {
  /*
   ** 0 = GWP
   ** 1 = SUM OF ARRAY
   ** 2 = PERCENTAGE
   ** 3 = ORIGINAL tofixed 1
   */
  //   switch (type) {
  //     case 0:
  //       return x
  //     case 1:
  //       return (
  //         (x.reduce((partialSum, a) => partialSum + a, 0) * 100).toFixed(1) + "%"
  //       );
  //     case 2:
  //       return (x * 100).toFixed(1) + "%";
  //     case 3:
  //       return x ? x.toFixed(1) : "";
  //   }
  return 100;
}

function changeMonth(x: number) {
  console.log(x);
}

const prevMonth = ref({
  actualOrProjection: 3,
  Loads: 1,
  Att: 1,
});

function chosenHierarchies() {
  return Object.values(underwriting_store.underwritingControllerDate['filters']);
}

function chosenHierarchiesValue(idx: string, val: any) {
  if (val.length == 1) {
    return portfolio_store.dictionary[idx.replace(/_/g, ' ')][val[0]];
  } else {
    return 'All';
  }
}

const selectedGWPUnit = computed<string>(() => global_store.currency.currencyFormat.selectedGWPUnit);
const GWPUnits: any = computed(() => global_store.currency.GWPUnits);

function GWPFormat(x: number) {
  return (x / parseFloat(GWPUnits.value[selectedGWPUnit.value][0])).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

const amountType = computed(
  () =>
    global_store.currency.currencyFormat['currency'] +
    global_store.currency.GWPUnits[global_store.currency.currencyFormat['selectedGWPUnit']][1]
);

const isActive = ref({ GWP: false });
const isChanged = ref({ GWP: false });
const isChangedPrev = ref({ GWP: false });
const changedClassPrev = ref({ GWP: false });
const changedClass = ref({ GWP: false });

const tempInput = ref('');

const isEdit = ref({
  RateCredit: {
    isEditing: false,
    value: 100,
  },
  Elasticity: {
    isEditing: false,
    value: 100,
  },
  LineSize: {
    isEditing: false,
    value: 100,
  },
  RateChange: {
    isEditing: false,
    value: 100,
  },
  CP: {
    isEditing: false,
    value: 100,
  },
  Index: {
    isEditing: false,
    value: 100,
  },
  PP: {
    isEditing: false,
    value: 100,
  },
  GWP: {
    isEditing: false,
    value: 100,
  },
  Att: {
    isEditing: false,
    value: 100,
  },
  Loads: {
    isEditing: false,
    value: 100,
  },
  CombineLoads: {
    isEditing: false,
    value: 100,
  },
  CCR: {
    isEditing: false,
    value: 100,
  },
});

async function controllerUnitChange(e: any, changeType: string, valueType: string) {
  isEdit.value[changeType].isEditing = false;
  let val = 0;

  if (valueType == 'Elasticity') {
    val = parseFloat(parseFloat(e.target.value).toFixed(1));
  } else if (
    valueType == 'Rate Change' ||
    valueType == 'Credit' ||
    valueType == 'Manual Perturbation' ||
    valueType == 'Algorithmic Perturbation'
  ) {
    val = parseFloat((parseFloat(e.target.value) / 100).toFixed(4));
  } else {
    val = parseFloat((parseFloat(e.target.value) / 100).toFixed(4));
  }

  if (controllerData.value['value_array'][CURRENT][underwriting_store.valueArrayColumns[valueType]] != val) {
    controllerData.value['value_array'][CURRENT][underwriting_store.valueArrayColumns[valueType]] = val;
    if (valueType == portfolio_store.selectedLineSize) {
      controllerData.value['value_array'][NEXT][underwriting_store.valueArrayColumns[valueType]] =
        controllerData.value['value_array'][CURRENT][underwriting_store.valueArrayColumns[valueType]];
      changes.value[valueType] = val;
    } else {
      changes.value[valueType] = val;
      controllerData.value = await fetchTableController();
      changes.value['Attritional A-priori'] = null;
    }
  }

  for (const i in portfolio_store.parameters['claims_nature'].slice(1)) {
    if (applyRate.value[i] == true) {
      applyRateChangeImpact(portfolio_store.parameters['claims_nature'].slice(1)[i], true);
    }
  }

  if (valueType == 'Attritional A-priori') {
    changes.value['Rate Change'] =
      controllerData.value['value_array'][CURRENT][underwriting_store.valueArrayColumns['Rate Change']];
  }

  highlightCheck();

  console.log(changes.value);
}

function highlightCheck() {
  isChanged.value['CCR'] =
    (
      controllerData.value['value_array'][CURRENT][underwriting_store.valueArrayColumns['Commission']] +
      controllerData.value['value_array'][CURRENT][underwriting_store.valueArrayColumns['Attritional A-priori']] +
      portfolio_store.parameters['claims_nature']
        .slice(1)
        .map(
          (x) =>
            controllerData.value['value_array'][CURRENT][underwriting_store.valueArrayColumns[toTitleCase(x) + ' Load']]
        )
        .reduce((s, a) => s + a, 0)
    ).toFixed(4) !=
    (
      originalControllerData.value['value_array'][CURRENT][underwriting_store.valueArrayColumns['Commission']] +
      originalControllerData.value['value_array'][CURRENT][
        underwriting_store.valueArrayColumns['Attritional A-priori']
      ] +
      portfolio_store.parameters['claims_nature']
        .slice(1)
        .map(
          (x) =>
            originalControllerData.value['value_array'][CURRENT][
              underwriting_store.valueArrayColumns[toTitleCase(x) + ' Load']
            ]
        )
        .reduce((s, a) => s + a, 0)
    ).toFixed(4);
  isChanged.value['NLR'] = isChanged.value['CCR'];
  isChanged.value['Att'] =
    controllerData.value['value_array'][CURRENT][underwriting_store.valueArrayColumns['Attritional A-priori']].toFixed(
      4
    ) !=
    originalControllerData.value['value_array'][CURRENT][
      underwriting_store.valueArrayColumns['Attritional A-priori']
    ].toFixed(4);

  isChanged.value['GWP'] =
    controllerData.value['value_array'][CURRENT][underwriting_store.valueArrayColumns['GWP']].toFixed(0) !=
    originalControllerData.value['value_array'][CURRENT][underwriting_store.valueArrayColumns['GWP']].toFixed(0);
  isChanged.value['Elasticity'] =
    controllerData.value['value_array'][CURRENT][underwriting_store.valueArrayColumns['Elasticity']].toFixed(1) !=
    originalControllerData.value['value_array'][CURRENT][underwriting_store.valueArrayColumns['Elasticity']].toFixed(1);
  isChanged.value['RateChange'] =
    controllerData.value['value_array'][CURRENT][underwriting_store.valueArrayColumns['Rate Change']].toFixed(4) !=
    originalControllerData.value['value_array'][CURRENT][underwriting_store.valueArrayColumns['Rate Change']].toFixed(
      4
    );
  isChanged.value['RateCredit'] =
    controllerData.value['value_array'][CURRENT][underwriting_store.valueArrayColumns['Credit']].toFixed(4) !=
    originalControllerData.value['value_array'][CURRENT][underwriting_store.valueArrayColumns['Credit']].toFixed(4);
  isChanged.value['Index'] =
    controllerData.value['value_array'][CURRENT][underwriting_store.valueArrayColumns['Indexation']].toFixed(4) !=
    originalControllerData.value['value_array'][CURRENT][underwriting_store.valueArrayColumns['Indexation']].toFixed(4);
  isChanged.value['CP'] =
    controllerData.value['value_array'][CURRENT][underwriting_store.valueArrayColumns['Manual Perturbation']].toFixed(
      4
    ) !=
    originalControllerData.value['value_array'][CURRENT][
      underwriting_store.valueArrayColumns['Manual Perturbation']
    ].toFixed(4);
  isChanged.value['PP'] =
    controllerData.value['value_array'][CURRENT][
      underwriting_store.valueArrayColumns['Algorithmic Perturbation']
    ].toFixed(4) !=
    originalControllerData.value['value_array'][CURRENT][
      underwriting_store.valueArrayColumns['Algorithmic Perturbation']
    ].toFixed(4);
  isChanged.value['Commission'] =
    controllerData.value['value_array'][CURRENT][underwriting_store.valueArrayColumns['Commission']].toFixed(4) !=
    originalControllerData.value['value_array'][CURRENT][underwriting_store.valueArrayColumns['Commission']].toFixed(4);
  isChanged.value['Loads'] =
    portfolio_store.parameters['claims_nature']
      .slice(1)
      .map(
        (x) =>
          controllerData.value['value_array'][CURRENT][underwriting_store.valueArrayColumns[toTitleCase(x) + ' Load']]
      )
      .reduce((s, a) => s + a, 0)
      .toFixed(4) !=
    portfolio_store.parameters['claims_nature']
      .slice(1)
      .map(
        (x) =>
          originalControllerData.value['value_array'][CURRENT][
            underwriting_store.valueArrayColumns[toTitleCase(x) + ' Load']
          ]
      )
      .reduce((s, a) => s + a, 0)
      .toFixed(4);
}

function revertChange(changeType: string, valueType: string) {
  controllerData.value = structuredClone(originalControllerData.value);
  isChanged.value = { GWP: false };
  changes.value = Object.keys(underwriting_store.valueArrayColumns).reduce((acc, key) => {
    acc[key] = null;
    return acc;
  }, {});
  highlightCheck();
}

// const isPreviousDate = computed(() => portfolio_store.all_uw_dates["month"][0] != moment(underwriting_store.underwritingControllerDate["underwriting_month"], "YYYY-MM-DD").format("MMM-YYYY") &&
// exitsTable.value[underwriting_store.underwritingControllerDate["index"]][moment(underwriting_store.underwritingControllerDate["underwriting_month"], "YYYY-MM-DD").subtract(1, 'months').format("MMM-YYYY")] != 0)
// const isNextMonth = computed(() => portfolio_store.all_uw_dates["month"][portfolio_store.all_uw_dates["month"].length - 1] != moment(underwriting_store.underwritingControllerDate["underwriting_month"], "YYYY-MM-DD").format("MMM-YYYY") &&
// exitsTable.value[underwriting_store.underwritingControllerDate["index"]][moment(underwriting_store.underwritingControllerDate["underwriting_month"], "YYYY-MM-DD").add(1, 'months').format("MMM-YYYY")] != 0)
function onUnderwritingControllerModal() {
  underwriting_store.updateIsUnderwritingControllerModal(false);
}

function onClickValueDynamicDialog(cd: string, idx: number) {
  dynamicDialogEdit.value[cd].isEditing = true;
}

function dynamicModalEnter(e: any, cd: string) {
  if (dynamicDialogType.value == 'Commission') {
    controllerData.value['value_array'][CURRENT][underwriting_store.valueArrayColumns['Commission']] +=
      parseFloat((parseFloat(e) / 100).toFixed(4)) -
      (additionalChanges.value[dynamicDialogType.value][cd]
        ? additionalChanges.value[dynamicDialogType.value][cd]
        : dynamicDialogEdit.value[cd].value);
    controllerData.value['value_array'][NEXT][underwriting_store.valueArrayColumns['Commission']] =
      controllerData.value['value_array'][CURRENT][underwriting_store.valueArrayColumns['Commission']];
  } else {
    controllerData.value['value_array'][CURRENT][underwriting_store.valueArrayColumns[toTitleCase(cd) + ' Load']] =
      parseFloat((parseFloat(e) / 100).toFixed(4));
    controllerData.value['value_array'][NEXT][underwriting_store.valueArrayColumns[toTitleCase(cd) + ' Load']] =
      parseFloat((parseFloat(e) / 100).toFixed(4));
  }

  additionalChanges.value[dynamicDialogType.value][cd] = parseFloat((parseFloat(e) / 100).toFixed(4));
  console.log(additionalChanges.value);
  if (dynamicDialogEdit.value[cd]) {
    dynamicDialogEdit.value[cd].isEditing = false;
  }

  highlightCheck();
}

const dynamicDialogVisible = ref(false);
const dynamicDialogType = ref('');
const dynamicDialogData: any = ref(['one', 'two']);
const dynamicDialogEdit: any = ref({ one: { isEditing: false, value: 10 }, two: { isEditing: false, value: 10 } });
</script>
<template>
  <el-dialog
    width="950px"
    style="min-height: 500px"
    :model-value="underwriting_store.isUnderwritingControllerModal"
    :before-close="onUnderwritingControllerModal"
    :show-close="false"
  >
    <el-dialog v-model="dynamicDialogVisible" title="">
      <table
        v-if="dynamicDialogVisible"
        class="border-gray-500 shadow-lg"
        style="background-color: #fff; width: 100%; margin: 0 auto; border: 1px solid #eee"
      >
        <tr class="w-1/2 font-bold bg-sybil-teal text-white border-gray-500">
          <th class="border-1 border-gray-500">{{ dynamicDialogType }} Type</th>
          <th class="border-1 border-gray-500">{{ dynamicDialogType }} Value</th>
          <th v-if="dynamicDialogType == 'Loads'">Rate Change Impact</th>
        </tr>
        <tr v-for="(cd, idx) in dynamicDialogData" :key="cd" class="w-1/2 bg-white hover:bg-gray-200">
          <td class="pl-5 text-center">{{ toTitleCase(cd.replace('COM_', '').replace(/_/g, ' ')) }}</td>
          <td class="text-center">
            <div
              @dblclick="onClickApplyRate(cd, idx)"
              class="cursor-pointer"
              @keyup.enter="keyupEnter"
              @focusout="(e: any) => dynamicModalEnter(e.target.value, cd)"
            >
              <div v-if="!dynamicDialogEdit[cd].isEditing" class="text-center">
                {{
                  (
                    (additionalChanges[dynamicDialogType][cd] || additionalChanges[dynamicDialogType][cd] == 0
                      ? additionalChanges[dynamicDialogType][cd]
                      : dynamicDialogEdit[cd].value) * 100
                  ).toFixed(1)
                }}%
              </div>
              <input
                v-if="dynamicDialogEdit[cd].isEditing"
                type="text"
                :value="(dynamicDialogEdit[cd].value * 100).toFixed(1)"
                @focus="onFocusController"
                style="width: 100%; padding: 0; height: 80%; line-height: 100%; margin: auto"
                class="form-control text-center manual-input text-sm"
              />
            </div>
          </td>
          <td class="text-center" v-if="dynamicDialogType == 'Loads'">
            <el-switch @change="applyRateChangeImpact(cd, applyRate[idx])" v-model="applyRate[idx]" />
          </td>
        </tr>
        <tr class="w-1/2 font-bold bg-sybil-teal bg-opacity-20">
          <td class="pl-5 text-center">Total</td>
          <td class="text-center">
            <div>
              <div class="text-center">
                {{ percentageFormat(calculateTotalDynamicModal()) }}
              </div>
            </div>
          </td>
          <td v-if="dynamicDialogType == 'Loads'"></td>
        </tr>
      </table>
    </el-dialog>

    <div
      class="modal-body bg-gray-100 py-12 h-full -mt-24 -ml-5"
      :style="{ width: '950px' }"
      v-if="controllerData['UNDERWRITING_MONTH']"
    >
      <table class="ml-5">
        <tbody>
          <tr>
            <td style="width: 200px">
              <div
                class="p-5 text-xl"
                style="background-color: #fff; width: 150px; margin: 0 auto; border: 1px solid #eee"
                v-if="false"
              >
                The first<br />month<br />controller<br />is not<br />editable,<br />changes on<br />this screen<br />can
                <b>NOT</b><br />be saved
              </div>
              <table
                v-if="controllerData['UNDERWRITING_MONTH'][PREVIOUS]"
                class="shadow-lg scale-75 hover:scale-100 transition ease-in-out duration-500"
                style="background-color: #fff; width: 150px; margin: 0 auto; border: 1px solid #eee"
              >
                <tbody>
                  <tr>
                    <th class="bg-sybil-teal text-white cursor-pointer py-1" @click="changeMonth(-1)">
                      {{ controllerData['UNDERWRITING_MONTH'][PREVIOUS] }}
                    </th>
                  </tr>
                  <tr>
                    <td
                      :class="{
                        hoverColor: isActive['GWP'],
                        flagChangeAnimation: isChangedPrev['GWP'],
                        flagChange: changedClassPrev['GWP'],
                      }"
                      @mouseover="isActive['GWP'] = true"
                      @mouseleave="isActive['GWP'] = false"
                      class="border-b"
                    >
                      <div class="break-normal my-1 text-center cursor-default">
                        {{ is_proj_prev ? 'Sys. Proj.' : 'Actual' }}
                        GWP
                        {{ amountType }}:
                        {{
                          GWPFormat(
                            originalControllerData['value_array'][PREVIOUS][underwriting_store.valueArrayColumns['GWP']]
                          )
                        }}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td
                      :class="{ hoverColor: isActive['Att'] }"
                      @mouseover="isActive['Att'] = true"
                      @mouseleave="isActive['Att'] = false"
                      class="border-b"
                    >
                      <div class="break-normal my-1 text-center cursor-default">
                        Att.A-priori:
                        {{
                          percentageFormat(
                            originalControllerData['value_array'][PREVIOUS][
                              underwriting_store.valueArrayColumns['Attritional A-priori']
                            ]
                          )
                        }}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td
                      :class="{ hoverColor: isActive['Commission'] }"
                      @mouseover="isActive['Commission'] = true"
                      @mouseleave="isActive['Commission'] = false"
                      class="border-b"
                    >
                      <div class="break-normal my-1 text-center cursor-default">
                        {{ is_proj_prev ? 'Sys. Proj.' : 'Actual' }} commission:
                        {{
                          percentageFormat(
                            originalControllerData['value_array'][PREVIOUS][
                              underwriting_store.valueArrayColumns['Commission']
                            ]
                          )
                        }}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td
                      :class="{ hoverColor: isActive['RateChange'] }"
                      @mouseover="isActive['RateChange'] = true"
                      @mouseleave="isActive['RateChange'] = false"
                      class="border-b"
                    >
                      <div class="break-normal my-1 text-center cursor-default">
                        Rate change:
                        {{
                          percentageFormat(
                            originalControllerData['value_array'][PREVIOUS][
                              underwriting_store.valueArrayColumns['Rate Change']
                            ]
                          )
                        }}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td
                      :class="{ hoverColor: isActive['RateCredit'] }"
                      @mouseover="isActive['RateCredit'] = true"
                      @mouseleave="isActive['RateCredit'] = false"
                      class="border-b"
                    >
                      <div class="break-normal my-1 text-center cursor-default">
                        Rate credit:
                        {{
                          percentageFormat(
                            originalControllerData['value_array'][PREVIOUS][
                              underwriting_store.valueArrayColumns['Credit']
                            ]
                          )
                        }}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td
                      :class="{ hoverColor: isActive['Elasticity'] }"
                      @mouseover="isActive['Elasticity'] = true"
                      @mouseleave="isActive['Elasticity'] = false"
                      class="border-b"
                    >
                      <div class="break-normal my-1 text-center cursor-default">
                        Elasticity:
                        {{
                          originalControllerData['value_array'][PREVIOUS][
                            underwriting_store.valueArrayColumns['Elasticity']
                          ].toFixed(1)
                        }}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td
                      :class="{ hoverColor: isActive['Index'] }"
                      @mouseover="isActive['Index'] = true"
                      @mouseleave="isActive['Index'] = false"
                      class="border-b"
                    >
                      <div class="break-normal my-1 text-center cursor-default">
                        Index.:
                        {{
                          percentageFormat(
                            originalControllerData['value_array'][PREVIOUS][
                              underwriting_store.valueArrayColumns['Indexation']
                            ]
                          )
                        }}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td
                      :class="{ hoverColor: isActive['CP'] }"
                      @mouseover="isActive['CP'] = true"
                      @mouseleave="isActive['CP'] = false"
                      class="border-b"
                    >
                      <div class="break-normal my-1 text-center cursor-default">
                        M. Perturbation:
                        {{
                          percentageFormat(
                            originalControllerData['value_array'][PREVIOUS][
                              underwriting_store.valueArrayColumns['Manual Perturbation']
                            ]
                          )
                        }}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td
                      :class="{ hoverColor: isActive['PP'] }"
                      @mouseover="isActive['PP'] = true"
                      @mouseleave="isActive['PP'] = false"
                      class="border-b"
                    >
                      <div class="break-normal my-1 text-center cursor-default">
                        Alg. Perturbation:
                        {{
                          percentageFormat(
                            originalControllerData['value_array'][PREVIOUS][
                              underwriting_store.valueArrayColumns['Algorithmic Perturbation']
                            ]
                          )
                        }}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td
                      :class="{ hoverColor: isActive['LineSize'] }"
                      @mouseover="isActive['LineSize'] = true"
                      @mouseleave="isActive['LineSize'] = false"
                      class="border-b"
                    >
                      <div ref="LineSize-1" class="break-normal my-1 text-center cursor-default">
                        Line Size:
                        {{
                          percentageFormat(
                            originalControllerData['value_array'][PREVIOUS][
                              underwriting_store.valueArrayColumns[portfolio_store.selectedLineSize]
                            ]
                          )
                        }}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td
                      v-if="prevMonth['Loads']"
                      :class="{ hoverColor: isActive['Loads'] }"
                      @mouseover="isActive['Loads'] = true"
                      @mouseleave="isActive['Loads'] = false"
                      class="border-b"
                    >
                      <div class="break-normal my-1 text-center cursor-default">
                        Loads:
                        {{
                          percentageFormat(
                            portfolio_store.parameters['claims_nature']
                              .slice(1)
                              .map(
                                (x) =>
                                  originalControllerData['value_array'][PREVIOUS][
                                    underwriting_store.valueArrayColumns[toTitleCase(x) + ' Load']
                                  ]
                              )
                              .reduce((s, a) => s + a, 0)
                          )
                        }}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td
                      :class="{ hoverColor: isActive['NLR'] }"
                      @mouseover="isActive['CombineLoads'] = true"
                      @mouseleave="isActive['CombineLoads'] = false"
                      class="border-b"
                    >
                      <div class="break-normal my-1 text-center cursor-default">
                        NLR A-priori:
                        {{
                          percentageFormat(
                            (originalControllerData['value_array'][PREVIOUS][
                              underwriting_store.valueArrayColumns['Attritional A-priori']
                            ] +
                              portfolio_store.parameters['claims_nature']
                                .slice(1)
                                .map(
                                  (x) =>
                                    originalControllerData['value_array'][PREVIOUS][
                                      underwriting_store.valueArrayColumns[toTitleCase(x) + ' Load']
                                    ]
                                )
                                .reduce((s, a) => s + a, 0)) /
                              (1 -
                                originalControllerData['value_array'][PREVIOUS][
                                  underwriting_store.valueArrayColumns['Commission']
                                ])
                          )
                        }}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td
                      :class="{ hoverColor: isActive['CCR'] }"
                      @mouseover="isActive['CCR'] = true"
                      @mouseleave="isActive['CCR'] = false"
                    >
                      <div class="break-normal my-1 text-center cursor-default">
                        CCR A-priori:
                        {{
                          percentageFormat(
                            originalControllerData['value_array'][PREVIOUS][
                              underwriting_store.valueArrayColumns['Attritional A-priori']
                            ] +
                              portfolio_store.parameters['claims_nature']
                                .slice(1)
                                .map(
                                  (x) =>
                                    originalControllerData['value_array'][PREVIOUS][
                                      underwriting_store.valueArrayColumns[toTitleCase(x) + ' Load']
                                    ]
                                )
                                .reduce((s, a) => s + a, 0) +
                              originalControllerData['value_array'][PREVIOUS][
                                underwriting_store.valueArrayColumns['Commission']
                              ]
                          )
                        }}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
            <td style="width: 500px; margin: 0 auto">
              <table class="shadow-lg bg-white w-full">
                <tbody>
                  <tr>
                    <th colspan="6" class="bg-sybil-teal text-white text-center py-2">
                      {{ controllerData['UNDERWRITING_MONTH'][CURRENT] }}
                    </th>
                  </tr>
                  <tr v-for="(value, index) in chosenHierarchies()" v-bind:key="index">
                    <td colspan="6" class="border-b break-normal px-2">
                      <label class="font-bold">{{ Object.keys(value)[0].replace(/_/g, ' ') }}:</label>
                      &nbsp;&nbsp;
                      <a>{{
                        chosenHierarchiesValue(Object.keys(value)[0].replace(/_/g, ' '), Object.values(value)[0])
                      }}</a>
                    </td>
                  </tr>
                  <tr>
                    <td
                      colspan="6"
                      class="break-normal px-2"
                      :class="{
                        hoverColor: isActive['LineSize'],
                        flagChangeAnimation: isChanged['LineSize'],
                        flagChange: isChanged['LineSize'],
                      }"
                      @mouseover="mouseOverCurrMonth('LineSize')"
                      @mouseleave="mouseLeaveController('LineSize')"
                      @contextmenu.prevent="revertChange('LineSize', portfolio_store.selectedLineSize)"
                    >
                      <div class="flex flex-row">
                        <label class="font-bold">Line Size:</label>&nbsp;&nbsp;
                        <div
                          ref="LineSize"
                          class="cursor-pointer"
                          @dblclick="
                            if (portfolio_store.selectedLineSize != '100% Share') {
                              isEdit['LineSize'].isEditing = true;
                              tempInput = (
                                controllerData['value_array'][CURRENT][
                                  underwriting_store.valueArrayColumns[portfolio_store.selectedLineSize]
                                ] * 100
                              ).toFixed(1);
                            }
                          "
                          @keyup.enter="keyupEnter"
                          @focusout="(e) => controllerUnitChange(e, 'LineSize', portfolio_store.selectedLineSize)"
                        >
                          <p v-if="!isEdit['LineSize'].isEditing">
                            {{
                              percentageFormat(
                                controllerData['value_array'][CURRENT][
                                  underwriting_store.valueArrayColumns[portfolio_store.selectedLineSize]
                                ]
                              )
                            }}
                          </p>
                          <input
                            v-if="isEdit['LineSize'].isEditing"
                            type="text"
                            @focus="onFocusController"
                            :value="tempInput"
                            @keyup="keyUpInput"
                            style="width: 100%; padding: 0; height: 80%; line-height: 100%; margin: auto"
                            class="text-center"
                            ref="controllerInput"
                          />
                        </div>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td colspan="3" class="bg-sybil-teal bg-opacity-20 pl-2">
                      <i>Starting Point</i>
                    </td>
                  </tr>
                  <tr>
                    <td
                      :class="{ hoverColor: isActive['GWP'] }"
                      @mouseover="isActive['GWP'] = true"
                      @mouseleave="isActive['GWP'] = false"
                      class="text-center border-r w-1/3"
                    >
                      <label class="font-bold">{{ is_proj_curr ? 'Sys. Proj.' : 'Actual' }} GWP {{ amountType }}</label>
                      <div>
                        {{
                          GWPFormat(
                            originalControllerData['value_array'][CURRENT][underwriting_store.valueArrayColumns['GWP']]
                          )
                        }}
                      </div>
                    </td>

                    <td
                      :class="{ hoverColor: isActive['Att'] }"
                      @mouseover="isActive['Att'] = true"
                      @mouseleave="isActive['Att'] = false"
                      class="text-center border-r w-1/3"
                    >
                      <label class="font-bold">Attritional A-priori</label>
                      <div>
                        {{
                          percentageFormat(
                            originalControllerData['value_array'][CURRENT][
                              underwriting_store.valueArrayColumns['Attritional A-priori']
                            ]
                          )
                        }}
                      </div>
                    </td>

                    <td
                      :class="{ hoverColor: isActive['Commission'] }"
                      @mouseover="isActive['Commission'] = true"
                      @mouseleave="isActive['Commission'] = false"
                      class="text-center w-1/3"
                    >
                      <label class="font-bold">{{ is_proj_curr ? 'Sys. Proj.' : 'Actual' }} Commission</label>
                      <div>
                        {{
                          percentageFormat(
                            originalControllerData['value_array'][CURRENT][
                              underwriting_store.valueArrayColumns['Commission']
                            ]
                          )
                        }}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="3" class="bg-sybil-teal bg-opacity-20 pl-2">
                      <i>Calculate, double click to adjust</i>
                    </td>
                  </tr>
                  <tr></tr>
                  <tr class="border-b">
                    <td
                      :class="{
                        hoverColor: isActive['RateChange'],
                        flagChangeAnimation: isChanged['RateChange'],
                        flagChange: isChanged['RateChange'],
                      }"
                      @mouseover="mouseOverCurrMonth('RateChange')"
                      @mouseleave="mouseLeaveController('RateChange')"
                      @contextmenu.prevent="revertChange('RateChange', 'Rate Change')"
                      class="text-center border-r"
                    >
                      <label class="font-bold">Rate Change</label>
                      <div
                        class="cursor-pointer"
                        @dblclick="
                          isEdit['RateChange'].isEditing = true;
                          tempInput = (
                            controllerData['value_array'][CURRENT][
                              underwriting_store.valueArrayColumns['Rate Change']
                            ] * 100
                          ).toFixed(1);
                        "
                        @keyup.enter="keyupEnter"
                        @focusout="(e) => controllerUnitChange(e, 'RateChange', 'Rate Change')"
                      >
                        <p v-if="!isEdit['RateChange'].isEditing">
                          {{
                            percentageFormat(
                              controllerData['value_array'][CURRENT][
                                underwriting_store.valueArrayColumns['Rate Change']
                              ]
                            )
                          }}
                        </p>
                        <input
                          v-if="isEdit['RateChange'].isEditing"
                          type="text"
                          @focus="onFocusController"
                          :value="tempInput"
                          @keyup="keyUpInput"
                          style="width: 100%; padding: 0; height: 80%; line-height: 100%; margin: auto"
                          class="form-control text-center manual-input text-sm"
                        />
                      </div>
                    </td>
                    <td
                      :class="{
                        hoverColor: isActive['RateCredit'],
                        flagChangeAnimation: isChanged['RateCredit'],
                        flagChange: isChanged['RateCredit'],
                      }"
                      @mouseover="mouseOverCurrMonth('RateCredit')"
                      @mouseleave="mouseLeaveController('RateCredit')"
                      @contextmenu.prevent="revertChange('RateCredit', 'Credit')"
                      class="text-center border-r"
                    >
                      <label class="font-bold">Rate Credit</label>
                      <div
                        class="cursor-pointer"
                        @dblclick="
                          isEdit['RateCredit'].isEditing = true;
                          tempInput = (
                            controllerData['value_array'][CURRENT][underwriting_store.valueArrayColumns['Credit']] * 100
                          ).toFixed(1);
                        "
                        @keyup.enter="keyupEnter"
                        @focusout="(e) => controllerUnitChange(e, 'RateCredit', 'Credit')"
                      >
                        <p v-if="!isEdit['RateCredit'].isEditing">
                          {{
                            percentageFormat(
                              controllerData['value_array'][CURRENT][underwriting_store.valueArrayColumns['Credit']]
                            )
                          }}
                        </p>
                        <input
                          v-if="isEdit['RateCredit'].isEditing"
                          type="text"
                          @focus="onFocusController"
                          :value="tempInput"
                          @keyup="keyUpInput"
                          style="width: 100%; padding: 0; height: 80%; line-height: 100%; margin: auto"
                          class="form-control text-center manual-input text-sm"
                        />
                      </div>
                    </td>

                    <td
                      :class="{
                        hoverColor: isActive['Elasticity'],
                        flagChangeAnimation: isChanged['Elasticity'],
                        flagChange: isChanged['Elasticity'],
                      }"
                      @mouseover="mouseOverCurrMonth('Elasticity')"
                      @mouseleave="mouseLeaveController('Elasticity')"
                      @contextmenu.prevent="revertChange('Elasticity', 'Elasticity')"
                      class="text-center border-r"
                    >
                      <label class="font-bold">Volume Elasticity</label>
                      <div
                        class="cursor-pointer"
                        @dblclick="
                          isEdit['Elasticity'].isEditing = true;
                          tempInput =
                            controllerData['value_array'][CURRENT][
                              underwriting_store.valueArrayColumns['Elasticity']
                            ].toFixed(1);
                        "
                        @keyup.enter="keyupEnter"
                        @focusout="(e) => controllerUnitChange(e, 'Elasticity', 'Elasticity')"
                      >
                        <p v-if="!isEdit['Elasticity'].isEditing">
                          {{
                            controllerData['value_array'][CURRENT][
                              underwriting_store.valueArrayColumns['Elasticity']
                            ].toFixed(1)
                          }}
                        </p>
                        <input
                          v-if="isEdit['Elasticity'].isEditing"
                          type="text"
                          @focus="onFocusController"
                          :value="tempInput"
                          @keyup="keyUpInput"
                          style="width: 100%; padding: 0; height: 80%; line-height: 100%; margin: auto"
                          class="form-control text-center manual-input text-sm"
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td
                      :class="{
                        hoverColor: isActive['Index'],
                        flagChangeAnimation: isChanged['Index'],
                        flagChange: isChanged['Index'],
                      }"
                      @mouseover="mouseOverCurrMonth('Index')"
                      @mouseleave="mouseLeaveController('Index')"
                      @contextmenu.prevent="revertChange('Index', 'Indexation')"
                      class="text-center border-r"
                    >
                      <label class="font-bold">Indexation</label>
                      <div
                        class="cursor-pointer"
                        @dblclick="
                          isEdit['Index'].isEditing = true;
                          tempInput = (
                            controllerData['value_array'][CURRENT][underwriting_store.valueArrayColumns['Indexation']] *
                            100
                          ).toFixed(1);
                        "
                        @keyup.enter="keyupEnter"
                        @focusout="(e) => controllerUnitChange(e, 'Index', 'Indexation')"
                      >
                        <p v-if="!isEdit['Index'].isEditing">
                          {{
                            percentageFormat(
                              controllerData['value_array'][CURRENT][underwriting_store.valueArrayColumns['Indexation']]
                            )
                          }}
                        </p>
                        <input
                          v-if="isEdit['Index'].isEditing"
                          type="text"
                          @focus="onFocusController"
                          :value="tempInput"
                          @keyup="keyUpInput"
                          style="width: 100%; padding: 0; height: 80%; line-height: 100%; margin: auto"
                          class="form-control text-center manual-input text-sm"
                        />
                      </div>
                    </td>

                    <td
                      :class="{
                        hoverColor: isActive['CP'],
                        flagChangeAnimation: isChanged['CP'],
                        flagChange: isChanged['CP'],
                      }"
                      @mouseover="mouseOverCurrMonth('CP')"
                      @mouseleave="mouseLeaveController('CP')"
                      @contextmenu.prevent="revertChange('CP', 'Manual Perturbation')"
                      class="text-center border-r"
                    >
                      <label class="font-bold">Manual Perturbation</label>
                      <div
                        class="cursor-pointer"
                        @dblclick="
                          isEdit['CP'].isEditing = true;
                          tempInput = (
                            controllerData['value_array'][CURRENT][
                              underwriting_store.valueArrayColumns['Manual Perturbation']
                            ] * 100
                          ).toFixed(1);
                        "
                        @keyup.enter="keyupEnter"
                        @focusout="(e) => controllerUnitChange(e, 'CP', 'Manual Perturbation')"
                      >
                        <p v-if="!isEdit['CP'].isEditing">
                          {{
                            percentageFormat(
                              controllerData['value_array'][CURRENT][
                                underwriting_store.valueArrayColumns['Manual Perturbation']
                              ]
                            )
                          }}
                        </p>
                        <input
                          v-if="isEdit['CP'].isEditing"
                          type="text"
                          @focus="onFocusController"
                          :value="tempInput"
                          @keyup="keyUpInput"
                          style="width: 100%; padding: 0; height: 80%; line-height: 100%; margin: auto"
                          class="form-control text-center manual-input text-sm"
                        />
                      </div>
                    </td>

                    <td
                      :class="{
                        hoverColor: isActive['PP'],
                        flagChangeAnimation: isChanged['PP'],
                        flagChange: isChanged['PP'],
                      }"
                      @mouseover="mouseOverCurrMonth('PP')"
                      @mouseleave="mouseLeaveController('PP')"
                      @contextmenu.prevent="revertChange('PP', 'Algorithmic Perturbation')"
                      class="text-center border-r"
                    >
                      <label class="font-bold">Alg. Perturbation</label>
                      <div class="cursor-pointer" @keyup.enter="keyupEnter">
                        <p v-if="!isEdit['PP'].isEditing">
                          {{
                            percentageFormat(
                              controllerData['value_array'][CURRENT][
                                underwriting_store.valueArrayColumns['Algorithmic Perturbation']
                              ]
                            )
                          }}
                        </p>
                        <input
                          v-if="isEdit['PP'].isEditing"
                          type="text"
                          @focus="onFocusController"
                          :value="tempInput"
                          @keyup="keyUpInput"
                          style="width: 100%; padding: 0; height: 80%; line-height: 100%; margin: auto"
                          class="form-control text-center manual-input text-sm"
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="3" class="bg-sybil-teal bg-opacity-20 pl-2">
                      <i>Results, double click to change <b>Commission</b> or <b>Loads</b></i>
                    </td>
                  </tr>
                  <tr class="border-b">
                    <td
                      :class="{
                        hoverColor: isActive['GWP'],
                        flagChangeAnimation:
                          (isChanged['RateChange'] || isChanged['Elasticity'] || isChanged['Index']) && is_proj_curr,
                        flagChange:
                          (isChanged['RateChange'] || isChanged['Elasticity'] || isChanged['Index']) && is_proj_curr,
                      }"
                      @mouseover="mouseOverCurrMonth('GWP')"
                      @mouseleave="mouseLeaveController('GWP')"
                      class="text-center border-r"
                    >
                      <label class="font-bold">{{ is_proj_curr ? 'Sys. Proj.' : 'Actual' }} GWP {{ amountType }}</label>
                      <p
                        :class="{
                          edited: false,
                        }"
                        v-if="!isEdit['GWP'].isEditing"
                      >
                        {{
                          GWPFormat(controllerData['value_array'][CURRENT][underwriting_store.valueArrayColumns['GWP']])
                        }}
                      </p>
                    </td>

                    <td
                      :class="{
                        hoverColor: isActive['Att'],
                        flagChangeAnimation: isChanged['Att'],
                        flagChange: isChanged['Att'],
                      }"
                      @mouseover="mouseOverCurrMonth('Att')"
                      @mouseleave="mouseLeaveController('Att')"
                      class="text-center border-r"
                    >
                      <label class="font-bold">Attritional A-priori</label>
                      <div
                        class="cursor-pointer"
                        @dblclick="
                          isEdit['Att'].isEditing = true;
                          tempInput = (
                            controllerData['value_array'][CURRENT][
                              underwriting_store.valueArrayColumns['Attritional A-priori']
                            ] * 100
                          ).toFixed(1);
                        "
                        @keyup.enter="keyupEnter"
                        @focusout="(e) => controllerUnitChange(e, 'Att', 'Attritional A-priori')"
                      >
                        <p v-if="!isEdit['Att'].isEditing">
                          {{
                            percentageFormat(
                              controllerData['value_array'][CURRENT][
                                underwriting_store.valueArrayColumns['Attritional A-priori']
                              ]
                            )
                          }}
                        </p>
                        <input
                          v-if="isEdit['Att'].isEditing"
                          type="text"
                          @focus="onFocusController"
                          :value="tempInput"
                          @keyup="keyUpInput"
                          style="width: 100%; padding: 0; height: 80%; line-height: 100%; margin: auto"
                          class="text-center text-sm"
                        />
                      </div>
                    </td>

                    <td
                      :class="{
                        hoverColor: isActive['Commission'],
                        flagChangeAnimation: isChanged['Commission'],
                        flagChange: isChanged['Commission'],
                      }"
                      @click="onDynamicModal('Commission')"
                      @mouseover="mouseOverCurrMonth('Commission')"
                      @mouseleave="mouseLeaveController('Commission')"
                      class="text-center"
                    >
                      <label class="font-bold"> {{ is_proj_curr ? 'Sys. Proj.' : 'Actual' }} Commission</label>
                      <div class="cursor-pointer">
                        {{
                          percentageFormat(
                            controllerData['value_array'][CURRENT][underwriting_store.valueArrayColumns['Commission']]
                          )
                        }}
                      </div>
                    </td>
                  </tr>
                  <tr class="border-b">
                    <td
                      @click="onDynamicModal('Loads')"
                      :class="{
                        hoverColor: isActive['Loads'],
                        flagChangeAnimation: isChanged['Loads'],
                        flagChange: isChanged['Loads'],
                      }"
                      @mouseover="mouseOverCurrMonth('Loads')"
                      @mouseleave="mouseLeaveController('Loads')"
                      class="text-center border-r"
                    >
                      <label class="font-bold">Loads</label>
                      <div class="cursor-pointer">
                        {{
                          percentageFormat(
                            portfolio_store.parameters['claims_nature']
                              .slice(1)
                              .map(
                                (x) =>
                                  controllerData['value_array'][CURRENT][
                                    underwriting_store.valueArrayColumns[toTitleCase(x) + ' Load']
                                  ]
                              )
                              .reduce((s, a) => s + a, 0)
                          )
                        }}
                      </div>
                    </td>
                    <td
                      :class="{
                        hoverColor: isActive['NLR'],
                        flagChangeAnimation: isChanged['NLR'],
                        flagChange: isChanged['NLR'],
                      }"
                      @mouseover="mouseOverCurrMonth('NLR')"
                      @mouseleave="mouseLeaveController('NLR')"
                      class="text-center border-r"
                    >
                      <label class="font-bold">NLR A-priori</label>
                      <div class="">
                        {{
                          percentageFormat(
                            (controllerData['value_array'][CURRENT][
                              underwriting_store.valueArrayColumns['Attritional A-priori']
                            ] +
                              portfolio_store.parameters['claims_nature']
                                .slice(1)
                                .map(
                                  (x) =>
                                    controllerData['value_array'][CURRENT][
                                      underwriting_store.valueArrayColumns[toTitleCase(x) + ' Load']
                                    ]
                                )
                                .reduce((s, a) => s + a, 0)) /
                              (1 -
                                controllerData['value_array'][CURRENT][
                                  underwriting_store.valueArrayColumns['Commission']
                                ])
                          )
                        }}
                      </div>
                    </td>
                    <td
                      :class="{
                        hoverColor: isActive['CCR'],
                        flagChangeAnimation: isChanged['CCR'],
                        flagChange: isChanged['CCR'],
                      }"
                      @mouseover="mouseOverCurrMonth('CCR')"
                      @mouseleave="mouseLeaveController('CCR')"
                      class="text-center"
                    >
                      <label class="font-bold">CCR A-priori</label>
                      <div type="" id="ccr" class="modal-value-x ccr">
                        {{
                          percentageFormat(
                            controllerData['value_array'][CURRENT][
                              underwriting_store.valueArrayColumns['Attritional A-priori']
                            ] +
                              portfolio_store.parameters['claims_nature']
                                .slice(1)
                                .map(
                                  (x) =>
                                    controllerData['value_array'][CURRENT][
                                      underwriting_store.valueArrayColumns[toTitleCase(x) + ' Load']
                                    ]
                                )
                                .reduce((s, a) => s + a, 0) +
                              controllerData['value_array'][CURRENT][underwriting_store.valueArrayColumns['Commission']]
                          )
                        }}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="3" class="p-2 bg-sybil-teal bg-opacity-20">
                      <div><i>(i) Double click on numbers to change</i></div>
                      <div><i>(ii) Right click on numbers to discard change</i></div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
            <td style="width: 200px" v-if="controllerData['UNDERWRITING_MONTH'][NEXT]">
              <table
                class="shadow-lg scale-75 hover:scale-100 transition ease-in-out duration-500"
                style="background-color: #fff; width: 150px; margin: 0 auto; border: 1px solid #eee"
              >
                <tbody>
                  <tr>
                    <th class="bg-sybil-teal text-white cursor-pointer py-1" @click="changeMonth(1)">
                      {{ controllerData['UNDERWRITING_MONTH'][NEXT] }}
                    </th>
                  </tr>
                  <tr>
                    <td
                      :class="{
                        hoverColor: isActive['GWP'],
                        flagChangeAnimation: isChanged['GWP'],
                        flagChange: isChanged['GWP'],
                      }"
                      @mouseover="mouseOverCurrMonth('GWP')"
                      @mouseleave="mouseLeaveController('GWP')"
                      class="border-b"
                    >
                      <div class="break-normal my-1 text-center cursor-default">
                        {{ is_proj_next ? 'Sys. Proj.' : 'Actual' }}
                        GWP
                        {{ amountType }}
                        {{
                          GWPFormat(controllerData['value_array'][NEXT][underwriting_store.valueArrayColumns['GWP']])
                        }}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td
                      :class="{
                        hoverColor: isActive['Att'],
                        flagChangeAnimation: isChanged['Att'],
                        flagChange: isChanged['Att'],
                      }"
                      @mouseover="mouseOverCurrMonth('Att')"
                      @mouseleave="mouseLeaveController('Att')"
                      class="border-b"
                    >
                      <div class="break-normal my-1 text-center cursor-default">
                        Att. A-priori:
                        {{
                          percentageFormat(
                            controllerData['value_array'][NEXT][
                              underwriting_store.valueArrayColumns['Attritional A-priori']
                            ]
                          )
                        }}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td
                      :class="{
                        hoverColor: isActive['Commission'],
                        flagChangeAnimation: isChanged['Commission'],
                        flagChange: isChanged['Commission'],
                      }"
                      @mouseover="mouseOverCurrMonth('Commission')"
                      @mouseleave="mouseLeaveController('Commission')"
                      class="border-b"
                    >
                      <div class="break-normal my-1 text-center cursor-default">
                        {{ is_proj_next ? 'Sys. Proj.' : 'Actual' }} Commission:
                        {{
                          percentageFormat(
                            controllerData['value_array'][NEXT][underwriting_store.valueArrayColumns['Commission']]
                          )
                        }}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td
                      :class="{ hoverColor: isActive['RateChange'] }"
                      @mouseover="isActive['RateChange'] = true"
                      @mouseleave="isActive['RateChange'] = false"
                      class="border-b"
                    >
                      <div class="break-normal my-1 text-center cursor-default">
                        Rate change:
                        {{
                          percentageFormat(
                            controllerData['value_array'][NEXT][underwriting_store.valueArrayColumns['Rate Change']]
                          )
                        }}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td
                      :class="{ hoverColor: isActive['RateCredit'] }"
                      @mouseover="isActive['RateCredit'] = true"
                      @mouseleave="isActive['RateCredit'] = false"
                      class="border-b"
                    >
                      <div class="break-normal my-1 text-center cursor-default">
                        Rate credit:
                        {{
                          percentageFormat(
                            controllerData['value_array'][NEXT][underwriting_store.valueArrayColumns['Credit']]
                          )
                        }}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td
                      :class="{
                        hoverColor: isActive['Elasticity'],
                        flagChangeAnimation: isChanged['Elasticity'],
                        flagChange: isChanged['Elasticity'],
                      }"
                      @mouseover="mouseOverCurrMonth('Elasticity')"
                      @mouseleave="mouseLeaveController('Elasticity')"
                      class="border-b"
                    >
                      <div class="break-normal my-1 text-center cursor-default">
                        Elasticity:
                        {{
                          controllerData['value_array'][NEXT][
                            underwriting_store.valueArrayColumns['Elasticity']
                          ].toFixed(1)
                        }}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td
                      :class="{ hoverColor: isActive['Index'] }"
                      @mouseover="isActive['Index'] = true"
                      @mouseleave="isActive['Index'] = false"
                      class="border-b"
                    >
                      <div class="break-normal my-1 text-center cursor-default">
                        Index.:
                        {{
                          percentageFormat(
                            controllerData['value_array'][NEXT][underwriting_store.valueArrayColumns['Indexation']]
                          )
                        }}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td
                      :class="{ hoverColor: isActive['CP'] }"
                      @mouseover="isActive['CP'] = true"
                      @mouseleave="isActive['CP'] = false"
                      class="border-b"
                    >
                      <div class="break-normal my-1 text-center cursor-default">
                        M. Perturbation:
                        {{
                          percentageFormat(
                            controllerData['value_array'][NEXT][
                              underwriting_store.valueArrayColumns['Manual Perturbation']
                            ]
                          )
                        }}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td
                      :class="{ hoverColor: isActive['PP'] }"
                      @mouseover="isActive['PP'] = true"
                      @mouseleave="isActive['PP'] = false"
                      class="border-b"
                    >
                      <div class="break-normal my-1 text-center cursor-default">
                        Alg. Perturbation:
                        {{
                          percentageFormat(
                            controllerData['value_array'][NEXT][
                              underwriting_store.valueArrayColumns['Algorithmic Perturbation']
                            ]
                          )
                        }}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td
                      :class="{
                        hoverColor: isActive['LineSize'],
                        flagChangeAnimation: isChanged['LineSize'],
                        flagChange: isChanged['LineSize'],
                      }"
                      @mouseover="mouseOverCurrMonth('LineSize')"
                      @mouseleave="mouseLeaveController('LineSize')"
                      class="border-b"
                    >
                      <div ref="LineSize+1" class="break-normal my-1 text-center cursor-default">
                        Line Size:
                        {{
                          percentageFormat(
                            controllerData['value_array'][NEXT][
                              underwriting_store.valueArrayColumns[portfolio_store.selectedLineSize]
                            ]
                          )
                        }}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td
                      :class="{
                        hoverColor: isActive['Loads'],
                        flagChangeAnimation: isChanged['Loads'],
                        flagChange: isChanged['Loads'],
                      }"
                      @mouseover="mouseOverCurrMonth('Loads')"
                      @mouseleave="mouseLeaveController('Loads')"
                      class="border-b"
                    >
                      <div class="break-normal my-1 text-center cursor-default">
                        Loads:
                        {{
                          percentageFormat(
                            portfolio_store.parameters['claims_nature']
                              .slice(1)
                              .map(
                                (x) =>
                                  controllerData['value_array'][NEXT][
                                    underwriting_store.valueArrayColumns[toTitleCase(x) + ' Load']
                                  ]
                              )
                              .reduce((s, a) => s + a, 0)
                          )
                        }}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td
                      :class="{
                        hoverColor: isActive['NLR'],
                        flagChangeAnimation: isChanged['NLR'],
                        flagChange: isChanged['NLR'],
                      }"
                      @mouseover="mouseOverCurrMonth('NLR')"
                      @mouseleave="mouseLeaveController('NLR')"
                      class="border-b"
                    >
                      <div class="break-normal my-1 text-center cursor-default">
                        NLR A-priori:
                        {{
                          percentageFormat(
                            (controllerData['value_array'][NEXT][
                              underwriting_store.valueArrayColumns['Attritional A-priori']
                            ] +
                              portfolio_store.parameters['claims_nature']
                                .slice(1)
                                .map(
                                  (x) =>
                                    controllerData['value_array'][NEXT][
                                      underwriting_store.valueArrayColumns[toTitleCase(x) + ' Load']
                                    ]
                                )
                                .reduce((s, a) => s + a, 0)) /
                              (1 -
                                controllerData['value_array'][NEXT][underwriting_store.valueArrayColumns['Commission']])
                          )
                        }}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td
                      :class="{
                        hoverColor: isActive['CCR'],
                        flagChangeAnimation: isChanged['CCR'],
                        flagChange: isChanged['CCR'],
                      }"
                      @mouseover="mouseOverCurrMonth('CCR')"
                      @mouseleave="mouseLeaveController('CCR')"
                    >
                      <div class="break-normal my-1 text-center cursor-default">
                        CCR A-priori:
                        {{
                          percentageFormat(
                            controllerData['value_array'][NEXT][
                              underwriting_store.valueArrayColumns['Attritional A-priori']
                            ] +
                              portfolio_store.parameters['claims_nature']
                                .slice(1)
                                .map(
                                  (x) =>
                                    controllerData['value_array'][NEXT][
                                      underwriting_store.valueArrayColumns[toTitleCase(x) + ' Load']
                                    ]
                                )
                                .reduce((s, a) => s + a, 0) +
                              controllerData['value_array'][NEXT][underwriting_store.valueArrayColumns['Commission']]
                          )
                        }}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button
          v-if="controllerData['UNDERWRITING_MONTH'] && controllerData['UNDERWRITING_MONTH'][NEXT]"
          type="danger"
          @click="ceaseUncease()"
          >{{
            controllerData['value_array'][NEXT][underwriting_store.valueArrayColumns['Exits']]
              ? 'Cease underwriting'
              : 'Uncease underwriting'
          }}</el-button
        >
        <el-button type="default" @click="underwriting_store.updateIsUnderwritingControllerModal(false)"
          >Cancel</el-button
        >
        <el-button type="primary" @click="submitUnderwritingController()">Confirm</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<style scoped>
.edited {
  color: red;
}

.labeltd {
  width: 33%;
  background-color: rgb(255, 255, 255);
}

.modalContentSmall {
  width: 33%;
  text-align: center;
  background-color: rgb(255, 255, 255);
}

.hoverColor {
  color: #ff5555;
}

.modal-bu:hover {
  color: #109cff;
}

.png-teal:hover {
  filter: invert(63%) sepia(42%) saturate(423%) hue-rotate(105deg) brightness(92%) contrast(93%);
}

.png-gray {
  filter: invert(69%) sepia(0%) saturate(0%) hue-rotate(220deg) brightness(89%) contrast(89%);
}

.projection {
  background-color: black;
}

.p-datatable-thead {
  color: rgb(85, 182, 145);
}

.textdarea:focus {
  background-color: lightblue;
}

@keyframes change-color {
  0% {
    background-color: #5555ff;
  }

  100% {
    background-color: #eeeeff;
  }
}

.flagChangeAnimation {
  animation-name: change-color;
  animation-duration: 1s;
  animation-iteration-count: 1;
}

.flagChange {
  background-color: #eeeeff;
}

.bghover::hover {
  background-color: #eef8f4;
  width: fit-content;
}

.p-autocomplete {
  width: 100%;
}

.p-inputgroup-addon:first-child,
.p-inputgroup button:first-child,
.p-inputgroup input:first-child,
.p-inputgroup > .p-inputwrapper:first-child,
.p-inputgroup > .p-inputwrapper:first-child > .p-inputtext {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.p-inputtext,
.p-component,
.p-inputnumber-input {
  border-top-left-radius: 0rem;
  border-bottom-left-radius: 0rem;
}

.p-inputgroup-addon:last-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
</style>

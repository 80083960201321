<script setup lang="ts">
import SelectBounceTree from '@/components/SelectBounceTree.vue';
import { useFinanceStore } from '@/stores/finance';
import { useGlobalStore } from '@/stores/global';
import type {
  FilterHierarchyType,
  ParametersObjectType,
  PortfolioType,
  PortfolioTypeTreeNode,
  TreeNodeSelectBounceTreeType,
} from '@/types/portfolio';
import { ArrowDown, ArrowRight } from '@element-plus/icons-vue';
import { ref, onMounted } from 'vue';
import openErrorMsg from '@/components/openErrorMsg';
import { Minus, Plus } from '@element-plus/icons-vue';
import { api } from '@/services/api';
import axios from 'axios';
import { embedMirrorPortfolioIntoPortfolio } from '@/utilities/portfolioHelper';
import { fetchMirroredPortfolioDictionary } from '@/services/portfolio';

export interface BusinessSelection {
  [key: string]: string;
}

const financeStore = useFinanceStore();
const globalStore = useGlobalStore();
const portfolios = ref<PortfolioType[]>([]);
const draftList = ref({});
const selectedDraftList = ref({});
const selectedBusiness = ref<string>('');
const selectedBusinessID = ref<string>('');
const selectedBusinessBounce = ref<PortfolioTypeTreeNode>();
const treeSelected = ref('');
const show = ref(false);
const nodeName = ref('');
const selectedBounceTreeKey = ref(0);
const bounceDictionary = ref();
const businessSelection = ref<BusinessSelection>({});

function getBusinessSelection(i: string) {
  let result;
  if (businessSelection.value && businessSelection.value[i] !== '') {
    console.log(i);
    console.log(bounceDictionary.value);
    const selection = businessSelection.value[i];
    console.log(selection);
    if (typeof selection === 'string' || typeof selection === 'number') {
      result = bounceDictionary.value[i][selection] ?? 'Not Selected';
    } else {
      // Handle case where selection is not a string or number
      result = 'Not Selected';
    }
  } else {
    result = 'Not Selected';
  }

  return result;
}

const yearsOfProjection = ref(0);

function onBusinessSelection() {
  console.log(businessSelection.value);
  console.log(bounceDictionary.value);
  console.log(parameters.value);

  let filtersHierarchy: FilterHierarchyType = {};
  let businessSelectionSelectedHierarchyName: BusinessSelection = {};

  for (const hierarchy of Object.keys(bounceDictionary.value)) {
    if (businessSelection.value[hierarchy]) {
      filtersHierarchy[hierarchy] = [parseInt(businessSelection.value[hierarchy])];
      businessSelectionSelectedHierarchyName[hierarchy] =
        bounceDictionary.value[hierarchy][parseInt(businessSelection.value[hierarchy])];
    } else {
      filtersHierarchy[hierarchy] = Object.keys(bounceDictionary.value[hierarchy]).map((str) => parseInt(str));
      businessSelectionSelectedHierarchyName[hierarchy] = '';
    }
  }
  console.log(businessSelectionSelectedHierarchyName);
  if (parameters.value && uwMonth.value && accMonth.value) {
    financeStore.getPortfolio(
      treeSelected.value,
      filtersHierarchy,
      selectedBusiness.value,
      selectedBusinessID.value,
      nodeName.value,
      parameters.value,
      uwMonth.value,
      accMonth.value,
      [...parameters.value['hierarchies'], ...parameters.value['actuarial_grouping']],
      businessSelectionSelectedHierarchyName,
      yearsOfProjection.value
    );
  } else {
    openErrorMsg('System Error!');
  }

  console.log(filtersHierarchy);
}

const parameters = ref<ParametersObjectType>();
const uwMonth = ref<string[]>();
const accMonth = ref<string[]>();

function selectHierarchy(i, data, idx) {
  businessSelection.value[i] = idx;
  console.log(i, data, idx);
}

async function onClickTree(node: TreeNodeSelectBounceTreeType) {
  treeSelected.value = node.nodeID;
  nodeName.value = node.name;
  await onFetchPortfolio();
  console.log(bounceDictionary.value);
}

async function onFetchPortfolio() {
  globalStore.setLoading(true);

  await api
    .get(import.meta.env.VITE_API_URL + 'portfolio/load-portfolio', {
      params: {
        bounce_id: treeSelected.value,
      },
    })
    .then((res) => {
      console.log(res);
      let promises: Promise<void>[] = [];
      parameters.value = res.data.data['parameters'];
      uwMonth.value = res.data.data['uw_month'];
      accMonth.value = res.data.data['acc_month'];
      if (isMirror.value) {
        promises.push(
          fetchMirroredPortfolioDictionary(treeSelected.value, selectedMirroredID.value).then((res) => {
            if (res && res.mirroredDictionary) {
              bounceDictionary.value = res.mirroredDictionary;
            }
          })
        );
      } else {
        promises.push(
          axios.get(res.data.data.signed_dictionary).then((res) => {
            bounceDictionary.value = res.data;
          })
        );
      }

      return Promise.all(promises);
    })
    .catch((err) => {
      globalStore.setLoading(false);
      if (err.response && err.response.data) {
        openErrorMsg(err.response.data.data);
      } else {
        openErrorMsg('Error! Please try again');
      }
    });

  globalStore.setLoading(false);
}

function createDraftList() {
  let tempDraftList = {};

  if (draftList.value[selectedBusinessID.value]) {
    for (const i of draftList.value[selectedBusinessID.value]) {
      if (!tempDraftList[i.parent_id]) {
        tempDraftList[i.parent_id] = [];
      }

      tempDraftList[i.parent_id].push({ nodeID: i.id, name: i.name });
    }

    selectedDraftList.value = tempDraftList;
  } else {
    selectedDraftList.value = [];
  }

  selectedBounceTreeKey.value += 1;
  console.log(selectedDraftList.value);
}

onMounted(() => {
  retrievePortfolioList();
});

const isMirror = ref(false);
const selectedMirroredID = ref('');

function onSelectBusiness(business: PortfolioType) {
  selectedBusiness.value = business.name;
  selectedBusinessID.value = business.id;
  selectedBusinessBounce.value = business.tree;
  if (business.mirrored_portfolio_id) {
    selectedMirroredID.value = business.mirrored_portfolio_id;
    isMirror.value = true;
  } else {
    isMirror.value = false;
  }
  treeSelected.value = '';
  nodeName.value = '';
  createDraftList();
  // treeSeletedData.value = null;
  // dictData.value = null;
  // dictDataFiltered.value = null;
  // businessSelection.value = null;
}

async function retrievePortfolioList() {
  globalStore.isLoading = true;

  await api
    .get(import.meta.env.VITE_API_URL + 'portfolio/list_portfolio')
    .then((res) => {
      draftList.value = res.data.data.draft_list;
      portfolios.value = embedMirrorPortfolioIntoPortfolio(res.data.data.mirrored_portfolio, res.data.data.portfolios);
    })
    .catch((err) => {
      if (err.response && err.response.data) {
        openErrorMsg(err.response.data.data);
      } else {
        openErrorMsg('Error! Please try again');
      }
      globalStore.isLoading = false;
    });

  globalStore.isLoading = false;
}
</script>

<template>
  <el-dialog v-model="financeStore.isAddPortfolioModal" title="Add new portfolio" :width="650" :fullscreen="true">
    <div>
      <h1 class="mb-1">Select portfolio:</h1>
      <el-dropdown trigger="click">
        <span class="el-dropdown-link">
          {{ selectedBusiness == '' ? 'Select a portfolio' : selectedBusiness }}
          <el-icon class="el-icon--right">
            <arrow-down />
          </el-icon>
        </span>
        <template #dropdown>
          <el-dropdown-menu class="h-52">
            <el-dropdown-item v-for="business in portfolios" :key="business.id" @click="onSelectBusiness(business)">{{
              business.name
            }}</el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
    <div class="mt-2 mb-4">
      <h1>Years Of Projections</h1>
      <el-input-number v-model="yearsOfProjection" :min="0" :max="5" />
    </div>
    <div v-if="selectedBusinessID != ''">
      <h1>Select Bounce:</h1>
      <h1>Selected Bounce: {{ treeSelected ? nodeName : 'Not selected' }}</h1>
      <el-icon class="dot cursor-pointer flex items-center justify-center" @click="show = !show">
        <Minus v-if="show" color="white" />
        <Plus v-if="!show" color="white" />
      </el-icon>
      <SelectBounceTree
        v-if="selectedBusinessBounce && show"
        :key="selectedBounceTreeKey"
        :tree-selected="treeSelected"
        :tree-data="selectedBusinessBounce"
        :selected-draft-list="selectedDraftList"
        @on-click-tree="onClickTree"
      />
    </div>
    <div v-if="bounceDictionary" class="mt-2 mb-2">
      <h1>Hierarchy:</h1>
      <div v-for="i of Object.keys(bounceDictionary)" :key="i" class="mt-2">
        <h1>{{ i }}</h1>
        <el-dropdown trigger="click">
          <span class="el-dropdown-link">
            {{ getBusinessSelection(i) }}
            <el-icon class="el-icon--right">
              <ArrowDown />
            </el-icon>
          </span>
          <template #dropdown>
            <el-scrollbar height="200px">
              <el-dropdown-menu>
                <el-dropdown-item
                  v-for="(data, idx) of bounceDictionary[i]"
                  :key="idx"
                  @click="selectHierarchy(i, data, idx)"
                >
                  {{ data }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-scrollbar>
          </template>
        </el-dropdown>
      </div>
      <el-button type="primary" class="absolute right-5" @click="onBusinessSelection">
        Next
        <el-icon class="el-icon--right">
          <ArrowRight />
        </el-icon>
      </el-button>
    </div>
  </el-dialog>
</template>

<style scoped>
.dot {
  height: 15px;
  width: 15px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
}
</style>

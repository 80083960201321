<script setup lang="ts">
import dagre from 'dagre';
import { ref, type PropType } from 'vue';
import { ConnectionMode, Position, VueFlow, isNode, useVueFlow, type ViewportFunctions } from '@vue-flow/core';
import { Background, BackgroundVariant } from '@vue-flow/background';
import { Controls } from '@vue-flow/controls';
import '@vue-flow/core/dist/style.css';
import '@vue-flow/core/dist/theme-default.css';
import '@vue-flow/controls/dist/style.css';
import BounceNode from './BounceNode.vue';
import type {
  BounceChartTreeSelectionType,
  SelectedDraftListType,
  VueFlowEdgeType,
  VueFlowNodeType,
} from '@/types/portfolio';

const dagreGraph = new dagre.graphlib.Graph();

dagreGraph.setDefaultEdgeLabel(() => ({}));

const emit = defineEmits(['onClickTree']);
const props = defineProps({
  data: {
    type: Object as PropType<(VueFlowNodeType | VueFlowEdgeType)[]>,
    required: true,
  },
  selectedNode: {
    type: Number,
    required: true,
  },
  chartKey: {
    type: Number,
    required: true,
  },
  treeSelected: {
    type: String,
    required: true,
  },
  selectedDraftList: {
    type: Object as PropType<SelectedDraftListType>,
    required: true,
  },
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const elements = ref<any>(props.data);

const a = useVueFlow();

const defaultZoom = ref(2);

function onClickTree(val: BounceChartTreeSelectionType) {
  emit('onClickTree', { val: val.val, isDrafts: val.isDrafts, parent_id: val.parent_id });
}

function onLayout(i: ViewportFunctions) {
  const direction = 'TB';
  i.zoomTo(1);

  // const isHorizontal = direction === 'LR'
  dagreGraph.setGraph({ rankdir: direction });

  elements.value.forEach((el: VueFlowEdgeType) => {
    if (isNode(el)) {
      dagreGraph.setNode(el.id, { width: 200, height: 150 });
    } else {
      dagreGraph.setEdge(el.source, el.target);
    }
  });

  dagre.layout(dagreGraph);

  elements.value.forEach((el: VueFlowEdgeType) => {
    if (isNode(el)) {
      const nodeWithPosition = dagreGraph.node(el.id);
      el.targetPosition = Position.Top;
      el.sourcePosition = Position.Bottom;
      el.position = { x: nodeWithPosition.x, y: nodeWithPosition.y };
    }
  });
}

function onZoom() {
  defaultZoom.value = a.getViewport().zoom;
}
</script>

<template>
  <div class="layoutflow">
    <VueFlow
      v-if="elements"
      :key="chartKey"
      v-model="elements"
      :default-zoom="defaultZoom"
      :connection-mode="ConnectionMode.Strict"
      :pan-on-scroll="true"
      @pane-ready="onLayout($event)"
    >
      <Background :variant="BackgroundVariant.Lines" class="bg-gray-200" />
      <template #node-custom="{ data: nodeData }">
        <BounceNode
          :data="nodeData"
          :tree-selected="treeSelected"
          :elements="elements"
          :selected-draft-list="selectedDraftList"
          @on-click-tree="onClickTree"
        />
      </template>
      <Controls :show-interactive="false" @zoom-out="onZoom" @zoom-in="onZoom" />
    </VueFlow>
  </div>
</template>

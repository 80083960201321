<script setup lang="ts">
import { ref, computed } from 'vue';
import { useActuarialStore } from '@/stores/actuarial';
import RegionLoaderComponent from '@/components/RegionLoaderComponent.vue';
import { Unlock, Lock } from '@element-plus/icons-vue';
import { DEV_FACTORS_TABLE_ID } from '@/views/Actuarial/Triangulations/constants';
const locked = ref(false);

const actuarial_store = useActuarialStore();

function onChangeSelect(index: number, idx: number) {
  console.log(index, idx);
  actuarial_store.onChangeSelectionRatios(index, idx);
}

const ratios = computed(() => actuarial_store.ratios);
const dev_headers = computed(() => Array.from({ length: actuarial_store.devs[0] }, (_, i) => i + 1));
const title = ref('Method');

function calculatedSelectedDevFactors(idx: number): number {
  let sum = 1;
  for (let i = ratios.value['selected_link_ratios']['rows'][2].length - 1; i >= idx; i--) {
    const selection = ratios.value['selected_link_ratios']['rows'][2][i];
    sum *= parseFloat(ratios.value['link_ratios'][selection][i]);
  }
  return sum;
}

function addAverageLinkRatioModal() {
  actuarial_store.onChangeAverageLinkRatioModal(true);
}

function methodClick(event: any, index: any, name: string) {
  if (event.ctrlKey || event.metaKey) {
  } else if (event.shiftKey) {
    actuarial_store.removeLinkRatios(index);
    console.log('clicked with Shift key pressed');
  } else {
    let selectedData: any = [];
    let avg = 100;
    if (index == 'selected') {
      for (let i = 0; i < ratios.value.dev_factors[0].length; i++) {
        let tempSelectedData: any = {};

        tempSelectedData['value'] =
          (1 / ratios.value.dev_factors[ratios.value.selected_link_ratios.rows[2][i]][i]) * 100;
        tempSelectedData['smooth'] = avg;
        tempSelectedData['dev'] = i + 1;

        selectedData.push(tempSelectedData);
      }
    } else {
      for (let i = 0; i < actuarial_store.ratios.dev_factors[index].length; i++) {
        let tempSelectedData: any = {};

        tempSelectedData['value'] = (1 / actuarial_store.ratios.dev_factors[index][i]) * 100;
        tempSelectedData['smooth'] = avg;
        tempSelectedData['dev'] = i + 1;

        selectedData.push(tempSelectedData);
      }
    }

    actuarial_store.setFitDevFactors(selectedData, name);
    actuarial_store.onChangeCurveFittingModal(true);
  }
}

const emit = defineEmits(['on-scroll']);

function scrollTable(t) {
  emit('on-scroll', t);
}
</script>
<template>
  <div :class="locked ? 'locked' : ''">
    <div class="relative w-full bg-sybil-charcoal text-gray-300 mt-5">
      <b class="text-sm px-2 py-1">Development Factors</b
      ><span class="absolute right-2 top-1 cursor-pointer" @click="locked = !locked"
        ><el-icon v-if="locked"><Lock /></el-icon><el-icon v-else><Unlock /></el-icon
      ></span>
    </div>
    <div class="relative">
      <RegionLoaderComponent v-if="actuarial_store.ratiosLoading" />

      <div
        class="relative table-panel"
        :id="DEV_FACTORS_TABLE_ID"
        @scroll="scrollTable(DEV_FACTORS_TABLE_ID)"
        :style="{ overflow: actuarial_store.trianglesLoading ? 'scroll' : 'scroll' }"
      >
        <table id="dev_factors" class="table-panel table-fixed bg-white shadow" style="border-spacing: 0">
          <thead>
            <tr>
              <th class="fixWidth-left header-teal topLeft">
                {{ title }}
                <el-tooltip
                  content="Add a new average link ratios/development factors row"
                  class=""
                  placement="right"
                  :show-after="100"
                >
                  <span @click="addAverageLinkRatioModal" class="text-lgm cursor-pointer hover:text-sybil-teal">+</span>
                </el-tooltip>
              </th>

              <th class="fixWidth header-teal head" v-for="(value, index) in dev_headers" v-bind:key="index">
                {{ dev_headers[index] + ' - Ult' }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(value, index) in ratios['type']" :key="index">
              <td
                class="fixWidth-left side-teal left panel-custom-scrollbar overflow-hidden overflow-x-scroll whitespace-nowrap cursor-pointer"
                @click="(event) => methodClick(event, index, value.slice(0, value.indexOf(')') + 1))"
                :class="
                  value.slice(0, 4) == 'calc'
                    ? 'text-blue-500'
                    : value.slice(0, 4) == 'impt'
                    ? 'text-red-500'
                    : 'text-green-500'
                "
              >
                {{ value }}
              </td>
              <td
                class="fixWidth panel-custom-scrollbar overflow-hidden overflow-x-scroll whitespace-nowrap"
                :class="
                  value.slice(0, 4) == 'calc'
                    ? 'text-blue-500'
                    : value.slice(0, 4) == 'impt'
                    ? 'text-red-500'
                    : 'text-green-500'
                "
                v-for="(val, idx) in ratios['dev_factors'][index]"
                :key="idx"
              >
                {{ val.toFixed(3) }}
              </td>
            </tr>
            <tr
              v-if="ratios['selected_link_ratios']"
              @click="(event) => methodClick(event, 'selected', 'Selected')"
              class="sticky bottom-0 cursor-pointer"
            >
              <td class="fixWidth-left header-teal left">Selected</td>
              <td
                v-for="(val, idx) in ratios['selected_link_ratios']['rows'][2]"
                :key="idx"
                data-testid="selected-dev-factors"
                class="fixWidth cursor-pointer panel-custom-scrollbar header-teal"
              >
                {{ calculatedSelectedDevFactors(idx).toFixed(3) }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<style scoped>
.selected {
  background: rgb(236, 209, 175, 0.2);
  box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset;
}

.table-panel {
  overflow-x: scroll;
  width: 100%;
  max-height: 200px;
  border-collapse: separate;
}

.tableOff {
  height: 58px;
}

.head {
  position: sticky;
  top: 0;
}

.topLeft {
  position: sticky;
  left: 0;
  top: 0;
  z-index: 10;
}

.left {
  position: sticky;
  left: 0;
}

.thead {
  display: table-header-group;
  vertical-align: middle;
  border-color: inherit;
  position: sticky;
  top: 0;
}

.table {
  overflow: scroll;
  position: relative;
  table-layout: fixed;
  margin-bottom: 0 !important;
  border-spacing: 0;
}

.fixWidth-left {
  padding-top: 5px;
  padding-bottom: 5px;
  width: 160px;
  padding-left: 20px;
  padding-right: 20px;
  border: 0.01rem solid #f4f4f5;
  font-size: 12px;
  text-align: center;
}

.fixWidth:hover {
  padding-top: 5px;
  padding-bottom: 5px;
  width: 100px;
  padding-left: 20px;
  padding-right: 20px;
  border: 0.01rem solid #f4f4f5;
  font-size: 12px;
  text-align: center;
  overflow-x: auto;
}

.fixWidth {
  padding-top: 5px;
  padding-bottom: 5px;
  width: 100px;
  padding-left: 20px;
  padding-right: 20px;
  border: 0.01rem solid #f4f4f5;
  font-size: 12px;
  text-align: center;
  overflow-x: hidden;
}

.nowrap {
  white-space: nowrap;
}

.header-teal {
  background-color: rgb(187, 226, 211);
  color: #6b7280;
}

.side-teal {
  background-color: rgb(238, 248, 244);
}

.total-gray {
  background-color: #fafafa;
  /* color: #3f3f46; */
}

.locked {
  position: sticky;
  top: 65px;
  bottom: 0;
  z-index: 50;
}

.panel-custom-scrollbar::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome, Safari and Opera */
}

.panel-custom-scrollbar {
  -ms-overflow-style: none; /* Hide scrollbar for IE and Edge */
  scrollbar-width: none; /* Hide scrollbar for Firefox */
}
</style>

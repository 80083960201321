<script setup lang="ts">
import { useFinanceStore } from '@/stores/finance';
import * as financeConst from '@/constants/finance';
import type { MetricsConstants } from '@/types/finance';
import { ref } from 'vue';
import { ElMessage } from 'element-plus';
import RecalculateFinanceCacheOnChangeMetrics from '@/views/Finance/calculations/RecalculateFinanceCacheOnChangeMetrics';

const INVALID_INPUT_MESSAGE = 'Invalid Input';

const financeStore = useFinanceStore();
const tempInput = ref('');

function onEditMetrics(editIndex: number, metricsToChange: MetricsConstants) {
  financeStore.customMetrics[editIndex][metricsToChange][financeConst.METRICS_IS_EDIT_LOC] = true;
  tempInput.value = (
    financeStore.customMetrics[editIndex][metricsToChange][financeConst.METRICS_VALUE_LOC] * 100
  ).toString();
  console.log(editIndex, metricsToChange);
}

function openErrorMsg(msg: string) {
  ElMessage.warning(msg);
}

function onChangeMetrics(editIndex: number, metricsToChange: MetricsConstants) {
  if (!isFinite(parseFloat(tempInput.value))) {
    openErrorMsg(INVALID_INPUT_MESSAGE);
    return;
  }

  financeStore.customMetrics[editIndex][metricsToChange][financeConst.METRICS_IS_EDIT_LOC] = false;
  financeStore.customMetrics[editIndex][metricsToChange][financeConst.METRICS_VALUE_LOC] =
    parseFloat(tempInput.value) / 100;
  const recalculateFinanceCacheOnChangeMetrics = new RecalculateFinanceCacheOnChangeMetrics(
    financeStore.customMetrics,
    financeStore.businessAccidentValuesMonthConverted,
    financeStore.businessUnderwritingValuesMonthConverted,
    financeStore.accidentFinanceSummary,
    financeStore.underwritingFinanceSummary,
    financeStore.uniqueClaimsNature
  );

  recalculateFinanceCacheOnChangeMetrics.run();
  financeStore.accidentFinanceSummary = recalculateFinanceCacheOnChangeMetrics.getRecalculatedAccidentFinanceSummary();
  financeStore.underwritingFinanceSummary =
    recalculateFinanceCacheOnChangeMetrics.getRecalculatedUnderwritingFinanceSummary();
}

function assignInputValueToTempInput(event: Event) {
  const target = event.target as HTMLInputElement;
  if (target) {
    tempInput.value = target.value;
  }
}

function handleBlur(event: Event) {
  const target = event.target as HTMLInputElement;

  if (target) {
    target.blur();
  }
}

function handleSelect(event: Event) {
  const target = event.target as HTMLInputElement;

  if (target) {
    target.select();
  }
}
</script>
<template>
  <div class="table-panel shadow-md">
    <table class="table-panel table-fixed bg-sybil-teal bg-opacity-10" style="border-spacing: 0">
      <thead>
        <tr>
          <th class="fixWidth-left header-teal text-white topLeft">Portfolio</th>
          <th class="fixWidth header-teal text-white head">LR Load</th>
          <th class="fixWidth header-teal text-white head">OH Expenses</th>
          <th class="fixWidth header-teal text-white head">GWP Growth</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(business, index) of financeStore.businessInformation" :key="business.name">
          <td class="fixWidth-left side-teal left">{{ business.name }}</td>
          <td class="fixWidth bg-white shadow-md">
            <h1
              v-if="financeStore.customMetrics[index].LRLoad[financeConst.METRICS_IS_EDIT_LOC] == false"
              class="cursor-pointer"
              @keyup.enter="handleBlur"
              @dblclick="onEditMetrics(index, financeConst.METRICS_LR_LOAD)"
            >
              {{ (financeStore.customMetrics[index].LRLoad[financeConst.METRICS_VALUE_LOC] * 100).toFixed(1) }}%
            </h1>
            <input
              v-else
              :value="tempInput"
              style="width: 100%; padding: 0; height: 80%; line-height: 100%; font-size: small; margin: auto"
              class="text-center"
              @focus="handleSelect"
              @keyup.enter="onChangeMetrics(index, financeConst.METRICS_LR_LOAD)"
              @keyup="assignInputValueToTempInput"
              @focusout="onChangeMetrics(index, financeConst.METRICS_LR_LOAD)"
            />
          </td>
          <td class="fixWidth bg-white shadow-md">
            <h1
              v-if="financeStore.customMetrics[index].OHExpenses[financeConst.METRICS_IS_EDIT_LOC] == false"
              class="cursor-pointer"
              @keyup.enter="handleBlur"
              @dblclick="onEditMetrics(index, financeConst.METRICS_OH_EXPENSES)"
            >
              {{ (financeStore.customMetrics[index].OHExpenses[financeConst.METRICS_VALUE_LOC] * 100).toFixed(1) }}%
            </h1>
            <input
              v-else
              :value="tempInput"
              style="width: 100%; padding: 0; height: 80%; line-height: 100%; font-size: small; margin: auto"
              class="text-center"
              @focus="handleSelect"
              @keyup.enter="onChangeMetrics(index, financeConst.METRICS_OH_EXPENSES)"
              @keyup="assignInputValueToTempInput"
              @focusout="onChangeMetrics(index, financeConst.METRICS_OH_EXPENSES)"
            />
          </td>
          <td class="fixWidth bg-white shadow-md">
            <h1
              v-if="financeStore.customMetrics[index].GWPGrowth[financeConst.METRICS_IS_EDIT_LOC] == false"
              class="cursor-pointer"
              @keyup.enter="handleBlur"
              @dblclick="onEditMetrics(index, financeConst.METRICS_GWP_GROWTH)"
            >
              {{ (financeStore.customMetrics[index].GWPGrowth[financeConst.METRICS_VALUE_LOC] * 100).toFixed(1) }}%
            </h1>
            <input
              v-else
              :value="tempInput"
              style="width: 100%; padding: 0; height: 80%; line-height: 100%; font-size: small; margin: auto"
              class="text-center"
              @focus="handleSelect"
              @keyup.enter="onChangeMetrics(index, financeConst.METRICS_GWP_GROWTH)"
              @keyup="assignInputValueToTempInput"
              @focusout="onChangeMetrics(index, financeConst.METRICS_GWP_GROWTH)"
            />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<style scoped>
.table-panel {
  overflow: scroll;
  width: 100%;
  max-height: 350px;
  border-collapse: separate;
}
.head {
  position: sticky;
  top: 0;
}
.left {
  position: sticky;
  left: 0;
}
.side-teal {
  background-color: rgb(238, 248, 244);
}
.fixWidth-left {
  padding-top: 5px;
  padding-bottom: 5px;
  width: 150px;
  padding-left: 20px;
  padding-right: 20px;
  border: 0.01rem solid #f4f4f5;
  font-size: 12px;
  text-align: center;
}

.table {
  overflow: scroll;
  position: relative;
  table-layout: fixed;
  margin-bottom: 0 !important;
  border-spacing: 0;
}

.fixWidth {
  padding-top: 5px;
  padding-bottom: 5px;
  width: 100px;
  padding-left: 20px;
  padding-right: 20px;
  border: 0.01rem solid #f4f4f5;
  font-size: 12px;
  text-align: center;
}

.topLeft {
  position: sticky;
  left: 0;
  top: 0;
  z-index: 10;
}

.header-teal {
  background-color: rgb(187, 226, 211);
  color: #6b7280;
}
</style>

<script setup lang="ts">
import * as fs from 'file-saver';
import { UnderwritingAccidentEnum } from '@/types/finance';
import FinanceSummaryTable from './components/FinanceSummaryTable.vue';
import { ref } from 'vue';
import { Workbook, type Borders } from 'exceljs';
import * as logo from '@/assets/images/sybil';
import { tableToArray, toExcelCellRef } from '@/utilities/exceljsHelper';

const underwritingAccident = ref(UnderwritingAccidentEnum.ACCIDENT_DATA);

function onChangeUnderwritingAccident() {
  if (underwritingAccident.value == UnderwritingAccidentEnum.ACCIDENT_DATA) {
    underwritingAccident.value = UnderwritingAccidentEnum.UNDERWRITING_DATA;
  } else {
    underwritingAccident.value = UnderwritingAccidentEnum.ACCIDENT_DATA;
  }
}

function formattedUnderwritingAccident() {
  return underwritingAccident.value == UnderwritingAccidentEnum.ACCIDENT_DATA ? 'Accident' : 'Underwriting';
}

function applyTitleStyle(cell) {
  cell.border = {
    top: { style: 'thin', color: { argb: 'FFEEEEEE' } }, // Grey top border
    left: { style: 'thin', color: { argb: 'FFEEEEEE' } }, // Grey left border
    bottom: { style: 'thin', color: { argb: 'FFEEEEEE' } }, // Grey bottom border
    right: { style: 'thin', color: { argb: 'FFEEEEEE' } }, // Grey right border
  };

  cell.fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'bbe2d3' },
  };
  cell.alignment = {
    wrapText: true,
    horizontal: 'center',
    vertical: 'middle',
  };
}

function onExportTable() {
  let workbook = new Workbook();
  let worksheet = workbook.addWorksheet('Finance Summary Table');

  worksheet.mergeCells('A1:C5');
  let myLogoImage = workbook.addImage({
    base64: logo.imgBase64,
    extension: 'png',
  });
  worksheet.addImage(myLogoImage, 'A1:C5');
  worksheet.addRow([]);

  let rowIndex = 10;

  let tableData = tableToArray(document.getElementById('finance-summary-table'));
  console.log(tableData);
  worksheet.getColumn(1).width = 20;
  worksheet.getColumn(2).width = 20;

  for (let cIndex in tableData) {
    const isFirstTwoTitle =
      tableData[cIndex][0].type == 'string' && tableData[cIndex][1].type == 'string' ? true : false;
    let offset = 0;

    for (let dataIdx in tableData[cIndex]) {
      if ((!isFirstTwoTitle || parseInt(cIndex) == 0) && parseInt(dataIdx) == 1) {
        const row = worksheet.getRow(rowIndex);
        const cell = row.getCell(parseInt(dataIdx) + 1 + offset);
        console.log(offset);
        applyTitleStyle(cell);
        offset += 1;
      }
      const data = tableData[cIndex][dataIdx];
      const row = worksheet.getRow(rowIndex);
      const cell = row.getCell(parseInt(dataIdx) + 1 + offset);
      cell.value = data.value;
      if (data.type == 'string') {
        // it's title
        applyTitleStyle(cell);
      }

      switch (data.type) {
        case 'number':
          cell.numFmt = '#,##0';
          break;
        case 'percentage':
          cell.numFmt = '0.0%';
          break;
        // Other formats as needed
      }
    }
    rowIndex++;
  }

  workbook.xlsx.writeBuffer().then((data) => {
    let blob = new Blob([data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });

    fs.saveAs(blob, 'Finance Summary Table.xlsx');
  });
}
</script>
<template>
  <div>
    <el-button @click="onChangeUnderwritingAccident">{{ formattedUnderwritingAccident() }} Summary</el-button>
    <el-button @click="onExportTable">Export</el-button>
    <FinanceSummaryTable :underwriting-accident="underwritingAccident" />
  </div>
</template>
<style scoped></style>

import { ref } from 'vue';
import { defineStore } from 'pinia';
import { useDashboardStore } from '@/stores/dashboard';
import { useUnderwritingStore } from '@/stores/underwriting';
import moment from 'moment';
import { useGlobalStore } from '@/stores/global';
import { ElMessage } from 'element-plus';
import { ElNotification } from 'element-plus';
import { api } from '@/services/api';
import { useActuarialStore } from '@/stores/actuarial';
import axios from 'axios';
import router from '@/router/index';
import { openSuccessMsg } from '@/utilities/notificationMessage';
import { useAvEActuarialStore } from '@/stores/AvEActuarial';
import type { BinderYears } from '@/types/dashboard';
import { fetchMirroredPortfolioDictionary } from '@/services/portfolio';

export const usePortfolioStore = defineStore('portfolio', () => {
  const ave_portfolio_store = useAvEActuarialStore();
  const dashboard_store = useDashboardStore();
  const global_store = useGlobalStore();
  const underwriting_store = useUnderwritingStore();
  const actuarial_store = useActuarialStore();
  const selectedPortfolioID = ref('');
  const isMirrored = ref(false);
  // the original dictionary for the mirrored portfolio
  const originalDictionary = ref({});
  const selectedPortfolioName = ref('');
  const selectedBounceID = ref('');
  const selectedBounceName = ref('');
  const selectedBounceDate = ref('');
  const selectedBounceFullName = ref('');
  const selectedBounceUser = ref('');
  const selectedBounceIsFormal = ref(false);
  const forwardLookings = ref<[number, number][] | null>(null);
  const oldForwardLookings = ref<[number, number][] | null>(null);
  const selectedBounceTimeStamp = ref('');
  const isBounce = ref(false);
  const isAve = ref(false);
  const current_month = ref('');
  const normalise = ref<boolean[]>([]);
  const normaliseSelection = ref<boolean[]>([]);
  const dictionary: any = ref({});

  // ACTUAL VS EXPECTED
  const expectedBounceID = ref('');
  const isActualVsExpectedModal = ref(false);
  const expectedNodeName = ref('');
  const selectedExpectedReportDate = ref('');
  const selectedExpectedReportDateHeader = ref('');
  const selectedExpectedReportName = ref('');
  const selectedExpectedReportisFormal = ref(false);
  const selectedExpectedBounceTimeStamp = ref('');
  const selectedExpectedselectedBounceUser = ref('');

  // Signed Off
  const signedOff = ref({
    underwriting_module: false,
    actuarial_module: {},
  });

  function signedOffActuarialFrontend(isSignOff: boolean) {
    const currentGroup = actuarial_store.getSelectedGroup();
    signedOff.value['actuarial_module'][currentGroup.join(',')] = isSignOff;
  }

  async function signedOffActuarial() {
    const currentGroup = actuarial_store.getSelectedGroup();
    signedOff.value['actuarial_module'][currentGroup.join(',')] =
      !signedOff.value['actuarial_module'][currentGroup.join(',')];
    console.log(signedOff.value['actuarial_module'][currentGroup.join(',')]);
    console.log(signedOff.value['actuarial_module']);
    postSignedOff('actuarial_module');
  }

  async function getSignedOff() {
    global_store.setLoading(true);
    await api
      .get(import.meta.env.VITE_API_URL + 'portfolio/signed-off', {
        params: {
          bounce_id: selectedBounceID.value,
          hierarchy: parameters.value['hierarchies'],
          actuarial_grouping: parameters.value['actuarial_grouping'],
        },
      })
      .then((res) => {
        console.log(res.data.data.signed_off);
        signedOff.value = res.data.data.signed_off;
        global_store.setLoading(false);
      })
      .catch((err) => {
        global_store.setLoading(false);
        if (err.response && err.response.data) {
          openErrorMsg(err.response.data.data);
        } else {
          openErrorMsg('Error! Please try again');
        }
      });
  }

  async function postSignedOff(signed_off_type: string) {
    await api
      .post(import.meta.env.VITE_API_URL + 'portfolio/signed-off', {
        bounce_id: selectedBounceID.value,
        signed_off_type: signed_off_type,
        signed_off_data: signedOff.value[signed_off_type],
      })
      .then(async () => {
        await getSignedOff().then(() => global_store.setLoading(false));
      })
      .catch((err) => {
        global_store.setLoading(false);
        if (err.response && err.response.data) {
          openErrorMsg(err.response.data.data);
        } else {
          openErrorMsg('Error! Please try again');
        }
      });
  }

  function signedOffUnderwriting() {
    signedOff.value['underwriting_module'] = !signedOff.value['underwriting_module'];
    postSignedOff('underwriting_module');
  }

  async function onSelectExpectedBounceID(id: string, expectedReportDate: string, bounceName: string) {
    expectedBounceID.value = id;
    isActualVsExpectedModal.value = false;
    selectedExpectedReportDate.value = expectedReportDate;
    selectedExpectedReportDateHeader.value = bounceName.slice(0, 6);
    selectedExpectedBounceTimeStamp.value = bounceName.slice(7, 21);
    selectedExpectedReportisFormal.value = bounceName.slice(21, 22) == 'F';
    selectedExpectedselectedBounceUser.value = selectedExpectedReportisFormal.value
      ? bounceName.slice(23, 25)
      : bounceName.slice(22, 24);
    selectedExpectedReportName.value = selectedExpectedReportisFormal.value
      ? bounceName.slice(26)
      : bounceName.slice(25);

    if (parseInt(current_month.value.split('-')[1]) - parseInt(bounceName.split('-')[0].slice(4, 6)) < 0) {
      openErrorMsg('Expected month is bigger than current month');
      return;
    }

    actuarial_store.setReportingPeriodAve(
      parseInt(current_month.value.split('-')[1]) - parseInt(bounceName.split('-')[0].slice(4, 6))
    );
    ave_portfolio_store.isActuarialAveEligible = false;
    isAve.value = true;

    // all the data that needed for ave
    dashboard_store.loadDashboard();
    await actuarial_store.getActuarialChunkGrouping();
    actuarial_store.fetchTriangulationsData();
  }

  function onSetParameters(parameters_to_be_set) {
    parameters.value = parameters_to_be_set;
  }

  function changeIsActualVsExpectedModal(e: boolean) {
    isActualVsExpectedModal.value = e;
  }

  const selectedLineSize = ref('100% Share');
  const all_uw_dates: any = ref({
    month: [],
  });
  const all_acc_dates: any = ref({});

  const parameters: any = ref({
    hierarchies: [],
    default_hierarchies: [],
    large_threshold: null,
    actuarial_grouping: [],
    claims_nature: [],
    line_size: [],
    commission: [],
    large_method: null,
    binding_years: [],
    target: {},
  });
  // const doubleCount = computed(() => count.value * 2)

  function createFiltersForDashboard() {
    let filters: any = {};
    for (const key of Object.keys(dictionary.value)) {
      filters[key] = Object.values(dictionary.value[key]);
    }
    dashboard_store.setFilters(filters);
  }

  function convert_distinct_uw_acc(portfolio_data: any) {
    let temp_uw_month: string[] = [];
    let temp_acc_month: string[] = [];

    let quarter_set = new Set();
    let temp_uw_quarter: string[] = [];
    let temp_acc_quarter: string[] = [];

    let year_set = new Set();
    let temp_uw_year: string[] = [];
    let temp_acc_year: string[] = [];

    for (const i of portfolio_data['uw_month']) {
      const quarter = moment(i[0], 'YYYY/MM/DD').format('[Q]Q-YYYY');
      const year = moment(i[0], 'YYYY/MM/DD').format('YYYY');

      if (!quarter_set.has(quarter)) {
        quarter_set.add(quarter);
        temp_uw_quarter.push(quarter);
      }

      if (!year_set.has(year)) {
        year_set.add(year);
        temp_uw_year.push(year);
      }

      temp_uw_month.push(moment(i[0], 'YYYY/MM/DD').format('MMM-YYYY'));
    }

    quarter_set = new Set();
    year_set = new Set();

    for (const i of portfolio_data['acc_month']) {
      const quarter = moment(i[0], 'YYYY/MM/DD').format('[Q]Q-YYYY');
      const year = moment(i[0], 'YYYY/MM/DD').format('YYYY');

      if (!quarter_set.has(quarter)) {
        quarter_set.add(quarter);
        temp_acc_quarter.push(quarter);
      }

      if (!year_set.has(year)) {
        year_set.add(year);
        temp_acc_year.push(year);
      }
    }

    all_uw_dates.value['month'] = temp_uw_month;
    all_acc_dates.value['month'] = temp_acc_month;

    all_uw_dates.value['quarter'] = temp_uw_quarter;
    all_acc_dates.value['quarter'] = temp_acc_quarter;

    all_uw_dates.value['year'] = temp_uw_year;
    all_acc_dates.value['year'] = temp_acc_year;
  }

  async function getBounceInformation() {
    global_store.setLoading(true);
    await api
      .get(import.meta.env.VITE_API_URL + 'portfolio/get-bounce-information', {
        params: {
          bounce_id: selectedBounceID.value,
        },
      })
      .then((res) => {
        const bounceName = res.data.data.name;
        selectedBounceFullName.value = bounceName;
        console.log(selectedBounceFullName.value);
        selectedBounceDate.value = bounceName.slice(0, 6);
        selectedBounceTimeStamp.value = bounceName.slice(7, 21);
        selectedBounceIsFormal.value = bounceName.slice(21, 22) == 'F';
        selectedBounceUser.value = selectedBounceIsFormal.value ? bounceName.slice(23, 25) : bounceName.slice(22, 24);
        console.log(bounceName);
        selectedBounceName.value = selectedBounceIsFormal.value ? bounceName.slice(26) : bounceName.slice(25);
        isBounce.value = res.data.data.is_bounce;
        actuarial_store.setActuarialCacheResetRequired(res.data.data.is_ibnr_reset_required);
        global_store.setLoading(false);
      })
      .catch(() => {
        global_store.setLoading(false);
      });
  }

  async function changeBounceID(bounceID: string) {
    selectedBounceID.value = bounceID;
    ElNotification.success({
      title: 'A draft version is created',
      message: 'All changes that you make will be saved to this draft instance',
      position: 'bottom-right',
    });

    getBounceInformation();
  }

  async function pollingUpload() {
    console.log('pollingUpload');
    const intervalId = setInterval(async () => {
      await api
        .get(import.meta.env.VITE_API_URL + 'portfolio/check-upload-status', {
          params: {
            bounce_id: selectedBounceID.value,
          },
        })
        .then((res) => {
          if (!res.data.data.is_upload) {
            clearInterval(intervalId);
          }
          if (res.data.data.is_upload && res.data.data.is_upload_done) {
            if (res.data.data.status == 'failed') {
              if (res.data.data.message) {
                openErrorMsg(res.data.data.message);
              }
            } else {
              if (res.data.data.message) {
                openSuccessMsg(res.data.data.message);
              }

              openSuccessMsg('Refresh required, auto refresh in 5 seconds');
              clearInterval(intervalId);
              setTimeout(() => {
                // refresh page
                location.reload();
              }, 5000);
            }
          }
        })
        .catch((err) => {
          if (err && err.response && err.response.data) {
            openErrorMsg(err.response.data.data);
          }
          clearInterval(intervalId);
        });
    }, 5000);
  }

  async function onFetchPortfolio() {
    console.log('--------------------------------------------------');
    global_store.setLoading(true);

    let data = {
      dictionary: null,
      parameters: {},
      uw_month: [],
      acc_month: [],
      seasonality: [],
      large_method: 0,
      large_threshold: 0,
    };

    await api
      .get(import.meta.env.VITE_API_URL + 'portfolio/load-portfolio', {
        params: {
          bounce_id: selectedBounceID.value,
        },
      })
      .then(async (res) => {
        console.log(res);
        await actuarial_store.getActuarialChunkGrouping();
        current_month.value = res.data.data.current_month;
        isBounce.value = res.data.data.is_bounce;
        console.log(current_month.value);
        data.uw_month = res.data.data.uw_month;
        data.acc_month = res.data.data.acc_month;
        data.seasonality = res.data.data.seasonality;
        data.large_method = res.data.data.large_method;
        data.large_threshold = res.data.data.large_threshold;

        if (
          res.data.data['parameters']['default_dashboard'] &&
          res.data.data['parameters']['default_dashboard']['accident_underwriting']
        ) {
          dashboard_store.dashboards.uw_acc = res.data.data['parameters']['default_dashboard']['accident_underwriting'];
          dashboard_store.dashboards.mqy = res.data.data['parameters']['default_dashboard']['cohort'];
        }

        if (res.data.data['parameters']['currency_format']) {
          global_store.currency = res.data.data['parameters']['currency_format'];
        }

        actuarial_store.setActuarialCacheResetRequired(res.data.data.is_ibnr_reset_required);

        pollingUpload();

        let promises: any = [];

        promises.push(
          axios.get(res.data.data.signed_dictionary).then((res) => {
            data.dictionary = res.data;
            console.log(data.dictionary);
          })
        );
        data.parameters = res.data.data.parameters;

        data.parameters['seasonality'] = data.seasonality;
        data.parameters['large_method'] = data.large_method;
        data.parameters['large_threshold'] = data.large_threshold;

        return Promise.all(promises);
      })
      .then(() => {
        // console.log(data);
        if (isMirrored.value) {
          return Promise.all([onFetchMirroredPortfolioDictionary()]);
        } else {
          return;
        }
      })
      .then(() => {
        global_store.setLoading(false);
        onSetPortfolio(data);
      })
      .catch((err) => {
        global_store.setLoading(false);
        if (err.response && err.response.data) {
          openErrorMsg(err.response.data.data);
        } else {
          openErrorMsg('Error! Please try again');
        }
      });

    global_store.setLoading(false);
  }

  function openErrorMsg(msg: string) {
    ElMessage.warning(msg);
  }

  async function onSelectPortfolio(
    portfolioID: string,
    bounceID: string,
    portfolioName: string,
    bounceName: string,
    mirrored: boolean
  ) {
    selectedPortfolioID.value = portfolioID;
    selectedPortfolioName.value = portfolioName;
    selectedBounceID.value = bounceID;
    isMirrored.value = mirrored;

    await onFetchPortfolio();
    if (mirrored) {
      await onFetchMirroredPortfolioDictionary();
    }
    router.push({ path: '/dashboard' });
    console.log(dashboard_store.report_date);
    selectedBounceFullName.value = bounceName;
    console.log(selectedBounceFullName.value);
    selectedBounceDate.value = bounceName.slice(0, 6);
    selectedBounceTimeStamp.value = bounceName.slice(7, 21);
    selectedBounceIsFormal.value = bounceName.slice(21, 22) == 'F';
    selectedBounceUser.value = selectedBounceIsFormal.value ? bounceName.slice(23, 25) : bounceName.slice(22, 24);
    console.log(bounceName);
    selectedBounceName.value = selectedBounceIsFormal.value ? bounceName.slice(26) : bounceName.slice(25);

    onResetForwardLooking();
  }

  async function onResetForwardLooking(change: boolean = false) {
    await api
      .post(import.meta.env.VITE_API_URL + 'underwriting/forwardlooking', {
        bounce_id: selectedBounceID.value,
        report_date: dashboard_store.report_date,
        parameters_json: parameters.value,
      })
      .then((res) => {
        let ccrnlr = dashboard_store.dashboards.ccr_nlr;
        if (change) {
          oldForwardLookings.value = structuredClone(forwardLookings.value);
        }
        forwardLookings.value = res.data.data;
        console.log(forwardLookings.value);
      })
      .catch((err) => {
        console.log(err);
        global_store.setLoading(false);
        throw err;
      });
  }

  function setOldForwardLookings() {
    console.log(oldForwardLookings.value);
    oldForwardLookings.value = structuredClone(forwardLookings.value);
    console.log(oldForwardLookings.value);
  }

  function resetOldForwardLookings() {
    oldForwardLookings.value = null;
  }

  function setSelectedLineSize(val: string) {
    dashboard_store.dashboard_data = null;

    selectedLineSize.value = val;
  }

  async function onFetchMirroredPortfolioDictionary() {
    await fetchMirroredPortfolioDictionary(selectedBounceID.value, selectedPortfolioID.value).then((res) => {
      if (res && res.mirroredDictionary) {
        dictionary.value = res.mirroredDictionary;
      }
    });
  }

  function onSetPortfolio(portfolio_data: any) {
    if (isMirrored.value) {
      originalDictionary.value = portfolio_data['dictionary'];
    } else {
      dictionary.value = portfolio_data['dictionary'];
    }
    parameters.value = portfolio_data['parameters'];

    /**
     * Create default values if not available
     */
    if (!parameters.value['binding_years']) {
      parameters.value['binding_years'] = [];
    }

    if (!parameters.value['default_hierarchies']) {
      parameters.value['default_hierarchies'] = [];
    }

    if (!parameters.value['target']) {
      parameters.value['target'] = {};
    }

    /**
     * End
     */

    console.log(portfolio_data);
    convert_distinct_uw_acc(portfolio_data);

    let temp_normalised: boolean[] = [];
    let temp_normalised_selection: boolean[] = [];

    for (const i of parameters.value.claims_nature.slice(1)) {
      temp_normalised.push(true);
      temp_normalised_selection.push(true);
    }

    normalise.value = temp_normalised;
    normaliseSelection.value = temp_normalised;

    createFiltersForDashboard();
    getSignedOff();
    console.log(current_month.value);
    // underwriting_store.setHierarchyOrder()
  }

  function setNormalisedSelection(index: any, x: boolean) {
    normaliseSelection.value[index] = x;
    dashboard_store.setChartData();
  }

  function removeBindedYears() {
    let old_parameters = structuredClone(parameters.value);
    parameters.value.binding_years.pop();
    while (
      parameters.value.binding_years.length > 0 &&
      parameters.value.binding_years[parameters.value.binding_years.length - 1].type == 'exceptional_period'
    ) {
      parameters.value.binding_years.pop();
    }
    saveParameters(old_parameters, 'Deleted Binded Years');
  }

  function addBindedYears(currentBindedYearsSelection: BinderYears) {
    const oldParameters = structuredClone(parameters.value);
    const arr = JSON.parse(JSON.stringify(currentBindedYearsSelection));

    for (const i of arr) {
      if (i.type == 'exceptional_period') {
        i.range[0] = moment(i['range'][0]).format('MMM-YYYY');
        i.range[1] = moment(i['range'][1]).format('MMM-YYYY');
        i.group = i.group.trim();
      }
    }
    parameters.value.binding_years = [...parameters.value.binding_years, ...arr];
    saveParameters(oldParameters, 'Added Binded Years');
  }

  async function saveParameters(old_parameters: any, msg: string) {
    global_store.setLoading(true);
    await api
      .post(import.meta.env.VITE_API_URL + 'portfolio/save-parameters', {
        bounce_id: selectedBounceID.value,
        new_parameters: parameters.value,
      })
      .then(() => {
        openSuccessMsg(msg);
        global_store.setLoading(false);
      })
      .catch((e) => {
        parameters.value = old_parameters;
        global_store.setLoading(false);
        openErrorMsg('Error!');
      });
  }

  function toggleIsAve() {
    if (!isAve.value) {
      changeIsActualVsExpectedModal(true);
    } else {
      isAve.value = false;
      dashboard_store.transformDataToDate();
    }
  }

  return {
    changeBounceID,
    addBindedYears,
    setNormalisedSelection,
    normalise,
    normaliseSelection,
    selectedPortfolioID,
    selectedPortfolioName,
    selectedBounceID,
    onSetPortfolio,
    dictionary,
    parameters,
    all_acc_dates,
    all_uw_dates,
    onSelectPortfolio,
    onFetchPortfolio,
    selectedBounceFullName,
    selectedBounceDate,
    selectedBounceTimeStamp,
    selectedBounceIsFormal,
    selectedBounceUser,
    selectedBounceName,
    isAve,
    forwardLookings,
    oldForwardLookings,
    toggleIsAve,
    selectedLineSize,
    setSelectedLineSize,
    current_month,
    isActualVsExpectedModal,
    changeIsActualVsExpectedModal,
    onSelectExpectedBounceID,
    expectedBounceID,
    selectedExpectedReportDate,
    selectedExpectedReportDateHeader,
    selectedExpectedReportName,
    selectedExpectedReportisFormal,
    selectedExpectedBounceTimeStamp,
    selectedExpectedselectedBounceUser,
    onResetForwardLooking,
    onSetParameters,
    getSignedOff,
    postSignedOff,
    signedOff,
    signedOffUnderwriting,
    signedOffActuarial,
    signedOffActuarialFrontend,
    isBounce,
    getBounceInformation,
    removeBindedYears,
    saveParameters,
    setOldForwardLookings,
    resetOldForwardLookings,
    pollingUpload,
    isMirrored,
    originalDictionary,
  };
});

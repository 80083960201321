<script setup lang="ts">
import { computed, onMounted, ref, nextTick, watch, onUnmounted } from 'vue';
import { useActuarialStore } from '@/stores/actuarial';
import { usePortfolioStore } from '@/stores/portfolio';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import * as am5 from '@amcharts/amcharts5/index';
import * as am5percent from '@amcharts/amcharts5/percent';

function toTitleCase(str: string) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}
let root;
let data;
function confirm() {}
const activeTabIndex = ref(0);
const portfolio_store = usePortfolioStore();
const actuarial_store = useActuarialStore();
const chartData = ref(null);
const currHierarchy = computed(() => actuarial_store.currHierarchyOnHierarchyGroupingListModal);

const actuarialHierarchy: any = computed(() =>
  actuarial_store.actuarialHierarchy[currHierarchy.value] ? actuarial_store.actuarialHierarchy[currHierarchy.value] : {}
);

const inputVal = computed({
  // getter
  get() {
    return actuarial_store.isHierarchyPercentageModal;
  },
  // setter
  set() {
    actuarial_store.changeActuarialPercentageModal(false);
  },
});
const num = ref(structuredClone(actuarial_store.claims_combinations_pc));
const claims = computed(() => actuarial_store.actual_claims_combinations_pc);
onMounted(() => {
  console.log(num.value);
  createChartData();

  nextTick(() => {
    createPieChart();
  });
});

function createChartData() {
  let dataItem: any = [];
  Object.keys(portfolio_store.dictionary[currHierarchy.value])
    .sort()
    .forEach((key, idx) => {
      let item = {
        item: portfolio_store.dictionary[currHierarchy.value][key],
        actual: claims.value[activeTabIndex.value][idx],
        parameter:
          num.value[activeTabIndex.value][portfolio_store.parameters.actuarial_grouping.indexOf(currHierarchy.value)][
            idx
          ],
      };
      dataItem.push(item);
    });

  // for (let idx in Object.values(portfolio_store.dictionary[currHierarchy.value])) {
  //   let item = {
  //     item: Object.values(portfolio_store.dictionary[currHierarchy.value])[idx],
  //     actual: claims.value[activeTabIndex.value][idx],
  //     parameter:
  //       num.value[activeTabIndex.value][portfolio_store.parameters.actuarial_grouping.indexOf(currHierarchy.value)][
  //         idx
  //       ],
  //   };
  //   dataItem.push(item);
  // }
  chartData.value = dataItem;

  return structuredClone(chartData.value);
}

function checkTotal(idx1: number, idx2: number, idx3: number, val: number, old_val: number) {
  let sum = num.value[idx1][idx2].reduce((a, b) => a + b, 0);
  let indexKey = idx3;

  if (idx3 == Object.values(portfolio_store.dictionary[currHierarchy.value]).length - 1) {
    if (sum < 100) {
      let index = 0;
      let remain = parseFloat((100 - sum).toFixed(1));
      while (remain != 0 && index < num.value[idx1][idx2].length) {
        if (indexKey == index) {
          index += 1;
          continue;
        }
        if (num.value[idx1][idx2][index] < 100) {
          num.value[idx1][idx2][index] += remain;
          if (num.value[idx1][idx2][index] <= 100) {
            remain = 0;
          } else {
            remain = num.value[idx1][idx2][index] - 100;
            num.value[idx1][idx2][index] = 100;
          }
        }
        index += 1;
      }
    } else {
      let index = 0;
      let remain = parseFloat((sum - 100).toFixed(1));
      while (remain != 0 && index < num.value[idx1][idx2].length) {
        if (indexKey == index) {
          index += 1;
          continue;
        }
        if (num.value[idx1][idx2][index] > 0) {
          num.value[idx1][idx2][index] -= remain;
          if (num.value[idx1][idx2][index] >= 0) {
            remain = 0;
          } else {
            remain = -num.value[idx1][idx2][index];
            num.value[idx1][idx2][index] = 0;
          }
        }
        index += 1;
      }
    }
  } else {
    if (sum < 100) {
      let index = num.value[idx1][idx2].length;
      let remain = parseFloat((100 - sum).toFixed(1));
      while (remain != 0 && index >= 0) {
        if (indexKey == index) {
          index -= 1;
          continue;
        }
        if (num.value[idx1][idx2][index] < 100) {
          num.value[idx1][idx2][index] += remain;
          if (num.value[idx1][idx2][index] <= 100) {
            remain = 0;
          } else {
            remain = num.value[idx1][idx2][index] - 100;
            num.value[idx1][idx2][index] = 100;
          }
        }
        index -= 1;
      }
    } else {
      let index = num.value[idx1][idx2].length;
      let remain = parseFloat((sum - 100).toFixed(1));
      while (remain != 0 && index >= 0) {
        if (indexKey == index) {
          index -= 1;
          continue;
        }
        if (num.value[idx1][idx2][index] > 0) {
          num.value[idx1][idx2][index] -= remain;
          if (num.value[idx1][idx2][index] >= 0) {
            remain = 0;
          } else {
            remain = -num.value[idx1][idx2][index];
            num.value[idx1][idx2][index] = 0;
          }
        }
        index -= 1;
      }
    }
  }
  createPieChart();
}

watch(
  () => activeTabIndex.value,
  () => {
    createPieChart();
  }
);

onUnmounted(() => {
  if (root) {
    root.dispose();
  }
  root = null;
});

function createPieChart() {
  data = createChartData();
  if (root) {
    root.dispose();
  }

  root = am5.Root.new('chartdiv');
  let myTheme = am5.Theme.new(root);
  myTheme.rule('Label').set('fontSize', '0.8em');

  root.setThemes([am5themes_Animated.new(root), myTheme]);

  // Create chart
  // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/
  // start and end angle must be set both for chart and series
  let chart = root.container.children.push(
    am5percent.PieChart.new(root, {
      layout: root.verticalLayout,
      innerRadius: am5.percent(40),
      tooltip: am5.Tooltip.new(root, {}),
    })
  );

  // Create series
  // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/#Series
  // start and end angle must be set both for chart and series
  let series0 = chart.series.push(
    am5percent.PieSeries.new(root, {
      valueField: 'actual',
      categoryField: 'item',
      alignLabels: false,
    })
  );

  let bgColor = root.interfaceColors.get('background');

  series0.slices.template.setAll({
    stroke: bgColor,
    strokeWidth: 2,
    tooltipText: "{category}: Actual {valuePercentTotal.formatNumber('0.0')}%",
  });
  series0.slices.template.states.create('hover', { scale: 0.95 });

  let series1 = chart.series.push(
    am5percent.PieSeries.new(root, {
      valueField: 'parameter',
      categoryField: 'item',
      alignLabels: true,
    })
  );

  series1.slices.template.setAll({
    stroke: bgColor,
    strokeWidth: 2,
    tooltipText: "{category} Parameter: {valuePercentTotal.formatNumber('0.00')}% ",
  });

  series1.labels.template.setAll({
    textType: 'circular',
    templateField: 'dummyLabelSettings',
  });

  // for (let l = 0; l<10;l++){
  //   let series2 = chart.series.push(am5percent.PieSeries.new(root, {
  //   valueField: "parameter",
  //   categoryField: "item",
  //   alignLabels: true
  // }));

  // series2.slices.template.setAll({
  //   stroke: bgColor,
  //   strokeWidth: 2,
  //   tooltipText:
  //     "{category} Parameter: {valuePercentTotal.formatNumber('0.00')}% "
  // });

  // series2.labels.template.setAll({
  //   textType: "circular",
  //   templateField: "dummyLabelSettings"
  // });
  // series2.data.setAll(data);
  // series2.ticks.template.set("forceHidden", true);
  // series2.labels.template.setAll({
  //   visible: false
  // });

  // }

  // Set data
  // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/#Setting_data
  console.log(data);
  series0.data.setAll(data);
  series1.data.setAll(data);

  // series0.appear(1000, 100);
  // series1.appear(1000, 100);
  series0.ticks.template.set('forceHidden', true);
  series1.ticks.template.set('forceHidden', true);
  series0.labels.template.setAll({
    visible: false,
  });

  // Hide labels for series1
  series1.labels.template.setAll({
    visible: false,
  });

  let legend = chart.children.push(
    am5.Legend.new(root, {
      centerX: am5.percent(50),
      x: am5.percent(50),
      layout: root.verticalLayout,
      height: am5.percent(100),
      marginTop: 15,
      marginBottom: 15,
      verticalScrollbar: am5.Scrollbar.new(root, {
        orientation: 'vertical',
      }),
    })
  );

  legend.data.setAll(series1.dataItems);
}

function matchActual() {
  num.value[activeTabIndex.value][portfolio_store.parameters.actuarial_grouping.indexOf(currHierarchy.value)] =
    structuredClone(claims.value[activeTabIndex.value]);

  // need to make sure the percentage is 100%
  // If the total is more than 100%, reduce the first element until it is less than 100%
  while (
    num.value[activeTabIndex.value][portfolio_store.parameters.actuarial_grouping.indexOf(currHierarchy.value)].reduce(
      (sum, e) => sum + e
    ) > 100
  ) {
    num.value[activeTabIndex.value][
      portfolio_store.parameters.actuarial_grouping.indexOf(currHierarchy.value)
    ][0] -= 0.1;
  }

  // If the total is less than 100%, increase the first element until it is more than 100%
  while (
    num.value[activeTabIndex.value][portfolio_store.parameters.actuarial_grouping.indexOf(currHierarchy.value)].reduce(
      (sum, e) => sum + e
    ) < 100
  ) {
    num.value[activeTabIndex.value][
      portfolio_store.parameters.actuarial_grouping.indexOf(currHierarchy.value)
    ][0] += 0.1;
  }
  createPieChart();
}

function confirmChange() {
  actuarial_store.setPercentagleParameter(num.value);
}
</script>

<template>
  <el-dialog v-model="inputVal" :show-close="false" width="750px">
    <template #header>
      <div class="my-header text-xl">
        <h1>
          <span class="text-sybil-teal font-bold">{{ currHierarchy }}</span>
          Percentages
        </h1>
      </div>
    </template>

    <div class="flex flex-row h-full w-full p-5">
      <el-tabs v-model="activeTabIndex" tab-position="top" style="height: 300px" class="demo-tabs">
        <el-tab-pane
          v-for="(item, index) in portfolio_store.parameters.claims_nature"
          :label="toTitleCase(item)"
          :name="index"
        >
          <div>
            <div v-if="actuarial_store.claims_combinations_pc" style="width: 200px">
              <el-scrollbar height="500px" class="text-lg">
                <div
                  v-for="(val, idx) in Object.keys(portfolio_store.dictionary[currHierarchy]).sort()"
                  :key="idx"
                  class="mt-3"
                >
                  {{ portfolio_store.dictionary[currHierarchy][val] }}<br />
                  <el-input-number
                    v-model="num[index][portfolio_store.parameters.actuarial_grouping.indexOf(currHierarchy)][idx]"
                    @change="
                      (val, old_val) =>
                        checkTotal(
                          index,
                          portfolio_store.parameters.actuarial_grouping.indexOf(currHierarchy),
                          idx,
                          val,
                          old_val
                        )
                    "
                    :precision="1"
                    :step="0.1"
                    :max="100"
                    :min="0"
                  />
                </div>

                <div class="h-16"></div>
              </el-scrollbar>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
      <div id="chartdiv" style="height: 500px; width: 500px"></div>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" plain @click="matchActual()">Match History</el-button>
        <el-button type="info" @click="inputVal = false">Cancel</el-button>
        <el-button type="primary" @click="confirmChange()">Confirm</el-button>
      </span>
    </template>
  </el-dialog>
</template>

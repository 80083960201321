<script setup lang="ts">
import { useActionsStore } from '@/stores/actions';
import { ref, watch } from 'vue';
import type { StatusObject, UserObj } from '@/types/actions';
import { ArrowDown } from '@element-plus/icons-vue';
import { openErrorMsg } from '@/utilities/errorhandler';
import moment from 'moment';

const actionsStore = useActionsStore();

watch(
  () => actionsStore.isAddActionsFormModal,
  () => {
    if (actionsStore.isMoreDetailsOrNewAction == true) {
      createNewActionDescription.value = actionsStore.selectedData?.description || '';
      createNewActionDataTitle.value = actionsStore.selectedData?.title || '';
      createNewActionDataDueDate.value = actionsStore.selectedData?.due_date || null;
      createNewActionDataStatus.value = actionsStore.selectedData?.status?.name || 'No Status';
      createNewActionDataAssignedTo.value = actionsStore.selectedData?.assigned_to
        ? actionsStore.selectedData.assigned_to.first_name + ' ' + actionsStore.selectedData.assigned_to.last_name
        : 'Not Assigned';
      createNewActionID.value = {
        statusID: actionsStore.selectedData?.status?.id || null,
        assignedID: actionsStore.selectedData?.assigned_to?.id || null,
      };
      currentAssignedListModal.value = structuredClone(actionsStore.userList);
      currentStatusListModal.value = structuredClone(actionsStore.statusList);
    } else {
      createNewActionDescription.value = '';
      createNewActionDataTitle.value = '';
      createNewActionDataDueDate.value = null;
      createNewActionDataStatus.value = 'No Status';
      createNewActionDataAssignedTo.value = 'Not Assigned';
      createNewActionID.value = {
        statusID: null,
        assignedID: null,
      };
      currentAssignedListModal.value = structuredClone(actionsStore.userList);
      currentStatusListModal.value = structuredClone(actionsStore.statusList);
    }
  }
);

function onAssignAddAction(i: undefined | UserObj) {
  if (i) {
    createNewActionDataAssignedTo.value = i.name;
    createNewActionID.value['assignedID'] = i.id;
  } else {
    createNewActionDataAssignedTo.value = 'Not Assigned';
    createNewActionID.value['assignedID'] = null;
  }
}

function onStatusAddAction(i: undefined | StatusObject) {
  if (i) {
    createNewActionDataStatus.value = i.name;
    createNewActionID.value['statusID'] = i.id;
  } else {
    createNewActionDataStatus.value = 'No Status';
    createNewActionID.value['statusID'] = null;
  }
}

const createNewActionDescription = ref('');
const createNewActionDataTitle = ref('');
const createNewActionDataDueDate = ref<string | Date | null>(null);
const createNewActionDataStatus = ref('No Status');
const createNewActionDataAssignedTo = ref('Not Assigned');
const currentAssignedListModal = ref<UserObj[]>([]);
const currentStatusListModal = ref<StatusObject[]>([]);
const createNewActionID = ref();

function onCloseActionsModal() {
  actionsStore.isMoreDetailsOrNewAction = false;
  actionsStore.selectedData = null;
  actionsStore.isAddActionsFormModal = false;
}

function addActions() {
  if (createNewActionDataTitle.value === '') {
    openErrorMsg('Please fill in all fields');
    return;
  }

  if (actionsStore.isMoreDetailsOrNewAction) {
    if (actionsStore.selectedData) {
      actionsStore.patchActions({
        id: actionsStore.selectedData.id,
        owner: actionsStore.selectedData.owner,
        title: createNewActionDataTitle.value,
        description: createNewActionDescription.value,
        assigned_to: createNewActionID.value['assignedID'],
        status: createNewActionID.value['statusID'],
        due_date: createNewActionDataDueDate.value
          ? moment(createNewActionDataDueDate.value).format('YYYY/MM/DD')
          : null,
      });
    } else {
      openErrorMsg('Something went wrong. Please refresh and try again.');
      return;
    }
  } else {
    actionsStore.addNewAction({
      title: createNewActionDataTitle.value,
      description: createNewActionDescription.value,
      assigned_to: createNewActionID.value['assignedID'],
      status: createNewActionID.value['statusID'],
      due_date: createNewActionDataDueDate.value ? moment(createNewActionDataDueDate.value).format('YYYY/MM/DD') : null,
    });
  }
}
</script>
<template>
  <el-dialog
    v-model="actionsStore.isAddActionsFormModal"
    :width="650"
    style="height: 500px"
    :title="actionsStore.isMoreDetailsOrNewAction ? 'Edit Actions' : 'Add new Actions'"
    :before-close="onCloseActionsModal"
  >
    <div>
      <h1>Title:</h1>
      <el-input
        v-model="createNewActionDataTitle"
        type="text"
        class="w-full"
        style="height: 30px"
        maxlength="100"
        show-word-limit
      />
    </div>
    <div class="mt-2">
      <h1>Description:</h1>
      <el-input v-model="createNewActionDescription" :rows="2" type="textarea" placeholder="Please input" />
    </div>
    <div class="mt-2">
      <h1>Assigned To:</h1>
      <el-dropdown trigger="click">
        <span class="el-dropdown-link">
          {{ createNewActionDataAssignedTo }}
          <el-icon class="el-icon--right">
            <ArrowDown />
          </el-icon>
        </span>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item v-if="createNewActionID['assignedID']" @click="onAssignAddAction(undefined)">
              Not Assigned
            </el-dropdown-item>
            <el-dropdown-item v-for="i of currentAssignedListModal" :key="i.id" @click="onAssignAddAction(i)">{{
              i.name
            }}</el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
    <div class="mt-2">
      <h1>Status:</h1>
      <el-dropdown trigger="click">
        <span class="el-dropdown-link">
          {{ createNewActionDataStatus }}
          <el-icon class="el-icon--right">
            <ArrowDown />
          </el-icon>
        </span>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item v-if="createNewActionID['statusID']" @click="onStatusAddAction(undefined)">
              No Status
            </el-dropdown-item>
            <el-dropdown-item v-for="i of currentStatusListModal" :key="i.id" @click="onStatusAddAction(i)">{{
              i.name
            }}</el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
    <div class="mt-2">
      <h1>Due Date:</h1>
      <el-date-picker v-model="createNewActionDataDueDate" type="date" placeholder="Pick a day" />
    </div>
    <div class="flex justify-end mt-2">
      <el-button @click="addActions">Save</el-button>
    </div>
  </el-dialog>
</template>

interface msalConfigauth {
    clientId: string;
    authority: string;
    redirectUri: string;
    postLogoutRedirectUri: string;
}

interface msalConfigcache {
  cacheLocation: string;
}

interface msalConfigtype {
  auth: msalConfigauth;
  cache: msalConfigcache;
}

export const msalConfig: msalConfigtype = {
  auth: {
    clientId: import.meta.env.VITE_AZURE_CLIENT_ID,
    authority:
      "https://login.microsoftonline.com/organizations/",
      redirectUri: '/blank.html',
      postLogoutRedirectUri: '/'
  },
  cache: {
    cacheLocation: "localStorage",
  },
};

export const loginRequest = {
    scopes: ['User.Read'],
    prompt: 'select_account',
  };
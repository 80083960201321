<script setup lang="ts">
import { useActuarialStore } from '@/stores/actuarial';
import { usePortfolioStore } from '@/stores/portfolio';
import { onMounted } from 'vue';

const portfolioStore = usePortfolioStore();
const actuarialStore = useActuarialStore();

const ALL_HIERARCHIES = [...portfolioStore.parameters.hierarchies, ...portfolioStore.parameters.actuarial_grouping];

onMounted(() => {
  for (const hierarchy of ALL_HIERARCHIES) {
    actuarialStore.hierarchySelectionAddLinkRatio[hierarchy] = '';
  }
});

const DATA_TYPE = [
  {
    label: 'Incurred Claims Triangles',
    value: 'INCURRED',
  },
  {
    label: 'Paid Claims Triangles',
    value: 'PAID',
  },
  {
    label: 'Number of Claims Triangles',
    value: 'CLAIMS',
  },
];
</script>

<template>
  <div>
    <div>
      <h1 class="mt-3">Select amount type</h1>
      <el-select
        :model-value="actuarialStore.selectedAmountType.join(', ')"
        class="text-center shadow-sm w-full"
        @change="(e) => (actuarialStore.selectedAmountType = e)"
      >
        <el-option
          v-for="(item, index) in actuarialStore.actuarial_claims_nature"
          :key="index"
          data-testid="claims-nature-selection-import"
          :label="item.join(', ')"
          :value="item"
        />
      </el-select>
    </div>
    <div>
      <h1 class="mt-3">Select data type</h1>
      <el-select v-model="actuarialStore.selectedDataType" placeholder="Select" class="text-center shadow-sm w-full">
        <el-option
          v-for="(item, index) in DATA_TYPE"
          :key="index"
          data-testid="data-type-selection-import"
          :label="item.label"
          :value="item.value"
        />
      </el-select>
    </div>
    <div>
      <h1 class="mt-3">Hierarchy</h1>
      <template v-for="(hierarchy, index) in ALL_HIERARCHIES" :key="index">
        <h1>{{ hierarchy }}</h1>
        <el-select
          v-model="actuarialStore.hierarchySelectionAddLinkRatio[hierarchy]"
          placeholder="Select"
          class="text-center shadow-sm w-full"
        >
          <template
            v-if="
              actuarialStore.actuarialHierarchy[hierarchy] && actuarialStore.actuarialHierarchy[hierarchy]['groupNames']
            "
          >
            <el-option
              v-for="(item, hierarchyGroupindex) in actuarialStore.actuarialHierarchy[hierarchy]['groupNames']"
              :key="hierarchyGroupindex"
              :data-testid="hierarchy + '-import-selection'"
              :label="item"
              :value="item"
            />
          </template>
        </el-select>
      </template>
    </div>
  </div>
</template>
<style scoped>
/* Your style code here */
</style>

<script setup lang="ts">
import { usePortfolioStore } from '@/stores/portfolio';
import { useDashboardStore } from '@/stores/dashboard';
import { Filter, Close } from '@element-plus/icons-vue';
import { ref, onMounted, onBeforeMount, computed, watch, nextTick } from 'vue';
import { ElTree } from 'element-plus';
import InputText from 'primevue/inputtext';
import type Tree from 'primevue/tree';
import { useAuthenticationStore } from '@/stores/auth';
import moment from 'moment';

const emit = defineEmits(['rightClickFilters', 'resizeFilter']);

const props = defineProps(['filtersWidth']);
const filterText = ref('');
const treeRef = ref<InstanceType<typeof ElTree>>();
const treeRefDemo = ref<InstanceType<typeof ElTree>>();

const filterNode = (value: string, data: Tree) => {
  if (!value) return true;
  return data.label.toLowerCase().includes(value.toLowerCase());
};

function resizeFilter() {
  emit('resizeFilter');
}

const chunkGroups = computed(() => dashboard_store.chunks_grouping);
const chunkGroupsBasis = computed(() => dashboard_store.chunks_grouping_basis);

onBeforeMount(async () => {
  if (!chunkGroups.value) {
    await dashboard_store.get_chunk();
  }
});

const dateFiltersUW: any = computed(() => dashboard_store.dateFilters['underwriting']);

const filterKeys = ref([]);
const uw_dateFilterKeys = ref([]);
const acc_dateFilterKeys = ref([]);
// const selectedAccidentDate = dateFiltersUW.value["month"]
// const selectedUnderwritingDate = dateFiltersUW.value["month"]
const portfolio_store = usePortfolioStore();
const authenticate_store = useAuthenticationStore();
const dashboard_store = useDashboardStore();

const treeSelectionKeys = computed(() => dashboard_store.treeSelectionKeys);
const treeSelectionKeysChange = ref({});

onMounted(() => {
  if (treeSelectionKeys.value) {
    treeRef.value!.setCheckedKeys(treeSelectionKeys.value, false);
  }

  let temp_isHide: any = { Hierarchy: {}, Underwriting: false, Accident: false };
  for (const i of Object.keys(dashboard_store.filters)) {
    temp_isHide['Hierarchy'][i] = false;
  }
  isHide.value = temp_isHide;

  if (!dashboard_store.dashboard_data) {
    dashboard_store.resetFilters();
  }
});

watch(filterText, (val) => {
  expandedKeys.value = allKeys.value;
  treeRef.value!.filter(val);
});

const getCheckedKeys = () => {
  return treeRef.value!.getCheckedKeys(false);
};

const tracker = ref(dashboard_store.filtersTracker);
const setCheckedKeys = (id: number, pid: number, event) => {
  let selected = getCheckedKeys();
  if (event.ctrlKey || event.metaKey) {
    selected.push(pid);
    treeRef.value!.setCheckedKeys(selected, false);
    selected = getCheckedKeys();
    selected = selected.filter((x) => x !== id && x != pid);
    treeRef.value!.setCheckedKeys(selected, false);

    changeSelection();
  } else {
    if (selected.indexOf(id) === -1) {
      selected.push(id);
    } else {
      selected = selected.filter((x: number) => x != id);
    }
    treeRef.value!.setCheckedKeys(selected, false);
    changeSelection();
  }
};

const renderContentDemo = (
  h,
  {
    node,
    data,
  }: {
    node: Node;
    data: Tree;
    store: Node['store'];
  }
) => {
  if (!data.isTitle) {
    return h(
      'div',
      {},

      h(
        'span',
        {
          class: 'custom-tree-node',
        },
        null,
        node.label
      )
    );
  } else {
    return h('div', {}, [
      h(
        'span',
        {
          class: 'custom-tree-node',
        },
        null,
        node.label
      ),
      h(
        'span',
        {
          class: 'ml-2 absolute right-0 mr-5 tracker mt-1',
        },
        null,
        tracker.value[node.label] || ''
      ),
    ]);
  }
};

const renderContent = (
  h,
  {
    node,
    data,
  }: {
    node: Node;
    data: Tree;
    store: Node['store'];
  }
) => {
  if (!data.isTitle) {
    return h(
      'div',
      {
        onClick: (event) => setCheckedKeys(node.data.id, node.data.parentId, event),
        onDblclick: () => setCheckedKeys(node.data.id, node.data.parentId),
      },

      h(
        'span',
        {
          class: 'custom-tree-node',
        },
        null,
        node.label
      )
    );
  } else {
    return h(
      'div',
      {
        onClick: (event) => setCheckedKeys(node.data.id, node.data.parentId, event),
        onContextmenu: (event) => {
          event.preventDefault(); // Prevent the default browser context menu
          collapseAll();
          // Add additional right-click functionality here
        },
      },
      [
        h(
          'span',
          {
            class: 'custom-tree-node',
          },
          null,
          node.label
        ),
        h(
          'span',
          {
            class: 'ml-2 absolute right-0 mr-5 tracker mt-1',
          },
          null,
          tracker.value[node.label] || ''
        ),
      ]
    );
  }
};

const report_date = computed(() => dashboard_store.report_date);
const parsedDate = moment(report_date.value, 'YYYY-MM-DD').format('MMM-YYYY');
const date = new Date(report_date.value);

const selectedData: any = computed(() => dashboard_store.selectedData);

const dataFilters: any = computed(() => dashboard_store.dataFilters);
const unDataFilters: any = computed(() => dashboard_store.unDataFilters);
const unDateFilters: any = computed(() => dashboard_store.unDateFilters);

const allKeys = ref([]);
const all_uw_dates = computed(() => portfolio_store.all_uw_dates);
const dictionary = computed(() => portfolio_store.dictionary);
const treeData = computed(() => buildFiltersTree());
const treeDataDemo = computed(() => getDemoSelectionTreeData());

const expandedKeys = computed(() => dashboard_store.filtersExpandedKeys);

const handleExpand = (node) => {
  if (!expandedKeys.value.includes(node.id)) {
    expandedKeys.value.push(node.id);
  }
};
const handleCollapse = (node) => {
  const index = expandedKeys.value.indexOf(node.id);
  if (index > -1) {
    expandedKeys.value.splice(index, 1);
  }
};
const collapseAll = () => {
  expandedKeys.value = [];
};

function getQuarter(month) {
  const quarterMap = {
    Jan: 'Q1',
    Feb: 'Q1',
    Mar: 'Q1',
    Apr: 'Q2',
    May: 'Q2',
    Jun: 'Q2',
    Jul: 'Q3',
    Aug: 'Q3',
    Sep: 'Q3',
    Oct: 'Q4',
    Nov: 'Q4',
    Dec: 'Q4',
  };
  return quarterMap[month.substring(0, 3)];
}

function findChildrenLabelsById(tree, nodeId) {
  const queue = [...tree]; // Start with a shallow copy of the tree

  while (queue.length) {
    const node = queue.shift(); // Take the first node from the queue

    if (node.id === nodeId) {
      return node.children ? node.children.map((child) => child.label) : [];
    }

    // If the node has children, add them to the queue to be searched next
    if (node.children) {
      queue.push(...node.children);
    }
  }

  // Return an empty array if no matching node was found
  return [];
}

function changeSelectionDemo() {
  const selectedKeys = treeRefDemo.value?.getCheckedKeys();
  // const selectedLabels = findChildrenLabelsById(treeDataDemo.value, selectedKeys[0]);

  dashboard_store.visibleColumns = selectedKeys;
}

function changeSelection() {
  treeSelectionKeysChange.value = getCheckedKeys();
  if (treeSelectionKeys.value !== treeSelectionKeysChange.value) {
    dashboard_store.updateTreeSelectionKeys(treeSelectionKeysChange.value);
    let selection = {};
    for (let i in dataFilters.value) {
      // let itemselections = treeData.value.filter(x=> x.label === i)[0].children.filter(x=> treeSelectionKeys.value.includes(x.id) ).map(x=> x.label)
      let temp = filterKeys.value
        .filter((y) => y.name === i)[0]
        .items.filter((x) => treeSelectionKeys.value.includes(x.id));
      let itemselections = temp.map((x) => x.name);
      let itemselectionskeys = temp.map((x) => x.id);

      if (itemselectionskeys.length === Object.values(dataFilters.value[i]).length) {
        let removeS = itemselectionskeys;
        removeS.push(filterKeys.value.filter((y) => y.name === i)[0].id);

        let removed = treeSelectionKeysChange.value.filter((x) => removeS.indexOf(x) === -1);

        treeRef.value!.setCheckedKeys([removed], false);
        dashboard_store.updateTreeSelectionKeys(removed);
        itemselections = [];
      }
      if (itemselections.length) {
        selection[i] = itemselections;
      } else {
        selection[i] = Object.values(dataFilters.value[i]);
      }
      tracker.value[i] = itemselections.length;

      for (let c_group of Object.keys(chunkGroupsBasis.value).filter((x) => chunkGroupsBasis.value[x] == i)) {
        let c_temp = filterKeys.value
          .filter((y) => y.name === c_group)[0]
          .items.filter((x) => treeSelectionKeys.value.includes(x.id));
        let c_itemselections = c_temp.map((x) => x.name);
        let c_itemselectionskeys = c_temp.map((x) => x.id);

        if (c_itemselectionskeys.length === Object.keys(chunkGroups.value[c_group]).length) {
          let removeS = c_itemselectionskeys;
          removeS.push(filterKeys.value.filter((y) => y.name === c_group)[0].id);

          let removed = treeSelectionKeysChange.value.filter((x) => removeS.indexOf(x) === -1);

          treeRef.value!.setCheckedKeys([removed], false);
          dashboard_store.updateTreeSelectionKeys(removed);
          c_itemselections = [];
        }
        if (c_itemselections.length) {
          let intersect_Array = c_itemselections
            .map((x) => chunkGroups.value[c_group][x][i])
            .flat()
            .map((x) => dictionary.value[i][x]);
          selection[i] = selection[i].filter((value) => intersect_Array.includes(value));
        }

        tracker.value[c_group] = c_itemselections.length;
      }
    }

    let uwKeys = uw_dateFilterKeys.value.filter((x) => treeSelectionKeysChange.value.includes(x.id));
    let accKeys = acc_dateFilterKeys.value.filter((x) => treeSelectionKeysChange.value.includes(x.id));
    tracker.value['Underwriting Period'] = 0;
    tracker.value['Accident Period'] = 0;

    let uw_selection = all_uw_dates.value['month'];
    let acc_selection = all_uw_dates.value['month'];
    if (uwKeys.length === uw_dateFilterKeys.value.length) {
      let removeS = uwKeys.map((x) => x.id);
      let removed = treeSelectionKeysChange.value.filter((x) => removeS.indexOf(x) === -1);
      treeRef.value!.setCheckedKeys([removed], false);
      dashboard_store.updateTreeSelectionKeys(removed);
    } else {
      let months = uwKeys.filter((x) => x.name == 'month').map((x) => x.val);
      tracker.value['Underwriting Period'] = [...new Set(months)].length;
      uw_selection = all_uw_dates.value['month'].map((x) => (months.includes(x) ? x : 0));
    }
    if (accKeys.length === acc_dateFilterKeys.value.length) {
      let removeS = accKeys.map((x) => x.id);
      let removed = treeSelectionKeysChange.value.filter((x) => removeS.indexOf(x) === -1);
      treeRef.value!.setCheckedKeys([removed], false);
      dashboard_store.updateTreeSelectionKeys(removed);
    } else {
      let months = accKeys.map((x) => x.val);
      tracker.value['Accident Period'] = [...new Set(months)].length;
      acc_selection = all_uw_dates.value['month'].map((x) => (months.includes(x) ? x : 0));
    }
    dashboard_store.setTreeFilters(selection, uw_selection, acc_selection);
    treeRef.value!.setCheckedKeys(treeSelectionKeys.value, false);
  }
  filterText.value = '';
  dashboard_store.setFiltersTracker(tracker.value);
}

const customNodeClass = (data: Tree) => {
  if (data.isTitle) {
    if (data.isAdditionalTitle) {
      return 'is-additional-title';
    } else {
      return 'is-title';
    }
  }
  return 'is-item ' + (data.grayed ? 'text-gray-400' : '');
};

function buildFiltersTree() {
  let data: Tree[] = [];
  let counter = 1;
  for (let i of Object.keys(unDataFilters.value)) {
    let chld = [];
    let parentId = counter++;
    let filterItem = [];
    for (let j of dataFilters.value[i]) {
      allKeys.value.push(counter);
      filterItem.push({ id: counter, name: j });
      chld.push({
        id: counter++,
        parentId: parentId,
        label: j,
        tooltipContent: j,
        isTitle: false,
        grayed: unDataFilters.value[i].map((x) => dictionary.value[i][x]).indexOf(j) === -1,
      });
    }
    filterKeys.value.push({ id: parentId, name: i, items: filterItem });
    allKeys.value.push(parentId);
    data.push({
      id: parentId,
      label: i,
      children: chld,
      isTitle: true,
    });
  }

  let uwId = counter++;
  let accId = counter++;
  let periodsEnd = all_uw_dates.value['month'].indexOf(parsedDate);
  let dates = getMonthsDates(all_uw_dates.value['month'].slice(0, periodsEnd + 1), counter, uwId, accId);
  counter = dates[2];
  uw_dateFilterKeys.value.push({ id: uwId, name: 'main_uw', val: '' });
  allKeys.value.push(uwId);
  data.push({
    id: uwId,
    label: 'Underwriting Period',
    children: dates[0],
    isTitle: true,
  });
  acc_dateFilterKeys.value.push({ id: accId, name: 'main_acc', val: '' });
  allKeys.value.push(accId);
  if (dashboard_store.dashboards.uw_acc == 'acc') {
    data.push({
      id: accId,
      label: 'Accident Period',
      children: dates[1],
      isTitle: true,
    });
  }

  for (let i of Object.keys(chunkGroups.value)) {
    let chld = [];
    let parentId = counter++;
    let filterItem = [];

    for (let j of Object.keys(chunkGroups.value[i])) {
      allKeys.value.push(counter);
      filterItem.push({ id: counter, name: j });
      chld.push({
        id: counter++,
        parentId: parentId,
        label: j,
        tooltipContent: j,
        isTitle: false,
        grayed:
          unDataFilters.value[chunkGroupsBasis.value[i]].filter((x) =>
            chunkGroups.value[i][j][chunkGroupsBasis.value[i]].includes(x.toString())
          ).length == 0
            ? true
            : false,
      });
    }
    filterKeys.value.push({ id: parentId, name: i, items: filterItem });
    allKeys.value.push(parentId);
    data.push({
      id: parentId,
      label: i,
      children: chld,
      isTitle: true,
      isAdditionalTitle: true,
      checked: true,
    });
  }
  return data;
}

function getDemoSelectionTreeData() {
  if (authenticate_store.user && authenticate_store.user.email == 'david.sweet@sybil.cloud') {
    const columns = dashboard_store.DASHBOARD_COLUMNS_SELECTION;

    let counter = 0;
    let child = [];
    let data = [];
    const parentId = counter++;
    for (const column of columns) {
      child.push({
        id: counter++,
        parentId: parentId,
        label: column,
        tooltipContent: column,
        isTitle: false,
      });
    }
    data.push({
      id: parentId,
      label: 'Column Selection',
      children: child,
      isTitle: true,
      isAdditionalTitle: true,
    });

    return data;
  } else {
    return null;
  }
}

function getMonthsDates(months: any, counter: number, uwId: number, accId: number) {
  let uw_data = [];
  let acc_data = [];
  let currentYear = null;
  let currentQuarter = null;
  let uw_yearNode = null;
  let acc_yearNode = null;
  let uw_quarterNode = null;
  let acc_quarterNode = null;
  let idCounter = counter;

  let uw_yearId = null;
  let acc_yearId = null;

  let uw_quarterId = null;
  let acc_quarterId = null;

  months.forEach((month) => {
    const year = month.substring(4, 8);
    const quarter = getQuarter(month);

    if (year !== currentYear) {
      currentYear = year;
      uw_yearId = idCounter++;
      acc_yearId = idCounter++;
      uw_dateFilterKeys.value.push({ id: uw_yearId, name: 'year', val: `${year}` });
      acc_dateFilterKeys.value.push({ id: acc_yearId, name: 'year', val: `${year}` });
      allKeys.value.push(uw_yearId);
      allKeys.value.push(acc_yearId);
      uw_yearNode = { id: uw_yearId, parentId: uwId, label: `${year}`, children: [] };
      acc_yearNode = { id: acc_yearId, parentId: accId, label: `${year}`, children: [] };
      uw_data.push(uw_yearNode);
      acc_data.push(acc_yearNode);
    }

    if (quarter !== currentQuarter || uw_yearNode.children.length === 0) {
      currentQuarter = quarter;
      uw_quarterId = idCounter++;
      acc_quarterId = idCounter++;
      allKeys.value.push(uw_quarterId);
      allKeys.value.push(acc_quarterId);
      uw_dateFilterKeys.value.push({ id: uw_quarterId, name: 'quarter', val: `${quarter}-${year}` });
      acc_dateFilterKeys.value.push({ id: acc_quarterId, name: 'quarter', val: `${quarter}-${year}` });

      uw_quarterNode = { id: uw_quarterId, parentId: uw_yearId, label: `${quarter}-${year}`, children: [] };
      acc_quarterNode = { id: acc_quarterId, parentId: acc_yearId, label: `${quarter}-${year}`, children: [] };
      uw_yearNode.children.push(uw_quarterNode);
      acc_yearNode.children.push(acc_quarterNode);
    }
    let uwMonthId = idCounter++;
    let accMonthId = idCounter++;
    allKeys.value.push(uwMonthId);
    allKeys.value.push(accMonthId);
    uw_dateFilterKeys.value.push({ id: uwMonthId, name: 'month', val: `${month}` });
    acc_dateFilterKeys.value.push({ id: accMonthId, name: 'month', val: `${month}` });
    uw_quarterNode.children.push({
      id: uwMonthId,
      parentId: uw_quarterId,
      label: `${month}`,
      isTitle: false,
      grayed: unDateFilters.value['uw'].indexOf(month) === -1,
    });
    acc_quarterNode.children.push({
      id: accMonthId++,
      parentId: acc_quarterId,
      label: `${month}`,
      isTitle: false,
      grayed: unDateFilters.value['acc'].indexOf(month) === -1,
    });
  });

  return [uw_data, acc_data, idCounter];
}

const isHide: any = ref({ Hierarchy: {}, Underwriting: true, Accident: true });
</script>

<template>
  <div class="sidenav main-sidebars shadow" data-testid="dashboard-sidebar">
    <!-- <h1>{{sideBarData}}</h1> -->
    <div class="flex flex-row place-items-center sticky top-0 bg-white py-2 z-20">
      <div v-if="props.filtersWidth === '300px'" class="mr-10 ml-2">
        <span class="p-input-icon-left p-input-icon-right">
          <i class="pi pi-search"></i>
          <InputText v-model="filterText" type="text" class="p-inputtext-sm h-8 w-56" placeholder="search" />
          <i class="pi pi-filter-slash cursor-pointer" @click="filterText = ''"></i>
        </span>
      </div>
      <i
        class="pi pi-arrows-h ml-2 cursor-pointer absolute top-4 right-2"
        style="font-size: 20px"
        @click="resizeFilter"
      ></i>
    </div>
    <template v-if="props.filtersWidth === '300px' && selectedData && all_uw_dates && unDataFilters && unDateFilters">
      <el-tree
        v-if="treeData"
        ref="treeRef"
        :data="treeData"
        show-checkbox
        :render-content="renderContent"
        node-key="id"
        :filter-node-method="filterNode"
        class="filter-tree"
        :default-expanded-keys="expandedKeys"
        :expand-on-click-node="false"
        :props="{ class: customNodeClass }"
        @check="changeSelection()"
        @node-expand="handleExpand"
        @node-collapse="handleCollapse"
      />
      <el-tree
        v-if="treeDataDemo"
        ref="treeRefDemo"
        :data="treeDataDemo"
        :render-content="renderContentDemo"
        :default-checked-keys="dashboard_store.DEFAULT_DASHBOARD_COLULMNS_SELECTION"
        :props="{ class: customNodeClass }"
        @check="changeSelectionDemo()"
        show-checkbox
        node-key="id"
        class="filter-tree"
        :expand-on-click-node="false"
      />
      <div class="card flex justify-content-center"></div>
    </template>
  </div>
</template>
<style>
.sidenav {
  height: 100%;
  position: fixed;
  right: 0;
  border-left: 1px solid #eeeeee;
  background-color: white;
  overflow-x: hidden;
  overflow-y: scroll;

  padding-bottom: 6rem;
}

.sidenav #filter {
  text-align: center;
  padding: 2px 0px 2px 16px;
  font-size: 18px;
  display: block;
  text-align: center;
}

.sidenav #filter-choice:hover {
  padding: 0px 0px 0px 22px;
  font-size: 13px;
  display: block;
  background-color: #e2e8f0;
}

.sidenav #filter-choice {
  padding: 0px 0px 0px 22px;
  font-size: 11px;
  display: block;
}

.is-title > .el-tree-node__content {
  --tw-text-opacity: 1;
  color: rgb(48 53 60 / var(--tw-bg-opacity));

  height: 30px;

  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));

  padding-top: 5px;
  padding-bottom: 5px;
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;

  position: sticky;
  top: 3rem;
  font-size: 18px;
  z-index: 10;
}

.is-additional-title > .el-tree-node__content {
  --tw-text-opacity: 1;
  /* color: rgb(85 182 145 / var(--tw-text-opacity)); */
  color: rgb(48 53 60 / var(--tw-bg-opacity));

  height: 30px;

  font-style: oblique;

  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));

  padding-top: 5px;
  padding-bottom: 5px;
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;

  position: sticky;
  top: 3rem;
  font-size: 18px;
  z-index: 10;
}

.is-item {
  font-size: 12px;
  display: block;
  overflow: hidden;
  white-space: normal;
}

.is-item:hover {
  font-size: 14px;
}
@keyframes scroll-right {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%); /* Adjust to match content width */
  }
}

.tracker {
  font-size: 11px;
}
</style>

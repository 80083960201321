import { createRouter, createWebHistory } from 'vue-router';
import { useAuthenticationStore } from '@/stores/auth';
import LoginView from '@/views/LoginView.vue';
// import DashboardView from '@/views/Dashboard/DashboardView.vue'
import HomeView from '@/views/Home/HomeView.vue';
import TableView from '@/views/Underwriting/TableView/TableView.vue';
import ChartView from '@/views/Underwriting/ChartView/ChartView.vue';
import UnderwritingView from '@/views/Underwriting/UnderwritingView.vue';
import PerformanceView from '@/views/Dashboard/ClaimsDashboard/PerformanceView.vue';
import DashboardView from '@/views/Dashboard/DashboardView.vue';
import PortfolioSelection from '@/views/Home/PortfolioSelection/PortfolioSelection.vue';
import OTPVerificationPage from '@/views/Authentication/OTPVerificationPage.vue';
import OnboardingPage from '@/views/Authentication/OnboardingPage.vue';
import UserProfile from '@/views/Profile/UserProfile.vue';
import ComparisonView from '@/views/Dashboard/ClaimsDashboard/ComparisonView.vue';
import ActuarialView from '@/views/Actuarial/ActuarialView.vue';
import Triangulations from '@/views/Actuarial/Triangulations/Triangulations.vue';
import ResultsSummary from '@/views/Actuarial/ResultsSummary/ResultsSummary.vue';
import FinanceSelection from '@/views/Finance/FinanceSelection/FinanceSelection.vue';
import FinanceSummary from '@/views/Finance/FinanceSummary/FinanceSummary.vue';
import FinanceView from '@/views/Finance/FinanceView.vue';
import ActionsView from '@/views/Actions/ActionsView.vue';
import UnsubscribeNewsletter from '@/views/Profile/UnsubscribeNewsletter.vue';

const routes = [
  {
    path: '/login',
    name: 'login',
    component: LoginView,
  },
  {
    path: '/home',
    name: 'home',
    component: HomeView,
    children: [
      {
        path: '/actions',
        name: 'Actions',
        component: ActionsView,
      },
      {
        path: 'portfolio-selection',
        name: 'Portfolio',
        component: PortfolioSelection,
      },
      {
        path: '',
        name: 'Default',
        redirect: { path: '/home/portfolio-selection' },
      },
    ],
  },
  {
    path: '/finance',
    name: 'Finance',
    component: FinanceView,
    children: [
      {
        path: 'finance-selection',
        name: 'Finance Selection',
        component: FinanceSelection,
      },
      {
        path: 'finance-summary',
        name: 'Finance Summary',
        component: FinanceSummary,
      },
      {
        path: '',
        name: 'Default Finance',
        redirect: { path: '/finance/finance-selection' },
      },
    ],
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: DashboardView,
    children: [
      {
        path: 'performance-view',
        name: 'Performance View',
        component: PerformanceView,
      },
      {
        path: 'comparison-view',
        name: 'Comparison View',
        component: ComparisonView,
      },
      {
        path: '',
        name: 'Default Dashboard',
        redirect: { path: '/dashboard/performance-view' },
      },
    ],
  },
  {
    path: '/actuarial',
    name: 'Actuarial',
    component: ActuarialView,
    children: [
      {
        path: 'triangulations',
        name: 'Triangulations',
        component: Triangulations,
      },
      {
        path: 'results-summary',
        name: 'AvE',
        component: ResultsSummary,
      },
      {
        path: '',
        name: 'Default Triangulations',
        redirect: { path: '/actuarial/triangulations' },
      },
    ],
  },
  {
    path: '/underwriting',
    name: 'Underwriting',
    component: UnderwritingView,
    children: [
      {
        path: 'table-view',
        name: 'Table View',
        component: TableView,
      },
      {
        path: 'chart-view',
        name: 'Chart View',
        component: ChartView,
      },
      {
        path: '',
        name: 'Default Underwriting',
        redirect: { path: '/underwriting/table-view' },
      },
    ],
  },
  {
    path: '/profile',
    name: 'User Profile',
    component: UserProfile,
  },
  {
    path: '/OTPVerificationPage',
    name: 'OTP Verification Page',
    component: OTPVerificationPage,
  },
  {
    path: '/OnboardingPage',
    name: 'Onboarding Page',
    component: OnboardingPage,
  },
  {
    path: '/unsubscribe',
    name: 'Unsubscribe',
    component: UnsubscribeNewsletter,
  },
  {
    path: '/',
    redirect: { path: '/login' },
  },
];

let popstate = false;
let popStateCount = 0;
window.addEventListener('popstate', () => {
  popstate = true;
  popStateCount += 1;
  if (popStateCount == 2) {
    popStateCount = 0;
    popstate = false;
  }
});

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, from) => {
  const authenticationStore = useAuthenticationStore();
  const isAuthenticated = authenticationStore.isAuthenticated;
  const isItABackButton = popstate;

  popstate = false;
  if (isItABackButton) {
    return false;
  }
  popStateCount = 0;

  if (to.name === 'Unsubscribe') {
    return true;
  }

  if (!isAuthenticated && to.name !== 'login') {
    return { name: 'login', query: to.query };
  } else {
    return true;
  }
});

export default router;

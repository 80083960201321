<script setup lang="ts">
import { useActuarialStore } from '@/stores/actuarial';
import { usePortfolioStore } from '@/stores/portfolio';
import { useAvEActuarialStore } from '@/stores/AvEActuarial';
import { ref, onMounted, watch } from 'vue';
import ResultsSummarySidebar from './components/ResultsSummarySidebar.vue';
import ResultsSummaryTable from './components/ResultsSummaryTable.vue';

const actuarial_store = useActuarialStore();
const portfolio_store = usePortfolioStore();
const ave_portfolio_store = useAvEActuarialStore();

const filtersWidth = ref('300px');

function resizeFilter() {
  const numericValue = parseInt(filtersWidth.value, 10); // Extract numeric value from the string
  filtersWidth.value = `${350 - numericValue}px`; // Calculate and append 'px' back
}
</script>
<template>
  <div v-if="!ave_portfolio_store.isActuarialAveEligible && portfolio_store.isAve">
    Combinations are different, ensure the triangles and patterns are on the same basis
  </div>
  <div v-else-if="actuarial_store.is_parameters_fetch && portfolio_store.isAve && ave_portfolio_store.trianglesJSON">
    <div class="absolute bg-white top-20 right-0 z-50" style="margin-top: -15px">
      <div
        :style="{
          width: filtersWidth,
          transition: '0.5s ease-out all',
        }"
      >
        <ResultsSummarySidebar style="width: inherit" @resizeFilter="resizeFilter" :filtersWidth="filtersWidth" />
      </div>
    </div>
    <div class="w-fit">
      <ResultsSummaryTable />
    </div>
  </div>
  <div v-else>Please select AvE</div>
</template>

<script setup lang="ts">
import BounceChart from '@/components/BounceChart.vue';
import { ref, onMounted, type PropType } from 'vue';
import moment from 'moment';
import { MarkerType } from '@vue-flow/core';
import { Folder, Document, Notebook } from '@element-plus/icons-vue';
import VueSimpleContextMenu from 'vue-simple-context-menu';
import EditDraftOptionsModal from '@/components/EditDraftOptionsModal.vue';
import type {
  PortfolioTypeTreeNode,
  VueFlowEdgeType,
  VueFlowNodeType,
  TreeNodeSelectBounceTreeType,
  SideBarData,
  TreeNodeSelectBounceSideBarType,
  SelectedDraftListType,
  PortfolioTypeTreeNodeFlat,
} from '@/types/portfolio';
import type { NumericDictionary } from '@/types/common';

const position = { x: 0, y: 0 };

const emit = defineEmits(['onClickTree', 'onSubmitNewBounceName', 'onDeleteDraft']);
const props = defineProps({
  treeData: {
    type: Object as PropType<PortfolioTypeTreeNode>,
    required: true,
  },
  treeSelected: {
    type: String,
    required: true,
  },
  selectedDraftList: {
    type: Object as PropType<SelectedDraftListType>,
    required: true,
  },
});

const draftsGrouping = ref<PortfolioTypeTreeNodeFlat[]>([]);
const chartKey = ref(0);
const elements = ref<(VueFlowNodeType | VueFlowEdgeType)[]>();
const selectedNode = ref(0);
const elementsId = ref<NumericDictionary>();
const isEditDraftBounce = ref(false);
const editDraftNodeID = ref('');

function handleRightClickDraftContextMenu(event, draftNode) {
  vueSimpleContextMenu1.value.showMenu(event, draftNode);
}

const vueSimpleContextMenu1 = ref();

const editDraftOptions = [
  {
    name: 'Edit draft name',
    id: 'edit_draft_name',
  },
  {
    name: 'Delete Draft',
    id: 'delete_draft',
  },
];

function onEditDraftOptions(e) {
  if (e.option.id == 'edit_draft_name') {
    editDraftName(e.item.nodeID);
  }

  if (e.option.id == 'delete_draft') {
    deleteDraft(e.item.nodeID);
  }
}

function deleteDraft(nodeID: string) {
  emit('onDeleteDraft', { selectedNodeID: nodeID });
}

function editDraftName(nodeID: string) {
  console.log(nodeID);
  editDraftNodeID.value = nodeID;
  editDraftModal(true);
}

onMounted(() => {
  dataSidebar();
  bounceChartData();
  console.log(props.selectedDraftList);
});

function modifyDraftName(name) {
  try {
    return name.split('-')[3];
  } catch {
    return 'Undefined';
  }
}

function bounceChartData() {
  let data: (VueFlowNodeType | VueFlowEdgeType)[] = [];
  let linkedId: NumericDictionary = {};

  let stack: PortfolioTypeTreeNode[] = [props.treeData];
  let id = 0;
  while (stack.length > 0) {
    const node: PortfolioTypeTreeNode | undefined = stack.pop();
    if (!node) {
      break;
    }
    data.push({
      id: id,
      data: { nodeID: node.nodeID, name: node.name },
      type: 'custom',
      position,
      label: node.name,
      draggable: false,
    });
    linkedId[node.nodeID] = id;
    id += 1;

    for (const i of node['children']) {
      stack.push(i);
    }
  }

  stack = [props.treeData];

  while (stack.length > 0) {
    const node = stack.pop();
    if (!node) {
      break;
    }

    for (const i of node['children']) {
      data.push({
        id,
        source: linkedId[node.nodeID].toString(),
        target: linkedId[i.nodeID].toString(),
        type: 'step',
        markerEnd: MarkerType.ArrowClosed,
      });
      id += 1;
      stack.push(i);
    }
  }
  elements.value = data;
  elementsId.value = linkedId;
}

const dateMonth = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

interface Node {
  val: PortfolioTypeTreeNode;
  isDrafts: boolean;
  parent_id: string;
}

const groupedData = ref();

function isFormal(node: PortfolioTypeTreeNode) {
  try {
    if (node.name == 'root') {
      return false;
    }

    const splitName = node.name.split('-');
    if (splitName[0].length != 6) {
      if (splitName[0].slice(-1) == 'F') {
        return true;
      }
      return false;
    }

    if (splitName[1].slice(-1) == 'F') {
      return true;
    }

    return false;
  } catch {
    return false;
  }
}

function dataSidebar() {
  let data: SideBarData = {};
  let stack: PortfolioTypeTreeNode[] = [props.treeData];
  while (stack.length > 0) {
    const node: PortfolioTypeTreeNode | undefined = stack.pop();
    if (!node) {
      break;
    }
    console.log(node);
    if (node.name != 'root') {
      extractSideBarData(data, stack, node);
    }
  }

  for (const year of Object.keys(data).sort()) {
    for (const month of Object.keys(data[year]).sort()) {
      data[year][month].sort((a: TreeNodeSelectBounceSideBarType, b: TreeNodeSelectBounceSideBarType) => {
        if (a.timestamp > b.timestamp) {
          return 1;
        } else {
          return -1;
        }
      });
    }
  }

  setDraftsGrouping();

  groupedData.value = data;
  console.log(groupedData.value);
}

function extractSideBarData(data, stack, node) {
  const splitName = node.name.split('-');
  const stringlength = splitName[0].length + 1 + splitName[1].length + 1;
  if (splitName[0].length == 6) {
    const monthDate = moment(splitName[0], 'YYYYMM').format('MMM');
    const yearDate = moment(splitName[0], 'YYYYMM').format('YYYY');
    const idx = dateMonth.findIndex((e) => e == monthDate);
    if (!data[yearDate]) {
      data[yearDate] = {};
    }

    if (!data[yearDate][idx]) {
      data[yearDate][idx] = [];
    }
    data[yearDate][idx].push({
      name: node.name.slice(stringlength, node.name.length),
      timestamp: parseInt(splitName[1]),
      monthName: splitName[0],
      nodeID: node.nodeID,
      isFormal: isFormal(node),
    });
  }
  for (const i of node['children']) {
    stack.push(i);
  }
}

function setDraftsGrouping() {
  draftsGrouping.value = [];
  for (const nodes of Object.keys(props.selectedDraftList)) {
    for (const node of props.selectedDraftList[nodes]) {
      draftsGrouping.value.push(node);
    }
  }
}

function onClickTree2(node: TreeNodeSelectBounceTreeType) {
  chartKey.value += 1;
  selectedNode.value = elementsId.value ? elementsId.value[node.nodeID] : 0;
  const nodeClone = structuredClone(node);
  console.log('onClickTree2');
  if (nodeClone.monthName && nodeClone.timestamp) {
    nodeClone.name = nodeClone.monthName + '-' + nodeClone.timestamp + '-' + nodeClone.name;
  }

  emit('onClickTree', nodeClone);
}

function treeFormatter(node: PortfolioTypeTreeNode, isDrafts: boolean, parentId: string) {
  try {
    if (node.name == 'root') {
      defaultActive.value = null;
    }
    const splitName = node.name.split('-');

    if (splitName[0].length != 6) {
      return 'Unspecified';
    }

    const year = moment(splitName[0], 'YYYYMM').format('YYYY');
    const month = moment(splitName[0], 'YYYYMM').format('MMM');

    const yearIdx = Object.keys(groupedData.value)
      .sort()
      .findIndex((e) => e == year);
    const monthInInt: number = dateMonth.findIndex((e) => e == month);

    const monthIdx = Object.keys(groupedData.value[year])
      .sort()
      .findIndex((e) => parseInt(e) == monthInInt);

    const index = groupedData.value[year][monthInInt].findIndex((e: PortfolioTypeTreeNode) => e.nodeID == node.nodeID);
    console.log(isDrafts);
    if (!isDrafts) {
      defaultActive.value = yearIdx.toString() + '.' + monthIdx.toString() + '.' + index.toString() + '.ACTUAL';
    } else {
      console.log(parentId);
      const idx = props.selectedDraftList[parentId].findIndex((e) => {
        return e.nodeID == node.nodeID;
      });
      console.log(idx);
      const index = groupedData.value[year][monthInInt].findIndex((e: PortfolioTypeTreeNode) => e.nodeID == parentId);
      defaultActive.value =
        yearIdx.toString() + '.' + monthIdx.toString() + '.' + index.toString() + '.DRAFTS.' + idx.toString();
      console.log(defaultActive.value);
    }
  } catch {
    defaultActive.value = null;
  }
}

function onClickTree(node: Node) {
  treeFormatter(node.val, node.isDrafts, node.parent_id);
  console.log(node);

  menuID.value += 1;
  emit('onClickTree', node.val);
}

const menuID = ref(0);
const defaultActive = ref();

function onSelectOnMenu(idx: string) {
  const arr = idx.split('.');
  let selectedData: TreeNodeSelectBounceSideBarType | null = null;

  const year = Object.keys(groupedData.value).sort()[parseInt(arr[0])];

  const month = Object.keys(groupedData.value[year]).sort()[parseInt(arr[1])];

  selectedData = groupedData.value[year][month][parseInt(arr[2])];

  if (arr[3] == 'DRAFTS') {
    if (selectedData) {
      selectedData = props.selectedDraftList[selectedData.nodeID][arr[4]];
    }
  }

  if (selectedData) {
    onClickTree2(selectedData);
  }
}

function editDraftModal(e: boolean) {
  isEditDraftBounce.value = e;
}

function onSubmitNewBounceName(event) {
  event['selectedNodeID'] = editDraftNodeID.value;
  emit('onSubmitNewBounceName', event);
}
</script>
<template>
  <vue-simple-context-menu
    ref="vueSimpleContextMenu1"
    element-id="mySecondMenu"
    :options="editDraftOptions"
    @option-clicked="onEditDraftOptions"
  >
  </vue-simple-context-menu>
  <EditDraftOptionsModal
    :is-edit-draft-bounce="isEditDraftBounce"
    @on-close-edit-draft-modal="editDraftModal(false)"
    @on-submit-new-bounce-name="onSubmitNewBounceName"
  />
  <div class="flex flex-row w-screen h-full">
    <div
      class="overflow-y-auto border-2 border-r-0 shadow-md border-gray-500 bg-gray-100 h-[calc(100vh-200px)]"
      style="width: 350px"
    >
      <el-menu
        v-if="groupedData"
        :key="menuID"
        :default-active="defaultActive || null"
        class="overflow-auto h-full w-full"
        @select="onSelectOnMenu"
      >
        <el-sub-menu
          v-for="(year, idx) in Object.keys(groupedData).sort()"
          :key="idx"
          data-testid="sidebar-year-category"
          :index="idx.toString()"
        >
          <template #title>
            <el-icon>
              <Folder />
            </el-icon>
            <span>{{ year }}</span>
          </template>
          <el-sub-menu
            v-for="(month, monthIdx) in Object.keys(groupedData[year]).sort()"
            :key="idx.toString() + '.' + monthIdx.toString()"
            :index="idx.toString() + '.' + monthIdx.toString()"
            data-testid="sidebar-bounce-month"
          >
            <template #title>
              <el-icon>
                <Folder />
              </el-icon>
              <span>{{ dateMonth[parseInt(month)] + '-' + year }}</span>
            </template>
            <div v-for="(data, dataidx) in groupedData[year][month]" :key="dataidx" data-testid="sidebar-bounce-item">
              <el-sub-menu :index="idx.toString() + '.' + monthIdx.toString() + '.' + dataidx.toString() + '.DRAFTS'">
                <template #title>
                  <el-icon>
                    <Document />
                  </el-icon>
                  <span>{{ data.name }}</span>
                </template>
                <el-menu-item
                  :key="dataidx"
                  data-testid="sidebar-bounce-item-expanded"
                  :index="idx.toString() + '.' + monthIdx.toString() + '.' + dataidx.toString() + '.' + 'ACTUAL'"
                >
                  <template #title>
                    <el-icon>
                      <Document />
                    </el-icon>
                    <div class="w-full">
                      <el-tooltip
                        class="box-item"
                        :show-after="1000"
                        effect="dark"
                        :content="'Timestamp: ' + data.timestamp"
                      >
                        <p class="break-all">{{ data.name }} {{ data.isFormal ? '(F)' : '' }}</p>
                      </el-tooltip>
                    </div>
                  </template>
                </el-menu-item>
                <el-menu-item
                  v-for="(draft_node, draft_node_idx) in props.selectedDraftList[data.nodeID]"
                  :key="draft_node_idx"
                  data-testid="sidebar-bounce-item-expanded"
                  :index="
                    idx.toString() +
                    '.' +
                    monthIdx.toString() +
                    '.' +
                    dataidx.toString() +
                    '.DRAFTS.' +
                    draft_node_idx.toString()
                  "
                  @contextmenu.prevent.stop="handleRightClickDraftContextMenu($event, draft_node)"
                >
                  <template #title>
                    <el-icon>
                      <Notebook class="text-gray-300" />
                    </el-icon>
                    <div class="w-full">
                      <p class="break-all">
                        {{ modifyDraftName(draft_node.name) }}
                      </p>
                    </div>
                  </template>
                </el-menu-item>
              </el-sub-menu>
            </div>
          </el-sub-menu>
        </el-sub-menu>
      </el-menu>
    </div>
    <BounceChart
      v-if="elements"
      class="border-2 shadow-md border-gray-500 h-[calc(100vh-200px)] w-[calc(100vw-400px)]"
      :data="elements"
      :selected-node="selectedNode"
      :chart-key="chartKey"
      :tree-selected="treeSelected"
      :selected-draft-list="props.selectedDraftList"
      @on-click-tree="onClickTree"
    />
  </div>
</template>

<script setup lang="ts">
import { useGlobalStore } from "@/stores/global"
import { computed, ref } from "vue"
import InputText from "primevue/inputtext";

const global_store = useGlobalStore()

const inputVal = computed(() => global_store.isCurrencySelectionModal)

function handleClose() {
  global_store.setCurrencyModal(false)
}

const tempCurrency = ref(global_store.currency.currencyFormat.currency)
const tempSelectedGWPUnit = ref(global_store.currency.currencyFormat.selectedGWPUnit)
const tempprecision = ref(global_store.currency.currencyFormat.precision)
const GWPUnits: any = computed(() => global_store.currency.GWPUnits)

function onChangeCurrencyFormat() {
  global_store.onChangeCurrencyFormat(tempCurrency.value, tempSelectedGWPUnit.value, tempprecision.value)
  global_store.setCurrencyModal(false)
}

</script>

<template>
  <el-dialog v-model="inputVal" title="" :before-close="handleClose">
    <template #header> Currency format </template>
    <div class="flex p-12 w-full h-full">
      <div class="w-1/2 h-full">
        <div class="font-bold">Type currency:</div>
        <div>
          <InputText
            type="text"
            v-model="tempCurrency"
            class="p-inputtext-sm"
            placeholder="currency"
          />
        </div>
        <div class="mt-5 font-bold">Select unit:</div>
        <div class="">
          <el-radio-group v-model="tempSelectedGWPUnit">
            <el-radio label="Ones">Units</el-radio>
            <el-radio label="Thousands">Thousands</el-radio>
            <el-radio label="Millions">Millions</el-radio>
          </el-radio-group>
        </div>
        <div class="mt-5 font-bold">Precision:</div>
        <div class="w-full">
          <el-input-number
            v-model="tempprecision"
            :min="0"
            :max="2"
            size="small"
            controls-position="right"
            class="w-full"
          />
        </div>
      </div>

      <div
        class="w-full h-grow bg-sybil-teal bg-opacity-5 text-center ml-12 text-sybil-teal"
      >
        <div class="text-4xl inline-block align-middle mt-16">
          {{ tempCurrency + GWPUnits[tempSelectedGWPUnit][1] }}
        </div>
      </div>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose()">Close</el-button>
        <el-button type="primary" @click="onChangeCurrencyFormat"
          >Confirm
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>

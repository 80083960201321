<script setup lang="ts">
import { useDashboardStore } from '@/stores/dashboard';
import { usePortfolioStore } from '@/stores/portfolio';
import { ref, computed } from 'vue';
import { ElMessage } from 'element-plus';
import moment from 'moment';
import type { BinderYears } from '@/types/dashboard';

const dashboardStore = useDashboardStore();
const portfolioStore = usePortfolioStore();

function openErrorMsg(msg: string) {
  ElMessage({
    message: msg,
    type: 'error',
  });
}

const monthConstants: string[] = moment.monthsShort();

const currentBindedYearsSelection = ref<BinderYears>([
  { type: 'period', group: '', range: [monthConstants[0], monthConstants[11]] },
]);

function onCloseConfigBindedYearsModal() {
  dashboardStore.onSetConfigBindedYearsModal(false);
  currentBindedYearsSelection.value = [{ type: 'period', group: '', range: [monthConstants[0], monthConstants[11]] }];
}

function removeExceptionalPeriod() {
  // Remove first index
  if (currentBindedYearsSelection.value.length > 1) {
    currentBindedYearsSelection.value = currentBindedYearsSelection.value.slice(1);
  }
}

function addExceptionalPeriod() {
  currentBindedYearsSelection.value = [
    {
      type: 'exceptional_period',
      group: '',
      range: [moment(portfolioStore.all_uw_dates['month'][0], 'MMM-YYYY').toDate(), null],
    },
    ...currentBindedYearsSelection.value,
  ];
  // }
}

const disabledDate = (time: Date) => {
  return !(
    time.getTime() >= moment(portfolioStore.all_uw_dates['month'][0], 'MMM-YYYY').toDate().getTime() &&
    time.getTime() <=
      moment(portfolioStore.all_uw_dates['month'][portfolioStore.all_uw_dates['month'].length - 1], 'MMM-YYYY')
        .add(1, 'months')
        .toDate()
        .getTime()
  );
};

const bindedYears = computed(() => portfolioStore.parameters['binding_years']);

function checkErrorBeforeSubmit() {
  let smallestDate: Date | null = null;

  if (currentBindedYearsSelection.value.length == 1 && bindedYears.value.length > 0) {
    openErrorMsg('Needed exceptional period');
    return;
  }

  for (const selection of currentBindedYearsSelection.value) {
    if (selection.range[0] == null || selection.range[1] == null) {
      openErrorMsg('Please enter a valid date');
      return;
    }

    if (selection.type == 'exceptional_period') {
      if (
        !selection.range[0] ||
        !(selection.range[0] instanceof Date) ||
        !selection.range[1] ||
        !(selection.range[1] instanceof Date)
      ) {
        openErrorMsg('Something went wrong');
        return;
      }
      if (selection.group.trim() == '') {
        openErrorMsg('Please enter a valid group name');
        return;
      }
      if (smallestDate == null) {
        if (selection.range[0].getTime() > selection.range[1].getTime()) {
          openErrorMsg('Incorrect exceptional period');
          return;
        }
        smallestDate = selection.range[1];
      } else {
        if (
          moment(smallestDate.getTime()).toDate().getTime() !=
            moment(selection.range[0].getTime()).subtract(1, 'months').toDate().getTime() ||
          selection.range[0].getTime() >= selection.range[1].getTime()
        ) {
          openErrorMsg('Incorrect exceptional period');
          return;
        } else {
          smallestDate = selection.range[1];
        }
      }
    }
  }

  if (currentBindedYearsSelection.value[0].type == 'exceptional_period') {
    for (let idx = bindedYears.value.length - 1; idx >= 0; idx--) {
      const selection = bindedYears.value[idx];
      if (selection.type == 'exceptional_period') {
        if (
          moment(currentBindedYearsSelection.value[0].range[0]).toDate().getTime() !=
          moment(selection.range[1], 'MMM-YYYY').add(1, 'months').toDate().getTime()
        ) {
          openErrorMsg('Exceptional period must be a month after the pervious exceptional period.');
          return;
        }
        break;
      }
    }
  }

  // Proceed
  portfolioStore.addBindedYears(currentBindedYearsSelection.value);
}

function ensureMonthConstantsNotSame() {
  const idx = monthConstants.findIndex(
    (e) => e == currentBindedYearsSelection.value[currentBindedYearsSelection.value.length - 1].range[0]
  );
  let newIdx = (idx + 11) % 12;
  currentBindedYearsSelection.value[currentBindedYearsSelection.value.length - 1].range[1] = monthConstants[newIdx];
}

function onPickExceptionalPeriod() {
  currentBindedYearsSelection.value[currentBindedYearsSelection.value.length - 1].range[0] = moment(
    currentBindedYearsSelection.value[currentBindedYearsSelection.value.length - 2].range[1]
  )
    .add(1, 'months')
    .format('MMM');
  currentBindedYearsSelection.value[currentBindedYearsSelection.value.length - 1].range[1] = moment(
    currentBindedYearsSelection.value[currentBindedYearsSelection.value.length - 2].range[1]
  )
    .add(12, 'months')
    .format('MMM');
  // ensureMonthConstantsNotSame()
}

function removeBindedYears() {
  portfolioStore.removeBindedYears();
}
</script>

<template>
  <el-dialog
    data-testid="bespoke-years-modal"
    title="Configure Bespoke Years"
    style="width: 1030px"
    :model-value="dashboardStore.isConfigBindedYearsModal"
    :before-close="onCloseConfigBindedYearsModal"
  >
    <div class="grid grid-cols-2 gap-2 divide-x h-full">
      <div class="p-2 overflow-y-scroll">
        <div v-if="bindedYears.length == 0">
          <h1>No Bespoke Years Available.</h1>
        </div>
        <div v-else>
          <div v-for="(i, idx) of bindedYears" :key="idx + '-binded-years'">
            <div v-if="i.type == 'period'" class="border-2 p-3">
              <h1 class="text-center mb-2">Period</h1>
              <div class="grid grid-cols-2 grid-rows-2">
                <h1 class="mt-2">From:</h1>
                <select
                  :disabled="true"
                  :value="bindedYears[idx].range[0]"
                  class="m-2"
                  data-testid="binded-period-from-selected"
                  placeholder="Select"
                  size="small"
                >
                  <option v-for="item in monthConstants" :key="item" :label="item" :value="item" />
                </select>
                <h1 class="mt-2">To:</h1>
                <select
                  :disabled="true"
                  :value="bindedYears[idx].range[1]"
                  class="m-2"
                  data-testid="binded-period-to-selected"
                  placeholder="Select"
                  size="small"
                >
                  <option v-for="item in monthConstants" :key="item" :label="item" :value="item" />
                </select>
              </div>
            </div>
            <div v-else class="border-2 p-3">
              <h1 class="text-center mb-2">Exceptional Period</h1>
              <div class="grid grid-cols-2 grid-rows-3">
                <h1 class="mt-2">Group:</h1>
                <el-input
                  class="mb-2"
                  :disabled="true"
                  size="small"
                  :model-value="bindedYears[idx].group"
                  placeholder="Please insert group"
                />
                <h1 class="mt-2">From:</h1>
                <div data-testid="binded-years-from-selected">
                  <el-date-picker
                    :model-value="bindedYears[idx].range[0]"
                    type="month"
                    placeholder="Pick a month"
                    size="small"
                    style="width: 155px"
                    :disabled="true"
                  />
                </div>
                <h1 class="mt-2">To:</h1>
                <div data-testid="binded-years-to-selected">
                  <el-date-picker
                    :disabled="true"
                    :model-value="bindedYears[idx].range[1]"
                    type="month"
                    placeholder="Pick a month"
                    size="small"
                    style="width: 155px"
                  />
                </div>
              </div>
            </div>
          </div>
          <el-button class="mt-3" data-testid="remove-exceptional-period-selected" @click="removeBindedYears"
            >Remove Exceptional Period</el-button
          >
        </div>
      </div>
      <div class="p-2">
        <div class="grid grid-rows-2 divide-y">
          <div class="overflow-y-scroll">
            <h1>Current Selection:</h1>
            <div v-for="(i, idx) of currentBindedYearsSelection" :key="idx + '-binded-years-selection'">
              <div v-if="i.type == 'period'" class="border-2 p-3">
                <h1 class="text-center mb-2">Period</h1>
                <div class="grid grid-cols-2 grid-rows-2">
                  <h1 class="mt-2">From:</h1>
                  <select
                    v-model="currentBindedYearsSelection[idx].range[0]"
                    :disabled="currentBindedYearsSelection.length > 1"
                    class="m-2"
                    data-testid="binded-period-from-input"
                    placeholder="Select"
                    size="small"
                    @change="ensureMonthConstantsNotSame"
                  >
                    <option v-for="item in monthConstants" :key="item" :label="item" :value="item" />
                  </select>
                  <h1 class="mt-2">To:</h1>
                  <select
                    v-model="currentBindedYearsSelection[idx].range[1]"
                    :disabled="true"
                    class="m-2"
                    data-testid="binded-period-to-input"
                    placeholder="Select"
                    size="small"
                    @change="ensureMonthConstantsNotSame"
                  >
                    <option v-for="item in monthConstants" :key="item" :label="item" :value="item" />
                  </select>
                </div>
              </div>
              <div v-else class="border-2 p-3">
                <h1 class="text-center mb-2">Exceptional Period</h1>
                <div class="grid grid-cols-2 grid-rows-3">
                  <h1 class="mt-2">Group:</h1>
                  <el-input
                    v-model="currentBindedYearsSelection[idx].group"
                    data-testid="binded-years-group-input"
                    class="mb-2"
                    size="small"
                    placeholder="Please insert group"
                  />
                  <h1 class="mt-2">From:</h1>
                  <div data-testid="binded-years-from-input">
                    <el-date-picker
                      v-model="currentBindedYearsSelection[idx].range[0]"
                      type="month"
                      placeholder="Pick a month"
                      size="small"
                      style="width: 155px"
                      :disabled-date="disabledDate"
                    />
                  </div>
                  <h1 class="mt-2">To:</h1>
                  <div data-testid="binded-years-to-input">
                    <el-date-picker
                      v-model="currentBindedYearsSelection[idx].range[1]"
                      type="month"
                      placeholder="Pick a month"
                      size="small"
                      style="width: 155px"
                      :disabled-date="disabledDate"
                      @change="onPickExceptionalPeriod"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div class="flex flex-row mt-3">
              <el-button data-testid="add-exceptional-period-selection" @click="addExceptionalPeriod"
                >Add Exceptional Period</el-button
              >
              <el-button
                :disabled="currentBindedYearsSelection.length == 1"
                data-testid="remove-exceptional-period-selection"
                @click="removeExceptionalPeriod"
                >Remove Exceptional Period</el-button
              >
            </div>
            <el-button
              class="mt-3"
              :disabled="currentBindedYearsSelection.length == 0"
              data-testid="submit-exceptional-period"
              @click="checkErrorBeforeSubmit"
              >Confirm</el-button
            >
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

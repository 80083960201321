<script setup lang="ts">
import { ref, onMounted } from "vue";
import { api } from "@/services/api";
import { usePortfolioStore } from '@/stores/portfolio'
import SelectBounceTree from "@/components/SelectBounceTree.vue";
import { ElMessage } from 'element-plus'
import {
  ArrowRight
} from "@element-plus/icons-vue";
import sybilColor from '@/constants/sybilColor';

const portfolio_store = usePortfolioStore()

function openErrorMsg(msg: string) {
    ElMessage.warning(msg);
}

const tree = ref({})
const expectedDate = ref({})

onMounted(() => {
    retrieveBounceTree()
})

async function retrieveBounceTree() {
    await api
        .get(import.meta.env.VITE_API_URL + "portfolio/list-current-portfolio-bounce", {
            params: {
                bounce_id: portfolio_store.selectedBounceID,
            }
        })
        .then((res) => {
            tree.value = res.data.data.tree
            expectedDate.value = res.data.data.dates
        })
        .catch((err) => {
            if (err.response && err.response.data) {
                openErrorMsg(err.response.data.data)
            } else {
                openErrorMsg('Error! Please try again')
            }
        })
}

const selectedBounceTreeKey = ref(0)
const treeSelected = ref<string>("");
const nodeID = ref()
const nodeName = ref()

function onClickTree(node: any) {
    nodeID.value = node.nodeID
    treeSelected.value = node.nodeID;
    nodeName.value = node.name

    console.log(node)
}

function onCloseIsActualVsExpectedModal() {
    portfolio_store.changeIsActualVsExpectedModal(false)
}

function openExpected() {
    console.log(nodeName.value)
    portfolio_store.onSelectExpectedBounceID(treeSelected.value, expectedDate.value[treeSelected.value], nodeName.value)
}

const selectedDraftList = ref({})

</script>
<template>
    <div>
        <el-dialog :model-value="portfolio_store.isActualVsExpectedModal" :before-close="onCloseIsActualVsExpectedModal"
            :fullscreen="true" title="Actual vs Expected">
            <SelectBounceTree :key="selectedBounceTreeKey" v-if="Object.keys(tree).length > 0" :treeSelected="treeSelected"
                v-on:onClickTree="onClickTree" :treeData="tree" :selectedDraftList="selectedDraftList" />

            <el-button type="info" @click="onCloseIsActualVsExpectedModal" class="absolute bottom-5 right-32">
                Cancel
            </el-button>
            <el-button @click="openExpected" type="primary" :disabled="treeSelected.length == 0" :color="sybilColor"
                class="absolute bottom-5 right-8" style="color: white">
                Open
                <el-icon class="el-icon--right">
                    <ArrowRight v-on:onClickTree="onClickTree" />
                </el-icon>
            </el-button>
        </el-dialog>
    </div>
</template>
<script setup lang="ts">
import { useActuarialStore } from '@/stores/actuarial';
const actuarialStore = useActuarialStore();
defineEmits(['update:updateIdfMethodType']);
defineProps({
  idfMethodType: {
    type: String,
    default: 'Dynamic',
  },
});
</script>

<template>
  <div class="w-full">
    <div>
      Type
      <span class="text-sybil-teal cursor-help">
        <el-tooltip
          content="Calculated average link ratios are calculated from the triangles and are impacted by excluding any cells or diagonals on the trangles"
          placement="right"
          :show-after="10"
        >
          ?
        </el-tooltip>
      </span>
    </div>
    <div class="mb-5 grid grid-cols-2">
      <el-button-group>
        <el-button
          class="w-30"
          :type="idfMethodType === 'Dynamic' ? 'primary' : ''"
          @click="$emit('update:updateIdfMethodType', 'Dynamic')"
          >Calculated</el-button
        >
        <el-button
          data-testid="imported-selection"
          class="w-30"
          :type="idfMethodType == 'Static' ? 'primary' : ''"
          @click="$emit('update:updateIdfMethodType', 'Static')"
          >Imported</el-button
        >
      </el-button-group>
      <el-button-group v-if="idfMethodType != 'Dynamic' && actuarialStore.staticValueType == 'paste'">
        <el-button
          class="w-30"
          :type="actuarialStore.selectedStaticValue == 'Link ratio' ? 'primary' : ''"
          @click="actuarialStore.selectedStaticValue = 'Link ratio'"
          >Link ratio</el-button
        >
        <el-button
          class="w-30"
          :type="actuarialStore.selectedStaticValue == 'Dev. factor' ? 'primary' : ''"
          @click="actuarialStore.selectedStaticValue = 'Dev. factor'"
          >Dev. factor</el-button
        >
      </el-button-group>
    </div>
  </div>
</template>

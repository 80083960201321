import {
  UnderwritingAccidentEnum,
  type FinanceCustomMetricsType,
  type FinanceDataType,
  type FinanceSummaryData,
} from '@/types/finance';
import { METRICS_VALUE_LOC } from '@/constants/finance';
import { FinanceMetricsFormulas } from './FinanceFormulas';
import FinanceSummaryCalculation from '@/views/Finance/calculations/FinanceSummaryCalculation';

export default class RecalculateFinanceCacheOnChangeMetrics {
  customMetrics: FinanceCustomMetricsType;
  businessAccidentValues: FinanceDataType;
  businessUnderwritingValues: FinanceDataType;
  accidentFinanceSummary: FinanceSummaryData;
  underwritingFinanceSummary: FinanceSummaryData;
  uniqueClaimsNature: Set<string>;
  financeSummaryCalculation: FinanceSummaryCalculation;
  constructor(
    customMetrics: FinanceCustomMetricsType,
    businessAccidentValues: FinanceDataType,
    businessUnderwritingValues: FinanceDataType,
    accidentFinanceSummary: FinanceSummaryData,
    underwritingFinanceSummary: FinanceSummaryData,
    uniqueClaimsNature: Set<string>
  ) {
    this.customMetrics = customMetrics;
    this.businessAccidentValues = businessAccidentValues;
    this.businessUnderwritingValues = businessUnderwritingValues;
    this.accidentFinanceSummary = accidentFinanceSummary;
    this.underwritingFinanceSummary = underwritingFinanceSummary;
    this.uniqueClaimsNature = uniqueClaimsNature;
    this.financeSummaryCalculation = new FinanceSummaryCalculation(
      this.businessAccidentValues,
      this.businessUnderwritingValues,
      this.uniqueClaimsNature
    );
  }

  run() {
    this.recalculateValues(this.businessUnderwritingValues, UnderwritingAccidentEnum.UNDERWRITING_DATA);
    this.recalculateValues(this.businessAccidentValues, UnderwritingAccidentEnum.ACCIDENT_DATA);
  }

  getRecalculatedUnderwritingFinanceSummary() {
    return this.financeSummaryCalculation.getUnderwritingValues();
  }

  getRecalculatedAccidentFinanceSummary() {
    return this.financeSummaryCalculation.getAccidentValues();
  }

  recalculateValues(values: FinanceDataType, accidentUnderwriting: UnderwritingAccidentEnum) {
    for (const businessIndex in values) {
      const businessValues = values[businessIndex];
      for (const value of businessValues) {
        const valueDateKeys = Object.keys(value);
        for (const date of valueDateKeys) {
          value[date].CCR =
            this.customMetrics[parseInt(businessIndex)].LRLoad[METRICS_VALUE_LOC] + value[date].CCROriginal;
          value[date].LossRatio =
            this.customMetrics[parseInt(businessIndex)].LRLoad[METRICS_VALUE_LOC] + value[date].LossRatioOriginal;
          value[date].LRLoad = this.customMetrics[parseInt(businessIndex)].LRLoad[METRICS_VALUE_LOC];
          value[date].CombineRatio = FinanceMetricsFormulas.combineRatio(
            value[date].CCR,
            this.customMetrics[parseInt(businessIndex)].OHExpenses[METRICS_VALUE_LOC]
          );

          if (accidentUnderwriting == UnderwritingAccidentEnum.UNDERWRITING_DATA) {
            value[date].GWPGrowth = FinanceMetricsFormulas.gwpGrowth(
              value[date].GWP_GEP,
              this.customMetrics[parseInt(businessIndex)].GWPGrowth[METRICS_VALUE_LOC]
            );
          }

          value[date].OverheadExpenses = FinanceMetricsFormulas.overheadExpenses(
            value[date].GWP_GEP,
            this.customMetrics[parseInt(businessIndex)].OHExpenses[METRICS_VALUE_LOC]
          );
        }
      }
    }

    return values;
  }
}

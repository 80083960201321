<script setup lang="ts">
import { api } from "@/services/api";
import { useRouter } from "vue-router";
import { ref } from "vue";
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from '@/config/AzureSSOConfig.js'
import { ElMessage } from "element-plus";
import LoaderComponent from "@/components/LoaderComponent.vue";
import { useAuthenticationStore } from '@/stores/auth'

let msalInstance: PublicClientApplication | null = null
const API_URL = import.meta.env.VITE_API_URL + 'users/';
const router = useRouter();
const loading = ref(false)

const auth_store = useAuthenticationStore()

function openErrorMsg(msg: string) {
    ElMessage.warning(msg);
}

function logout() {
  auth_store.logout()
    .then(() => router.push({path: '/login'}))
    .catch((err) => {
      if(err.response && err.response.data) {
            openErrorMsg(err.response.data.data)
        } else {
            openErrorMsg('Error! Please try again')
        }
    })
}

function showErrorMsg(err: any) {
    console.log(err)
    if (err.response?.data && err.response?.status != 404) {
        openErrorMsg(err.response?.data?.message)
    } else {
        openErrorMsg("Error!")
    }
}

async function signInWithAzure() {
    if (!msalInstance) {
        msalInstance = new PublicClientApplication(msalConfig);
        await msalInstance.initialize();
    }

    const loginRequest = {
        scopes: ['User.Read'],
        prompt: 'select_account'
    };

    loading.value = true

    await msalInstance
        .loginPopup(loginRequest)
        .then(async (response) => {
            const user = JSON.parse(localStorage.getItem("user") || '{}');
            let account = msalInstance?.setActiveAccount(response.account);

            const loginRequest:any = {
                scopes: ['User.Read'],
                account: account
            };

            const tokenResponse = await msalInstance?.acquireTokenSilent(loginRequest);

            return api.post(API_URL + 'onboard_SSO', { token: tokenResponse?.idToken, email: user.email }, { withCredentials: true })
        })
        .then(async (res) => {
            auth_store.set_data_login(res)
            router.push({path: '/home'})
        })
        .catch(error => {
            loading.value = false
            console.error(`error during authentication: ${error}`);
        });
}

// async function logout() {
//     await store.dispatch("auth/logout")
//     router.push("/login");
// }

</script>
<template>
    <div class="h-screen flex items-center justify-center">
        <LoaderComponent v-if="loading" />
        <div class="border-2 p-16 border-sybil-teal flex items-center justify-center flex-col">
            <h1 class="text-4xl font-bold text-sybil-teal">Welcome to Sybil!</h1>
            <p class="mt-4">Admin permissions might be needed.</p>
            <div class="w-full flex items-center justify-center">
                <el-button class="mt-6" @click="signInWithAzure">
                    Log In with Azure
                </el-button>
                <el-button class="mt-6" @click="logout">
                    Log Out
                </el-button>
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import { useActuarialStore, CLAIMS_REF } from '@/stores/actuarial';
import { useGlobalStore } from '@/stores/global';
import { usePortfolioStore } from '@/stores/portfolio';
import { useAvEActuarialStore } from '@/stores/AvEActuarial';

const actuarialStore = useActuarialStore();
const actuarialAvEStore = useAvEActuarialStore();
const globalStore = useGlobalStore();

const ultTableData = computed(() => actuarialStore.ult_table_data);
const ultTableDataAve = computed(() => actuarialAvEStore.ult_table_data);

const diff = computed(() => actuarialStore.reportingPeriodAVE);

onMounted(() => {
  console.log(ultTableData.value);
  console.log(ultTableDataAve.value);
});

const portfolioStore = usePortfolioStore();

const currency = computed(() => globalStore.currency.currencyFormat.currency);
const gwpUnits = computed(() => globalStore.currency.GWPUnits);
const selectedGWPUnit = computed<string>(() => globalStore.currency.currencyFormat.selectedGWPUnit);
const ACCIDENT_UNDERWRITING = ['Accident', 'Underwriting'];
function numberWithCommas(x: number, isValue: boolean) {
  if (!isValue) {
    return (x / parseFloat(gwpUnits.value[selectedGWPUnit.value][0]))
      .toFixed(globalStore.currency.currencyFormat.precision)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  } else {
    return parseFloat(
      (x / parseFloat(gwpUnits.value[selectedGWPUnit.value][0])).toFixed(globalStore.currency.currencyFormat.precision)
    );
  }
}

function percentage(e: number) {
  if (!e) {
    return 0 + '%';
  }

  return (e * 100).toFixed(1) + '%';
}

function totalActual() {
  let sum = 0;

  for (let i = 0; i < Object.keys(ultTableDataAve.value['MONTH']).length; i++) {
    sum += ultTableData.value['Incurred Claims'][i] - ultTableDataAve.value['Incurred Claims'][i];
  }

  return sum;
}

function totalExpected() {
  let sum = 0;
  console.log(ultTableDataAve.value);
  for (let i = 0; i < Object.keys(ultTableDataAve.value['MONTH']).length; i++) {
    sum +=
      ((ultTableDataAve.value[allClaimsTypeColumnAvE.value[ultTableDataAve.value['selected_ult'][i]]][i] -
        ultTableDataAve.value['Incurred Claims'][i]) *
        (1 / (ultTableDataAve.value['incurred_dev'][i - diff.value] || 1) -
          1 / ultTableDataAve.value['incurred_dev'][i])) /
      (1 / ultTableDataAve.value['incurred_dev'][i]);
  }

  return sum;
}

const allClaimsTypeColumnAvE = computed(() => {
  return [...CLAIMS_REF, ...actuarialAvEStore.customUltTableTitle];
});

function prevIBNRTotal() {
  let total = 0;
  for (const index in ultTableDataAve.value['MONTH']) {
    total +=
      ultTableDataAve.value[allClaimsTypeColumnAvE.value[ultTableDataAve.value['selected_ult'][index]]][index] -
      ultTableDataAve.value['Incurred Claims'][index];
  }

  return total;
}

function prevIncurredTotal() {
  let total = 0;
  for (const index in ultTableDataAve.value['MONTH']) {
    total += ultTableDataAve.value['Incurred Claims'][index];
  }

  return total;
}

function currentIncurredTotal() {
  let total = 0;
  for (const index in ultTableDataAve.value['MONTH']) {
    total += ultTableData.value['Incurred Claims'][index];
  }

  return total;
}

const showExtra = ref(false);
</script>
<template>
  <div class="mr-12 h-full max-w-full">
    <div class="flex flex-row">
      <span class="ml-3 my-3 text-sm text-gray-500 italic cursor-pointer"
        ><i>Amounts are in {{ currency + gwpUnits[selectedGWPUnit][1] }}</i></span
      >
    </div>
  </div>
  <el-button @click="showExtra = !showExtra">{{ !showExtra ? 'Show' : 'Hide' }} Extra</el-button>
  <div v-if="ultTableDataAve.length != 0" class="table-panel shadow-md">
    <table class="table-panel bg-white shadow" style="border-spacing: 0">
      <thead class="sticky top-0 z-20">
        <tr>
          <th class="fixWidth header-teal text-black">
            {{ ACCIDENT_UNDERWRITING[actuarialStore.actuarial_accident_underwriting] }} Months
          </th>
          <th v-if="showExtra" class="fixWidth header-teal whitespace-nowrap text-blue-500">Prev IBNR</th>
          <th v-if="showExtra" class="fixWidth header-teal whitespace-nowrap text-blue-500">Prev LDF</th>
          <th v-if="showExtra" class="fixWidth header-teal whitespace-nowrap text-blue-500">expected factor</th>
          <th class="fixWidth header-teal whitespace-nowrap text-blue-500">
            Expected ({{ portfolioStore.isAve ? 'Prior bounce' : 'Open Model' }})
          </th>
          <th v-if="showExtra" class="fixWidth header-teal whitespace-nowrap text-blue-500">Prev Incurred</th>
          <th v-if="showExtra" class="fixWidth header-teal whitespace-nowrap text-blue-500">Current Incurred</th>
          <th class="fixWidth header-teal whitespace-nowrap text-blue-500">Actual</th>
          <th class="fixWidth header-teal whitespace-nowrap text-blue-500">Actual - Expected</th>
          <th class="fixWidth header-teal whitespace-nowrap text-blue-500">Actual/Expected</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(value, index) in ultTableDataAve['MONTH']" :key="index" class="relative">
          <td class="fixWidth side-teal sticky left-0 top-0 z-10">{{ value }}</td>
          <td v-if="showExtra" class="fixWidth text-blue-700">
            {{
              numberWithCommas(
                ultTableDataAve[allClaimsTypeColumnAvE[ultTableDataAve['selected_ult'][index]]][index] -
                  ultTableDataAve['Incurred Claims'][index],
                false
              )
            }}
          </td>
          <td v-if="showExtra" class="fixWidth text-blue-700">
            {{ ultTableDataAve['incurred_dev'][index].toFixed(1) }}
          </td>
          <td v-if="showExtra" class="fixWidth text-blue-700">
            {{
              (
                (1 /
                  (ultTableDataAve['incurred_dev'][index - diff] ||
                    ultTableDataAve['incurred_dev'][0] +
                      ((1 - ultTableDataAve['incurred_dev'][0]) * -(index - diff)) / 24) -
                  1 / ultTableDataAve['incurred_dev'][index]) *
                ultTableDataAve['incurred_dev'][index]
              ).toFixed(1)
            }}
          </td>
          <td class="fixWidth text-blue-700">
            {{
              numberWithCommas(
                ((ultTableDataAve[allClaimsTypeColumnAvE[ultTableDataAve['selected_ult'][index]]][index] -
                  ultTableDataAve['Incurred Claims'][index]) *
                  (1 /
                    (ultTableDataAve['incurred_dev'][index - diff] ||
                      ultTableDataAve['incurred_dev'][0] +
                        ((1 - ultTableDataAve['incurred_dev'][0]) * -(index - diff)) / 24) -
                    1 / ultTableDataAve['incurred_dev'][index])) /
                  (1 / ultTableDataAve['incurred_dev'][index]),
                false
              )
            }}
          </td>
          <td v-if="showExtra" class="fixWidth text-blue-700">
            {{ numberWithCommas(ultTableDataAve['Incurred Claims'][index], false) }}
          </td>
          <td v-if="showExtra" class="fixWidth text-blue-700">
            {{ numberWithCommas(ultTableData['Incurred Claims'][index], false) }}
          </td>
          <td class="fixWidth text-blue-700">
            {{
              numberWithCommas(
                ultTableData['Incurred Claims'][index] - ultTableDataAve['Incurred Claims'][index],
                false
              )
            }}
          </td>

          <td
            class="fixWidth text-blue-500 cursor-pointer"
            :class="{ selected: ultTableData['selected_ult'][index] == 0 ? true : false }"
          >
            {{
              numberWithCommas(
                ultTableData['Incurred Claims'][index] -
                  ultTableDataAve['Incurred Claims'][index] -
                  ((ultTableDataAve[allClaimsTypeColumnAvE[ultTableDataAve['selected_ult'][index]]][index] -
                    ultTableDataAve['Incurred Claims'][index]) *
                    (1 /
                      (ultTableDataAve['incurred_dev'][index - diff] ||
                        ultTableDataAve['incurred_dev'][0] +
                          ((1 - ultTableDataAve['incurred_dev'][0]) * -(index - diff)) / 24) -
                      1 / ultTableDataAve['incurred_dev'][index])) /
                    (1 / ultTableDataAve['incurred_dev'][index]),
                false
              )
            }}
          </td>
          <td class="fixWidth text-blue-500">
            {{
              (
                ((ultTableData['Incurred Claims'][index] - ultTableDataAve['Incurred Claims'][index]) /
                  (((ultTableDataAve[allClaimsTypeColumnAvE[ultTableDataAve['selected_ult'][index]]][index] -
                    ultTableDataAve['Incurred Claims'][index]) *
                    (1 /
                      (ultTableDataAve['incurred_dev'][index - diff] ||
                        ultTableDataAve['incurred_dev'][0] +
                          ((1 - ultTableDataAve['incurred_dev'][0]) * -(index - diff)) / 24) -
                      1 / ultTableDataAve['incurred_dev'][index])) /
                    (1 / ultTableDataAve['incurred_dev'][index]))) *
                100
              ).toFixed(1)
            }}%
          </td>
        </tr>
      </tbody>
      <tfoot class="sticky bottom-0 z-20">
        <tr v-if="ultTableDataAve">
          <td class="fixWidth total-gray font-bold sticky left-0">Total</td>
          <td v-if="showExtra" class="fixWidth text-blue-800 font-bold total-gray sticky right-0">
            {{ numberWithCommas(prevIBNRTotal(), false) }}
          </td>
          <td v-if="showExtra" class="fixWidth text-blue-800 font-bold total-gray sticky right-0"></td>
          <td v-if="showExtra" class="fixWidth text-blue-800 font-bold total-gray sticky right-0"></td>
          <td class="fixWidth text-blue-800 font-bold total-gray sticky right-0">
            {{ numberWithCommas(totalExpected(), false) }}
          </td>
          <td v-if="showExtra" class="fixWidth text-blue-800 font-bold total-gray sticky right-0">
            {{ numberWithCommas(prevIncurredTotal(), false) }}
          </td>
          <td v-if="showExtra" class="fixWidth text-blue-800 font-bold total-gray sticky right-0">
            {{ numberWithCommas(currentIncurredTotal(), false) }}
          </td>
          <td class="fixWidth text-blue-800 font-bold total-gray sticky right-0">
            {{ numberWithCommas(totalActual(), false) }}
          </td>
          <td class="fixWidth text-blue-800 font-bold total-gray sticky right-0">
            {{ numberWithCommas(totalActual() - totalExpected(), false) }}
          </td>
          <td class="fixWidth text-blue-800 font-bold total-gray sticky right-0">
            {{ percentage(totalActual() / totalExpected()) }}
          </td>
        </tr>
      </tfoot>
    </table>
  </div>
</template>
<style scoped>
.table-panel {
  overflow-x: scroll;
  height: 600px;
  margin-left: 2px;
  border-collapse: separate;
}

.fixWidth {
  padding-top: 5px;
  padding-bottom: 5px;
  width: 120px;
  min-width: 120px;
  max-width: 420px;
  padding-left: 20px;
  padding-right: 20px;
  border: 0.01em solid #f4f4f5;
  font-size: 12px;
  text-align: center;
}

.header-teal {
  background-color: rgb(187, 226, 211);
}

.side-teal {
  background-color: rgb(238, 248, 244);
}

.total-gray {
  background-color: #fafafa;
}
</style>

<script setup lang="ts">
import { usePortfolioStore } from '@/stores/portfolio';
import { useDashboardStore } from '@/stores/dashboard';
import { Filter, Close, CaretRight, CaretBottom } from '@element-plus/icons-vue';
import { ref, onMounted, computed, onBeforeMount, watch } from 'vue';
import { ElTree } from 'element-plus';
import Draggable from 'vue3-draggable';

const treeRef = ref<InstanceType<typeof ElTree>>();

const emit = defineEmits(['chunkSetup', 'tierSetup', 'valuesSetup', 'resizeFilter']);

const props = defineProps(['filtersWidth']);

const chnuksGroupExpand = ref(false);
const TieringGroupExpand = ref(false);
const TieringLegendExpand = ref(true);
const GraphSelectionExpand = ref(true);

const boxSizeExpand = ref(false);
const hierarchiesExpand = ref(false);

const defaultProps = {
  children: 'children',
  label: 'label',
  disabled: 'disabled',
};

function resizeFilter() {
  emit('resizeFilter');
}
function chunkSetup() {
  emit('chunkSetup');
}
function tierSetup() {
  emit('tierSetup');
}
function valueSetup() {
  emit('valueSetup');
}

const beforeEnter = (el) => {
  el.style.height = '0';
};

const enter = (el) => {
  el.style.height = el.scrollHeight + 'px';
};

const beforeLeave = (el) => {
  el.style.height = el.scrollHeight + 'px';
};

const leave = (el) => {
  el.style.height = '0';
};

const customNodeClass = (data: Tree, node: Node) => {
  if (data.isTitle) {
    return 'is-title';
  }
  return 'is-item ' + (data.grayed ? 'text-gray-400' : '');
};

const getCheckedKeys = () => {
  return treeRef.value!.getCheckedKeys(false);
};

const setCheckedKeys = (id: number, event) => {
  let selected = getCheckedKeys();
  if (event.ctrlKey || event.metaKey) {
    if (selected.indexOf(id) === -1) {
      selected.push(id);
      treeRef.value!.setCheckedKeys(selected, false);
      changeSelection();
    }
    graphSelectionRadio.value = id;
    graphSelectionChange();
  } else {
    if (selected.indexOf(id) === -1) {
      selected.push(id);
    } else {
      selected = selected.filter((x: number) => x != id);
    }
    treeRef.value!.setCheckedKeys(selected, false);
    changeSelection();
  }
};

const renderContent = (
  h,
  {
    node,
    data,
    store,
  }: {
    node: Node;
    data: Tree;
    store: Node['store'];
  }
) => {
  if (!data.isTitle && !data.isSubTitle && !data.disabled) {
    return h(
      'div',
      { onClick: (event) => setCheckedKeys(node.data.id, event) },

      h('span', {}, null, node.label)
    );
  } else {
    return h('div', h('span', {}, null, node.label));
  }
};

const transitionRenderKey = computed(() => dashboard_store.transitionRenderKey);
const portfolio_store = usePortfolioStore();
const dashboard_store = useDashboardStore();
const currChunkGroup = computed(() => portfolio_store.parameters.hierarchies);
const hierarchies = ref(dashboard_store.partitions_hierarchies);
const hierarchies_bool = ref(dashboard_store.partitions_hierarchies_bool);

const chunkGroups = computed(() => dashboard_store.chunks_grouping);
const comp2 = computed(() => dashboard_store.comp2);

const chunkRadio = computed(() => dashboard_store.selectedChunkGroup);
// const chunkRadio = ref( portfolio_store.parameters['hierarchies'][0])
const tierRadio = ref(dashboard_store.selectedTierGroup || 'None');
const tierData = computed(() => dashboard_store.tierData);

const comparisonViewColumnsTree = computed(() => dashboard_store.comparisonViewColumnsTree);
const hierarchyRadio = ref(dashboard_store.partitions_hierarchy_radio);

const comparisonViewColumns = ref(dashboard_store.comparisonViewColumns);
const graphSelectionRadio = ref(dashboard_store.graphSelectionRadio);

onBeforeMount(async () => {
  if (!comparisonViewColumns.value) {
    comparisonViewColumns.value = await dashboard_store.getcomparisonViewColumns();
  }
});
onMounted(async () => {
  if (!hierarchies.value) {
    hierarchies.value = portfolio_store.parameters.hierarchies.map((x, i) => ({
      id: i,
      name: x,
      value: i > 2 ? false : true,
    }));
    hierarchies_bool.value = portfolio_store.parameters.hierarchies.map((x, i) => (i > 2 ? false : true));

    dashboard_store.setPartitionsHierarchies(hierarchies.value);
    dashboard_store.setPartitionsHierarchiesBool(hierarchies_bool.value);
  } else {
    dashboard_store.setPartitionsHierarchies(hierarchies.value);
    dashboard_store.partition_chart();
  }

  dashboard_store.setGraphSelectionRadio(graphSelectionRadio.value);
  treeRef.value!.setCheckedKeys(comparisonViewColumns.value, false);
});

watch(
  hierarchies,
  (newVal) => {
    dashboard_store.setPartitionsHierarchies(newVal);
    dashboard_store.partition_chart();
  },
  { deep: true }
);

function chunkGroupChange(e) {
  dashboard_store.setSelectedChunkGroup(e);
}

function tierGroupChange() {
  if (
    tierRadio.value != 'None' &&
    !comparisonViewColumns.value.includes(tierData.value[tierRadio.value]?.selectedTiering)
  ) {
    comparisonViewColumns.value.push(tierData.value[tierRadio.value].selectedTiering);
    treeRef.value!.setCheckedNodes(comparisonViewColumns.value);
    dashboard_store.setComparisonViewColumns(comparisonViewColumns.value);
  }
  dashboard_store.setSelectedTierGroup(tierRadio.value);
  if (tierRadio.value != 'None' && !graphSelectionRadio.value) {
    graphSelectionRadio.value = comparisonViewColumns.value
      .filter((e) => e != tierData.value[tierRadio.value].selectedTiering)
      .filter((x) => !x.includes('GWP'))[0];
    graphSelectionChange();
  }
  dashboard_store.partition_chart();
}

function graphSelectionChange() {
  dashboard_store.setGraphSelectionRadio(graphSelectionRadio.value);
}

function changeSelection() {
  let excludeItems = [
    'main',
    'gwp',
    'fwd',
    'current',
    'after12',
    'next12',
    'all_time',
    'underwriting',
    'underwriting_last12',
    'underwriting_last6',
    'accident',
    'accident_last12',
    'accident_last6',
  ];
  comparisonViewColumns.value = treeRef
    .value!.getCheckedNodes()
    .map((x) => x.id)
    .filter((item) => !excludeItems.includes(item));
  if (
    tierRadio.value != 'None' &&
    !comparisonViewColumns.value.includes(tierData.value[tierRadio.value]?.selectedTiering)
  ) {
    tierRadio.value = 'None';
    tierGroupChange();
  }
  dashboard_store.setComparisonViewColumns(comparisonViewColumns.value);
}

function changeHierarcies(value, id) {
  let index = hierarchies.value.findIndex((x) => x.id == id);
  hierarchies.value[index].value = value;
  hierarchies_bool.value = [];
  for (const i of hierarchies.value) {
    hierarchies_bool.value.push(i.value);
  }
  dashboard_store.setPartitionsHierarchiesBool(hierarchies_bool.value);
  dashboard_store.setPartitionsHierarchies(hierarchies.value);
}

function changeHierarchyRadio() {
  dashboard_store.setPartitionsHierarchyRadio(hierarchyRadio.value);
  dashboard_store.partition_chart();
}
</script>

<template>
  <div class="sidenav main-sidebars shadow">
    <!-- <h1>{{sideBarData}}</h1> -->
    <div class="flex flex-row place-items-center sticky top-0 bg-white py-2 z-20">
      <div v-if="props.filtersWidth === '300px'" class="h-8 mr-10 ml-2"></div>
      <i
        @click="resizeFilter"
        class="pi pi-arrows-h ml-2 cursor-pointer absolute top-4 right-2"
        style="font-size: 20px"
      ></i>
    </div>
    <template v-if="props.filtersWidth === '300px'">
      <div v-show="!comp2" class="side-panel-title">
        <el-icon class="pt-3 cursor-pointer ml-1" @click="chnuksGroupExpand = !chnuksGroupExpand"
          ><CaretRight v-if="!chnuksGroupExpand" class="text-gray-400 text-sm" /><CaretBottom
            v-if="chnuksGroupExpand"
            class="text-gray-400 text-sm"
        /></el-icon>
        <span class="text-sybil-charcoal -mt-2 ml-1 cursor-pointer" @click="chnuksGroupExpand = !chnuksGroupExpand"
          >Chunking Groups</span
        ><img
          src="@/assets/images/add.svg"
          width="20"
          class="absolute right-2 top-1 cursor-pointer ml-2"
          @click="chunkSetup()"
        />
      </div>
      <transition
        v-show="!comp2"
        :key="transitionRenderKey"
        @before-enter="beforeEnter"
        @enter="enter"
        @before-leave="beforeLeave"
        @leave="leave"
      >
        <div v-if="chnuksGroupExpand" class="chunk-group-elements">
          <div class="hover:bg-gray-200 px-3" v-for="(itm, idx) in currChunkGroup">
            <el-radio @change="chunkGroupChange(itm)" :model-value="chunkRadio" :label="itm">{{ itm }}</el-radio>
          </div>
          <div class="hover:bg-gray-200 px-3" v-for="(itm, idx) in chunkGroups">
            <el-radio @change="chunkGroupChange(idx)" :model-value="chunkRadio" :label="idx">{{ idx }}</el-radio>
          </div>
        </div>
      </transition>

      <div class="side-panel-title ease-in-out duration-1000">
        <el-icon class="pt-3 cursor-pointer ml-1" @click="TieringGroupExpand = !TieringGroupExpand"
          ><CaretRight v-if="!TieringGroupExpand" class="text-gray-400 text-sm" /><CaretBottom
            v-if="TieringGroupExpand"
            class="text-gray-400 text-sm"
        /></el-icon>
        <span
          class="text-sybil-charcoal ease-in-out duration-1000 -mt-2 ml-1 cursor-pointer"
          @click="TieringGroupExpand = !TieringGroupExpand"
          >Tiering Groups</span
        ><img
          src="@/assets/images/add.svg"
          width="20"
          class="absolute right-2 top-1 cursor-pointer ml-2"
          @click="tierSetup()"
        />
      </div>
      <transition
        :key="transitionRenderKey"
        @before-enter="beforeEnter"
        @enter="enter"
        @before-leave="beforeLeave"
        @leave="leave"
      >
        <div v-if="TieringGroupExpand" class="chunk-group-elements">
          <div class="hover:bg-gray-200 px-3" v-for="(itm, idx) in tierData">
            <el-radio @change="tierGroupChange()" v-model="tierRadio" :label="idx">{{ idx }}</el-radio>
          </div>
          <div class="hover:bg-gray-200 px-3">
            <el-radio @change="tierGroupChange()" v-model="tierRadio" label="None">None</el-radio>
          </div>
        </div>
      </transition>

      <div v-if="tierRadio != 'None'" class="text-sm text-gray-700">
        <div class="side-panel-title ease-in-out duration-1000">
          <el-icon class="pt-3 cursor-pointer ml-1" @click="TieringLegendExpand = !TieringLegendExpand"
            ><CaretRight v-if="!TieringLegendExpand" class="text-gray-400 text-sm" /><CaretBottom
              v-if="TieringLegendExpand"
              class="text-gray-400 text-sm"
          /></el-icon>
          <span
            class="text-sybil-charcoal ease-in-out duration-1000 -mt-2 ml-1 cursor-pointer"
            @click="TieringLegendExpand = !TieringLegendExpand"
            >Tiering Legend</span
          >
        </div>

        <transition @before-enter="beforeEnter" @enter="enter" @before-leave="beforeLeave" @leave="leave">
          <div v-if="TieringLegendExpand" class="chunk-group-elements">
            <div class="px-5">
              {{ tierData[tierRadio].selectedTiering }}

              <div v-for="[i, lowUp] of tierData[tierRadio].tiers.entries()" class="flex flex-row mt-3" v-bind:key="i">
                <el-color-picker :model-value="tierData[tierRadio].colorPicker[i]" color-format="hex" />
                <p class="ml-2">Tier {{ i + 1 }}</p>
                <div class="ml-2" v-if="lowUp[1] == 'Up'">>{{ lowUp[0] }}%</div>
                <div class="ml-2" v-else>{{ lowUp[0] }}% - {{ lowUp[1] }}%</div>
              </div>
            </div>
          </div>
        </transition>
      </div>

      <div v-if="false">
        <div class="side-panel-title ease-in-out duration-1000">
          <el-icon class="pt-3 cursor-pointer ml-1" @click="GraphSelectionExpand = !GraphSelectionExpand"
            ><CaretRight v-if="!GraphSelectionExpand" class="text-gray-400 text-sm" /><CaretBottom
              v-if="GraphSelectionExpand"
              class="text-gray-400 text-sm"
          /></el-icon>
          <span
            class="text-sybil-charcoal ease-in-out duration-1000 -mt-2 ml-1 cursor-pointer"
            @click="GraphSelectionExpand = !GraphSelectionExpand"
            >Chart Values</span
          >
        </div>

        <transition @before-enter="beforeEnter" @enter="enter" @before-leave="beforeLeave" @leave="leave">
          <div class="whitespace-break-spaces">
            <div
              class="hover:bg-gray-200 px-3 py-1"
              v-for="item of Object.keys(comparisonViewColumns).filter((e) => e != tierData[tierRadio].selectedTiering)"
            >
              <el-radio size="large" @change="graphSelectionChange()" v-model="graphSelectionRadio" :label="item"
                ><div class="overflow-hidden overflow-x-auto panel-custom-scrollbar whitespace-break-spaces">
                  {{ item }}
                </div></el-radio
              >
            </div>
          </div>
        </transition>
      </div>
      <div v-show="!comp2">
        <div v-if="GraphSelectionExpand" class="">
          <el-tree
            v-if="comparisonViewColumns"
            ref="treeRef"
            :data="comparisonViewColumnsTree"
            show-checkbox
            @check="changeSelection()"
            node-key="id"
            class="filter-tree"
            :render-content="renderContent"
            :props="{ class: customNodeClass, disabled: 'disabled' }"
            :default-expanded-keys="['main']"
          />
        </div>
      </div>

      <div v-if="comp2" class="side-panel-title">
        <el-icon class="pt-3 cursor-pointer ml-1" @click="boxSizeExpand = !boxSizeExpand"
          ><CaretRight v-if="!boxSizeExpand" class="text-gray-400 text-sm" /><CaretBottom
            v-if="boxSizeExpand"
            class="text-gray-400 text-sm"
        /></el-icon>
        <span class="text-sybil-charcoal -mt-2 ml-1 cursor-pointer" @click="boxSizeExpand = !boxSizeExpand"
          >Box Size Indicator (GWP)</span
        >
      </div>
      <transition v-if="comp2" @before-enter="beforeEnter" @enter="enter" @before-leave="beforeLeave" @leave="leave">
        <div v-if="boxSizeExpand" class="chunk-group-elements">
          <div class="hover:bg-gray-200 px-3" v-for="itm in comparisonViewColumnsTree[0].children[0].children">
            <el-radio @change="changeHierarchyRadio()" v-model="hierarchyRadio" :label="itm.label">{{
              itm.label
            }}</el-radio>
          </div>
        </div>
      </transition>

      <div v-show="comp2" class="side-panel-title">
        <el-icon class="pt-3 cursor-pointer ml-1" @click="hierarchiesExpand = !hierarchiesExpand"
          ><CaretRight v-if="!hierarchiesExpand" class="text-gray-400 text-sm" /><CaretBottom
            v-if="hierarchiesExpand"
            class="text-gray-400 text-sm"
        /></el-icon>
        <span class="text-sybil-charcoal -mt-2 ml-1 cursor-pointer" @click="hierarchiesExpand = !hierarchiesExpand"
          >Hierarchies <span class="text-sm italic text-gray-500">Select and reorder</span></span
        >
      </div>
      <transition v-show="comp2" @before-enter="beforeEnter" @enter="enter" @before-leave="beforeLeave" @leave="leave">
        <div v-if="hierarchiesExpand" class="chunk-group-elements">
          <draggable v-model="hierarchies">
            <template #item="{ item }">
              <!-- example -->
              <div class="w-full">
                <el-checkbox
                  class="hover:bg-gray-200 px-3 w-full"
                  :disabled="
                    item.value && hierarchies_bool.map((x) => (x == true ? 1 : 0)).reduce((x, y) => x + y, 0) == 1
                  "
                  @change="(e: any) => changeHierarcies(e, item.id)"
                  :model-value="item.value"
                  :label="item.name"
                  :value="item.value"
                >
                  {{ item.name }}
                </el-checkbox>
              </div>
              <!-- or your own template -->
            </template>
          </draggable>
        </div>
      </transition>
      <div class="card flex justify-content-center"></div>
    </template>
  </div>
</template>

<style>
.sidenav {
  height: 100%;
  position: fixed;
  right: 0;
  border-left: 1px solid #eeeeee;
  background-color: white;
  overflow-x: hidden;
  overflow-y: scroll;

  padding-bottom: 6rem;
}

.sidenav #filter {
  text-align: center;
  padding: 2px 0px 2px 16px;
  font-size: 18px;
  display: block;
  text-align: center;
}

.sidenav #filter-choice:hover {
  padding: 0px 0px 0px 22px;
  font-size: 13px;
  display: block;
  background-color: #e2e8f0;
}

.sidenav #filter-choice {
  padding: 0px 0px 0px 22px;
  font-size: 11px;
  display: block;
}

.side-panel-title {
  height: 30px;

  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));

  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;

  position: sticky;
  top: 3rem;
  font-size: 18px;
  z-index: 10;
}

.is-item {
  font-size: 12px;
  display: block;
  overflow: hidden;
  white-space: normal;
}

.is-item:hover {
  font-size: 14px;
}
@keyframes scroll-right {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%); /* Adjust to match content width */
  }
}

.tracker {
  font-size: 11px;
}

.panel-teal {
  filter: invert(63%) sepia(42%) saturate(423%) hue-rotate(105deg) brightness(92%) contrast(93%);
}

.panel-teal:hover {
  filter: none;
}

.chunk-group-elements {
  overflow: hidden;
  transition: height 0.2s ease-in-out;
}

.buttons {
  margin-top: 35px;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
</style>

import { defineStore } from 'pinia';
import { ref } from 'vue';
import { api } from '@/services/api';
import type {
  FinanceCustomMetricsType,
  FinanceBusinessInformationType,
  FinanceDataType,
  FinanceRawDataType,
  MetricsObj,
  FinanceSummaryData,
} from '@/types/finance';
import type { FilterHierarchyType, ParametersObjectType } from '@/types/portfolio';
import { useGlobalStore } from '@/stores/global';
import openErrorMsg from '@/components/openErrorMsg';
import FinanceAPIDataTransformation from '@/views/Finance/calculations/FinanceAPIDataTransformation';
import type { BusinessSelection } from '@/views/Finance/FinanceSelection/components/FinanceAddPortfolio.vue';
import FinanceSummaryCalculation from '@/views/Finance/calculations/FinanceSummaryCalculation';
import * as DateTimeConstants from '@/constants/DateTimeConstants';
import FinanceMonthConversion from '@/views/Finance/calculations/FinanceMonthConversion';

const DEFAULT_CUSTOM_METRICS: MetricsObj = { LRLoad: [0, false], OHExpenses: [0, false], GWPGrowth: [0, false] };

export const useFinanceStore = defineStore('finance', () => {
  const globalStore = useGlobalStore();
  // Business related stores
  /**
   * Selected years for the finance data
   * @type {DateTimeConstants.DateUnitEnum}
   * @default DateTimeConstants.DateUnitEnum.MONTH
   * @example DateTimeConstants.DateUnitEnum.MONTH
   */
  const selectedYears = ref<DateTimeConstants.DateUnitEnum>(DateTimeConstants.DateUnitEnum.MONTH);
  /**
   * Custom metrics for the finance data
   * @type {FinanceCustomMetricsType}
   * @default []
   * @example [[{LRLoad: [0.2, true], OHExpenses: [0.2, true], GWPGrowth: [0.2, true}]]
   */
  const customMetrics = ref<FinanceCustomMetricsType>([]);
  /**
   * Business information for the finance data
   * Contains the business id, name, all hierarchies, all claims nature,
   * selected bounce name, and selected hierarchy name
   * @type {FinanceBusinessInformationType}
   * @default []
   * @example [{id: '1', name: 'Business 1', allHierarchies: ['1', '2'], allClaimsNature: ['1', '2'], selectedBounceName: ['Bounce 1'], selectedHierarchyName: ['Hierarchy 1']}]
   */
  const businessInformation = ref<FinanceBusinessInformationType>([]);
  const financeRawData = ref<FinanceRawDataType>();
  const maxHierarchyForAllBusiness = ref<number>(0);
  const uniqueClaimsNature = ref<Set<string>>(new Set());
  const accidentFinanceSummary = ref<FinanceSummaryData>({});
  const underwritingFinanceSummary = ref<FinanceSummaryData>({});

  /**
   * Business underwriting values
   * @type {FinanceDataType}
   * @default []
   * @example [[{date: {CCR: 0.2, NLR: 0.2, CombineRatio: 0.2, GWP_GEP: 0.2, GWPGrowth: 0.2, LRLoad: 0.2, LossRatio: 0.2}}]]
   */
  const businessUnderwritingValues = ref<FinanceDataType>([]);
  const businessAccidentValues = ref<FinanceDataType>([]);

  // Date related stores
  const businessUnderwritingValuesMonthConverted = ref<FinanceDataType>([]);
  const businessAccidentValuesMonthConverted = ref<FinanceDataType>([]);

  const allUnderwritingDateKeys = ref<string[]>([]);
  /**
   * All accident date keys sorted monthly
   */
  const allAccidentDateKeys = ref<string[]>([]);
  /**
   * All underwriting date keys that is formatted to current date
   * unit sorted
   */
  const allUnderwritingDateKeysConverted = ref<string[]>([]);
  /**
   * All accident date keys that is formatted to current date
   * unit sorted
   */
  const allAccidentDateKeysConverted = ref<string[]>([]);

  // Modal Variables
  const isAddPortfolioModal = ref<boolean>(false);

  /**
   * ‘transformData’ function
   * Transform the raw data into the finance data
   * @param rawData ‘FinanceRawDataType’
   * @param parameters ‘ParametersObjectType’
   * @param uwMonth ‘string[]’
   * @param accMonth ‘string[]’
   * @param businessMetrics ‘MetricsObj’
   * @param indexOfTheAddedBusiness ‘number’
   */
  function transformData(
    rawData: FinanceRawDataType,
    parameters: ParametersObjectType,
    uwMonth: string[],
    accMonth: string[],
    businessMetrics: MetricsObj,
    indexOfTheAddedBusiness: number
  ) {
    const financeDataCalculations = new FinanceAPIDataTransformation(
      rawData,
      parameters,
      uwMonth,
      accMonth,
      businessMetrics
    );

    // Merging all the dates together with other business
    function mergeDate(list1: string[], list2: string[]) {
      const combinedList = [...new Set([...list1, ...list2])];
      combinedList.sort((a, b) => new Date(a).getTime() - new Date(b).getTime());
      return combinedList;
    }

    allUnderwritingDateKeys.value = mergeDate(
      allUnderwritingDateKeys.value,
      financeDataCalculations.getUnderwritingDate()
    );

    allAccidentDateKeys.value = mergeDate(allAccidentDateKeys.value, financeDataCalculations.getAccidentDate());

    // Adding the data onto the respective business
    businessUnderwritingValues.value[indexOfTheAddedBusiness].push(financeDataCalculations.getUnderwritingData());
    businessAccidentValues.value[indexOfTheAddedBusiness].push(financeDataCalculations.getAccidentData());
    console.log(businessUnderwritingValues.value);
    console.log(businessAccidentValues.value);
    console.log(allUnderwritingDateKeys.value);
    console.log(allAccidentDateKeys.value);
    monthCalculation();
  }

  function monthCalculation() {
    const financeMonthConversion = new FinanceMonthConversion(
      businessAccidentValues.value,
      businessUnderwritingValues.value,
      uniqueClaimsNature.value,
      selectedYears.value
    );

    businessUnderwritingValuesMonthConverted.value = financeMonthConversion.getBusinessUnderwritingValues();
    businessAccidentValuesMonthConverted.value = financeMonthConversion.getBusinessAccidentValues();
    allUnderwritingDateKeysConverted.value = financeMonthConversion.dateKeyConversion(allUnderwritingDateKeys.value);
    allAccidentDateKeysConverted.value = financeMonthConversion.dateKeyConversion(allAccidentDateKeys.value);
    console.log(allUnderwritingDateKeysConverted.value);
    financeSummaryCalculation();
  }

  function financeSummaryCalculation() {
    const financeSummaryCalculation = new FinanceSummaryCalculation(
      businessAccidentValuesMonthConverted.value,
      businessUnderwritingValuesMonthConverted.value,
      uniqueClaimsNature.value
    );
    accidentFinanceSummary.value = financeSummaryCalculation.getAccidentValues();
    underwritingFinanceSummary.value = financeSummaryCalculation.getUnderwritingValues();
    console.log(accidentFinanceSummary.value);
  }

  async function getPortfolio(
    bounceId: string,
    filtersHierarchy: FilterHierarchyType,
    selectedBusiness: string,
    selectedBusinessID: string,
    nodeName: string,
    parameters: ParametersObjectType,
    uwMonth: string[],
    accMonth: string[],
    allHierarchies: string[],
    businessSelectionSelectedHierarchyName: BusinessSelection,
    yearsOfProjections: number
  ) {
    globalStore.setLoading(true);
    console.log(yearsOfProjections);
    await api
      .post(import.meta.env.VITE_API_URL + 'claims/finance-get-portfolio', {
        bounce_id: bounceId,
        filters_hierarchy: filtersHierarchy,
        years_of_projections: yearsOfProjections,
      })
      .then((res) => {
        console.log(res);
        console.log(res.data.data);
        const businessIndex = businessInformation.value.findIndex((e) => e.id === selectedBusinessID);
        let metricsIndex = -1;
        if (businessIndex < 0) {
          businessInformation.value.push({
            id: selectedBusinessID,
            name: selectedBusiness,
            allHierarchies: allHierarchies,
            allClaimsNature: parameters.claims_nature,
            selectedBounceName: [nodeName],
            selectedHierarchyName: [businessSelectionSelectedHierarchyName],
          });
          businessUnderwritingValues.value.push([]);
          businessAccidentValues.value.push([]);
          customMetrics.value.push(structuredClone(DEFAULT_CUSTOM_METRICS));
          maxHierarchyForAllBusiness.value = Math.max(maxHierarchyForAllBusiness.value, allHierarchies.length);
          uniqueClaimsNature.value = new Set([...parameters.claims_nature, ...uniqueClaimsNature.value]);
          metricsIndex = customMetrics.value.length - 1;
        } else {
          businessInformation.value[businessIndex].selectedBounceName.push(nodeName);
          businessInformation.value[businessIndex].selectedHierarchyName.push(businessSelectionSelectedHierarchyName);
          metricsIndex = businessIndex;
        }

        transformData(res.data.data, parameters, uwMonth, accMonth, customMetrics.value[metricsIndex], metricsIndex);

        console.log(customMetrics.value);
        console.log(uniqueClaimsNature.value);
        globalStore.setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        openErrorMsg('Error!');
        globalStore.setLoading(false);
      });
  }

  return {
    customMetrics,
    businessInformation,
    allUnderwritingDateKeys,
    allAccidentDateKeys,
    financeRawData,
    isAddPortfolioModal,
    transformData,
    getPortfolio,
    businessUnderwritingValues,
    businessAccidentValues,
    maxHierarchyForAllBusiness,
    accidentFinanceSummary,
    underwritingFinanceSummary,
    uniqueClaimsNature,
    businessUnderwritingValuesMonthConverted,
    businessAccidentValuesMonthConverted,
    selectedYears,
    monthCalculation,
    allUnderwritingDateKeysConverted,
    allAccidentDateKeysConverted,
  };
});

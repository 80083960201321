<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
// import Dialog from "primevue/dialog";
import InputText from 'primevue/inputtext';
import { useUnderwritingStore } from '@/stores/underwriting';
import { useActuarialStore } from '@/stores/actuarial';
import { usePortfolioStore } from '@/stores/portfolio';
import { useGlobalStore } from '@/stores/global';
import AutoComplete from 'primevue/autocomplete';
import Calendar from 'primevue/calendar';
import moment from 'moment';
import { api } from '@/services/api';
import openErrorMsg from '@/components/openErrorMsg';
import reverseSearch from '@/utilities/reverseSearch';

const underwriting_store = useUnderwritingStore();
const portfolio_store = usePortfolioStore();
const global_store = useGlobalStore();

const parameters = computed(() => portfolio_store.parameters);
const currency: any = computed(() => global_store.currency.currencyFormat.currency);
const dictionary = computed(() => portfolio_store.dictionary);
const autoCompleteSuggestion: any = ref([]);
const newBusinessModalData: any = ref({});
const totalTableData = computed(() => underwriting_store.totalTableData);
const valueArrayColumns = computed(() => underwriting_store.valueArrayColumns);
// Hierarchy, GWP, Commission, Dates, Loads, Elasticity
const isAllValidated = ref([0, 0, 0, 0, 0, 0]);

function title(str) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

const additionalInfomationNeeded = ref<string[]>([]);
const additionalInfomationNeededSelection = ref({});
const actuarialHierarchy = ref();
const additionalInfomationDialog = ref(false);
const configureEarningRatioDialog = ref(false);
function checkNewDictionary() {
  additionalInfomationNeeded.value = [];
  additionalInfomationNeededSelection.value = {};
  for (const hr of parameters.value.hierarchies) {
    if (reverseSearch(dictionary.value[hr], title(newBusinessModalData.value[hr])) == -1) {
      additionalInfomationNeeded.value.push(hr);
      additionalInfomationNeededSelection.value[hr] = { name: null, isNew: false };
    }
  }

  console.log(additionalInfomationNeeded.value);
}

const actuarialStore = useActuarialStore();

async function getActuarialChunkGrouping() {
  global_store.setLoading(true);
  await api
    .get(import.meta.env.VITE_API_URL + 'actuarial/get-actuarial-chunk-grouping', {
      params: {
        bounce_id: portfolio_store.selectedBounceID,
      },
    })
    .then((res) => {
      global_store.setLoading(false);
      actuarialHierarchy.value = res.data.data.variable.actuarial_hierarchy;
      if (portfolio_store.isMirrored) {
        actuarialHierarchy.value = actuarialStore.configChunkGroupNamesIfMirrored(actuarialHierarchy.value);
      }
      checkNewDictionary();
      if (additionalInfomationNeeded.value.length > 0) {
        additionalInfomationDialog.value = true;
      } else {
        underwriting_store.onAddNewBusiness(
          newBusinessModalData.value,
          additionalInfomationNeededSelection.value,
          earningRatio.value
        );
      }
    })
    .catch((err) => {
      openErrorMsg('Error!');
      console.log(err);
      global_store.setLoading(false);
    });
}

function onConfirm() {
  if (earningRatio.value.reduce((x, y) => x + y, 0).toFixed(4) != '1.0000') {
    openErrorMsg('Total earning ratio should be 1');
    return;
  }

  getActuarialChunkGrouping();
}

function checkGWP() {
  const isCorrect = isFinite(newBusinessModalData.value['GWP']) && newBusinessModalData.value['GWP'] != '';

  if (isCorrect) {
    isAllValidated.value[1] = 1;
  } else {
    isAllValidated.value[1] = 0;
  }
}

function checkElasticity() {
  const isCorrect =
    isFinite(newBusinessModalData.value['ELASTICITY']) && newBusinessModalData.value['ELASTICITY'] != '';

  if (isCorrect) {
    isAllValidated.value[5] = 1;
  } else {
    isAllValidated.value[5] = 0;
  }
}

function checkClaimsNature() {
  const isAllCorrect = parameters.value['claims_nature'].every((e: string) => {
    return isFinite(newBusinessModalData.value[e]) && newBusinessModalData.value[e] != '';
  });

  if (isAllCorrect) {
    isAllValidated.value[4] = 1;
  } else {
    isAllValidated.value[4] = 0;
  }
}

const all_uw_dates = computed(() => portfolio_store.all_uw_dates);

function checkDates() {
  if (newBusinessModalData.value['start_date'] == null || newBusinessModalData.value['end_date'] == null) {
    isAllValidated.value[3] = 0;
    return;
  }

  let a = moment(newBusinessModalData.value['start_date']).isBefore(newBusinessModalData.value['end_date']);
  let b = moment(newBusinessModalData.value['start_date']).isBetween(
    moment(all_uw_dates.value['month'][0], 'MMM-YYYY').subtract(1, 'months'),
    moment(all_uw_dates.value['month'][all_uw_dates.value['month'].length - 1], 'MMM-YYYY').add(1, 'months')
  );
  let c = moment(newBusinessModalData.value['end_date']).isBetween(
    moment(all_uw_dates.value['month'][0], 'MMM-YYYY').subtract(1, 'months'),
    moment(all_uw_dates.value['month'][all_uw_dates.value['month'].length - 1], 'MMM-YYYY').add(1, 'months')
  );

  const isDateCorrect = [a, b, c].every((e: any) => e);

  if (isDateCorrect) {
    isAllValidated.value[3] = 1;
  } else {
    isAllValidated.value[3] = 0;
  }
}

function toTitleCase(str: string) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

async function fetchDynamicController() {
  global_store.setLoading(true);
  let data = null;

  let post = {
    anyChange: false,
    bounce_id: portfolio_store.selectedBounceID,
    underwriting_month: moment(all_uw_dates.value['month'][0], 'MMM-YYYY').format('YYYY-MM-01'),
    selectedLineSize: portfolio_store.selectedLineSize,
    variable: 'Commission',
    filters: parameters.value.hierarchies.map((hierarchy) => {
      return { [hierarchy]: Object.keys(dictionary.value[hierarchy]) };
    }),
  };

  await api
    .post(import.meta.env.VITE_API_URL + 'underwriting/fetch-dynamic-controller', post)
    .then((res) => {
      data = res.data.data;
      global_store.setLoading(false);
    })
    .catch(() => {
      // openErrorMsg("Error!")
      global_store.setLoading(false);
      global_store.onConnectionError();
    });

  return data;
}

const formattedClaimsNature = [
  'Attritional A-priori',
  ...parameters.value.claims_nature.slice(1).map((e: any) => {
    return toTitleCase(e) + ' Load';
  }),
];
const formattedCommission = [
  ...parameters.value.commission.map((e: any) => {
    return e.split('_')[1] + ' Commission';
  }),
];

onMounted(async () => {
  newBusinessModalData.value = {};
  for (const i of parameters.value.hierarchies) {
    newBusinessModalData.value[i] = '';
  }

  const commissionData: any = await fetchDynamicController();

  newBusinessModalData.value['GWP'] = totalTableData.value['value_array'][0][valueArrayColumns.value['GWP']].toFixed(1);

  for (const i in parameters.value.claims_nature) {
    const name = parameters.value.claims_nature[i];
    const formattedName = formattedClaimsNature[i];
    newBusinessModalData.value[name] = (
      totalTableData.value['value_array'][0][valueArrayColumns.value[formattedName]] * 100
    ).toFixed(1);
  }

  newBusinessModalData.value['ELASTICITY'] =
    totalTableData.value['value_array'][0][valueArrayColumns.value['Elasticity']].toFixed(2);

  for (const i of parameters.value.commission) {
    if (commissionData) {
      newBusinessModalData.value[i] = (
        commissionData.value[commissionData.variable.findIndex((e) => e == i)] * 100
      ).toFixed(1);
    } else {
      newBusinessModalData.value[i] = 0;
    }
  }

  isAllValidated.value = [0, 1, 1, 0, 1, 1];

  newBusinessModalData.value['start_date'] = moment(all_uw_dates.value['month'][0], 'MMM-YYYY').toDate();
  newBusinessModalData.value['end_date'] = moment(
    all_uw_dates.value['month'][all_uw_dates.value['month'].length - 1],
    'MMM-YYYY'
  ).toDate();
  checkDates();
});

function checkCommission() {
  let sum = 0;
  let isAllCorrect = parameters.value['Commission'].every((e: string) => {
    if (!isFinite(newBusinessModalData.value[e]) && newBusinessModalData.value[e] != '') {
      return false;
    } else {
      sum += parseFloat(newBusinessModalData.value[e]);
      return true;
    }
  });

  if (sum > 100) {
    isAllCorrect = false;
  }

  if (isAllCorrect) {
    isAllValidated.value[2] = 1;
  } else {
    isAllValidated.value[2] = 0;
  }
}

function checkHierarchy() {
  const isAllCorrect = parameters.value['hierarchies'].every((e: string) => {
    return newBusinessModalData.value[e] != '';
  });

  if (isAllCorrect) {
    isAllValidated.value[0] = 1;
  } else {
    isAllValidated.value[0] = 0;
  }
}

function onUpdateNewBusinessHierarchy(e: any, hr: string) {
  newBusinessModalData.value[hr] = e.target.value;
  checkHierarchy();
}

async function autoCompleteFunction(e: any, i: any) {
  autoCompleteSuggestion.value = Object.values(dictionary.value[parameters.value['hierarchies'][i]]).filter(
    (word: any) => word.toLowerCase().includes(e.query.toLowerCase())
  );
}

function onCloseModal() {
  underwriting_store.onChangeIsNewBusinessModal(false);
}

function onResetAdditionalInfomationName(hierarchy) {
  console.log(additionalInfomationNeededSelection.value);
  additionalInfomationNeededSelection.value[hierarchy]['name'] = null;
}

function onSubmitAdditionalInfomation() {
  // check if empty selection exists
  for (const hierarchy of additionalInfomationNeeded.value) {
    if (!additionalInfomationNeededSelection.value[hierarchy]['name']) {
      openErrorMsg('Please fill in all the required fields');
      return;
    }

    if (
      additionalInfomationNeededSelection.value[hierarchy]['isNew'] &&
      actuarialHierarchy.value[hierarchy].groupNames.includes(
        additionalInfomationNeededSelection.value[hierarchy]['name']
      )
    ) {
      openErrorMsg('Duplicated name');
      return;
    }
  }

  underwriting_store.onAddNewBusiness(
    newBusinessModalData.value,
    additionalInfomationNeededSelection.value,
    earningRatio.value
  );
}

const earningRatio = ref([1]);
const numberOfEarningRatio = ref(1);

function handleChange() {
  const ratio = 1 / numberOfEarningRatio.value;
  earningRatio.value = Array(numberOfEarningRatio.value).fill(ratio);
  console.log(earningRatio.value);
}
</script>
<template>
  <el-dialog
    v-model="underwriting_store.isNewBusinessModal"
    position="top"
    class="w-96"
    title="Add new business"
    @update:visible="onCloseModal"
  >
    <!-- EARNING RATIO -->
    <el-dialog v-model="configureEarningRatioDialog" title="Tweak Earning Ratio">
      <div>
        <h1>Number Of Earning Ratios</h1>
        <el-input-number v-model="numberOfEarningRatio" :min="1" class="mb-6" @change="handleChange" />
        <div v-for="idx in earningRatio.length" :key="idx" class="mb-3">
          <h1 class="mb-2">Earning Ratio {{ idx }}:</h1>
          <el-input-number v-model="earningRatio[idx - 1]" :min="0" :step="0.001" :precision="4" />
        </div>
        <h1 class="text-red-500">
          *Note that the total ratio need to be 1. Current Total:
          {{ earningRatio.reduce((x, y) => x + y, 0).toFixed(4) }}
        </h1>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="configureEarningRatioDialog = false">Confirm</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- ADDITIONAL INFORMATION DIALOG -->
    <el-dialog v-model="additionalInfomationDialog" title="Additional Infomation needed">
      <div v-for="hierarchy in additionalInfomationNeeded" :key="hierarchy">
        <h1>{{ hierarchy }}</h1>
        <div class="flex flex-col mb-10">
          <el-switch
            v-model="additionalInfomationNeededSelection[hierarchy]['isNew']"
            active-text="Create New Group"
            size="large"
            inactive-text="Use Existing Group"
            @change="onResetAdditionalInfomationName(hierarchy)"
          />
          <span v-if="!additionalInfomationNeededSelection[hierarchy]['isNew']">
            <el-select v-model="additionalInfomationNeededSelection[hierarchy]['name']">
              <el-option
                v-for="item in actuarialHierarchy[hierarchy].groupNames"
                :key="item"
                :label="item"
                :value="item"
              ></el-option>
            </el-select>
          </span>
          <span v-else>
            <el-input
              v-model="additionalInfomationNeededSelection[hierarchy]['name']"
              placeholder="Enter new group name"
            />
          </span>
        </div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="onSubmitAdditionalInfomation()">Add</el-button>
        </span>
      </template>
    </el-dialog>
    <div v-if="newBusinessModalData">
      <div v-for="(hr, i) in parameters.hierarchies" :key="hr" class="w-full">
        <div>{{ hr }} <span class="italic text-sm text-red-700">*</span></div>
        <AutoComplete
          v-model="newBusinessModalData[hr]"
          :suggestions="autoCompleteSuggestion"
          @input="onUpdateNewBusinessHierarchy($event, hr)"
          @complete="autoCompleteFunction($event, i)"
        />
      </div>
      <div>
        <div>Monthly GWP <span class="italic text-sm text-red-700">*</span></div>
        <div class="p-inputgroup h-10 mb-3">
          <InputText
            v-model="newBusinessModalData['GWP']"
            :class="{
              'p-invalid': newBusinessModalData['GWP'] != '' && !isFinite(newBusinessModalData['GWP']),
            }"
            class="radius-0"
            @input="checkGWP"
          />
          <span class="p-inputgroup-addon">{{ currency }}</span>
        </div>
        <span v-if="newBusinessModalData['GWP'] != '' && !isFinite(newBusinessModalData['GWP'])" class="text-red-500"
          >Please enter a valid GWP value.</span
        >
      </div>
      <div v-for="(item, index) in parameters['commission']" :key="index">
        <div>{{ formattedCommission[index] }} <span class="italic text-sm text-red-700">*</span></div>
        <div class="p-inputgroup h-10 mb-3">
          <InputText
            v-model="newBusinessModalData[item]"
            :class="{
              'p-invalid': newBusinessModalData[item] != '' && !isFinite(newBusinessModalData[item]),
            }"
            class="radius-0"
            @input="checkGWP"
          />
          <span class="p-inputgroup-addon">{{ currency }}</span>
        </div>
        <span v-if="newBusinessModalData['GWP'] != '' && !isFinite(newBusinessModalData['GWP'])" class="text-red-500"
          >Please enter a valid GWP value.</span
        >
      </div>
      <div v-for="com in parameters.Commission" :key="com">
        <div>
          {{ com.replace('COM_', '') + ' Commission' }}
          <span class="italic text-sm text-red-700">*</span>
        </div>
        <div class="p-inputgroup h-10 mb-3">
          <InputText
            v-model="newBusinessModalData[com]"
            mode="decimal"
            :min-fraction-digits="0"
            :max-fraction-digits="1"
            @input="checkCommission()"
          />
          <span class="p-inputgroup-addon">%</span>
        </div>
      </div>
      <div v-if="isAllValidated[2] == 0" class="my-5 w-full p-8 bg-red-200 text-red-700 flex flex-row">
        <div class="mr-2">
          <i class="pi pi-times-circle" style="font-size: 1.5rem"></i>
        </div>
        <div>Total commission should be between 0% and 100%</div>
      </div>
      <div class="flex flex-row mb-3">
        <div class="mr-2">
          <div>Start date <span class="italic text-sm text-red-700">*</span></div>
          <Calendar
            v-model="newBusinessModalData['start_date']"
            view="month"
            date-format="M-yy"
            class="h-10"
            :manual-input="false"
            @date-select="checkDates()"
          />
        </div>

        <div>
          <div>End date <span class="italic text-sm text-red-700">*</span></div>
          <Calendar
            v-model="newBusinessModalData['end_date']"
            view="month"
            date-format="M-yy"
            class="h-10"
            :manual-input="false"
            @date-select="checkDates()"
          />
        </div>
      </div>
      <div
        v-if="newBusinessModalData['start_date'] && newBusinessModalData['end_date'] && isAllValidated[3] == 0"
        class="my-5 w-full p-8 bg-red-200 text-red-700 flex flex-row"
      >
        <div class="mr-2">
          <i class="pi pi-times-circle" style="font-size: 1.5rem"></i>
        </div>
        <div>Invalid Start Date or End Date</div>
      </div>
      <div class="flex flex-row mb-3">
        <div class="mr-2 w-1/2">
          <div>Attritional LR <span class="italic text-sm text-red-700">*</span></div>
          <div class="p-inputgroup h-10">
            <InputText
              v-model="newBusinessModalData['ATTRITIONAL']"
              :class="{
                'p-invalid':
                  newBusinessModalData['ATTRITIONAL'] != '' && !isFinite(newBusinessModalData['ATTRITIONAL']),
              }"
              mode="decimal"
              :min-fraction-digits="0"
              :max-fraction-digits="1"
              @input="checkClaimsNature()"
            />
            <span class="p-inputgroup-addon">%</span>
          </div>
        </div>

        <div class="w-1/2">
          <div>Elasticity <span class="italic text-sm text-red-700">*</span></div>
          <InputText
            v-model="newBusinessModalData['ELASTICITY']"
            :class="{
              'p-invalid': newBusinessModalData['ELASTICITY'] != '' && !isFinite(newBusinessModalData['ELASTICITY']),
            }"
            mode="decimal"
            :min-fraction-digits="0"
            :max-fraction-digits="1"
            class="h-10 w-full"
            @input="checkElasticity"
          />
        </div>
      </div>
      <div class="flex flex-row mb-3">
        <div v-for="(item, index) in parameters['claims_nature'].slice(1)" :key="index">
          <div>{{ formattedClaimsNature[index + 1] }} <span class="italic text-sm text-red-700">*</span></div>
          <div class="p-inputgroup h-10">
            <InputText
              v-model="newBusinessModalData[item]"
              :class="{
                'p-invalid': newBusinessModalData[item] != '' && !isFinite(newBusinessModalData[item]),
              }"
              mode="decimal"
              :min-fraction-digits="0"
              :max-fraction-digits="1"
              @input="checkClaimsNature()"
            />
            <span class="p-inputgroup-addon">%</span>
          </div>
        </div>
      </div>
    </div>
    <span class="dialog-footer">
      <el-button @click="onCloseModal">Cancel</el-button>
      <el-button type="primary" @click="configureEarningRatioDialog = true">Configure Earning Ratio</el-button>
      <el-button :disabled="!isAllValidated.every((e: any) => e == 1)" type="primary" @click="onConfirm"
        >Confirm</el-button
      >
    </span>
  </el-dialog>
</template>
<style scoped>
.edited {
  color: red;
}

.labeltd {
  width: 33%;
  background-color: rgb(255, 255, 255);
}

.modalContentSmall {
  width: 33%;
  text-align: center;
  background-color: rgb(255, 255, 255);
}

.hoverColor {
  background-color: #ffeeee;
}

.modal-bu:hover {
  color: #109cff;
}

.png-teal:hover {
  filter: invert(63%) sepia(42%) saturate(423%) hue-rotate(105deg) brightness(92%) contrast(93%);
}

.png-gray {
  filter: invert(69%) sepia(0%) saturate(0%) hue-rotate(220deg) brightness(89%) contrast(89%);
}

.projection {
  background-color: black;
}

.p-datatable-thead {
  color: rgb(85, 182, 145);
}

.textdarea:focus {
  background-color: lightblue;
}

@keyframes change-color {
  0% {
    background-color: #5555ff;
  }

  100% {
    background-color: #eeeeff;
  }
}

.flagChangeAnimation {
  animation-name: change-color;
  animation-duration: 1s;
  animation-iteration-count: 1;
}

.flagChange {
  background-color: #eeeeff;
}

.bghover::hover {
  background-color: #eef8f4;
  width: fit-content;
}

.p-autocomplete {
  width: 100%;
}

.p-inputgroup-addon:first-child,
.p-inputgroup button:first-child,
.p-inputgroup input:first-child,
.p-inputgroup > .p-inputwrapper:first-child,
.p-inputgroup > .p-inputwrapper:first-child > .p-inputtext {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.p-inputtext,
.p-component,
.p-inputnumber-input {
  border-top-left-radius: 0rem;
  border-bottom-left-radius: 0rem;
}

.p-inputgroup-addon:last-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
</style>

export function tableToArray(table) {
  console.log(table);
  const rows = table.querySelectorAll('tr');
  return Array.from(rows).map((tr) => {
    return Array.from(tr.querySelectorAll('td, th')).map((td, columnIndex) => {
      const type = getType(td.innerText, columnIndex);
      const value = parseData(td.innerText, type);
      const classes = td.classList;
      return { value, type, classes };
    });
  });
}

function getType(data, columnIndex) {
  if (columnIndex === 0) {
    return 'string'; // Treat the first column as text
  }
  // Remove commas and check if it's a valid number
  const unformattedNumber = data.replace(/,/g, '');
  if (!isNaN(unformattedNumber) && unformattedNumber.trim() !== '') {
    return 'number';
  }
  if (data.endsWith('%')) {
    return 'percentage';
  }
  return 'string';
}

function parseData(data, type) {
  switch (type) {
    case 'number':
      return parseFloat(data.replace(/,/g, ''));
    case 'percentage':
      return parseFloat(data.replace('%', '')) / 100;
    default:
      return data;
  }
}

export function toExcelCellRef(row, col) {
  // Convert column index to letter(s)
  let colRef = '';
  let dividend = col;
  let modulo;

  while (dividend > 0) {
    modulo = (dividend - 1) % 26;
    colRef = String.fromCharCode(65 + modulo) + colRef;
    dividend = Math.floor((dividend - modulo) / 26);
  }

  // Combine with row index
  return colRef + row;
}

<script setup lang="ts">
import { ref, onMounted, onUnmounted } from 'vue';
import { useGlobalStore } from '@/stores/global';
import { usePortfolioStore } from '@/stores/portfolio';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import * as am5 from '@amcharts/amcharts5/index';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import type { ModelParametersType } from '@/types/portfolio';

const globalStore = useGlobalStore();
const portfolioStore = usePortfolioStore();

const monthConst = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

function saveSeasonality() {
  if (parameters.value.length == 4) {
    const result = seasonality.value.map((subArray) => subArray.join(';'));
    parameters.value[2] = result;
    globalStore.postModelParameters(parameters.value);
  }
  globalStore.setSeasonalityModal(false);
}

function onCloseModal() {
  globalStore.setSeasonalityModal(false);
}

interface SeasonalityData {
  Month: string;
  visits: number;
}

const parameters = ref<ModelParametersType>(['', 0, [], []]);
const parametersCol = ref([]);
const seasonality = ref<number[][]>([]);

const activeSeasonalityTab = ref(0);

async function fetchParameters() {
  const [rows, cols] = await globalStore.getModelParameters();
  parameters.value = rows as unknown as ModelParametersType;
  parametersCol.value = cols;

  for (const amountName in portfolioStore.parameters['claims_nature']) {
    seasonality.value.push(parameters.value[2][amountName].split(';').map((item) => parseFloat(item)));
  }
  createSeasChart();
}

let root: { [key: string]: am5.Root } | null = {};
let charts: { [key: string]: am5xy.XYChart } | null = {};
let categoryAxis: { [key: string]: am5xy.CategoryAxis<am5xy.AxisRendererX> } | null = {};
let valueAxis: { [key: string]: am5xy.ValueAxis<am5xy.AxisRendererY> } | null = {};
let series: { [key: string]: am5xy.ColumnSeries } | null = {};

onUnmounted(() => {
  for (const amountName of portfolioStore.parameters['claims_nature']) {
    if (root && root[amountName]) {
      root[amountName].dispose();
    }
  }

  root = null;
  charts = null;
  categoryAxis = null;
  valueAxis = null;
  series = null;
});

function createSeasChart() {
  for (const amountIdx in portfolioStore.parameters['claims_nature']) {
    const amountName = portfolioStore.parameters['claims_nature'][amountIdx];
    if (!root) root = {};
    if (!charts) charts = {};
    if (!categoryAxis) categoryAxis = {};
    if (!valueAxis) valueAxis = {};
    if (!series) series = {};
    root[amountName] = am5.Root.new(amountName + 'seaschart');

    const myTheme = am5.Theme.new(root[amountName]);

    myTheme.rule('AxisLabel', ['minor']).setAll({
      dy: 1,
    });

    myTheme.rule('AxisLabel').setAll({
      fontSize: '0.9em',
    });

    root[amountName].setThemes([am5themes_Animated.new(root[amountName])]);

    charts[amountName] = root[amountName].container.children.push(am5xy.XYChart.new(root[amountName], {}));

    let cursor = charts[amountName].set(
      'cursor',
      am5xy.XYCursor.new(root[amountName], {
        behavior: 'none',
      })
    );
    cursor.lineY.set('visible', false);

    categoryAxis[amountName] = charts[amountName].xAxes.push(
      am5xy.CategoryAxis.new(root[amountName], {
        categoryField: 'Month',
        renderer: am5xy.AxisRendererX.new(root[amountName], {
          minorGridEnabled: true,
          minorLabelsEnabled: true,
        }),
        tooltip: am5.Tooltip.new(root[amountName], {}),
      })
    );

    categoryAxis[amountName].data.setAll(convertSeasonalityValueToChartValue(parseInt(amountIdx)));

    valueAxis[amountName] = charts[amountName].yAxes.push(
      am5xy.ValueAxis.new(root[amountName], {
        renderer: am5xy.AxisRendererY.new(root[amountName], {}),
      })
    );
    // Create series
    series[amountName] = charts[amountName].series.push(
      am5xy.ColumnSeries.new(root[amountName], {
        name: 'Series',
        xAxis: categoryAxis[amountName],
        yAxis: valueAxis[amountName],
        valueYField: 'visits',
        categoryXField: 'Month',
        tooltip: am5.Tooltip.new(root[amountName], {
          labelText: '{visits}',
        }),
      })
    );

    series[amountName].columns.template.setAll({ cornerRadiusTL: 5, cornerRadiusTR: 5, strokeOpacity: 0 });
    series[amountName].columns.template.adapters.add('fill', function (fill, target) {
      return charts && charts[amountName] && series && series[amountName]?.columns
        ? charts[amountName].get('colors')?.getIndex(series[amountName].columns.indexOf(target))
        : undefined;
    });

    series[amountName].columns.template.adapters.add('stroke', function (stroke, target) {
      return charts?.[amountName]?.get('colors')?.getIndex(series?.[amountName]?.columns.indexOf(target) ?? 0);
    });

    series[amountName].data.setAll(convertSeasonalityValueToChartValue(parseInt(amountIdx)));
    // // Animations
    series[amountName].appear(1000);
    charts[amountName].appear(1000, 100);
  }
}

function onChangeSeasonality(indexKey: number) {
  let sum = seasonality.value[activeSeasonalityTab.value].reduce(
    (accumulator, currentValue) => accumulator + currentValue,
    0
  );
  if (indexKey == 11) {
    if (sum < 12) {
      let index = 0;
      let remain = 12 - sum;
      while (remain != 0 && index < 12) {
        if (indexKey == index) {
          index += 1;
          continue;
        }
        if (seasonality.value[activeSeasonalityTab.value][index] < 12) {
          seasonality.value[activeSeasonalityTab.value][index] += remain;
          seasonality.value[activeSeasonalityTab.value][index] = parseFloat(
            seasonality.value[activeSeasonalityTab.value][index].toFixed(2)
          );
          if (seasonality.value[activeSeasonalityTab.value][index] <= 12) {
            remain = 0;
            sum = 12;
          } else {
            remain = seasonality.value[activeSeasonalityTab.value][index] - 12;
            seasonality.value[activeSeasonalityTab.value][index] = 100;
          }
        }
        index += 1;
      }
    } else {
      let index = 0;
      let remain = sum - 12;
      while (remain != 0 && index < 12) {
        if (indexKey == index) {
          index += 1;
          continue;
        }
        if (seasonality.value[activeSeasonalityTab.value][index] > 0) {
          seasonality.value[activeSeasonalityTab.value][index] -= remain;
          seasonality.value[activeSeasonalityTab.value][index] = parseFloat(
            seasonality.value[activeSeasonalityTab.value][index].toFixed(2)
          );
          if (seasonality.value[activeSeasonalityTab.value][index] >= 0) {
            remain = 0;
            sum = 12;
          } else {
            remain = -seasonality.value[activeSeasonalityTab.value][index];
            seasonality.value[activeSeasonalityTab.value][index] = 0;
          }
        }
        index += 1;
      }
    }
  } else {
    if (sum < 12) {
      let index = 11;
      let remain = 12 - sum;
      while (remain != 0 && index < 12) {
        if (indexKey == index) {
          index -= 1;
          continue;
        }
        if (seasonality.value[activeSeasonalityTab.value][index] < 12) {
          seasonality.value[activeSeasonalityTab.value][index] += remain;
          seasonality.value[activeSeasonalityTab.value][index] = parseFloat(
            seasonality.value[activeSeasonalityTab.value][index].toFixed(2)
          );
          if (seasonality.value[activeSeasonalityTab.value][index] <= 12) {
            remain = 0;
            sum = 12;
          } else {
            remain = seasonality.value[activeSeasonalityTab.value][index] - 12;
            seasonality.value[activeSeasonalityTab.value][index] = 100;
          }
        }
        index -= 1;
      }
    } else {
      let index = 11;
      let remain = sum - 12;
      while (remain != 0 && index < 12) {
        if (indexKey == index) {
          index -= 1;
          continue;
        }
        if (seasonality.value[activeSeasonalityTab.value][index] > 0) {
          seasonality.value[activeSeasonalityTab.value][index] -= remain;
          seasonality.value[activeSeasonalityTab.value][index] = parseFloat(
            seasonality.value[activeSeasonalityTab.value][index].toFixed(2)
          );
          if (seasonality.value[activeSeasonalityTab.value][index] >= 0) {
            remain = 0;
            sum = 12;
          } else {
            remain = -seasonality.value[activeSeasonalityTab.value][index];
            seasonality.value[activeSeasonalityTab.value][index] = 0;
          }
        }
        index -= 1;
      }
    }
  }

  updateChartValue();
}

function resetSeasonality() {
  for (const idx in seasonality.value[activeSeasonalityTab.value]) {
    seasonality.value[activeSeasonalityTab.value][idx] = 1;
  }

  updateChartValue();
}

function updateChartValue() {
  if (categoryAxis && categoryAxis[portfolioStore.parameters['claims_nature'][activeSeasonalityTab.value]]) {
    categoryAxis[portfolioStore.parameters['claims_nature'][activeSeasonalityTab.value]].data.setAll(
      convertSeasonalityValueToChartValue(activeSeasonalityTab.value)
    );
  }
  if (series && series[portfolioStore.parameters['claims_nature'][activeSeasonalityTab.value]]) {
    series[portfolioStore.parameters['claims_nature'][activeSeasonalityTab.value]].data.setAll(
      convertSeasonalityValueToChartValue(activeSeasonalityTab.value)
    );
  }
}

function convertSeasonalityValueToChartValue(amountIdx: number) {
  let seasonalityChart: SeasonalityData[] = [];
  for (const i in seasonality.value[amountIdx]) {
    seasonalityChart.push({
      Month: monthConst[i],
      visits: seasonality.value[amountIdx][i],
    });
  }
  return seasonalityChart;
}

onMounted(() => {
  fetchParameters();
});

function toTitleCase(str: string) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}
</script>
<template>
  <el-dialog
    :before-close="onCloseModal"
    :model-value="globalStore.isSeasonalityModal"
    title="Seasonality"
    width="950px"
  >
    <TabView v-model:activeIndex="activeSeasonalityTab">
      <TabPanel
        v-for="amountName in portfolioStore.parameters['claims_nature']"
        :key="amountName"
        :header="toTitleCase(amountName)"
      >
        <div class="flex flex-row">
          <div>
            <div v-for="(val, idx) in monthConst" :key="idx" class="flex flex-row my-2 hover:scale-110">
              <div class="w-10 bg-gray-100 text-center border-gray-200 border text-sm text-gray-500">{{ val }}</div>
              <el-input-number
                v-if="seasonality[activeSeasonalityTab]"
                v-model="seasonality[activeSeasonalityTab][idx]"
                controls-position="right"
                size="small"
                :precision="3"
                :step="0.01"
                :max="12"
                :min="0"
                @change="onChangeSeasonality(idx)"
              />
            </div>
          </div>
          <div :id="amountName + 'seaschart'" style="height: 440px; width: 800px"></div>
        </div>
        <div class="absolute right-5">
          <el-button type="danger" plain @click="resetSeasonality">Reset</el-button>
          <el-button plain @click="globalStore.setSeasonalityModal(false)">Close</el-button>
          <el-button type="primary" plain @click="saveSeasonality">Confirm</el-button>
        </div>
      </TabPanel>
    </TabView>
  </el-dialog>
</template>

<script setup lang="ts">
import { ref, nextTick, onMounted, computed, watch } from 'vue';
import { useActuarialStore } from '@/stores/actuarial';
import { ElMessage } from 'element-plus';
import Textarea from 'primevue/textarea';
import InputText from 'primevue/inputtext';
import { useGlobalStore } from '@/stores/global';

type IBNRFormDataType = {
  period: string;
  ibnr: string;
  ultimate: string;
};

const actuarialStore = useActuarialStore();

function onCloseIBNRModal() {
  actuarialStore.onChangeIBNRModal(false);
}

const headerStyle = {
  textAlign: 'center',
  font: '12px graphie',
  bgColor: '#f9f9f9',
};

const manualValueError = () => {
  ElMessage.error('Invalid input.');
};

const ibnrName = ref('');

function onChangedPasteValue(e) {
  if (e.field == 'ibnr') {
    let curr: number | string = 1;
    for (let i = ibnrCopyPaste.value.length - 1; i >= 0; i--) {
      if (!isFinite(parseFloat(removeCommas(ibnrCopyPaste.value[i]['ibnr'])))) {
        ibnrCopyPaste.value = structuredClone(prevIBNRCopyPaste.value);
        manualValueError();
        break;
      }
      curr =
        parseFloat(removeCommas(ibnrCopyPaste.value[i]['ibnr'])) +
        parseFloat(removeCommas(prevIBNRCopyPaste.value[i]['ultimate']));
      curr = numberWithCommas(curr);
      ibnrCopyPaste.value[i]['ibnr'] = numberWithCommas(parseFloat(removeCommas(ibnrCopyPaste.value[i]['ibnr'])));
      ibnrCopyPaste.value[i]['ultimate'] = curr;
    }
  } else {
    let curr: number | string = 1;
    for (let i = ibnrCopyPaste.value.length - 1; i >= 0; i--) {
      if (!isFinite(parseFloat(removeCommas(ibnrCopyPaste.value[i]['ultimate'])))) {
        ibnrCopyPaste.value = structuredClone(prevIBNRCopyPaste.value);
        console.log(ibnrCopyPaste.value[i]['ultimate']);
        manualValueError();
        break;
      }
      curr =
        parseFloat(removeCommas(ibnrCopyPaste.value[i]['ultimate'])) -
        parseFloat(removeCommas(prevIBNRCopyPaste.value[i]['ultimate']));
      curr = numberWithCommas(curr);
      ibnrCopyPaste.value[i]['ibnr'] = curr;
      ibnrCopyPaste.value[i]['ultimate'] = numberWithCommas(
        parseFloat(removeCommas(ibnrCopyPaste.value[i]['ultimate']))
      );
    }
  }
}

async function addIBNRmethod() {
  if (ibnrName.value == '') {
    ElMessage.error('Please enter a name for the IBNR');
    return;
  }

  if (ibnrName.value.includes(';')) {
    ElMessage.error('Name cannot contain semicolon');
    return;
  }

  let constantArray: number[] = [];
  const name = ibnrName.value + ';' + ibnrDesc.value;
  for (const i of ibnrCopyPaste.value) {
    constantArray.push(parseFloat(removeCommas(i['ultimate'])));
  }
  actuarialStore.addIBNR(name, constantArray);
  onCloseIBNRModal();
}

const columnStyle = {
  textAlign: 'center',
  font: '12px graphie',
  bgColor: '#f9f9f9',
};
const cgridRender = ref(false);

onMounted(() => {
  createIBNRCopyPasteData();
  nextTick(() => {
    cgridRender.value = true;
  });
});

const selectedIBNRValue = ref('IBNR');
const ibnrDesc = ref('');
const ibnrCopyPaste = ref<IBNRFormDataType[]>([]);
const prevIBNRCopyPaste = ref<IBNRFormDataType[]>([]);
const ultTableData = computed(() => actuarialStore.ult_table_data);

function createIBNRCopyPasteData() {
  let temp: IBNRFormDataType[] = [];
  for (let i = 0; i < Object.values(ultTableData.value.MONTH).length; i++) {
    let tempObj: IBNRFormDataType = {
      period: Object.values(ultTableData.value.MONTH)[i] as string,
      ibnr: '0',
      ultimate: numberWithCommas(Object.values(ultTableData.value['Incurred Claims'])[i] as number),
    };
    temp.push(tempObj);
  }

  ibnrCopyPaste.value = temp;
  prevIBNRCopyPaste.value = structuredClone(temp);
}

const globalStore = useGlobalStore();
const currency = computed(() => globalStore.currency.currencyFormat.currency);

watch(
  () => globalStore.currency,
  () => {
    createIBNRCopyPasteData();
  }
);

function numberWithCommas(x: number) {
  return x
    .toFixed(globalStore.currency.currencyFormat.precision)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

function removeCommas(x: string) {
  return x.replace(/,/g, '');
}
</script>
<template>
  <el-dialog
    :model-value="actuarialStore.IBNRModal"
    :before-close="onCloseIBNRModal"
    width="650px"
    title="Import External IBNR/Ultimate Numbers"
  >
    <div class="w-full">
      <div class="mb-5 grid grid-cols-2">
        <el-button-group>
          <el-button
            class="w-30"
            :type="selectedIBNRValue == 'IBNR' ? 'primary' : ''"
            @click="selectedIBNRValue = 'IBNR'"
            >IBNR</el-button
          >
          <el-button
            class="w-30"
            :type="selectedIBNRValue == 'Ultimate' ? 'primary' : ''"
            @click="selectedIBNRValue = 'Ultimate'"
            >Ultimate</el-button
          >
        </el-button-group>
      </div>
      <div>
        <div>Import Name:</div>
        <div class="w-full mb-3">
          <InputText v-model="ibnrName" data-testid="new-ibnr-name" class="h-10 w-full" />
        </div>
        <div>IBNR Description</div>
        <div class="w-full mb-3">
          <Textarea v-model="ibnrDesc" class="w-full" :auto-resize="true" rows="1" cols="30"></Textarea>
        </div>
      </div>
      <el-button class="pl-5 left-0 text-gray-500 item mx-5 mt-2" type="info" link
        ><i>Amounts are in {{ currency }}</i></el-button
      >
      <div style="height: 500px">
        <c-grid v-if="cgridRender" :data="ibnrCopyPaste" :allow-range-paste="true" @changed-value="onChangedPasteValue">
          <c-grid-column field="period" width="50%" :column-style="columnStyle" :header-style="headerStyle">
            Period
          </c-grid-column>
          <c-grid-input-column
            v-if="selectedIBNRValue == 'IBNR'"
            field="ibnr"
            width="50%"
            :column-style="columnStyle"
            :header-style="headerStyle"
          >
            IBNR
          </c-grid-input-column>
          <c-grid-input-column
            v-if="selectedIBNRValue == 'Ultimate'"
            field="ultimate"
            width="50%"
            :column-style="columnStyle"
            :header-style="headerStyle"
          >
            Ultimate
          </c-grid-input-column>
        </c-grid>
      </div>
    </div>

    <template #footer>
      <span class="dialog-footer">
        <el-button type="info" @click="actuarialStore.onChangeIBNRModal(false)">Cancel</el-button>
        <el-button type="primary" data-testid="new-ibnr-submit" @click="addIBNRmethod()">Add</el-button>
      </span>
    </template>
  </el-dialog>
</template>

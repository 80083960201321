<script setup lang="ts">
/**
 * TODO - ADD TYPE DEFINITIONS
 */
import { watch, ref, onMounted, computed } from 'vue';
import { useActuarialStore, COHORT_CONSTANT, CLAIMS_REF, CLAIMS_REF_TOOLTIP } from '@/stores/actuarial';
import ultTableUltClaimsCalculation from '@/views/Actuarial/Triangulations/functions/ultTableUltClaimsCalculation';
import { useGlobalStore } from '@/stores/global';
import RegionLoaderComponent from '@/components/RegionLoaderComponent.vue';
import { Unlock, Lock } from '@element-plus/icons-vue';
import { ULT_TABLE_ID } from '@/views/Actuarial/Triangulations/constants';
import { useElementVisibility } from '@vueuse/core';
const locked = ref(false);
const locked_2 = ref(false);

const global_store = useGlobalStore();
const show_ibnr_allocation = ref(false);
const selectedGWPUnit = computed<string>(() => global_store.currency.currencyFormat.selectedGWPUnit);
const GWPUnits: any = computed(() => global_store.currency.GWPUnits);
const currency: any = computed(() => global_store.currency.currencyFormat.currency);
const actuarial_store = useActuarialStore();

const allocation_methods = ['Incurred', 'Paid', 'A-priori', 'GEP'];
function totalGEP() {
  return numberWithCommas(<any>Object.values(ult_table_data.value['GEP_AMOUNT']).reduce((x, y) => x + y, 0), false);
}

function totalPaid() {
  return numberWithCommas(<any>Object.values(ult_table_data.value['Paid Claims']).reduce((x, y) => x + y, 0), false);
}

function totalOS() {
  return numberWithCommas(
    <any>Object.values(ult_table_data.value['Incurred Claims']).reduce((x, y) => x + y, 0) -
      <any>Object.values(ult_table_data.value['Paid Claims']).reduce((x, y) => x + y, 0),
    false
  );
}

function totalIncurred() {
  return numberWithCommas(
    <any>Object.values(ult_table_data.value['Incurred Claims']).reduce((x, y) => x + y, 0),
    false
  );
}

// function totalCountOfClaims() {
//     return numberWithCommas(<any>Object.values(incurred_paid_amount.value['AMOUNT']).reduce((x, y) => x + y) ,false)
// }

function totalUltTable(claims_type: string) {
  return numberWithCommas(<any>Object.values(ult_table_data.value[claims_type]).reduce((x, y) => x + y, 0), false);
}

function totalSelected() {
  let sum = Object.values(ult_table_data.value['selected_ult'])
    .map((x, index) => ult_table_data.value[allClaimsTypeColumn.value[x]][index])
    .reduce((a, s) => a + s, 0);
  return numberWithCommas(sum, false);
}

function totalSelectedULR() {
  let sum = Object.values(ult_table_data.value['selected_ult'])
    .map((x, index) => ult_table_data.value[allClaimsTypeColumn.value[x]][index])
    .reduce((a, s) => a + s, 0);
  let totalGEP = Object.values(ult_table_data.value['GEP_AMOUNT']).reduce((a, s) => a + s, 0);
  return ((sum / totalGEP) * 100).toFixed(1) + '%';
}

function IBNRTotal() {
  let sum = Object.values(ult_table_data.value['selected_ult'])
    .map((x, index) => ult_table_data.value[allClaimsTypeColumn.value[x]][index])
    .reduce((a, s) => a + s, 0);
  let sum_incurred = Object.values(ult_table_data.value['Incurred Claims']).reduce((a, s) => a + s, 0);
  return numberWithCommas(sum - sum_incurred, false);
}

function IBNRLessUnIncurredTotal() {
  let sum = Object.values(ult_table_data.value['selected_ult'])
    .map((x, index) => ult_table_data.value[allClaimsTypeColumn.value[x]][index])
    .reduce((a, s) => a + s, 0);
  let sum_incurred = Object.values(ult_table_data.value['Incurred Claims']).reduce((a, s) => a + s, 0);
  let sum_unincurred = Object.values(ult_table_data.value['UNEARNED_APRIORI']).reduce((a, s) => a + s, 0);
  return numberWithCommas(sum - sum_incurred - sum_unincurred, false);
}

function UnIncurredTotal() {
  let sum_unincurred = Object.values(ult_table_data.value['UNEARNED_APRIORI']).reduce((a, s) => a + s, 0);
  return numberWithCommas(sum_unincurred, false);
}

function totalIBNRRatio() {
  let sum = Object.values(ult_table_data.value['selected_ult'])
    .map((x, index) => ult_table_data.value[allClaimsTypeColumn.value[x]][index])
    .reduce((a, s) => a + s, 0);
  let sum_incurred = Object.values(ult_table_data.value['Incurred Claims']).reduce((a, s) => a + s, 0);
  let totalGEP = Object.values(ult_table_data.value['GEP_AMOUNT']).reduce((a, s) => a + s, 0);
  return (((sum - sum_incurred) / totalGEP) * 100).toFixed(1) + '%';
}

function numberWithCommas(x: number, isValue: boolean) {
  if (!isValue) {
    return (x / parseFloat(GWPUnits.value[selectedGWPUnit.value][0]))
      .toFixed(global_store.currency.currencyFormat.precision)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  } else {
    return parseFloat(
      (x / parseFloat(GWPUnits.value[selectedGWPUnit.value][0])).toFixed(global_store.currency.currencyFormat.precision)
    );
  }
}
const allocation_mapping = {
  0: 0,
  1: 0,
  2: 2,
  3: 0,
  4: 0,
  5: 2,
  6: 2,
};
// const allocation_mapping = {
//     0: 1,
//     1: 0,
//     2: 2,
//     3: 1,
//     4: 0,
//     5: 2,
//     6: 2
//   };

function onSelectUltimate(event, row: number, selection_idx: number, selected: boolean) {
  let allocation_selection = -1;
  if (selection_idx <= 6) {
    if (ult_table_data.value['Incurred Claims'][row] == 0) {
      allocation_selection = 2;
    } else {
      allocation_selection = allocation_mapping[selection_idx];
    }
  }
  if (event.ctrlKey || event.metaKey) {
    for (let i = row; i < Object.keys(ult_table_data.value['selected_ult']).length; i++) {
      ult_table_data.value['selected_ult'][i] = selection_idx;

      if (allocation_selection != -1) {
        if (ult_table_data.value['Incurred Claims'][i] == 0) {
          allocation_selection = 2;
        } else {
          allocation_selection = allocation_mapping[selection_idx];
        }
        ult_table_data.value['allocation'][i] = allocation_selection;
      }
    }
  } else if (event.shiftKey) {
  } else {
    ult_table_data.value['selected_ult'][row] = selection_idx;
    if (allocation_selection != -1) {
      ult_table_data.value['allocation'][row] = allocation_selection;
    }
  }
  // if(actuarial_store.trianglesLoading){
  //     actuarial_store.ultimateSelectionStackAppend(1)
  // } else {
  actuarial_store.isUltimateSelectionChanged = true;
  // }
}

function changeAllocation(index: number, incurred: number) {
  if (incurred != 0) {
    ult_table_data.value['allocation'][index] = 2 - ult_table_data.value['allocation'][index];
    if (actuarial_store.trianglesLoading) {
      actuarial_store.ultimateSelectionStackAppend(1);
    } else {
      actuarial_store.onChangeultimateSelection();
    }
  } else {
    actuarial_store.openErrorMsg("Incurred is Zero, you can't allocate IBNR using Incurred");
  }
}

function selectedULR(selected: number, gep: number) {
  if (gep == 0) {
    return 0;
  }
  return (selected / gep) * 100;
}

function addIBNRModal() {
  actuarial_store.onChangeIBNRModal(true);
}

async function deleteCustomUltClaims(idx: number) {
  const claimsIndex = idx + 2;
  const customIndex = idx - 5;
  for (const i in actuarial_store.ult_table_data['selected_ult']) {
    if (actuarial_store.ult_table_data['selected_ult'][i] == claimsIndex) {
      actuarial_store.ult_table_data['selected_ult'][i] = 4;
    }

    if (actuarial_store.ult_table_data['selected_ult'][i] > claimsIndex) {
      actuarial_store.ult_table_data['selected_ult'][i] -= 1;
    }
  }
  actuarial_store.customUltTableTitle.splice(customIndex, 1);
  actuarial_store.customUltTableDesc.splice(customIndex, 1);

  actuarial_store.ultLoading = true;
  await actuarial_store.onChangeultimateSelection();
  await actuarial_store.removeIBNR(customIndex);
  actuarial_store.ultLoading = false;
}

const ult_table_data = computed(() => actuarial_store.ult_table_data);

const tooltip = computed(() => [...CLAIMS_REF_TOOLTIP.slice(2), ...actuarial_store.customUltTableDesc]);

const allClaimsTypeColumnWihoutPaidAndClaims = computed(() => {
  return [...CLAIMS_REF.slice(2), ...actuarial_store.customUltTableTitle];
});

const allClaimsTypeColumn = computed(() => {
  return [...CLAIMS_REF, ...actuarial_store.customUltTableTitle];
});

const showStatus = ref(true);

const triangulationsUltimateTableElement = ref();
const triangulationsLinkRatiosVisibilityWatch = useElementVisibility(triangulationsUltimateTableElement);

watch(triangulationsLinkRatiosVisibilityWatch, () => {
  actuarial_store.saveTriangulationsData();
});
</script>
<template>
  <div v-if="ult_table_data">
    <div ref="triangulationsUltimateTableElement">
      <div :class="locked ? 'locked' : ''">
        <div class="w-full bg-sybil-charcoal text-gray-300 mt-5 relative">
          <b class="text-sm px-2 py-1">Ultimate Selection</b
          ><el-button
            class="pl-5 left-0 text-gray-500 cursor-pointer item mx-2"
            type="info"
            link
            @click="global_store.setCurrencyModal(true)"
            ><i>Amounts are in {{ currency + GWPUnits[selectedGWPUnit][1] }}</i></el-button
          ><span
            class="absolute right-2 top-1 cursor-pointer"
            data-testid="lock-unlock-ibnr"
            @click="(event) => (event.shiftKey ? (show_ibnr_allocation = !show_ibnr_allocation) : (locked = !locked))"
            ><el-icon v-if="locked"><Lock /></el-icon><el-icon v-else><Unlock /></el-icon
          ></span>
        </div>
        <div class="relative">
          <RegionLoaderComponent v-if="actuarial_store.ultLoading" />
          <div
            v-if="ult_table_data"
            class="table-panel relative shadow-md"
            :class="{ tableOff: !showStatus }"
            :style="{ overflow: actuarial_store.trianglesLoading ? 'scroll' : 'scroll' }"
          >
            <table
              :id="ULT_TABLE_ID"
              data-testid="ult-table"
              class="table-panel bg-white shadow"
              style="border-spacing: 0"
            >
              <thead class="sticky top-0 z-20">
                <tr>
                  <th class="fixWidth header-teal sticky left-0 z-20" rowspan="2">
                    {{ ['Accident', 'Underwriting'][actuarial_store.actuarial_accident_underwriting] }}
                    {{ COHORT_CONSTANT[actuarial_store.cohort] }}
                  </th>
                  <th class="fixWidth text-green-700 header-teal" rowspan="2">
                    {{ ['GEP', 'GWP'][actuarial_store.actuarial_accident_underwriting] }}
                  </th>
                  <th class="fixWidth text-red-500 header-teal" rowspan="2">Paid Claims</th>
                  <th class="fixWidth text-red-500 header-teal" rowspan="2">OS Claims</th>
                  <th class="fixWidth text-red-500 header-teal" rowspan="2">Incurred Claims</th>
                  <th class="fixWidth head header-teal" style="width: 200px" colspan="2">Development factors</th>
                  <th
                    class="fixWidth text-red-700 header-teal"
                    :style="{ width: 112 * allClaimsTypeColumn.length + 'px' }"
                    :colspan="allClaimsTypeColumn.length"
                  >
                    Ultimate Claims
                    <span
                      data-testid="add-new-ibnr"
                      class="text-lgm cursor-pointer hover:text-sybil-teal"
                      @click="addIBNRModal()"
                      >+</span
                    >
                  </th>
                  <th class="fixWidth text-blue-700 header-teal" rowspan="2">
                    IBNR{{ actuarial_store.actuarial_accident_underwriting ? ' + unIncurred' : '' }}
                  </th>
                  <th
                    v-if="actuarial_store.actuarial_accident_underwriting"
                    class="fixWidth text-blue-700 header-teal"
                    rowspan="2"
                  >
                    IBNR
                  </th>
                  <th
                    v-if="actuarial_store.actuarial_accident_underwriting"
                    class="fixWidth text-blue-700 header-teal"
                    rowspan="2"
                  >
                    UnIncurred
                  </th>
                  <th class="fixWidth text-blue-700 header-teal" rowspan="2">
                    IBNR{{ actuarial_store.actuarial_accident_underwriting ? ' + unIncurred ' : '' }} Ratio
                  </th>
                  <th class="fixWidth text-blue-700 header-teal" rowspan="2">IBNR Allocation Method</th>
                </tr>
                <tr>
                  <th class="fixWidth header-teal">Paid</th>
                  <th class="fixWidth header-teal">Incurred</th>

                  <th
                    v-for="(value, idx) in allClaimsTypeColumnWihoutPaidAndClaims"
                    :key="idx"
                    class="fixWidth text-red-700 header-teal"
                  >
                    <el-tooltip v-if="idx >= 5" content="Click to remove this row" placement="right" :show-after="500">
                      <a
                        data-testid="delete-custom-claims"
                        class="cursor-pointer hover:text-sybil-teal ml-0"
                        @click="deleteCustomUltClaims(idx)"
                        >-
                      </a>
                    </el-tooltip>
                    <el-tooltip :content="tooltip[idx]" placement="bottom" :show-after="500">
                      {{ value }}
                    </el-tooltip>
                  </th>

                  <th class="fixWidth text-red-800 font-bold italic header-teal">Selected</th>
                  <th class="fixWidth text-red-800 font-bold italic header-teal">Selected ULR</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(value, index) in ult_table_data['MONTH']" :key="index" data-testid="ult-month">
                  <td data-testid="ult-table-month" class="fixWidth side-teal sticky left-0 z-10">{{ value }}</td>
                  <td
                    data-testid="ult-selection-premium"
                    class="fixWidth text-green-700 panel-custom-scrollbar overflow-hidden overflow-x-scroll whitespace-nowrap"
                  >
                    <!-- GEP -->
                    {{ numberWithCommas(ult_table_data['GEP_AMOUNT'][index], false) }}
                  </td>
                  <td
                    class="fixWidth text-red-500 cursor-pointer panel-custom-scrollbar overflow-hidden overflow-x-scroll whitespace-nowrap"
                    :class="{ selected: ult_table_data['selected_ult'][index] == 0 ? true : false }"
                    data-testid="ult-paid-claims"
                    @click="(event) => onSelectUltimate(event, index, 0)"
                  >
                    {{ numberWithCommas(ult_table_data['Paid Claims'][index], false) }}
                  </td>
                  <td
                    class="fixWidth text-red-500 panel-custom-scrollbar overflow-hidden overflow-x-scroll whitespace-nowrap"
                    data-testid="ult-os-claims"
                  >
                    {{
                      numberWithCommas(
                        ult_table_data['Incurred Claims'][index] - ult_table_data['Paid Claims'][index],
                        false
                      )
                    }}
                  </td>
                  <td
                    class="fixWidth text-red-500 cursor-pointer panel-custom-scrollbar overflow-hidden overflow-x-scroll whitespace-nowrap"
                    :class="{ selected: ult_table_data['selected_ult'][index] == 1 ? true : false }"
                    data-testid="ult-incurred-claims"
                    @click="(event) => onSelectUltimate(event, index, 1)"
                  >
                    {{ numberWithCommas(ult_table_data['Incurred Claims'][index], false) }}
                  </td>
                  <td
                    data-testid="ult-table-paid-dev"
                    class="fixWidth panel-custom-scrollbar overflow-hidden overflow-x-scroll whitespace-nowrap"
                  >
                    {{ ult_table_data['paid_dev'][index].toFixed(3) }}
                  </td>
                  <td
                    data-testid="ult-table-incurred-dev"
                    class="fixWidth panel-custom-scrollbar overflow-hidden overflow-x-scroll whitespace-nowrap"
                  >
                    {{ ult_table_data['incurred_dev'][index].toFixed(3) }}
                  </td>

                  <td
                    v-for="(claims_type, idx) in allClaimsTypeColumnWihoutPaidAndClaims"
                    :key="idx"
                    data-testid="ult-table-claims"
                    class="fixWidth text-red-700 cursor-pointer panel-custom-scrollbar overflow-hidden overflow-x-scroll whitespace-nowrap"
                    :class="{
                      selected:
                        claims_type == allClaimsTypeColumn[ult_table_data['selected_ult'][index]] ? true : false,
                    }"
                    @click="
                      (event) =>
                        onSelectUltimate(
                          event,
                          index,
                          idx + 2,
                          claims_type == allClaimsTypeColumn[ult_table_data['selected_ult'][index] - 2]
                        )
                    "
                  >
                    {{ numberWithCommas(ult_table_data[claims_type][index], false) }}
                  </td>
                  <td
                    class="fixWidth text-red-800 font-bold italic panel-custom-scrollbar overflow-hidden overflow-x-scroll whitespace-nowrap"
                  >
                    {{
                      numberWithCommas(
                        ult_table_data[allClaimsTypeColumn[ult_table_data['selected_ult'][index]]][index],
                        false
                      )
                    }}
                  </td>
                  <td
                    class="fixWidth text-red-800 font-bold italic panel-custom-scrollbar overflow-hidden overflow-x-scroll whitespace-nowrap"
                  >
                    {{
                      selectedULR(
                        ult_table_data[allClaimsTypeColumn[ult_table_data['selected_ult'][index]]][index],
                        ult_table_data['GEP_AMOUNT'][index]
                      ).toFixed(1)
                    }}%
                  </td>
                  <td
                    class="fixWidth text-blue-700 panel-custom-scrollbar overflow-hidden overflow-x-scroll whitespace-nowrap"
                    data-testid="ibnr-value"
                  >
                    {{
                      numberWithCommas(
                        ult_table_data[allClaimsTypeColumn[ult_table_data['selected_ult'][index]]][index] -
                          ult_table_data['Incurred Claims'][index],
                        false
                      )
                    }}
                  </td>
                  <td
                    v-if="actuarial_store.actuarial_accident_underwriting"
                    class="fixWidth text-blue-700 panel-custom-scrollbar overflow-hidden overflow-x-scroll whitespace-nowrap bg-gray-50"
                  >
                    {{
                      numberWithCommas(
                        ult_table_data[allClaimsTypeColumn[ult_table_data['selected_ult'][index]]][index] -
                          ult_table_data['Incurred Claims'][index] -
                          ult_table_data['UNEARNED_APRIORI'][index],
                        false
                      )
                    }}
                  </td>
                  <td
                    v-if="actuarial_store.actuarial_accident_underwriting"
                    class="fixWidth text-blue-700 panel-custom-scrollbar overflow-hidden overflow-x-scroll whitespace-nowrap bg-gray-50"
                  >
                    {{ numberWithCommas(ult_table_data['UNEARNED_APRIORI'][index], false) }}
                  </td>

                  <td
                    data-testid="ibnr-ratio"
                    class="fixWidth text-blue-700 panel-custom-scrollbar overflow-hidden overflow-x-scroll whitespace-nowrap"
                  >
                    {{
                      selectedULR(
                        ult_table_data[allClaimsTypeColumn[ult_table_data['selected_ult'][index]]][index] -
                          ult_table_data['Incurred Claims'][index],
                        ult_table_data['GEP_AMOUNT'][index]
                      ).toFixed(1)
                    }}%
                  </td>
                  <td
                    class="fixWidth text-blue-700 panel-custom-scrollbar overflow-hidden overflow-x-scroll whitespace-nowrap cursor-pointer"
                    data-testid="ibnr-allocation-method"
                    @click="changeAllocation(index, ult_table_data['Incurred Claims'][index])"
                  >
                    {{ allocation_methods[ult_table_data['allocation'][index]] }}
                  </td>
                </tr>
              </tbody>
              <tfoot v-if="showStatus" class="sticky bottom-0 z-20 bg-white mb-5">
                <tr>
                  <td class="fixWidth sticky left-0 z-20 total-gray">Total</td>
                  <td
                    class="fixWidth total-gray text-green-700 panel-custom-scrollbar overflow-hidden overflow-x-scroll whitespace-nowrap"
                  >
                    {{ totalGEP() }}
                  </td>
                  <td
                    class="fixWidth total-gray text-red-500 panel-custom-scrollbar overflow-hidden overflow-x-scroll whitespace-nowrap"
                  >
                    {{ totalPaid() }}
                  </td>
                  <td
                    class="fixWidth total-gray text-red-500 panel-custom-scrollbar overflow-hidden overflow-x-scroll whitespace-nowrap"
                  >
                    {{ totalOS() }}
                  </td>
                  <td
                    class="fixWidth total-gray text-red-500 panel-custom-scrollbar overflow-hidden overflow-x-scroll whitespace-nowrap"
                  >
                    {{ totalIncurred() }}
                  </td>
                  <td class="fixWidth total-gray"></td>
                  <td class="fixWidth total-gray"></td>

                  <td
                    v-for="(claims_type, idx) in allClaimsTypeColumnWihoutPaidAndClaims"
                    :key="idx"
                    class="fixWidth total-gray text-red-700 cursor-pointer panel-custom-scrollbar overflow-hidden overflow-x-scroll whitespace-nowrap"
                  >
                    {{ totalUltTable(claims_type) }}
                  </td>

                  <td
                    class="fixWidth total-gray text-red-800 font-bold italic panel-custom-scrollbar overflow-hidden overflow-x-scroll whitespace-nowrap"
                  >
                    {{ totalSelected() }}
                  </td>
                  <td
                    class="fixWidth total-gray text-red-800 font-bold italic panel-custom-scrollbar overflow-hidden overflow-x-scroll whitespace-nowrap"
                  >
                    {{ totalSelectedULR() }}
                  </td>
                  <td
                    class="fixWidth total-gray text-blue-700 panel-custom-scrollbar overflow-hidden overflow-x-scroll whitespace-nowrap"
                  >
                    {{ IBNRTotal() }}
                  </td>
                  <td
                    v-if="actuarial_store.actuarial_accident_underwriting"
                    class="fixWidth total-gray text-blue-700 panel-custom-scrollbar overflow-hidden overflow-x-scroll whitespace-nowrap"
                  >
                    {{ IBNRLessUnIncurredTotal() }}
                  </td>
                  <td
                    v-if="actuarial_store.actuarial_accident_underwriting"
                    class="fixWidth total-gray text-blue-700 panel-custom-scrollbar overflow-hidden overflow-x-scroll whitespace-nowrap"
                  >
                    {{ UnIncurredTotal() }}
                  </td>
                  <td
                    class="fixWidth total-gray text-blue-700 panel-custom-scrollbar overflow-hidden overflow-x-scroll whitespace-nowrap"
                  >
                    {{ totalIBNRRatio() }}
                  </td>
                  <td
                    class="fixWidth total-gray text-blue-700 panel-custom-scrollbar overflow-hidden overflow-x-scroll whitespace-nowrap"
                  ></td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
      <div v-if="show_ibnr_allocation" :class="locked_2 ? 'locked' : ''">
        <div class="w-full bg-sybil-charcoal text-gray-300 mt-5 relative">
          <b class="text-sm px-2 py-1">IBNR allocation to month</b
          ><el-button
            class="pl-5 left-0 text-gray-500 cursor-pointer item mx-2"
            type="info"
            link
            @click="global_store.setCurrencyModal(true)"
            ><i>Amounts are in {{ currency + GWPUnits[selectedGWPUnit][1] }}</i></el-button
          ><span
            class="absolute right-2 top-1 cursor-pointer"
            @click="
              (event) => (event.shiftKey ? (show_ibnr_allocation = !show_ibnr_allocation) : (locked_2 = !locked_2))
            "
            ><el-icon v-if="locked_2"><Lock /></el-icon><el-icon v-else><Unlock /></el-icon
          ></span>
        </div>
        <div class="relative">
          <RegionLoaderComponent v-if="actuarial_store.ultLoading" />
          <div
            v-if="ult_table_data"
            class="table-panel relative shadow-md"
            :class="{ tableOff: !showStatus }"
            :style="{ overflow: actuarial_store.trianglesLoading ? 'scroll' : 'scroll' }"
          >
            <table data-testid="ibnr-allocation-method" class="table-panel bg-white shadow" style="border-spacing: 0">
              <thead>
                <tr>
                  <th class="fixWidth header-teal sticky left-0 top-0 z-30" rowspan="2">
                    {{ ['Accident', 'Underwriting'][actuarial_store.actuarial_accident_underwriting] }}
                    Month
                  </th>
                  <th class="fixWidth header-teal sticky top-0 z-20" rowspan="2">
                    {{ ['Accident', 'Underwriting'][actuarial_store.actuarial_accident_underwriting] }}
                    {{ COHORT_CONSTANT[actuarial_store.cohort] }}
                  </th>
                  <th class="fixWidth header-teal sticky top-0 z-20">GEP</th>
                  <th class="fixWidth header-teal sticky top-0 z-20">Paid</th>
                  <th class="fixWidth header-teal sticky top-0 z-20">Incurred</th>
                  <th class="fixWidth header-teal sticky top-0 z-20">Apriori</th>
                  <th class="fixWidth header-teal sticky top-0 z-20">IBNR</th>
                  <th class="fixWidth header-teal sticky top-0 z-20">Aggregate GEP</th>
                  <th class="fixWidth header-teal sticky top-0 z-20">Aggregate Paid</th>
                  <th class="fixWidth header-teal sticky top-0 z-20">Aggregate Incurred</th>
                  <th class="fixWidth header-teal sticky top-0 z-20">Aggregate Apriori</th>
                  <th class="fixWidth header-teal sticky top-0 z-20">Aggregate IBNR</th>
                  <th class="fixWidth header-teal sticky top-0 z-20">Paid CDF</th>
                  <th class="fixWidth header-teal sticky top-0 z-20">Incurred CDF</th>
                  <th class="fixWidth header-teal sticky top-0 z-20">Allocation</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(value, index) in actuarial_store.ibnr_table['MONTH_x']" :key="index">
                  <td class="fixWidth side-teal sticky left-0 z-20">
                    {{ actuarial_store.ibnr_table['MONTH_x'][index] }}
                  </td>
                  <td class="fixWidth">
                    {{ actuarial_store.ibnr_table['AGG'][index] }}
                  </td>
                  <td class="fixWidth text-green-700">
                    {{ numberWithCommas(actuarial_store.ibnr_table['GEP_AMOUNT'][index], false) }}
                  </td>
                  <td class="fixWidth text-red-500">
                    {{ numberWithCommas(actuarial_store.ibnr_table['PAID'][index], false) }}
                  </td>
                  <td class="fixWidth text-red-500">
                    {{ numberWithCommas(actuarial_store.ibnr_table['INCURRED'][index], false) }}
                  </td>
                  <td class="fixWidth text-red-700">
                    {{ numberWithCommas(actuarial_store.ibnr_table['APRIORI'][index], false) }}
                  </td>
                  <td class="fixWidth text-red-700">
                    {{ numberWithCommas(actuarial_store.ibnr_table['ibnr'][index], false) }}
                  </td>
                  <td class="fixWidth text-green-700">
                    {{ numberWithCommas(actuarial_store.ibnr_table['agg_gep'][index], false) }}
                  </td>
                  <td class="fixWidth text-red-500">
                    {{ numberWithCommas(actuarial_store.ibnr_table['agg_paid'][index], false) }}
                  </td>
                  <td class="fixWidth text-red-500">
                    {{ numberWithCommas(actuarial_store.ibnr_table['agg_incurred'][index], false) }}
                  </td>
                  <td class="fixWidth text-red-700">
                    {{ numberWithCommas(actuarial_store.ibnr_table['agg_apriori'][index], false) }}
                  </td>
                  <td class="fixWidth text-red-700">
                    {{ numberWithCommas(actuarial_store.ibnr_table['agg_ibnr'][index], false) }}
                  </td>
                  <td class="fixWidth">
                    {{ (1 / actuarial_store.ibnr_table['paid_dev'][index] || 0).toFixed(3) }}
                  </td>
                  <td class="fixWidth">
                    {{ (1 / actuarial_store.ibnr_table['incurred_dev'][index] || 0).toFixed(3) }}
                  </td>
                  <td class="fixWidth">
                    {{ allocation_methods[actuarial_store.ibnr_table['allocation'][index]] }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.left {
  left: 0;
  z-index: 10;
}
.stickytotal {
  position: sticky;
  bottom: 0;
  background: white;
}
.selected {
  background: rgb(236, 209, 175, 0.2);
  box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset;
}

.th {
  height: 70px;
}

.table-panel {
  overflow: scroll;
  height: 450px;
  width: 100%;
  border-collapse: separate;
}

.tableOff {
  height: 80px;
}

.head {
  position: sticky;
  top: 0;
}

.top {
  position: sticky;
  top: 25px;
  z-index: 5;
}

.topLeft {
  z-index: 30;
}

.left {
  position: sticky;
  left: 0;
}

.thead {
  display: table-header-group;
  vertical-align: middle;
  border-color: inherit;
  position: sticky;
  top: 0;
}

.table {
  overflow: scroll;
  position: relative;
  margin-bottom: 0 !important;
  border-spacing: 0;
}

.table-panel {
  width: 100%;
  resize: vertical;
  table-layout: fixed;
  overflow: scroll;
  max-height: 600px;
  border-collapse: separate;
}

.fixWidth {
  width: 112px;
  min-width: 112px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  border: 0.01em solid #f4f4f5;
  font-size: 12px;
  text-align: center;
}
.nowrap {
  white-space: nowrap;
}

.header-teal {
  background-color: rgb(187, 226, 211);
}

.side-teal {
  background-color: rgb(238, 248, 244);
}
.total-gray {
  background-color: #fafafa;
  border-top: 2px solid gray;
  /* color: #3f3f46; */
}
.selected {
  background: rgb(236, 209, 175, 0.2);
  box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset;
}

.locked {
  position: sticky;
  top: 65px;
  bottom: 0;
  z-index: 50;
}
.panel-custom-scrollbar::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome, Safari and Opera */
}
.panel-custom-scrollbar {
  -ms-overflow-style: none; /* Hide scrollbar for IE and Edge */
  scrollbar-width: none; /* Hide scrollbar for Firefox */
}
</style>

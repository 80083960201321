<script setup lang="ts">
import { computed } from 'vue';
import * as DateTimeConstants from '@/constants/DateTimeConstants';
import FinanceMetricsTable from './components/FinanceMetricsTable.vue';
import { useFinanceStore } from '@/stores/finance';
import FinanceAddPortfolio from './components/FinanceAddPortfolio.vue';
import FinanceTablePortfolio from './components/FinanceTablePortfolio.vue';
import { UnderwritingAccidentEnum } from '@/types/finance';
import { useGlobalStore } from '@/stores/global';

const financeStore = useFinanceStore();

const globalStore = useGlobalStore();
const selectedGWPUnit = computed<string>(() => globalStore.currency.currencyFormat.selectedGWPUnit);
const gwpUnits = computed(() => globalStore.currency.GWPUnits);
const currency = computed(() => globalStore.currency.currencyFormat.currency);

function changeSelectedYearsFunc() {
  if (financeStore.selectedYears == DateTimeConstants.DateUnitEnum.MONTH) {
    financeStore.selectedYears = DateTimeConstants.DateUnitEnum.QUARTER;
  } else if (financeStore.selectedYears == DateTimeConstants.DateUnitEnum.QUARTER) {
    financeStore.selectedYears = DateTimeConstants.DateUnitEnum.YEAR;
  } else {
    financeStore.selectedYears = DateTimeConstants.DateUnitEnum.MONTH;
  }

  financeStore.monthCalculation();
}
</script>

<template>
  <div>
    <FinanceAddPortfolio />
    <el-button style="width: 135px" @click="financeStore.isAddPortfolioModal = true">Add portfolio</el-button>
    <el-button @click="changeSelectedYearsFunc">{{ financeStore.selectedYears }}</el-button>
    <el-button @click="globalStore.setCurrencyModal(true)"
      ><i>Amounts are in {{ currency + gwpUnits[selectedGWPUnit][1] }}</i></el-button
    >
    <FinanceMetricsTable />
    <FinanceTablePortfolio :underwriting-accident="UnderwritingAccidentEnum.UNDERWRITING_DATA" />
    <div class="mt-3"></div>
    <FinanceTablePortfolio :underwriting-accident="UnderwritingAccidentEnum.ACCIDENT_DATA" />
  </div>
</template>

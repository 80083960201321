import { ElMessage } from 'element-plus';
import { AxiosError } from 'axios';

interface ErrorResponseData {
  message: string; // or any other type that matches your actual data structure
  data: { message: string };
}

export function errorHandler(err: AxiosError<ErrorResponseData>) {
  if (err.response && err.response.data && err.response.data.data && err.response.data.data.message) {
    openErrorMsg(err.response.data.data.message);
  } else {
    openErrorMsg('Error! Please try again');
  }
}

export function openErrorMsg(msg: string) {
  ElMessage.error(msg);
}

export default errorHandler;

<script setup lang="ts">
import { useActuarialStore } from '@/stores/actuarial';
import { ref, computed } from 'vue';
import TypeSelection from './components/TypeSelection.vue';
import DynamicType from './components/DynamicType.vue';
import StaticType from './components/StaticType.vue';
import getStaticLinkRatiosFromAnotherGroup from './functions/getStaticLinkRatiosFromAnotherGroup';
import { usePortfolioStore } from '@/stores/portfolio';
import errorHandler from '@/utilities/errorhandler';
import { AxiosError } from 'axios';
import openErrorMsg from '@/components/openErrorMsg';
import { useGlobalStore } from '@/stores/global';
import moment from 'moment';

const actuarialStore = useActuarialStore();
const portfolioStore = usePortfolioStore();
const globalStore = useGlobalStore();

async function addIDFmethod() {
  let ratioType: string[] = [];
  let constantArray: number[] = [];

  if (idfMethodType.value == 'Dynamic') {
    ratioType.push('Dynamic');
    ratioType.push(averageType.value);
    ratioType.push(periodType.value);
    if (lastN.value < 0 || lastN.value > numberOfPeriods.value) {
      openErrorMsg('Incorrect number of periods');
      return;
    }
    constantArray = [1];
    if (periodType.value == 'LastN') {
      ratioType.push(lastN.value.toString());
      idfMethodName.value = 'Dynamic, Last ' + lastN.value.toString() + ' Period';
    } else {
      if (fromX.value == '' || toY.value == '') {
        openErrorMsg('Incorrect period selection');
        return;
      }
      if (moment(toY.value, 'YYYY-MM').isBefore(moment(fromX.value, 'YYYY-MM'))) {
        openErrorMsg('Incorrect period selection');
        return;
      }
      ratioType.push(fromX.value + ',' + toY.value);
      idfMethodName.value = 'Dynamic, From ' + fromX.value + ' to ' + toY.value;
    }
  } else {
    if (idfMethodName.value == '') {
      openErrorMsg('Please enter a calculation name');
      return;
    }
    if (actuarialStore.staticValueType == 'paste') {
      ratioType.push('Static');
      ratioType.push(actuarialStore.staticValueType);
      ratioType.push(idfMethodName.value);
      constantArray = [];
      for (const i of actuarialStore.averageLinkRatioCopyPaste) {
        constantArray.push(i['linkRatio']);
      }
      // constantArray.reverse()
    } else if (actuarialStore.staticValueType == 'openLocal') {
      ratioType.push('Static');
      ratioType.push(actuarialStore.staticValueType);
      ratioType.push(idfMethodName.value);

      try {
        globalStore.isLoading = true;
        await getStaticLinkRatiosFromAnotherGroup(
          portfolioStore.selectedBounceID,
          actuarialStore.selectedAmountType,
          actuarialStore.selectedDataType,
          portfolioStore.current_month,
          portfolioStore.parameters['hierarchies'],
          portfolioStore.parameters['actuarial_grouping'],
          actuarialStore.hierarchySelection,
          actuarialStore.actuarialHierarchy
        ).then((res) => {
          constantArray = res;
          globalStore.isLoading = false;
        });
      } catch (err: unknown) {
        if (err instanceof AxiosError) {
          errorHandler(err);
        } else {
          openErrorMsg('An error occurred while fetching data');
        }
        globalStore.isLoading = false;
        return;
      }
    } else if (actuarialStore.staticValueType == 'openExternal') {
      ratioType.push('Static');
      ratioType.push(actuarialStore.staticValueType);
      ratioType.push(idfMethodName.value);

      try {
        globalStore.isLoading = true;
        await getStaticLinkRatiosFromAnotherGroup(
          actuarialStore.selectedBusinessBounceIDAddLinkRatio,
          actuarialStore.selectedAmountType,
          actuarialStore.selectedDataType,
          actuarialStore.selectedBusinessCurrentMonthAddLinkRatio,
          actuarialStore.selectedBusinessParametersAddLinkRatio['hierarchies'],
          actuarialStore.selectedBusinessParametersAddLinkRatio['actuarial_grouping'],
          actuarialStore.selectedBusinessHierarchySelectionAddLinkRatio,
          actuarialStore.selectedBusinessActuarialHierarchyAddLinkRatio
        ).then((res) => {
          constantArray = res;
          while (actuarialStore.numberOfDev.length < constantArray.length) {
            constantArray.pop();
          }
          while (actuarialStore.numberOfDev.length > constantArray.length) {
            constantArray.push(1);
          }
          globalStore.isLoading = false;
        });

        console.log(actuarialStore.numberOfDev.length);
        console.log(constantArray);
      } catch (err: unknown) {
        console.log(err);
        if (err instanceof AxiosError) {
          errorHandler(err);
        } else {
          openErrorMsg('An error occurred while fetching data');
        }
        globalStore.isLoading = false;
        return;
      }
    }
  }
  actuarialStore.addLinkRatios(ratioType, constantArray);
  actuarialStore.averageLinkRatioModal = false;
}

const idfMethodName = ref('');
const idfMethodType = ref('Dynamic');
const numberOfPeriods = computed(() => actuarialStore.origins.length);
const averageType = ref('Weighted');
const periodType = ref('LastN');
const idfMethodDesc = ref('');
const lastN = ref(1);
const fromX = ref('');
const toY = ref('');
</script>

<template>
  <div>
    <el-dialog v-model="actuarialStore.averageLinkRatioModal" width="650px" title="Add Link Ratios Calculation">
      <TypeSelection v-model:updateIdfMethodType="idfMethodType" :idf-method-type="idfMethodType" />
      <!-- Dynamic Link Ratios -->
      <DynamicType
        v-if="idfMethodType == 'Dynamic'"
        v-model:updateAverageType="averageType"
        v-model:updatePeriodType="periodType"
        v-model:updateLastN="lastN"
        v-model:updateFromX="fromX"
        v-model:updateToY="toY"
        v-model:updateNumberOfPeriods="numberOfPeriods"
        :average-type="averageType"
        :period-type="periodType"
        :last-n="lastN"
        :from-x="fromX"
        :to-y="toY"
        :number-of-periods="numberOfPeriods"
      />
      <!-- Static Link Ratios -->
      <StaticType
        v-else
        v-model:updateIdfMethodName="idfMethodName"
        v-model:updateIdfMethodDesc="idfMethodDesc"
        :idf-method-name="idfMethodName"
        :idf-method-desc="idfMethodDesc"
      />
      <template #footer>
        <span class="dialog-footer">
          <el-button type="info" @click="actuarialStore.onChangeAverageLinkRatioModal(false)">Cancel</el-button>
          <el-button type="primary" data-testid="add-link-ratios" @click="addIDFmethod()">Add</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

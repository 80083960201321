<script setup lang="ts">
import { onMounted, ref, type PropType } from 'vue';
import type { PortfolioType } from '@/types/portfolio';

defineProps({
  item: {
    type: Object as PropType<PortfolioType>,
    required: true,
  },
});
const emit = defineEmits(['selectCurrBusinessModal']);

const cardOne = ref('start');

const isError = ref(false);

const PROJECT_ID = import.meta.env.VITE_PROJECT_ID;

function imageUrlOnError() {
  isError.value = true;
}
</script>
<template>
  <div>
    <div
      class="scene scene--card"
      @click="emit('selectCurrBusinessModal', item)"
      @mouseover="cardOne = 'flipped'"
      @mouseleave="cardOne = 'start'"
    >
      <div class="card group">
        <div class="card__face bg-white shadow-md text-black relative">
          <div class="absolute top-3 left-3 max-h-6 max-w-32">
            <el-image
              v-if="!isError"
              :src="
                `https://storage.googleapis.com/sybil-public-${PROJECT_ID}/images/` +
                item.name.slice(0, item.name.indexOf(' ')) +
                '.png'
              "
              class="grayscale group-hover:grayscale-0 group-hover:scale-105"
              @error="imageUrlOnError"
            >
              <template #error>
                <div class="image-slot text-3xl">{{ item.name.slice(0, item.name.indexOf(' ')) }}</div>
              </template>
            </el-image>
            <el-image
              v-else
              class="grayscale group-hover:grayscale-0 group-hover:scale-105"
              :src="'/src/assets/images/Sybil.png'"
            >
              <template #error>
                <div class="image-slot text-3xl">{{ item.name }}</div>
              </template>
            </el-image>
          </div>
          <div
            class="z-20 w-full absolute bottom-10 h-32 -skew-y-12 transition-colors duration-300 bg-gray-50 group-hover:bg-sybil-teal"
          ></div>
          <div
            class="z-20 w-full absolute bottom-0 h-32 pt-8 transition-colors duration-300 bg-gray-50 group-hover:bg-sybil-teal group-hover:text-2xl text-sybil-charcoal group-hover:text-white"
          >
            <span class="">{{ isError ? item.name : item.name.slice(item.name.indexOf(' ')) }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.scene {
  width: 300px;
  height: 250px;
  perspective: 600px;
  margin: 10px;
}

.card {
  width: 100%;
  height: 100%;
  transition: transform 0.4s;
  transform-style: preserve-3d;
  cursor: pointer;
  position: relative;
}

.card__face {
  width: 100%;
  height: 100%;

  text-align: center;
  font-weight: bold;
  font-size: 20px;
}

.rectskew {
  transform: skewY(-6deg);
}
</style>

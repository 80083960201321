<script setup lang="ts">
import { computed, onBeforeUnmount, onMounted, ref, watch } from 'vue';
import { useActuarialStore } from '@/stores/actuarial';
import * as am5 from '@amcharts/amcharts5/index';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import * as am5xy from '@amcharts/amcharts5/xy';
import RegionLoaderComponent from '@/components/RegionLoaderComponent.vue';
import { Unlock, Lock } from '@element-plus/icons-vue';
const locked = ref(false);

const actuarial_store = useActuarialStore();

const numberOfDev = computed(() => actuarial_store.numberOfDev);
const ratios = computed(() => actuarial_store.ratios);
const claims_ref = ['Incurred Claims', 'Paid Claims', 'A-priori', 'CL Paid', 'CL Incurred', 'BF Paid', 'BF Incurred'];
const allClaimsTypeColumn = computed(() => {
  return [...claims_ref, ...actuarial_store.customUltTableTitle];
});

const ult_table_data = computed(() => actuarial_store.ult_table_data);
const trianglesLoading = computed(() => actuarial_store.trianglesLoading);
onMounted(() => {
  createChart();
});

onBeforeUnmount(() => {
  if (root) {
    root.dispose();
  }
  root = null;
  seriesSelected = null;
  allLinkRatiosSeries = null;
  devSeries = null;
});

function chartData() {
  let devData: any = [];
  let selectedData: any = [];
  let allLinkRatios: any = [];

  for (let triIdx = 0; triIdx < actuarial_store.origins.length; triIdx++) {
    const triangle = actuarial_store.trianglesJSON[actuarial_store.origins[triIdx]];
    const selectionUltTableValue = Math.round(
      ult_table_data.value[allClaimsTypeColumn.value[ult_table_data.value['selected_ult'][triIdx]]][triIdx]
    );

    let tempDevData: any = {};
    tempDevData['date'] = actuarial_store.origins[triIdx];
    tempDevData['dev'] = [];

    for (let dev = 1; dev <= Object.values(triangle).length; dev++) {
      tempDevData['dev'].push({
        dev: dev,
        value: parseFloat(
          selectionUltTableValue == 0 || triangle[dev][1] != ''
            ? NaN
            : ((Math.round(triangle[dev][0]) / selectionUltTableValue) * 100).toFixed(1)
        ),
      });
    }
    devData.push(tempDevData);
  }

  for (let selection = 0; selection < ratios.value['type'].length; selection++) {
    let tempDevData: any = {};
    tempDevData['type'] = ratios.value['type'][selection].slice(0, 9);
    tempDevData['dev'] = [];

    for (let dev = 1; dev <= numberOfDev.value.length; dev++) {
      let tempSelectedData: any = {};

      tempSelectedData['value'] = parseFloat(((1 / ratios.value['dev_factors'][selection][dev - 1]) * 100).toFixed(1));
      tempSelectedData['dev'] = dev;

      tempDevData['dev'].push(tempSelectedData);
    }
    allLinkRatios.push(tempDevData);
  }

  for (let dev = 1; dev <= numberOfDev.value.length; dev++) {
    let tempSelectedData: any = {};

    tempSelectedData['value'] = parseFloat(((1 / calculatedSelectedDevFactors(dev - 1)) * 100).toFixed(1));
    tempSelectedData['dev'] = dev;

    selectedData.push(tempSelectedData);
  }
  devData = devData.reverse();

  return [devData, selectedData, allLinkRatios];
}

function calculatedSelectedDevFactors(idx: number): number {
  let prod = 1;
  for (let i = idx; i < ratios.value['selected_link_ratios']['rows'][2].length; i++) {
    const selection = ratios.value['selected_link_ratios']['rows'][2][i];
    prod *= parseFloat(ratios.value['link_ratios'][selection][i]);
  }
  return prod;
}

let root;
let [devData, selectedData, allLinkRatios] = [null, null, null];

let allLinkRatiosSeries: any = [];
let devSeries: any = [];
let seriesSelected: any = null;

watch(trianglesLoading, () => {
  if (trianglesLoading.value == false) {
    actuarial_store.updateActuarialCache();
  }
});

watch(ult_table_data.value, () => {
  [devData, selectedData, allLinkRatios] = chartData();

  seriesSelected.data.setAll(selectedData);

  if (allLinkRatios.length != allLinkRatiosSeries.length) {
    createChart();
    return;
  }

  for (let i = 0; i < allLinkRatios.length; i++) {
    allLinkRatiosSeries[i].data.setAll(allLinkRatios[i]['dev']);
  }

  for (let i = 0; i < devData.length; i++) {
    devSeries[i].data.setAll(devData[i]['dev']);
  }
});

async function createChart() {
  [devData, selectedData, allLinkRatios] = chartData();

  if (root) {
    await root.dispose();
  }

  root = am5.Root.new('chartdiv');

  const myTheme = am5.Theme.new(root);

  myTheme.rule('AxisLabel', ['minor']).setAll({
    dy: 1,
  });

  myTheme.rule('Grid', ['x']).setAll({
    strokeOpacity: 0.05,
  });

  myTheme.rule('Grid', ['x', 'minor']).setAll({
    strokeOpacity: 0.05,
  });

  // Set themes
  // https://www.amcharts.com/docs/v5/concepts/themes/
  root.setThemes([am5themes_Animated.new(root), myTheme]);

  let chart = root.container.children.push(
    am5xy.XYChart.new(root, {
      panX: true,
      panY: true,
      wheelX: 'panX',
      wheelY: 'zoomX',
      maxTooltipDistance: 0,
      pinchZoomX: true,
    })
  );

  let xAxis = chart.xAxes.push(
    am5xy.ValueAxis.new(root, {
      renderer: am5xy.AxisRendererX.new(root, {}),
      tooltip: am5.Tooltip.new(root, {}),
    })
  );

  let yAxis = chart.yAxes.push(
    am5xy.ValueAxis.new(root, {
      renderer: am5xy.AxisRendererY.new(root, {}),
      numberFormat: "#'%'",
    })
  );

  xAxis.children.push(
    am5.Label.new(root, {
      text: 'Development',
      x: am5.p50,
      centerX: am5.p50,
    })
  );

  yAxis.children.unshift(
    am5.Label.new(root, {
      rotation: -90,
      text: 'Percent(%)',
      y: am5.p50,
      centerX: am5.p50,
    })
  );

  seriesSelected = chart.series.push(
    am5xy.LineSeries.new(root, {
      name: 'Selected',
      xAxis: xAxis,
      yAxis: yAxis,
      valueYField: 'value',
      valueXField: 'dev',
      legendValueText: '{valueY}%',
      fill: am5.color(0xff0000),
      tooltip: am5.Tooltip.new(root, {
        pointerOrientation: 'horizontal',
        labelText: '{name}',
      }),
      stroke: am5.color(0xff0000),
    })
  );

  seriesSelected.data.setAll(selectedData);

  seriesSelected.strokes.template.setAll({
    strokeWidth: 4,
  });

  // Make stuff animate on load
  // https://www.amcharts.com/docs/v5/concepts/animations/
  seriesSelected.appear();

  for (let i = 0; i < allLinkRatios.length; i++) {
    let series = chart.series.push(
      am5xy.LineSeries.new(root, {
        name: allLinkRatios[i]['type'],
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: 'value',
        valueXField: 'dev',
        legendValueText: '{valueY}%',
        tooltip: am5.Tooltip.new(root, {
          pointerOrientation: 'horizontal',
          labelText: '{name}',
        }),
      })
    );

    series.data.setAll(allLinkRatios[i]['dev']);

    // Make stuff animate on load
    // https://www.amcharts.com/docs/v5/concepts/animations/
    series.appear();

    allLinkRatiosSeries.push(series);
  }

  for (let i = 0; i < devData.length; i++) {
    let series = chart.series.push(
      am5xy.LineSeries.new(root, {
        connect: false,
        name: devData[i]['date'].toString().replace(',', ''),
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: 'value',
        valueXField: 'dev',
        legendValueText: '{valueY}%',
        tooltip: am5.Tooltip.new(root, {
          pointerOrientation: 'horizontal',
          labelText: '{name}',
        }),
      })
    );

    series.data.setAll(devData[i]['dev']);

    // Make stuff animate on load
    // https://www.amcharts.com/docs/v5/concepts/animations/
    series.appear();

    devSeries.push(series);
  }

  seriesSelected.toFront();

  let cursor = chart.set(
    'cursor',
    am5xy.XYCursor.new(root, {
      behavior: 'none',
    })
  );
  cursor.lineY.set('visible', false);

  // Add scrollbar
  // https://www.amcharts.com/docs/v5/charts/xy-chart/scrollbars/
  chart.set(
    'scrollbarX',
    am5.Scrollbar.new(root, {
      orientation: 'horizontal',
    })
  );

  chart.set(
    'scrollbarY',
    am5.Scrollbar.new(root, {
      orientation: 'vertical',
    })
  );

  // Add legend
  // https://www.amcharts.com/docs/v5/charts/xy-chart/legend-xy-series/
  let legend = chart.rightAxesContainer.children.push(
    am5.Legend.new(root, {
      width: 250,
      paddingLeft: 15,
      height: am5.percent(100),
      verticalScrollbar: am5.Scrollbar.new(root, {
        orientation: 'vertical',
      }),
    })
  );

  // When legend item container is hovered, dim all the series except the hovered one
  legend.itemContainers.template.events.on('pointerover', function (e) {
    let itemContainer: any = e.target;

    // As series list is data of a legend, dataContext is series
    let series = itemContainer.dataItem.dataContext;

    chart.series.each(function (chartSeries: any) {
      if (chartSeries != series) {
        chartSeries.strokes.template.setAll({
          strokeOpacity: 0.15,
          stroke: am5.color(0x000000),
        });
      } else {
        chartSeries.strokes.template.setAll({
          strokeWidth: 3,
        });
      }
    });

    seriesSelected.strokes.template.setAll({
      strokeWidth: 4,
    });
  });

  // When legend item container is unhovered, make all series as they are
  legend.itemContainers.template.events.on('pointerout', function (e) {
    let itemContainer = e.target;
    let series = itemContainer?.dataItem?.dataContext;

    chart.series.each(function (chartSeries: any) {
      if (chartSeries != seriesSelected) {
        chartSeries.strokes.template.setAll({
          strokeOpacity: 1,
          strokeWidth: 1,
          stroke: chartSeries.get('fill'),
        });
      } else {
        chartSeries.strokes.template.setAll({
          strokeOpacity: 1,
          strokeWidth: 1,
          stroke: am5.color(0xff0000),
        });
      }
    });

    seriesSelected.strokes.template.setAll({
      strokeWidth: 4,
    });
  });

  legend.itemContainers.template.set('width', am5.p100);
  legend.valueLabels.template.setAll({
    width: am5.p100,
    textAlign: 'right',
  });

  // It's is important to set legend data after all the events are set on template, otherwise events won't be copied
  legend.data.setAll(chart.series.values);

  // Make stuff animate on load
  // https://www.amcharts.com/docs/v5/concepts/animations/
  chart.appear(1000, 100);
}
</script>
<template>
  <div class="bg-white" :class="locked ? 'locked' : ''">
    <div class="relative w-full bg-sybil-charcoal text-gray-300 mt-5">
      <b class="text-sm px-2 py-1">Diagnostic Chart</b
      ><span class="absolute right-2 top-1 cursor-pointer" @click="locked = !locked"
        ><el-icon v-if="locked"><Lock /></el-icon><el-icon v-else><Unlock /></el-icon
      ></span>
    </div>
    <div class="relative">
      <RegionLoaderComponent v-if="actuarial_store.ultLoading" />
      <div
        id="chartdiv"
        class="mt-10"
        style="width: 100%; background-color: white"
        :style="{ height: 450 + 'px' }"
      ></div>
    </div>
  </div>
</template>

<style scoped>
.locked {
  position: sticky;
  top: 65px;
  bottom: 0;
  z-index: 50;
}
</style>

<script setup lang="ts">
import { useDashboardStore } from '@/stores/dashboard';
import { usePortfolioStore } from '@/stores/portfolio';
import { computed, ref } from 'vue';
import toTitleCase from '@/utilities/toTitleCase';
import { useDashboardAvEStore } from '@/stores/AvEDashboard';
import DashboardComparisonRow from './DashboardComparisonRow.vue';
import { ArrowRightBold, ArrowLeftBold } from '@element-plus/icons-vue';
import type { DashboardData } from '@/types/dashboard';

const props = defineProps({
  overflow: {
    type: String, // Replace "String" with the appropriate type
    required: true, // Add this line if the prop is required
  },
});

const isYearSubTotal = computed(() => dashboardStore.isYearSubTotal);
const isYearSubTotalUp = computed(() => dashboardStore.isYearSubTotalUp);
const isQuarterSubTotal = computed(() => dashboardStore.isQuarterSubTotal);
const isQuarterSubTotalUp = computed(() => dashboardStore.isQuarterSubTotalUp);
const isBinderSubTotal = computed(() => dashboardStore.isBinderSubTotal);
const isBinderSubTotalUp = computed(() => dashboardStore.isBinderSubTotalUp);

// Comparison Binder
const comparisonBinderDashboardData = computed(() => dashboardAveStore.binder_dashboard_data);
// Binder
const binderDashboardData = computed(() => dashboardAveStore.current_binder_dashboard_data);

// Comparison
const comparisonYearDashboardData = computed(() => dashboardAveStore.yearly_dashboard_data);
const comparisonQuarterDashboardData = computed(() => dashboardAveStore.quarterly_dashboard_data);
const comparisonTotalDashboardData = computed<DashboardData>(() => {
  const obj = {};
  obj[0] = dashboardAveStore.totalData;
  return obj;
});

// Current Data
const yearDashboardData = computed(() => dashboardAveStore.current_yearly_dashboard_data);
const quarterDashboardData = computed(() => dashboardAveStore.current_quarterly_dashboard_data);
const totalDashboardData = computed<DashboardData>(() => {
  const obj = {};
  obj[0] = dashboardAveStore.current_totalData;
  return obj;
});

const dashboardStore = useDashboardStore();
const portfolioStore = usePortfolioStore();

const ratioAmount = computed(() => dashboardStore.dashboards.ratio_amount);

function changeCurrentDispDate() {
  dashboardStore.change_mqy();
}

function changeCurrData() {
  dashboardStore.change_uw_acc();
}

function switchRatiosAmount() {
  dashboardStore.switch_ratio_amount();
}

const compLrExpand = ref(false);
const showColumnTotal = ref(false);
const normalise = computed<boolean[]>(() => portfolioStore.normalise);
const normaliseSelection = computed<boolean[]>(() => portfolioStore.normaliseSelection);
const seasonFactor = computed<boolean>(() => dashboardStore.dashboards.seasonFactor);

const dashboardAveStore = useDashboardAvEStore();

const dashboardData = computed(() => dashboardAveStore.current_dashboard_data);
const comparisonDashboardData = computed(() => dashboardAveStore.dashboard_data);

function changeSeas() {
  dashboardStore.dashboards.seasonFactor = !dashboardStore.dashboards.seasonFactor;
}

const totalMargin = ref(0);

function changeccrnlr() {
  dashboardStore.changeccrnlr();
}

const ccrnlr = computed(() => dashboardStore.dashboards.ccr_nlr);

const mqy = computed(() => dashboardStore.dashboards.mqy);
const claimsType = computed(() => portfolioStore.parameters.claims_nature);
const margin: any = ref(claimsType.value.reduce((acc: any, curr: any) => ((acc[curr] = 0), acc), {}));

function widthFormula() {
  const baseWidth = 112 * 3;
  const isWrittenUw = dashboardStore.underwriting_loss_ratios == 'Written' && dashboardStore.dashboards.uw_acc == 'uw';
  const factor = isWrittenUw ? 5 : 4;
  const claimsTypeLength = claimsType.value.length;
  const totalMarginValue = totalMargin.value * 3;

  let additionalWidth;

  if (compLrExpand.value) {
    if (showColumnTotal.value) {
      additionalWidth = totalMarginValue * (factor * (claimsTypeLength + 1) + claimsTypeLength);
    } else {
      additionalWidth = baseWidth * factor;
    }
  } else {
    additionalWidth = totalMarginValue * claimsTypeLength;
  }

  return baseWidth + additionalWidth + 'px';
}
</script>
<template>
  <div>
    <div id="dashboardTable3" class="table-panel relative shadow-md" :class="'overflow-' + props.overflow">
      <table id="comparison_table" class="bg-white shadow" style="border-spacing: 0">
        <thead class="sticky top-0 z-30 header-teal">
          <tr>
            <th
              class="fixWidth header-teal sticky left-0 whitespace-nowrap z-50"
              rowspan="3"
              :style="{
                height: '120px',
              }"
            >
              <p class="cursor-pointer item" @click="changeCurrData()">
                {{ { uw: 'Underwriting', acc: 'Accident' }[dashboardStore.dashboards.uw_acc] }}
              </p>
              <p class="cursor-pointer item" @click="changeCurrentDispDate()">
                {{ toTitleCase(mqy) }}
              </p>
            </th>

            <th
              class="fixWidth header-teal relative text-red-500 pb-24"
              :colspan="
                3 +
                (compLrExpand
                  ? showColumnTotal
                    ? (totalMargin / 112) * 3 * (4 * (claimsType.length + 1) + claimsType.length)
                    : 3 * 4
                  : (totalMargin / 112) * 3 * claimsType.length)
              "
              :style="{
                width:
                  112 * 3 +
                  (compLrExpand
                    ? showColumnTotal
                      ? totalMargin *
                        3 *
                        ((dashboardStore.underwriting_loss_ratios == 'Written' &&
                        dashboardStore.dashboards.uw_acc == 'uw'
                          ? 5
                          : 4) *
                          (claimsType.length + 1) +
                          claimsType.length)
                      : 112 *
                        3 *
                        (dashboardStore.underwriting_loss_ratios == 'Written' &&
                        dashboardStore.dashboards.uw_acc == 'uw'
                          ? 5
                          : 4)
                    : totalMargin * 3 * claimsType.length) +
                  'px',
                'min-width':
                  112 * 3 +
                  (compLrExpand
                    ? showColumnTotal
                      ? totalMargin *
                        3 *
                        ((dashboardStore.underwriting_loss_ratios == 'Written' &&
                        dashboardStore.dashboards.uw_acc == 'uw'
                          ? 5
                          : 4) *
                          (claimsType.length + 1) +
                          claimsType.length)
                      : 112 *
                        3 *
                        (dashboardStore.underwriting_loss_ratios == 'Written' &&
                        dashboardStore.dashboards.uw_acc == 'uw'
                          ? 5
                          : 4)
                    : totalMargin * 3 * claimsType.length) +
                  'px',
                'max-width':
                  112 * 3 +
                  (compLrExpand
                    ? showColumnTotal
                      ? totalMargin *
                        3 *
                        ((dashboardStore.underwriting_loss_ratios == 'Written' &&
                        dashboardStore.dashboards.uw_acc == 'uw'
                          ? 5
                          : 4) *
                          (claimsType.length + 1) +
                          claimsType.length)
                      : 112 *
                        3 *
                        (dashboardStore.underwriting_loss_ratios == 'Written' &&
                        dashboardStore.dashboards.uw_acc == 'uw'
                          ? 5
                          : 4)
                    : totalMargin * 3 * claimsType.length) +
                  'px',

                transition: '1.0s ease-out width',
              }"
            >
              <p v-if="dashboardStore.dashboards.uw_acc == 'acc'" style="display: inline">Earned</p>
              <p
                v-if="dashboardStore.dashboards.uw_acc == 'uw'"
                style="display: inline"
                class="cursor-pointer lossRatioItem"
                @click="dashboardStore.underwritingLossRatiosChange()"
              >
                {{ dashboardStore.underwriting_loss_ratios + ' ' }}
              </p>
              Loss
              <p class="cursor-pointer lossRatioItem" style="display: inline" @click="switchRatiosAmount()">
                {{ ' ' + toTitleCase(ratioAmount) }}
              </p>
              <div class="absolute top-2 left-1">
                <el-icon v-if="!compLrExpand" class="cursor-pointer" @click="compLrExpand = !compLrExpand"
                  ><ArrowRightBold
                /></el-icon>
                <el-icon v-if="compLrExpand" class="cursor-pointer" @click="compLrExpand = !compLrExpand"
                  ><ArrowLeftBold
                /></el-icon>
              </div>
            </th>

            <th class="tripleFixWidth header-teal text-blue-400 pb-6" rowspan="2" colspan="3">Comm.</th>
            <th class="tripleFixWidth header-teal pb-6" rowspan="2" colspan="3">
              <div class="cursor-pointer" @click="changeccrnlr()">
                {{ ccrnlr }}
              </div>
            </th>

            <th class="tripleFixWidth header-teal text-orange-900 pb-6" rowspan="2" colspan="3">
              <div>
                <el-dropdown trigger="click">
                  {{
                    claimsType
                      .slice(1)
                      .filter((x, i) => normalise[i] == true)
                      .map((x) =>
                        normaliseSelection.filter((e) => e == true).length <= 1
                          ? toTitleCase(x)
                          : toTitleCase(x).slice(0, 1)
                      )
                      .join(', ')
                  }}
                  <template #dropdown>
                    <div class="flex flex-col items-center p-5" :min="1">
                      <div v-for="(item, index) in claimsType.slice(1)" :key="index">
                        <el-checkbox
                          v-model="normaliseSelection[index]"
                          :label="item"
                          :name="item"
                          :disabled="
                            normaliseSelection.filter((e) => e == true).length <= 1 && normaliseSelection[index]
                          "
                        />
                      </div>
                    </div>
                  </template>
                </el-dropdown>
                normalised
                <div class="cursor-pointer" @click="changeccrnlr()">
                  {{ ccrnlr }}
                </div>
              </div>
            </th>
            <th class="tripleFixWidth header-teal pb-6" rowspan="2" colspan="3">
              <p v-if="!seasonFactor" class="item" style="display: inline" @click="changeSeas()">No Seas.</p>
              <p v-if="seasonFactor" class="item" style="display: inline" @click="changeSeas()">Seas.</p>
              adj. A-priori
              <div class="cursor-pointer" @click="changeccrnlr()">
                {{ ccrnlr }}
              </div>
            </th>
          </tr>
          <tr class="header-teal">
            <template v-for="(amount, ix) in dashboardStore.amtLabel" :key="ix">
              <template v-for="(item, index) in claimsType" :key="index">
                <th
                  class="tripleFixWidth header-teal text-red-500 absolute z-10 pt-5 cursor-pointer"
                  :style="{
                    top: '30px',
                    height: '60px',
                    left: 112 + Object.values(margin).reduce((ps, s) => ps + s, 0) * claimsType.length + 'px',
                    transition: '1.0s ease-out all',
                    transform:
                      'translateX(' +
                      (compLrExpand
                        ? showColumnTotal
                          ? totalMargin * 3 * (ix * (claimsType.length + 1) + index)
                          : 112 * 3 * ix
                        : totalMargin * 3 * index) +
                      'px)',
                  }"
                  colspan="3"
                  @click="
                    showColumnTotal = !showColumnTotal;
                    totalMargin = 112 - totalMargin;
                  "
                >
                  {{ toTitleCase(item) + ' ' + amount }}
                </th>
              </template>
              <th
                class="tripleFixWidth header-teal pt-5 text-red-500 absolute z-10 cursor-pointer"
                :style="{
                  top: '30px',
                  height: '60px',
                  left: 112 + 'px',
                  transition: '1.0s ease-out all',
                  transform:
                    'translateX(' +
                    (compLrExpand
                      ? showColumnTotal
                        ? totalMargin * 3 * (ix * (claimsType.length + 1) + claimsType.length)
                        : 112 * 3 * ix
                      : totalMargin * 3 * claimsType.length) +
                    'px)',
                }"
                colspan="3"
                @click="
                  showColumnTotal = !showColumnTotal;
                  totalMargin = 112 - totalMargin;
                "
              >
                Total {{ amount }}
              </th>
            </template>
          </tr>
          <tr>
            <template v-for="(amount, ix) in dashboardStore.amtLabel" :key="ix">
              <template v-for="(item, index) in claimsType" :key="index">
                <th
                  class="fixWidth absolute z-20 text-red-700 pt-1 header-teal cursor-pointer"
                  :style="{
                    top: '90px',
                    height: '30px',
                    left: 112 * 1 + Object.values(margin).reduce((ps, s) => ps + s, 0) * claimsType.length + 'px',
                    transition: '1.0s ease-out all',
                    transform:
                      'translateX(' +
                      (compLrExpand
                        ? showColumnTotal
                          ? totalMargin * 3 * (ix * (claimsType.length + 1) + index)
                          : 112 * 3 * ix
                        : totalMargin * 3 * index) +
                      'px)',
                  }"
                  @click="
                    showColumnTotal = !showColumnTotal;
                    totalMargin = 112 - totalMargin;
                  "
                >
                  Comparator
                </th>
                <th
                  class="fixWidth absolute z-20 text-red-500 pt-1 header-teal cursor-pointer"
                  :style="{
                    top: '90px',
                    height: '30px',
                    left: 112 * 2 + Object.values(margin).reduce((ps, s) => ps + s, 0) * claimsType.length + 'px',
                    transition: '1.0s ease-out all',
                    transform:
                      'translateX(' +
                      (compLrExpand
                        ? showColumnTotal
                          ? totalMargin * 3 * (ix * (claimsType.length + 1) + index)
                          : 112 * 3 * ix
                        : totalMargin * 3 * index) +
                      'px)',
                  }"
                  @click="
                    showColumnTotal = !showColumnTotal;
                    totalMargin = 112 - totalMargin;
                  "
                >
                  Current
                </th>
                <th
                  class="fixWidth absolute z-20 pt-1 header-teal cursor-pointer"
                  :style="{
                    top: '90px',
                    height: '30px',
                    left: 112 * 3 + Object.values(margin).reduce((ps, s) => ps + s, 0) * claimsType.length + 'px',
                    transition: '1.0s ease-out all',
                    transform:
                      'translateX(' +
                      (compLrExpand
                        ? showColumnTotal
                          ? totalMargin * 3 * (ix * (claimsType.length + 1) + index)
                          : 112 * 3 * ix
                        : totalMargin * 3 * index) +
                      'px)',
                  }"
                  @click="
                    showColumnTotal = !showColumnTotal;
                    totalMargin = 112 - totalMargin;
                  "
                >
                  Change
                </th>
              </template>
              <th
                class="fixWidth absolute z-20 text-red-700 pt-1 header-teal cursor-pointer"
                :style="{
                  top: '90px',
                  height: '30px',
                  left: 112 * 1 + Object.values(margin).reduce((ps, s) => ps + s, 0) * claimsType.length + 'px',
                  transition: '1.0s ease-out all',
                  transform:
                    'translateX(' +
                    (compLrExpand
                      ? showColumnTotal
                        ? totalMargin * 3 * (ix * (claimsType.length + 1) + claimsType.length)
                        : 112 * 3 * ix
                      : totalMargin * 3 * claimsType.length) +
                    'px)',
                }"
                @click="
                  showColumnTotal = !showColumnTotal;
                  totalMargin = 112 - totalMargin;
                "
              >
                Comparator
              </th>
              <th
                class="fixWidth absolute z-20 text-red-500 pt-1 header-teal cursor-pointer"
                :style="{
                  top: '90px',
                  height: '30px',
                  left: 112 * 2 + Object.values(margin).reduce((ps, s) => ps + s, 0) * claimsType.length + 'px',
                  transition: '1.0s ease-out all',
                  transform:
                    'translateX(' +
                    (compLrExpand
                      ? showColumnTotal
                        ? totalMargin * 3 * (ix * (claimsType.length + 1) + claimsType.length)
                        : 112 * 3 * ix
                      : totalMargin * 3 * claimsType.length) +
                    'px)',
                }"
                @click="
                  showColumnTotal = !showColumnTotal;
                  totalMargin = 112 - totalMargin;
                "
              >
                Current
              </th>
              <th
                class="fixWidth absolute z-20 pt-1 header-teal cursor-pointer"
                :style="{
                  top: '90px',
                  height: '30px',
                  left: 112 * 3 + Object.values(margin).reduce((ps, s) => ps + s, 0) * claimsType.length + 'px',
                  transition: '1.0s ease-out all',
                  transform:
                    'translateX(' +
                    (compLrExpand
                      ? showColumnTotal
                        ? totalMargin * 3 * (ix * (claimsType.length + 1) + claimsType.length)
                        : 112 * 3 * ix
                      : totalMargin * 3 * claimsType.length) +
                    'px)',
                }"
                @click="
                  showColumnTotal = !showColumnTotal;
                  totalMargin = 112 - totalMargin;
                "
              >
                Change
              </th>
            </template>

            <th
              class="fixWidth absolute z-20 text-blue-400 pt-1 header-teal"
              :style="{
                top: '90px',
                height: '30px',
                left: 112 * 4 + Object.values(margin).reduce((ps, s) => ps + s, 0) * claimsType.length + 'px',
                transition: '1.0s ease-out all',
                transform:
                  'translateX(' +
                  (compLrExpand
                    ? showColumnTotal
                      ? totalMargin *
                        3 *
                        ((dashboardStore.underwriting_loss_ratios == 'Written' &&
                        dashboardStore.dashboards.uw_acc == 'uw'
                          ? 5
                          : 4) *
                          (claimsType.length + 1) +
                          claimsType.length)
                      : 112 *
                        3 *
                        (dashboardStore.underwriting_loss_ratios == 'Written' &&
                        dashboardStore.dashboards.uw_acc == 'uw'
                          ? 5
                          : 4)
                    : totalMargin * 3 * claimsType.length) +
                  'px)',
              }"
            >
              Comparator
            </th>
            <th
              class="fixWidth absolute z-20 text-blue-400 pt-1 header-teal"
              :style="{
                top: '90px',
                height: '30px',
                left: 112 * 5 + Object.values(margin).reduce((ps, s) => ps + s, 0) * claimsType.length + 'px',
                transition: '1.0s ease-out all',
                transform:
                  'translateX(' +
                  (compLrExpand
                    ? showColumnTotal
                      ? totalMargin *
                        3 *
                        ((dashboardStore.underwriting_loss_ratios == 'Written' &&
                        dashboardStore.dashboards.uw_acc == 'uw'
                          ? 5
                          : 4) *
                          (claimsType.length + 1) +
                          claimsType.length)
                      : 112 *
                        3 *
                        (dashboardStore.underwriting_loss_ratios == 'Written' &&
                        dashboardStore.dashboards.uw_acc == 'uw'
                          ? 5
                          : 4)
                    : totalMargin * 3 * claimsType.length) +
                  'px)',
              }"
            >
              Current
            </th>
            <th
              class="fixWidth absolute z-20 text-blue-400 pt-1 header-teal"
              :style="{
                top: '90px',
                height: '30px',
                left: 112 * 6 + Object.values(margin).reduce((ps, s) => ps + s, 0) * claimsType.length + 'px',
                transition: '1.0s ease-out all',
                transform:
                  'translateX(' +
                  (compLrExpand
                    ? showColumnTotal
                      ? totalMargin *
                        3 *
                        ((dashboardStore.underwriting_loss_ratios == 'Written' &&
                        dashboardStore.dashboards.uw_acc == 'uw'
                          ? 5
                          : 4) *
                          (claimsType.length + 1) +
                          claimsType.length)
                      : 112 *
                        3 *
                        (dashboardStore.underwriting_loss_ratios == 'Written' &&
                        dashboardStore.dashboards.uw_acc == 'uw'
                          ? 5
                          : 4)
                    : totalMargin * 3 * claimsType.length) +
                  'px)',
              }"
            >
              Change
            </th>

            <th
              class="fixWidth absolute z-20 pt-1 header-teal"
              :style="{
                top: '90px',
                height: '30px',
                left: 112 * 7 + Object.values(margin).reduce((ps, s) => ps + s, 0) * claimsType.length + 'px',
                transition: '1.0s ease-out all',
                transform:
                  'translateX(' +
                  (compLrExpand
                    ? showColumnTotal
                      ? totalMargin *
                        3 *
                        ((dashboardStore.underwriting_loss_ratios == 'Written' &&
                        dashboardStore.dashboards.uw_acc == 'uw'
                          ? 5
                          : 4) *
                          (claimsType.length + 1) +
                          claimsType.length)
                      : 112 *
                        3 *
                        (dashboardStore.underwriting_loss_ratios == 'Written' &&
                        dashboardStore.dashboards.uw_acc == 'uw'
                          ? 5
                          : 4)
                    : totalMargin * 3 * claimsType.length) +
                  'px)',
              }"
            >
              Comparator
            </th>
            <th
              class="fixWidth absolute z-20 pt-1 header-teal"
              :style="{
                top: '90px',
                height: '30px',
                left: 112 * 8 + Object.values(margin).reduce((ps, s) => ps + s, 0) * claimsType.length + 'px',
                transition: '1.0s ease-out all',
                transform:
                  'translateX(' +
                  (compLrExpand
                    ? showColumnTotal
                      ? totalMargin *
                        3 *
                        ((dashboardStore.underwriting_loss_ratios == 'Written' &&
                        dashboardStore.dashboards.uw_acc == 'uw'
                          ? 5
                          : 4) *
                          (claimsType.length + 1) +
                          claimsType.length)
                      : 112 *
                        3 *
                        (dashboardStore.underwriting_loss_ratios == 'Written' &&
                        dashboardStore.dashboards.uw_acc == 'uw'
                          ? 5
                          : 4)
                    : totalMargin * 3 * claimsType.length) +
                  'px)',
              }"
            >
              Current
            </th>
            <th
              class="fixWidth absolute z-20 pt-1 header-teal"
              :style="{
                top: '90px',
                height: '30px',
                left: 112 * 9 + Object.values(margin).reduce((ps, s) => ps + s, 0) * claimsType.length + 'px',
                transition: '1.0s ease-out all',
                transform:
                  'translateX(' +
                  (compLrExpand
                    ? showColumnTotal
                      ? totalMargin *
                        3 *
                        ((dashboardStore.underwriting_loss_ratios == 'Written' &&
                        dashboardStore.dashboards.uw_acc == 'uw'
                          ? 5
                          : 4) *
                          (claimsType.length + 1) +
                          claimsType.length)
                      : 112 *
                        3 *
                        (dashboardStore.underwriting_loss_ratios == 'Written' &&
                        dashboardStore.dashboards.uw_acc == 'uw'
                          ? 5
                          : 4)
                    : totalMargin * 3 * claimsType.length) +
                  'px)',
              }"
            >
              Change
            </th>

            <th
              class="fixWidth absolute z-20 text-orange-900 pt-1 header-teal"
              :style="{
                top: '90px',
                height: '30px',
                left: 112 * 10 + Object.values(margin).reduce((ps, s) => ps + s, 0) * claimsType.length + 'px',
                transition: '1.0s ease-out all',
                transform:
                  'translateX(' +
                  (compLrExpand
                    ? showColumnTotal
                      ? totalMargin *
                        3 *
                        ((dashboardStore.underwriting_loss_ratios == 'Written' &&
                        dashboardStore.dashboards.uw_acc == 'uw'
                          ? 5
                          : 4) *
                          (claimsType.length + 1) +
                          claimsType.length)
                      : 112 *
                        3 *
                        (dashboardStore.underwriting_loss_ratios == 'Written' &&
                        dashboardStore.dashboards.uw_acc == 'uw'
                          ? 5
                          : 4)
                    : totalMargin * 3 * claimsType.length) +
                  'px)',
              }"
            >
              Comparator
            </th>
            <th
              class="fixWidth absolute z-20 text-orange-900 pt-1 header-teal"
              :style="{
                top: '90px',
                height: '30px',
                left: 112 * 11 + Object.values(margin).reduce((ps, s) => ps + s, 0) * claimsType.length + 'px',
                transition: '1.0s ease-out all',
                transform:
                  'translateX(' +
                  (compLrExpand
                    ? showColumnTotal
                      ? totalMargin *
                        3 *
                        ((dashboardStore.underwriting_loss_ratios == 'Written' &&
                        dashboardStore.dashboards.uw_acc == 'uw'
                          ? 5
                          : 4) *
                          (claimsType.length + 1) +
                          claimsType.length)
                      : 112 *
                        3 *
                        (dashboardStore.underwriting_loss_ratios == 'Written' &&
                        dashboardStore.dashboards.uw_acc == 'uw'
                          ? 5
                          : 4)
                    : totalMargin * 3 * claimsType.length) +
                  'px)',
              }"
            >
              Current
            </th>
            <th
              class="fixWidth absolute z-20 text-orange-900 pt-1 header-teal"
              :style="{
                top: '90px',
                height: '30px',
                left: 112 * 12 + Object.values(margin).reduce((ps, s) => ps + s, 0) * claimsType.length + 'px',
                transition: '1.0s ease-out all',
                transform:
                  'translateX(' +
                  (compLrExpand
                    ? showColumnTotal
                      ? totalMargin *
                        3 *
                        ((dashboardStore.underwriting_loss_ratios == 'Written' &&
                        dashboardStore.dashboards.uw_acc == 'uw'
                          ? 5
                          : 4) *
                          (claimsType.length + 1) +
                          claimsType.length)
                      : 112 *
                        3 *
                        (dashboardStore.underwriting_loss_ratios == 'Written' &&
                        dashboardStore.dashboards.uw_acc == 'uw'
                          ? 5
                          : 4)
                    : totalMargin * 3 * claimsType.length) +
                  'px)',
              }"
            >
              Change
            </th>

            <th
              class="fixWidth absolute z-20 pt-1 header-teal"
              :style="{
                top: '90px',
                height: '30px',
                left: 112 * 13 + Object.values(margin).reduce((ps, s) => ps + s, 0) * claimsType.length + 'px',
                transition: '1.0s ease-out all',
                transform:
                  'translateX(' +
                  (compLrExpand
                    ? showColumnTotal
                      ? totalMargin *
                        3 *
                        ((dashboardStore.underwriting_loss_ratios == 'Written' &&
                        dashboardStore.dashboards.uw_acc == 'uw'
                          ? 5
                          : 4) *
                          (claimsType.length + 1) +
                          claimsType.length)
                      : 112 *
                        3 *
                        (dashboardStore.underwriting_loss_ratios == 'Written' &&
                        dashboardStore.dashboards.uw_acc == 'uw'
                          ? 5
                          : 4)
                    : totalMargin * 3 * claimsType.length) +
                  'px)',
              }"
            >
              Comparator
            </th>
            <th
              class="fixWidth absolute z-20 pt-1 header-teal"
              :style="{
                top: '90px',
                height: '30px',
                left: 112 * 14 + Object.values(margin).reduce((ps, s) => ps + s, 0) * claimsType.length + 'px',
                transition: '1.0s ease-out all',
                transform:
                  'translateX(' +
                  (compLrExpand
                    ? showColumnTotal
                      ? totalMargin *
                        3 *
                        ((dashboardStore.underwriting_loss_ratios == 'Written' &&
                        dashboardStore.dashboards.uw_acc == 'uw'
                          ? 5
                          : 4) *
                          (claimsType.length + 1) +
                          claimsType.length)
                      : 112 *
                        3 *
                        (dashboardStore.underwriting_loss_ratios == 'Written' &&
                        dashboardStore.dashboards.uw_acc == 'uw'
                          ? 5
                          : 4)
                    : totalMargin * 3 * claimsType.length) +
                  'px)',
              }"
            >
              Current
            </th>
            <th
              class="fixWidth absolute z-20 pt-1 header-teal"
              :style="{
                top: '90px',
                height: '30px',
                left: 112 * 15 + Object.values(margin).reduce((ps, s) => ps + s, 0) * claimsType.length + 'px',
                transition: '1.0s ease-out all',
                transform:
                  'translateX(' +
                  (compLrExpand
                    ? showColumnTotal
                      ? totalMargin *
                        3 *
                        ((dashboardStore.underwriting_loss_ratios == 'Written' &&
                        dashboardStore.dashboards.uw_acc == 'uw'
                          ? 5
                          : 4) *
                          (claimsType.length + 1) +
                          claimsType.length)
                      : 112 *
                        3 *
                        (dashboardStore.underwriting_loss_ratios == 'Written' &&
                        dashboardStore.dashboards.uw_acc == 'uw'
                          ? 5
                          : 4)
                    : totalMargin * 3 * claimsType.length) +
                  'px)',
              }"
            >
              Change
            </th>
          </tr>
        </thead>
        <tbody v-if="dashboardData">
          <template
            v-for="(n, idx) in dashboardData.length > comparisonDashboardData.length
              ? comparisonDashboardData
              : dashboardData"
            :key="idx"
          >
            <tr>
              <DashboardComparisonRow
                :row-index="parseInt(idx.toString())"
                :margin="margin"
                :show-column-total="showColumnTotal"
                :total-margin="totalMargin"
                :dashboard-data="dashboardData"
                :comparison-dashboard-data="comparisonDashboardData"
                :comp-lr-expand="compLrExpand"
                row-class=""
              />
            </tr>
            <tr
              v-if="
                binderDashboardData[idx] &&
                isBinderSubTotal &&
                isBinderSubTotalUp &&
                mqy == 'month' &&
                dashboardStore.dashboards.uw_acc == 'uw'
              "
            >
              <DashboardComparisonRow
                :row-index="parseInt(idx.toString())"
                :margin="margin"
                :show-column-total="showColumnTotal"
                :total-margin="totalMargin"
                :dashboard-data="binderDashboardData"
                :comparison-dashboard-data="comparisonBinderDashboardData"
                :comp-lr-expand="compLrExpand"
                row-class="total-teal-quarter"
              />
            </tr>
            <tr v-if="quarterDashboardData[idx] && isQuarterSubTotal && isQuarterSubTotalUp && mqy == 'month'">
              <DashboardComparisonRow
                :row-index="parseInt(idx.toString())"
                :margin="margin"
                :show-column-total="showColumnTotal"
                :total-margin="totalMargin"
                :dashboard-data="quarterDashboardData"
                :comparison-dashboard-data="comparisonQuarterDashboardData"
                :comp-lr-expand="compLrExpand"
                row-class="total-teal-quarter"
              />
            </tr>
            <tr v-if="yearDashboardData[idx] && isYearSubTotal && isYearSubTotalUp && mqy != 'year'">
              <DashboardComparisonRow
                :row-index="parseInt(idx.toString())"
                :margin="margin"
                :show-column-total="showColumnTotal"
                :total-margin="totalMargin"
                :dashboard-data="yearDashboardData"
                :comparison-dashboard-data="comparisonYearDashboardData"
                :comp-lr-expand="compLrExpand"
                row-class="total-teal-quarter"
              />
            </tr>
          </template>
          <template
            v-if="isBinderSubTotal && !isBinderSubTotalUp && mqy == 'month' && dashboardStore.dashboards.uw_acc == 'uw'"
          >
            <tr v-for="(item, idx) in binderDashboardData" :key="item">
              <DashboardComparisonRow
                :row-index="parseInt(idx.toString())"
                :margin="margin"
                :show-column-total="showColumnTotal"
                :total-margin="totalMargin"
                :dashboard-data="binderDashboardData"
                :comparison-dashboard-data="comparisonBinderDashboardData"
                :comp-lr-expand="compLrExpand"
                row-class="total-teal-quarter"
              />
            </tr>
          </template>
          <template v-if="isQuarterSubTotal && !isQuarterSubTotalUp && mqy == 'month'">
            <tr v-for="(item, idx) in quarterDashboardData" :key="item">
              <DashboardComparisonRow
                :row-index="parseInt(idx.toString())"
                :margin="margin"
                :show-column-total="showColumnTotal"
                :total-margin="totalMargin"
                :dashboard-data="quarterDashboardData"
                :comparison-dashboard-data="comparisonQuarterDashboardData"
                :comp-lr-expand="compLrExpand"
                row-class="total-teal-quarter"
              />
            </tr>
          </template>
          <template v-if="isYearSubTotal && !isYearSubTotalUp && mqy != 'year'">
            <tr v-for="(item, idx) in yearDashboardData" :key="item">
              <DashboardComparisonRow
                :row-index="parseInt(idx.toString())"
                :margin="margin"
                :show-column-total="showColumnTotal"
                :total-margin="totalMargin"
                :dashboard-data="yearDashboardData"
                :comparison-dashboard-data="comparisonYearDashboardData"
                :comp-lr-expand="compLrExpand"
                row-class="total-teal-quarter"
              />
            </tr>
          </template>
          <tr class="sticky z-30 bg-gray-300 bottom-0">
            <DashboardComparisonRow
              :row-index="0"
              :margin="margin"
              :show-column-total="showColumnTotal"
              :total-margin="totalMargin"
              :dashboard-data="totalDashboardData"
              :comparison-dashboard-data="comparisonTotalDashboardData"
              :comp-lr-expand="compLrExpand"
              row-class="total-teal-quarter"
            />
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<style scoped>
.ftstick {
  height: 50px;
  border: 0;
  position: sticky;
  bottom: 0px;
  left: 0px;
}

.slide-enter-active {
  transition: all 0.25s ease;
  display: none;
}

.slide-leave-active {
  transition: all 1s ease;
  display: none;
}

.slide-enter-from,
.slide-leave-to {
  transform: translate(0%, 0);
  flex-grow: 0 !important;
}

.slide-enter-to,
.slide-leave {
  transform: translate(0, 0);
}

/*  */

.slide2-enter-active {
  transition: all 1s ease;
  display: none;
}

.slide2-leave-active {
  transition: all 0.75s ease;
  display: none;
}

.slide2-enter-from,
.slide2-leave-to {
  transform: translate(-100%, 0);
  flex-grow: 0 !important;
}

.slide2-enter-to,
.slide2-leave {
  transform: translate(0, 0);
}
/*  */

.slide3-enter-active {
  transition: all 0.75s ease;
  display: none;
}

.slide3-leave-active {
  transition: all 1s ease;
  display: none;
}

.slide3-enter-from,
.slide3-leave-to {
  transform: translate(-200%, 0);
  flex-grow: 0 !important;
}

.slide3-enter-to,
.slide3-leave {
  transform: translate(0, 0);
}

/*  */

.slide4-enter-active {
  transition: all 1s ease;
  display: none;
}

.slide4-leave-active {
  transition: all 0.25s ease;
  display: none;
}

.slide4-enter-from,
.slide4-leave-to {
  transform: translate(-300%, 0);
  flex-grow: 0 !important;
}

.slide4-enter-to,
.slide4-leave {
  transform: translate(0, 0);
}

.lossRatioItem {
  color: #ef4444;
}

.lossRatioItem:hover {
  color: #7dd3fc;
}

/* .table-panel-total::-webkit-scrollbar {
  display: none;
}

.table-panel::-webkit-scrollbar {
  display: none;
} */

.table-panel {
  width: 100%;
  resize: vertical;
  table-layout: fixed;

  max-height: calc(100vh - 180px);
  border-collapse: separate;
}

.fixWidth {
  width: 112px;
  min-width: 112px;
  padding-left: 20px;
  padding-right: 20px;
  border: 0.01em solid #f4f4f5;
  font-size: 12px;
  text-align: center;
}

.tripleFixWidth {
  width: 336px;
  min-width: 336px;
  padding-left: 20px;
  padding-right: 20px;
  border: 0.01em solid #f4f4f5;
  font-size: 12px;
  text-align: center;
}
.header-teal {
  background-color: rgb(187, 226, 211);
}

.side-teal {
  background-color: rgb(238, 248, 244);
}

.total-teal {
  background-color: rgb(208, 218, 214);
}
.total-teal-quarter {
  background-color: rgb(223, 233, 229);
}
.total-gray {
  background-color: #fafafa;
  /* color: #3f3f46; */
}

.fixHeight {
  height: 90px;
}

.total-blue-binder {
  background-color: rgb(220, 235, 245);
}
.animate-slide-down.ng-hide-add,
.animate-slide-down.ng-hide-remove.ng-hide-remove-active {
  transition: 1s ease-out all;
  transform: scale(1);
  transform-origin: right;
  line-height: 1.42857;
  display: table-row !important;
}

.moveleft {
  transition: 1s ease-out all;
  transform: translateX(42px);
}
.moveright {
  transition: 1s ease-out all;
  transform: translateX(0px);
}

.panel-custom-scrollbar::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome, Safari and Opera */
}

.panel-custom-scrollbar {
  -ms-overflow-style: none; /* Hide scrollbar for IE and Edge */
  scrollbar-width: none; /* Hide scrollbar for Firefox */
  overflow-y: hidden;
}
</style>

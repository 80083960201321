import { type FinanceDataType, type FinanceDataObj } from '@/types/finance';
import * as DateTimeConstants from '@/constants/DateTimeConstants';
import moment from 'moment';
import { safeDivide } from '@/utilities/calculationHelper';

export default class FinanceMonthConversion {
  private businessAccidentValues: FinanceDataType;
  private businessUnderwritingValues: FinanceDataType;
  private uniqueClaimsNature: Set<string>;
  private dateUnit: DateTimeConstants.DateUnitEnum;
  private dateStringFormat: string;

  constructor(
    businessAccidentValues: FinanceDataType,
    businessUnderwritingValues: FinanceDataType,
    uniqueClaimsNature: Set<string>,
    dateUnit: DateTimeConstants.DateUnitEnum
  ) {
    this.businessAccidentValues = businessAccidentValues;
    this.businessUnderwritingValues = businessUnderwritingValues;
    this.uniqueClaimsNature = uniqueClaimsNature;
    this.dateUnit = dateUnit;
    this.dateStringFormat =
      this.dateUnit == DateTimeConstants.DateUnitEnum.YEAR
        ? DateTimeConstants.MomentFormatUnitEnum.YEAR
        : DateTimeConstants.MomentFormatUnitEnum.QUARTER;
  }

  getBusinessAccidentValues() {
    return this.recalculatebusinessValues(this.businessAccidentValues);
  }

  getBusinessUnderwritingValues() {
    return this.recalculatebusinessValues(this.businessUnderwritingValues);
  }

  /**
   * Convert sorted list of dates (month) into current format
   * @param dateKey Sorted dates by month
   */
  dateKeyConversion(dateKey: string[]) {
    if (this.dateUnit == DateTimeConstants.DateUnitEnum.MONTH) {
      return dateKey;
    }
    const visitedDate = new Set();
    const transformedDate: string[] = [];
    for (const date of dateKey) {
      const newDateFormat = moment(date, 'YYYY/MM/01').format(this.dateStringFormat);
      if (!visitedDate.has(newDateFormat)) {
        visitedDate.add(newDateFormat);
        transformedDate.push(newDateFormat);
      }
    }

    return transformedDate;
  }

  private recalculatebusinessValues(values: FinanceDataType) {
    if (this.dateUnit == DateTimeConstants.DateUnitEnum.MONTH) {
      return values;
    }

    const newBusinessValuesObject: FinanceDataType = [];

    for (const businessIndex in values) {
      const businessValues = values[businessIndex];
      const newBusinessValue: FinanceDataObj[] = [];
      for (const value of businessValues) {
        const newBounceValue: FinanceDataObj = {};
        const valueDateKeys = Object.keys(value);
        for (const date of valueDateKeys) {
          const newDateFormat = moment(date, 'YYYY/MM/01').format(this.dateStringFormat);
          if (!newBounceValue[newDateFormat]) {
            newBounceValue[newDateFormat] = {
              CCR: 0,
              NLR: 0,
              CombineRatio: 0,
              GWP_GEP: 0,
              GWPGrowth: 0,
              LRLoad: 0,
              LossRatio: 0,
              OverheadExpenses: 0,
              ClaimsUltimate: {},
              Commission: 0,
              GEP: 0,
            };

            this.uniqueClaimsNature.forEach((claims) => {
              newBounceValue[newDateFormat].ClaimsUltimate[claims] = 0;
            });
          }

          newBounceValue[newDateFormat].CCR += value[date].CCR * value[date].GEP;
          newBounceValue[newDateFormat].NLR += value[date].NLR * value[date].GEP;
          newBounceValue[newDateFormat].CombineRatio += value[date].CombineRatio * value[date].GEP;
          newBounceValue[newDateFormat].GWP_GEP += value[date].GWP_GEP;
          newBounceValue[newDateFormat].GWPGrowth += value[date].GWPGrowth;
          newBounceValue[newDateFormat].LRLoad += value[date].LRLoad;
          newBounceValue[newDateFormat].LossRatio += value[date].LossRatio * value[date].GEP;
          newBounceValue[newDateFormat].OverheadExpenses += value[date].OverheadExpenses;
          newBounceValue[newDateFormat].Commission += value[date].Commission * value[date].GEP;
          newBounceValue[newDateFormat].GEP += value[date].GEP;
          this.uniqueClaimsNature.forEach((claims) => {
            newBounceValue[newDateFormat].ClaimsUltimate[claims] +=
              value[date].ClaimsUltimate[claims] * value[date].GEP;
          });
        }

        for (const date of Object.keys(newBounceValue)) {
          newBounceValue[date].CCR = safeDivide(newBounceValue[date].CCR, newBounceValue[date].GEP);
          newBounceValue[date].NLR = safeDivide(newBounceValue[date].NLR, newBounceValue[date].GEP);
          newBounceValue[date].CombineRatio = safeDivide(newBounceValue[date].CombineRatio, newBounceValue[date].GEP);
          newBounceValue[date].LossRatio = safeDivide(newBounceValue[date].LossRatio, newBounceValue[date].GEP);
          newBounceValue[date].Commission = safeDivide(newBounceValue[date].Commission, newBounceValue[date].GEP);
          this.uniqueClaimsNature.forEach((claims) => {
            newBounceValue[date].ClaimsUltimate[claims] = safeDivide(
              newBounceValue[date].ClaimsUltimate[claims],
              newBounceValue[date].GEP
            );
          });
        }
        newBusinessValue.push(newBounceValue);
      }

      newBusinessValuesObject.push(newBusinessValue);
    }

    return newBusinessValuesObject;
  }
}

<script setup lang="ts">
import { api } from '@/services/api';
import { ElMessage } from 'element-plus';
import { onMounted, ref, onBeforeUnmount, watch, computed, shallowRef } from 'vue';
import { usePortfolioStore } from '@/stores/portfolio';
import { useDashboardStore } from '@/stores/dashboard';
import DashboardTable from './DashboardTable/DashboardTable.vue';
// import DashboardComparisonTable from './DashboardComparisonTable.vue';
import DashboardComparisonTable from './DashboardComparisonTable/DashboardComparisonTable.vue';
import { useDashboardAvEStore } from '@/stores/AvEDashboard';
import DashboardFilters from './DashboardFilters.vue';
// import * as am4core from "@amcharts/amcharts4/core";
// import * as am4charts from "@amcharts/amcharts4/charts";
import * as am5 from '@amcharts/amcharts5/index';
import * as am5xy from '@amcharts/amcharts5/xy';
import * as am5plugins_exporting from '@amcharts/amcharts5/plugins/exporting';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import AdditionalClaimsInformationModal from '@/views/Dashboard/ClaimsDashboard/AdditionalClaimsInformationModal.vue';
import ConfigBindedYearsModal from './ConfigBindedYearsModal.vue';

const dashboard_ave_store = useDashboardAvEStore();

const optionsArrayFilter = ref([
  {
    name: 'Select Quarter',
    slug: 'quarter',
  },
  {
    name: 'Select Year',
    slug: 'year',
  },
]);

const overflow = ref('scroll');
const overflow2 = ref('scroll');
function filterPopOutFunction(event: any) {
  dashboard_store.onChoosePeriod(event.item.object, event.item.type, event.option.slug);
}

const dashboard_store = useDashboardStore();
const portfolio_store = usePortfolioStore();

const isBindedYears: any = computed(() => dashboard_store.isBindedYears);
const claimsType: any = computed(() => portfolio_store.parameters.claims_nature);
const ccrnlr = computed(() => dashboard_store.dashboards.ccr_nlr);
function openErrorMsg(msg: string) {
  ElMessage.warning(msg);
}

let root: any = null;

onMounted(async () => {
  window.addEventListener('scroll', checkOverlap);
  // if (!dashboard_store.dashboard_data) {
  await loadDashboard();
  // } else {
  dashboard_store.setChartData();
  // }

  window.scrollTo(0, dashboard_store.perdormanceViewScroll);
});

async function loadDashboard() {
  await dashboard_store.loadDashboard().catch((err) => {
    if (err.response && err.response.data) {
      openErrorMsg(err.response.data.data);
    } else {
      openErrorMsg('Error! Please try again');
    }
  });
  // console.log(dashboard_store.dashboard_data);
}

const dashboardTable = ref();

function onResizeDashboard() {}

function resizeFilter() {
  const numericValue = parseInt(filtersWidth.value, 10); // Extract numeric value from the string
  filtersWidth.value = `${350 - numericValue}px`; // Calculate and append 'px' back
}

const filtersWidth = ref('300px');
watch(
  () => dashboard_store.dashboard_data,
  () => {
    dashboard_store.setChartData();
  },
  { deep: true }
);

watch(
  () => portfolio_store.parameters,
  () => {
    dashboard_store.setChartData();
  },
  { deep: true }
);
const graphConfig = computed(() => dashboard_store.graphConfig);

const section1 = ref(null);
const section2 = ref(null);
const section3 = ref(null);

const blur1 = ref('');
const blur2 = ref('');

function checkOverlap() {
  if (portfolio_store.isAve) {
    const rect1 = section1.value.getBoundingClientRect();
    const rect2 = section2.value.getBoundingClientRect();
    const rect3 = section3.value.getBoundingClientRect();
    if (rect2.top < rect1.bottom - 400) {
      blur1.value = 'blur-md opacity-20';
      overflow.value = 'hidden';
    } else if (rect2.top < rect1.bottom - 200) {
      blur1.value = 'blur  opacity-50';
      overflow.value = 'hidden';
    } else if (rect2.top < rect1.bottom - 50) {
      blur1.value = 'blur-sm  opacity-80';
      overflow.value = 'hidden';
    } else {
      blur1.value = '';
      overflow.value = 'scroll';
    }

    if (rect3.top < rect2.bottom - 400) {
      blur2.value = 'blur-md  opacity-20';
      overflow2.value = 'hidden';
    } else if (rect3.top < rect2.bottom - 200) {
      blur2.value = 'blur  opacity-50';
      overflow2.value = 'hidden';
    } else if (rect3.top < rect2.bottom - 50) {
      blur2.value = 'blur-sm  opacity-80';
      overflow2.value = 'hidden';
    } else {
      blur2.value = '';
      if (rect2.top <= rect1.top + 50) {
        overflow2.value = 'scroll';
      } else {
        overflow2.value = 'hidden';
      }
    }
  } else {
    const rect1 = section1.value.getBoundingClientRect();
    const rect2 = section3.value.getBoundingClientRect();
    if (rect2.top < rect1.bottom - 400) {
      blur1.value = 'blur-md  opacity-20';
      overflow.value = 'hidden';
    } else if (rect2.top < rect1.bottom - 200) {
      blur1.value = 'blur  opacity-50';
      overflow.value = 'hidden';
    } else if (rect2.top < rect1.bottom - 50) {
      blur1.value = 'blur-sm  opacity-80';
      overflow.value = 'hidden';
    } else {
      blur1.value = '';
      overflow.value = 'scroll';
    }
  }
}

onBeforeUnmount(async () => {
  if (root) {
    root.dispose();
  }
  root = null;

  window.removeEventListener('scroll', checkOverlap);
  dashboard_store.setPerdormanceViewScroll(window.scrollY);
});

function createChart() {
  if (root) {
    root.dispose();
  }

  root = am5.Root.new('chartdiv');

  root.setThemes([am5themes_Animated.new(root)]);

  const data = structuredClone(chart_data.value);
  // console.log(data);

  let dashboardChart = root.container.children.push(
    am5xy.XYChart.new(root, {
      panX: false,
      panY: false,
      layout: root.verticalLayout,
    })
  );
  // console.log(dashboardChart)

  // Export menu
  let exporting = am5plugins_exporting.Exporting.new(root, {
    menu: am5plugins_exporting.ExportingMenu.new(root, {
      align: 'right',
      valign: 'top',
    }),
  });

  // Add titles
  dashboardChart.children.unshift(
    am5.Label.new(root, {
      text:
        'by ' +
        { uw: isBindedYears.value ? 'Bespoke' : 'Underwriting', acc: 'Accident' }[dashboard_store.dashboards.uw_acc] +
        ' ' +
        toTitleCase(dashboard_store.dashboards.mqy),
      fontSize: 14,
      textAlign: 'center',
      x: am5.percent(50),
      centerX: am5.percent(50),
    })
  );

  dashboardChart.children.unshift(
    am5.Label.new(root, {
      text: 'Results',
      fontSize: 25,
      fontWeight: '500',
      textAlign: 'center',
      x: am5.percent(50),
      centerX: am5.percent(50),
      paddingTop: 0,
      paddingBottom: 0,
    })
  );
  // // Scrollbars
  const scrollbarX = am5.Scrollbar.new(root, {
    orientation: 'horizontal',
  });

  dashboardChart.set('scrollbarX', scrollbarX);
  dashboardChart.bottomAxesContainer.children.push(scrollbarX);

  // Scrollbar Y
  const scrollbarY = am5.Scrollbar.new(root, {
    orientation: 'vertical',
  });

  dashboardChart.set('scrollbarY', scrollbarY);
  // dashboardChart.set("scrollbarX", am5.Scrollbar.new(root, {}));
  // dashboardChart.set("scrollbarY", am5.Scrollbar.new(root, {}));

  // X-Axis
  let xAxis = dashboardChart.xAxes.push(
    am5xy.CategoryAxis.new(root, {
      categoryField: 'date',
      renderer: am5xy.AxisRendererX.new(root, {
        minGridDistance: 40,
        cellStartLocation: 0.2,
        cellEndLocation: 0.8,
      }),
      tooltip: am5.Tooltip.new(root, {}),
    })
  );

  xAxis.get('renderer').labels.template.setAll({
    rotation: -90, // Rotates the labels to 0 degrees (horizontal)
    centerY: am5.p50, // Aligns vertically to center
    centerX: am5.p50, // Aligns horizontally to center
  });

  // Y-Axis
  let yAxis = dashboardChart.yAxes.push(
    am5xy.ValueAxis.new(root, {
      renderer: am5xy.AxisRendererY.new(root, {
        opposite: true,
        cellStartLocation: 0,
        minGridDistance: 30,
      }),
      numberFormat: '#.0a',
      tooltip: am5.Tooltip.new(root, {}),
    })
  );

  yAxis.get('renderer').grid.template.set('forceHidden', true);

  let yAxis2 = dashboardChart.yAxes.push(
    am5xy.ValueAxis.new(root, {
      renderer: am5xy.AxisRendererY.new(root, {
        cellStartLocation: 0,
        minGridDistance: 30,
      }),
      numberFormat: "#'%'",
      min: 0,
      tooltip: am5.Tooltip.new(root, {}),
    })
  );

  // // Cursor
  let cursor = dashboardChart.set(
    'cursor',
    am5xy.XYCursor.new(root, {
      behavior: 'zoomX',
    })
  );

  // // Series
  let seriesNull = dashboardChart.series.push(
    am5xy.ColumnSeries.new(root, {
      name: '',
      valueYField: 'null',
      categoryXField: 'date',
      xAxis: xAxis,
      yAxis: yAxis,
      legendValueText: '[bold #a00]{categoryX}',
      fill: am5.color(0xffffff),
      stroke: am5.color(0xffffff),
    })
  );

  // // Series
  let seriesGWP = dashboardChart.series.push(
    am5xy.ColumnSeries.new(root, {
      name: dashboard_store.dashboards.gwpnwp,
      valueYField: 'gwp',
      categoryXField: 'date',
      xAxis: xAxis,
      yAxis: yAxis,
      legendValueText: "[bold #a00]{valueY.formatNumber('#.0a')}",
      fill: am5.color(0xb5e8d5),
      stroke: am5.color(0xb5e8d5),
      clustered: true,
      columns: {
        template: {
          width: am5.percent(200),
        },
      },
    })
  );

  // GEP
  let seriesGEP = dashboardChart.series.push(
    am5xy.ColumnSeries.new(root, {
      name: dashboard_store.dashboards.gwpnwp.replace('W', 'E'),
      xAxis: xAxis,
      yAxis: yAxis,
      valueYField: 'gep',
      categoryXField: 'date',
      legendValueText: "[bold #a00]{valueY.formatNumber('#.0a')}",
      fill: am5.color(0x55b691),
      stroke: am5.color(0xb5e8d5),
      clustered: true,
      columns: {
        template: {
          width: am5.percent(200),
        },
      },
    })
  );

  seriesGWP.columns.template.setAll({
    width: am5.percent(200),
  });
  seriesGEP.columns.template.setAll({
    width: am5.percent(200),
  });

  // Seasonality series
  let seriesSeasonality = dashboardChart.series.push(
    am5xy.LineSeries.new(root, {
      name: (dashboard_store.dashboards.seasonFactor ? 'Seasonality Adjusted ' : '') + 'A-priori',
      valueYField: 'apriori',
      categoryXField: 'date',
      xAxis: xAxis,
      yAxis: yAxis2,
      legendValueText: "[bold #a00]{valueY.formatNumber('#.0p')}",
      stroke: am5.color(0xff0000),
      strokeWidth: 2,
    })
  );

  if (data[0].target && data[0].target != 0) {
    let seriesTarget = dashboardChart.series.push(
      am5xy.LineSeries.new(root, {
        name: 'Target',
        valueYField: 'target',
        categoryXField: 'date',
        xAxis: xAxis,
        yAxis: yAxis2,
        legendValueText: "[bold #a00]{valueY.formatNumber('#.0p')}",
        stroke: am5.color(0x000000),
      })
    );
    seriesTarget.strokes.template.setAll({
      strokeWidth: 3,
      strokeDasharray: [3, 3],
    });
    seriesTarget.data.setAll(data);
  }

  seriesSeasonality.strokes.template.setAll({
    strokeWidth: 3,
  });

  // Attritional Series
  let seriesAttrirional = dashboardChart.series.push(
    am5xy.LineSeries.new(root, {
      name:
        (graphConfig.value['isNormalised']
          ? claimsType.value
              .slice(1)
              .filter((x, i) => portfolio_store.normalise[i] == true)
              .map((x) => toTitleCase(x))
              .join(', ') + ' Normalised '
          : '') + (graphConfig.value['isGLR'] ? ccrnlr.value : 'GLR'),
      xAxis: xAxis,
      yAxis: yAxis2,
      valueYField: 'ccr',
      categoryXField: 'date',
      legendValueText: "[bold #a00]{valueY.formatNumber('#.0p')}",
      strokeWidth: 3,
      stroke: am5.color(0x534fc6),

      snapTooltip: true,
      clustered: true,
    })
  );

  seriesAttrirional.bullets.push(function () {
    return am5.Bullet.new(root, {
      sprite: am5.Circle.new(root, {
        stroke: am5.color(0x534fc6),
        strokeWidth: 2,
        fill: root.interfaceColors.get('background'),
        radius: 3,
      }),
    });
  });

  seriesAttrirional.strokes.template.setAll({
    strokeWidth: 2,
  });

  // Attritional Series
  let seriesIncurred = dashboardChart.series.push(
    am5xy.LineSeries.new(root, {
      name:
        (graphConfig.value['isNormalised']
          ? claimsType.value
              .slice(1)
              .filter((x, i) => portfolio_store.normalise[i] == true)
              .map((x) => toTitleCase(x))
              .join(', ') + ' Normalised Incurred '
          : 'Incurred ') + (graphConfig.value['isGLR'] ? ccrnlr.value : 'GLR'),
      xAxis: xAxis,
      yAxis: yAxis2,
      valueYField: 'incurred',
      categoryXField: 'date',
      legendValueText: "[bold #a00]{valueY.formatNumber('#.0p')}",
      stroke: am5.color(0x800080),
      fill: am5.color(0xffffff),
    })
  );

  seriesIncurred.strokes.template.setAll({
    strokeWidth: 2,
  });

  seriesIncurred.hide();

  let comp_seriesSeasonality = null;
  let comp_seriesAttrirional = null;
  let comp_seriesIncurred = null;

  if (portfolio_store.isAve) {
    // Seasonality series
    comp_seriesSeasonality = dashboardChart.series.push(
      am5xy.LineSeries.new(root, {
        name: 'Comparator ' + (dashboard_store.dashboards.seasonFactor ? 'Seasonality Adjusted ' : '') + 'A-priori',
        valueYField: 'comp_apriori',
        categoryXField: 'date',
        xAxis: xAxis,
        yAxis: yAxis2,
        legendValueText: "[bold #a00]{valueY.formatNumber('#.0p')}",
        stroke: am5.color(0xff0000),
        strokeWidth: 2,
      })
    );

    comp_seriesSeasonality.strokes.template.setAll({
      strokeWidth: 2,
      strokeDasharray: [7, 7],
    });

    // Attritional Series
    comp_seriesAttrirional = dashboardChart.series.push(
      am5xy.LineSeries.new(root, {
        name:
          'Comparator ' +
          (graphConfig.value['isNormalised']
            ? claimsType.value
                .slice(1)
                .filter((x, i) => portfolio_store.normalise[i] == true)
                .map((x) => toTitleCase(x))
                .join(', ') + ' Normalised '
            : '') +
          (graphConfig.value['isGLR'] ? ccrnlr.value : 'GLR'),
        xAxis: xAxis,
        yAxis: yAxis2,
        valueYField: 'comp_ccr',
        categoryXField: 'date',
        legendValueText: "[bold #a00]{valueY.formatNumber('#.0p')}",
        strokeWidth: 3,
        stroke: am5.color(0x534fc6),

        snapTooltip: true,
        clustered: true,
      })
    );

    comp_seriesAttrirional.bullets.push(function () {
      let graphics = am5.Rectangle.new(root, {
        width: 5,
        height: 5,
        centerX: am5.p50,
        centerY: am5.p50,
        stroke: am5.color(0x534fc6),
        strokeWidth: 2,
        fill: am5.color(0xffffff),
      });

      return am5.Bullet.new(root, {
        sprite: graphics,
      });
    });

    comp_seriesAttrirional.strokes.template.setAll({
      strokeWidth: 2,
      strokeDasharray: [7, 7],
    });

    // Attritional Series
    comp_seriesIncurred = dashboardChart.series.push(
      am5xy.LineSeries.new(root, {
        name:
          'Comparator ' +
          (graphConfig['isNormalised']
            ? claimsType
                .slice(1)
                .filter((x, i) => portfolio_store.normalise[i] == true)
                .map((x) => toTitleCase(x))
                .join(', ') + ' Normalised Incurred '
            : 'Incurred ') +
          (graphConfig.value['isGLR'] ? ccrnlr.value : 'GLR'),
        xAxis: xAxis,
        yAxis: yAxis2,
        valueYField: 'comp_incurred',
        categoryXField: 'date',
        legendValueText: "[bold #a00]{valueY.formatNumber('#.0p')}",
        stroke: am5.color(0x800080),
        fill: am5.color(0xffffff),
      })
    );

    comp_seriesIncurred.strokes.template.setAll({
      strokeWidth: 2,
      strokeDasharray: [7, 7],
    });
    comp_seriesIncurred.hide();
  }

  let legend = dashboardChart.children.push(
    am5.Legend.new(root, {
      x: am5.percent(45),
      centerX: am5.p50,
      paddingLeft: 50,
    })
  );
  legend.data.setAll(dashboardChart.series.values.slice(0, 3));
  legend.valueLabels.template.setAll({
    width: 100,
    textAlign: 'left',
    paddingLeft: 100,
  });

  let legend2 = dashboardChart.children.push(
    am5.Legend.new(root, {
      x: am5.percent(45),
      centerX: am5.p50,
      paddingLeft: 50,
    })
  );
  legend2.data.setAll(dashboardChart.series.values.slice(3));
  legend2.valueLabels.template.setAll({
    width: 100,
    textAlign: 'left',
    paddingLeft: 100,
  });

  seriesNull.data.setAll(data);
  seriesGWP.data.setAll(data);
  xAxis.data.setAll(data);

  seriesGEP.data.setAll(data);
  seriesSeasonality.data.setAll(data);
  seriesSeasonality.toFront(data);
  seriesIncurred.data.setAll(data);
  seriesAttrirional.data.setAll(data);

  if (portfolio_store.isAve) {
    comp_seriesSeasonality.data.setAll(data);
    comp_seriesAttrirional.data.setAll(data);
    comp_seriesIncurred.data.setAll(data);
  }
}

const chart_data = computed(() => dashboard_store.chart_data);

function toTitleCase(str: string) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

watch(
  () => dashboard_store.chart_data,
  () => {
    if (dashboard_store.chart_data.length != 0) {
      createChart();
    }
  },
  { deep: true }
);

watch(
  () => ccrnlr.value,
  () => {
    // loadDashboard()
    dashboard_store.setChartData();
  }
);

watch(
  () => portfolio_store.selectedLineSize,
  () => {
    loadDashboard();
    dashboard_store.setChartData();
  }
);

type RightClickEventTypeFilters = {
  event: any;
  object: any;
  type: string;
};

function rightClickFilters(rightClickEvent: RightClickEventTypeFilters) {
  console.log(filterPopOut.value);
  filterPopOut.value.showMenu(rightClickEvent.event, { object: rightClickEvent.object, type: rightClickEvent.type });
}

const filterPopOut: any = ref(null);

function openBindersModal() {
  dashboard_store.onSetConfigBindedYearsModal(true);
}

function changeSeas() {
  dashboard_store.changeSeas();
}

function changeccrnlr() {
  dashboard_store.changeccrnlr();
}
function changeCurrentDispDate() {
  dashboard_store.change_mqy();
}

function changeCurrData() {
  dashboard_store.change_uw_acc();
}
</script>

<template>
  <div class="font-graphie">
    <ConfigBindedYearsModal />
    <AdditionalClaimsInformationModal />
    <div class=" ">
      <div
        class="section w-fit"
        ref="section1"
        :class="blur1"
        :style="{
          'max-width': 'calc(100% - ' + filtersWidth + ')',
          transition: '0.5s ease-out all',
          position: 'sticky',
          top: '80px',
        }"
      >
        <DashboardTable @binderChange="openBindersModal()" class="bg-white" :overflow="overflow" />
      </div>
      <div
        v-if="portfolio_store.isAve && dashboard_ave_store.dashboard_data"
        ref="section2"
        class="section snap-center mt-5"
        :class="blur2"
        :style="{
          width: 'calc(100% - ' + filtersWidth + ')',
          transition: '0.5s ease-out all',
          position: 'sticky',
          top: '80px',
        }"
      >
        <DashboardComparisonTable @binderChange="openBindersModal()" class="bg-white" :overflow="overflow2" />
      </div>
      <div
        ref="section3"
        :style="{
          height: 'calc(100vh - 100px)',
          width: 'calc(100% - ' + filtersWidth + ')',
          transition: '0.5s ease-out all',
          position: 'sticky',
          top: '80px',
        }"
        class="section snap-center bg-white mt-5 pr-2"
      >
        <div class="flex flex-row justify-between pb-2 pl-5">
          <label class="text-lg mt-5"
            >Actual vs
            <span
              v-if="!dashboard_store.dashboards.seasonFactor"
              @click="changeSeas()"
              class="item cursor-pointer font-bold text-sybil-teal hover:brightness-75"
              style="display: inline"
            >
              No Seas.
            </span>
            <span
              v-if="dashboard_store.dashboards.seasonFactor"
              @click="changeSeas()"
              class="item cursor-pointer font-bold text-sybil-teal hover:brightness-75"
              style="display: inline"
            >
              Seas.
            </span>
            Adj.
            <span class=""> A-priori </span>
            <span class="cursor-pointer font-bold text-sybil-teal hover:brightness-75" @click="changeccrnlr()">
              {{ ccrnlr }}
            </span>
            by
            <span class="cursor-pointer font-bold text-sybil-teal hover:brightness-75 item" @click="changeCurrData()">
              {{
                { uw: isBindedYears ? 'Bespoke' : 'Underwriting', acc: 'Accident' }[dashboard_store.dashboards.uw_acc]
              }}
            </span>
            <span
              class="cursor-pointer font-bold text-sybil-teal hover:brightness-75 item"
              @click="changeCurrentDispDate()"
            >
              {{ ' ' + toTitleCase(dashboard_store.dashboards.mqy) }}
            </span>
          </label>
        </div>
        <div id="chartdiv" class="chart bg-white w-full" :style="{ height: 'calc(100% - 100px)' }"></div>
        <div class="flex flex-row justify-evenly pb-2 pl-5">
          <el-switch @change="dashboard_store.setChartData()" v-model="graphConfig['isGLR']" active-text="Commission" />
          <el-switch
            @change="dashboard_store.setChartData()"
            v-model="graphConfig['isNormalised']"
            active-text="Normalsed"
          />
        </div>
      </div>
    </div>
  </div>

  <div class="absolute top-20 right-0 z-50" style="margin-top: -15px">
    <div
      :style="{
        width: filtersWidth,
        transition: '0.5s ease-out all',
      }"
    >
      <DashboardFilters
        style="width: inherit"
        @rightClickFilters="rightClickFilters"
        @resizeFilter="resizeFilter"
        :filtersWidth="filtersWidth"
      />
    </div>
  </div>
</template>

<style scoped>
#date-legend-div {
  font-weight: bold;
  height: 30px;
  width: 150px;
}
#gwp-legend-div {
  height: 55px;
  width: 150px;
}

#ratios-legend-div {
  height: 85px;
  width: 400px;
}

#comparison-ratios-legend-div {
  height: 85px;
  width: 400px;
}
</style>

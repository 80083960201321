<script setup lang="ts">
import { el } from '@/assets/css/css_dist/locale/af';
import { useActuarialStore } from '@/stores/actuarial';
import { ref } from 'vue';

const actuarial_store = useActuarialStore();

function onCloseClaimsTieringGroupModal() {
  hashMapSelection.value = {};
  mergable.value = false;
  splitable.value = false;
  actuarial_store.change_tiering_group_modal(false);
}

const hashMapSelection: any = ref({});
const mergable: any = ref(false);
const splitable: any = ref(false);

function selectionClaimsNature(e: any, val: any) {
  hashMapSelection.value[val] = e;
  console.log(hashMapSelection.value);
  console.log(
    Object.values(hashMapSelection.value).reduce((accumulator, currentValue) => {
      if (currentValue) {
        return accumulator + 1;
      }
      return accumulator;
    }, 0)
  );
  splitable.value =
    Object.values(hashMapSelection.value).reduce((accumulator, currentValue) => {
      if (currentValue) {
        return accumulator + 1;
      }
      return accumulator;
    }, 0) == 1 && Object.keys(actuarial_store.actuarial_claims_nature[val]).length > 1;
  mergable.value =
    Object.values(hashMapSelection.value).reduce((accumulator, currentValue) => {
      if (currentValue) {
        return accumulator + 1;
      }
      return accumulator;
    }, 0) > 1;
}

function splitClaimsNature() {
  let newMerged: any = [];
  for (const i in actuarial_store.actuarial_claims_nature) {
    if (hashMapSelection.value[i] == null || hashMapSelection.value[i] == false) {
      console.log(actuarial_store.actuarial_claims_nature[i]);
      newMerged.push(actuarial_store.actuarial_claims_nature[i]);
    } else {
      for (const j of actuarial_store.actuarial_claims_nature[i]) {
        newMerged.push([j]);
      }
    }
  }

  actuarial_store.changeActuarialClaimsNature(newMerged);
  onCloseClaimsTieringGroupModal();
}

function mergeAll() {
  let newMerged: any = [[]];
  for (const i in actuarial_store.actuarial_claims_nature) {
    for (const j of actuarial_store.actuarial_claims_nature[i]) {
      newMerged[0].push(j);
    }
  }
  actuarial_store.changeActuarialClaimsNature(newMerged);
  onCloseClaimsTieringGroupModal();
}

function splitAll() {
  let newMerged: any = [];
  for (const i in actuarial_store.actuarial_claims_nature) {
    for (const j of actuarial_store.actuarial_claims_nature[i]) {
      newMerged.push([j]);
    }
  }
  actuarial_store.changeActuarialClaimsNature(newMerged);
  onCloseClaimsTieringGroupModal();
}

function mergeClaimsNature() {
  console.log(actuarial_store.actuarial_claims_nature);
  let newMerged: any = [];
  let merge: any = [];
  for (const i in actuarial_store.actuarial_claims_nature) {
    if (hashMapSelection.value[i] == null || hashMapSelection.value[i] == false) {
      console.log(actuarial_store.actuarial_claims_nature[i]);
      newMerged.push(actuarial_store.actuarial_claims_nature[i]);
    } else {
      merge.push(...actuarial_store.actuarial_claims_nature[i]);
    }
  }

  if (merge.length > 0) {
    newMerged.push(merge);
  }

  actuarial_store.changeActuarialClaimsNature(newMerged);
  onCloseClaimsTieringGroupModal();
}

function toTitleCase(str: string) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}
</script>
<template>
  <el-dialog v-model="actuarial_store.tiering_group_modal" :before-close="onCloseClaimsTieringGroupModal">
    <template #header="{ close, titleId, titleClass }">
      <div class="my-header text-2xl">
        <h4 :id="titleId" class="titleClass">Claims Type Grouping</h4>
      </div>
    </template>
    <div v-if="actuarial_store.tiering_group_modal">
      <div class="hover:bg-gray-200 px-3" v-for="(itm, idx) in actuarial_store.actuarial_claims_nature">
        <el-checkbox :model-value="hashMapSelection[idx]" @change="(e: any) => selectionClaimsNature(e, idx)">{{
          toTitleCase(itm.join(', '))
        }}</el-checkbox>
      </div>
      <div class="flex justify-end">
        <el-button data-testid="split-all-claims-nature" @click="splitAll">Split All</el-button>
        <el-button data-testid="merge-all-claims-nature" @click="mergeAll">Merge All</el-button>
        <el-button type="info" :disabled="!splitable" class="flex flex-end" @click="splitClaimsNature">Split</el-button>

        <el-button type="primary" :disabled="!mergable" class="flex flex-end" @click="mergeClaimsNature"
          >Merge</el-button
        >
      </div>
    </div>
  </el-dialog>
</template>

<script setup lang="ts">
import InputText from "primevue/inputtext";
import { ref } from "vue"

const emit = defineEmits(['onCloseEditDraftModal', 'onSubmitNewBounceName']);
defineProps(['isEditDraftBounce'])

function onCloseEditDraftModal() {
    emit("onCloseEditDraftModal")
}

function submitNewBounceName() {
    console.log(bounceName.value)
    if (bounceName.value.trim() != "") {
        emit("onSubmitNewBounceName", {bounceName: bounceName.value.trim()})
    }
    
}

const bounceName = ref("")

</script>
<template>
    <el-dialog :model-value="isEditDraftBounce" :before-close="onCloseEditDraftModal"
        title="Edit Draft Name">
        <h1>Enter new draft name:</h1>
        <div class="p-inputgroup" style="width: 85%">
            <InputText v-model="bounceName" placeholder="New Draft Name" />
        </div>
        <template #footer>
            <span class="dialog-footer">
                <el-button type="primary" @click="submitNewBounceName()">Submit</el-button>
            </span>
        </template>
    </el-dialog>
</template>
import moment from "moment";

export default function formatDateWithDateUnit(dateUnit: string, date: string) {
    if (dateUnit == "year") {
        return moment(date, "MMM-YYYY").format("YYYY")
    } else if (dateUnit == "quarter") {
        return moment(date, "MMM-YYYY").format("[Q]Q-YYYY")
    } else if (dateUnit == "month") {
        return moment(date, "MMM-YYYY").format("MMM-YYYY")
    }

    return "Invalid"
}
<script setup lang="ts">
import { useGlobalStore } from '@/stores/global';
import { ref, computed, onMounted } from 'vue';
import { useDashboardStore } from '@/stores/dashboard';
import { usePortfolioStore } from '@/stores/portfolio';
import { targetCCR, targetNLR } from '@/calculations/Claims/TargetCalculation';

const dashboard_store = useDashboardStore();
const portfolio_store = usePortfolioStore();
const forwardlooking = computed(() =>
  dashboard_store.dashboards.ccr_nlr == 'CCR'
    ? portfolio_store.forwardLookings.map((x) => x[0] + x[1])
    : portfolio_store.forwardLookings.map((x) => x[0] / (1 - x[1]))
);
const global_store = useGlobalStore();
function onCloseTargetModal() {
  global_store.isTargetModal = false;
}
const oldParameters = ref();

onMounted(async () => {
  const parameters = portfolio_store.parameters;
  oldParameters.value = structuredClone(portfolio_store.parameters);
  if (parameters['target']) {
    console.log(parameters);
    if (parameters['target']['target_value']) {
      targetValue.value = parameters['target']['target_value'];
    }

    if (parameters['target']['target_commission']) {
      targetCommission.value = parameters['target']['target_commission'];
    }

    if (parameters['target']['overhead_value']) {
      overheadValue.value = parameters['target']['overhead_value'];
    }

    if (parameters['target']['ri_costs']) {
      ricosts.value = parameters['target']['ri_costs'];
    }
  }
});

function onSave() {
  if (!portfolio_store.parameters['target']) {
    portfolio_store.parameters['target'] = {};
  }

  portfolio_store.parameters['target']['target_value'] = targetValue.value;

  portfolio_store.parameters['target']['target_commission'] = targetCommission.value;

  portfolio_store.parameters['target']['overhead_value'] = overheadValue.value;

  portfolio_store.parameters['target']['ri_costs'] = ricosts.value;

  delete portfolio_store.parameters['target']['targetValue'];

  portfolio_store.saveParameters(oldParameters.value, 'Edited target');
}

const targetValue = ref(0);
const targetCommission = ref(0);
const overheadValue = ref(0);
const ricosts = ref(0);

function calculateCOR() {
  const total = forwardlooking.value[1] + overheadValue.value / 100 + ricosts.value / 100;
  if (!total) {
    return 'Invalid';
  }
  return (total * 100).toFixed(1);
}

function onTargetInput(e) {
  if (isFinite(e)) {
    targetValue.value = parseFloat(e);
  }
}

function onTargetCommission(e) {
  if (isFinite(e)) {
    targetCommission.value = parseFloat(e);
  }
}

function onOverheadValue(e) {
  if (isFinite(e)) {
    overheadValue.value = parseFloat(e);
  }
}

function onRICost(e) {
  if (isFinite(e)) {
    ricosts.value = parseFloat(e);
  }
}

// function openErrorMsg(msg: string) {
//   ElMessage.warning(msg);
// }
</script>

<template>
  <el-dialog :model-value="global_store.isTargetModal" :before-close="onCloseTargetModal" title="Target" width="800px">
    <div class="grid grid-cols-2 divide-x-2">
      <div>
        <div class="grid grid-cols-2">
          <div>
            <h3 class="text-xl">Target Gross LR</h3>
            <h3 class="text-xl mt-2">Target Commission</h3>
            <h3 class="text-xl mt-2">Overhead</h3>
            <h3 class="text-xl mt-2">RI costs</h3>
          </div>
          <div>
            <el-input
              :model-value="targetValue"
              @input="onTargetInput"
              class="ml-3 right-0 absolute"
              style="width: 150px; height: 30px"
              placeholder="Please input"
              type="number"
            >
              <template #append>%</template>
            </el-input>
            <el-input
              :model-value="targetCommission"
              @input="onTargetCommission"
              class="ml-3 right-0 absolute mt-2"
              style="width: 150px; height: 30px"
              placeholder="Please input"
              type="number"
            >
              <template #append>%</template>
            </el-input>
            <el-input
              :model-value="overheadValue"
              @input="onOverheadValue"
              class="ml-3 right-0 absolute mt-2"
              style="width: 150px; height: 30px"
              placeholder="Please input"
              type="number"
            >
              <template #append>%</template>
            </el-input>
            <el-input
              :model-value="ricosts"
              @input="onRICost"
              class="ml-3 right-0 absolute mt-2"
              style="width: 150px; height: 30px"
              placeholder="Please input"
              type="number"
            >
              <template #append>%</template>
            </el-input>
          </div>
        </div>
      </div>
      <div class="flex flex-col p-3">
        <h3 class="text-xl text-center">Target CCR: {{ targetCCR(targetValue, targetCommission).toFixed(1) }}%</h3>
        <h3 class="text-xl text-center mt-7">
          Target Net LR: {{ targetNLR(targetValue, targetCommission).toFixed(1) }}%
        </h3>
        <h3 class="text-xl text-center mt-7">COR: {{ calculateCOR() }}%</h3>
      </div>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button
          @click="onSave"
          :disabled="isNaN(overheadValue) || isNaN(ricosts) || isNaN(targetValue)"
          type="primary"
        >
          Save
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>

import { api } from '@/services/api';

type HierarchySelection = {
  [key: string]: number;
};

type ActuarialHierarchy = {
  [key: string]: {
    combinationKey: {
      [key: number]: string[];
    };
  };
};

type Filter = {
  [key: string]: string[];
};

function createFilters(
  hierarchies: string[],
  actuarialGrouping: string[],
  actuarialHierarchy: ActuarialHierarchy,
  hierarchySelection: HierarchySelection
) {
  const filter: Filter = {};

  for (const i of [...hierarchies, ...actuarialGrouping]) {
    const newHierarchyVal: string[] = [];

    for (const j of actuarialHierarchy[i]['combinationKey'][hierarchySelection[i]]) {
      newHierarchyVal.push(j);
    }

    filter[i] = newHierarchyVal;
  }

  return filter;
}

function getSelectedGroup(hierarchies: string[], actuarialGrouping: string[], hierarchySelection: HierarchySelection) {
  const selectedGroup: number[] = [];
  for (const i of [...hierarchies, ...actuarialGrouping]) {
    selectedGroup.push(hierarchySelection[i]);
  }
  return selectedGroup;
}

async function getStaticLinkRatiosFromAnotherGroup(
  selectedBounceID: string,
  selectedClaimsNature: string[],
  triangleType: string,
  reportDate: string,
  hierarchies: string[],
  actuarialGrouping: string[],
  hierarchySelection: HierarchySelection,
  actuarialHierarchy: ActuarialHierarchy
) {
  const filter = createFilters(hierarchies, actuarialGrouping, actuarialHierarchy, hierarchySelection);

  return api
    .post(import.meta.env.VITE_API_URL + 'actuarial/triangles', {
      bounce_id: selectedBounceID,
      claims_nature_selection: selectedClaimsNature,
      filters_hierarchy: filter,
      selected_group: getSelectedGroup(hierarchies, actuarialGrouping, hierarchySelection),
      report_date: reportDate,
      triangles_type: triangleType,
    })
    .then((res) => {
      //   console.log(res.data);
      const data: number[] = [];
      const linkRatios = res.data.data.ratios.link_ratios;
      const selectedlinkRatios = res.data.data.ratios.selected_link_ratios.rows[2];

      for (const index in selectedlinkRatios) {
        const selected = selectedlinkRatios[index];
        data.push(linkRatios[selected][index]);
      }
      return data;
    });
}

// Export your function here
export default getStaticLinkRatiosFromAnotherGroup;

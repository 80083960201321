<script setup lang="ts">
import { Minus, Plus } from '@element-plus/icons-vue';

const props = defineProps<{
  show: boolean;
}>();

const emit = defineEmits(['changeShowStatus']);

function changeShowStatus() {
  emit('changeShowStatus', !props.show);
}
</script>

<template>
  <el-icon class="dot cursor-pointer flex items-center justify-center" @click="changeShowStatus()">
    <Minus v-if="show" color="white" />
    <Plus v-if="!show" color="white" />
  </el-icon>
</template>

<style>
.dot {
  height: 15px;
  width: 15px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
}
</style>

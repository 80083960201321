import type { FinanceSummaryObject } from '@/types/finance';

export class FinanceMetricsFormulas {
  public static combineRatio(ccr: number, ohExpenses: number) {
    return ccr + ohExpenses;
  }

  public static gwpGrowth(gwpnwp: number, gwpGrowth: number) {
    return gwpnwp * gwpGrowth;
  }

  public static overheadExpenses(gwpgep: number, ohExpenses: number) {
    return gwpgep * ohExpenses;
  }
}

export class FinanceSummaryFormulas {
  public static netPremium(financeSummaryObject: FinanceSummaryObject) {
    return financeSummaryObject.GrossPremium - financeSummaryObject.Commission * financeSummaryObject.GrossPremium;
  }

  public static ultClaims(financeSummaryObject: FinanceSummaryObject) {
    return (
      Object.keys(financeSummaryObject.ClaimsUltimate).reduce(
        (x, el) => financeSummaryObject.ClaimsUltimate[el] + x,
        0
      ) * financeSummaryObject.GrossPremium
    );
  }

  public static totalExpensesExclOH(financeSummaryObject: FinanceSummaryObject) {
    return financeSummaryObject.DirectExpenses + financeSummaryObject.RLCosts + financeSummaryObject.ClaimsExpenses;
  }

  public static totalExpensesExclOHAndRL(financeSummaryObject: FinanceSummaryObject) {
    return financeSummaryObject.DirectExpenses + financeSummaryObject.ClaimsExpenses;
  }

  public static totalExpensesInclRL(financeSummaryObject: FinanceSummaryObject) {
    return (
      financeSummaryObject.OverheadExpenses +
      financeSummaryObject.DirectExpenses +
      financeSummaryObject.RLCosts +
      financeSummaryObject.ClaimsExpenses
    );
  }

  public static totalExpensesExclRL(financeSummaryObject: FinanceSummaryObject) {
    return (
      financeSummaryObject.OverheadExpenses + financeSummaryObject.DirectExpenses + financeSummaryObject.ClaimsExpenses
    );
  }

  public static grossProfit(financeSummaryObject: FinanceSummaryObject) {
    return financeSummaryObject.GrossPremium - financeSummaryObject.LossRatio;
  }

  public static net(financeSummaryObject: FinanceSummaryObject) {
    return (
      financeSummaryObject.GrossPremium -
      financeSummaryObject.Commission * financeSummaryObject.GrossPremium -
      financeSummaryObject.LossRatio * financeSummaryObject.GrossPremium -
      financeSummaryObject.RLCosts
    );
  }

  public static netGross(financeSummaryObject: FinanceSummaryObject) {
    return (
      financeSummaryObject.GrossPremium -
      financeSummaryObject.Commission * financeSummaryObject.GrossPremium -
      financeSummaryObject.LossRatio * financeSummaryObject.GrossPremium
    );
  }
}

import { ref, computed } from 'vue';
import { defineStore } from 'pinia';
import { api } from '@/services/api';
import { usePortfolioStore } from '@/stores/portfolio';
import { useGlobalStore } from '@/stores/global';
import { useAvEActuarialStore } from '@/stores/AvEActuarial';
import { ElMessage } from 'element-plus';
import findKeyByValue from '@/utilities/findKeyByValue';
import reverseSearch from '@/utilities/reverseSearch';
import { type LinkRatioObject } from '@/types/actuarial';
import type { PortfolioType } from '@/types/portfolio';
import type { ParametersObjectType } from '@/types/actuarial';
import configChunkGroupNamesForMirrored from '@/utilities/portfolioHelper';

/**
 * CONSTANTS
 */
export const REPORTING_PERIOD_CONST = ['Last 12 Months', 'Last 6 Months', 'Last 3 Months', 'Last Month'];
export const REPORTING_PERIOD_VALUE = [12, 6, 3, 1];
export const INCURRED_PAID_NUMBER = [
  'Incurred Claims Triangles',
  'Paid Claims Triangles',
  'Number of Claims Triangles',
];
export const INCURRED_PAID_NUMBER_VALUE = ['INCURRED', 'PAID', 'COUNT'];
export const SELECTION_FROM_PAGE = ['Triangulations', 'Results_Summary'];
export const COHORT_CONSTANT = ['Month', 'Quarter', 'Year'];
export const CLAIMS_REF = [
  'Paid Claims',
  'Incurred Claims',
  'A-priori',
  'CL Paid',
  'CL Incurred',
  'BF Paid',
  'BF Incurred',
];

export const CLAIMS_REF_TOOLTIP = [
  'Paid Claims',
  'Incurred Claims',
  'A-priori',
  'Ultimate claims calculated using chainladder method based on paid claims developments',
  'Ultimate claims calculated using chainladder method based on incurred claims developments',
  'Ultimate claims calculated using Bornhuetter Ferguson method based on paid claims developments',
  'Ultimate claims calculated using Bornhuetter Ferguson method based on incurred claims developments',
];

export const useActuarialStore = defineStore('actuarial', () => {
  /**
   * Actuarial adding average link ratio modal data
   */
  const averageLinkRatioCopyPaste = ref<LinkRatioObject[]>([]);
  const selectedStaticValue = ref('Link ratio');
  const staticValueType = ref('paste');
  const selectedAmountType = ref([]);
  const selectedDataType = ref('');
  const hierarchySelectionAddLinkRatio = ref({});
  const selectedBusinessAddLinkRatio = ref<PortfolioType>({
    name: '',
    id: '',
    tree: {
      name: '',
      nodeID: '',
      children: [],
    },
  });
  const selectedBusinessParametersAddLinkRatio = ref<ParametersObjectType>();
  const selectedBusinessDevsAddLinkRatio = ref<string[]>([]);
  const selectedBusinessCurrentMonthAddLinkRatio = ref<string>('');
  const selectedBusinessBounceIDAddLinkRatio = ref<string>('');
  const selectedBusinessActuarialHierarchyAddLinkRatio = ref({});
  const selectedBusinessHierarchySelectionAddLinkRatio = ref({});
  const customUltTableDesc = ref<string[]>([]);

  /**
   * End of Actuarial adding average link ratio modal data
   */
  const actuarial_AvE_store = useAvEActuarialStore();
  const reportingPeriodAVE = ref(-1);
  const graphComponentID = ref(0);
  const cohort = ref(0);
  const development = ref(0);
  const global_store = useGlobalStore();
  const portfolio_store = usePortfolioStore();
  const numberOfDev = ref([]);
  const trianglesJSON = ref(null);
  const devs = ref([]);
  const origins = ref<string[]>([]);
  const actuarialHierarchy: any = ref({});
  const actuarial_claims_nature: any = ref([]);
  const actuarial_claims_selection: any = ref(0);
  const is_parameters_fetch = ref(false);
  const tiering_group_modal = ref(false);
  const isHierarchyGroupingListModal = ref(false);
  const isHierarchyPercentageModal = ref(false);
  const currHierarchyOnHierarchyGroupingListModal = ref('');
  const hierarchySelection = ref({});
  const linkRatio = ref([]);
  const ratios: any = ref({});
  const actuarial_accident_underwriting = ref(0);
  const ult_table_data: any = ref(null);
  const ibnr_table: any = ref(null);
  const triangles_dates: any = ref({ month: [], quarter: [], year: [] });

  const customUltTableTitle = ref([]);
  const isLinkRatioChanged = ref(false);
  const isTriangleChanged = ref(false);
  const isUltimateSelectionChanged = ref(false);

  // testing
  const triangulationsExclusionTEST = ref([]);
  const triangleType = ref('INCURRED');

  const exclusionStack = ref({ exclude: [], include: [] });
  const claims_combinations_pc = ref(null);
  const actual_claims_combinations_pc = ref(null);

  const trianglesLoading = ref(false);
  const ratiosLoading = ref(false);
  const ultLoading = ref(false);

  /**
   * AvE Page Data
   */

  const actuarial_cache_reset_required = ref(false);

  function setActuarialCacheResetRequired(e: boolean) {
    actuarial_cache_reset_required.value = e;
  }

  /**
   * Add Average Link Ratio Modal
   */
  const averageLinkRatioModal = ref(false);
  const IBNRModal = ref(false);
  const curveFittingModal = ref(false);
  const fitDevFactors = ref(null);
  const fitDevFactorsName = ref('');

  function onChangeAverageLinkRatioModal(e: boolean) {
    averageLinkRatioModal.value = e;
  }

  function onChangeIBNRModal(e: boolean) {
    IBNRModal.value = e;
  }

  function onChangeCurveFittingModal(e: boolean) {
    curveFittingModal.value = e;
  }

  function setFitDevFactors(val: any, name: string) {
    fitDevFactors.value = val;
    fitDevFactorsName.value = name;
  }

  /**
   * REPORTING PERIOD
   */
  const reportingPeriod = ref(0);

  // Render Key
  const renderKeySidePanel = ref(0);

  async function onChangeReportingPeriod(e: number) {
    reportingPeriod.value = e;
    fetchTriangulationsData();
  }

  async function saveTriangulationsData() {
    if (
      (isTriangleChanged.value || isLinkRatioChanged.value || isUltimateSelectionChanged.value) &&
      !trianglesLoading.value
    ) {
      if (isTriangleChanged.value) {
        await triangleExclusion()
          .then(() => (isTriangleChanged.value = false))
          .catch(() => {
            trianglesLoading.value = false;
            ultLoading.value = false;
            portfolio_store.getSignedOff();
            openErrorMsg('Error! Please try again');
          });
      }
      if (isLinkRatioChanged.value) {
        await onSaveSelectionRatios()
          .then(() => (isLinkRatioChanged.value = false))
          .catch(() => {
            portfolio_store.getSignedOff();
            trianglesLoading.value = false;
            ultLoading.value = false;
            openErrorMsg('Error! Please try again');
          });
      }

      if (isUltimateSelectionChanged.value) {
        await onChangeultimateSelection()
          .then(() => (isUltimateSelectionChanged.value = false))
          .catch(() => {
            portfolio_store.getSignedOff();
            trianglesLoading.value = false;
            ultLoading.value = false;
            openErrorMsg('Error! Please try again');
          });
      }

      await fetchTriangulationsData().then(() => {
        trianglesLoading.value = false;
        ratiosLoading.value = false;
        ultLoading.value = false;
      });
    }
  }

  async function triangleExclusion() {
    console.time('executionTime');
    const filter = createFilters();
    console.timeEnd('executionTime');
    console.time('executionTime');
    let selected_group: number[] = [];
    for (const i of [
      ...portfolio_store.parameters['hierarchies'],
      ...portfolio_store.parameters['actuarial_grouping'],
    ]) {
      selected_group.push(hierarchySelection.value[i]);
    }
    console.timeEnd('executionTime');
    console.time('executionTime');
    portfolio_store.signedOffActuarialFrontend(false);
    trianglesLoading.value = true;
    ratiosLoading.value = true;
    ultLoading.value = true;
    await api
      .post(import.meta.env.VITE_API_URL + 'actuarial/exclusion-triangle', {
        bounce_id: portfolio_store.selectedBounceID,
        claims_nature_selection: actuarial_claims_nature.value[actuarial_claims_selection.value],
        filters_hierarchy: filter,
        parameters_json: portfolio_store.parameters,
        triangles_type: triangleType.value,
        all_months: portfolio_store.all_uw_dates['month'],
        trianglesJSON: trianglesJSON.value,
        development: development.value,
        t_cohort: cohort.value,
        selected_group: getSelectedGroup(),
        exclusion_data: exclusionStack.value,
      })
      .then(async (res) => {
        createDraft(res);
        // ratios.value = res.data.data.ratios
        console.timeEnd('executionTime');
      });
  }

  function createDraft(res) {
    if (portfolio_store.selectedBounceID != res.data.data.bounce_id) {
      portfolio_store.changeBounceID(res.data.data.bounce_id);
    }
  }

  async function removeIBNR(deleteIndex: any) {
    // global_store.setLoading(true)
    trianglesLoading.value = true;
    // ratiosLoading.value = true
    ultLoading.value = true;
    portfolio_store.signedOffActuarialFrontend(false);
    await api
      .delete(import.meta.env.VITE_API_URL + 'actuarial/ibnr-ratios', {
        data: {
          bounce_id: portfolio_store.selectedBounceID,
          triangles_type: triangleType.value,
          selected_group: getSelectedGroup(),
          claims_nature_selection: actuarial_claims_nature.value[actuarial_claims_selection.value],
          hierarchy: portfolio_store.parameters['hierarchies'],
          actuarial_grouping: portfolio_store.parameters['actuarial_grouping'],
          trianglesJSON: trianglesJSON.value,
          development: development.value,
          t_cohort: cohort.value,
          delete_index: deleteIndex,
        },
      })
      .then(async (res) => {
        createDraft(res);
        await fetchTriangulationsData().then(() => {
          trianglesLoading.value = false;
          ratiosLoading.value = false;
          ultLoading.value = false;
        });
        trianglesLoading.value = false;
        ultLoading.value = false;
      })
      .catch((err) => {
        // global_store.setLoading(false)
        trianglesLoading.value = false;
        // ratiosLoading.value = false
        ultLoading.value = false;
        portfolio_store.getSignedOff();
        if (err.response && err.response.data) {
          openErrorMsg(err.response.data.data);
        } else {
          openErrorMsg('Error! Please try again');
        }
      });
  }

  async function removeLinkRatios(deleteIndex: any) {
    // global_store.setLoading(true)
    trianglesLoading.value = true;
    // ratiosLoading.value = true
    ultLoading.value = true;
    portfolio_store.signedOffActuarialFrontend(false);
    await api
      .delete(import.meta.env.VITE_API_URL + 'actuarial/link-ratios', {
        data: {
          bounce_id: portfolio_store.selectedBounceID,
          triangles_type: triangleType.value,
          selected_group: getSelectedGroup(),
          claims_nature_selection: actuarial_claims_nature.value[actuarial_claims_selection.value],
          hierarchy: portfolio_store.parameters['hierarchies'],
          actuarial_grouping: portfolio_store.parameters['actuarial_grouping'],
          trianglesJSON: trianglesJSON.value,
          development: development.value,
          t_cohort: cohort.value,
          delete_index: deleteIndex,
        },
      })
      .then(async (res) => {
        createDraft(res);
        await fetchTriangulationsData().then(() => {
          trianglesLoading.value = false;
          ratiosLoading.value = false;
          ultLoading.value = false;
        });
        trianglesLoading.value = false;
        ultLoading.value = false;
      })
      .catch((err) => {
        // global_store.setLoading(false)
        trianglesLoading.value = false;
        // ratiosLoading.value = false
        ultLoading.value = false;
        portfolio_store.getSignedOff();
        if (err.response && err.response.data) {
          openErrorMsg(err.response.data.data);
        } else {
          openErrorMsg('Error! Please try again');
        }
      });
  }

  async function addIBNR(ratioType: any, constantArray: any) {
    // global_store.setLoading(true)
    trianglesLoading.value = true;
    // ratiosLoading.value = true
    ultLoading.value = true;
    const parsedconstantArray = constantArray.map((x) => parseFloat(x));
    portfolio_store.signedOffActuarialFrontend(false);
    await api
      .post(import.meta.env.VITE_API_URL + 'actuarial/ibnr-ratios', {
        bounce_id: portfolio_store.selectedBounceID,
        metadata: ratioType,
        constant_array: parsedconstantArray,
        triangles_type: triangleType.value,
        selected_group: getSelectedGroup(),
        claims_nature_selection: actuarial_claims_nature.value[actuarial_claims_selection.value],
        hierarchy: portfolio_store.parameters['hierarchies'],
        actuarial_grouping: portfolio_store.parameters['actuarial_grouping'],
        trianglesJSON: trianglesJSON.value,
        development: development.value,
        t_cohort: cohort.value,
      })
      .then(async (res) => {
        createDraft(res);
        await fetchTriangulationsData().then(() => {
          trianglesLoading.value = false;
          ratiosLoading.value = false;
          ultLoading.value = false;
        });
        trianglesLoading.value = false;
        ultLoading.value = false;
      })
      .catch((err) => {
        // global_store.setLoading(false)
        trianglesLoading.value = false;
        // ratiosLoading.value = false
        ultLoading.value = false;
        portfolio_store.getSignedOff();
        if (err.response && err.response.data) {
          openErrorMsg(err.response.data.data);
        } else {
          openErrorMsg('Error! Please try again');
        }
      });
  }

  async function addLinkRatios(ratioType: any, constantArray: any) {
    // global_store.setLoading(true)
    trianglesLoading.value = true;
    // ratiosLoading.value = true
    ultLoading.value = true;
    const parsedconstantArray = constantArray.map((x) => parseFloat(x));
    portfolio_store.signedOffActuarialFrontend(false);
    await api
      .post(import.meta.env.VITE_API_URL + 'actuarial/link-ratios', {
        bounce_id: portfolio_store.selectedBounceID,
        metadata: ratioType,
        constant_array: parsedconstantArray,
        triangles_type: triangleType.value,
        selected_group: getSelectedGroup(),
        claims_nature_selection: actuarial_claims_nature.value[actuarial_claims_selection.value],
        hierarchy: portfolio_store.parameters['hierarchies'],
        actuarial_grouping: portfolio_store.parameters['actuarial_grouping'],
        trianglesJSON: trianglesJSON.value,
        development: development.value,
        t_cohort: cohort.value,
      })
      .then(async (res) => {
        createDraft(res);
        await fetchTriangulationsData().then(() => {
          trianglesLoading.value = false;
          ratiosLoading.value = false;
          ultLoading.value = false;
        });
        trianglesLoading.value = false;
        ultLoading.value = false;
      })
      .catch((err) => {
        // global_store.setLoading(false)
        trianglesLoading.value = false;
        // ratiosLoading.value = false
        ultLoading.value = false;
        portfolio_store.getSignedOff();
        if (err.response && err.response.data) {
          openErrorMsg(err.response.data.data);
        } else {
          openErrorMsg('Error! Please try again');
        }
      });
  }

  async function updateTailFactor(index: number, val: number) {
    // global_store.setLoading(true)
    trianglesLoading.value = true;
    // ratiosLoading.value = true
    ultLoading.value = true;
    portfolio_store.signedOffActuarialFrontend(false);
    await api
      .post(import.meta.env.VITE_API_URL + 'actuarial/update-tail', {
        bounce_id: portfolio_store.selectedBounceID,
        triangles_type: triangleType.value,
        selected_group: getSelectedGroup(),
        claims_nature: actuarial_claims_nature.value[actuarial_claims_selection.value],
        hierarchy: portfolio_store.parameters['hierarchies'],
        actuarial_grouping: portfolio_store.parameters['actuarial_grouping'],
        trianglesJSON: trianglesJSON.value,
        development: development.value,
        t_cohort: cohort.value,
        factors_index: index,
        tail_val: val,
      })
      .then(async (res) => {
        createDraft(res);
        await fetchTriangulationsData().then(() => {
          trianglesLoading.value = false;
          ratiosLoading.value = false;
          ultLoading.value = false;
        });
        trianglesLoading.value = false;
        ultLoading.value = false;
      })
      .catch((err) => {
        // global_store.setLoading(false)
        trianglesLoading.value = false;
        // ratiosLoading.value = false
        ultLoading.value = false;
        portfolio_store.getSignedOff();
        if (err.response && err.response.data) {
          openErrorMsg(err.response.data.data);
        } else {
          openErrorMsg('Error! Please try again');
        }
      });
  }

  async function updateActuarialCache() {
    global_store.setLoading(true);
    // trianglesLoading.value = true
    // ratiosLoading.value = true
    // ultLoading.value = true
    portfolio_store.signedOffActuarialFrontend(false);
    await api
      .post(import.meta.env.VITE_API_URL + 'actuarial/update_actuarial_cache', {
        bounce_id: portfolio_store.selectedBounceID,
        triangles_type: triangleType.value,
        selected_group: getSelectedGroup(),
        claims_nature: actuarial_claims_nature.value[actuarial_claims_selection.value],
        hierarchy: portfolio_store.parameters['hierarchies'],
        actuarial_grouping: portfolio_store.parameters['actuarial_grouping'],
        trianglesJSON: trianglesJSON.value,
        development: development.value,
        t_cohort: cohort.value,
        ibnr_table: ibnr_table.value,
        ult_table: ult_table_data.value,
        ratios: ratios.value,
        report_date: portfolio_store.current_month,
        accident_underwriting: actuarial_accident_underwriting.value,
      })
      .then(async (res) => {
        // await fetchTriangulationsData().then(() => {
        //   global_store.setLoading(false)
        // })
        ibnr_table.value = JSON.parse(res.data.data.ibnr_table);
        global_store.setLoading(false);
        // trianglesLoading.value = false
        // // ratiosLoading.value = false
        // ultLoading.value = false
      })
      .catch((err) => {
        global_store.setLoading(false);
        trianglesLoading.value = false;
        // ratiosLoading.value = false
        ultLoading.value = false;
        portfolio_store.getSignedOff();
        if (err.response && err.response.data) {
          openErrorMsg(err.response.data.data);
        } else {
          openErrorMsg('Error! Please try again');
        }
      });
  }

  async function updateAccidentUnderwriting(e: any) {
    global_store.setLoading(true);
    await api
      .post(import.meta.env.VITE_API_URL + 'actuarial/change-accident-underwriting', {
        bounce_id: portfolio_store.selectedBounceID,
        accident_underwriting: e,
        hierarchy: portfolio_store.parameters['hierarchies'],
        actuarial_grouping: portfolio_store.parameters['actuarial_grouping'],
        claims_nature: portfolio_store.parameters['claims_nature'],

        report_date: portfolio_store.current_month,
        triangles_type: triangleType.value,
      })
      .then(async (res) => {
        createDraft(res);
        await fetchTriangulationsData();
        global_store.setLoading(false);
      })
      .catch(() => {
        global_store.setLoading(false);
        openErrorMsg('Error! Please try again');
      });
  }

  async function onChangeSelectionRatios(newSel: number, idx: any) {
    for (let ix of idx) {
      ratios.value['selected_link_ratios']['rows'][2][ix] = newSel;
    }

    isLinkRatioChanged.value = true;
  }

  async function onSaveSelectionRatios() {
    // global_store.setLoading(false)
    trianglesLoading.value = true;
    // ratiosLoading.value = false
    ultLoading.value = true;

    await api
      .post(import.meta.env.VITE_API_URL + 'actuarial/select-link-ratios', {
        bounce_id: portfolio_store.selectedBounceID,
        claims_nature_selection: actuarial_claims_nature.value[actuarial_claims_selection.value],
        selected_group: getSelectedGroup(),
        triangles_type: triangleType.value,
        data_to_be_added: ratios.value['selected_link_ratios']['rows'][2],
        hierarchy: portfolio_store.parameters['hierarchies'],
        actuarial_grouping: portfolio_store.parameters['actuarial_grouping'],
      })
      .then(async (res) => {
        createDraft(res);
      });
  }

  async function setHierarchySelection(i: any, val: any, selection_from: string) {
    global_store.setLoading(true);
    hierarchySelection.value[i] = val;
    if (selection_from == SELECTION_FROM_PAGE[0]) {
      await fetchTriangulationsData();
      global_store.setLoading(false);
    } else {
      await fetchTriangulationsData();
      global_store.setLoading(false);
    }
  }

  async function resetActuarialCache() {
    global_store.setLoading(true);
    await api
      .post(import.meta.env.VITE_API_URL + 'actuarial/reset_actuarial_cache', {
        bounce_id: portfolio_store.selectedBounceID,
        hierarchy: portfolio_store.parameters['hierarchies'],
        actuarial_grouping: portfolio_store.parameters['actuarial_grouping'],
        claims_nature: portfolio_store.parameters['claims_nature'],

        report_date: portfolio_store.current_month,
        triangles_type: triangleType.value,
      })
      .then((res) => {
        actuarial_cache_reset_required.value = false;
        global_store.setLoading(false);
      })
      .catch(() => {
        global_store.setLoading(false);
        openErrorMsg('Error! Please try again');
      });
  }

  async function setActuarialHierarchy(currHierarchy: any, groupNames: any, combinationKey: any) {
    actuarialHierarchy.value[currHierarchy] = {};
    actuarialHierarchy.value[currHierarchy]['groupNames'] = groupNames;
    actuarialHierarchy.value[currHierarchy]['combinationKey'] = combinationKey;
    renderKeySidePanel.value += 1;
    portfolio_store.isAve = false;
    global_store.setLoading(true);
    await api
      .post(import.meta.env.VITE_API_URL + 'actuarial/actuarial-chunk-grouping', {
        bounce_id: portfolio_store.selectedBounceID,
        added_type: 'hierarchy',
        data: structuredClone(actuarialHierarchy.value),
        hierarchy: portfolio_store.parameters['hierarchies'],
        actuarial_grouping: portfolio_store.parameters['actuarial_grouping'],
        claims_nature: portfolio_store.parameters['claims_nature'],

        report_date: portfolio_store.current_month,
        triangles_type: triangleType.value,
      })
      .then((res) => {
        createDraft(res);
      })
      .catch(() => {
        global_store.setLoading(false);
        openErrorMsg('Error! Please try again');
      });

    hierarchySelection.value[currHierarchy] = 0;
    fetchTriangulationsData();
  }

  function changeActuarialGroupingListModal(val: boolean) {
    isHierarchyGroupingListModal.value = val;
  }

  async function setPercentagleParameter(num: any) {
    global_store.setLoading(true);
    await api
      .post(import.meta.env.VITE_API_URL + 'actuarial/set-percentages', {
        bounce_id: portfolio_store.selectedBounceID,
        claims_hierarchy_index:
          portfolio_store.parameters.actuarial_grouping_keys[currHierarchyOnHierarchyGroupingListModal.value],
        claims_combination_pc: num,
      })
      .then(async (res) => {
        createDraft(res);
        setActuarialCacheResetRequired(true);
        await resetActuarialCache();
        await fetchTriangulationsData();
        isHierarchyPercentageModal.value = false;
        global_store.setLoading(false);
      })
      .catch((err) => {
        global_store.setLoading(false);
        portfolio_store.getSignedOff();
        if (err.response && err.response.data) {
          openErrorMsg(err.response.data.data);
        } else {
          openErrorMsg('Error! Please try again');
        }
      });
  }
  async function changeActuarialPercentageModal(val: boolean, key: string = '') {
    if (val) {
      global_store.setLoading(true);
      await api
        .post(import.meta.env.VITE_API_URL + 'actuarial/get-percentages', {
          bounce_id: portfolio_store.selectedBounceID,
          claims_hierarchy_index:
            portfolio_store.parameters.actuarial_grouping_keys[currHierarchyOnHierarchyGroupingListModal.value],
          claims_combination_pc: null,
        })
        .then(async (res) => {
          claims_combinations_pc.value = JSON.parse(res.data.data.pcs)[0];
          actual_claims_combinations_pc.value = res.data.data.actual_pcs;
          isHierarchyPercentageModal.value = val;
          global_store.setLoading(false);
        })
        .catch((err) => {
          global_store.setLoading(false);
          portfolio_store.getSignedOff();
          if (err.response && err.response.data) {
            openErrorMsg(err.response.data.data);
          } else {
            openErrorMsg('Error! Please try again');
          }
        });
    } else {
      isHierarchyPercentageModal.value = val;
      claims_combinations_pc.value = null;
      actual_claims_combinations_pc.value = null;
    }
  }

  function change_tiering_group_modal(val: boolean) {
    tiering_group_modal.value = val;
  }

  function openErrorMsg(msg: string) {
    ElMessage({
      message: msg,
      type: 'error',
    });
  }

  async function changeActuarialClaimsNature(val: any) {
    global_store.setLoading(true);
    portfolio_store.isAve = false;
    renderKeySidePanel.value += 1;
    await api
      .post(import.meta.env.VITE_API_URL + 'actuarial/actuarial-chunk-grouping', {
        bounce_id: portfolio_store.selectedBounceID,
        added_type: 'claims_nature',
        data: val,
        hierarchy: portfolio_store.parameters['hierarchies'],
        actuarial_grouping: portfolio_store.parameters['actuarial_grouping'],
        claims_nature: portfolio_store.parameters['claims_nature'],

        report_date: portfolio_store.current_month,
        triangles_type: triangleType.value,
      })
      .then((res) => {
        createDraft(res);
      })
      .catch(() => {
        global_store.setLoading(false);
        openErrorMsg('Error! Please try again');
      });
    actuarial_claims_nature.value = val;
    actuarial_claims_selection.value = 0;
    fetchTriangulationsData();
  }

  function configChunkGroupNamesIfMirrored(actuarialHierarchy) {
    return configChunkGroupNamesForMirrored(
      actuarialHierarchy,
      portfolio_store.parameters['hierarchies'],
      portfolio_store.originalDictionary,
      portfolio_store.dictionary
    );
  }

  async function getActuarialChunkGrouping() {
    await api
      .get(import.meta.env.VITE_API_URL + 'actuarial/get-actuarial-chunk-grouping', {
        params: {
          bounce_id: portfolio_store.selectedBounceID,
        },
      })
      .then((res) => {
        if (res.data.data['variable'].actuarial_hierarchy) {
          actuarialHierarchy.value = res.data.data['variable'].actuarial_hierarchy;
        }

        if (res.data.data['variable'].actuarial_claims_nature) {
          actuarial_claims_nature.value = res.data.data['variable'].actuarial_claims_nature;
        } else {
          actuarial_claims_nature.value = [];
          for (const i of portfolio_store.parameters['claims_nature']) {
            actuarial_claims_nature.value.push([i]);
          }
        }
        actuarial_claims_selection.value = 0;

        hierarchySelection.value = {};
        for (const i of [
          ...portfolio_store.parameters['hierarchies'],
          ...portfolio_store.parameters['actuarial_grouping'],
        ]) {
          hierarchySelection.value[i] = 0;
        }

        if (portfolio_store.isMirrored) {
          actuarialHierarchy.value = configChunkGroupNamesIfMirrored(actuarialHierarchy.value);
        }

        is_parameters_fetch.value = true;
      })
      .catch((err) => {
        console.log(err);
        openErrorMsg('Error! Please try again');
      });
  }

  function createFilters() {
    let filter = {};

    for (const i of [
      ...portfolio_store.parameters['hierarchies'],
      ...portfolio_store.parameters['actuarial_grouping'],
    ]) {
      let newHierarchyVal: any = [];

      for (const j of actuarialHierarchy.value[i]['combinationKey'][hierarchySelection.value[i]]) {
        newHierarchyVal.push(j);
      }

      filter[i] = newHierarchyVal;
    }

    return filter;
  }

  async function setDevelopment(e: any) {
    development.value = e;
    setCohortDevelopment();
  }

  async function setCohort(e: any) {
    cohort.value = e;
    if (development.value > cohort.value) {
      development.value = cohort.value;
    }
    await setCohortDevelopment();
  }

  async function setCohortDevelopment() {
    global_store.setLoading(true);
    await api
      .post(import.meta.env.VITE_API_URL + 'actuarial/cohort-development', {
        bounce_id: portfolio_store.selectedBounceID,
        cohort: cohort.value,
        development: development.value,
        hierarchy: portfolio_store.parameters['hierarchies'],
        actuarial_grouping: portfolio_store.parameters['actuarial_grouping'],
        claims_nature: portfolio_store.parameters['claims_nature'],

        report_date: portfolio_store.current_month,
        triangles_type: triangleType.value,
      })
      .then(async (res) => {
        createDraft(res);
        cohort.value = res.data.data.cohort;
        development.value = res.data.data.development;
        await fetchTriangulationsData();
        global_store.setLoading(false);
      })
      .catch(() => {
        global_store.setLoading(false);
        openErrorMsg('Error! Please try again');
      });
  }

  async function fetchCohortDevelopment() {
    await api
      .get(import.meta.env.VITE_API_URL + 'actuarial/cohort-development', {
        params: {
          bounce_id: portfolio_store.selectedBounceID,
        },
      })
      .then((res) => {
        cohort.value = res.data.data.cohort;
        development.value = res.data.data.development;
        exclusionStack.value = { exclude: [], include: [] };
      })
      .catch(() => {
        global_store.setLoading(false);
        openErrorMsg('Error! Please try again');
      });
  }

  async function fetchTriangulationsData() {
    const filter = createFilters();
    await fetchCohortDevelopment();

    if (portfolio_store.isAve) {
      await actuarial_AvE_store.checkEligible();
    }

    if (portfolio_store.isAve && actuarial_AvE_store.isActuarialAveEligible) {
      actuarial_AvE_store.fetchTriangulationsData();
    }

    await api
      .post(import.meta.env.VITE_API_URL + 'actuarial/triangles', {
        bounce_id: portfolio_store.selectedBounceID,
        claims_nature_selection: actuarial_claims_nature.value[actuarial_claims_selection.value],
        filters_hierarchy: filter,
        selected_group: getSelectedGroup(),
        report_date: portfolio_store.current_month,
        triangles_type: triangleType.value,
      })
      .then((res) => {
        trianglesJSON.value = JSON.parse(res.data.data.triangles_json);
        origins.value = res.data.data.origins;
        devs.value = res.data.data.devs;

        numberOfDev.value = res.data.data.n_devs;
        ratios.value = res.data.data.ratios;
        actuarial_accident_underwriting.value = res.data.data.actuarial_accident_underwriting;
        triangles_dates.value = res.data.data.dates;
        ult_table_data.value = JSON.parse(res.data.data.ult_table_data.ult_table);
        customUltTableTitle.value = res.data.data.ult_table_data.custom_ult_table;
        customUltTableDesc.value = res.data.data.ult_table_data.custom_ult_description;
        ibnr_table.value = JSON.parse(res.data.data.ult_table_data.monthly_ult_table);
        global_store.setLoading(false);
        graphComponentID.value += 1;
      })
      .catch((err) => {
        global_store.setLoading(false);
        console.log(err);
        openErrorMsg('Error! Please try again');
      });
  }

  async function setActuarialClaimsSelection(e: any, selection_from: string) {
    global_store.setLoading(true);
    actuarial_claims_selection.value = e;
    if (selection_from == SELECTION_FROM_PAGE[0]) {
      await fetchTriangulationsData();
      global_store.setLoading(false);
    } else {
      await fetchTriangulationsData();
      global_store.setLoading(false);
    }
  }

  function changeCurrHierarchyOnHierarchyGroupingListModal(currHierarchy: string) {
    currHierarchyOnHierarchyGroupingListModal.value = currHierarchy;
  }

  async function onSetTriangleType(e: any, selection_from: string) {
    global_store.setLoading(true);
    triangleType.value = e;
    if (selection_from == SELECTION_FROM_PAGE[0]) {
      await fetchTriangulationsData();
      global_store.setLoading(false);
    } else {
      await fetchTriangulationsData();
      global_store.setLoading(false);
    }
  }

  function getSelectedGroup() {
    let selected_group: number[] = [];
    for (const i of [
      ...portfolio_store.parameters['hierarchies'],
      ...portfolio_store.parameters['actuarial_grouping'],
    ]) {
      selected_group.push(hierarchySelection.value[i]);
    }
    return selected_group;
  }

  async function onChangeultimateSelection() {
    // global_store.setLoading(true)
    trianglesLoading.value = true;
    // ratiosLoading.value = true
    // ultLoading.value = true
    let selected_ult = Object.values(ult_table_data.value['selected_ult']);
    let allocation_method = Object.values(ult_table_data.value['allocation']);
    portfolio_store.signedOffActuarialFrontend(false);
    await api
      .post(import.meta.env.VITE_API_URL + 'actuarial/select-ultimate-claims', {
        bounce_id: portfolio_store.selectedBounceID,
        claims_nature_selection: actuarial_claims_nature.value[actuarial_claims_selection.value],
        selected_group: getSelectedGroup(),
        triangles_type: triangleType.value,
        hierarchy: portfolio_store.parameters['hierarchies'],
        actuarial_grouping: portfolio_store.parameters['actuarial_grouping'],
        selected_ult: selected_ult,
        allocation_method: allocation_method,
      })
      .then(async (res) => {
        createDraft(res);

        trianglesLoading.value = false;
        // await fetchTriangulationsData().then(() => {
        // global_store.setLoading(false)
        // ratiosLoading.value = false
        // ultLoading.value = false
        // })
      })
      .catch((err) => {
        console.log(err);
        portfolio_store.getSignedOff();
        // global_store.setLoading(false)
        trianglesLoading.value = false;
        // ratiosLoading.value = false
        // ultLoading.value = false
        openErrorMsg('Error! Please try again');
      });
  }

  function setReportingPeriodAve(e: number) {
    reportingPeriodAVE.value = e;
  }

  function updateExclusionDiagnonalFrontend(x: number, y: number) {
    let tempX = x;
    let tempY = y;
    let tempArr: any = [];
    while (tempX >= 0) {
      tempArr.push([tempX, tempY]);
      tempX -= 1;
      tempY += 1;
    }

    tempX = x + 1;
    tempY = y - 1;

    while (tempY >= 0) {
      tempArr.push([tempX, tempY]);
      tempX += 1;
      tempY -= 1;
    }
    let val = '';
    for (const i of tempArr) {
      val += trianglesJSON.value[Object.keys(trianglesJSON.value)[i[0]]][i[1] + 1][1];
    }
    if (val == '') {
      val = 'excluded';
    } else {
      val = '';
    }

    for (const i of tempArr) {
      trianglesJSON.value[Object.keys(trianglesJSON.value)[i[0]]][i[1] + 1][1] = val;
    }

    return val;
  }

  function updateExclusionFrontend(cohort: number, report_date: number) {
    let val = trianglesJSON.value[Object.keys(trianglesJSON.value)[cohort]][report_date + 1][1];

    if (val == 'excluded') {
      val = '';
    } else {
      val = 'excluded';
    }

    trianglesJSON.value[Object.keys(trianglesJSON.value)[cohort]][report_date + 1][1] = val;

    return val;
  }

  function setTrianglesLoading(val: boolean) {
    trianglesLoading.value = val;
  }

  function areSetsEqual<T>(setA: Set<T>, setB: Set<T>): boolean {
    // Quick check to see if their sizes are different
    if (setA.size !== setB.size) return false;

    // Check if every element in setA is also in setB
    for (let item of setA) {
      if (!setB.has(item)) {
        return false; // Found an item that is not in both sets
      }
    }

    // If we've got here, the sets have the same size and elements
    return true;
  }

  function exclusionStackAppend(val: any, isExclude: string) {
    isTriangleChanged.value = true;
    if (isExclude == 'excluded') {
      exclusionStack.value['exclude'].push(val);

      const valZeroSet = new Set(val[0]);
      const valOneSet = new Set(val[1]);

      let temp = [];

      for (let i = 0; i < exclusionStack.value['include'].length; i++) {
        const elZeroSet = new Set(exclusionStack.value['include'][i][0]);
        const elOneSet = new Set(exclusionStack.value['include'][i][1]);

        if (!(areSetsEqual(valZeroSet, elZeroSet) && areSetsEqual(valOneSet, elOneSet))) {
          temp.push([exclusionStack.value['include'][i][0], exclusionStack.value['include'][i][1]]);
        }
      }

      exclusionStack.value['include'] = temp;
      // exclusionStack.value['include'][1] = exclusionStack.value['include'][1].filter((el, idx) => {
      //   const elSet = new Set(el)

      //   return !(areSetsEqual(valOneSet, elSet))
      // })
    } else {
      const valZeroSet = new Set(val[0]);
      const valOneSet = new Set(val[1]);

      let temp = [];

      for (let i = 0; i < exclusionStack.value['exclude'].length; i++) {
        const elZeroSet = new Set(exclusionStack.value['exclude'][i][0]);
        const elOneSet = new Set(exclusionStack.value['exclude'][i][1]);

        if (!(areSetsEqual(valZeroSet, elZeroSet) && areSetsEqual(valOneSet, elOneSet))) {
          temp.push([exclusionStack.value['exclude'][i][0], exclusionStack.value['exclude'][i][1]]);
        }
      }

      exclusionStack.value['exclude'] = temp;

      exclusionStack.value['include'].push(val);
    }
  }

  async function fit(val: string) {
    global_store.setLoading(true);
    let facotrs = fitDevFactors.value.map((x) => x['value'] / 100);
    await api
      .post(import.meta.env.VITE_API_URL + 'actuarial/fit-cdf', {
        bounce_id: portfolio_store.selectedBounceID,
        method: val,
        factors: facotrs,
      })
      .then(async (res) => {
        let data = res.data.data.fitted_dev;
        for (let i in fitDevFactors.value) {
          fitDevFactors.value[i]['smooth'] = data[i] * 100;
        }
        global_store.setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        global_store.setLoading(false);
        openErrorMsg("Fitting couldn't be done on this pattern");
      });
  }
  return {
    fetchTriangulationsData,
    numberOfDev,
    trianglesJSON,
    devs,
    origins,
    getActuarialChunkGrouping,
    actuarialHierarchy,
    actuarial_claims_nature,
    actuarial_claims_selection,
    setActuarialClaimsSelection,
    is_parameters_fetch,
    tiering_group_modal,
    change_tiering_group_modal,
    changeActuarialClaimsNature,
    isHierarchyGroupingListModal,
    changeActuarialGroupingListModal,
    isHierarchyPercentageModal,
    changeActuarialPercentageModal,
    setActuarialHierarchy,
    currHierarchyOnHierarchyGroupingListModal,
    changeCurrHierarchyOnHierarchyGroupingListModal,
    hierarchySelection,
    setHierarchySelection,
    triangulationsExclusionTEST,
    linkRatio,
    fetchCohortDevelopment,
    cohort,
    development,
    setCohortDevelopment,
    setCohort,
    setDevelopment,
    ratios,
    onChangeSelectionRatios,
    ult_table_data,
    actuarial_accident_underwriting,
    updateAccidentUnderwriting,
    triangleType,
    onSetTriangleType,
    reportingPeriod,
    onChangeReportingPeriod,
    onChangeAverageLinkRatioModal,
    onChangeIBNRModal,
    averageLinkRatioModal,
    IBNRModal,
    onChangeCurveFittingModal,
    curveFittingModal,
    setFitDevFactors,
    fitDevFactors,
    fitDevFactorsName,
    triangleExclusion,
    triangles_dates,
    graphComponentID,
    onChangeultimateSelection,
    addLinkRatios,
    reportingPeriodAVE,
    setReportingPeriodAve,
    createFilters,
    updateExclusionFrontend,
    updateExclusionDiagnonalFrontend,
    trianglesLoading,
    ratiosLoading,
    ultLoading,
    setTrianglesLoading,
    exclusionStack,
    exclusionStackAppend,
    fit,
    updateTailFactor,
    ibnr_table,
    getSelectedGroup,
    updateActuarialCache,
    claims_combinations_pc,
    actual_claims_combinations_pc,
    setPercentagleParameter,
    resetActuarialCache,
    actuarial_cache_reset_required,
    createDraft,
    setActuarialCacheResetRequired,
    removeLinkRatios,
    openErrorMsg,
    saveTriangulationsData,
    isUltimateSelectionChanged,
    isLinkRatioChanged,
    isTriangleChanged,
    averageLinkRatioCopyPaste,
    selectedStaticValue,
    staticValueType,
    selectedAmountType,
    selectedDataType,
    hierarchySelectionAddLinkRatio,
    selectedBusinessAddLinkRatio,
    selectedBusinessParametersAddLinkRatio,
    selectedBusinessDevsAddLinkRatio,
    selectedBusinessCurrentMonthAddLinkRatio,
    selectedBusinessBounceIDAddLinkRatio,
    selectedBusinessActuarialHierarchyAddLinkRatio,
    selectedBusinessHierarchySelectionAddLinkRatio,
    addIBNR,
    customUltTableTitle,
    customUltTableDesc,
    removeIBNR,
    renderKeySidePanel,
    configChunkGroupNamesIfMirrored,
  };
});

import type { FinanceRawDataType, FinanceDataObj, MetricsObj } from '@/types/finance';
import ClaimsCalculation from '@/calculations/Claims/ClaimsCalculation';
import type { ParametersObjectType } from '@/types/portfolio';
import { METRICS_VALUE_LOC } from '@/constants/finance';
import { FinanceMetricsFormulas } from './FinanceFormulas';
import { safeDivide } from '@/utilities/calculationHelper';

enum ExtractDataType {
  UNDERWRITING = 'uw',
  ACCIDENT = 'acc',
}

export default class FinanceAPIDataTransformation {
  financeRawData: FinanceRawDataType;
  parameters: ParametersObjectType;
  uwMonth: string[];
  accMonth: string[];
  businessMetrics: MetricsObj;

  constructor(
    financeRawData: FinanceRawDataType,
    parameters: ParametersObjectType,
    uwMonth: string[],
    accMonth: string[],
    businessMetrics: MetricsObj
  ) {
    this.financeRawData = financeRawData;
    this.parameters = parameters;
    this.uwMonth = uwMonth;
    this.accMonth = accMonth;
    this.businessMetrics = businessMetrics;
  }

  getUnderwritingData() {
    return this.extractData(ExtractDataType.UNDERWRITING);
  }

  getAccidentData() {
    return this.extractData(ExtractDataType.ACCIDENT);
  }

  getAccidentDate() {
    return this.accMonth.slice(0, this.financeRawData.accident_data.data.length).flat();
  }

  getUnderwritingDate() {
    return this.uwMonth.slice(0, this.financeRawData.underwriting_data.data.length).flat();
  }

  extractData(dataType: ExtractDataType) {
    const months = dataType == ExtractDataType.ACCIDENT ? this.accMonth : this.uwMonth;
    const extracttedData =
      dataType == ExtractDataType.ACCIDENT ? this.financeRawData.accident_data : this.financeRawData.underwriting_data;
    const ccrNlrParameters = dataType == ExtractDataType.ACCIDENT ? 'acc' : 'uw';
    const claimsCalculation = new ClaimsCalculation(
      extracttedData.data,
      extracttedData.column,
      this.parameters.claims_nature
    );
    const newFinanceData: FinanceDataObj = {};

    for (const monthIdx in extracttedData.data) {
      const month = months[monthIdx];

      const GWP_GEP =
        dataType == ExtractDataType.UNDERWRITING
          ? claimsCalculation.gwpNWPAmount(parseInt(monthIdx), 'GWP')
          : claimsCalculation.gepAmount(parseInt(monthIdx));

      newFinanceData[month] = {
        // CCR
        CCR: claimsCalculation.ccr(parseInt(monthIdx), ccrNlrParameters, 'Written'),
        CCROriginal: claimsCalculation.ccr(parseInt(monthIdx), ccrNlrParameters, 'Written'),
        NLR: claimsCalculation.nlr(parseInt(monthIdx), ccrNlrParameters, 'Written'),
        // CCR + businessMetrics[Overhead Expenses]
        CombineRatio: FinanceMetricsFormulas.combineRatio(
          claimsCalculation.ccr(parseInt(monthIdx), ccrNlrParameters, 'Written'),
          this.businessMetrics.OHExpenses[METRICS_VALUE_LOC]
        ),
        // this.businessMetrics.OHExpenses[METRICS_VALUE_LOC],
        // GWP || GEP
        GWP_GEP: GWP_GEP,

        // GWP * businessMetrics[GWPGrowth]
        GWPGrowth:
          dataType == ExtractDataType.ACCIDENT
            ? 0
            : FinanceMetricsFormulas.gwpGrowth(
                claimsCalculation.gwpNWPAmount(parseInt(monthIdx), 'GWP'),
                this.businessMetrics.GWPGrowth[METRICS_VALUE_LOC]
              ),
        // businessMetrics[LR Load] * GWP
        LRLoad: this.businessMetrics.LRLoad[METRICS_VALUE_LOC] * GWP_GEP,
        // All Loss Ratio: Att, Lrg, Cat.....
        LossRatio: this.parameters.claims_nature.reduce(
          (x, claims) =>
            x +
            safeDivide(claimsCalculation.ultimate(parseInt(monthIdx), ccrNlrParameters, 'Written', claims), GWP_GEP),
          0
        ),
        LossRatioOriginal: this.parameters.claims_nature.reduce(
          (x, claims) =>
            x +
            safeDivide(claimsCalculation.ultimate(parseInt(monthIdx), ccrNlrParameters, 'Written', claims), GWP_GEP),
          0
        ),
        // GWP * businessMetrics[Overhead Expenses] (need to be checked)
        OverheadExpenses: FinanceMetricsFormulas.overheadExpenses(
          GWP_GEP,
          this.businessMetrics.OHExpenses[METRICS_VALUE_LOC]
        ),
        ClaimsUltimate: {},
        Commission: safeDivide(claimsCalculation.commission(parseInt(monthIdx)), GWP_GEP),
        GEP: claimsCalculation.gepAmount(parseInt(monthIdx)),
      };
      //   const month = this.uwMonth[monthIdx];

      this.parameters.claims_nature.map(
        (claims) =>
          (newFinanceData[month].ClaimsUltimate[claims] = safeDivide(
            claimsCalculation.ultimate(parseInt(monthIdx), ccrNlrParameters, 'Written', claims),
            GWP_GEP
          ))
      );
    }
    console.log(newFinanceData);
    return newFinanceData;
  }
}

<script setup lang="ts">
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';
import { RefreshLeft } from '@element-plus/icons-vue';
import { useActionsStore } from '@/stores/actions';
import moment from 'moment';
import type { ActionData } from '@/types/actions';
import { onMounted } from 'vue';

onMounted(() => {
  getActionList();
});

const actionsStore = useActionsStore();

function openStatusModal() {
  actionsStore.isEditStatusModal = true;
}

function openAddActionsModal() {
  actionsStore.isAddActionsFormModal = true;
}

async function getActionList() {
  actionsStore.fetchAllData();
}

function onChangePage() {
  getActionList();
}

function moreDetailsModal(data: ActionData) {
  // actionsStore.isMoreDetailsModal = true;
  actionsStore.selectedData = data;
  actionsStore.isMoreDetailsOrNewAction = true;
  actionsStore.isAddActionsFormModal = true;
}
</script>
<template>
  <div>
    <el-button @click="openStatusModal">Edit status</el-button>
    <el-button @click="openAddActionsModal">Add new actions</el-button>
    <el-button class="absolute right-6" @click="getActionList"
      ><el-icon><RefreshLeft /></el-icon
    ></el-button>
    <DataTable
      class="mt-4"
      :value="actionsStore.actionsList"
      data-key="_id"
      responsive-layout="scroll"
      :loading="actionsStore.isTableLoading"
    >
      <template #empty> No outstanding lists. </template>
      <template #loading> Loading list. Please wait. </template>
      <Column field="title" header="Title">
        <template #body="{ data }">
          {{ data.title }}
        </template>
      </Column>
      <Column field="owner" header="Owner">
        <template #body="{ data }">
          <div>{{ data.owner.first_name + ' ' + data.owner.last_name }}</div>
        </template>
      </Column>
      <Column field="assigned_to" header="Assigned To">
        <template #body="{ data }">
          {{ data.assigned_to ? data.assigned_to.first_name + ' ' + data.assigned_to.last_name : 'Not Assigned' }}
        </template>
      </Column>
      <Column field="status" header="Status">
        <template #body="{ data }">
          <div class="flex flex-row">
            <p v-if="data.status" :style="{ color: data.status.color }">
              {{ data.status.name }}
            </p>
            <p v-else>No Status</p>
          </div>
        </template>
      </Column>
      <Column field="due_date" header="Due Date">
        <template #body="{ data }">
          <div class="flex flex-row">
            <p v-if="data.due_date">
              {{ moment(data.due_date).format('DD/MM/YYYY') }}
            </p>
            <p v-else>No due date</p>
          </div>
        </template>
      </Column>
      <Column header-style="text-align: center" body-style="text-align: center; overflow: visible">
        <template #body="{ data }">
          <Button type="button" label="More Details" class="bg-sybil-teal" @click="moreDetailsModal(data)"></Button>
        </template>
      </Column>
    </DataTable>
    <el-pagination
      v-if="actionsStore.pagination"
      v-model:current-page="actionsStore.currentPage"
      layout="prev, pager, next"
      :total="actionsStore.pagination.total_count"
      :page-size="15"
      class="mt-3"
      @current-change="onChangePage"
    />
  </div>
</template>

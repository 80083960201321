<script setup lang="ts">
import { ref, onMounted } from 'vue';
import { useGlobalStore } from '@/stores/global';
import InputNumber from 'primevue/inputnumber';

const globalStore = useGlobalStore();
function onCloseModal() {
  globalStore.setLargeThresholdMethodModal(false);
}

onMounted(() => {
  getModelParameters();
});

const parameters = ref([]);
const parametersCol = ref([]);

async function getModelParameters() {
  globalStore.setLoading(true);
  globalStore.getModelParameters().then((res) => {
    parameters.value = res[0];
    parametersCol.value = res[1];
  });
}

async function postModelParameters() {
  globalStore.setLoading(true);
  globalStore.postModelParameters(parameters.value);
}
</script>
<template>
  <el-dialog
    :before-close="onCloseModal"
    :model-value="globalStore.isLargeThresholdMethodModal"
    title="Large Threshold & Method"
    width="650px"
  >
    <div
      v-if="parameters.length > 0"
      class="grid grid-cols-2 divide-x items-center content-center justify-items-center"
    >
      <div class="content-center">
        <h1>Large Threshold</h1>
        <InputNumber v-model="parameters[1]" input-id="integeronly" class="h-4 mt-2" />
      </div>
      <div class="content-center">
        <div class="ml-4">
          <h1>Large Method</h1>
          <el-radio-group v-model="parameters[0]" class="ml-4">
            <el-radio label="FGU" size="large">From Ground Up</el-radio>
            <el-radio label="XS" size="large">Above Threshold</el-radio>
          </el-radio-group>
        </div>
      </div>
    </div>
    <template #footer>
      <el-button type="primary" @click="postModelParameters()">Confirm</el-button>
    </template>
  </el-dialog>
</template>

import type { ActionData, StatusObject } from '@/types/actions';
import { defineStore } from 'pinia';
import { ref } from 'vue';
import { api } from '@/services/api';
import errorHandler from '@/utilities/errorhandler';
import { useGlobalStore } from '@/stores/global';
import type { UserObj, StatusCreationObject, ActionDataCreationObject } from '@/types/actions';

export const useActionsStore = defineStore('actions', () => {
  const globalStore = useGlobalStore();
  const currentPage = ref(1);
  const pagination = ref();
  const isEditStatusModal = ref(false);
  const isAddActionsFormModal = ref(false);
  const isMoreDetailsModal = ref(false);
  const actionsList = ref<ActionData[]>([]);
  const isTableLoading = ref(false);
  const statusList = ref<StatusObject[]>([]);
  const userList = ref<UserObj[]>([]);

  const isMoreDetailsOrNewAction = ref(false);
  const selectedData = ref<ActionData | null>(null);

  async function addNewAction(data: ActionDataCreationObject) {
    globalStore.isLoading = true;
    await api
      .post(import.meta.env.VITE_API_URL + 'actions/actions', data)
      .then(async () => {
        await fetchActionsData();
        globalStore.isLoading = false;
      })
      .catch((error) => {
        errorHandler(error);
        globalStore.isLoading = false;
      });
  }

  async function deleteStatus(id: string) {
    globalStore.isLoading = true;
    await api
      .delete(import.meta.env.VITE_API_URL + 'actions/status', {
        data: {
          id: id,
        },
      })
      .then(async () => {
        await fetchStatusList();
        globalStore.isLoading = false;
      })
      .catch((error) => {
        errorHandler(error);
        globalStore.isLoading = false;
      });
  }

  async function fetchActionsData() {
    globalStore.isLoading = true;
    await api
      .get(import.meta.env.VITE_API_URL + 'actions/actions', {
        params: {
          limit: 15,
          offset: (currentPage.value - 1) * 1,
        },
      })
      .then((response) => {
        console.log(response.data);
        actionsList.value = response.data.data[0].actions_list;
        pagination.value = response.data.data[0].pagination;
        console.log(pagination.value);
        globalStore.isLoading = false;
      })
      .catch((error) => {
        errorHandler(error);
        globalStore.isLoading = false;
      });
  }

  async function addStatus(data: StatusCreationObject) {
    globalStore.isLoading = true;
    await api
      .post(import.meta.env.VITE_API_URL + 'actions/status', data)
      .then(async () => {
        await fetchStatusList();
        globalStore.isLoading = false;
      })
      .catch((error) => {
        errorHandler(error);
        globalStore.isLoading = false;
      });
  }

  async function fetchStatusList() {
    await api
      .get(import.meta.env.VITE_API_URL + 'actions/status')
      .then((response) => {
        statusList.value = response.data.data;
      })
      .catch((error) => {
        errorHandler(error);
      });
  }

  async function patchActions(data: ActionData) {
    await api
      .patch(import.meta.env.VITE_API_URL + 'actions/actions', data)
      .then(async () => {
        await fetchActionsData();
        globalStore.isLoading = false;
      })
      .catch((error) => {
        errorHandler(error);
        globalStore.isLoading = false;
      });
  }

  async function fetchUserList() {
    await api
      .get(import.meta.env.VITE_API_URL + 'actions/users-list')
      .then((res) => {
        console.log(res.data.data);
        const tempArr: UserObj[] = [];
        for (const i of res.data.data) {
          tempArr.push({
            name: i.first_name + ' ' + i.last_name,
            id: i.id,
          });
        }
        userList.value = tempArr;
      })
      .catch((err) => {
        errorHandler(err);
      });
  }

  async function fetchAllData() {
    globalStore.isLoading = true;
    await Promise.all([fetchActionsData(), fetchStatusList(), fetchUserList()])
      .then(() => (globalStore.isLoading = false))
      .catch(() => {
        () => (globalStore.isLoading = false);
      });
  }

  return {
    currentPage,
    pagination,
    isEditStatusModal,
    isAddActionsFormModal,
    isMoreDetailsModal,
    actionsList,
    isTableLoading,
    fetchActionsData,
    fetchStatusList,
    fetchUserList,
    fetchAllData,
    userList,
    statusList,
    addStatus,
    deleteStatus,
    addNewAction,
    isMoreDetailsOrNewAction,
    selectedData,
    patchActions,
  };
});

import { ref } from 'vue';
import { defineStore } from 'pinia';
import axios, { type AxiosResponse } from 'axios';
import { api } from '@/services/api';
import router from '@/router/index';
import * as Sentry from '@sentry/browser';

export const useAuthenticationStore = defineStore('authentication', () => {
  const isAuthenticated = ref(false);
  const user = ref(JSON.parse(localStorage.getItem('user') || '{}'));
  const client = ref();
  const isInternal = ref(false);
  const API_URL = import.meta.env.VITE_API_URL + 'users/';
  const password = ref('');
  // const doubleCount = computed(() => count.value * 2)

  function setPassword(data: string) {
    password.value = data;
  }

  function verifyOTP(data: any) {
    user.value.isOTP = data.is_OTP;
    user.value.OTPVerified = data.OTP_verified;
  }

  function reset() {
    localStorage.removeItem('user');
    isAuthenticated.value = false;
    router.push({ path: '/login' });
  }

  function refresh_token_login() {
    return new Promise((resolve) => {
      api
        .post(API_URL + 'refresh_token_login', { withCredentials: true })
        .then((response) => {
          isAuthenticated.value = true;
          set_data_login(response);
          resolve(true);
        })
        .catch(() => {
          resolve(true);
        });
    });
  }

  function set_data_login(response: AxiosResponse) {
    if (response.data) {
      Sentry.setUser({ email: user.value.email });
      localStorage.setItem('user', JSON.stringify(response.data.data.user));
      console.log(response.data.data.client);
      user.value = response.data.data.user;
      client.value = response.data.data.client;
      isAuthenticated.value = true;
      // use regex to check if the email is an internal email
      if (user.value.email.match(/^[a-zA-Z0-9._%+-]+@sybil\.cloud$/)) {
        isInternal.value = true;
      } else {
        isInternal.value = false;
      }
    }
  }

  function verification() {
    return new Promise((resolve) => {
      api
        .post(API_URL + 'verify_token', { withCredentials: true })
        .then(() => {
          isAuthenticated.value = true;
          resolve(true);
        })
        .catch(() => {
          resolve(true);
        });
    });
  }

  function logout() {
    return new Promise((resolve) => {
      api
        .post(API_URL + 'logout', { withCredentials: true })
        .then(() => {
          localStorage.removeItem('user');
          isAuthenticated.value = false;
          router.push({ path: '/login' });
          resolve(true);
        })
        .catch(() => {
          resolve(true);
        });
    });
  }

  function updateUser(data: any) {
    user.value.firstName = data.user.first_name;
    user.value.lastName = data.user.last_name;
    user.value.email = data.user.email;
  }

  function loginSuccess(a: any) {
    isAuthenticated.value = true;
    user.value = a;
  }

  function login(email: String, password: String) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          API_URL + 'login',
          {
            email: email,
            password: password,
          },
          { withCredentials: true }
        )
        .then((response) => {
          set_data_login(response);
          resolve(true);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  function loginAzure(token: String) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          API_URL + 'login_SSO',
          {
            token: token,
          },
          { withCredentials: true }
        )
        .then((response) => {
          set_data_login(response);
          resolve(true);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  return {
    isAuthenticated,
    password,
    loginAzure,
    set_data_login,
    setPassword,
    updateUser,
    login,
    logout,
    verification,
    reset,
    user,
    client,
    refresh_token_login,
    verifyOTP,
    loginSuccess,
    isInternal,
  };
});

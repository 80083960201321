import type { BusinessSelection } from '@/views/Finance/FinanceSelection/components/FinanceAddPortfolio.vue';

export type FinanceCustomMetricsType = MetricsObj[];

export interface MetricsObj {
  LRLoad: MetricsTupleType;
  OHExpenses: MetricsTupleType;
  GWPGrowth: MetricsTupleType;
}
export type MetricsConstants = keyof MetricsObj;

type MetricsTupleType = [number, boolean];

export type FinanceBusinessInformationType = BusinessObj[];

export interface BusinessObj {
  id: string;
  name: string;
  allHierarchies: string[];
  allClaimsNature: string[];
  selectedBounceName: string[];
  selectedHierarchyName: BusinessSelection[];
}

export type FinanceDataType = FinanceDataObj[][];

export interface FinanceDataObj {
  [key: string]: {
    /**
     * CCR is represented as ratios.
     */
    CCR: number;
    /**
     * NLR is represented as ratios.
     */
    NLR: number;
    /**
     * Combine Ratio is represented as ratios.
     */
    CombineRatio: number;
    /**
     * GWP or GEP.
     */
    GWP_GEP: number;
    /**
     * GWP Growth metric is represented as number.
     * Formula: (GWP or GEP - GWP or GEP previous month) / GWP or GEP previous month
     */
    GWPGrowth: number;
    /**
     * LR Load is represented as number.
     * Formula: GWP or GEP * (LR Load metric)
     */
    LRLoad: number;
    /**
     * Loss ratio is represented as ratio.
     * Formula: Sum of claims ultimate ratios.
     */
    LossRatio: number;
    /**
     * Overhead expenses is represented as number.
     * Formula: GWP or GEP * (OH Expenses metric)
     */
    OverheadExpenses: number;
    /**
     * Elements in Claims Ultimate is represented as ratios.
     * Object that contains ratio. Ex: {ATTRITIONAL: 0.2}
     */
    ClaimsUltimate: RawDataColumnObject;
    /**
     * Commission is represented as ratios.
     */
    Commission: number;
    /**
     * GEP
     */
    GEP: number;
    LossRatioOriginal: number;
    CCROriginal: number;
  };
}

export interface FinanceRawDataType {
  underwriting_data: {
    data: [];
    column: RawDataColumnObject;
  };
  accident_data: {
    data: [];
    column: RawDataColumnObject;
  };
}

export enum UnderwritingAccidentEnum {
  UNDERWRITING_DATA = 0,
  ACCIDENT_DATA,
}

export interface RawDataColumnObject {
  [key: string]: number;
}

export interface RawDataColumnObjectString {
  [key: string]: string;
}

export interface RawDataColumnObjectBoolean {
  [key: string]: boolean;
}

export interface FinanceSummaryData {
  [key: string]: FinanceSummaryObject;
}

export interface FinanceSummaryObject {
  GrossPremium: number;
  LossRatio: number;
  Commission: number;
  CCR: number;
  NLR: number;
  OverheadExpenses: number;
  DirectExpenses: number;
  RLCosts: number;
  ClaimsExpenses: number;
  ClaimsUltimate: RawDataColumnObject;
}

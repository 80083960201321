<script setup lang="ts">
import { computed, reactive, ref, watch } from 'vue';
import { useDashboardStore } from '@/stores/dashboard';
import ClaimsCalculation from '@/calculations/Claims/ClaimsCalculation';
import { usePortfolioStore } from '@/stores/portfolio';
import type { DashboardData } from '@/types/dashboard';
import type { NumericDictionary } from '@/types/common';
import { numberWithCommas, decimalToPercentage } from '@/utilities/numberFormatter';
import { safeTypeNumber } from '@/utilities/calculationHelper';

const dashboardStore = useDashboardStore();
const portfolioStore = usePortfolioStore();

const ratioAmount = computed(() => dashboardStore.dashboards.ratio_amount);
const claimsType = computed(() => portfolioStore.parameters.claims_nature);
const isBindedYears = computed(() => dashboardStore.isBindedYears);
const dashboardDataColumn = computed(() => dashboardStore.dashboard_data_column);

const props = withDefaults(
  defineProps<{
    rowIndex: number;
    margin: NumericDictionary;
    showColumnTotal: boolean;
    totalMargin: number;
    dashboardData: DashboardData;
    comparisonDashboardData: DashboardData;
    compLrExpand: boolean;
    rowClass: string;
  }>(),
  { rowIndex: 0 }
);

const claimsCalculation = ref<ClaimsCalculation>(
  new ClaimsCalculation(props.dashboardData, dashboardDataColumn.value, claimsType.value)
);

const comparisonClaimsCalculation = ref<ClaimsCalculation>(
  new ClaimsCalculation(props.comparisonDashboardData, dashboardDataColumn.value, claimsType.value)
);

function seasAdjAprioriDifference(
  calculation: ClaimsCalculation,
  comparisonCalculation: ClaimsCalculation,
  isValue: boolean
) {
  const ans = calculation.seasAdjustedCCRNLR(
    props.rowIndex,
    dashboardStore.dashboards.uw_acc,
    dashboardStore.underwriting_loss_ratios,
    dashboardStore.dashboards.seasonFactor,
    dashboardStore.dashboards.ccr_nlr
  );
  const ans2 = comparisonCalculation.seasAdjustedCCRNLR(
    props.rowIndex,
    dashboardStore.dashboards.uw_acc,
    dashboardStore.underwriting_loss_ratios,
    dashboardStore.dashboards.seasonFactor,
    dashboardStore.dashboards.ccr_nlr
  );
  return decimalToPercentage(ans - ans2, isValue);
}

function seasAdjApriori(calculation: ClaimsCalculation, isValue: boolean) {
  const ans = calculation.seasAdjustedCCRNLR(
    props.rowIndex,
    dashboardStore.dashboards.uw_acc,
    dashboardStore.underwriting_loss_ratios,
    dashboardStore.dashboards.seasonFactor,
    dashboardStore.dashboards.ccr_nlr
  );
  return decimalToPercentage(ans, isValue);
}

function calculateCommission(calculation: ClaimsCalculation) {
  return calculation.commission(props.rowIndex);
}

function normalisedCCRNLR(calculation: ClaimsCalculation, isValue: boolean) {
  const ans = calculation.normalisedCCRNLR(
    props.rowIndex,
    dashboardStore.dashboards.uw_acc,
    dashboardStore.underwriting_loss_ratios,
    dashboardStore.normalise,
    dashboardStore.dashboards.ccr_nlr,
    dashboardStore.dashboards.seasonFactor
  );
  return decimalToPercentage(ans, isValue);
}

function normalisedCCRNLRDifference(
  calculation: ClaimsCalculation,
  comparisonCalculation: ClaimsCalculation,
  isValue: boolean
) {
  const ans = calculation.normalisedCCRNLR(
    props.rowIndex,
    dashboardStore.dashboards.uw_acc,
    dashboardStore.underwriting_loss_ratios,
    dashboardStore.normalise,
    dashboardStore.dashboards.ccr_nlr,
    dashboardStore.dashboards.seasonFactor
  );
  const ans2 = comparisonCalculation.normalisedCCRNLR(
    props.rowIndex,
    dashboardStore.dashboards.uw_acc,
    dashboardStore.underwriting_loss_ratios,
    dashboardStore.normalise,
    dashboardStore.dashboards.ccr_nlr,
    dashboardStore.dashboards.seasonFactor
  );
  return decimalToPercentage(ans - ans2, isValue);
}

function calculateCCRNLR(calculation: ClaimsCalculation, isValue: boolean) {
  const ans = calculation.ccrNlr(
    props.rowIndex,
    dashboardStore.dashboards.uw_acc,
    dashboardStore.underwriting_loss_ratios,
    dashboardStore.dashboards.ccr_nlr
  );
  return decimalToPercentage(ans, isValue);
}

function calculateCCRNLRDifference(
  calculation: ClaimsCalculation,
  comparisonCalculation: ClaimsCalculation,
  isValue: boolean
) {
  const ans = calculation.ccrNlr(
    props.rowIndex,
    dashboardStore.dashboards.uw_acc,
    dashboardStore.underwriting_loss_ratios,
    dashboardStore.dashboards.ccr_nlr
  );
  const ans2 = comparisonCalculation.ccrNlr(
    props.rowIndex,
    dashboardStore.dashboards.uw_acc,
    dashboardStore.underwriting_loss_ratios,
    dashboardStore.dashboards.ccr_nlr
  );
  return decimalToPercentage(ans - ans2, isValue);
}

watch(
  () => props.dashboardData,
  () => {
    claimsCalculation.value = new ClaimsCalculation(props.dashboardData, dashboardDataColumn.value, claimsType.value);
  },
  { deep: true }
);

watch(
  () => props.comparisonDashboardData,
  () => {
    comparisonClaimsCalculation.value = new ClaimsCalculation(
      props.comparisonDashboardData,
      dashboardDataColumn.value,
      claimsType.value
    );
  },
  { deep: true }
);

function valueCheck(funcName: string, data: DashboardData, rowIndex: number) {
  if (funcName == 'Unearned') {
    return parseFloat(
      (
        safeTypeNumber(data[rowIndex][dashboardDataColumn.value['uws.GWP_SUM']]) -
        safeTypeNumber(data[rowIndex][dashboardDataColumn.value['uw_data.GEP_AMOUNT']])
      ).toFixed(0)
    );
  }

  if (dashboardStore.underwriting_loss_ratios == 'Written' && dashboardStore.dashboards.uw_acc == 'uw') {
    return safeTypeNumber(data[rowIndex][dashboardDataColumn.value['uws.GWP_SUM']]);
  }
  return safeTypeNumber(data[rowIndex][dashboardDataColumn.value['uw_data.GEP_AMOUNT']]);
}

const callFunction = (funcName, data, idx, item = null) => {
  return methods[funcName](data, idx, item);
};

const methods = reactive({
  Paid(data: ClaimsCalculation, idx, item = null) {
    if (item) {
      return data.paid(idx, item);
    }
    return data.paidTotal(idx);
  },
  OS(data: ClaimsCalculation, idx, item = null) {
    if (item) {
      return data.os(idx, item);
    }

    return data.osTotal(idx);
  },
  Incurred(data: ClaimsCalculation, idx, item = null) {
    if (item) {
      return data.incurred(idx, item);
    }
    return data.incurredTotal(idx);
  },
  IBNR(data: ClaimsCalculation, idx, item = null) {
    if (item) {
      return data.ibnr(idx, item);
    }
    return data.ibnrTotal(idx);
  },
  Ultimate(data: ClaimsCalculation, idx, item = null) {
    if (item) {
      return data.ultimate(idx, dashboardStore.dashboards.uw_acc, dashboardStore.underwriting_loss_ratios, item);
    }

    return data.ultimateTotal(idx, dashboardStore.dashboards.uw_acc, dashboardStore.underwriting_loss_ratios);
  },
  Unearned(data: ClaimsCalculation, idx, item = null) {
    if (item) {
      return data.unearned(idx, item);
    }

    return data.unearnedTotal(idx);
  },
});

function differenceWithCommasOrRatios(x2: number, x1: number, gep2: number, gep1: number, isValue: boolean) {
  if (isValue) {
    return numberWithCommas(x2 - x1, false);
  } else {
    return decimalToPercentage((gep2 == 0 ? 0 : x2 / gep2) - (gep1 == 0 ? 0 : x1 / gep1), false);
  }
}

function numberWithCommasOrRatios(x: number, gep: number, isValue: boolean) {
  if (isValue) {
    return numberWithCommas(x, false);
  } else {
    return decimalToPercentage(gep == 0 ? 0 : x / gep, false);
  }
}

const mqy = computed(() => dashboardStore.dashboards.mqy);
</script>
<template>
  <td class="fixWidth side-teal sticky left-0 z-20 h-5" :class="{ [rowClass]: true }">
    {{
      mqy == 'month'
        ? dashboardData[rowIndex][0]
        : mqy == 'quarter'
        ? dashboardData[rowIndex][0] +
          (['Mar', 'Jun', 'Sep', 'Dec'].some((substring: string) => dashboardData[rowIndex][1].includes(substring))
            ? ''
            : ' QTD')
        : isBindedYears
        ? 'B - ' + dashboardData[rowIndex][0]
        : dashboardData[rowIndex][0] + ' ' + (dashboardData[rowIndex][1].includes('Dec') ? '' : 'YTD')
    }}
  </td>
  <template v-for="(amount, ix) in dashboardStore.amtFunc" :key="ix">
    <template v-for="(item, index) in claimsType" :key="index">
      <td
        class="fixWidth bg-gray-50 absolute z-10 text-red-700"
        :class="{ [rowClass]: true }"
        :style="{
          left: 112 * 1 + Object.values(margin).reduce((ps, s) => ps + s, 0) * claimsType.length + 'px',
          transition: '1.0s ease-out all',
          transform:
            'translateX(' +
            (compLrExpand
              ? showColumnTotal
                ? totalMargin * 3 * (ix * (claimsType.length + 1) + index)
                : 112 * 3 * ix
              : totalMargin * 3 * index) +
            'px)',
        }"
      >
        {{
          numberWithCommasOrRatios(
            callFunction(amount, comparisonClaimsCalculation, rowIndex, item),
            valueCheck(amount, comparisonDashboardData, rowIndex),
            ratioAmount == 'amount'
          )
        }}
      </td>
      <td
        class="fixWidth bg-gray-50 absolute z-10 text-red-500"
        :class="{ [rowClass]: true }"
        :style="{
          left: 112 * 2 + Object.values(margin).reduce((ps, s) => ps + s, 0) * claimsType.length + 'px',
          transition: '1.0s ease-out all',
          transform:
            'translateX(' +
            (compLrExpand
              ? showColumnTotal
                ? totalMargin * 3 * (ix * (claimsType.length + 1) + index)
                : 112 * 3 * ix
              : totalMargin * 3 * index) +
            'px)',
        }"
      >
        {{
          numberWithCommasOrRatios(
            callFunction(amount, claimsCalculation, rowIndex, item),
            valueCheck(amount, dashboardData, rowIndex),
            ratioAmount == 'amount'
          )
        }}
      </td>
      <td
        class="fixWidth bg-gray-50 absolute z-10"
        :class="{ [rowClass]: true }"
        :style="{
          left: 112 * 3 + Object.values(margin).reduce((ps, s) => ps + s, 0) * claimsType.length + 'px',
          transition: '1.0s ease-out all',
          transform:
            'translateX(' +
            (compLrExpand
              ? showColumnTotal
                ? totalMargin * 3 * (ix * (claimsType.length + 1) + index)
                : 112 * 3 * ix
              : totalMargin * 3 * index) +
            'px)',
        }"
      >
        {{
          differenceWithCommasOrRatios(
            callFunction(amount, claimsCalculation, rowIndex, item),
            callFunction(amount, comparisonClaimsCalculation, rowIndex, item),
            valueCheck(amount, dashboardData, rowIndex),
            valueCheck(amount, comparisonDashboardData, rowIndex),
            ratioAmount == 'amount'
          )
        }}
      </td>
    </template>
    <td
      class="fixWidth bg-white absolute z-10 text-red-700"
      :class="{ [rowClass]: true }"
      :style="{
        left: 112 * 1 + Object.values(margin).reduce((ps, s) => ps + s, 0) * claimsType.length + 'px',
        transition: '1.0s ease-out all',
        transform:
          'translateX(' +
          (compLrExpand
            ? showColumnTotal
              ? totalMargin * 3 * (ix * (claimsType.length + 1) + claimsType.length)
              : 112 * 3 * ix
            : totalMargin * 3 * claimsType.length) +
          'px)',
      }"
    >
      {{
        numberWithCommasOrRatios(
          callFunction(amount, comparisonClaimsCalculation, rowIndex),
          valueCheck(amount, comparisonDashboardData, rowIndex),
          ratioAmount == 'amount'
        )
      }}
    </td>
    <td
      class="fixWidth bg-white absolute z-10 text-red-500"
      :class="{ [rowClass]: true }"
      :style="{
        left: 112 * 2 + Object.values(margin).reduce((ps, s) => ps + s, 0) * claimsType.length + 'px',
        transition: '1.0s ease-out all',
        transform:
          'translateX(' +
          (compLrExpand
            ? showColumnTotal
              ? totalMargin * 3 * (ix * (claimsType.length + 1) + claimsType.length)
              : 112 * 3 * ix
            : totalMargin * 3 * claimsType.length) +
          'px)',
      }"
    >
      {{
        numberWithCommasOrRatios(
          callFunction(amount, claimsCalculation, rowIndex),
          valueCheck(amount, dashboardData, rowIndex),
          ratioAmount == 'amount'
        )
      }}
    </td>
    <td
      class="fixWidth bg-white absolute z-10"
      :class="{ [rowClass]: true }"
      :style="{
        left: 112 * 3 + Object.values(margin).reduce((ps, s) => ps + s, 0) * claimsType.length + 'px',
        transition: '1.0s ease-out all',
        transform:
          'translateX(' +
          (compLrExpand
            ? showColumnTotal
              ? totalMargin * 3 * (ix * (claimsType.length + 1) + claimsType.length)
              : 112 * 3 * ix
            : totalMargin * 3 * claimsType.length) +
          'px)',
      }"
    >
      {{
        differenceWithCommasOrRatios(
          callFunction(amount, claimsCalculation, rowIndex),
          callFunction(amount, comparisonClaimsCalculation, rowIndex),
          valueCheck(amount, dashboardData, rowIndex),
          valueCheck(amount, comparisonDashboardData, rowIndex),
          ratioAmount == 'amount'
        )
      }}
    </td>
  </template>

  <td
    class="fixWidth absolute text-blue-400 bg-white"
    :class="{ [rowClass]: true }"
    :style="{
      left: 112 * 4 + Object.values(margin).reduce((ps, s) => ps + s, 0) * claimsType.length + 'px',
      transition: '1.0s ease-out all',
      transform:
        'translateX(' +
        (compLrExpand
          ? showColumnTotal
            ? totalMargin *
              3 *
              ((dashboardStore.underwriting_loss_ratios == 'Written' && dashboardStore.dashboards.uw_acc == 'uw'
                ? 5
                : 4) *
                (claimsType.length + 1) +
                claimsType.length)
            : 112 *
              3 *
              (dashboardStore.underwriting_loss_ratios == 'Written' && dashboardStore.dashboards.uw_acc == 'uw' ? 5 : 4)
          : totalMargin * 3 * claimsType.length) +
        'px)',
    }"
  >
    {{
      numberWithCommasOrRatios(
        calculateCommission(comparisonClaimsCalculation),
        safeTypeNumber(comparisonDashboardData[rowIndex][dashboardDataColumn['uw_data.GEP_AMOUNT']]),
        ratioAmount == 'amount'
      )
    }}
  </td>
  <td
    class="fixWidth absolute text-blue-400 bg-white"
    :class="{ [rowClass]: true }"
    :style="{
      left: 112 * 5 + Object.values(margin).reduce((ps, s) => ps + s, 0) * claimsType.length + 'px',
      transition: '1.0s ease-out all',
      transform:
        'translateX(' +
        (compLrExpand
          ? showColumnTotal
            ? totalMargin *
              3 *
              ((dashboardStore.underwriting_loss_ratios == 'Written' && dashboardStore.dashboards.uw_acc == 'uw'
                ? 5
                : 4) *
                (claimsType.length + 1) +
                claimsType.length)
            : 112 *
              3 *
              (dashboardStore.underwriting_loss_ratios == 'Written' && dashboardStore.dashboards.uw_acc == 'uw' ? 5 : 4)
          : totalMargin * 3 * claimsType.length) +
        'px)',
    }"
  >
    {{
      numberWithCommasOrRatios(
        calculateCommission(claimsCalculation),
        safeTypeNumber(dashboardData[rowIndex][dashboardDataColumn['uw_data.GEP_AMOUNT']]),
        ratioAmount == 'amount'
      )
    }}
  </td>
  <td
    class="fixWidth absolute text-blue-400 bg-white"
    :class="{ [rowClass]: true }"
    :style="{
      left: 112 * 6 + Object.values(margin).reduce((ps, s) => ps + s, 0) * claimsType.length + 'px',
      transition: '1.0s ease-out all',
      transform:
        'translateX(' +
        (compLrExpand
          ? showColumnTotal
            ? totalMargin *
              3 *
              ((dashboardStore.underwriting_loss_ratios == 'Written' && dashboardStore.dashboards.uw_acc == 'uw'
                ? 5
                : 4) *
                (claimsType.length + 1) +
                claimsType.length)
            : 112 *
              3 *
              (dashboardStore.underwriting_loss_ratios == 'Written' && dashboardStore.dashboards.uw_acc == 'uw' ? 5 : 4)
          : totalMargin * 3 * claimsType.length) +
        'px)',
    }"
  >
    {{
      differenceWithCommasOrRatios(
        calculateCommission(claimsCalculation),
        calculateCommission(comparisonClaimsCalculation),
        safeTypeNumber(dashboardData[rowIndex][dashboardDataColumn['uw_data.GEP_AMOUNT']]),
        safeTypeNumber(comparisonDashboardData[rowIndex][dashboardDataColumn['uw_data.GEP_AMOUNT']]),
        ratioAmount == 'amount'
      )
    }}
  </td>

  <td
    class="fixWidth absolute bg-white"
    :class="{ [rowClass]: true }"
    :style="{
      left: 112 * 7 + Object.values(margin).reduce((ps, s) => ps + s, 0) * claimsType.length + 'px',
      transition: '1.0s ease-out all',
      transform:
        'translateX(' +
        (compLrExpand
          ? showColumnTotal
            ? totalMargin *
              3 *
              ((dashboardStore.underwriting_loss_ratios == 'Written' && dashboardStore.dashboards.uw_acc == 'uw'
                ? 5
                : 4) *
                (claimsType.length + 1) +
                claimsType.length)
            : 112 *
              3 *
              (dashboardStore.underwriting_loss_ratios == 'Written' && dashboardStore.dashboards.uw_acc == 'uw' ? 5 : 4)
          : totalMargin * 3 * claimsType.length) +
        'px)',
    }"
  >
    {{ calculateCCRNLR(comparisonClaimsCalculation, false) }}
  </td>
  <td
    class="fixWidth absolute bg-white"
    :class="{ [rowClass]: true }"
    :style="{
      left: 112 * 8 + Object.values(margin).reduce((ps, s) => ps + s, 0) * claimsType.length + 'px',
      transition: '1.0s ease-out all',
      transform:
        'translateX(' +
        (compLrExpand
          ? showColumnTotal
            ? totalMargin *
              3 *
              ((dashboardStore.underwriting_loss_ratios == 'Written' && dashboardStore.dashboards.uw_acc == 'uw'
                ? 5
                : 4) *
                (claimsType.length + 1) +
                claimsType.length)
            : 112 *
              3 *
              (dashboardStore.underwriting_loss_ratios == 'Written' && dashboardStore.dashboards.uw_acc == 'uw' ? 5 : 4)
          : totalMargin * 3 * claimsType.length) +
        'px)',
    }"
  >
    {{ calculateCCRNLR(claimsCalculation, false) }}
  </td>
  <td
    class="fixWidth absolute bg-white"
    :class="{ [rowClass]: true }"
    :style="{
      left: 112 * 9 + Object.values(margin).reduce((ps, s) => ps + s, 0) * claimsType.length + 'px',
      transition: '1.0s ease-out all',
      transform:
        'translateX(' +
        (compLrExpand
          ? showColumnTotal
            ? totalMargin *
              3 *
              ((dashboardStore.underwriting_loss_ratios == 'Written' && dashboardStore.dashboards.uw_acc == 'uw'
                ? 5
                : 4) *
                (claimsType.length + 1) +
                claimsType.length)
            : 112 *
              3 *
              (dashboardStore.underwriting_loss_ratios == 'Written' && dashboardStore.dashboards.uw_acc == 'uw' ? 5 : 4)
          : totalMargin * 3 * claimsType.length) +
        'px)',
    }"
  >
    {{ calculateCCRNLRDifference(claimsCalculation, comparisonClaimsCalculation, false) }}
  </td>

  <td
    class="fixWidth absolute text-orange-900 bg-white"
    :class="{ [rowClass]: true }"
    :style="{
      left: 112 * 10 + Object.values(margin).reduce((ps, s) => ps + s, 0) * claimsType.length + 'px',
      transition: '1.0s ease-out all',
      transform:
        'translateX(' +
        (compLrExpand
          ? showColumnTotal
            ? totalMargin *
              3 *
              ((dashboardStore.underwriting_loss_ratios == 'Written' && dashboardStore.dashboards.uw_acc == 'uw'
                ? 5
                : 4) *
                (claimsType.length + 1) +
                claimsType.length)
            : 112 *
              3 *
              (dashboardStore.underwriting_loss_ratios == 'Written' && dashboardStore.dashboards.uw_acc == 'uw' ? 5 : 4)
          : totalMargin * 3 * claimsType.length) +
        'px)',
    }"
  >
    {{ normalisedCCRNLR(comparisonClaimsCalculation, false) }}
  </td>
  <td
    class="fixWidth absolute text-orange-900 bg-white"
    :class="{ [rowClass]: true }"
    :style="{
      left: 112 * 11 + Object.values(margin).reduce((ps, s) => ps + s, 0) * claimsType.length + 'px',
      transition: '1.0s ease-out all',
      transform:
        'translateX(' +
        (compLrExpand
          ? showColumnTotal
            ? totalMargin *
              3 *
              ((dashboardStore.underwriting_loss_ratios == 'Written' && dashboardStore.dashboards.uw_acc == 'uw'
                ? 5
                : 4) *
                (claimsType.length + 1) +
                claimsType.length)
            : 112 *
              3 *
              (dashboardStore.underwriting_loss_ratios == 'Written' && dashboardStore.dashboards.uw_acc == 'uw' ? 5 : 4)
          : totalMargin * 3 * claimsType.length) +
        'px)',
    }"
  >
    {{ normalisedCCRNLR(claimsCalculation, false) }}
  </td>
  <td
    class="fixWidth absolute text-orange-900 bg-white"
    :class="{ [rowClass]: true }"
    :style="{
      left: 112 * 12 + Object.values(margin).reduce((ps, s) => ps + s, 0) * claimsType.length + 'px',
      transition: '1.0s ease-out all',
      transform:
        'translateX(' +
        (compLrExpand
          ? showColumnTotal
            ? totalMargin *
              3 *
              ((dashboardStore.underwriting_loss_ratios == 'Written' && dashboardStore.dashboards.uw_acc == 'uw'
                ? 5
                : 4) *
                (claimsType.length + 1) +
                claimsType.length)
            : 112 *
              3 *
              (dashboardStore.underwriting_loss_ratios == 'Written' && dashboardStore.dashboards.uw_acc == 'uw' ? 5 : 4)
          : totalMargin * 3 * claimsType.length) +
        'px)',
    }"
  >
    {{ normalisedCCRNLRDifference(claimsCalculation, comparisonClaimsCalculation, false) }}
  </td>

  <td
    class="fixWidth absolute bg-white"
    :class="{ [rowClass]: true }"
    :style="{
      left: 112 * 13 + Object.values(margin).reduce((ps, s) => ps + s, 0) * claimsType.length + 'px',
      transition: '1.0s ease-out all',
      transform:
        'translateX(' +
        (compLrExpand
          ? showColumnTotal
            ? totalMargin *
              3 *
              ((dashboardStore.underwriting_loss_ratios == 'Written' && dashboardStore.dashboards.uw_acc == 'uw'
                ? 5
                : 4) *
                (claimsType.length + 1) +
                claimsType.length)
            : 112 *
              3 *
              (dashboardStore.underwriting_loss_ratios == 'Written' && dashboardStore.dashboards.uw_acc == 'uw' ? 5 : 4)
          : totalMargin * 3 * claimsType.length) +
        'px)',
    }"
  >
    {{ seasAdjApriori(comparisonClaimsCalculation, false) }}
  </td>
  <td
    class="fixWidth absolute bg-white"
    :class="{ [rowClass]: true }"
    :style="{
      left: 112 * 14 + Object.values(margin).reduce((ps, s) => ps + s, 0) * claimsType.length + 'px',
      transition: '1.0s ease-out all',
      transform:
        'translateX(' +
        (compLrExpand
          ? showColumnTotal
            ? totalMargin *
              3 *
              ((dashboardStore.underwriting_loss_ratios == 'Written' && dashboardStore.dashboards.uw_acc == 'uw'
                ? 5
                : 4) *
                (claimsType.length + 1) +
                claimsType.length)
            : 112 *
              3 *
              (dashboardStore.underwriting_loss_ratios == 'Written' && dashboardStore.dashboards.uw_acc == 'uw' ? 5 : 4)
          : totalMargin * 3 * claimsType.length) +
        'px)',
    }"
  >
    {{ seasAdjApriori(claimsCalculation, false) }}
  </td>
  <td
    class="fixWidth absolute bg-white"
    :class="{ [rowClass]: true }"
    :style="{
      left: 112 * 15 + Object.values(margin).reduce((ps, s) => ps + s, 0) * claimsType.length + 'px',
      transition: '1.0s ease-out all',
      transform:
        'translateX(' +
        (compLrExpand
          ? showColumnTotal
            ? totalMargin *
              3 *
              ((dashboardStore.underwriting_loss_ratios == 'Written' && dashboardStore.dashboards.uw_acc == 'uw'
                ? 5
                : 4) *
                (claimsType.length + 1) +
                claimsType.length)
            : 112 *
              3 *
              (dashboardStore.underwriting_loss_ratios == 'Written' && dashboardStore.dashboards.uw_acc == 'uw' ? 5 : 4)
          : totalMargin * 3 * claimsType.length) +
        'px)',
    }"
  >
    {{ seasAdjAprioriDifference(claimsCalculation, comparisonClaimsCalculation, false) }}
  </td>
</template>

import type { MirroredPortfolioType, PortfolioType } from '@/types/portfolio';
import { openErrorMsg } from './notificationMessage';
import findKeyByValue from './findKeyByValue';

export function embedMirrorPortfolioIntoPortfolio(
  mirrorPortfolios: MirroredPortfolioType[],
  portfolios: PortfolioType[]
) {
  for (const mirrorPortfolio of mirrorPortfolios) {
    // find portfolio id that matches the portfolio_id
    const portfolio = portfolios.find((portfolio) => portfolio.id === mirrorPortfolio.portfolio_id);
    if (portfolio) {
      const curr = {
        ...portfolio,
        mirrored_portfolio_id: mirrorPortfolio.mirrored_portfolio_id,
      };
      curr.name = mirrorPortfolio.name;
      portfolios.push(curr);
    } else {
      openErrorMsg('Error! Mirrored Portfolio is not found');
    }
  }

  return portfolios;
}

export default function configChunkGroupNamesForMirrored(
  actuarialHierarchy,
  hierarchies,
  originalDictionary,
  mirroredDictionary
) {
  for (const hierarchy of hierarchies) {
    const newGroupNames: string[] = [];
    for (const groupName of actuarialHierarchy[hierarchy]['groupNames']) {
      // Find the key of the group name in dictionary
      const key = findKeyByValue(originalDictionary[hierarchy], groupName);
      // Find the group name in the dictionary
      if (!key) {
        newGroupNames.push(groupName);
      } else {
        newGroupNames.push(mirroredDictionary[hierarchy][key]);
      }
    }
    actuarialHierarchy[hierarchy]['groupNames'] = newGroupNames;
  }

  return actuarialHierarchy;
}

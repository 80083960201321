/**
 * Safely divides two numbers.
 * If the divisor is 0, it returns 0 to avoid division by zero error.
 *
 * @param dividend The dividend
 * @param divisor The divisor
 * @returns The result of the division or 0 if the divisor is 0
 */
export function safeDivide(dividend: number, divisor: number): number {
  if (divisor === 0) {
    return 0;
  }

  return dividend / divisor;
}

/**
 * Safely converts a value to a number.
 * If the value is already a number, it will be returned as is.
 * If the value is not a number, it will return Infinity.
 *
 * @param val The value to convert
 * @returns The converted number or Infinity if the value is not a number
 */
export function safeTypeNumber(val: unknown): number {
  // Implementation goes here
  return typeof val === 'number' ? val : Infinity;
}

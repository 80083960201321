<script setup lang="ts">
// import type { FinanceDataType } from '@/types/finance';
import { useFinanceStore } from '@/stores/finance';
import { onMounted, ref, computed } from 'vue';
import { UnderwritingAccidentEnum } from '@/types/finance';
import { numberWithCommas } from '@/utilities/numberFormatter';

const UNDERWRITING_DROPDOWN = {
  CCR: 'CCR',
  'Combined Ratio': 'CombineRatio',
  GWP: 'GWP_GEP',
  'GWP Growth': 'GWPGrowth',
  'LR Load': 'LRLoad',
  'Loss Ratio': 'LossRatio',
  'Overhead Expenses': 'OverheadExpenses',
};
const ACCIDENT_DROPDOWN = {
  CCR: 'CCR',
  'Combined Ratio': 'CombineRatio',
  GEP: 'GWP_GEP',
  'GWP Growth': 'GWPGrowth',
  'LR Load': 'LRLoad',
  'Loss Ratio': 'LossRatio',
  'Overhead Expenses': 'OverheadExpenses',
};

const props = defineProps<{
  underwritingAccident: UnderwritingAccidentEnum;
}>();

const financeStore = useFinanceStore();
const allDateKeys = computed(() =>
  props.underwritingAccident == UnderwritingAccidentEnum.ACCIDENT_DATA
    ? financeStore.allAccidentDateKeysConverted
    : financeStore.allUnderwritingDateKeysConverted
);

const allData = computed(() =>
  props.underwritingAccident == UnderwritingAccidentEnum.ACCIDENT_DATA
    ? financeStore.businessAccidentValuesMonthConverted
    : financeStore.businessUnderwritingValuesMonthConverted
);

onMounted(() => {
  console.log(allDateKeys);
  console.log(allData);
  console.log(financeStore.businessInformation);
});

const dropdownConstant =
  props.underwritingAccident == UnderwritingAccidentEnum.ACCIDENT_DATA ? ACCIDENT_DROPDOWN : UNDERWRITING_DROPDOWN;

const title =
  props.underwritingAccident == UnderwritingAccidentEnum.ACCIDENT_DATA ? 'Accident Data' : 'Underwriting Data';

// defineProps<{
//   portfolioData: FinanceDataType;
// }>();

function formatHierarchyName(name: string) {
  return name == '' ? 'All' : name;
}

function formatValue(val: number) {
  if (
    selectedValue.value == 'GWP_GEP' ||
    selectedValue.value == 'GWPGrowth' ||
    selectedValue.value == 'OverheadExpenses'
  ) {
    return numberWithCommas(val, false);
  } else {
    return (val * 100).toFixed(1) + '%';
  }
}

const selectedValue = ref('CCR');
</script>
<template>
  <h1 class="mt-2">{{ title }}</h1>
  <select v-model="selectedValue" class="mt-2 h-8 text-xs">
    <option v-for="(value, key) in dropdownConstant" :key="key" :value="value">
      {{ key }}
    </option>
  </select>

  <div class="mt-2">
    <div class="table-panel">
      <table>
        <thead>
          <tr>
            <th class="fixWidth header-teal text-white">Selected Portfolio</th>
            <th class="fixWidth header-teal text-white">Selected Bounce</th>
            <th v-for="i in financeStore.maxHierarchyForAllBusiness" :key="i" class="fixWidth header-teal text-white">
              Level {{ i }}
            </th>
            <th v-for="i in allDateKeys" :key="i" class="fixWidth header-teal text-white">
              {{ i }}
            </th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(information, infoIndex) in financeStore.businessInformation" :key="information.id">
            <tr v-for="(bounceName, index) in information.selectedBounceName" :key="bounceName">
              <td class="fixWidth">{{ information.name }}</td>
              <td class="fixWidth">{{ bounceName }}</td>
              <td v-for="i in financeStore.maxHierarchyForAllBusiness" :key="i" class="fixWidth">
                {{
                  information.allHierarchies[i - 1]
                    ? information.allHierarchies[i - 1] +
                      ':' +
                      formatHierarchyName(information.selectedHierarchyName[index][information.allHierarchies[i - 1]])
                    : ''
                }}
              </td>
              <td v-for="i in allDateKeys" :key="i" class="fixWidth">
                {{ allData[infoIndex][index][i] ? formatValue(allData[infoIndex][index][i][selectedValue]) : 0 }}
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>
<style scoped>
.table-panel {
  overflow: scroll;
  width: 100%;
  max-height: 350px;
  border-collapse: separate;
}

.fixWidth {
  padding-top: 5px;
  padding-bottom: 5px;
  width: 100px;
  height: 20px;
  padding-left: 20px;
  padding-right: 20px;
  border: 0.01rem solid #f4f4f5;
  font-size: 12px;
  text-align: center;
  white-space: nowrap; /* Prevents text from wrapping into a new line */
}

.header-teal {
  background-color: rgb(187, 226, 211);
  color: #6b7280;
}
</style>
@/utilities/numberFormatter

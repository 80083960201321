<script setup lang="ts">
import { useFinanceStore } from '@/stores/finance';
import {
  UnderwritingAccidentEnum,
  type RawDataColumnObjectBoolean,
  type RawDataColumnObjectString,
} from '@/types/finance';
import { computed, onMounted, ref } from 'vue';
import { FinanceSummaryFormulas } from '../../calculations/FinanceFormulas';
import JetPlusMinusButton from '@/components/JetPlusMinusButton.vue';
import { numberWithCommas, decimalToPercentage } from '@/utilities/numberFormatter';
import openErrorMsg from '@/components/openErrorMsg';

onMounted(() => {
  console.log(allDateKeys);
});

const isUltClaims = ref(true);
const isTotalExpensesInclRl = ref(true);

const props = defineProps<{
  underwritingAccident: UnderwritingAccidentEnum;
}>();

function errorMsg(msg: string) {
  openErrorMsg(msg);
}

const isEditDirectExpenses = ref<RawDataColumnObjectBoolean>({});
const isEditDirectExpensesVal = ref<RawDataColumnObjectString>({});

const isEditRLCosts = ref<RawDataColumnObjectBoolean>({});
const isEditRLCostsVal = ref<RawDataColumnObjectString>({});

const isEditClaimsExpenses = ref<RawDataColumnObjectBoolean>({});
const isEditClaimsExpensesVal = ref<RawDataColumnObjectString>({});

function numberWithCommasNotValue(x: number) {
  return numberWithCommas(x, false);
}

function decimalToPercentageNotValue(x: number) {
  return decimalToPercentage(x, false);
}

const financeStore = useFinanceStore();

const allData = computed(() =>
  props.underwritingAccident == UnderwritingAccidentEnum.ACCIDENT_DATA
    ? financeStore.accidentFinanceSummary
    : financeStore.underwritingFinanceSummary
);

const allDateKeys = computed(() =>
  props.underwritingAccident == UnderwritingAccidentEnum.ACCIDENT_DATA
    ? financeStore.allAccidentDateKeysConverted
    : financeStore.allUnderwritingDateKeysConverted
);
</script>
<template>
  <div class="wrapper">
    <table
      id="finance-summary-table"
      style="width: 100%; border-spacing: 0; text-align: left; background: white"
      class="table-panel table-fixed bg-sybil-teal bg-opacity-10"
    >
      <tr>
        <th colspan="2" class="sticky-col first-col">Date</th>
        <td v-for="date of allDateKeys" :key="date">{{ date }}</td>
      </tr>
      <tr>
        <th colspan="2" class="sticky-col first-col">Gross Premium</th>
        <td v-for="date of allDateKeys" :key="date">{{ numberWithCommasNotValue(allData[date]?.GrossPremium) }}</td>
      </tr>
      <tr>
        <th colspan="2" class="sticky-col first-col">Net Premium</th>
        <td v-for="date of allDateKeys" :key="date">
          {{ numberWithCommasNotValue(FinanceSummaryFormulas.netPremium(allData[date])) }}
        </td>
      </tr>
      <tr>
        <th colspan="2" class="sticky-col first-col">
          <div class="flex flex-row">
            <div class="align-middle ml-2">
              <JetPlusMinusButton
                :show="isUltClaims"
                table="ultClaims"
                @change-show-status="(e) => (isUltClaims = e)"
              />
            </div>
            <p class="ml-2">Ultimate Claims</p>
          </div>
        </th>
        <td v-for="date of allDateKeys" :key="date">
          {{ numberWithCommasNotValue(FinanceSummaryFormulas.ultClaims(allData[date])) }}
        </td>
      </tr>
      <tr v-for="(claimsNature, idx) of financeStore.uniqueClaimsNature" v-show="isUltClaims" :key="idx">
        <th class="subRight sticky-col first-col"></th>
        <th class="subLeft sticky-col second-col">{{ claimsNature }}</th>
        <td v-for="date of allDateKeys" :key="date">
          {{ numberWithCommasNotValue(allData[date].ClaimsUltimate[claimsNature] * allData[date].GrossPremium) }}
        </td>
      </tr>
      <tr>
        <th colspan="2" class="sticky-col first-col">
          <p class="ml-2">Loss Ratio</p>
        </th>
        <td v-for="date of allDateKeys" :key="date">{{ decimalToPercentageNotValue(allData[date].LossRatio) }}</td>
      </tr>
      <tr>
        <th colspan="2" class="sticky-col first-col">
          <p class="ml-2">Commission</p>
        </th>
        <td v-for="date of allDateKeys" :key="date">{{ decimalToPercentageNotValue(allData[date].Commission) }}</td>
      </tr>
      <tr>
        <th colspan="2" class="sticky-col first-col">
          <p class="ml-2">CCR</p>
        </th>
        <td v-for="date of allDateKeys" :key="date">{{ decimalToPercentageNotValue(allData[date].CCR) }}</td>
      </tr>
      <tr>
        <th colspan="2" class="sticky-col first-col">
          <p class="ml-2">NLR</p>
        </th>
        <td v-for="date of allDateKeys" :key="date">{{ decimalToPercentageNotValue(allData[date].NLR) }}</td>
      </tr>
      <tr>
        <th colspan="2" class="sticky-col first-col">
          <div class="flex flex-row">
            <div class="align-middle ml-2">
              <JetPlusMinusButton
                :show="isTotalExpensesInclRl"
                table="ultClaims"
                @change-show-status="(e) => (isTotalExpensesInclRl = e)"
              />
            </div>
            <p class="ml-2">Total expenses incl RL</p>
          </div>
        </th>
        <td v-for="date of allDateKeys" :key="date">
          {{ numberWithCommasNotValue(FinanceSummaryFormulas.totalExpensesInclRL(allData[date])) }}
        </td>
      </tr>
      <tr v-show="isTotalExpensesInclRl">
        <th class="subRight sticky-col first-col"></th>
        <th class="subLeft sticky-col second-col">Overheads</th>
        <td v-for="date of allDateKeys" :key="date">
          <h1>
            {{ numberWithCommasNotValue(allData[date].OverheadExpenses) }}
          </h1>
        </td>
      </tr>
      <tr v-show="isTotalExpensesInclRl">
        <th class="subRight sticky-col first-col"></th>
        <th class="subLeft sticky-col second-col">Direct Expenses</th>
        <td v-for="date of allDateKeys" :key="date">
          <template v-if="isEditDirectExpenses[date]">
            <el-input
              v-model="isEditDirectExpensesVal[date]"
              style="height: 30px"
              @keyup.enter="
                isFinite(parseFloat(isEditDirectExpensesVal[date]))
                  ? (allData[date].DirectExpenses = parseFloat(isEditDirectExpensesVal[date]))
                  : errorMsg('Incorrect input');
                $event.target.blur();
                isEditDirectExpenses[date] = false;
              "
            />
          </template>
          <template v-else>
            <h1
              @dblclick="
                isEditDirectExpenses[date] = true;
                isEditDirectExpensesVal[date] = allData[date].DirectExpenses.toString();
              "
            >
              {{ numberWithCommasNotValue(allData[date].DirectExpenses) }}
            </h1>
          </template>
        </td>
      </tr>
      <tr v-show="isTotalExpensesInclRl">
        <th class="subRight sticky-col first-col"></th>
        <th class="subLeft sticky-col second-col">RI costs</th>
        <td v-for="date of allDateKeys" :key="date">
          <template v-if="isEditRLCosts[date]">
            <el-input
              v-model="isEditRLCostsVal[date]"
              style="height: 30px"
              @keyup.enter="
                isFinite(parseFloat(isEditRLCostsVal[date]))
                  ? (allData[date].RLCosts = parseFloat(isEditRLCostsVal[date]))
                  : errorMsg('Incorrect input');
                $event.target.blur();
                isEditRLCosts[date] = false;
              "
            />
          </template>
          <template v-else>
            <h1
              @dblclick="
                isEditRLCosts[date] = true;
                isEditRLCostsVal[date] = allData[date].RLCosts.toString();
              "
            >
              {{ numberWithCommasNotValue(allData[date].RLCosts) }}
            </h1>
          </template>
        </td>
      </tr>
      <tr v-show="isTotalExpensesInclRl">
        <th class="subRight sticky-col first-col"></th>
        <th class="subLeft sticky-col second-col">Claims expenses</th>
        <td v-for="date of allDateKeys" :key="date">
          <template v-if="isEditClaimsExpenses[date]">
            <el-input
              v-model="isEditClaimsExpensesVal[date]"
              style="height: 30px"
              @keyup.enter="
                isFinite(parseFloat(isEditClaimsExpensesVal[date]))
                  ? (allData[date].ClaimsExpenses = parseFloat(isEditClaimsExpensesVal[date]))
                  : errorMsg('Incorrect input');
                $event.target.blur();
                isEditClaimsExpenses[date] = false;
              "
            />
          </template>
          <template v-else>
            <h1
              @dblclick="
                isEditClaimsExpenses[date] = true;
                isEditClaimsExpensesVal[date] = allData[date].ClaimsExpenses.toString();
              "
            >
              {{ numberWithCommasNotValue(allData[date].ClaimsExpenses) }}
            </h1>
          </template>
        </td>
      </tr>
      <tr>
        <th colspan="2" class="sticky-col first-col">
          <p class="ml-2">Total expenses excl RI</p>
        </th>
        <td v-for="date of allDateKeys" :key="date">
          {{ numberWithCommasNotValue(FinanceSummaryFormulas.totalExpensesExclRL(allData[date])) }}
        </td>
      </tr>
      <tr>
        <th colspan="2" class="sticky-col first-col">
          <p class="ml-2">Total expenses excl OH</p>
        </th>
        <td v-for="date of allDateKeys" :key="date">
          {{ numberWithCommasNotValue(FinanceSummaryFormulas.totalExpensesExclOH(allData[date])) }}
        </td>
      </tr>
      <tr>
        <th colspan="2" class="sticky-col first-col">
          <p class="ml-2">Total expenses excl OH and RI</p>
        </th>
        <td v-for="date of allDateKeys" :key="date">
          {{ numberWithCommasNotValue(FinanceSummaryFormulas.totalExpensesExclOHAndRL(allData[date])) }}
        </td>
      </tr>
      <tr>
        <th colspan="2" class="sticky-col first-col">
          <p class="ml-2">Gross Underwriting Profit</p>
        </th>
        <td v-for="date of allDateKeys" :key="date">
          {{ numberWithCommasNotValue(FinanceSummaryFormulas.grossProfit(allData[date])) }}
        </td>
      </tr>
      <tr>
        <th colspan="2" class="sticky-col first-col">
          <p class="ml-2">Net Underwriting Profit</p>
        </th>
        <td v-for="date of allDateKeys" :key="date">
          {{ numberWithCommasNotValue(FinanceSummaryFormulas.net(allData[date])) }}
        </td>
      </tr>
      <tr>
        <th colspan="2" class="sticky-col first-col">
          <p class="ml-2">Net Gross Underwriting Profit</p>
        </th>
        <td v-for="date of allDateKeys" :key="date">
          {{ numberWithCommasNotValue(FinanceSummaryFormulas.netGross(allData[date])) }}
        </td>
      </tr>
    </table>
  </div>
</template>
<style scoped>
.wrapper {
  position: relative;
  overflow: auto;
  border: 1px solid black;
  white-space: nowrap;
}

.sticky-col {
  position: -webkit-sticky;
  position: sticky;
}

table,
th,
td {
  border: 1px solid black;
  border-collapse: collapse;
}

th {
  width: 280px;
  background: rgb(182, 179, 179);
}

td {
  width: 150px;
  text-align: center;
}

.subLeft {
  border-left: 0 !important;
}

.first-col {
  left: 0px;
}

.second-col {
  left: 140px;
}

.subRight {
  border-right: 0 !important;
}

.table-panel {
  overflow: scroll;
  width: 100%;
  max-height: 350px;
  border-collapse: separate;
}
</style>

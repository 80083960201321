<script setup lang="ts">
import { useGlobalStore } from '@/stores/global';
import { useActuarialStore } from '@/stores/actuarial';
import { ref, onMounted } from 'vue';
import openErrorMsg from '@/components/openErrorMsg';
import { api } from '@/services/api';
import type { PortfolioType, TreeNodeSelectBounceTreeType } from '@/types/portfolio';
import SelectBounceTree from '@/components/SelectBounceTree.vue';
import { Minus, Plus } from '@element-plus/icons-vue';
import axios from 'axios';
import { usePortfolioStore } from '@/stores/portfolio';
import configChunkGroupNamesForMirrored, { embedMirrorPortfolioIntoPortfolio } from '@/utilities/portfolioHelper';
import { fetchMirroredPortfolioDictionary } from '@/services/portfolio';

const globalStore = useGlobalStore();
const actuarialStore = useActuarialStore();
const portfolioStore = usePortfolioStore();

const DATA_TYPE = [
  {
    label: 'Incurred Claims Triangles',
    value: 'INCURRED',
  },
  {
    label: 'Paid Claims Triangles',
    value: 'PAID',
  },
  {
    label: 'Number of Claims Triangles',
    value: 'CLAIMS',
  },
];

const portfolios = ref<PortfolioType[]>([]);
const draftList = ref([]);

const nodeName = ref('');
const show = ref(false);
const selectedBounceTreeKey = ref('');
const selectedDraftList = ref({});

const bounceDictionary = ref({});
const actuarialClaimsNature = ref<string[][]>([]);

async function getActuarialChunkGrouping() {
  await api
    .get(import.meta.env.VITE_API_URL + 'actuarial/get-actuarial-chunk-grouping', {
      params: {
        bounce_id: actuarialStore.selectedBusinessBounceIDAddLinkRatio,
      },
    })
    .then(async (res) => {
      if (res.data.data['variable'].actuarial_hierarchy) {
        actuarialStore.selectedBusinessActuarialHierarchyAddLinkRatio = res.data.data['variable'].actuarial_hierarchy;
      }

      if (res.data.data['variable'].actuarial_claims_nature) {
        actuarialClaimsNature.value = res.data.data['variable'].actuarial_claims_nature;
      } else {
        actuarialClaimsNature.value = [];
        for (const i of actuarialStore.selectedBusinessParametersAddLinkRatio['claims_nature']) {
          actuarialClaimsNature.value.push([i]);
        }
      }
      if (actuarialStore.selectedBusinessAddLinkRatio['mirrored_portfolio_id']) {
        await fetchMirroredPortfolioDictionary(
          actuarialStore.selectedBusinessBounceIDAddLinkRatio,
          actuarialStore.selectedBusinessAddLinkRatio['mirrored_portfolio_id']
        ).then((res) => {
          if (res && res.mirroredDictionary) {
            actuarialStore.selectedBusinessActuarialHierarchyAddLinkRatio = configChunkGroupNamesForMirrored(
              actuarialStore.selectedBusinessActuarialHierarchyAddLinkRatio,
              actuarialStore.selectedBusinessParametersAddLinkRatio['hierarchies'],
              bounceDictionary.value,
              res.mirroredDictionary
            );
          }
        });
      }

      actuarialStore.selectedBusinessHierarchySelectionAddLinkRatio = {};
      for (const i of [
        ...actuarialStore.selectedBusinessParametersAddLinkRatio['hierarchies'],
        ...actuarialStore.selectedBusinessParametersAddLinkRatio['actuarial_grouping'],
      ]) {
        actuarialStore.selectedBusinessHierarchySelectionAddLinkRatio[i] = 0;
      }
    })
    .catch((err) => {
      console.log(err);
      openErrorMsg('Error! Please try again');
    });
}

async function onFetchPortfolio() {
  globalStore.setLoading(true);

  await api
    .get(import.meta.env.VITE_API_URL + 'portfolio/load-portfolio', {
      params: {
        bounce_id: actuarialStore.selectedBusinessBounceIDAddLinkRatio,
      },
    })
    .then((res) => {
      console.log(res);
      let promises: Promise<void>[] = [];
      actuarialStore.selectedBusinessParametersAddLinkRatio = res.data.data['parameters'];
      actuarialStore.selectedBusinessDevsAddLinkRatio = res.data.data['devs'];
      actuarialStore.selectedBusinessCurrentMonthAddLinkRatio = res.data.data.current_month;
      promises.push(
        axios.get(res.data.data.signed_dictionary).then((res) => {
          bounceDictionary.value = res.data;
          actuarialStore.hierarchySelectionAddLinkRatio = {};
          for (const i of Object.keys(bounceDictionary.value)) {
            actuarialStore.hierarchySelectionAddLinkRatio[i] = '';
          }
        })
      );

      return Promise.all(promises);
    })
    .catch((err) => {
      globalStore.setLoading(false);
      if (err.response && err.response.data) {
        openErrorMsg(err.response.data.data);
      } else {
        openErrorMsg('Error! Please try again');
      }
    });

  globalStore.setLoading(false);
}

onMounted(() => {
  actuarialStore.selectedDataType = '';
  actuarialStore.selectedBusinessAddLinkRatio = {
    name: '',
    id: '',
    tree: {
      name: '',
      nodeID: '',
      children: [],
    },
  };
  actuarialStore.selectedBusinessParametersAddLinkRatio = null;
});

async function onClickTree(node: TreeNodeSelectBounceTreeType) {
  actuarialStore.selectedBusinessBounceIDAddLinkRatio = node.nodeID;
  nodeName.value = node.name;

  await onFetchPortfolio();
  await getActuarialChunkGrouping();
}

onMounted(() => {
  retrievePortfolioList();
});

async function retrievePortfolioList() {
  globalStore.isLoading = true;

  await api
    .get(import.meta.env.VITE_API_URL + 'portfolio/list_portfolio')
    .then((res) => {
      draftList.value = res.data.data.draft_list;
      portfolios.value = embedMirrorPortfolioIntoPortfolio(res.data.data.mirrored_portfolio, res.data.data.portfolios);
    })
    .catch((err) => {
      if (err.response && err.response.data) {
        openErrorMsg(err.response.data.data);
      } else {
        openErrorMsg('Error! Please try again');
      }
      globalStore.isLoading = false;
    });

  globalStore.isLoading = false;
}

function onSelectBusiness(business: PortfolioType, idx: number) {
  actuarialStore.selectedBusinessAddLinkRatio = business;
}
</script>
<template>
  <div>
    <h1 class="mt-3">Select Business</h1>
    <el-select
      :model-value="actuarialStore.selectedBusinessAddLinkRatio"
      value-key="name"
      placeholder="Select"
      class="text-center shadow-sm w-full"
      @change="onSelectBusiness"
    >
      <el-option
        v-for="(item, index) in portfolios"
        :key="index"
        data-testid="select-portfolios-import"
        :label="item.name"
        :value="item"
      />
    </el-select>
    <div v-if="actuarialStore.selectedBusinessAddLinkRatio.id != ''">
      <h1>Select Bounce:</h1>
      <h1>Selected Bounce: {{ actuarialStore.selectedBusinessBounceIDAddLinkRatio ? nodeName : 'Not selected' }}</h1>
      <el-icon class="dot cursor-pointer flex items-center justify-center" @click="show = !show">
        <Minus v-if="show" data-testid="close-bounce-selection" color="white" />
        <Plus v-if="!show" data-testid="open-bounce-selection" color="white" />
      </el-icon>
      <SelectBounceTree
        v-if="actuarialStore.selectedBusinessAddLinkRatio && show"
        :key="selectedBounceTreeKey"
        :tree-selected="actuarialStore.selectedBusinessBounceIDAddLinkRatio"
        :tree-data="actuarialStore.selectedBusinessAddLinkRatio.tree"
        :selected-draft-list="selectedDraftList"
        @on-click-tree="onClickTree"
      />
      <div
        v-if="
          actuarialStore.selectedBusinessBounceIDAddLinkRatio &&
          actuarialStore.selectedBusinessParametersAddLinkRatio &&
          actuarialStore.selectedBusinessParametersAddLinkRatio.hierarchies &&
          actuarialStore.selectedBusinessParametersAddLinkRatio.actuarial_grouping
        "
      >
        <div>
          <h1 class="mt-3">Select amount type</h1>
          <el-select
            :model-value="actuarialStore.selectedAmountType.join(', ')"
            class="text-center shadow-sm w-full"
            @change="(e) => (actuarialStore.selectedAmountType = e)"
          >
            <el-option
              v-for="(item, index) in actuarialClaimsNature"
              :key="index"
              data-testid="claims-nature-selection-import"
              :label="item.join(', ')"
              :value="item"
            />
          </el-select>
        </div>
        <div>
          <h1 class="mt-3">Select data type</h1>
          <el-select
            v-model="actuarialStore.selectedDataType"
            placeholder="Select"
            class="text-center shadow-sm w-full"
          >
            <el-option
              v-for="(item, index) in DATA_TYPE"
              :key="index"
              data-testid="data-type-selection-import"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </div>
        <div>
          <h1 class="mt-3">Hierarchy</h1>
          <template
            v-for="(hierarchy, index) in [
              ...actuarialStore.selectedBusinessParametersAddLinkRatio.hierarchies,
              ...actuarialStore.selectedBusinessParametersAddLinkRatio.actuarial_grouping,
            ]"
            :key="index"
          >
            <h1>{{ hierarchy }}</h1>
            <el-select
              v-model="actuarialStore.hierarchySelectionAddLinkRatio[hierarchy]"
              placeholder="Select"
              class="text-center shadow-sm w-full"
            >
              <template
                v-if="
                  actuarialStore.selectedBusinessActuarialHierarchyAddLinkRatio[hierarchy] &&
                  actuarialStore.selectedBusinessActuarialHierarchyAddLinkRatio[hierarchy]['groupNames']
                "
              >
                <el-option
                  v-for="(item, hierarchyGroupindex) in actuarialStore.selectedBusinessActuarialHierarchyAddLinkRatio[
                    hierarchy
                  ]['groupNames']"
                  :key="hierarchyGroupindex"
                  :data-testid="hierarchy + '-import-selection'"
                  :label="item"
                  :value="item"
                />
              </template>
            </el-select>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss"></style>

import { useGlobalStore } from '@/stores/global';

/**
 * Formats a number with commas.
 * @param x - The number to format.
 * @param isValue - Indicates whether the number is a value.
 * @returns The formatted number as a string or a float.
 */
export function numberWithCommas(x: number, isValue: boolean): string | number {
  const globalStore = useGlobalStore();
  if (!isValue) {
    return (x / parseFloat(globalStore.currency.GWPUnits[globalStore.currency.currencyFormat.selectedGWPUnit][0]))
      .toFixed(globalStore.currency.currencyFormat.precision)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  } else {
    return parseFloat(
      (x / parseFloat(globalStore.currency.GWPUnits[globalStore.currency.currencyFormat.selectedGWPUnit][0])).toFixed(
        globalStore.currency.currencyFormat.precision
      )
    );
  }
}

/**
 * Converts a decimal number to a percentage.
 * @param x - The decimal number to convert.
 * @param isValue - Indicates whether the number is a value.
 * @returns The converted percentage as a string or a float.
 */
export function decimalToPercentage(x: number, isValue: boolean): string | number {
  if (!isValue) {
    return (x * 100).toFixed(1) + '%';
  } else {
    return parseFloat((x * 100).toFixed(1));
  }
}

/**
 * Formats a number with commas or calculates a ratio.
 * @param x - The number to format or calculate the ratio.
 * @param gep - The denominator for calculating the ratio.
 * @param isValue - Indicates whether the number is a value.
 * @returns The formatted number as a string or a float.
 */
export function numberWithCommasOrRatios(x: number, gep: number, isValue: boolean): string | number {
  if (isValue) {
    return numberWithCommas(x, false);
  } else {
    return decimalToPercentage(gep == 0 ? 0 : x / gep, false);
  }
}

/**
 * Returns the number value or calculates a ratio.
 * @param x - The number value.
 * @param gep - The denominator for calculating the ratio.
 * @param isValue - Indicates whether the number is a value.
 * @returns The number value or the calculated ratio.
 */
export function numberValueOrRatios(x: number, gep: number, isValue: boolean): number {
  if (isValue) {
    return x;
  } else {
    return gep == 0 ? 0 : x / gep;
  }
}

<script setup lang="ts">
import { ref } from 'vue';
import imageUrl from '@/assets/images/phaedus.png';

const cssProps = ref({
  backgroundImage: `url(${imageUrl})`,
});
</script>

<template>
  <div class="sm:grid sm:grid-rows-1 flex flex-col sm:justify-left items-left">
    <div
      class="bg-sybil-teal bg-opacity-10 sm:absolute sm:top-0 sm:left-0 w-full sm:w-2/3 md:w-1/2 px-10 pb-10 sm:p-20 h-2/3 sm:h-full bg-white drop-shadow-2xl"
    >
      <slot class="w-auto" name="logo" />
      <slot />
    </div>
    <div
      class="text-white bg-cover sm:absolute sm:top-0 sm:right-0 w-full sm:w-1/3 md:w-1/2 h-1/3 sm:h-full bg-black"
      :style="{ backgroundImage: imageUrl }"
    >
      <div class="bg-sybil-teal sm:pt-10 md:p-20 h-full w-full opacity-90">
        <div class="p-5 sm:text-xl md:text-4xl font-serif">
          "We will not mention the Sybil or the others who foretell many things by means of god-inspired prophetic
          trances and give sound guidance to many people - that would take too much time for a point that's obvious to
          everyone."
        </div>
        <div class="p-5 text-sm md:text-sm text-right font-mono">Socrates, Plato's Phaedrus Dialogue</div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { useGlobalStore } from '@/stores/global';
import moment from 'moment';
import { usePortfolioStore } from '@/stores/portfolio';
import { api } from '@/services/api';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import InputNumber from 'primevue/inputnumber';
import { openSuccessMsg, openErrorMsg } from '@/utilities/notificationMessage';
import { useActuarialStore } from '@/stores/actuarial';

const globalStore = useGlobalStore();
const portfolioStore = usePortfolioStore();
const actuarialStore = useActuarialStore();

const dateToExtend = ref();
const currentDate = moment(portfolioStore.current_month, 'YYYY-MM-DD').add(1, 'M').valueOf();
const validated = ref(false);
const canBeUploaded = ref(false);
const message = ref('');
const underwritingDataFile = ref();
const claimsDataFile = ref();

let newDate: string = '';

const defaultLoads = ref(portfolioStore.parameters['claims_nature'].map((_) => 0));

function handleClose() {
  globalStore.isDataUploadModal = false;
}

function disabledDate(time) {
  return currentDate >= time.getTime();
}

function onUpdateDate() {
  newDate = moment(dateToExtend.value).format('MMM-YYYY');
}

async function validateBounce() {
  if (newDate.length === 0) {
    return;
  }
  globalStore.setLoading(true);
  await api
    .get('portfolio/validate-upload', {
      params: {
        bounce_id: portfolioStore.selectedBounceID,
        new_date: newDate,
      },
    })
    .then((res) => {
      globalStore.setLoading(false);
      canBeUploaded.value = res.data.data.can_be_uploaded;
      message.value = res.data.data.message;
      console.log(message.value);
      validated.value = true;
    })
    .catch((err) => {
      globalStore.setLoading(false);
      console.log(err);
    });
}

function renderValidatedText() {
  if (validated.value) {
    if (canBeUploaded.value) {
      return 'Validated';
    } else {
      return message.value;
    }
  } else {
    return 'Not validated';
  }
}

function uploadUnderwritingFile(event) {
  underwritingDataFile.value = event.target.files[0];
}

function uploadClaimsFile(event) {
  claimsDataFile.value = event.target.files[0];
}

const config = {
  headers: {
    'Content-Type': 'application/octet-stream',
  },
};

async function uploadFile() {
  // Check if all defaultLoads is between 0 and 100
  if (defaultLoads.value.some((load) => load < 0 || load > 100)) {
    openErrorMsg('Default loads should be between 0 and 100');
    return;
  }

  if (!(validated.value && canBeUploaded.value)) {
    openErrorMsg('Please validate the bounce first');
    return;
  }

  const underwritingCacheID = uuidv4();
  const claimsCacheID = uuidv4();

  globalStore.isLoading = true;
  const requests = [
    api.post('storage/upload-cache', {
      bounce_id: portfolioStore.selectedBounceID,
      cache_path: underwritingCacheID + '.xlsx',
    }),
    api.post('storage/upload-cache', {
      bounce_id: portfolioStore.selectedBounceID,
      cache_path: claimsCacheID + '.xlsx',
    }),
  ];

  await axios
    .all(requests)
    .then(
      axios.spread((underwritingRes, claimsRes) => {
        axios.put(underwritingRes.data.data.signed_url, underwritingDataFile.value, config);
        axios.put(claimsRes.data.data.signed_url, claimsDataFile.value, config);
      })
    )
    .then(() => {
      const loads = defaultLoads.value.map((load) => load / 100);
      return api.post('portfolio/upload-new-month', {
        bounce_id: portfolioStore.selectedBounceID,
        underwriting_cache_path: underwritingCacheID,
        claims_cache_path: claimsCacheID,
        default_loads: loads,
        new_date: newDate,
      });
    })
    .then((res) => {
      globalStore.isLoading = false;
      actuarialStore.createDraft(res);
      openSuccessMsg('Data uploaded successfully');
      portfolioStore.pollingUpload();
    })
    .catch((error) => {
      if (error.response && error.response.data && error.response.data.data && error.response.data.data.bounce_id) {
        console.log('error', error.response.data.data.bounce_id);
        actuarialStore.createDraft(error.response);
      }
      globalStore.isLoading = false;
      openErrorMsg('An error occurred while uploading the data');
    });
}
</script>
<template>
  <el-dialog
    title="Upload Data"
    :model-value="globalStore.isDataUploadModal"
    width="1300px"
    :before-close="handleClose"
  >
    <div class="grid grid-cols-4 divide-x-2">
      <div>
        <h1>Pick a period</h1>
        <el-date-picker
          v-model="dateToExtend"
          type="month"
          placeholder="Pick a period"
          :disabled-date="disabledDate"
          @change="onUpdateDate"
        />
      </div>
      <div class="px-4 py-2">
        <h1>Status:</h1>
        <h1
          :class="{
            'text-green-400': validated && canBeUploaded,
            'text-red-400': validated && !canBeUploaded,
            'text-blue-400': !validated,
          }"
        >
          {{ renderValidatedText() }}
        </h1>
      </div>
      <div class="px-4 py-2">
        <h1>Default Loads:</h1>
        <div v-for="(claims_nature, idx) in portfolioStore.parameters['claims_nature']" :key="idx + 'claims'">
          <h1>{{ claims_nature }}</h1>
          <InputNumber v-model="defaultLoads[idx]" suffix="%" />
        </div>
      </div>
      <div class="px-4 py-2">
        <h1>Uploads</h1>
        <div class="py-4">
          <h1 class="mb-2">Underwriting Data</h1>
          <input
            type="file"
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            @change="uploadUnderwritingFile"
          />
          <h1 class="mb-2 mt-5">Claims Data</h1>
          <input
            type="file"
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            @change="uploadClaimsFile"
          />
        </div>
      </div>
    </div>
    <template #footer>
      <el-button @click="validateBounce()">Validate</el-button>
      <el-button type="primary" :disabled="!(validated && canBeUploaded)" @click="uploadFile">Submit</el-button>
    </template>
  </el-dialog>
</template>

<script setup lang="ts">
import DialogComponent from '@/components/Dialog/DialogComponent.vue';
import errorHandler from '@/utilities/errorhandler';
import { SuccessFilled, WarningFilled } from '@element-plus/icons-vue';
import axios from 'axios';
import { onBeforeMount, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useGlobalStore } from '@/stores/global';

const globalStore = useGlobalStore();
const showErrorMessage = ref(false);

onBeforeMount(async () => {
  const route = useRoute();
  const auth = route.query.auth;
  globalStore.isLoading = true;

  await axios
    .post(import.meta.env.VITE_API_URL + 'users/newsletter-options-email', {
      auth: auth,
      portfolio_insights_newsletters: false,
    })
    .then(() => {
      globalStore.isLoading = false;
    })
    .catch((err) => {
      globalStore.isLoading = false;
      showErrorMessage.value = true;
      errorHandler(err);
    });
});
</script>
<template>
  <div>
    <DialogComponent :model-value="true" :is-close-button="false">
      <div v-if="!showErrorMessage" class="text-center p-6">
        <el-icon :size="40" color="#097969"><SuccessFilled /></el-icon>
        <h1 class="text-2xl">Unsubscribed!</h1>
        <p class="mt-3">You will no longer receive emails from us.</p>
        <p>You can resubscribe in your user settings.</p>
      </div>
      <div v-else class="text-center p-6">
        <el-icon :size="40" color="#FF0000"><WarningFilled /></el-icon>
        <h1 class="text-2xl">Something went wrong!</h1>
      </div>
    </DialogComponent>
  </div>
</template>

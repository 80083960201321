<script setup lang="ts">
import { useActuarialStore } from '@/stores/actuarial';
import InputText from 'primevue/inputtext';
// import TextArea from 'primevue/textarea';
import StaticEnterPaste from './StaticEnterPaste.vue';
import ImportFromAnotherGroup from './ImportFromAnotherGroup.vue';
import ImportFromDifferentBounce from './ImportFromDifferentBounce.vue';

const actuarialStore = useActuarialStore();
defineEmits(['update:updateIdfMethodName', 'update:updateIdfMethodDesc']);

defineProps({
  idfMethodName: {
    type: String,
    default: '',
  },
  idfMethodDesc: {
    type: String,
    default: '',
  },
});
</script>
<template>
  <div>
    <div>Calculation Name</div>
    <div class="w-full mb-3">
      <InputText
        data-testid="calculation-name"
        :model-value="idfMethodName"
        class="h-10 w-full"
        @update:model-value="$emit('update:updateIdfMethodName', $event)"
      />
    </div>
    <!-- <div>Description</div>
    <div class="w-full mb-3">
      <TextArea
        :model-value="idfMethodDesc"
        class="w-full"
        :auto-resize="true"
        rows="1"
        cols="30"
        @update:model-value="$emit('update:updateIdfMethodDesc', $event)"
      ></TextArea>
    </div> -->
    <el-select
      v-model="actuarialStore.staticValueType"
      :placeholder="actuarialStore.staticValueType"
      class="text-center shadow-sm w-full"
    >
      <el-option label="Enter/paste values" value="paste" />
      <el-option
        data-testid="import-another-group"
        label="Import from another group on this module"
        value="openLocal"
      />
      <el-option data-testid="import-external-group" label="Import from a different bounce" value="openExternal" />
    </el-select>
    <StaticEnterPaste v-if="actuarialStore.staticValueType === 'paste'" />
    <ImportFromAnotherGroup v-if="actuarialStore.staticValueType === 'openLocal'" />
    <ImportFromDifferentBounce v-if="actuarialStore.staticValueType === 'openExternal'" />
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { ElMessage } from 'element-plus';
import { useActuarialStore } from '@/stores/actuarial';
import type { LinkRatioObject } from '@/types/actuarial';

const actuarialStore = useActuarialStore();

const prevAverageLinkRatioCopyPaste = ref<LinkRatioObject[]>([]);
const columnStyle = {
  textAlign: 'center',
  font: '12px graphie',
  bgColor: '#f9f9f9',
};

const headerStyle = {
  textAlign: 'center',
  font: '12px graphie',
  bgColor: '#f9f9f9',
};

const manualValueError = () => {
  ElMessage.error('Invalid input.');
};

onMounted(() => {
  createAvgLinkRatioCopyPasteData();
});

function createAvgLinkRatioCopyPasteData() {
  let temp: LinkRatioObject[] = [];
  // for (let i = 1; i <= actuarialStore.numberOfDev.length + [120,40,10][actuarialStore.cohort]; i++) {
  for (let i = 1; i <= actuarialStore.numberOfDev.length; i++) {
    let tempObj = {
      period: actuarialStore.origins[i],
      DevelopmentPeriod: i,
      linkRatio: parseFloat((1).toFixed(3)),
      devFactor: parseFloat((1).toFixed(3)),
    };
    temp.push(tempObj);
  }
  actuarialStore.averageLinkRatioCopyPaste = temp;
  prevAverageLinkRatioCopyPaste.value = structuredClone(temp);
}

// defineProps({
//   actuarialStore.averageLinkRatioCopyPaste: {
//     type: String,
//     default: 'Weighted',
//   },
// });
function onChangedPasteValue(e) {
  if (e.field == 'linkRatio') {
    let curr = 1;
    for (let i = actuarialStore.averageLinkRatioCopyPaste.length - 1; i >= 0; i--) {
      if (!isFinite(actuarialStore.averageLinkRatioCopyPaste[i]['linkRatio'])) {
        actuarialStore.averageLinkRatioCopyPaste = structuredClone(prevAverageLinkRatioCopyPaste.value);
        manualValueError();
        break;
      }
      curr *= actuarialStore.averageLinkRatioCopyPaste[i]['linkRatio'];
      actuarialStore.averageLinkRatioCopyPaste[i]['devFactor'] = parseFloat(curr.toFixed(3));
    }
  } else {
    actuarialStore.averageLinkRatioCopyPaste[actuarialStore.averageLinkRatioCopyPaste.length - 1]['linkRatio'] =
      actuarialStore.averageLinkRatioCopyPaste[actuarialStore.averageLinkRatioCopyPaste.length - 1]['devFactor'];
    for (let i = actuarialStore.averageLinkRatioCopyPaste.length - 2; i >= 0; i--) {
      if (
        !isFinite(actuarialStore.averageLinkRatioCopyPaste[i]['devFactor']) ||
        !isFinite(actuarialStore.averageLinkRatioCopyPaste[i + 1]['devFactor'])
      ) {
        actuarialStore.averageLinkRatioCopyPaste = structuredClone(prevAverageLinkRatioCopyPaste.value);
        manualValueError();
        break;
      }

      actuarialStore.averageLinkRatioCopyPaste[i]['linkRatio'] = parseFloat(
        (
          actuarialStore.averageLinkRatioCopyPaste[i]['devFactor'] /
          actuarialStore.averageLinkRatioCopyPaste[i + 1]['devFactor']
        ).toFixed(3)
      );
    }
  }
  console.log('actuarialStore.averageLinkRatioCopyPaste', actuarialStore.averageLinkRatioCopyPaste);
  prevAverageLinkRatioCopyPaste.value = structuredClone(actuarialStore.averageLinkRatioCopyPaste);
}
</script>
<template>
  <div style="height: 500px">
    <c-grid
      :data="actuarialStore.averageLinkRatioCopyPaste"
      :allow-range-paste="true"
      @changed-value="onChangedPasteValue"
    >
      <c-grid-column field="DevelopmentPeriod" width="50%" :column-style="columnStyle" :header-style="headerStyle">
        Dev. period
      </c-grid-column>
      <c-grid-input-column
        v-if="actuarialStore.selectedStaticValue == 'Link ratio'"
        field="linkRatio"
        width="50%"
        :column-style="columnStyle"
        :header-style="headerStyle"
      >
        Link ratio
      </c-grid-input-column>
      <c-grid-input-column
        v-if="actuarialStore.selectedStaticValue == 'Dev. factor'"
        field="devFactor"
        width="50%"
        :column-style="columnStyle"
        :header-style="headerStyle"
      >
        Dev. factor
      </c-grid-input-column>
    </c-grid>
  </div>
</template>

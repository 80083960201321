<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: undefined,
  },
  visible: {
    type: Boolean,
    required: undefined,
  },
  title: {
    type: String,
    default: '',
  },
  dialogClass: {
    type: String,
    default: '',
  },
  isCloseButton: {
    type: Boolean,
    default: true,
  },
});

const emit = defineEmits(['update:modelValue']);

const closeDialog = () => {
  emit('update:modelValue', false);
};

const isVisible = computed(() => {
  return props.modelValue !== undefined ? props.modelValue : props.visible;
});
</script>
<template>
  <div v-if="isVisible" class="dialog">
    <div class="dialog__overlay" @click="closeDialog"></div>
    <div class="dialog__content" :class="dialogClass">
      <div class="flex flex-row justify-between">
        <h1 class="font-normal text-lg">
          {{ title }}
        </h1>
        <!-- Close Button -->
        <button v-if="isCloseButton" @click="closeDialog">
          <img src="@/assets/images/exit.svg" width="25" class="mr-2" />
        </button>
      </div>
      <div class="mt-3">
        <slot></slot>
      </div>
    </div>
  </div>
</template>
<style>
.dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.dialog__overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}
.dialog__content {
  position: relative;
  z-index: 1000;
  background-color: white;
  padding: 20px;
  border-radius: 5px;
}
</style>

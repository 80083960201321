<script setup lang="ts">
import { useRouter } from 'vue-router';
import DataTable from 'primevue/datatable';
import VueSimpleContextMenu from 'vue-simple-context-menu';
import Column from 'primevue/column';
import { useGlobalStore } from '@/stores/global';
import { usePortfolioStore } from '@/stores/portfolio';
import { useDashboardStore } from '@/stores/dashboard';
import { ref, computed, onMounted, onBeforeMount, onBeforeUnmount, watch, shallowRef } from 'vue';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import ComparisonViewSidePanel from './ComparisonViewSidePanel.vue';
import { Delete, Download, CircleCloseFilled } from '@element-plus/icons-vue';
import VueSlider from 'vue-slider-component';
import { ElMessage } from 'element-plus';
import 'vue-slider-component/theme/default.css';
import { api } from '@/services/api';
import * as am5 from '@amcharts/amcharts5';
import * as am5plugins_exporting from '@amcharts/amcharts5/plugins/exporting';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5index from '@amcharts/amcharts5/index';
import * as am5hierarchy from '@amcharts/amcharts5/hierarchy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import InputText from 'primevue/inputtext';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import * as logo from '@/assets/images/sybil';
import { useUnderwritingStore } from '@/stores/underwriting';

const router = useRouter();

const global_store = useGlobalStore();
const portfolio_store = usePortfolioStore();
const dashboard_store = useDashboardStore();
const underwriting_store = useUnderwritingStore();

function getKeyByValue(object: any, value: any): string {
  return Object.keys(object).find((key) => object[key] === value) || '';
}

let root: any = null;
let chart: any = null;
let root2: any = null;
let chart2: any = null;

const chartData: any = computed(() => dashboard_store.comparisonViewChartData);
const partitionData: any = computed(() => dashboard_store.comparisonViewPartitionData);

const screenHeight = ref(0);

const maxRadius = ref(50);
const section1 = ref(null);
const section2 = ref(null);
const section3 = ref(null);

const blur1 = ref('');
const blur2 = ref('');
const overflow = ref(0);

const dtable = ref(null);
const dtScroll = ref(dashboard_store.comparison_table_scroll_position);

const tierRadio = ref(dashboard_store.selectedTierGroup);

function storeTableScroll() {
  if (blur1.value == '') {
    let item = document.getElementsByClassName('p-datatable-wrapper');
    dtScroll.value = item[0].scrollTop;
    dashboard_store.set_comparison_table_scroll_position(dtScroll.value);
  }
}
function checkOverlap() {
  const rect1 = section1.value.getBoundingClientRect();
  const rect2 = section2.value.getBoundingClientRect();
  const rect3 = section3.value.getBoundingClientRect();
  let item = document.getElementsByClassName('p-datatable-wrapper');
  if (rect2.top < rect1.bottom - 400) {
    blur1.value = 'blur-md opacity-20';
    overflow.value = 99999;
  } else if (rect2.top < rect1.bottom - 200) {
    blur1.value = 'blur opacity-50';
    overflow.value = 99999;
  } else if (rect2.top < rect1.bottom - 50) {
    blur1.value = 'blur-sm opacity-80';
    overflow.value = 99999;
  } else {
    blur1.value = '';
    overflow.value = screenHeight.value;
    console.log(dtScroll.value);
    item[0].scrollTo(0, dtScroll.value);
  }

  if (rect3.top < rect2.bottom - 400) {
    blur2.value = 'blur-md opacity-20';
    dashboard_store.setComp2(true);
    overflow.value = 99999;
  } else if (rect3.top < rect2.bottom - 200) {
    blur2.value = 'blur opacity-50';
    dashboard_store.setComp2(true);
    overflow.value = 99999;
  } else if (rect3.top < rect2.bottom - 50) {
    blur2.value = 'blur-sm opacity-80';
    dashboard_store.setComp2(true);
    overflow.value = 99999;
  } else {
    blur2.value = '';
    dashboard_store.setComp2(false);
    overflow.value = screenHeight.value;
    item[0].scrollTo(0, dtScroll.value);
  }
}

onBeforeMount(async () => {
  screenHeight.value = window.innerHeight - 150;
  overflow.value = screenHeight.value;
  if (!chunkGroups.value) {
    await dashboard_store.get_chunk();
  }
});

function contextMenuListener(ev) {
  ev.preventDefault();
}

onBeforeUnmount(async () => {
  if (root) {
    root.dom.removeEventListener('contextmenu', contextMenuListener);
    root.dispose();
  }
  if (root2) {
    root2.dom.removeEventListener('contextmenu', contextMenuListener);
    root2.dispose();
  }
  // prevent memory leak
  root = null;
  root2 = null;
  chart = null;
  chart2 = null;

  window.removeEventListener('scroll', checkOverlap);
  let item = document.getElementsByClassName('p-datatable-wrapper');
  item[0].removeEventListener('scroll', storeTableScroll);

  dashboard_store.setComparisonViewScroll(window.scrollY);
});

async function onSelectTier() {
  await dashboard_store.chunk_tables();
  await createChartData();

  createChart();
}

function createChart() {
  if (root) {
    root.dispose();
  }

  root = am5.Root.new('chartdiv');

  const data = structuredClone(chartData.value);

  chart = root.container.children.push(am5xy.XYChart.new(root, {}));

  // Export menu
  let exporting = am5plugins_exporting.Exporting.new(root, {
    menu: am5plugins_exporting.ExportingMenu.new(root, {
      align: 'right',
      valign: 'top',
    }),
  });

  let scrollbarX = am5.Scrollbar.new(root, {
    orientation: 'horizontal',
  });

  chart.set('scrollbarX', scrollbarX);
  chart.bottomAxesContainer.children.push(scrollbarX);

  let scrollbarY = am5.Scrollbar.new(root, {
    orientation: 'vertical',
  });

  chart.set('scrollbarY', scrollbarY);
  chart.leftAxesContainer.children.push(scrollbarY);

  let xAxis = chart.xAxes.push(
    am5xy.ValueAxis.new(root, {
      min: minX,
      max: maxX,
      numberFormat: "#'%'",
      renderer: am5xy.AxisRendererX.new(root, {}),
      tooltip: am5.Tooltip.new(root, {}),
    })
  );

  xAxis.children.moveValue(
    am5.Label.new(root, {
      text: structuredClone(currentSelectedGraph.value),
      x: am5.p50,
      centerX: am5.p50,
    })
  );

  let yAxis = chart.yAxes.push(
    am5xy.ValueAxis.new(root, {
      min: minY,
      max: maxY,
      numberFormat: "#'%'",
      renderer: am5xy.AxisRendererY.new(root, {
        inversed: false,
      }),
      tooltip: am5.Tooltip.new(root, {}),
    })
  );

  let series: any = '';

  // Create series
  if (currentSelectedGraph.value != '' && tierData.value[currentTier.value] && currentTier.value != 'None') {
    yAxis.children.moveValue(
      am5.Label.new(root, {
        rotation: -90,
        text: structuredClone(tierData.value[currentTier.value].selectedTiering),
        y: am5.p50,
        centerX: am5.p50,
      }),
      0
    );
    series = chart.series.push(
      am5xy.LineSeries.new(root, {
        calculateAggregates: true,
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: structuredClone(tierData.value[currentTier.value].selectedTiering),
        valueXField: structuredClone(currentSelectedGraph.value),
        valueField: 'value',
        seriesTooltipTarget: 'bullet',
      })
    );
  } else {
    yAxis.children.moveValue(
      am5.Label.new(root, {
        rotation: -90,
        text: structuredClone(comparisonViewColumns.value.filter((x) => !x.includes('GWP'))[0]),
        y: am5.p50,
        centerX: am5.p50,
      }),
      0
    );
    series = chart.series.push(
      am5xy.LineSeries.new(root, {
        calculateAggregates: true,
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: structuredClone(comparisonViewColumns.value.filter((x) => !x.includes('GWP'))[0]),
        valueXField: structuredClone(comparisonViewColumns.value.filter((x) => !x.includes('GWP'))[0]),
        valueField: 'value',
        seriesTooltipTarget: 'bullet',
      })
    );
  }

  let circleTemplate: any = am5.Template.new({});
  circleTemplate.adapters.add('fill', function (fill: any, target: any) {
    let dataItem = target.dataItem;
    if (dataItem) {
      return am5.Color.fromString(dataItem.dataContext.color);
    }
    return fill;
  });
  series.bullets.push(function () {
    let bulletCircle = am5.Circle.new(
      root,
      {
        radius: 3,
        fill: series.get('fill'),
        fillOpacity: 0.8,
        tooltipText: "{Chunk} : {value.formatNumber('#.0a')}",
      },
      circleTemplate
    );
    return am5.Bullet.new(root, {
      sprite: bulletCircle,
    });
  });

  series.set('heatRules', [
    {
      min: 3,
      max: structuredClone(maxRadius.value),
      target: circleTemplate,
      dataField: 'value',
      key: 'radius',
    },
  ]);

  series.strokes.template.set('forceHidden', true);

  root.dom.addEventListener('contextmenu', contextMenuListener);

  circleTemplate.events.on('rightclick', function (ev) {
    // console.log(ev.target.dataItem.dataContext)
    const data = structuredClone(ev.target.dataItem.dataContext);
    data['GROUP'] = data['Chunk'];
    handleRightClickDraftContextMenu(ev.originalEvent, data);
  });

  chart.set(
    'cursor',
    am5xy.XYCursor.new(root, {
      behavior: 'zoomXY',
      xAxis: xAxis,
      yAxis: yAxis,
      snapToSeries: [series],
    })
  );

  series.data.setAll(data);
}

async function createChartData() {
  let manipulatedData = [];
  if (currentSelectedGraph.value != '' && tierData.value && currentTier.value != 'None') {
    const a = currentSelectedGraph.value;
    const b = tierData.value[currentTier.value].selectedTiering;
    minX = Math.floor(Math.min(...chunk_data.value.map((x) => x[a])) * 10) * 10;
    maxX = Math.ceil(Math.max(...chunk_data.value.map((x) => x[a])) * 10) * 10 + 20;
    minY = Math.floor(Math.min(...chunk_data.value.map((x) => x[b])) * 10) * 10;
    maxY = Math.ceil(Math.max(...chunk_data.value.map((x) => x[b])) * 10) * 10 + 20;
    dashboard_store.setMinMaxXY([minX, maxX, minY, maxY]);
    for (const chunk of chunk_data.value) {
      const xaxis = chunk[a];
      const yaxis = chunk[b];

      let manipulatedObj: any = {};
      manipulatedObj[currentSelectedGraph.value] = xaxis * 100;
      manipulatedObj[tierData.value[currentTier.value].selectedTiering] = yaxis * 100;
      manipulatedObj['value'] = chunk['Expected Next 12 Months GWP'];
      manipulatedObj['Chunk'] = chunk['GROUP'];
      manipulatedObj['color'] = checkColor(chunk);

      if (manipulatedObj['value'] == 0) {
        manipulatedObj[currentSelectedGraph.value] = 0;
        manipulatedObj[tierData.value[currentTier.value].selectedTiering] = 0;
      }

      manipulatedData.push(manipulatedObj);
    }
  } else {
    const a = comparisonViewColumns.value.filter((x) => !x.includes('GWP'))[0];
    minX = Math.floor(Math.min(...chunk_data.value.map((x) => x[a])) * 10) * 10;
    maxX = Math.ceil(Math.max(...chunk_data.value.map((x) => x[a])) * 10) * 10 + 20;
    minY = minX;
    maxY = maxX + 20;
    dashboard_store.setMinMaxXY([minX, maxX, minY, maxY]);
    for (const chunk of chunk_data.value) {
      const xaxis = chunk[a];
      const yaxis = chunk[a];
      let manipulatedObj: any = {};
      manipulatedObj[a] = xaxis * 100;
      manipulatedObj[a] = yaxis * 100;
      manipulatedObj['value'] = chunk['Expected Next 12 Months GWP'];
      manipulatedObj['color'] = '#55b691';
      manipulatedObj['Chunk'] = chunk['GROUP'];

      if (manipulatedObj['value'] == 0) {
        manipulatedObj[a] = 0;
        manipulatedObj[a] = 0;
      }

      manipulatedData.push(manipulatedObj);
    }
  }
  dashboard_store.setComparisonViewChartData(manipulatedData);
}

function toTitleCase(str: string) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

const currentSelectedGraph = computed(() => dashboard_store.graphSelectionRadio || '');

async function addChunkGroup() {
  global_store.setLoading(true);
  if (
    !(
      currChunkGroup.some((e: any) => e.toLowerCase() == chunkGroupName.value.toLowerCase()) ||
      Object.keys(chunkGroups.value).some((e: any) => e.toLowerCase() == chunkGroupName.value.toLowerCase())
    )
  ) {
    let a: any = {};

    let hierarchyName = currentSelectedChunkHierarchy.value;

    for (const group in tempChunkGroup.value) {
      a[toTitleCase(tempChunkGroup.value[group])] = {};
      a[toTitleCase(tempChunkGroup.value[group])][hierarchyName] = tempChunkSet.value[group];
    }

    if (filtersCopy.value[currentSelectedChunkHierarchy.value].length > 0) {
      // chunkGroups.value[chunkGroupName.value]["Others"] = {};

      a['Others'] = {};
      a['Others'][hierarchyName] = filtersCopy.value[currentSelectedChunkHierarchy.value];

      // chunkGroups.value[chunkGroupName.value]["Others"][
      //   currentSelectedChunkHierarchy.value
      // ] = filtersCopy.value[currentSelectedChunkHierarchy.value];
    }

    for (const a_key of Object.keys(a)) {
      let temp: any = [];
      for (const i of a[a_key][hierarchyName]) {
        temp.push(getKeyByValue(portfolio_store.dictionary[currentSelectedChunkHierarchy.value], i).toString());
      }

      a[a_key][hierarchyName] = temp;
    }

    await api
      .post(import.meta.env.VITE_API_URL + 'claims/chunk', {
        // SEND DATA HERE
        bounce_id: portfolio_store.selectedBounceID,
        chunk_group_name: toTitleCase(chunkGroupName.value),
        chunk_object: a,
      })
      .then(() => openSuccessMsg('Successfully added.'))
      .catch(async () => {
        openErrorMsg('Error, please try again!');
        global_store.setLoading(false);
      });

    // store.dispatch("data/updateChunkGroups", JSON.stringify(chunkGroups.value));
    resetModal();
    dashboard_store.transitionRenderKey += 1;
    await dashboard_store.get_chunk();
    global_store.setLoading(false);
  } else {
    global_store.setLoading(false);
    openErrorMsg('duplicated name');
  }
}

async function deleteGroup() {
  global_store.setLoading(true);

  if (selectedChunkGroup.value == selectedGroup.value) {
    dashboard_store.setSelectedChunkGroup(currChunkGroup ? currChunkGroup[0] : null);
  }

  await api
    .delete(import.meta.env.VITE_API_URL + 'claims/chunk', {
      // SEND DATA HERE
      params: {
        bounce_id: portfolio_store.selectedBounceID,
        chunk_group_name: selectedGroup.value,
      },
    })
    .then(() => openSuccessMsg('Deleted.'))
    .catch(() => {
      openErrorMsg('Error, please try again!');
      global_store.setLoading(false);
    });

  selectedGroup.value = null;
  await dashboard_store.get_chunk();

  global_store.setLoading(false);
}

function openErrorMsg(msg: string) {
  ElMessage({
    message: msg,
    type: 'warning',
  });
}

function openSuccessMsg(msg: string) {
  ElMessage({
    message: msg,
    type: 'success',
  });
}

function atChangeName(old, event) {
  tempChunkName.value = old;
  tempChunkGroup.value[tempChunkGroup.value.indexOf(old)] = event.target.value;
  tempChunkName.value = event.target.value;
}

function renameChunkTemp() {
  if (tempChunkGroup.value.filter((e) => e == tempChunkName.value).length > 1) {
    openErrorMsg('Same name exists');
    renameChunk.value = true;
  } else {
    renameChunk.value = false;
  }
}

function addChunk() {
  let val = tempChunkGroup.value.length + 1;
  while (tempChunkGroup.value.includes('Chunk ' + val)) {
    val += 1;
  }
  tempChunkGroup.value.push('Chunk ' + val);
  tempChunkSet.value.push([]);
}

function removeToSelectedChunk(idx: number) {
  tempChunkSet.value[idx] = tempChunkSet.value[idx].filter((e) => {
    return !selectedcurrentSelectedChunk.value.includes(e);
  });

  filtersCopy.value[currentSelectedChunkHierarchy.value] = filtersCopy.value[
    currentSelectedChunkHierarchy.value
  ].concat(Object.values(selectedcurrentSelectedChunk.value));
}

function handleDeleteChunkSel(idx: number) {
  if (tempChunkGroup.value.length == 1) {
    openErrorMsg('There must be at least one minimum chunks.');
    return;
  }
  if (currentTabView.value >= tempChunkGroup.value.length - 1) {
    currentTabView.value -= 1;
  }
  filtersCopy.value[currentSelectedChunkHierarchy.value] = filtersCopy.value[
    currentSelectedChunkHierarchy.value
  ].concat(tempChunkSet.value[idx]);

  tempChunkGroup.value.splice(idx, 1);
  tempChunkSet.value.splice(idx, 1);
}

const tempChunkName = ref('');
const renameChunk = ref(false);

function changeName(c: any) {
  console.log('rename');
  renameChunk.value = true;
  tempChunkName.value = c;
}

function addToCurrentSelectedChunk(hierarchy: any) {
  tempChunkSet.value[currentTabView.value] = tempChunkSet.value[currentTabView.value].concat(
    Object.values(selectedChunk.value)
  );

  currentSelectedChunkHierarchy.value = hierarchy;

  filtersCopy.value[hierarchy] = filtersCopy.value[hierarchy].filter(
    (e: any) => !Object.values(selectedChunk.value).includes(e)
  );
  selectedChunk.value = [];
}

const selectedGWPUnit = computed<string>(() => global_store.currency.currencyFormat.selectedGWPUnit);
const GWPUnits: any = computed(() => global_store.currency.GWPUnits);
const comparisonViewColumns: any = computed(() => dashboard_store.comparisonViewColumns);
const chunk_data: any = computed(() => {
  console.log(dashboard_store.chunk_data);
  return dashboard_store.chunk_data;
});

const minMaxXY = ref(dashboard_store.minMaxXY);
let minX = minMaxXY.value[0];
let maxX = minMaxXY.value[1];
let minY = minMaxXY.value[2];
let maxY = minMaxXY.value[3];

const selectedGroupChunk = ref(null);
const currency: any = computed(() => global_store.currency.currencyFormat.currency);
const kprocess = (dotsPos: any) => {
  let array: any = [];
  if (dotsPos.length < 1) {
    return array;
  }
  array[0] = [0, dotsPos[0], { backgroundColor: colorPicker.value[0] }];
  for (let i = 1; i < dotsPos.length; i++) {
    array[i] = [dotsPos[i - 1], dotsPos[i], { backgroundColor: colorPicker.value[i] }];
  }
  array.push([dotsPos[dotsPos.length - 1], 100, { backgroundColor: colorPicker.value[colorPicker.value.length - 1] }]);
  return array;
};

async function tweakMaximumSlider(e: any) {
  if (sliderValueTiering.value.length != 1) {
    sliderValueTiering.value = e;
    maximum.value = sliderValueTiering.value[sliderValueTiering.value.length - 1] + 10;
  } else {
    if (e > sliderValueTiering.value[0]) {
      sliderValueTiering.value = [e];
      maximum.value = sliderValueTiering.value[sliderValueTiering.value.length - 1] + 10;
    } else {
      sliderValueTiering.value = [e];
    }
  }
}

function deleteSelected(name: any) {
  notSelectedFields.value.push(name);
  selectedFields.value = selectedFields.value.filter((e: any) => e.name !== name);
}

onMounted(async () => {
  if (selectedChunkGroup.value == '') {
    dashboard_store.setSelectedChunkGroup(portfolio_store.parameters['hierarchies'][0]);
  } else {
    onSelectTier();
  }
  selectedFields.value = [];
  notSelectedFields.value = [];

  let idx = 0;

  for (const i of Object.keys(comparisonViewColumns.value)) {
    selectedFields.value.push({ name: i });
    notSelectedFields.value.push(i);
    idx += 1;
    if (idx == 4) {
      break;
    }
  }
  createChart();
  createHChart();
  chart.root.dom.style.height = screenHeight.value + 'px';
  chart2.root.dom.style.height = screenHeight.value + 'px';
  window.addEventListener('scroll', checkOverlap);
  let item = document.getElementsByClassName('p-datatable-wrapper');
  item[0].addEventListener('scroll', storeTableScroll);
  window.scrollTo(0, dashboard_store.comparisonViewScroll);
  item[0].scrollTo(0, dtScroll.value);
});

const selectedChunkGroup = computed(() => dashboard_store.selectedChunkGroup);
const selectedColumns = computed(() => dashboard_store.comparisonViewColumns);

watch(
  () => selectedChunkGroup.value,
  async () => {
    await dashboard_store.loadTierData();

    await dashboard_store.chunk_tables();
    onSelectTier();
  }
);

watch(
  () => selectedColumns.value,
  async () => {
    await dashboard_store.loadTierData();
    await dashboard_store.chunk_tables();
  }
);

watch(
  partitionData,
  () => {
    createHChart();
  },
  { deep: true }
);

const selectFieldsModal = ref(false);

function onChangeColor() {
  console.log('hello');
}

const tieringModal = ref(false);

const menu = ref();
const toggle = (event: any) => {
  menu.value.toggle(event);
};

function numberWithCommas(x: number, isValue: boolean) {
  if (!isValue) {
    return (x / parseFloat(GWPUnits.value[selectedGWPUnit.value][0]))
      .toFixed(global_store.currency.currencyFormat.precision)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  } else {
    return parseFloat(
      (x / parseFloat(GWPUnits.value[selectedGWPUnit.value][0])).toFixed(global_store.currency.currencyFormat.precision)
    );
  }
}

function onRowReorder(event: any) {
  selectedFields.value = event.value;
}

const currentTier = computed(() => dashboard_store.selectedTierGroup || 'None');

const tierData = computed(() => dashboard_store.tierData);

const selectedFields: any = ref([]);
const notSelectedFields: any = ref<string[]>([]);

watch(
  () => currentSelectedGraph.value,
  () => {
    onSelectTier();
  }
);

watch(
  () => currentTier.value,
  () => {
    onSelectTier();
  }
);

function checkColor(data: string) {
  if (currentTier.value == 'None') {
    return 'white';
  } else {
    const tiers = tierData.value[currentTier.value].tiers;

    const a = tierData.value[currentTier.value].selectedTiering;
    const value = parseFloat(data[a]) * 100;

    for (let i = 0; i < tiers.length; i++) {
      const tier = tiers[i];
      if (tier[1] != 'Up' && tier[0] <= value && tier[1] >= value) {
        return tierData.value[currentTier.value].colorPicker[i];
      } else if (tier[1] == 'Up') {
        return tierData.value[currentTier.value].colorPicker[i];
      }
    }
    return 'white';
  }
}

function getType(data, columnIndex) {
  if (columnIndex === 0) {
    return 'string'; // Treat the first column as text
  }
  // Remove commas and check if it's a valid number
  const unformattedNumber = data.replace(/,/g, '');
  if (!isNaN(unformattedNumber) && unformattedNumber.trim() !== '') {
    return 'number';
  }
  if (data.endsWith('%')) {
    return 'percentage';
  }
  return 'string';
}

function parseData(data, type) {
  switch (type) {
    case 'number':
      return parseFloat(data.replace(/,/g, ''));
    case 'percentage':
      return parseFloat(data.replace('%', '')) / 100;
    default:
      return data;
  }
}

function tableToArray(table) {
  const rows = table.querySelectorAll('tr');
  return Array.from(rows).map((tr) => {
    return Array.from(tr.querySelectorAll('td, th')).map((td, columnIndex) => {
      const type = getType(td.innerText, columnIndex);
      const value = parseData(td.innerText, type);
      return { value, type };
    });
  });
}

function cleanString(str) {
  return str.replace(/\n+/g, ' ').trim();
}

function getNumberFormat(precision) {
  let format = '#,##0'; // Base format for zero precision

  if (precision > 0) {
    // Add decimal places based on precision
    format += '.' + '0'.repeat(precision);
  }

  return format;
}

function onDownloadExcel() {
  const tableData = tableToArray(document.getElementById('performance_table'));

  let workbook = new Workbook();

  let worksheet = workbook.addWorksheet('Comparison View');
  worksheet.mergeCells('A1:C5');
  let myLogoImage = workbook.addImage({
    base64: logo.imgBase64,
    extension: 'png',
  });
  worksheet.addImage(myLogoImage, 'A1:C5');
  worksheet.addRow([]);

  worksheet.getCell('B7').value = portfolio_store.selectedPortfolioName;
  worksheet.getCell('B7').alignment = {
    horizontal: 'left',
    vertical: 'middle',
  };

  worksheet.getCell('B8').value = portfolio_store.selectedBounceDate;
  worksheet.getCell('B8').alignment = {
    horizontal: 'left',
    vertical: 'middle',
  };

  worksheet.getCell('B9').value = portfolio_store.selectedBounceFullName;
  worksheet.getCell('B9').alignment = {
    horizontal: 'left',
    vertical: 'middle',
  };

  let rowIndex = 12;
  let rowAddition = rowIndex;
  let colAddition = 2;

  for (let colIndex in tableData[0]) {
    colIndex = parseInt(colIndex);
    const row = worksheet.getRow(rowIndex);
    const cell = row.getCell(colIndex + colAddition);

    cell.value = cleanString(tableData[0][colIndex].value);

    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '55B691' },
    };
  }
  let iLength = 50;
  let jLength = 50;
  tableData.forEach((rowData, rowIndex) => {
    const row = worksheet.getRow(rowIndex + rowAddition);
    console.log(rowIndex);
    if (rowIndex > 0) {
      rowData.forEach((cellData, colIndex) => {
        const cell = row.getCell(colIndex + 2);
        cell.value = cellData.value;

        cell.border = {
          top: { style: 'thin', color: { argb: 'FFEEEEEE' } }, // Grey top border
          left: { style: 'thin', color: { argb: 'FFEEEEEE' } }, // Grey left border
          bottom: { style: 'thin', color: { argb: 'FFEEEEEE' } }, // Grey bottom border
          right: { style: 'thin', color: { argb: 'FFEEEEEE' } }, // Grey right border
        };

        cell.alignment = {
          wrapText: true,
          horizontal: 'center',
          vertical: 'middle',
        };

        // Apply number formatting only to other columns
        if (colIndex !== 0) {
          switch (cellData.type) {
            case 'number':
              const precision = global_store.currency.currencyFormat.precision;
              cell.numFmt = getNumberFormat(precision);
              break;
            case 'percentage':
              cell.numFmt = '0.0%';
              break;
            // Other formats as needed
          }
        } else {
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'eef8f4' },
          };
        }
      });
    }

    row.commit();
  });

  console.log(tableData);
  workbook.xlsx.writeBuffer().then((data) => {
    let blob = new Blob([data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });

    fs.saveAs(blob, 'unnamed export' + '.xlsx');
  });
}

const chunkGroups = computed(() => dashboard_store.chunks_grouping);
const currChunkGroup = portfolio_store.parameters.hierarchies;

async function onChangeChunkGroup(value: string) {
  dashboard_store.setSelectedChunkGroup(value);
  await dashboard_store.chunk_tables();
  await onSelectTier();
}

const notSelected: any = ref([]);

async function deleteTier() {
  global_store.setLoading(true);
  await api
    .delete(import.meta.env.VITE_API_URL + 'claims/tiering', {
      // SEND DATA HERE
      params: {
        bounce_id: portfolio_store.selectedBounceID,
        tiering_name: selectedTieringList.value,
      },
    })
    .then(() => {
      openSuccessMsg('Deleted.');
      selectedTieringList.value = null;
    })
    .catch(() => openErrorMsg('Error, please try again!'));

  await dashboard_store.loadTierData();

  global_store.setLoading(false);
}

async function createTier() {
  let sliderValue = JSON.parse(JSON.stringify(sliderValueTiering.value));

  sliderValue.push('Up');
  let tiers = [[0, sliderValue[0]]];
  for (let i = 1; i < numberOfTiersSlider.value; i++) {
    tiers.push([sliderValue[i - 1], sliderValue[i]]);
  }

  const tierData = {
    tiers: tiers,
    colorPicker: colorPicker.value,
    selectedTiering: selectedTiering.value,
  };

  await api
    .post(import.meta.env.VITE_API_URL + 'claims/tiering', {
      // SEND DATA HERE
      bounce_id: portfolio_store.selectedBounceID,
      tiering_name: tieringName.value,
      tiering_object: tierData,
    })
    .then(() => {
      openSuccessMsg('Successfully added.');
      dashboard_store.transitionRenderKey += 1;
      resetTierModal();
      tieringModal.value = false;
    })
    .catch(() => {
      global_store.setLoading(false);
      openErrorMsg('Error, please try again!');
    });
  await dashboard_store.loadTierData();
  global_store.setLoading(false);
}

function addToGroup() {
  if (notSelected.value.length > 0) {
    for (const select of notSelected.value) {
      notSelectedFields.value = notSelectedFields.value.filter((e: any) => e !== select);
      selectedFields.value.push({ name: select });
    }
  }
  notSelected.value = [];
}

const selectedTieringList = ref(null);
const selectedTiering = ref('');
const tieringSel = ref('createTier');
const numberOfTiersSlider = ref(3);
const tieringName = ref('');
const colorPicker = ref(['#00AA00', '#55ff55', '#ff8c33']);
const sliderValueTiering: any = ref([20, 80]);
const maximum = ref(sliderValueTiering.value[sliderValueTiering.value.length - 1] + 10);

function resetTierModal() {
  tieringModal.value = false;
  selectedTieringList.value = null;
  tieringSel.value = 'createTier';
  selectedTiering.value = '';
  numberOfTiersSlider.value = 3;
  colorPicker.value = ['#00AA00', '#55ff55', '#ff8c33'];
  tieringName.value = '';
  sliderValueTiering.value = [20, 80];
  maximum.value = sliderValueTiering.value[sliderValueTiering.value.length - 1] + 10;
}

const slider = ref();
const predefineColors = ['#00AA00', '#55ff55', '#ff8c33', '#ff5555', '#CC2222'];

async function tweakSlider(e: any) {
  if (sliderValueTiering.value.length < e - 1) {
    for (let i = sliderValueTiering.value.length; i < e - 1; i++) {
      maximum.value = (await sliderValueTiering.value[sliderValueTiering.value.length - 1]) + 35;
      await sliderValueTiering.value.push(sliderValueTiering.value[sliderValueTiering.value.length - 1] + 25);
      colorPicker.value.push(predefineColors[colorPicker.value.length]);
    }
  } else if (sliderValueTiering.value.length > e - 1) {
    const length = sliderValueTiering.value.length;
    for (let i = e - 1; i < length; i++) {
      maximum.value = (await sliderValueTiering.value[sliderValueTiering.value.length - 2]) + 10;
      await sliderValueTiering.value.pop();
      colorPicker.value.pop();
    }
  }
  slider.value.setValue(sliderValueTiering.value);
}

function beforeCloseChunkDialog() {
  chunkGroupModal.value = false;
  selectedcurrentSelectedChunk.value = [];
  selectedChunk.value = [];
  currentSelectedChunk.value = [];
  chunkGroupName.value = '';
  currentSelectedChunkHierarchy.value = '';
  selectedGroup.value = null;
}

const selectedGroup = ref(null);

function resetModal() {
  global_store.setLoading(true);
  filtersCopy.value = JSON.parse(
    JSON.stringify(
      Object.keys(portfolio_store.dictionary)
        .filter((e) => portfolio_store.parameters.hierarchies.includes(e))
        .reduce((obj, key) => {
          return Object.assign(obj, {
            [key]: Object.values(portfolio_store.dictionary[key]),
          });
        }, {})
    )
  );

  tempChunkGroup.value = ['Chunk 1'];
  tempChunkSet.value = [[]];
  selectedTempChunk.value = 'Chunk 1';
  currentTabView.value = 0;
  chunkGroupModal.value = true;
  currentSelectedChunk.value = [];
  selectedChunk.value = [];
  selectedcurrentSelectedChunk.value = [];
  chunkGroupName.value = '';
  currentSelectedChunkHierarchy.value = '';
  global_store.setLoading(false);
}

const filtersCopy: any = ref([]);
const tempChunkGroup = ref(['Chunk 1']);
const tempChunkSet = ref([[]]);
const selectedTempChunk = ref('Chunk 1');
const currentTabView = ref(0);
const chunkGroupModal = ref(false);
const currentSelectedChunk = ref([]);
const selectedChunk: any = ref([[]]);
const selectedcurrentSelectedChunk = ref([]);
const chunkGroupName = ref('');
const currentSelectedChunkHierarchy = ref('');

function resizeFilter() {
  const numericValue = parseInt(filtersWidth.value, 10); // Extract numeric value from the string
  filtersWidth.value = `${350 - numericValue}px`; // Calculate and append 'px' back
}

const filtersWidth = ref('300px');
const comp2 = ref(false);

function createHChart() {
  if (root2) {
    root2.dispose();
  }

  root2 = am5.Root.new('chartdiv2');

  root2.setThemes([am5themes_Animated.new(root2)]);

  chart2 = root2.container.children.push(
    am5.Container.new(root2, {
      width: am5.percent(100),
      height: am5.percent(100),
      layout: root.verticalLayout,
    })
  );

  let series = chart2.children.push(
    am5hierarchy.Partition.new(root2, {
      singleBranchOnly: false,
      orientation: 'horizontal',
      downDepth: 1,
      initialDepth: 10,
      valueField: 'value',
      categoryField: 'name',
      childDataField: 'children',
    })
  );

  root2.dom.addEventListener('contextmenu', contextMenuListener);

  series.rectangles.template.events.on('rightclick', function (ev) {
    console.log(ev.target.dataItem.dataContext);
    handleRightClickDraftContextMenuChart(ev, ev.target.dataItem.dataContext);
    console.log('Clicked on a column', ev.target.dataItem.dataContext);
  });

  series.rectangles.template.adapters.add('fill', function (fill, target) {
    return am5.color(0x55b691);
  });

  if (currentTier.value != 'None') {
    series.rectangles.template.adapters.add('fill', function (fill, target) {
      return am5.color(0x555555);
    });
    const tiers = tierData.value[currentTier.value];
    for (let index = 0; index < tiers.tiers.length; index++) {
      series.rectangles.template.adapters.add('fill', function (fill, target) {
        let rag = target.dataItem.dataContext.rag;

        if (
          (rag >= tiers.tiers[index][0] && rag < tiers.tiers[index][1]) ||
          (tiers.tiers[index][1] == 'Up' && rag >= tiers.tiers[index][0])
        ) {
          return am5.color(tiers.colorPicker[index]);
        } else {
          return fill;
        }
      });
    }
  }

  let data = structuredClone(partitionData.value);

  series.data.setAll([data]);
  series.set('selectedDataItem', series.dataItems[0]);

  // Make stuff animate on load
  series.appear(1000, 100);
}

const contextMenuChart = ref();
const contextMenuOptions = [
  {
    name: 'Open in Underwriting Module',
    id: 'open_underwriting_module',
  },
];

function onEditDraftOptionsTable(e) {
  if (e.option.id == 'open_underwriting_module') {
    console.log(e);
    const chunkGroup = dashboard_store.getChunk();
    const group = chunkGroup[e.item.GROUP];
    const selectedHierarchy = Object.keys(group)[0];
    // console.log(selectedChunkGroup.value);
    const parametersIndex = portfolio_store.parameters.hierarchies.findIndex((e) => e == selectedHierarchy);
    console.log(parametersIndex, group[selectedHierarchy]);
    onOpenUnderwritingModule([parametersIndex], 1, [group[selectedHierarchy]]);
  }
}

function onEditDraftOptionsChart(e) {
  if (e.option.id == 'open_underwriting_module') {
    if (e.item.level == '') {
      openErrorMsg('Please select a valid chunk');
      return;
    }

    const level = e.item.level.split('.');

    console.log(level);
    const tempHierarchyOrder = [];

    dashboard_store.partitions_hierarchies.slice(0, level.length).map((hierarchy, index) => {
      tempHierarchyOrder.push(hierarchy.id);
    });
    onOpenUnderwritingModule(
      tempHierarchyOrder,
      level.length,
      level.map((e) => [e])
    );
  }
}

function handleRightClickDraftContextMenuChart(event, node) {
  contextMenuChart.value.showMenu(event.originalEvent, node);
}

const contextMenuTable = ref();

function handleRightClickDraftContextMenu(event, node) {
  console.log(node);
  contextMenuTable.value.showMenu(event, node);
}

function onOpenUnderwritingModule(hierarchyOrder, currentLevel, checkedFilterTreeKeys) {
  // [0, 1], ['1', '4']
  underwriting_store.hierarchyOrder = hierarchyOrder;
  underwriting_store.currentLevel = currentLevel;

  underwriting_store.checkedFilterTreeKeys = checkedFilterTreeKeys;
  underwriting_store.isFiltered = 1;
  router.push({ path: '/underwriting' });
}
</script>

<template>
  <vue-simple-context-menu
    ref="contextMenuTable"
    element-id="comparison-view-context-table"
    :options="contextMenuOptions"
    @option-clicked="onEditDraftOptionsTable"
  >
  </vue-simple-context-menu>
  <vue-simple-context-menu
    ref="contextMenuChart"
    element-id="comparison-view-context-chart"
    :options="contextMenuOptions"
    @option-clicked="onEditDraftOptionsChart"
  >
  </vue-simple-context-menu>
  <el-dialog v-model="tieringModal" width="650px" title="Create Tiering" :before-close="resetTierModal">
    <div>
      <el-radio-group v-model="tieringSel" class="mb-4">
        <el-radio label="createTier" size="small">Create Tier</el-radio>
        <el-radio label="tierList" size="small">Tier List</el-radio>
      </el-radio-group>
      <!-- Create Tiering -->
      <div v-if="tieringSel == 'createTier'">
        <div>
          <div class="grid grid-cols-3 gap-4">
            <div class="col-span-2">
              <h1>Tiering Name:</h1>
              <input
                type="text"
                class="w-full mt-1"
                v-model="tieringName"
                style="height: 30px"
                placeholder="Tiering Name"
              />
              <h1 class="mt-5">Tiering Value:</h1>
              <el-select class="mt-2" v-model="selectedTiering" placeholder="Select">
                <el-option
                  v-for="item in comparisonViewColumns.filter((x) => !x.includes('GWP'))"
                  :key="item"
                  :label="item"
                  :value="item"
                />
              </el-select>
              <h1 class="mt-5">Number of tiers: {{ numberOfTiersSlider }}</h1>
              <el-slider v-model="numberOfTiersSlider" @change="tweakSlider" :min="2" :show-tooltip="false" :max="5" />
            </div>
            <div class="border-solid border-2 border-black overflow-scroll p-5" style="height: 220px">
              <div v-for="i of numberOfTiersSlider" v-bind:key="i">
                <div class="flex flex-row mb-5 place-items-center">
                  <el-color-picker v-model="colorPicker[i - 1]" color-format="hex" />
                  <h1 class="ml-2" style="font-size: medium">Tier {{ i }}</h1>
                </div>
              </div>
            </div>
          </div>
          <vue-slider
            class="mt-12"
            ref="slider"
            :tooltip="'always'"
            @change="tweakMaximumSlider"
            :max="maximum"
            :modelValue="sliderValueTiering"
            :process="kprocess"
          >
          </vue-slider>
          <!-- <p>Up</p> -->
        </div>

        <div class="flex justify-between">
          <p>0%</p>
          <p>Up</p>
        </div>
        <div class="flex justify-end mt-2">
          <el-button @click="createTier()" :disabled="tieringName == '' || selectedTiering == ''"
            >Create Tier</el-button
          >
        </div>
      </div>
      <div v-else>
        <div v-if="Object.keys(tierData).length == 0">
          <h1>No tiering created.</h1>
        </div>
        <div v-else>
          <div>
            <div>
              Tiering:
              <el-select
                v-model="selectedTieringList"
                class="m-2"
                placeholder="Select"
                size="large"
                @change="selectedGroupChunk = null"
              >
                <el-option v-for="item in Object.keys(tierData)" :key="item" :label="item" :value="item" />
              </el-select>
            </div>
            <div v-if="selectedTieringList" class="m-2 border-2 border-sybil-teal p-4 pb-12">
              <h1 class="mb-5">Selected Tiering:{{ ' ' + tierData[selectedTieringList].selectedTiering }}</h1>
              <div v-for="(i, idx) of tierData[selectedTieringList].colorPicker" v-bind:key="i">
                <div class="flex flex-row mb-5 place-items-center">
                  <el-color-picker :model-value="i" color-format="hex" :disabled="true" />
                  <h1 class="ml-3" style="font-size: medium">
                    Tier {{ idx + 1 }}:
                    {{ tierData[selectedTieringList].tiers[idx][0] }}
                    - {{ tierData[selectedTieringList].tiers[idx][1] }}
                  </h1>
                </div>
              </div>
              <el-button class="float-right mt-2" type="danger" @click="deleteTier()">Delete Tier</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
  <el-dialog v-model="renameChunk" :show-close="false" :before-close="renameChunkTemp">
    <template #header="{ titleId, titleClass }">
      <div class="my-header">
        <h4 :id="titleId" :class="titleClass">Rename chunk</h4>
      </div>
    </template>
    <InputText :value="tempChunkName" class="radius-0 w-full h-12" @input="atChangeName(tempChunkName, $event)" />
  </el-dialog>
  <el-dialog v-model="chunkGroupModal" :before-close="beforeCloseChunkDialog" :show-close="false" width="950px">
    <template #header="{ close, titleId, titleClass }">
      <div class="my-header">
        <h4 :id="titleId" :class="titleClass">Chunk Group</h4>
        <el-button type="danger" @click="close">
          <el-icon class="el-icon--left">
            <CircleCloseFilled />
          </el-icon>
          Close
        </el-button>
      </div>
    </template>
    <TabView scrollable class="p-0">
      <TabPanel header="Create New Group">
        <div>
          <div>
            <h1>Chunk group name (required)</h1>
            <input type="text" class="w-full mt-1" v-model="chunkGroupName" placeholder="chunk group name" />
            <div class="flex place-content-end mt-2">
              <el-button
                @click="addChunkGroup()"
                type="primary"
                :disabled="tempChunkSet.some((e) => e.length == 0) || chunkGroupName == ''"
                plain
                >Add Group</el-button
              >
            </div>
          </div>
          <div class="grid grid-cols-2 mt-2">
            <div>
              <TabView scrollable class="p-0">
                <TabPanel
                  v-for="h in Object.keys(filtersCopy)"
                  :header="h"
                  :key="h"
                  :disabled="tempChunkSet.some((e) => e.length != 0)"
                >
                  <div>
                    <form @submit.prevent>
                      <select style="height: 200px" class="w-full" multiple v-model="selectedChunk">
                        <option v-for="item in filtersCopy[h]" :key="item" :value="item">
                          {{ item }}
                        </option>
                      </select>
                      <el-button
                        class="mt-1"
                        :disabled="selectedChunk.length == 0"
                        @click="addToCurrentSelectedChunk(h)"
                        >Move Selected</el-button
                      >
                    </form>
                  </div>
                </TabPanel>
              </TabView>
            </div>
            <div>
              <TabView scrollable class="p-0" v-model:activeIndex="currentTabView">
                <TabPanel v-for="(c, idx) in tempChunkGroup" :key="c">
                  <template #header>
                    <!-- <span>Header II</span> -->
                    <div class="flex flex-row">
                      <div
                        class="whitespace-nowrap align-middle inline-block"
                        @click="
                          selectedTempChunk = c;
                          selectedcurrentSelectedChunk = [];
                        "
                        @dblclick="changeName(c)"
                      >
                        {{ c }}
                      </div>
                      <el-button
                        :disabled="idx >= tempChunkGroup.length - 1"
                        type="danger"
                        class="ml-2"
                        :icon="Delete"
                        circle
                        @click="handleDeleteChunkSel(idx)"
                      />
                    </div>
                  </template>
                  <div>
                    <form @submit.prevent>
                      <select
                        style="height: 200px"
                        class="w-full"
                        name="peril"
                        multiple
                        v-model="selectedcurrentSelectedChunk"
                      >
                        <option v-for="item in tempChunkSet[idx]" v-bind:key="item" :value="item">
                          {{ item }}
                        </option>
                      </select>
                      <div>
                        <el-button
                          :disabled="selectedcurrentSelectedChunk.length == 0"
                          class="mt-1"
                          @click="removeToSelectedChunk(idx)"
                          >Remove Selected
                        </el-button>
                        <el-button
                          class="mt-1"
                          :disabled="tempChunkSet.some((e) => e.length == 0) ? true : false"
                          @click="addChunk()"
                          >Add Chunk
                        </el-button>
                      </div>
                    </form>
                  </div>
                </TabPanel>
              </TabView>
            </div>
          </div>
        </div>
      </TabPanel>
      <TabPanel header="Current group">
        <div v-if="Object.keys(chunkGroups).length > 0">
          <div>
            <div class="flex flex-row">
              <div>
                Groups:
                <el-select
                  v-model="selectedGroup"
                  class="m-2"
                  placeholder="Select"
                  size="large"
                  @change="selectedGroupChunk = null"
                >
                  <el-option v-for="item in Object.keys(chunkGroups)" :key="item" :label="item" :value="item" />
                </el-select>
              </div>
            </div>
            <div v-if="selectedGroup" class="m-2 border-2 border-sybil-teal p-4 pb-12">
              <div class="flex flex-row items-center justify-around">
                <h1>
                  Selected Groups:
                  {{ Object.keys(Object.values(chunkGroups[selectedGroup])[0])[0] }}
                </h1>
                <div>
                  Select Chunk:
                  <el-select v-model="selectedGroupChunk" class="m-2" placeholder="Select" size="large">
                    <el-option
                      v-for="item in Object.keys(chunkGroups[selectedGroup])"
                      :key="item"
                      :label="item"
                      :value="item"
                    />
                  </el-select>
                </div>
              </div>
              <div v-if="selectedGroupChunk">
                <form @submit.prevent>
                  <select style="height: 200px" class="w-full" multiple disabled>
                    <option
                      v-for="f in Object.values(chunkGroups[selectedGroup][selectedGroupChunk])[0]"
                      :key="f"
                      :value="f"
                    >
                      {{ portfolio_store.dictionary[Object.keys(Object.values(chunkGroups[selectedGroup])[0])[0]][f] }}
                    </option>
                  </select>
                </form>
              </div>
              <el-button class="float-right mt-2" type="danger" @click="deleteGroup()">Delete Group</el-button>
            </div>
          </div>
        </div>
        <div v-else>No available Chunk Groups</div>
      </TabPanel>
    </TabView>
  </el-dialog>
  <!-- Select field dialog -->
  <el-dialog v-model="selectFieldsModal" :show-close="false" width="950px">
    <div class="grid grid-cols-2">
      <div>
        <form @submit.prevent>
          <select style="height: 500px" class="w-full" name="peril" multiple v-model="notSelected">
            <option v-for="item in notSelectedFields" v-bind:key="item" :value="item">
              {{ item }}
            </option>
          </select>
          <el-button class="mt-1" @click="addToGroup()" :disabled="!(notSelected.length > 0)">Move Selected</el-button>
        </form>
      </div>
      <div>
        <DataTable :value="selectedFields" responsiveLayout="scroll" scrollHeight="500px" @rowReorder="onRowReorder">
          <Column :rowReorder="true" headerStyle="width: 3rem" />
          <Column header="Hierarchy (drag to reorder)" key="name">
            <template #body="slotProps">
              <div class="grid grid-cols-5">
                <div class="col-span-4">{{ slotProps.data.name }}</div>
                <div>
                  <el-button
                    type="danger"
                    @click="deleteSelected(slotProps.data.name)"
                    :icon="Delete"
                    circle
                    class="ml-2"
                  />
                </div>
              </div>
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
  </el-dialog>

  <div
    ref="section1"
    :class="blur1"
    class="overflow-hidden bg-white shadow-md"
    :style="{
      width: 'calc(100% - ' + filtersWidth + ')',
      transition: '0.5s ease-out all',
      position: 'sticky',
      top: '80px',
      'max-height': 'calc(100vh - 100px)',
    }"
  >
    <div class="w-full h-full overflow-hidden">
      <DataTable
        v-if="chunk_data && chunk_data.length > 0"
        :value="chunk_data"
        scrollable
        :scrollHeight="overflow + 'px'"
        scrollDirection="both"
        class="p-datatable-sm"
        style="text-align: center; font-size: 12px"
        ref="dt"
        id="performance_table"
      >
        <Column
          field="GROUP"
          style="width: 200px; min-width: 200px; max-width: 200px; max-height: 20px; background-color: #eef8f4"
          class="place-content-center text-center"
          :sortable="true"
          frozen
          headerStyle="background-color: rgb(187, 226, 211);text-align: center"
        >
          <template #header>
            <div class="font-bold w-full text-center">{{ selectedChunkGroup }}</div>
          </template>

          <template #body="slotProps">
            <div
              @contextmenu.prevent.stop="handleRightClickDraftContextMenu($event, slotProps.data)"
              class="p-2 h-full w-full whitespace-nowrap overflow-hidden overflow-x-auto panel-custom-scrollbar"
            >
              {{ slotProps.data.GROUP }}
            </div>
          </template>
        </Column>
        <Column
          v-for="col of Object.keys(chunk_data[0]).slice(1)"
          :key="col"
          :field="col"
          :header="col"
          style="width: 200px; min-width: 200px; max-width: 200px; max-height: 20px"
          class="place-content-center text-center"
          :sortable="true"
          headerStyle="background-color: rgb(187, 226, 211);text-align: center"
        >
          <template #body="slotProps">
            <div
              :style="{
                backgroundColor: tierData[currentTier]?.selectedTiering == col ? checkColor(slotProps.data) : 'white',
                'border-color': checkColor(slotProps.data),
                margin: '-25px -12px -25px -12px',
                'margin-bottom': '-25px',
                height: '50px',
              }"
              class="pt-4 border-y-2 whitespace-nowrap overflow-hidden hover:overflow-visible text-center"
            >
              {{
                col.includes('GWP')
                  ? numberWithCommas(slotProps.data[col], false)
                  : (slotProps.data[col] * 100).toFixed(1) + '%'
              }}
            </div>
          </template>
        </Column>
      </DataTable>
    </div>
    <div class="h-12">
      <div>
        <el-button
          class="absolute left-2 bottom-4 text-gray-500 cursor-pointer item ml-5 mt-2"
          type="info"
          link
          @click="global_store.setCurrencyModal(true)"
          ><i>Amounts are in {{ currency + GWPUnits[selectedGWPUnit][1] }}</i></el-button
        >
      </div>
      <el-button
        @click="onDownloadExcel()"
        key="primary"
        type="primary"
        class="text-center w-5 absolute right-2 bottom-2"
      >
        <el-icon>
          <Download />
        </el-icon>
      </el-button>
    </div>
  </div>
  <div
    ref="section2"
    :class="blur2"
    class="bg-white shadow-md mt-5"
    :style="{
      width: 'calc(100% - ' + filtersWidth + ')',
      transition: '0.5s ease-out all',
      position: 'sticky',
      top: '80px',
      height: 'calc(100vh - 100px)',
    }"
  >
    <div class="w-full p-5">
      <div id="chartdiv" class="w-full h-full"></div>
      <el-slider v-model="maxRadius" @change="createChart()" :show-tooltip="false" />
    </div>
  </div>

  <div
    ref="section3"
    class="bg-white shadow-md mt-5"
    :style="{
      width: 'calc(100% - ' + filtersWidth + ')',
      transition: '0.5s ease-out all',
      position: 'sticky',
      top: '80px',
      height: 'calc(100vh - 100px)',
    }"
  >
    <div class="w-full p-5">
      <div id="chartdiv2" class="w-full h-full"></div>
    </div>
  </div>

  <div class="absolute bg-white top-20 right-0 z-50" style="margin-top: -15px">
    <div
      :style="{
        width: filtersWidth,
        transition: '0.5s ease-out all',
      }"
    >
      <ComparisonViewSidePanel
        style="width: inherit"
        @resizeFilter="resizeFilter"
        @chunkSetup="resetModal()"
        @tierSetup="tieringModal = true"
        @valueSetup="selectFieldsModal = true"
        :filtersWidth="filtersWidth"
        @onChangeChunkGroup="onChangeChunkGroup"
      />
    </div>
  </div>
</template>

<style scoped>
.my-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

#chartdiv {
  width: 100%;
  height: 500px;
}

#chartdiv2 {
  width: 100%;
  height: 500px;
}

.panel-custom-scrollbar::-webkit-scrollbar {
  display: none;
  /* Hide scrollbar for Chrome, Safari and Opera */
}

.panel-custom-scrollbar {
  -ms-overflow-style: none;
  /* Hide scrollbar for IE and Edge */
  scrollbar-width: none;
  /* Hide scrollbar for Firefox */
}

.p-datatable .p-datatable-tbody > tr > td {
  padding: 0;
}

.p-datatable.p-datatable-sm .p-datatable-tbody > tr > td {
  padding: 0;
}
</style>

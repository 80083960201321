<script setup lang="ts">
import { ref, onMounted, computed, watch } from 'vue';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import { useActuarialStore, COHORT_CONSTANT } from '@/stores/actuarial';
import { useGlobalStore } from '@/stores/global';
import Skeleton from 'primevue/skeleton';
import { TRIANGULATIONS_TABLE_ID, TRIANGULATIONS_RATIOS_TABLE_ID } from '@/views/Actuarial/Triangulations/constants';
import { useElementVisibility } from '@vueuse/core';

const actuarial_store = useActuarialStore();
const dev_headers = computed(() => Array.from({ length: actuarial_store.devs[0] }, (_, i) => i + 1));
const global_store = useGlobalStore();

const blankTriangles = Array.from({ length: 50 }, (_, i) => i + 1);

const selectedGWPUnit = computed<string>(() => global_store.currency.currencyFormat.selectedGWPUnit);
const GWPUnits: any = computed(() => global_store.currency.GWPUnits);
const currency: any = computed(() => global_store.currency.currencyFormat.currency);
const emit = defineEmits(['on-scroll']);

function scrollTable(t) {
  emit('on-scroll', t);
}

function numberWithCommas(x: number, isValue: boolean) {
  if (!isValue) {
    return (x / parseFloat(GWPUnits.value[selectedGWPUnit.value][0]))
      .toFixed(global_store.currency.currencyFormat.precision)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  } else {
    return parseFloat(
      (x / parseFloat(GWPUnits.value[selectedGWPUnit.value][0])).toFixed(global_store.currency.currencyFormat.precision)
    );
  }
}

function toTitleCase(str: string) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

import { usePortfolioStore } from '@/stores/portfolio';

const portfolio_store = usePortfolioStore();

function exclude(event: any, x: number, y: number) {
  if (event.ctrlKey || event.metaKey) {
    excludeTriangulationsDiagonal(x, y - 1);
  } else if (event.shiftKey) {
    console.log('clicked with Shift key pressed');
  } else {
    excludeTriangulations(x, y - 1);
  }
}
function excludeTriangulationsDiagonal(x: number, y: number) {
  const isExcluded = actuarial_store.updateExclusionDiagnonalFrontend(x, y);
  console.log(isExcluded);
  let tempX = x;
  let tempY = y;
  let tempArr: any = [];
  while (tempX >= 0) {
    tempArr.push([tempX, tempY]);
    tempX -= 1;
    tempY += 1;
  }

  tempX = x + 1;
  tempY = y - 1;

  while (tempY >= 0) {
    tempArr.push([tempX, tempY]);
    tempX += 1;
    tempY -= 1;
  }

  let finalDevRes: any = new Set();
  let finalCohortRes: any = new Set();

  for (const i of tempArr) {
    let [developmentRes, cohortRes] = getExcludeDate(i[0], i[1]);
    for (const ii of developmentRes) {
      finalDevRes.add(ii);
    }
    for (const ii of cohortRes) {
      finalCohortRes.add(ii);
    }
  }

  finalDevRes = [...finalDevRes];
  finalCohortRes = [...finalCohortRes];

  // if(actuarial_store.trianglesLoading){
  actuarial_store.exclusionStackAppend([finalCohortRes, finalDevRes], isExcluded);
  // } else {
  //   actuarial_store.triangleExclusion(finalCohortRes, finalDevRes)
  // }
}

function excludeTriangulations(cohort: number, report_date: number) {
  let [developmentRes, cohortRes] = getExcludeDate(cohort, report_date);
  console.log(developmentRes, 'developmentRes');
  console.log(cohortRes, 'cohortRes');

  const isExcluded = actuarial_store.updateExclusionFrontend(cohort, report_date);
  console.log(isExcluded);

  // if(actuarial_store.trianglesLoading){
  console.log(...[cohortRes, developmentRes][0]);
  actuarial_store.exclusionStackAppend([cohortRes, developmentRes], isExcluded);
  // }

  // else {
  // actuarial_store.triangleExclusion(cohortRes, developmentRes)
  // }
}

function prefixAdditionalDatesWhenExcludingForYears(date: string) {
  // Generate list of dates that goes from current month/quarter to first of month/quarter
  const isMonth = /^20\d{2}-\d{2}$/;
  const isQuarter = /^20\d{2}-Q[1-4]$/;

  const year = date.slice(0, 4);

  if (isQuarter.test(date)) {
    const quarter = date.slice(-1);
    let prefixQuarter: string[] = [];
    for (let i = 1; i < parseInt(quarter); i++) {
      prefixQuarter.push(year.toString() + '-Q' + i.toString());
    }
    return prefixQuarter;
  } else if (isMonth.test(date)) {
    const year = date.slice(0, 4);
    const month = date.slice(-2);
    let prefixMonth: string[] = [];
    for (let i = 1; i < parseInt(month); i++) {
      prefixMonth.push(year.toString() + '-' + i.toString().padStart(2, '0'));
    }
    return prefixMonth;
  }

  throw new Error('Invalid date format');
}

function getExcludeDate(cohort: number, report_date: number) {
  // REPORT DATE = DEV
  const quarterMonths = {
    1: ['01', '02', '03'],
    2: ['04', '05', '06'],
    3: ['07', '08', '09'],
    4: ['10', '11', '12'],
  };

  const yearsMonths = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];

  let cohortRes: any = null;
  let developmentRes: any = null;
  let start = 0;

  if (COHORT_CONSTANT[actuarial_store.cohort] == 'Month') {
    cohortRes = [actuarial_store.triangles_dates['month'][cohort].split('-').join('/') + '/01'];
  } else if (COHORT_CONSTANT[actuarial_store.cohort] == 'Quarter') {
    const tempQuarter = actuarial_store.triangles_dates['quarter'][cohort];
    cohortRes = [];
    for (const i of quarterMonths[parseInt(tempQuarter.slice(-1))]) {
      cohortRes.push(tempQuarter.slice(0, 4) + '/' + i + '/01');
    }
    if (COHORT_CONSTANT[actuarial_store.development] == 'Month') {
      start = cohort * 2;
    }
  } else if (COHORT_CONSTANT[actuarial_store.cohort] == 'Year') {
    const tempYear = actuarial_store.triangles_dates['year'][cohort];
    cohortRes = [];
    for (const i of yearsMonths) {
      cohortRes.push(tempYear.slice(0, 4) + '/' + i + '/01');
    }

    if (COHORT_CONSTANT[actuarial_store.development] == 'Month') {
      start = cohort * 11;
    }

    if (COHORT_CONSTANT[actuarial_store.development] == 'Quarter') {
      start = cohort * 3;
    }
  }

  const trianglesDateForMonthExclusion = [
    ...prefixAdditionalDatesWhenExcludingForYears(actuarial_store.triangles_dates['month'][0]),
    ...actuarial_store.triangles_dates['month'],
  ];
  const trianglesDateForQuarterExclusion = [
    ...prefixAdditionalDatesWhenExcludingForYears(actuarial_store.triangles_dates['quarter'][0]),
    ...actuarial_store.triangles_dates['quarter'],
  ];

  console.log(trianglesDateForMonthExclusion);
  if (COHORT_CONSTANT[actuarial_store.development] == 'Month') {
    let monthForExclusion;
    if (COHORT_CONSTANT[actuarial_store.cohort] == 'Year') {
      monthForExclusion = trianglesDateForMonthExclusion;
    } else {
      monthForExclusion = actuarial_store.triangles_dates['month'];
    }
    developmentRes = [monthForExclusion[report_date + cohort + start].split('-').join('/') + '/01'];
  } else if (COHORT_CONSTANT[actuarial_store.development] == 'Quarter') {
    let tempQuarter;
    if (COHORT_CONSTANT[actuarial_store.cohort] == 'Year') {
      tempQuarter = trianglesDateForQuarterExclusion[report_date + cohort + start];
    } else {
      tempQuarter = actuarial_store.triangles_dates['quarter'][report_date + cohort + start];
    }

    developmentRes = [];
    for (const i of quarterMonths[parseInt(tempQuarter.slice(-1))]) {
      developmentRes.push(tempQuarter.slice(0, 4) + '/' + i + '/01');
    }
  } else if (COHORT_CONSTANT[actuarial_store.cohort] == 'Year') {
    const tempYear = actuarial_store.triangles_dates['year'][report_date + cohort];
    developmentRes = [];
    for (const i of yearsMonths) {
      developmentRes.push(tempYear.slice(0, 4) + '/' + i + '/01');
    }
  }

  console.log(COHORT_CONSTANT[actuarial_store.development]);
  console.log(report_date, cohort, start);
  console.log(actuarial_store.triangles_dates['month']);
  console.log(prefixAdditionalDatesWhenExcludingForYears(actuarial_store.triangles_dates['quarter'][0]));
  console.log(prefixAdditionalDatesWhenExcludingForYears(actuarial_store.triangles_dates['month'][0]));
  console.log(actuarial_store.triangles_dates['quarter']);
  return [developmentRes, cohortRes];
  // actuarial_store.triangleExclusion([cohort.split('-').join('/') + '/01'], [report_date.split('-').join('/') + '/01'])
}

onMounted(() => {
  global_store.setLoading(true);
  actuarial_store.fetchTriangulationsData();
});

const showStatus = ref(true);
const triangulationsTable = ref();
const targetIsVisible = useElementVisibility(triangulationsTable);

watch(targetIsVisible, () => {
  actuarial_store.saveTriangulationsData();
});
</script>

<template>
  <div ref="triangulationsTable">
    <div class="w-full bg-sybil-charcoal text-gray-300">
      <b class="text-sm px-2 py-1">{{
        toTitleCase(actuarial_store.triangleType) +
        (actuarial_store.triangleType == 'COUNT' ? ' of' : '') +
        ' Claims Triangles / ' +
        'Development ' +
        COHORT_CONSTANT[actuarial_store.development]
      }}</b
      ><el-button
        class="pl-5 left-0 text-gray-500 cursor-pointer item mx-2"
        type="info"
        link
        @click="global_store.setCurrencyModal(true)"
        ><i>Amounts are in {{ currency + GWPUnits[selectedGWPUnit][1] }}</i></el-button
      >
    </div>
    <div
      class="table-panel h-fit shadow-md mb-5"
      :class="{ tableOff: !showStatus }"
      @scroll="scrollTable(TRIANGULATIONS_TABLE_ID)"
      :id="TRIANGULATIONS_TABLE_ID"
    >
      <table
        v-if="actuarial_store.trianglesJSON"
        class="table-panel table-fixed bg-sybil-teal bg-opacity-10"
        style="border-spacing: 0"
        :class="{ tableOff: !showStatus }"
      >
        <thead>
          <tr>
            <th class="fixWidth-left header-teal text-white topLeft">
              {{ ['Accident', 'Underwriting'][actuarial_store.actuarial_accident_underwriting] }}
              {{ COHORT_CONSTANT[actuarial_store.cohort] }}
            </th>
            <th
              class="fixWidth header-teal text-white head"
              v-for="index in Array.from({ length: actuarial_store.devs[0] }, (_, i) => i + 1)"
              v-bind:key="index"
            >
              {{ index }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(origin, index) in actuarial_store.origins" :key="index">
            <td class="fixWidth-left side-teal left">{{ origin }}</td>
            <td
              v-for="idx in Array.from({ length: actuarial_store.devs[index] }, (_, i) => i + 1)"
              class="fixWidth bg-white shadow-md cursor-pointer"
              :class="
                origin in actuarial_store.trianglesJSON
                  ? idx in actuarial_store.trianglesJSON[origin]
                    ? actuarial_store.trianglesJSON[origin][idx][1]
                    : ''
                  : ''
              "
              @click="(event) => exclude(event, index, idx)"
            >
              {{ numberWithCommas(actuarial_store.trianglesJSON[origin][idx][0], false) }}
            </td>
          </tr>
        </tbody>
      </table>
      <table
        v-if="!actuarial_store.trianglesJSON"
        class="table-panel table-fixed bg-sybil-teal bg-opacity-10"
        style="border-spacing: 0"
        :class="{ tableOff: !showStatus }"
      >
        <thead>
          <tr>
            <th class="fixWidth header-teal text-white head z-50" v-for="index in blankTriangles" v-bind:key="index">
              {{ index }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="index in blankTriangles" :key="index">
            <template v-for="idx in blankTriangles">
              <td v-if="index <= idx" class="fixWidth bg-white shadow-md">
                <Skeleton />
              </td>
            </template>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="w-full bg-sybil-charcoal text-gray-300"><b class="text-sm px-2 py-1">Link Ratios</b></div>
    <div
      class="table-panel h-fit shadow-md mb-5"
      :id="TRIANGULATIONS_RATIOS_TABLE_ID"
      :class="{ tableOff: !showStatus }"
      @scroll="scrollTable(TRIANGULATIONS_RATIOS_TABLE_ID)"
    >
      <table
        v-if="actuarial_store.trianglesJSON"
        class="table-panel table-fixed bg-sybil-teal bg-opacity-10"
        style="border-spacing: 0"
        :class="{ tableOff: !showStatus }"
      >
        <thead>
          <tr>
            <th class="fixWidth-left header-teal text-white topLeft">
              {{ ['Accident', 'Underwriting'][actuarial_store.actuarial_accident_underwriting] }}
              {{ COHORT_CONSTANT[actuarial_store.cohort] }}
            </th>
            <th class="fixWidth header-teal text-white head" v-for="(value, index) in dev_headers" v-bind:key="index">
              {{ dev_headers[index] + ' - ' + (dev_headers[index + 1] ? dev_headers[index + 1] : 'Ult') }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(origin, index) in actuarial_store.origins" :key="index">
            <td class="fixWidth-left side-teal left">{{ origin }}</td>
            <template v-for="idx in Array.from({ length: actuarial_store.devs[index] }, (_, i) => i + 1)">
              <td
                v-if="idx + 1 in actuarial_store.trianglesJSON[origin]"
                class="fixWidth bg-white shadow-md overflow-hidden overflow-x-auto panel-custom-scrollbar cursor-pointer"
                :class="
                  origin in actuarial_store.trianglesJSON
                    ? idx in actuarial_store.trianglesJSON[origin]
                      ? actuarial_store.trianglesJSON[origin][idx][1]
                      : ''
                    : ''
                "
                @click="(event) => exclude(event, index, idx)"
              >
                {{
                  (Math.round(actuarial_store.trianglesJSON[origin][idx][0]) != 0
                    ? actuarial_store.trianglesJSON[origin][idx + 1][0] / actuarial_store.trianglesJSON[origin][idx][0]
                    : 1
                  ).toFixed(3)
                }}
              </td>
            </template>
          </tr>
        </tbody>
      </table>
      <table
        v-if="!actuarial_store.trianglesJSON"
        class="table-panel table-fixed bg-sybil-teal bg-opacity-10"
        style="border-spacing: 0"
        :class="{ tableOff: !showStatus }"
      >
        <thead>
          <tr>
            <th
              class="fixWidth header-teal text-white head z-50"
              v-for="(value, index) in blankTriangles"
              v-bind:key="index"
            >
              {{ blankTriangles[index] + ' - ' + (blankTriangles[index + 1] ? blankTriangles[index + 1] : 'Ult') }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="index in blankTriangles" :key="index">
            <template v-for="idx in blankTriangles">
              <td v-if="index <= idx" class="fixWidth bg-white shadow-md">
                <Skeleton />
              </td>
            </template>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<style scoped>
.excluded {
  background: rgba(236, 175, 194, 0.354);
  box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset;
}

.table-panel {
  overflow: scroll;
  width: 100%;
  resize: vertical;

  max-height: calc(100vh - 180px);
  border-collapse: separate;
}

.table-panel-title {
  overflow: scroll;
  width: 20px;
  max-height: 350px;
  border-collapse: separate;
  height: 600px;
}

.tableOff {
  height: 58px;
}

.head {
  position: sticky;
  top: 0;
}

.topLeft {
  position: sticky;
  left: 0;
  top: 0;
  z-index: 10;
}

.left {
  position: sticky;
  left: 0;
}

.thead {
  display: table-header-group;
  vertical-align: middle;
  border-color: inherit;
  position: sticky;
  top: 0;
}

.table {
  overflow: scroll;
  position: relative;
  table-layout: fixed;
  margin-bottom: 0 !important;
  border-spacing: 0;
}

.fixWidth-left {
  padding-top: 5px;
  padding-bottom: 5px;
  width: 160px;
  padding-left: 20px;
  padding-right: 20px;
  border: 0.01rem solid #f4f4f5;
  font-size: 12px;
  text-align: center;
}

.fixWidth {
  padding-top: 5px;
  padding-bottom: 5px;
  width: 100px;
  padding-left: 20px;
  padding-right: 20px;
  border: 0.01rem solid #f4f4f5;
  font-size: 12px;
  text-align: center;
}

.nowrap {
  white-space: nowrap;
}

.header-teal {
  background-color: rgb(187, 226, 211);
  color: #6b7280;
}

.side-teal {
  background-color: rgb(238, 248, 244);
}

.panel-custom-scrollbar::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome, Safari and Opera */
}

.panel-custom-scrollbar {
  -ms-overflow-style: none; /* Hide scrollbar for IE and Edge */
  scrollbar-width: none; /* Hide scrollbar for Firefox */
}

.overlay {
  position: absolute;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.2); /* Semi-transparent black */
  /* Additional styling as needed */
}
</style>

<script setup lang="ts">
import { onMounted, onBeforeMount, ref } from 'vue';
import { api } from '@/services/api';
import { ElMessage } from 'element-plus';
import MainMenuSelection from '@/views/Home/PortfolioSelection/MainMenuSelection.vue';
import SelectBounceTree from '@/components/SelectBounceTree.vue';
import sybilColor from '@/constants/sybilColor';
import { ArrowRight } from '@element-plus/icons-vue';
import { useGlobalStore } from '@/stores/global';
import { usePortfolioStore } from '@/stores/portfolio';
import errorHandler from '@/utilities/errorhandler';
import type { PortfolioType, SelectedDraftListType, TreeNodeSelectBounceTreeType } from '@/types/portfolio';
import { useAuthenticationStore } from '@/stores/auth';
import { openSuccessMsg } from '@/utilities/notificationMessage';
import DialogComponent from '@/components/Dialog/DialogComponent.vue';
import { fetchMirroredPortfolioDictionary } from '@/services/portfolio';
import { useRoute } from 'vue-router';

const globalStore = useGlobalStore();
const authStore = useAuthenticationStore();

const portfolios = ref<PortfolioType[]>([]);
const mirroredPortfolios = ref([]);

const sortedClients = ref([]);
const draftList = ref({});

const mirroredDictionary = ref({});

const editMirroredDictionary = ref(false);
const isLoad = ref(true);

const router = useRoute();

async function openBounceRedirection() {
  if (router.query.openBounce) {
    const openBounceId = router.query.openBounce as string;
    // globalStore.setLoading(true);
    await api
      .get('portfolio/information', {
        params: {
          bounce_id: openBounceId,
        },
      })
      .then((res) => {
        if (res.data.data) {
          const data = res.data.data;
          portfolioStore
            .onSelectPortfolio(data.portfolio_id, openBounceId, data.portfolio_name, data.bounce_name, false)
            .then(() => {
              isLoad.value = false;
            });
        }
      })
      .catch((err) => {
        // globalStore.setLoading(false);
        isLoad.value = false;
        errorHandler(err);
      });
  } else {
    isLoad.value = false;
  }
}

onBeforeMount(() => {
  openBounceRedirection();
});

async function editMirrorPortfolioDictionary() {
  globalStore.isLoading = true;
  await fetchMirroredPortfolioDictionary(treeSelected.value, selectedMirroredID.value).then((res) => {
    globalStore.isLoading = false;
    if (res && res.mirroredDictionary) {
      mirroredDictionary.value = res.mirroredDictionary;
      editMirroredDictionary.value = true;
      selectedHierarchyMirroredDictionary.value = Object.keys(mirroredDictionary.value)[0];
      console.log(selectedHierarchyMirroredDictionary.value);
    }
  });
  globalStore.isLoading = false;
}

function deletePortfolio() {
  globalStore.isLoading = true;
  api
    .delete(import.meta.env.VITE_API_URL + 'portfolio/mirrored-portfolio', {
      data: {
        mirror_portfolio_id: selectedMirroredID.value,
      },
    })
    .then(() => {
      retrievePortfolioList().then(() => {
        globalStore.isLoading = false;
        isselectCurrBusinessModal.value = false;
      });
    })
    .catch((err) => {
      errorHandler(err);
      globalStore.isLoading = false;
    });
}

onMounted(() => {
  retrievePortfolioList();
});

function openErrorMsg(msg: string) {
  ElMessage.error(msg);
}

async function retrievePortfolioList() {
  globalStore.isLoading = true;

  await api
    .get(import.meta.env.VITE_API_URL + 'portfolio/list_portfolio')
    .then((res) => {
      portfolios.value = res.data.data.portfolios;
      mirroredPortfolios.value = res.data.data.mirrored_portfolio;
      sortedClients.value = Array.from(new Set(portfolios.value.map((a) => a.name.slice(0, a.name.indexOf(' ')))));
      sortedClients.value.sort();

      console.log(portfolios.value.filter((a) => a.name.slice(0, a.name.indexOf(' ')) == 'Amet'));
      draftList.value = res.data.data.draft_list;
    })
    .catch((err) => {
      if (err.response && err.response.data) {
        openErrorMsg(err.response.data.data);
      } else {
        openErrorMsg('Error! Please try again');
      }
      globalStore.isLoading = false;
    });

  globalStore.isLoading = false;
}

const selectCurrBusiness = ref();
const selectCurrBusinessName = ref();

const isselectCurrBusinessModal = ref(false);
const selectedBounceTreeKey = ref(0);

function createDraftList() {
  let tempDraftList: SelectedDraftListType = {};
  console.log(draftList.value, selectCurrBusiness.value);

  if (draftList.value[selectCurrBusiness.value]) {
    for (const i of draftList.value[selectCurrBusiness.value]) {
      if (!tempDraftList[i.parent_id]) {
        tempDraftList[i.parent_id] = [];
      }

      tempDraftList[i.parent_id].push({
        nodeID: i.id,
        name: i.name,
      });
    }

    selectedDraftList.value = tempDraftList;
  } else {
    selectedDraftList.value = {};
  }

  console.log(selectedDraftList.value);
  treeData.value = selectedData.value?.tree;

  selectedBounceTreeKey.value += 1;
}

const selectedData = ref();
const selectedMirroredID = ref();

const isMirror = ref(false);

function selectCurrBusinessModal(data: PortfolioType) {
  let id = data.id;
  isselectCurrBusinessModal.value = true;
  selectCurrBusiness.value = id;
  selectCurrBusinessName.value = data.name;
  selectedData.value = data;
  treeSelected.value = '';

  isMirror.value = false;
  createDraftList();
}

function selectMirrorPortfolioModal(data) {
  let id = data.portfolio_id;
  isselectCurrBusinessModal.value = true;
  selectCurrBusiness.value = data.portfolio_id;
  selectedMirroredID.value = data.mirrored_portfolio_id;
  selectCurrBusinessName.value = data.name;

  isMirror.value = true;
  selectedData.value = portfolios.value.find((e) => e.id == id);
  treeSelected.value = '';

  createDraftList();
}

const selectedDraftList = ref<SelectedDraftListType>({});
const treeData = ref();
const treeSelected = ref<string>('');
const nodeID = ref();
const nodeName = ref();

function onClickTree(node: TreeNodeSelectBounceTreeType) {
  nodeID.value = node.nodeID;
  treeSelected.value = node.nodeID;
  nodeName.value = node.name;
  console.log(node);
  console.log(nodeID.value);
}

const portfolioStore = usePortfolioStore();

function openBounce() {
  portfolioStore.onSelectPortfolio(
    isMirror.value ? selectedMirroredID.value : selectCurrBusiness.value,
    treeSelected.value,
    selectCurrBusinessName.value,
    nodeName.value,
    isMirror.value
  );
}

onBeforeMount(() => {
  portfolioStore.selectedPortfolioName = '';
});

async function onSubmitNewBounceName(event) {
  globalStore.isLoading = true;
  await api
    .post(import.meta.env.VITE_API_URL + 'portfolio/rename-draft', {
      bounce_name: event['bounceName'],
      bounce_id: event['selectedNodeID'],
    })
    .then(() => {
      retrievePortfolioList().then(() => {
        createDraftList();
        globalStore.isLoading = false;
      });
    })
    .catch((err) => {
      errorHandler(err);
      globalStore.isLoading = false;
    });
}

async function onDeleteDraft(event: { selectedNodeID: string }) {
  globalStore.isLoading = true;
  await api
    .delete(import.meta.env.VITE_API_URL + 'portfolio/draft', {
      data: {
        bounce_id: event['selectedNodeID'],
      },
    })
    .then(() => {
      retrievePortfolioList().then(() => {
        createDraftList();
        globalStore.isLoading = false;
      });
    })
    .catch((err) => {
      errorHandler(err);
      globalStore.isLoading = false;
    });
}

async function mirrorPortfolio() {
  if (!mirrorPortfolioName.value) {
    openErrorMsg('Please enter a name for the mirrored portfolio');
    return;
  }
  globalStore.isLoading = true;
  await api
    .post(import.meta.env.VITE_API_URL + 'portfolio/mirrored-portfolio', {
      portfolio_id: selectCurrBusiness.value,
      mirrored_portfolio_name: mirrorPortfolioName.value,
    })
    .then(() => {
      openSuccessMsg('Mirror Portfolio Created');
      globalStore.isLoading = false;
    })
    .catch((err) => {
      errorHandler(err);
      globalStore.isLoading = false;
    });
}

function onEditMirroredDictionary(key) {
  nameChangedMirrorPortfolioModal.value = true;
  nameChangedKey.value = key;
  nameChangedMirrorPortfolio.value = mirroredDictionary.value[selectedHierarchyMirroredDictionary.value][key];
}

const nameChangedMirrorPortfolioModal = ref(false);
const nameChangedMirrorPortfolio = ref('');
const nameChangedKey = ref('');

const mirrorPortfolioName = ref('');
const isMirrorPortfolioModal = ref(false);
const selectedHierarchyMirroredDictionary = ref();

function onChangeName() {
  if (!nameChangedMirrorPortfolio.value) {
    openErrorMsg('Please enter a name for the mirrored portfolio');
    return;
  }

  mirroredDictionary.value[selectedHierarchyMirroredDictionary.value][nameChangedKey.value] =
    nameChangedMirrorPortfolio.value;
  nameChangedMirrorPortfolioModal.value = false;
}

async function onUpdateMirrorDictionary() {
  globalStore.isLoading = true;
  await api
    .post(import.meta.env.VITE_API_URL + 'portfolio/update-mirrored-dictionary', {
      mirrored_portfolio_id: selectedMirroredID.value,
      mirrored_dictionary: mirroredDictionary.value,
      portfolio_id: selectCurrBusiness.value,
    })
    .then(() => {
      openSuccessMsg('Mirror Portfolio Dictionary Updated');
      globalStore.isLoading = false;
    })
    .catch((err) => {
      errorHandler(err);
      globalStore.isLoading = false;
    });
}
</script>

<template>
  <div>
    <el-dialog :model-value="isLoad" :fullscreen="true">
      <h1>Opening Bounce...</h1>
    </el-dialog>
    <el-dialog
      v-model="isselectCurrBusinessModal"
      :fullscreen="true"
      :title="portfolios && isselectCurrBusinessModal ? selectCurrBusinessName : ''"
    >
      <DialogComponent
        v-model="editMirroredDictionary"
        title="Edit Mirrored Dictionary"
        dialog-class="w-3/6 h-80 overflow-scroll"
      >
        <DialogComponent v-model="nameChangedMirrorPortfolioModal" title="Name change">
          <div class="flex flex-col p-5">
            <input v-model="nameChangedMirrorPortfolio" />
            <el-button class="mt-8 w-24" @click="onChangeName">Confirm</el-button>
          </div>
        </DialogComponent>
        <select v-model="selectedHierarchyMirroredDictionary">
          <option v-for="v in Object.keys(mirroredDictionary)" :key="v + 'mirrored-selection'" :value="v">
            {{ v }}
          </option>
        </select>
        <div class="mt-6">
          <h1>Business <span class="text-red-500">(Double Click to Edit)</span>:</h1>
          <h1
            v-for="(val, index) in Object.keys(mirroredDictionary[selectedHierarchyMirroredDictionary])"
            :key="index"
            class="cursor-pointer"
            @dblclick="onEditMirroredDictionary(val)"
          >
            {{ mirroredDictionary[selectedHierarchyMirroredDictionary][val] }}
          </h1>
        </div>
        <el-button type="primary" class="absolute bottom-5 right-8" @click="onUpdateMirrorDictionary()">
          Update
        </el-button>
      </DialogComponent>
      <SelectBounceTree
        v-if="treeData"
        :key="selectedBounceTreeKey"
        :tree-selected="treeSelected"
        :tree-data="treeData"
        :selected-draft-list="selectedDraftList"
        @on-click-tree="onClickTree"
        @on-submit-new-bounce-name="onSubmitNewBounceName"
        @on-delete-draft="onDeleteDraft"
      />
      <DialogComponent v-model="isMirrorPortfolioModal" title="" dialog-class="w-3/6 h-44">
        <h1>Mirrored Name</h1>
        <el-input v-model="mirrorPortfolioName" placeholder="Enter Mirror Portfolio Name" />
        <el-button type="info" class="absolute bottom-5 right-8" @click="mirrorPortfolio()"> Create </el-button>
      </DialogComponent>
      <el-button
        v-if="isMirror && treeSelected"
        type="info"
        class="absolute bottom-5 right-96"
        @click="() => editMirrorPortfolioDictionary()"
      >
        Edit Mirror Portfolio Dictionary
      </el-button>
      <el-button v-if="isMirror" type="info" class="absolute bottom-5 right-52" @click="() => deletePortfolio()">
        Delete Mirror Portfolio
      </el-button>
      <el-button
        v-if="authStore.isInternal && !isMirror"
        type="info"
        class="absolute bottom-5 right-52"
        @click="() => (isMirrorPortfolioModal = true)"
      >
        Create Mirror Portfolio
      </el-button>
      <el-button type="info" class="absolute bottom-5 right-32" @click="isselectCurrBusinessModal = false">
        Cancel
      </el-button>
      <el-button
        type="primary"
        :disabled="treeSelected.length == 0"
        :color="sybilColor"
        data-testid="open-bounce-button"
        class="absolute bottom-5 right-8"
        style="color: white"
        @click="openBounce()"
      >
        Open
        <el-icon class="el-icon--right">
          <ArrowRight :tree-data="treeData" @on-click-tree="onClickTree" />
        </el-icon>
      </el-button>
    </el-dialog>
    <div v-if="portfolios.length >= 0" class="cardList">
      <div v-for="(v, i) in sortedClients" :key="i">
        <div
          class="flex flex-wrap hover:bg-sybil-teal bg-sybil-charcoal bg-opacity-10 hover:bg-opacity-10 m-2 shadow-lg"
        >
          <MainMenuSelection
            v-for="(item, idx) in portfolios.filter((item) => item.name.slice(0, item.name.indexOf(' ')) == v)"
            :key="idx"
            :item="item"
            data-testid="portfolio-selection-card"
            @select-curr-business-modal="selectCurrBusinessModal"
          />
        </div>
      </div>
      <MainMenuSelection
        v-for="(item, idx) in mirroredPortfolios"
        :key="idx"
        :item="item"
        data-testid="mirrored-portfolio-selection-card"
        @select-curr-business-modal="selectMirrorPortfolioModal"
      />
    </div>
    <div v-else>
      <h1>No portfolios available</h1>
    </div>
  </div>
</template>

<style scoped>
.cardList {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
}
</style>

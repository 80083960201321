<script setup lang="ts">
import { ref, onMounted } from 'vue';
import LoaderComponent from '@/components/LoaderComponent.vue';
import JetInput from '@/components/JetInput.vue';
import JetLabel from '@/components/JetLabel.vue';
import JetButton from '@/components/JetButton.vue';
import JetAuthenticationCard from '@/components/JetAuthenticationCard.vue';
import { useAuthenticationStore } from '@/stores/auth';
import router from '@/router/index';
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from '@/config/AzureSSOConfig.js';
import openErrorMsg from '@/components/openErrorMsg';
import { useRoute } from 'vue-router';
import * as Sentry from '@sentry/browser';

const auth_store = useAuthenticationStore();
const route = useRoute();

onMounted(() => {
  verify();
});

async function verify() {
  loading.value = true;
  setTimeout(async () => {
    if (Object.keys(auth_store.user).length != 0) {
      await auth_store.refresh_token_login();

      if (auth_store.isAuthenticated) {
        if (!auth_store.client.is_sso || (auth_store.client.is_sso && auth_store.user.sso_id)) {
          router.push({ path: '/home', query: { ...route.query } });
        } else if (auth_store.client.is_sso && auth_store.user.sso_id == null) {
          router.push({ path: '/OnboardingPage', query: { ...route.query } });
        }
      }
    }
    loading.value = false;
  });
}

const loading = ref(false);
const loginInfo = ref({
  email: '',
  password: '',
});

function handleLogin() {
  loading.value = true;
  auth_store
    .login(loginInfo.value.email.toLowerCase(), loginInfo.value.password)
    .then(() => {
      loading.value = false;
      if (auth_store.isAuthenticated) {
        if (!auth_store.user?.is_OTP && !auth_store.client.is_sso) {
          router.push({ path: '/home', query: { ...route.query } });
        } else if (auth_store.client.is_sso && auth_store.user.sso_id == null) {
          router.push({ path: '/OnboardingPage', query: { ...route.query } });
        } else if (auth_store.user?.is_OTP && !auth_store.user?.OTP_verified) {
          auth_store.setPassword(loginInfo.value.password);
          router.push({ path: '/OTPVerificationPage', query: { ...route.query } });
        }
      }
    })
    .catch((err) => {
      if (err.response && err.response.data) {
        openErrorMsg(err.response.data.data);
      } else {
        openErrorMsg('Network Error!');
      }
      loading.value = false;
    });
}

let msalInstance: PublicClientApplication | null = null;

async function azureVerifyCurrentSession() {
  loading.value = true;
  if (!msalInstance) {
    msalInstance = new PublicClientApplication(msalConfig);
    await msalInstance.initialize();
  }

  const loginRequest = {
    scopes: ['User.Read'],
  };

  try {
    const tokenResponse = await msalInstance.acquireTokenSilent(loginRequest);
    auth_store
      .loginAzure(tokenResponse.idToken)
      .then(() => {
        verify();
      })
      .catch(() => openErrorMsg('Authentication Failed'));
    // await store.dispatch("auth/loginSSO", {token: tokenResponse.idToken}).catch((err) => {showErrorMsg(err); loading.value = false;});
  } catch (error) {
    const tokenResponse = await msalInstance.acquireTokenPopup(loginRequest);
    auth_store
      .loginAzure(tokenResponse.idToken)
      .then(() => {
        verify();
      })
      .catch(() => openErrorMsg('Authentication Failed'));
    // await store.dispatch("auth/loginSSO", {token: tokenResponse.idToken}).catch((err) => {showErrorMsg(err); loading.value = false;});
  }
  loading.value = false;
}

async function signInWithAzure() {
  if (!msalInstance) {
    msalInstance = new PublicClientApplication(msalConfig);
    await msalInstance.initialize();
  }

  const loginRequest = {
    scopes: ['User.Read'],
    prompt: 'select_account',
  };

  await msalInstance
    .loginPopup(loginRequest)
    .then((response) => {
      msalInstance?.setActiveAccount(response.account);
      azureVerifyCurrentSession();
    })
    .catch((error) => {
      console.error(`error during authentication: ${error}`);
    });
}
</script>

<template>
  <LoaderComponent v-if="loading" />
  <JetAuthenticationCard>
    <template #logo>
      <!-- site logo -->
      <img class="sybil-logo w-1/2" alt="Sybil" src="@/assets/images/sybil.png" />
    </template>

    <!-- <form method="POST" @submit.prevent="handleLogin"> -->
    <div>
      <JetLabel for="email" value="Email" />
      <JetInput
        id="email"
        v-model="loginInfo.email"
        data-testid="email-input"
        type="email"
        class="mt-1 block w-full"
        required
        autofocus
      />
    </div>

    <div class="mt-4">
      <JetLabel for="password" value="Password" />
      <JetInput
        id="password"
        v-model="loginInfo.password"
        data-testid="password-input"
        type="password"
        class="mt-1 block w-full"
        required
        autocomplete="current-password"
      />
    </div>
    <!-- 
              <div class="block mt-4">
                  <label class="flex items-center">
                      <JetCheckbox v-model="remember" name="remember" />
                      <span class="ml-2 text-sm text-gray-600">Remember me</span>
                  </label>
              </div> -->

    <div class="flex items-center justify-end mt-4">
      <!-- <Link class="underline text-sm text-gray-600 hover:text-gray-900">
                  Forgot your password?
                  </Link> -->
      <JetButton data-testid="log-in-with-azure" class="ml-4" @click.stop.prevent="signInWithAzure">
        Log in with azure</JetButton
      >
      <JetButton data-testid="log-in" class="ml-4" @click="handleLogin"> Log in </JetButton>
    </div>
    <!-- </form> -->
    <!-- <JetButton class="ml-4" @click="signOutWithAzure"> Log out</JetButton> -->
  </JetAuthenticationCard>
</template>

<script setup lang="ts">
import axios from 'axios';
import { ref } from 'vue';
import { ElMessage } from 'element-plus';
import { useAuthenticationStore } from '@/stores/auth';
import LoaderComponent from '@/components/LoaderComponent.vue';
import router from '@/router/index';
import { useRoute } from 'vue-router';

const openErrorMsg = (msg: string) => {
  ElMessage.error(msg);
};
const authStore = useAuthenticationStore();
const loading = ref(false);
const route = useRoute();
const otp = ref('');

async function verifyOTP() {
  loading.value = true;
  await axios
    .post(
      import.meta.env.VITE_API_URL + 'users/verify_OTP',
      {
        otp: otp.value,
        user_id: authStore.user.id,
        password: authStore.password,
      },
      { withCredentials: true }
    )
    .then((res) => {
      console.log(res);
      authStore.setPassword('');
      authStore.verifyOTP(res.data.data);
      router.push({ path: '/home', query: { ...route.query } });
      loading.value = false;
    })
    .catch((err) => {
      console.log(err.response);
      if (err.response && err.response.data) {
        openErrorMsg(err.response.data.data);
      } else {
        openErrorMsg('Error! Please try again');
      }
      loading.value = false;
    });
}
</script>
<template>
  <div class="h-screen flex items-center justify-center">
    <LoaderComponent v-if="loading" />
    <div class="border-2 border-sybil-teal" style="width: 500px">
      <div class="flex justify-center">
        <img class="pt-5" style="height: 100px" alt="Sybil" src="@/assets/images/sybil.png" />
      </div>
      <h1 class="flex justify-center pt-5"><b>Please enter the One Time Password to login</b></h1>
      <div class="p-16 flex flex-row items-center justify-center">
        <el-input v-model="otp" placeholder="Insert One Time Password here" />
        <el-button @click="verifyOTP">Confirm</el-button>
      </div>
    </div>
  </div>
</template>

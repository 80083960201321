<template>
    <div id="rloading" class="bg-black bg-opacity-50 z-50">
      <div class="flex space-x-2 h-full scale-50 align-middle p-5 justify-center items-center">
        <div class="animate-bounce">
          <div class="bg-sybil-teal p-2 w-8 h-8 mr-4 mt-8  -skew-y-12 green-circle"></div>
        </div>
        <div  class="animate-bounce delay-75">
          <div class="bg-sybil-teal p-2 w-8 h-8 mr-4 mt-16  -skew-y-12 green-circle"></div>
        </div>
        <div class="transition duration-100 animate-bounce">
          <div class="bg-sybil-teal p-2 w-8 h-8 mt-32  -skew-y-12 green-circle"></div>
        </div>
      </div>
    </div>
  </template>
  
  <style>
  #rloading {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 49;
    width: 100%;
    height: 100%;
  }
  
  </style>
import { defineStore } from 'pinia';
import { ref } from 'vue';
import { useUnderwritingStore } from '@/stores/underwriting';
import { usePortfolioStore } from '@/stores/portfolio';
import { useActuarialStore } from '@/stores/actuarial';
import { api } from '@/services/api';

export const useGlobalStore = defineStore('global', () => {
  const isLoading = ref(false);
  const routerViewKey = ref(1);
  const connectionError = ref(false);
  const underwriting_store = useUnderwritingStore();
  const portfolio_store = usePortfolioStore();
  const actuarial_store = useActuarialStore();
  const isLargeThresholdMethodModal = ref(false);
  const isTargetModal = ref(false);
  const isDataUploadModal = ref(false);

  function setLargeThresholdMethodModal(e: boolean) {
    isLargeThresholdMethodModal.value = e;
  }

  const isSeasonalityModal = ref(false);

  function setSeasonalityModal(e: boolean) {
    isSeasonalityModal.value = e;
  }

  async function resetAllViews() {
    isLoading.value = true;
    setTimeout(() => {
      routerViewKey.value += 1;
      underwriting_store.resetUnderwriting();
    });
  }

  function onConnectionError() {
    connectionError.value = !connectionError.value;
    console.log(connectionError.value);
  }

  const currency = ref({
    currencyFormat: {
      currency: '£',
      selectedGWPUnit: 'Ones',
      precision: 0,
    },
    GWPUnits: {
      Ones: [1, ''],
      Thousands: [1000, 'k'],
      Millions: [1000000, 'm'],
    },
  });

  const isBounceModal = ref(false);
  function setBounceModal(e: boolean) {
    isBounceModal.value = e;
  }

  const isAuditLogModal = ref(false);

  function setAuditLogModal(e: boolean) {
    isAuditLogModal.value = e;
  }

  const isCurrencySelectionModal = ref(false);

  const gwp_dashboard = ref([]);
  const incurred_data_dashboard = ref([]);

  function setCurrencyModal(status: boolean) {
    isCurrencySelectionModal.value = status;
  }

  function setLoading(status: any) {
    isLoading.value = status;
  }

  function loadPortfolio(gwp_data: any, incurred_data: any) {
    gwp_dashboard.value = gwp_data;
    incurred_data_dashboard.value = incurred_data;
  }

  function onChangeCurrencyFormat(currency_input: string, selectedGWPUnit: string, precision: number) {
    currency.value.currencyFormat.currency = currency_input;
    currency.value.currencyFormat.selectedGWPUnit = selectedGWPUnit;
    currency.value.currencyFormat.precision = precision;

    const oldParameters = structuredClone(portfolio_store.parameters);
    portfolio_store.parameters['currency_format'] = currency.value;

    portfolio_store.saveParameters(oldParameters, 'Saved Parameters');
  }

  async function getModelParameters() {
    isLoading.value = true;
    let parameters = [];
    let parameters_col = [];
    await api
      .get(import.meta.env.VITE_API_URL + 'portfolio/model-parameters', {
        params: {
          bounce_id: portfolio_store.selectedBounceID,
        },
      })
      .then((res) => {
        // parameters.value = res.data.data.rows
        // parameters_col.value = res.data.data.cols
        // console.log(parameters.value)
        isLoading.value = false;
        parameters = res.data.data.rows;
        parameters_col = res.data.data.cols;
      })
      .catch(() => {
        isLoading.value = false;
      });

    return [parameters, parameters_col];
  }

  async function postModelParameters(parameters) {
    isLoading.value = true;
    const new_parameters = {
      large_method: parameters[0],
      large_threshold: parameters[1],
      seasonality: parameters[2],
      claims_combination_pc: parameters[3],
    };
    await api
      .post(import.meta.env.VITE_API_URL + 'portfolio/model-parameters', {
        bounce_id: portfolio_store.selectedBounceID,
        new_parameters: new_parameters,
      })
      .then((res) => {
        actuarial_store.createDraft(res);
        resetAllViews();
        actuarial_store.setActuarialCacheResetRequired(true);
        isLoading.value = false;
      })
      .catch(() => {
        isLoading.value = false;
      });
  }

  return {
    setCurrencyModal,
    onChangeCurrencyFormat,
    setLoading,
    isLoading,
    loadPortfolio,
    gwp_dashboard,
    incurred_data_dashboard,
    currency,
    isCurrencySelectionModal,
    onConnectionError,
    connectionError,
    isAuditLogModal,
    setAuditLogModal,
    routerViewKey,
    resetAllViews,
    isLargeThresholdMethodModal,
    setLargeThresholdMethodModal,
    isSeasonalityModal,
    setSeasonalityModal,
    getModelParameters,
    postModelParameters,
    isBounceModal,
    setBounceModal,
    isTargetModal,
    isDataUploadModal,
  };
});

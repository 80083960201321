import { type FinanceDataType, type FinanceSummaryData } from '@/types/finance';

export default class FinanceSummaryCalculation {
  businessAccidentValues: FinanceDataType;
  businessUnderwritingValues: FinanceDataType;
  uniqueClaimsNature: Set<string>;
  constructor(
    businessAccidentValues: FinanceDataType,
    businessUnderwritingValues: FinanceDataType,
    uniqueClaimsNature: Set<string>
  ) {
    this.businessAccidentValues = businessAccidentValues;
    this.businessUnderwritingValues = businessUnderwritingValues;
    this.uniqueClaimsNature = uniqueClaimsNature;
    console.log(this.businessAccidentValues);
  }

  getUnderwritingValues() {
    return this.recalculateValues(this.businessUnderwritingValues);
  }

  getAccidentValues() {
    return this.recalculateValues(this.businessAccidentValues);
  }

  recalculateValues(values: FinanceDataType) {
    const totalCalculation: FinanceSummaryData = {};

    for (const businessIndex in values) {
      const businessValues = values[businessIndex];
      for (const value of businessValues) {
        const valueDateKeys = Object.keys(value);
        for (const date of valueDateKeys) {
          if (!totalCalculation[date]) {
            totalCalculation[date] = {
              GrossPremium: 0,
              LossRatio: 0,
              Commission: 0,
              CCR: 0,
              NLR: 0,
              OverheadExpenses: 0,
              DirectExpenses: 0,
              RLCosts: 0,
              ClaimsExpenses: 0,
              ClaimsUltimate: {},
            };

            this.uniqueClaimsNature.forEach((claims) => {
              totalCalculation[date].ClaimsUltimate[claims] = 0;
            });
          }
          totalCalculation[date].GrossPremium += value[date].GWP_GEP;
          totalCalculation[date].LossRatio += value[date].LossRatio;
          totalCalculation[date].Commission += value[date].Commission;
          totalCalculation[date].CCR += value[date].CCR;
          totalCalculation[date].NLR += value[date].NLR;
          totalCalculation[date].OverheadExpenses += value[date].OverheadExpenses;

          this.uniqueClaimsNature.forEach((claims) => {
            if (value[date].ClaimsUltimate[claims]) {
              totalCalculation[date].ClaimsUltimate[claims] += value[date].ClaimsUltimate[claims];
            }
          });
        }
      }
    }

    return totalCalculation;
  }
}

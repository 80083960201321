<script setup>
import { onMounted } from 'vue';
import UnderwritingMainTable from '@/views/Underwriting/TableView/components/UnderwritingMainTable.vue';
import ChangeHierarchyOrderModal from '@/views/Underwriting/TableView/components/ChangeHierarchyOrderModal.vue';
import UnderwritingControllerModal from '@/views/Underwriting/TableView/components/UnderwritingControllerModal.vue';
import UnderwritingMultipleChangeModal from '@/views/Underwriting/TableView/components/UnderwritingMultipleChangeModal.vue';
import { useUnderwritingStore } from '@/stores/underwriting';
import AddNewBusinessModal from './components/AddNewBusinessModal.vue';

const underwriting_store = useUnderwritingStore();

onMounted(() => {
  console.log('check');
});
</script>

<template>
  <ChangeHierarchyOrderModal v-if="underwriting_store.isChangeHierarchyOrderModal" />
  <UnderwritingControllerModal v-if="underwriting_store.isUnderwritingControllerModal" />
  <UnderwritingMultipleChangeModal v-if="underwriting_store.isUnderwritingMultipleChangeModal" />
  <div class="shadow-md">
    <UnderwritingMainTable />
    <AddNewBusinessModal v-if="underwriting_store.isNewBusinessModal" />
  </div>
</template>

<script setup lang="ts">
import { usePortfolioStore } from '@/stores/portfolio';
import { useDashboardStore } from '@/stores/dashboard';
import { useUnderwritingStore } from '@/stores/underwriting';
import { Filter, Close, CaretRight, CaretBottom } from '@element-plus/icons-vue';
import { ref, onMounted, computed, watch } from 'vue';
import { ElTree } from 'element-plus';

const emit = defineEmits(['clickFilters', 'resizeFilter']);
const props = defineProps(['filtersWidth']);
const filterText = ref('');
const treeRef = ref<InstanceType<typeof ElTree>>();

const filterNode = (value: string, data: any) => {
  if (!value) return true;
  return data.label.toLowerCase().includes(value.toLowerCase());
};

const uw_acc = computed(() => dashboard_store.dashboards.uw_acc);
const tierGroupExpand = ref(false);

const filterKeys: any = ref([]);
// const selectedAccidentDate = dateFiltersUW.value["month"]
// const selectedUnderwritingDate = dateFiltersUW.value["month"]
const portfolio_store = usePortfolioStore();
const dashboard_store = useDashboardStore();
const underwriting_store = useUnderwritingStore();

const treeSelectionKeys: any = computed(() => underwriting_store.treeSelectionKeys);
const treeSelectionKeysChange: any = ref({});

onMounted(() => {
  underwriting_store.treeSelectionKeys = null;
});

watch(filterText, (val) => {
  expandedKeys.value = allKeys.value;
  treeRef.value!.filter(val);
});

const getCheckedKeys = () => {
  return treeRef.value!.getCheckedKeys(false);
};

const tracker = ref({});
const setCheckedKeys = (id: number, pid: number, event) => {
  let selected = getCheckedKeys();
  if (event.ctrlKey || event.metaKey) {
    selected.push(pid);
    treeRef.value!.setCheckedKeys(selected, false);
    selected = getCheckedKeys();
    selected = selected.filter((x) => x !== id && x != pid);
    treeRef.value!.setCheckedKeys(selected, false);

    changeSelection();
  } else {
    if (selected.indexOf(id) === -1) {
      selected.push(id);
    } else {
      selected = selected.filter((x: any) => x != id);
    }
    treeRef.value!.setCheckedKeys(selected, false);
    changeSelection();
  }
};

const renderContent = (
  h,
  {
    node,
    data,
    store,
  }: {
    node: any;
    data: any;
    store: any;
  }
) => {
  if (!data.isTitle) {
    return h(
      'div',
      {
        onClick: (event) => setCheckedKeys(node.data.id, node.data.parentId, event),
        onDblclick: () => setCheckedKeys(node.data.id, node.data.parentId, false),
      },

      h(
        'span',
        {
          class: 'custom-tree-node',
        },
        null,
        node.label
      )
    );
  } else {
    return h(
      'div',
      {
        onClick: (event) => setCheckedKeys(node.data.id, node.data.parentId, event),
        onContextmenu: (event) => {
          event.preventDefault(); // Prevent the default browser context menu
          collapseAll();
          // Add additional right-click functionality here
        },
      },
      [
        h(
          'span',
          {
            class: 'custom-tree-node',
          },
          null,
          node.label
        ),
        h(
          'span',
          {
            class: 'has-tooltip text-xs italix text-red-500 cursor-help',
            tooltip: 'ggg',
          },
          [
            h(
              'span',
              {
                class: 'tooltip rounded bg-sybil-charcoal p-2 shadow-lg p-1 text-white mt-8  text-balance  -ml-28',
              },
              null,
              'Select items that you want to normalise',
              h('br'),
              'for when fitting the curve'
            ),
          ],
          portfolio_store.parameters['actuarial_grouping'].includes(node.label) ? ' (Norm.)' : ''
        ),
        h(
          'span',
          {
            class: 'ml-2 absolute right-0 mr-5 tracker mt-1',
          },
          null,
          tracker[node.label]
        ),
      ]
    );
  }
};

const report_date = computed(() => dashboard_store.report_date);
const date = new Date(report_date.value);

const monthYearReportDate = date
  .toLocaleDateString('en-GB', {
    month: 'short',
    year: 'numeric',
  })
  .replace(/ /g, '-');

const selectedData: any = computed(() => underwriting_store.selectedData);

const dataFilters: any = computed(() => dashboard_store.dataFilters);
const unDataFilters: any = computed(() => dashboard_store.unDataFilters);
const unDateFilters: any = computed(() => {
  return dashboard_store.unDateFilters;
});

const allKeys: any = ref([]);
const all_uw_dates = computed(() => portfolio_store.all_uw_dates);
const dictionary = computed(() => portfolio_store.dictionary);
const treeData = computed(() => buildFiltersTree());

const expandedKeys: any = computed(() => underwriting_store.filtersExpandedKeys);

const handleExpand = (node) => {
  if (!expandedKeys.value.includes(node.id)) {
    expandedKeys.value.push(node.id);
  }
};
const handleCollapse = (node) => {
  const index = expandedKeys.value.indexOf(node.id);
  if (index > -1) {
    expandedKeys.value.splice(index, 1);
  }
};
const collapseAll = () => {
  expandedKeys.value = [];
};

function getQuarter(month) {
  const quarterMap = {
    Jan: 'Q1',
    Feb: 'Q1',
    Mar: 'Q1',
    Apr: 'Q2',
    May: 'Q2',
    Jun: 'Q2',
    Jul: 'Q3',
    Aug: 'Q3',
    Sep: 'Q3',
    Oct: 'Q4',
    Nov: 'Q4',
    Dec: 'Q4',
  };
  return quarterMap[month.substring(0, 3)];
}

function findChildrenLabelsById(tree, nodeId) {
  const queue = [...tree]; // Start with a shallow copy of the tree

  while (queue.length) {
    const node = queue.shift(); // Take the first node from the queue

    if (node.id === nodeId) {
      return node.children ? node.children.map((child) => child.label) : [];
    }

    // If the node has children, add them to the queue to be searched next
    if (node.children) {
      queue.push(...node.children);
    }
  }

  // Return an empty array if no matching node was found
  return [];
}

function changeSelection() {
  treeSelectionKeysChange.value = getCheckedKeys();
  if (treeSelectionKeys.value !== treeSelectionKeysChange.value) {
    underwriting_store.updateTreeSelectionKeys(treeSelectionKeysChange.value);
    let selection = {};
    for (let i in dataFilters.value) {
      // let itemselections = treeData.value.filter(x=> x.label === i)[0].children.filter(x=> treeSelectionKeys.value.includes(x.id) ).map(x=> x.label)
      let itemselections = filterKeys.value
        .filter((y) => y.name === i)[0]
        .items.filter((x) => treeSelectionKeys.value.includes(x.id))
        .map((x) => x.name);
      let itemselectionskeys = filterKeys.value
        .filter((y) => y.name === i)[0]
        .items.filter((x) => treeSelectionKeys.value.includes(x.id))
        .map((x) => x.id);

      if (itemselectionskeys.length === Object.values(dataFilters.value[i]).length) {
        let removeS = itemselectionskeys;
        removeS.push(filterKeys.value.filter((y) => y.name === i)[0].id);

        let removed = treeSelectionKeysChange.value.filter((x) => removeS.indexOf(x) === -1);

        treeRef.value!.setCheckedKeys([removed], false);
        underwriting_store.updateTreeSelectionKeys(removed);
        itemselections = [];
      }
      if (itemselections.length) {
        selection[i] = itemselections;
      } else {
        selection[i] = Object.values(dataFilters.value[i]);
      }
      tracker[i] = itemselections.length;
    }

    let uw_selection = all_uw_dates.value['month'];
    let acc_selection = all_uw_dates.value['month'];

    underwriting_store.setTreeFilters(selection, uw_selection, acc_selection);
    treeRef.value!.setCheckedKeys(treeSelectionKeys.value, false);
  }
  filterText.value = '';
  emit('clickFilters');
}

const customNodeClass = (data: any) => {
  if (data.isTitle) {
    return 'is-title';
  }
  return 'is-item ' + (data.grayed ? 'text-gray-400' : '');
};

function buildFiltersTree() {
  let data: any[] = [];
  let counter = 1;
  for (let i of Object.keys(unDataFilters.value)) {
    let chld: any = [];
    let parentId: any = counter++;
    let filterItem: any = [];

    for (let j of dataFilters.value[i]) {
      allKeys.value.push(counter);
      filterItem.push({ id: counter, name: j });
      chld.push({
        id: counter++,
        parentId: parentId,
        label: j,
        tooltipContent: j,
        isTitle: false,
        grayed: unDataFilters.value[i].map((x) => dictionary.value[i][x]).indexOf(j) === -1,
      });
    }
    filterKeys.value.push({ id: parentId, name: i, items: filterItem });
    allKeys.value.push(parentId);
    data.push({
      id: parentId,
      label: i,
      children: chld,
      isTitle: true,
    });
  }

  return data;
}

function getMonthsDates(months: any, counter: number, uwId: number, accId: number) {
  let uw_data: any = [];
  let acc_data: any = [];
  let currentYear: any = null;
  let currentQuarter: any = null;
  let uw_yearNode: any = null;
  let acc_yearNode: any = null;
  let uw_quarterNode: any = null;
  let acc_quarterNode: any = null;
  let idCounter: any = counter;

  let uw_yearId: any = null;
  let acc_yearId: any = null;

  let uw_quarterId: any = null;
  let acc_quarterId: any = null;

  months.forEach((month) => {
    const year = month.substring(4, 8);
    const quarter = getQuarter(month);

    if (year !== currentYear) {
      currentYear = year;
      uw_yearId = idCounter++;
      acc_yearId = idCounter++;
      allKeys.value.push(uw_yearId);
      allKeys.value.push(acc_yearId);
      uw_yearNode = { id: uw_yearId, parentId: uwId, label: `${year}`, children: [] };
      acc_yearNode = { id: acc_yearId, parentId: accId, label: `${year}`, children: [] };
      uw_data.push(uw_yearNode);
      acc_data.push(acc_yearNode);
    }

    if (quarter !== currentQuarter || uw_yearNode.children.length === 0) {
      currentQuarter = quarter;
      uw_quarterId = idCounter++;
      acc_quarterId = idCounter++;
      allKeys.value.push(uw_quarterId);
      allKeys.value.push(acc_quarterId);

      uw_quarterNode = { id: uw_quarterId, parentId: uw_yearId, label: `${quarter}-${year}`, children: [] };
      acc_quarterNode = { id: acc_quarterId, parentId: acc_yearId, label: `${quarter}-${year}`, children: [] };
      uw_yearNode.children.push(uw_quarterNode);
      acc_yearNode.children.push(acc_quarterNode);
    }
    let uwMonthId = idCounter++;
    let accMonthId = idCounter++;
    allKeys.value.push(uwMonthId);
    allKeys.value.push(accMonthId);
    uw_quarterNode.children.push({
      id: uwMonthId,
      parentId: uw_quarterId,
      label: `${month}`,
      isTitle: false,
      grayed: unDateFilters.value['uw'].indexOf(month) === -1,
    });
    acc_quarterNode.children.push({
      id: accMonthId++,
      parentId: acc_quarterId,
      label: `${month}`,
      isTitle: false,
      grayed: unDateFilters.value['acc'].indexOf(month) === -1,
    });
  });

  return [uw_data, acc_data, idCounter];
}

const isHide: any = ref({ Hierarchy: {}, Underwriting: true, Accident: true });

function onHideHierarchy(i: any) {
  isHide.value.Hierarchy[i] = !isHide.value.Hierarchy[i];
}

function resizeFilter() {
  emit('resizeFilter');
}

const beforeEnter = (el) => {
  el.style.height = '0';
};

const enter = (el) => {
  el.style.height = el.scrollHeight + 'px';
};

const beforeLeave = (el) => {
  el.style.height = el.scrollHeight + 'px';
};

const leave = (el) => {
  el.style.height = '0';
};
function toTitleCase(str: string) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

function updateChartClaimsSelection(e: any) {
  underwriting_store.setChartClaimsSelection(e);
}
</script>

<template>
  <div class="sidenav main-sidebars shadow">
    <!-- <h1>{{sideBarData}}</h1> -->

    <div class="flex flex-row place-items-center sticky top-0 bg-white h-12 py-5 z-20">
      <i
        @click="resizeFilter"
        class="pi pi-arrows-h ml-2 cursor-pointer absolute top-4 right-2"
        style="font-size: 20px"
      ></i>
    </div>
    <div>
      <div class="side-panel-title">
        <el-icon class="pt-3 cursor-pointer ml-1" @click="tierGroupExpand = !tierGroupExpand">
          <CaretRight v-if="!tierGroupExpand" class="text-gray-400 text-sm" />
          <CaretBottom v-if="tierGroupExpand" class="text-gray-400 text-sm" />
        </el-icon>
        <span class="text-sybil-charcoal -mt-2 ml-1 cursor-pointer" @click="tierGroupExpand = !tierGroupExpand"
          >Claims Type</span
        >
        <Edit
          style="width: 1.2em; height: 1.2em"
          class="absolute right-2 top-1 cursor-pointer ml-2 hover:text-sybil-teal"
        />
      </div>
      <transition @before-enter="beforeEnter" @enter="enter" @before-leave="beforeLeave" @leave="leave">
        <div v-if="tierGroupExpand" class="chunk-group-elements">
          <el-radio-group
            @change="updateChartClaimsSelection"
            :model-value="underwriting_store.chart_claims_selection"
            class="chunk-group-elements"
          >
            <div>
              <el-radio
                disabled
                v-for="(item, idx) in portfolio_store.parameters['claims_nature']"
                :label="idx"
                :key="item"
                size="large"
                class="hover:bg-gray-200 px-3 w-full"
              >
                {{ toTitleCase(item) }}
              </el-radio>
            </div>
          </el-radio-group>
        </div>
      </transition>
    </div>
    <template
      v-if="
        props.filtersWidth === '300px' && selectedData && all_uw_dates && unDataFilters && unDateFilters && dataFilters
      "
    >
      <el-tree
        ref="treeRef"
        v-if="treeData"
        :data="treeData"
        show-checkbox
        @check="changeSelection()"
        :render-content="renderContent"
        node-key="id"
        :filter-node-method="filterNode"
        class="filter-tree"
        :default-expanded-keys="expandedKeys"
        @node-expand="handleExpand"
        @node-collapse="handleCollapse"
        :expand-on-click-node="false"
        draggable
        :props="{ class: customNodeClass }"
      />

      <div class="card flex justify-content-center"></div>
    </template>
  </div>
</template>
<style>
.sidenav {
  height: 100%;
  position: fixed;
  right: 0;
  border-left: 1px solid #eeeeee;
  background-color: white;
  overflow-x: hidden;
  overflow-y: scroll;

  padding-bottom: 6rem;
}

.sidenav #filter {
  text-align: center;
  padding: 2px 0px 2px 16px;
  font-size: 18px;
  display: block;
  text-align: center;
}

.sidenav #filter-choice:hover {
  padding: 0px 0px 0px 22px;
  font-size: 13px;
  display: block;
  background-color: #e2e8f0;
}

.sidenav #filter-choice {
  padding: 0px 0px 0px 22px;
  font-size: 11px;
  display: block;
}

.is-title > .el-tree-node__content {
  --tw-text-opacity: 1;
  color: rgb(85 182 145 / var(--tw-text-opacity));

  height: 30px;

  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));

  padding-top: 5px;
  padding-bottom: 5px;
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;

  position: sticky;
  top: 3rem;
  font-size: 18px;
  z-index: 10;
}

.is-item {
  font-size: 12px;
  display: block;
  overflow: hidden;
  white-space: normal;
}

.is-item:hover {
  font-size: 14px;
}
@keyframes scroll-right {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%); /* Adjust to match content width */
  }
}

.tracker {
  font-size: 11px;
}

.chunk-group-elements {
  overflow: hidden;
  transition: height 0.2s ease-in-out;
}

.sidenav {
  height: 100%;
  position: fixed;
  right: 0;
  border-left: 1px solid #eeeeee;
  background-color: white;
  overflow-x: hidden;
  overflow-y: scroll;

  padding-bottom: 6rem;
}

.sidenav #filter {
  text-align: center;
  padding: 2px 0px 2px 16px;
  font-size: 18px;
  display: block;
  text-align: center;
}

.sidenav #filter-choice:hover {
  padding: 0px 0px 0px 22px;
  font-size: 13px;
  display: block;
  background-color: #e2e8f0;
}

.sidenav #filter-choice {
  padding: 0px 0px 0px 22px;
  font-size: 11px;
  display: block;
}

.side-panel-title {
  height: 30px;

  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));

  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;

  position: sticky;
  top: 3rem;
  font-size: 18px;
  z-index: 10;
}
</style>

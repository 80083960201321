<script setup lang="ts">
import { ref } from 'vue';
import { useGlobalStore } from '@/stores/global';
import { usePortfolioStore } from '@/stores/portfolio';
import InputText from 'primevue/inputtext';
import { api } from '@/services/api';
import errorHandler from '@/utilities/errorhandler';

const portfolio_store = usePortfolioStore();
const global_store = useGlobalStore();

const bounceName = ref(portfolio_store.selectedBounceName);
const isFormal = ref(false);
const UMComment = ref('');
const AMComment = ref('');
const ClaimsComment = ref('');
const specialChar = ref(false);
const bounceEmpty = ref(false);

function onCloseModal() {
  global_store.setBounceModal(false);
}

async function createBounce() {
  specialChar.value = false;
  bounceEmpty.value = false;
  bounceName.value = bounceName.value.trim();

  if (bounceName.value == '') {
    bounceEmpty.value = true;
    return;
  }

  if (!/^[^-]+$/.test(bounceName.value)) {
    specialChar.value = true;
    return;
  }

  await api
    .post(import.meta.env.VITE_API_URL + 'portfolio/bounce', {
      bounce_id: portfolio_store.selectedBounceID,
      bounce_name: bounceName.value,
      is_formal: isFormal.value,
      uw_module_comments: UMComment.value,
      act_module_comments: AMComment.value,
      claims_module_comments: ClaimsComment.value,
    })
    .then(() => {
      portfolio_store.getBounceInformation().then(() => {
        global_store.setLoading(false);
        onCloseModal();
      });
    })
    .catch((error) => {
      errorHandler(error);
      global_store.setLoading(false);
    });
}
</script>

<template>
  <el-dialog
    v-model="global_store.isBounceModal"
    :show-close="false"
    width="1000px"
    title="Bounce"
    :before-close="onCloseModal"
  >
    <form>
      <div class="flex flex-row">
        <label class="col-form-label">Bounce name*:</label>
        <!-- <a class="text-blue-500" id="bounce-name">{{changeToCurrDate()}} {{isFormal? "F-" : ""}}</a> -->
      </div>
      <div class="flex flex-row items-center">
        <!-- <div class="flex flex-row items-center" style="width: 75%">
                            
                            <a class="text-blue-500" id="bounce-name">{{changeToCurrDate()}}{{isFormal? "F-" : "-"}}{{initialConverter()}}</a>
                            <input type="text" class="bounce-name ml-2" />
                        </div> -->
        <div class="p-inputgroup" style="width: 85%">
          <InputText v-model="bounceName" placeholder="Bounce Name" />
        </div>
        <div class="text-center ml-4">
          Formal
          <div>
            <label class="el-switch el-switch-sm">
              <el-switch v-model="isFormal" />
            </label>
          </div>
        </div>
      </div>
      <label class="col-form-label">Underwriting module comments:</label>
      <textarea class="bounce-textarea" v-model="UMComment"></textarea>
      <label class="col-form-label">Actuarial module comments:</label>
      <textarea class="bounce-textarea" v-model="AMComment"></textarea>
      <label class="col-form-label">Other comments:</label>
      <textarea class="bounce-textarea" v-model="ClaimsComment"></textarea>
      <div class="form-group italic text-red-700">* mandatory field</div>
      <div v-if="bounceEmpty" class="alert alert-danger" style="" id="bounce_name_error">
        You need to enter a bounce name
      </div>
      <div v-if="specialChar" class="alert alert-danger" style="" id="bounce_name_error">
        Name cannot contain dash (-)
      </div>
    </form>
    <!-- </TabPanel> -->
    <!-- </TabView> -->
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="onCloseModal">Cancel</el-button>
        <el-button type="primary" @click="createBounce()">Bounce</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<style scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tree-node {
  width: 150px;
  height: 150px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  color: white;
  background-color: #55b691;
  border-radius: 4px;
}

.alert-danger {
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1;
}

.bounce-textarea {
  color: black;
  padding: 8px 16px;
  height: 40px;
  -webkit-box-shadow: none;
  box-shadow: none;
  opacity: 0.7;
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  background-color: #eeffee;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.bounce-name {
  color: black;
  padding: 8px 16px;
  height: 40px;
  -webkit-box-shadow: none;
  box-shadow: none;
  opacity: 0.7;
  display: block;
  width: 150px;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  background-color: #eeffee;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
}
</style>

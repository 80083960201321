import { defineStore } from 'pinia';
import { useDashboardStore } from '@/stores/dashboard';
import { usePortfolioStore } from '@/stores/portfolio';
import { useGlobalStore } from '@/stores/global';
import { api } from '@/services/api';
import { ref, computed } from 'vue';

export const useDashboardAvEStore = defineStore('dashboardAvE', () => {
  const claimsType: any = computed(() => portfolio_store.parameters.claims_nature);
  const dashboards: any = computed(() => dashboard_store.dashboards);
  const underwriting_loss_ratios: any = computed(() => dashboard_store.underwriting_loss_ratios);
  const normalise: any = computed(() => dashboard_store.normalise);
  const graphConfig: any = computed(() => dashboard_store.graphConfig);
  const dashboard_store = useDashboardStore();
  const global_store = useGlobalStore();
  const portfolio_store = usePortfolioStore();
  const unDataFilters: any = ref(null);
  const unDateFilters: any = ref(null);
  const dashboard_data: any = ref(null);
  const current_dashboard_data: any = ref(null);
  const dashboard_data_column: any = ref(null);
  const seasonality_parameters: any = ref(null);
  const large_threshold: any = ref(null);
  const large_method: any = ref(null);
  const UWCommissionColumns: any = ref(null);
  const data_CommissionColumns: any = ref(null);
  const monthly_dashboard_data: any = ref(null);
  const yearly_dashboard_data: any = ref(null);
  const binder_dashboard_data: any = ref(null);
  const quarterly_dashboard_data: any = ref(null);
  const current_monthly_dashboard_data: any = ref(null);
  const current_yearly_dashboard_data: any = ref(null);
  const current_binder_dashboard_data: any = ref(null);
  const current_quarterly_dashboard_data: any = ref(null);
  const totalData: any = ref(null);
  const current_totalData: any = ref(null);

  function graphApriori(data_row: any, isValue: boolean) {
    if (dashboard_store.graphConfig['isGLR']) {
      return seasAdjAprioriGLR(data_row, isValue);
    } else {
      return seasAdjApriori(data_row, isValue);
    }
  }

  function numberWithRatios(x: number, isValue: boolean) {
    if (isValue) {
      return x;
    } else {
      return (x * 100).toFixed(1) + '%';
    }
  }

  function seasAdjAprioriGLR(data_row: any, isValue: boolean) {
    let ans = 0;
    data_row[dashboard_data_column.value['uw_data.GEP_AMOUNT']] != 0
      ? (ans =
          claimsType.value
            .map(
              (x) =>
                data_row[dashboard_data_column.value['uw_data.' + x + '_MODEL']] *
                (!dashboards.value.seasonFactor &&
                !(underwriting_loss_ratios.value == 'Written' && dashboards.value.uw_acc == 'uw')
                  ? 1
                  : data_row[dashboard_data_column.value['uw_data.' + x + '_seasonality']])
            )
            .reduce((ps: number, s: number) => ps + s, 0) / data_row[dashboard_data_column.value['uw_data.GEP_AMOUNT']])
      : (ans = 0);

    return numberWithRatios(ans, isValue);
  }

  function seasAdjApriori(data_row: any, isValue: boolean) {
    let ans = 0;
    if (dashboards.ccr_nlr == 'CCR') {
      data_row[dashboard_data_column.value['uw_data.GEP_AMOUNT']] != 0
        ? (ans =
            (claimsType.value
              .map(
                (x) =>
                  data_row[dashboard_data_column.value['uw_data.' + x + '_MODEL']] *
                  (!dashboards.value.seasonFactor &&
                  !(underwriting_loss_ratios.value == 'Written' && dashboards.value.uw_acc == 'uw')
                    ? 1
                    : data_row[dashboard_data_column.value['uw_data.' + x + '_seasonality']])
              )
              .reduce((ps: number, s: number) => ps + s, 0) +
              data_CommissionColumns.value
                .map((x) => data_row[dashboard_data_column.value[x]])
                .reduce((ps: number, s: number) => ps + s, 0)) /
            data_row[dashboard_data_column.value['uw_data.GEP_AMOUNT']])
        : (ans = 0);
    } else {
      data_row[dashboard_data_column.value['uw_data.GEP_AMOUNT']] != 0
        ? (ans =
            claimsType.value
              .map(
                (x) =>
                  data_row[dashboard_data_column.value['uw_data.' + x + '_MODEL']] *
                  (!dashboards.value.seasonFactor &&
                  !(underwriting_loss_ratios.value == 'Written' && dashboards.value.uw_acc == 'uw')
                    ? 1
                    : data_row[dashboard_data_column.value['uw_data.' + x + '_seasonality']])
              )
              .reduce((ps: number, s: number) => ps + s, 0) /
            (data_row[dashboard_data_column.value['uw_data.GEP_AMOUNT']] -
              data_CommissionColumns.value
                .map((x) => data_row[dashboard_data_column.value[x]])
                .reduce((ps: number, s: number) => ps + s, 0)))
        : (ans = 0);
    }

    return numberWithRatios(ans, isValue);
  }

  function graphIncurred(data_row: any, isValue: boolean) {
    if (dashboard_store.graphConfig['isGLR']) {
      if (dashboard_store.graphConfig['isNormalised']) {
        return normalisedIncurred(data_row, isValue);
      } else {
        return notNormalisedIncurred(data_row, isValue);
      }
    } else {
      if (dashboard_store.graphConfig['isNormalised']) {
        return normalisedIncurredGLR(data_row, isValue);
      } else {
        return notNormalisedIncurredGLR(data_row, isValue);
      }
    }
  }

  function notNormalisedIncurredGLR(data_row: any, isValue: boolean) {
    let ans = 0;

    data_row[dashboard_data_column.value['uw_data.GEP_AMOUNT']] != 0
      ? underwriting_loss_ratios.value == 'Written' && dashboards.value.uw_acc == 'uw'
        ? (ans =
            (claimsType.value
              .map((x) => data_row[dashboard_data_column.value['claims_data.' + x + '_inc']])
              .reduce((ps: number, s: number) => ps + s, 0) +
              (claimsType.value
                .map((x) => data_row[dashboard_data_column.value['uw_data.' + x + '_MODEL']])
                .reduce((ps: number, s: number) => ps + s, 0) /
                data_row[dashboard_data_column.value['uw_data.GEP_AMOUNT']]) *
                (data_row[dashboard_data_column.value['uws.GWP_SUM']] -
                  data_row[dashboard_data_column.value['uw_data.GEP_AMOUNT']])) /
            data_row[dashboard_data_column.value['uws.GWP_SUM']] /
            data_row[dashboard_data_column.value['uw_data.GEP_AMOUNT']])
        : (ans =
            claimsType.value
              .map((x) => data_row[dashboard_data_column.value['claims_data.' + x + '_inc']])
              .reduce((ps: number, s: number) => ps + s, 0) /
            data_row[dashboard_data_column.value['uw_data.GEP_AMOUNT']])
      : (ans = 0);

    return ans;
  }

  function normalisedIncurredGLR(data_row: any, isValue: boolean) {
    let ans = 0;

    data_row[dashboard_data_column.value['uw_data.GEP_AMOUNT']] != 0
      ? underwriting_loss_ratios.value == 'Written' && dashboards.value.uw_acc == 'uw'
        ? (ans =
            (claimsType.value
              .map((x) =>
                normalise.value.includes(x)
                  ? data_row[dashboard_data_column.value['uw_data.' + x + '_MODEL']] *
                    (!dashboards.value.seasonFactor
                      ? 1
                      : data_row[dashboard_data_column.value['uw_data.' + x + '_seasonality']])
                  : data_row[dashboard_data_column.value['claims_data.' + x + '_inc']]
              )
              .reduce((ps: number, s: number) => ps + s, 0) +
              (claimsType.value
                .map((x) => data_row[dashboard_data_column.value['uw_data.' + x + '_MODEL']])
                .reduce((ps: number, s: number) => ps + s, 0) /
                data_row[dashboard_data_column.value['uw_data.GEP_AMOUNT']]) *
                (data_row[dashboard_data_column.value['uws.GWP_SUM']] -
                  data_row[dashboard_data_column.value['uw_data.GEP_AMOUNT']])) /
            data_row[dashboard_data_column.value['uws.GWP_SUM']])
        : (ans =
            claimsType.value
              .map((x) =>
                normalise.value.includes(x)
                  ? data_row[dashboard_data_column.value['uw_data.' + x + '_MODEL']] *
                    (!dashboards.value.seasonFactor
                      ? 1
                      : data_row[dashboard_data_column.value['uw_data.' + x + '_seasonality']])
                  : data_row[dashboard_data_column.value['claims_data.' + x + '_inc']]
              )
              .reduce((ps: number, s: number) => ps + s, 0) /
            data_row[dashboard_data_column.value['uw_data.GEP_AMOUNT']])
      : (ans = 0);

    return ans;
  }

  function notNormalisedIncurred(data_row: any, isValue: boolean) {
    let ans = 0;

    if (dashboards.value.ccr_nlr == 'CCR') {
      data_row[dashboard_data_column.value['uw_data.GEP_AMOUNT']] != 0
        ? underwriting_loss_ratios.value == 'Written' && dashboards.value.uw_acc == 'uw'
          ? (ans =
              (claimsType.value
                .map((x) => data_row[dashboard_data_column.value['claims_data.' + x + '_inc']])
                .reduce((ps: number, s: number) => ps + s, 0) +
                (claimsType.value
                  .map((x) => data_row[dashboard_data_column.value['uw_data.' + x + '_MODEL']])
                  .reduce((ps: number, s: number) => ps + s, 0) /
                  data_row[dashboard_data_column.value['uw_data.GEP_AMOUNT']]) *
                  (data_row[dashboard_data_column.value['uws.GWP_SUM']] -
                    data_row[dashboard_data_column.value['uw_data.GEP_AMOUNT']])) /
                data_row[dashboard_data_column.value['uws.GWP_SUM']] +
              data_CommissionColumns.value
                .map((x) => data_row[dashboard_data_column.value[x]])
                .reduce((ps: number, s: number) => ps + s, 0) /
                data_row[dashboard_data_column.value['uw_data.GEP_AMOUNT']])
          : (ans =
              (claimsType.value
                .map((x) => data_row[dashboard_data_column.value['claims_data.' + x + '_inc']])
                .reduce((ps: number, s: number) => ps + s, 0) +
                data_CommissionColumns.value
                  .map((x) => data_row[dashboard_data_column.value[x]])
                  .reduce((ps: number, s: number) => ps + s, 0)) /
              data_row[dashboard_data_column.value['uw_data.GEP_AMOUNT']])
        : (ans = 0);
    } else {
      data_row[dashboard_data_column.value['uw_data.GEP_AMOUNT']] != 0
        ? underwriting_loss_ratios.value == 'Written' && dashboards.value.uw_acc == 'uw'
          ? (ans =
              (claimsType.value
                .map((x) => data_row[dashboard_data_column.value['claims_data.' + x + '_inc']])
                .reduce((ps: number, s: number) => ps + s, 0) +
                (claimsType.value
                  .map((x) => data_row[dashboard_data_column.value['uw_data.' + x + '_MODEL']])
                  .reduce((ps: number, s: number) => ps + s, 0) /
                  data_row[dashboard_data_column.value['uw_data.GEP_AMOUNT']]) *
                  (data_row[dashboard_data_column.value['uws.GWP_SUM']] -
                    data_row[dashboard_data_column.value['uw_data.GEP_AMOUNT']])) /
              (data_row[dashboard_data_column.value['uws.GWP_SUM']] -
                data_CommissionColumns.value
                  .map((x) => data_row[dashboard_data_column.value[x.replace('uw_data', 'uws')]])
                  .reduce((ps: number, s: number) => ps + s, 0)))
          : (ans =
              claimsType.value
                .map((x) => data_row[dashboard_data_column.value['claims_data.' + x + '_inc']])
                .reduce((ps: number, s: number) => ps + s, 0) /
              (data_row[dashboard_data_column.value['uw_data.GEP_AMOUNT']] -
                data_CommissionColumns.value
                  .map((x) => data_row[dashboard_data_column.value[x]])
                  .reduce((ps: number, s: number) => ps + s, 0)))
        : (ans = 0);
    }

    return numberWithRatios(ans, isValue);
  }

  function normalisedIncurred(data_row: any, isValue: boolean) {
    let ans = 0;
    if (dashboards.value.ccr_nlr == 'CCR') {
      data_row[dashboard_data_column.value['uw_data.GEP_AMOUNT']] != 0
        ? underwriting_loss_ratios.value == 'Written' && dashboards.value.uw_acc == 'uw'
          ? (ans =
              (claimsType.value
                .map((x) =>
                  normalise.value.includes(x)
                    ? data_row[dashboard_data_column.value['uw_data.' + x + '_MODEL']] *
                      (!dashboards.value.seasonFactor
                        ? 1
                        : data_row[dashboard_data_column.value['uw_data.' + x + '_seasonality']])
                    : data_row[dashboard_data_column.value['claims_data.' + x + '_inc']]
                )
                .reduce((ps: number, s: number) => ps + s, 0) +
                (claimsType.value
                  .map((x) => data_row[dashboard_data_column.value['uw_data.' + x + '_MODEL']])
                  .reduce((ps: number, s: number) => ps + s, 0) /
                  data_row[dashboard_data_column.value['uw_data.GEP_AMOUNT']]) *
                  (data_row[dashboard_data_column.value['uws.GWP_SUM']] -
                    data_row[dashboard_data_column.value['uw_data.GEP_AMOUNT']])) /
                data_row[dashboard_data_column.value['uws.GWP_SUM']] +
              data_CommissionColumns.value
                .map((x) => data_row[dashboard_data_column.value[x]])
                .reduce((ps: number, s: number) => ps + s, 0) /
                data_row[dashboard_data_column.value['uw_data.GEP_AMOUNT']])
          : (ans =
              (claimsType.value
                .map((x) =>
                  normalise.value.includes(x)
                    ? data_row[dashboard_data_column.value['uw_data.' + x + '_MODEL']] *
                      (!dashboards.value.seasonFactor
                        ? 1
                        : data_row[dashboard_data_column.value['uw_data.' + x + '_seasonality']])
                    : data_row[dashboard_data_column.value['claims_data.' + x + '_inc']]
                )
                .reduce((ps: number, s: number) => ps + s, 0) +
                data_CommissionColumns.value
                  .map((x) => data_row[dashboard_data_column.value[x]])
                  .reduce((ps: number, s: number) => ps + s, 0)) /
              data_row[dashboard_data_column.value['uw_data.GEP_AMOUNT']])
        : (ans = 0);
    } else {
      data_row[dashboard_data_column.value['uw_data.GEP_AMOUNT']] != 0
        ? underwriting_loss_ratios.value == 'Written' && dashboards.value.uw_acc == 'uw'
          ? (ans =
              (claimsType.value
                .map((x) =>
                  normalise.value.includes(x)
                    ? data_row[dashboard_data_column.value['uw_data.' + x + '_MODEL']] *
                      (!dashboards.value.seasonFactor
                        ? 1
                        : data_row[dashboard_data_column.value['uw_data.' + x + '_seasonality']])
                    : data_row[dashboard_data_column.value['claims_data.' + x + '_inc']]
                )
                .reduce((ps: number, s: number) => ps + s, 0) +
                (claimsType.value
                  .map((x) => data_row[dashboard_data_column.value['uw_data.' + x + '_MODEL']])
                  .reduce((ps: number, s: number) => ps + s, 0) /
                  data_row[dashboard_data_column.value['uw_data.GEP_AMOUNT']]) *
                  (data_row[dashboard_data_column.value['uws.GWP_SUM']] -
                    data_row[dashboard_data_column.value['uw_data.GEP_AMOUNT']])) /
              (data_row[dashboard_data_column.value['uws.GWP_SUM']] -
                data_CommissionColumns.value
                  .map((x) => data_row[dashboard_data_column.value[x.replace('uw_data', 'uws')]])
                  .reduce((ps: number, s: number) => ps + s, 0)))
          : (ans =
              claimsType.value
                .map((x) =>
                  normalise.value.includes(x)
                    ? data_row[dashboard_data_column.value['uw_data.' + x + '_MODEL']] *
                      (!dashboards.value.seasonFactor
                        ? 1
                        : data_row[dashboard_data_column.value['uw_data.' + x + '_seasonality']])
                    : data_row[dashboard_data_column.value['claims_data.' + x + '_inc']]
                )
                .reduce((ps: number, s: number) => ps + s, 0) /
              (data_row[dashboard_data_column.value['uw_data.GEP_AMOUNT']] -
                data_CommissionColumns.value
                  .map((x) => data_row[dashboard_data_column.value[x]])
                  .reduce((ps: number, s: number) => ps + s, 0)))
        : (ans = 0);
    }

    return numberWithRatios(ans, isValue);
  }

  function normalisedGLR(data_row: any, isValue: boolean) {
    let ans = 0;

    data_row[dashboard_data_column.value['uw_data.GEP_AMOUNT']] != 0
      ? underwriting_loss_ratios.value == 'Written' && dashboards.value.uw_acc == 'uw'
        ? (ans =
            (claimsType.value
              .map((x) =>
                normalise.value.includes(x)
                  ? data_row[dashboard_data_column.value['uw_data.' + x + '_MODEL']] *
                    (!dashboards.value.seasonFactor
                      ? 1
                      : data_row[dashboard_data_column.value['uw_data.' + x + '_seasonality']])
                  : data_row[dashboard_data_column.value['claims_data.' + x + '_inc']]
              )
              .reduce((ps: number, s: number) => ps + s, 0) +
              claimsType.value
                .map((x) => (normalise.value.includes(x) ? 0 : data_row[dashboard_data_column.value[x + '_ibnr']]))
                .reduce((ps: number, s: number) => ps + s, 0) +
              (claimsType.value
                .map((x) => data_row[dashboard_data_column.value['uw_data.' + x + '_MODEL']])
                .reduce((ps: number, s: number) => ps + s, 0) /
                data_row[dashboard_data_column.value['uw_data.GEP_AMOUNT']]) *
                (data_row[dashboard_data_column.value['uws.GWP_SUM']] -
                  data_row[dashboard_data_column.value['uw_data.GEP_AMOUNT']])) /
            data_row[dashboard_data_column.value['uws.GWP_SUM']] /
            data_row[dashboard_data_column.value['uw_data.GEP_AMOUNT']])
        : (ans =
            (claimsType.value
              .map((x) =>
                normalise.value.includes(x)
                  ? data_row[dashboard_data_column.value['uw_data.' + x + '_MODEL']] *
                    (!dashboards.value.seasonFactor
                      ? 1
                      : data_row[dashboard_data_column.value['uw_data.' + x + '_seasonality']])
                  : data_row[dashboard_data_column.value['claims_data.' + x + '_inc']]
              )
              .reduce((ps: number, s: number) => ps + s, 0) +
              claimsType.value
                .map((x) => (normalise.value.includes(x) ? 0 : data_row[dashboard_data_column.value[x + '_ibnr']]))
                .reduce((ps: number, s: number) => ps + s, 0)) /
            data_row[dashboard_data_column.value['uw_data.GEP_AMOUNT']])
      : (ans = 0);

    return numberWithRatios(ans, isValue);
  }

  function notNormalisedGLR(data_row: any, isValue: boolean) {
    let ans = 0;

    data_row[dashboard_data_column.value['uw_data.GEP_AMOUNT']] != 0
      ? underwriting_loss_ratios.value == 'Written' && dashboards.value.uw_acc == 'uw'
        ? (ans =
            (claimsType.value
              .map((x) => data_row[dashboard_data_column.value['claims_data.' + x + '_inc']])
              .reduce((ps: number, s: number) => ps + s, 0) +
              claimsType.value
                .map((x) => data_row[dashboard_data_column.value[x + '_ibnr']])
                .reduce((ps: number, s: number) => ps + s, 0) +
              (claimsType.value
                .map((x) => data_row[dashboard_data_column.value['uw_data.' + x + '_MODEL']])
                .reduce((ps: number, s: number) => ps + s, 0) /
                data_row[dashboard_data_column.value['uw_data.GEP_AMOUNT']]) *
                (data_row[dashboard_data_column.value['uws.GWP_SUM']] -
                  data_row[dashboard_data_column.value['uw_data.GEP_AMOUNT']])) /
            data_row[dashboard_data_column.value['uws.GWP_SUM']] /
            data_row[dashboard_data_column.value['uw_data.GEP_AMOUNT']])
        : (ans =
            (claimsType.value
              .map((x) => data_row[dashboard_data_column.value['claims_data.' + x + '_inc']])
              .reduce((ps: number, s: number) => ps + s, 0) +
              claimsType.value
                .map((x) => data_row[dashboard_data_column.value[x + '_ibnr']])
                .reduce((ps: number, s: number) => ps + s, 0)) /
            data_row[dashboard_data_column.value['uw_data.GEP_AMOUNT']])
      : (ans = 0);

    return numberWithRatios(ans, isValue);
  }

  function graphCCRNLR(data_row: any, isValue: boolean) {
    if (!graphConfig.value['isGLR']) {
      if (graphConfig.value['isNormalised']) {
        return normalisedGLR(data_row, isValue);
      } else {
        return notNormalisedGLR(data_row, isValue);
      }
    } else {
      if (graphConfig.value['isNormalised']) {
        return normalisedCCRNLR(data_row, isValue);
      } else {
        return calculateCCRNLR(data_row, isValue);
      }
    }
  }

  function calculateCCRNLR(data_row: any, isValue: boolean) {
    let ans = 0;
    if (dashboards.value.ccr_nlr == 'CCR') {
      data_row[dashboard_data_column.value['uw_data.GEP_AMOUNT']] != 0
        ? underwriting_loss_ratios.value == 'Written' && dashboards.value.uw_acc == 'uw'
          ? (ans =
              (claimsType.value
                .map((x) => data_row[dashboard_data_column.value['claims_data.' + x + '_inc']])
                .reduce((ps: number, s: number) => ps + s, 0) +
                claimsType.value
                  .map((x) => data_row[dashboard_data_column.value[x + '_ibnr']])
                  .reduce((ps: number, s: number) => ps + s, 0) +
                (claimsType.value
                  .map((x) => data_row[dashboard_data_column.value['uw_data.' + x + '_MODEL']])
                  .reduce((ps: number, s: number) => ps + s, 0) /
                  data_row[dashboard_data_column.value['uw_data.GEP_AMOUNT']]) *
                  (data_row[dashboard_data_column.value['uws.GWP_SUM']] -
                    data_row[dashboard_data_column.value['uw_data.GEP_AMOUNT']])) /
                data_row[dashboard_data_column.value['uws.GWP_SUM']] +
              data_CommissionColumns.value
                .map((x) => data_row[dashboard_data_column.value[x]])
                .reduce((ps: number, s: number) => ps + s, 0) /
                data_row[dashboard_data_column.value['uw_data.GEP_AMOUNT']])
          : (ans =
              (claimsType.value
                .map((x) => data_row[dashboard_data_column.value['claims_data.' + x + '_inc']])
                .reduce((ps: number, s: number) => ps + s, 0) +
                claimsType.value
                  .map((x) => data_row[dashboard_data_column.value[x + '_ibnr']])
                  .reduce((ps: number, s: number) => ps + s, 0) +
                data_CommissionColumns.value
                  .map((x) => data_row[dashboard_data_column.value[x]])
                  .reduce((ps: number, s: number) => ps + s, 0)) /
              data_row[dashboard_data_column.value['uw_data.GEP_AMOUNT']])
        : (ans = 0);
    } else {
      data_row[dashboard_data_column.value['uw_data.GEP_AMOUNT']] != 0
        ? underwriting_loss_ratios.value == 'Written' && dashboards.value.uw_acc == 'uw'
          ? (ans =
              (claimsType.value
                .map((x) => data_row[dashboard_data_column.value['claims_data.' + x + '_inc']])
                .reduce((ps: number, s: number) => ps + s, 0) +
                claimsType.value
                  .map((x) => data_row[dashboard_data_column.value[x + '_ibnr']])
                  .reduce((ps: number, s: number) => ps + s, 0) +
                (claimsType.value
                  .map((x) => data_row[dashboard_data_column.value['uw_data.' + x + '_MODEL']])
                  .reduce((ps: number, s: number) => ps + s, 0) /
                  data_row[dashboard_data_column.value['uw_data.GEP_AMOUNT']]) *
                  (data_row[dashboard_data_column.value['uws.GWP_SUM']] -
                    data_row[dashboard_data_column.value['uw_data.GEP_AMOUNT']])) /
              (data_row[dashboard_data_column.value['uws.GWP_SUM']] -
                data_CommissionColumns.value
                  .map((x) => data_row[dashboard_data_column.value[x.replace('uw_data', 'uws')]])
                  .reduce((ps: number, s: number) => ps + s, 0)))
          : (ans =
              (claimsType.value
                .map((x) => data_row[dashboard_data_column.value['claims_data.' + x + '_inc']])
                .reduce((ps: number, s: number) => ps + s, 0) +
                claimsType.value
                  .map((x) => data_row[dashboard_data_column.value[x + '_ibnr']])
                  .reduce((ps: number, s: number) => ps + s, 0)) /
              (data_row[dashboard_data_column.value['uw_data.GEP_AMOUNT']] -
                data_CommissionColumns.value
                  .map((x) => data_row[dashboard_data_column.value[x]])
                  .reduce((ps: number, s: number) => ps + s, 0)))
        : (ans = 0);
    }

    return numberWithRatios(ans, isValue);
  }

  function normalisedCCRNLR(data_row: any, isValue: boolean) {
    let ans = 0;
    if (dashboards.value.ccr_nlr == 'CCR') {
      data_row[dashboard_data_column.value['uw_data.GEP_AMOUNT']] != 0
        ? underwriting_loss_ratios.value == 'Written' && dashboards.value.uw_acc == 'uw'
          ? (ans =
              (claimsType.value
                .map((x) =>
                  normalise.value.includes(x)
                    ? data_row[dashboard_data_column.value['uw_data.' + x + '_MODEL']] *
                      (!dashboards.value.seasonFactor
                        ? 1
                        : data_row[dashboard_data_column.value['uw_data.' + x + '_seasonality']])
                    : data_row[dashboard_data_column.value['claims_data.' + x + '_inc']]
                )
                .reduce((ps: number, s: number) => ps + s, 0) +
                claimsType.value
                  .map((x) => (normalise.value.includes(x) ? 0 : data_row[dashboard_data_column.value[x + '_ibnr']]))
                  .reduce((ps: number, s: number) => ps + s, 0) +
                (claimsType.value
                  .map((x) => data_row[dashboard_data_column.value['uw_data.' + x + '_MODEL']])
                  .reduce((ps: number, s: number) => ps + s, 0) /
                  data_row[dashboard_data_column.value['uw_data.GEP_AMOUNT']]) *
                  (data_row[dashboard_data_column.value['uws.GWP_SUM']] -
                    data_row[dashboard_data_column.value['uw_data.GEP_AMOUNT']])) /
                data_row[dashboard_data_column.value['uws.GWP_SUM']] +
              data_CommissionColumns.value
                .map((x) => data_row[dashboard_data_column.value[x]])
                .reduce((ps: number, s: number) => ps + s, 0) /
                data_row[dashboard_data_column.value['uw_data.GEP_AMOUNT']])
          : (ans =
              (claimsType.value
                .map((x) =>
                  normalise.value.includes(x)
                    ? data_row[dashboard_data_column.value['uw_data.' + x + '_MODEL']] *
                      (!dashboards.value.seasonFactor
                        ? 1
                        : data_row[dashboard_data_column.value['uw_data.' + x + '_seasonality']])
                    : data_row[dashboard_data_column.value['claims_data.' + x + '_inc']]
                )
                .reduce((ps: number, s: number) => ps + s, 0) +
                claimsType.value
                  .map((x) => (normalise.value.includes(x) ? 0 : data_row[dashboard_data_column.value[x + '_ibnr']]))
                  .reduce((ps: number, s: number) => ps + s, 0) +
                data_CommissionColumns.value
                  .map((x) => data_row[dashboard_data_column.value[x]])
                  .reduce((ps: number, s: number) => ps + s, 0)) /
              data_row[dashboard_data_column.value['uw_data.GEP_AMOUNT']])
        : (ans = 0);
    } else {
      data_row[dashboard_data_column.value['uw_data.GEP_AMOUNT']] != 0
        ? underwriting_loss_ratios.value == 'Written' && dashboards.value.uw_acc == 'uw'
          ? (ans =
              (claimsType.value
                .map((x) =>
                  normalise.value.includes(x)
                    ? data_row[dashboard_data_column.value['uw_data.' + x + '_MODEL']] *
                      (!dashboards.value.seasonFactor
                        ? 1
                        : data_row[dashboard_data_column.value['uw_data.' + x + '_seasonality']])
                    : data_row[dashboard_data_column.value['claims_data.' + x + '_inc']]
                )
                .reduce((ps: number, s: number) => ps + s, 0) +
                claimsType.value
                  .map((x) =>
                    normalise.value.includes(x)
                      ? 0
                      : data_row[dashboard_data_column.value['claims_data.' + x + '_ibnr']]
                  )
                  .reduce((ps: number, s: number) => ps + s, 0) +
                (claimsType.value
                  .map((x) => data_row[dashboard_data_column.value['uw_data.' + x + '_MODEL']])
                  .reduce((ps: number, s: number) => ps + s, 0) /
                  data_row[dashboard_data_column.value['uw_data.GEP_AMOUNT']]) *
                  (data_row[dashboard_data_column.value['uws.GWP_SUM']] -
                    data_row[dashboard_data_column.value['uw_data.GEP_AMOUNT']])) /
              (data_row[dashboard_data_column.value['uws.GWP_SUM']] -
                data_CommissionColumns.value
                  .map((x) => data_row[dashboard_data_column.value[x.replace('uw_data', 'uws')]])
                  .reduce((ps: number, s: number) => ps + s, 0)))
          : (ans =
              (claimsType.value
                .map((x) =>
                  normalise.value.includes(x)
                    ? data_row[dashboard_data_column.value['uw_data.' + x + '_MODEL']] *
                      (!dashboards.value.seasonFactor
                        ? 1
                        : data_row[dashboard_data_column.value['uw_data.' + x + '_seasonality']])
                    : data_row[dashboard_data_column.value['claims_data.' + x + '_inc']]
                )
                .reduce((ps: number, s: number) => ps + s, 0) +
                claimsType.value
                  .map((x) =>
                    normalise.value.includes(x)
                      ? 0
                      : data_row[dashboard_data_column.value['claims_data.' + x + '_ibnr']]
                  )
                  .reduce((ps: number, s: number) => ps + s, 0)) /
              (data_row[dashboard_data_column.value['uw_data.GEP_AMOUNT']] -
                data_CommissionColumns.value
                  .map((x) => data_row[dashboard_data_column.value[x]])
                  .reduce((ps: number, s: number) => ps + s, 0)))
        : (ans = 0);
    }

    return numberWithRatios(ans, isValue);
  }

  function transformDataToDate() {
    let sliced_dashboard_data = monthly_dashboard_data.value;
    let current_sliced_dashboard_data = current_monthly_dashboard_data.value;

    yearly_dashboard_data.value = dashboard_store.convertToPeriod('year', sliced_dashboard_data);
    binder_dashboard_data.value = dashboard_store.convertToPeriod('binder', sliced_dashboard_data);
    quarterly_dashboard_data.value = dashboard_store.convertToPeriod('quarter', sliced_dashboard_data);
    totalData.value = dashboard_store.calculateTotal(sliced_dashboard_data, 'Total', 'Total');

    current_yearly_dashboard_data.value = dashboard_store.convertToPeriod('year', current_sliced_dashboard_data);
    current_binder_dashboard_data.value = dashboard_store.convertToPeriod('binder', current_sliced_dashboard_data);
    current_quarterly_dashboard_data.value = dashboard_store.convertToPeriod('quarter', current_sliced_dashboard_data);
    current_totalData.value = dashboard_store.calculateTotal(current_sliced_dashboard_data, 'Total', 'Total');

    dashboard_data.value = monthly_dashboard_data.value;
    current_dashboard_data.value = current_monthly_dashboard_data.value;
    if (dashboard_store.dashboards.mqy == 'quarter') {
      dashboard_data.value = quarterly_dashboard_data.value;
      current_dashboard_data.value = current_quarterly_dashboard_data.value;
    } else if (dashboard_store.dashboards.mqy == 'year') {
      if (dashboard_store.isBindedYears) {
        dashboard_data.value = binder_dashboard_data.value;
        current_dashboard_data.value = current_binder_dashboard_data.value;
      } else {
        dashboard_data.value = yearly_dashboard_data.value;
        current_dashboard_data.value = current_yearly_dashboard_data.value;
      }
    }
  }

  async function loadDashboard() {
    const filters_hierarchy = dashboard_store.set_filter();

    global_store.setLoading(true);

    const [underwriting_filters, accident_filters] = dashboard_store.convertFiltersToApiFormat();

    await api
      .post(import.meta.env.VITE_API_URL + 'claims/claims-dashboard-table', {
        bounce_id: portfolio_store.expectedBounceID,
        accident_underwriting: { uw: 1, acc: 0 }[dashboard_store.dashboards.uw_acc],
        accident_month_filter: accident_filters,
        underwriting_month_filter: underwriting_filters,
        filters_hierarchy: filters_hierarchy,
        parameters_json: portfolio_store.parameters,
        normalised_CCR: dashboard_store.normalised_for_api,
        report_date: portfolio_store.selectedExpectedReportDate,
        years_of_projections: 0,
        claim_category: 0,
        line_size: Object.values(portfolio_store.parameters['line_size']),
        selectedLineSize: portfolio_store.selectedLineSize,
      })
      .then((res) => {
        monthly_dashboard_data.value = res.data.data[0].data;
        current_monthly_dashboard_data.value = structuredClone(
          dashboard_store.monthly_dashboard_data.slice(0, monthly_dashboard_data.value.length)
        );
        monthly_dashboard_data.value = monthly_dashboard_data.value.slice(
          0,
          current_monthly_dashboard_data.value.length
        );
        dashboard_data_column.value = res.data.data[0].column;
        let p_data = JSON.parse(res.data.data[1]);
        seasonality_parameters.value = p_data.SEASONALITY[0].map((x) => x.split(';').map(Number));
        large_threshold.value = p_data.LARGE_THRESHOLD[0];
        large_method.value = p_data.LARGE_METHOD[0];
        UWCommissionColumns.value = Object.keys(dashboard_data_column.value).filter((key) => key.includes('uws.COM'));
        data_CommissionColumns.value = Object.keys(dashboard_data_column.value).filter((key) =>
          key.includes('uw_data.COM')
        );

        unDataFilters.value = res.data.data[0].unfilter;
        unDateFilters.value = res.data.data[0].date_unfilter;
        transformDataToDate();
        console.log('G');
        // gwp_data.value = res.data.data['gwp']['data']
        // incurred_data.value = res.data.data['incurred_data']['data']
        global_store.setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        global_store.setLoading(false);
        throw err;
      });

    global_store.setLoading(false);
  }
  return {
    loadDashboard,
    dashboard_data,
    current_dashboard_data,
    dashboard_data_column,
    monthly_dashboard_data,
    yearly_dashboard_data,
    binder_dashboard_data,
    quarterly_dashboard_data,
    totalData,
    current_monthly_dashboard_data,
    current_yearly_dashboard_data,
    current_binder_dashboard_data,
    current_quarterly_dashboard_data,
    current_totalData,
    UWCommissionColumns,
    data_CommissionColumns,
    unDataFilters,
    unDateFilters,
    seasonality_parameters,
    large_method,
    large_threshold,
    normalisedCCRNLR,
    normalisedIncurred,
    seasAdjApriori,
    transformDataToDate,
  };
});
